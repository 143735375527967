import { send } from '@giantmachines/redux-websocket/dist'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import networkMonitor from '../../utils/networkMonitor'
import env from '../../constants/env'

/**
 * Utility function to wait for websocket to be connected before sending an event.
 */

let tries = 0 // todo find better solution for passing this to the next try
const sendToWs = createAsyncThunk(
    'SENT_TO_WS',
    (action: Record<string, unknown>, thunkAPI) => {
        const rootState = thunkAPI.getState() as RootState
        if (rootState.websocket.isLoggedIn) {
            thunkAPI.dispatch(send(action))

            if (env.debug) {
                // @ts-ignore
                networkMonitor.outgoingWs.add(action?.type || 'unkown')
            }
        } else if (++tries < 100) {
            setTimeout(() => { thunkAPI.dispatch(sendToWs(action)) }, 500)
        }
    },
)

export default sendToWs
