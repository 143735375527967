import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/cjs/Button'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { _ } from '../../../utils/localization'
import {showRecordingsModal} from './utils'
import { toDate } from '../../../utils/datetime'
import { currentUserSelector } from '../../auth/authSlice'
import { MeetingItem } from '../types'
import useInterval from '../../../hooks/useInterval'

const S = {
    Button: styled(Button)`
          position: absolute;
          bottom: 75px;
          right: 20%;
          border-radius: 25px;
          height: 45px;
    `
}

interface RecordingsButtonProps {
    meeting: MeetingItem
}

const RecordingsButton = ({meeting}: RecordingsButtonProps) => {
    const dispatch = useDispatch()
    const currentUser = useSelector(currentUserSelector())
    const isHost = meeting.host_user_ids?.includes(currentUser.id)
    const [shouldShowButton, setShouldShowButton] = useState(true)
    const currentDatetime = useInterval(1000)

    const showModal = () => (
        dispatch(showRecordingsModal())
    )

    useEffect(() => {
        const startsWithinAnHour = currentDatetime.getTime()
            > toDate(meeting.starts_at).getTime() - 1000 * 60 * 60
        setShouldShowButton(isHost && !startsWithinAnHour)
    }, [currentDatetime, isHost, meeting.starts_at])

    if (!shouldShowButton) {
        return null
    }

    return (
        <S.Button variant="dark" onClick={showModal}>{_('meetingspace.meeting.recordings_button')}</S.Button>
    )
}

export default RecordingsButton
