// This imports a huge file, we can just take out the icons we need
// at some point in the future. Or see if we can do so with webpack
// Find icons at: https://materialdesignicons.com/
import * as icons from '@mdi/js'
import {
    appointmentScheduleIcon, broadcastMessage, performerIcon, rightArrow, backArrow
} from './customPaths'

export const iconPaths = {
    question: icons.mdiHelpCircle,
    info: icons.mdiInformation,
    close: icons.mdiClose,
    send: icons.mdiSend,
    search: icons.mdiMagnify,
    zoom: icons.mdiMagnify,
    add: icons.mdiPlus,
    remove: icons.mdiMinus,
    message: icons.mdiMessage,
    left: icons.mdiChevronLeft,
    right: icons.mdiChevronRight,
    video: icons.mdiVideo,
    videoOff: icons.mdiVideoOff,
    settings: icons.mdiCog,
    bookmark: icons.mdiBookmark,
    savedBookmark: icons.mdiBookmarkCheck,
    user: icons.mdiAccount,
    exit: icons.mdiLocationExit,
    mic: icons.mdiMicrophone,
    micOff: icons.mdiMicrophoneOff,
    messageProcessing: icons.mdiMessageProcessing,
    personCircle: icons.mdiAccountCircle,
    groupCircle: icons.mdiAccountSupervisorCircle,
    check: icons.mdiCheck,
    menu: icons.mdiMenu,
    enter: icons.mdiLocationEnter,
    dotsVertical: icons.mdiDotsVertical,
    cardAccountDetailsOutline: icons.mdiCardAccountDetailsOutline,
    forum: icons.mdiForum,
    calendarClock: icons.mdiCalendarClock,
    alertDecagram: icons.mdiAlertDecagram,
    file: icons.mdiFile,
    youtube: icons.mdiYoutube,
    linkedIn: icons.mdiLinkedin,
    facebook: icons.mdiFacebook,
    twitter: icons.mdiTwitter,
    instagram: icons.mdiInstagram,
    pencil: icons.mdiPencil,
    leftDropCircle: icons.mdiArrowLeftDropCircle,
    rightDropCircle: icons.mdiArrowRightDropCircle,
    monitorEye: icons.mdiMonitorEye,
    alert: icons.mdiAlert,
    download: icons.mdiDownload,
    chevronDown: icons.mdiChevronDown,
    checkCircle: icons.mdiCheckboxMarkedCircle,
    calendarRange: icons.mdiCalendarRange,
    clock: icons.mdiClockOutline,
    mapMarker: icons.mdiMapMarker,
    goodiebag: icons.mdiBagChecked,
    map: icons.mdiMap,
    group: icons.mdiAccountGroup,
    fullscreen: icons.mdiFullscreen,
    fullscreenExit: icons.mdiFullscreenExit,
    filepowerpoint: icons.mdiFilePowerpoint,
    fileword: icons.mdiFileWord,
    filepdf: icons.mdiFilePdfBox,
    fileimage: icons.mdiFileImage,
    eye: icons.mdiEye,
    gamepad: icons.mdiGamepadVariant,
    arrowExpand: icons.mdiArrowExpandAll,
    infoOutline: icons.mdiInformationOutline,
    volumeOff: icons.mdiVolumeOff,
    delete: icons.mdiDelete,
    refresh: icons.mdiRefresh,
    doorOpen: icons.mdiDoorOpen,
    monitorShare: icons.mdiMonitorShare,
    monitorOff: icons.mdiMonitorOff,
    stop: icons.mdiStopCircle,
    textBox: icons.mdiTextBox,
    email: icons.mdiEmail,
    openInNew: icons.mdiOpenInNew,
    earth: icons.mdiEarth,
    logout: icons.mdiLogout,
    messageText: icons.mdiMessageText,
    applicationCog: icons.mdiApplicationCog,
    eyeOff: icons.mdiEyeOff,
    expandHorizontal: icons.mdiArrowExpandHorizontal,
    collapseHorizontal: icons.mdiArrowCollapseHorizontal,
    filter: icons.mdiFilterVariant,
    emailSend: icons.mdiEmailArrowRight,
    shieldCheck: icons.mdiShieldCheck,
    timetable: icons.mdiTimetable,
    calendar: icons.mdiCalendarMonth,
    arrowLeftRight: icons.mdiArrowLeftRightBold,
    arrowUpDown: icons.mdiArrowUpDownBold,
    ribbon: icons.mdiSealVariant,
    virtual: icons.mdiYoutubeTv,
    handShake: icons.mdiHandshake,
    hybrid: icons.mdiTheater,
    copy: icons.mdiContentCopy,
    leftArrow: icons.mdiArrowLeftBold,
    performer: performerIcon,
    calendarRemove: icons.mdiCalendarRemove,
    videoAccount: icons.mdiVideoAccount,
    clockTimeFour: icons.mdiClockTimeFour,
    appointmentSchedule: appointmentScheduleIcon,
    broadcastMessage,
    arrowCollapse: icons.mdiArrowCollapse,
    rightArrow,
    jobs: icons.mdiFolderAccount,
    homeSwitch: icons.mdiHomeSwitchOutline,
    expandVertical: icons.mdiArrowExpandVertical,
    collapseVertical: icons.mdiArrowCollapseVertical,
    backArrow,
    upload: icons.mdiTrayArrowUp,
}

export type IconOption = keyof typeof iconPaths
