import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import useSlug from '../../../../hooks/useSlug'
import { _ } from '../../../../utils/localization'
import AttendeesSidebar from '../../../attendees/AttendeesSidebar'
import {
    clearData, joinedBoothVisitorsSelector, leftBoothVisitorsSelector
} from '../../boothVististorsSlice'

const S = {
    Visitors: styled(AttendeesSidebar)`
        @media (max-width: 1360px) and (min-width: 933px) {
            width: 280px;
        }

        @media (max-width: 932px) {
            & .attendee-header {
                display: none;
            }
        }
    `
}

const BoothVisitorsSidebar = () => {
    const dispatch = useDispatch()
    const boothSlug = useSlug('boothSlug')
    const joinedBoothVisitors = useSelector(joinedBoothVisitorsSelector)
    const leftBoothVisitors = useSelector(leftBoothVisitorsSelector)
    const [show, setShow] = useState(false)

    useEffect(() => {
        dispatch(clearData())
    }, [dispatch])

    useEffect(() => {
        setShow(false)
        setTimeout(() => {
            setShow(true)
        }, 700)
    }, [boothSlug])

    return show && (
        <S.Visitors
            header={_('booth.visitor_sidebar_header')}
            boothSlug={boothSlug}
            addedUsers={joinedBoothVisitors}
            removeUserIds={leftBoothVisitors}
            hideCloseButton
        />
    )
}

export default BoothVisitorsSidebar
