import React, {
    useEffect, useMemo, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import intersection from 'lodash/intersection'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import styled from 'styled-components'
import { format, isAfter, isSameDay, startOfDay } from 'date-fns'
import { sortBy } from 'lodash'
import {
    calendarSidebarInfoSelector,
    closeCalendar,
    getProgramByLocationType,
    locationFilterSelector,
    requestStatusSelector,
    tagsFilterSelector,
    toggleExpanding,
    toggleSidebar,
} from './calendarSlice'
import colors from '../../constants/colors'
import { _ } from '../../utils/localization'
import Icon from '../../components/Icon'
import SelectFilter from '../../components/SelectFilter'
import {
    decrementDate, incrementDate, selectDate, selectedDateSelector
} from '../timetable/redux/dateSlice'
import CalendarOverview from './CalendarOverview'
import FullPageLoading from '../../components/FullPageLoading'
import constants from '../../constants/constants'
import { ProgramSpace } from '../timetable/types'
import useClickOutSide from '../../hooks/useClickOutSide'
import Typography from '../../components/Typography'
import { getLocalTimezone, getUniqByDay } from '../../utils/datetime'
import useFetchAllProgramDates from './hooks/useFetchAllProgramDates'
import DatePicker from '../../components/DatePicker/DatePicker'
import calendarScheduleIcon from '../../assets/calendar-schedule.svg'
import useFetchAppointmentsInSelectedDate from './hooks/useFetchAppointmentsInSelectedDate'

const S = {
    Wrapper: styled.div`
        position: absolute;
        top: 50px;
        right: 0;
        height: calc(100% - 50px);
        overflow: hidden;
        z-index: ${constants.Z_INDEX.CALENDAR};
        background-color: white;
        box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1);
    `,
    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding: 16px;
        border-bottom: 1px solid ${colors.inputBorder};
    `,
    Title: styled.div`
        font-size: 20px;
        font-weight: 700;
        color: ${colors.grayDescription};
    `,
    SpaceAndDateFilter: styled.div`
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0 16px;
        border-bottom: 1px solid ${colors.inputBorder};
    `,
    SelectFilter: styled(SelectFilter)`
        position: relative;
        z-index: 99;
        & .space-filter__control {
            width: 180px;
            border: none !important;
            border-color: transparent !important;
            box-shadow: none !important;
            cursor: pointer;
        }
        & .space-filter__value-container {
            padding: 0;
            font-size: 14px;
            white-space: nowrap;
            color: ${colors.grayDescription};
        }
        & .space-filter__indicator-separator {
            display: none;
        }
        & .space-filter__menu {
            width: max-content;
            max-width: 500px;
        }
    `,
    NavigationButton: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        width: 24px;
        height: 24px;
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        cursor: pointer;
    `,
    DatePicker: styled(DatePicker)`
        position: relative;
        z-index: 10;
    `,
    UpcomingDateCard: styled.div<{isExpanding: boolean}>`
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: calc(100% - ${({isExpanding}) => (isExpanding ? 320 : 40)}px);
        height: 100%;
        top: ${({isExpanding}) => (isExpanding ? 109 : 235)}px;
        left: ${({isExpanding}) => (isExpanding ? 320 : 40)}px;
        padding: 32px;
        padding-top: 64px;
        background: ${colors.lightBackground};
        z-index: 10;

        & > img {
            opacity: 0.6;
        }

        & > h5 {
            color: ${colors.grayTitle};
            font-size: 20px;
            font-weight: 500;
            margin-top: 16px;
            margin-bottom: 8px;
        }
        & > p {
            color: ${colors.grayDescription};
            font-size: 14px;
        }
        & > div {
            width: auto;
            padding: 4px 8px 4px 16px;
            border-radius: 4px;
            background: ${colors.midBlue};
            color: white;
            font-size: 14px;
            cursor: pointer;
            transition: 0.25s;
            &:hover {
                background: ${colors.blue};
            }
        }
    `
}

const filterTags = (tagsFilter = {}) => (programSpace: ProgramSpace) => ({
    ...programSpace,
    items: programSpace.items.filter((item) => {
        const itemTags = item.tags?.map((tag) => tag.id)
        return Object.values(tagsFilter).filter((e) => !isEmpty(e)).every(
            (tags: any) => intersection(map(tags, (e) => e?.value), itemTags).length
        )
    })
})

const ProgramCalendar = () => {
    const dispatch = useDispatch()
    const selectedDate = useSelector(selectedDateSelector)
    const { isShowing, isExpanding } = useSelector(calendarSidebarInfoSelector)
    const programSpaces = useSelector(getProgramByLocationType)
    const requestStatus = useSelector(requestStatusSelector)
    const [_programSpaces, setProgramSpaces] = useState(programSpaces)
    const locationFilter = useSelector(locationFilterSelector)
    const [spacesFilter, setSpacesFilter] = useState([])
    const tagsFilter = useSelector(tagsFilterSelector)
    const calendarRef = useRef(null)
    useClickOutSide(calendarRef, () => isShowing && dispatch(closeCalendar()))
    const allProgramDates = useFetchAllProgramDates()
    const { data: plannedMeetings = [] } = useFetchAppointmentsInSelectedDate()
    const allPlannedDates = sortBy(
        // @ts-ignore
        [...allProgramDates, ...getUniqByDay(plannedMeetings.map((e) => e.start_date))]
    )
    const hasPlannedActivity = allPlannedDates.find((date) => isSameDay(date, selectedDate))
    const firstUpcomingDate = allPlannedDates.find((_date) => isAfter(_date, selectedDate))
    // @ts-ignore
    const latestPreviousDate = allPlannedDates.findLast((_date) => isAfter(selectedDate, _date))

    const selectOptions = useMemo(
        () => programSpaces.map((space) => ({ value: space.id, label: space.name })),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [programSpaces.map((e) => e.id).toString()]
    )

    useEffect(() => {
        setProgramSpaces(programSpaces)
        setSpacesFilter(programSpaces.map((space) => (space.id)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programSpaces.map((e) => e.id).toString()])

    useEffect(() => {
        setProgramSpaces(
            programSpaces
                .filter((e) => {
                    if (isEmpty(locationFilter)) {
                        return true
                    }

                    return locationFilter.map((location) => location.value).includes(e.location_type)
                })
                .filter((e) => !spacesFilter.length || spacesFilter.includes(e.id))
                .map(filterTags(tagsFilter))
                .filter((e) => !isEmpty(e.items))
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationFilter, programSpaces.map((e) => e.id).toString(), spacesFilter, tagsFilter])

    return (
        <S.Wrapper ref={calendarRef}>
            <S.Header>
                <S.Title>{_('calendar.title')}</S.Title>
                <Typography>
                    Timezone
                    {' '}
                    {getLocalTimezone()}
                </Typography>
                <div className="flex-align-center">
                    <Icon
                        className="mr-2"
                        icon={isExpanding ? 'collapseHorizontal' : 'expandHorizontal'}
                        onClick={() => dispatch(toggleExpanding())}
                    />
                    <Icon icon="close" onClick={() => dispatch(toggleSidebar())} />
                </div>
            </S.Header>
            <S.SpaceAndDateFilter>
                <S.SelectFilter
                    allOption={{
                        label: _('space_filter.select_all'),
                        value: '*'
                    }}
                    options={selectOptions}
                    defaultValues={selectOptions}
                    onChange={setSpacesFilter}
                    classNamePrefix="space-filter"
                    itemText="space"
                />
                <S.DatePicker
                    value={selectedDate}
                    onChange={(value) => dispatch(selectDate(value))}
                    highlightDates={allPlannedDates}
                />
                <S.NavigationButton onClick={() => dispatch(decrementDate())}>
                    <Icon icon="chevronDown" style={{ transform: 'rotate(90deg)' }} />
                </S.NavigationButton>
                <S.NavigationButton onClick={() => dispatch(incrementDate())}>
                    <Icon icon="chevronDown" style={{ transform: 'rotate(-90deg)' }} />
                </S.NavigationButton>
            </S.SpaceAndDateFilter>
            <CalendarOverview
                programSpaces={_programSpaces}
                isExpanding={isExpanding}
            />
            {!hasPlannedActivity && (
                <S.UpcomingDateCard isExpanding={isExpanding}>
                    <img src={calendarScheduleIcon} alt="" />
                    <h5>{_('calendar.no_planned_activities_header')}</h5>
                    {(firstUpcomingDate || latestPreviousDate) ? (
                        <>
                            <p>
                                {firstUpcomingDate ? _('calendar.first_upcoming_activity_description') : _('calendar.latest_previous_activity_description')}
                                {' '}
                                {format((firstUpcomingDate || latestPreviousDate), 'MMM do yyyy - HH:mm')}
                            </p>
                            <div
                                onClick={() => (
                                    dispatch(selectDate(startOfDay(firstUpcomingDate || latestPreviousDate)))
                                )}
                            >
                                {_('calendar.go_to_button')} {format(firstUpcomingDate || latestPreviousDate, 'MMM dd yyyy')}
                                <Icon icon="chevronDown" style={{transform: 'rotate(-90deg)'}} color="white" />
                            </div>
                        </>
                    ) : (
                        <p>{_('calendar.no_upcoming_activity_description')}</p>
                    )}
                </S.UpcomingDateCard>
            )}
            {requestStatus === 'loading' && <FullPageLoading isAbsolute />}
        </S.Wrapper>
    )
}

export default ProgramCalendar
