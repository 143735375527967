import { createAsyncThunk } from '@reduxjs/toolkit'
import sendToWs from '../websocket/sendToWs'
import { emptyData } from './networkingSlice'
import { emptyData as emptyVideocalls } from './networkingCallsSlice'

interface NetworkingAreaProps {
    eventSlug: string
    networkingSlug: string
}

export const joinNetworkingArea = createAsyncThunk(
    'JOIN_NETWORKING_AREA',
    async ({ eventSlug, networkingSlug}: NetworkingAreaProps, thunkAPI) => {
        thunkAPI.dispatch(sendToWs({
            type: 'join_networking_area',
            event_slug: eventSlug,
            networking_slug: networkingSlug
        }))
    },
)

export const leaveNetworkingArea = createAsyncThunk(
    'LEAVE_NETWORKING_AREA',
    async ({ eventSlug, networkingSlug }: NetworkingAreaProps, thunkAPI) => {
        thunkAPI.dispatch(sendToWs({
            type: 'leave_networking_area',
            event_slug: eventSlug,
            networking_slug: networkingSlug
        }))
        thunkAPI.dispatch(emptyData(networkingSlug))
        thunkAPI.dispatch(emptyVideocalls())
    },
)
