import { Get as GetReactAxios } from 'react-axios'
import React, { ReactChild } from 'react'
import { AxiosInstance } from 'axios'
import Loader from './Loader'
import { captureError } from '../utils/errorTracker'

/**
 * Wrapper around react-axios to:
 * - Add typescript
 * - Add default loader
 * - Get .data from payload
 * - Change to returned value from args to one single object.
 */

interface RequestInfo {
    response: any
    error: string
    isLoading: boolean
    makeRequest: () => void
    axios: AxiosInstance
}

interface GetProps {
    url: string
    children: (data: any, info: RequestInfo) => ReactChild
    debounce?: number
    hideError?: boolean
    disableLoader?: boolean
    disableCapture?: boolean
    params?: Record<string, string | number>
}

const Get = ({
    children,
    url,
    params = {},
    debounce = null,
    hideError = false,
    disableLoader = false,
    disableCapture = false,
}: GetProps) => (
    <GetReactAxios url={url} params={params} debounce={debounce}>
        { (error, response, isLoading, makeRequest, axios) => {
            const data = response && response.data

            if (error && !disableCapture) {
                captureError(error)
                return hideError ? null : <div>Something went wrong</div>
            }

            if ((isLoading || !data) && !disableLoader) {
                return <div className="mt-4"><Loader /></div>
            }

            return children(data, {
                error, response, isLoading, makeRequest, axios
            })
        } }
    </GetReactAxios>
)

export default Get
