import React from 'react'
import styled, { css } from 'styled-components'

const S = {
    ThumbnailWithCounter: styled.div<Props>`
        position: relative;
        width: ${({size}) => size}px;
        height: ${({size}) => size}px;
        border-radius: 50%;
        background-image: url(${({ image }) => image});
        background-size: cover;
        background-position: center;
        ${({ counter }) => counter > 0 && css<Props>`
            &:after {
                content: ${() => `'+${counter}'`};
                position: absolute;
                top: 0;
                left: ${({size}) => size / 2}px;
                padding-left: ${({ size }) => size / 14}px;
                display: flex;
                align-items: center;
                width: ${({size}) => size / 2}px;
                height: ${({size}) => size}px;
                border-bottom-right-radius: ${({size}) => size}px;
                border-top-right-radius: ${({size}) => size}px;
                background-color:  rgba(0, 0, 0, 0.5);
                font-size: ${({size}) => `clamp(12px, ${size / 5.25}px, 16px)`};
                font-weight: 700;
                color: white;
            }
        `}
    `
}

interface Props {
    image: string
    counter: number
    size: number
    className?: string
}

const ThumbnailWithCounter = (props: Props) => (
    <S.ThumbnailWithCounter {...props} />
)
export default ThumbnailWithCounter
