import React from 'react'
import ReactSelect from 'react-select'
import { SelectComponentsProps } from 'react-select/src/Select'

interface Props extends SelectComponentsProps {
    optionValue?: string,
    optionLabel?: string,
    options: any[],
    onChange: (data) => void,
    allowSelectAll?: boolean,
    allOption?: {
        label?: string,
        value?: string
    }
    placeholder?: string
}

const SelectWithAllOption = ({
    allOption, optionValue = 'value', optionLabel = 'label', ...props
}: Props) => {
    if (props.allowSelectAll) {
        return (
            <ReactSelect
                {...props}
                getOptionValue={(option) => option[optionValue]}
                getOptionLabel={(option) => option[optionLabel]}
                options={props.options.length ? [allOption, ...props.options] : []}
                onChange={(selected, event) => {
                    if (selected !== null && selected.length > 0) {
                        let result = []
                        if (event.action === 'select-option') {
                            if (selected[selected.length - 1][optionValue] === allOption[optionValue]
                                || selected.length === props.options.length
                            ) {
                                return props.onChange([allOption, ...props.options])
                            }
                            return props.onChange(selected)
                        } if (event.action === 'deselect-option' && event.option[optionValue] !== '*') {
                            result = selected.filter(
                                (option) => option[optionValue] !== allOption[optionValue]
                            )
                        }
                        return props.onChange(result)
                    }
                    return props.onChange(selected)
                }}
            />
        )
    }

    return (
        <ReactSelect
            getOptionValue={(option) => option[optionValue]}
            getOptionLabel={(option) => option[optionLabel]}
            {...props}
        />
    )
}

export default SelectWithAllOption
