import { createSlice } from '@reduxjs/toolkit'

let _level = 0
const zoomLevel = (minutesPerColumn: number) => ({
    level: _level++,
    columns: (24 * 60) / minutesPerColumn,
    widthPerMinute: 60 / minutesPerColumn,
})

export type ZoomLevel = ReturnType<typeof zoomLevel>
const zoomLevels = [
    zoomLevel(30),
    zoomLevel(15),
    zoomLevel(5),
    zoomLevel(1),
]

const initState: ZoomLevel = zoomLevels[2]

const changeZoom = (state, direction: number) => {
    const currentZoomLevel = state.level
    const nextZoomLevel = currentZoomLevel + direction
    const nextZoom = zoomLevels[nextZoomLevel]
    return nextZoom || state
}

const zoomLevelSlice = createSlice({
    name: 'zoom_level',
    initialState: initState,
    reducers: {
        incrementZoom: (state) => changeZoom(state, 1),
        decrementZoom: (state) => changeZoom(state, -1),
    },
})

export const { incrementZoom, decrementZoom } = zoomLevelSlice.actions
export default zoomLevelSlice.reducer
