import { useHistory } from 'react-router-dom'
import useSlug from '../../hooks/useSlug'
import routes from '../../routes'
import { MeetingItem } from './types'

const useRedirectToMeetingRoom = (meeting: MeetingItem, meetingSlug: string) => {
    const history = useHistory()
    const eventSlug = useSlug('eventSlug')
    return () => {
        history.push(
            routes.meetingRoom.getPath({
                eventSlug,
                meetingSlug,
                meetingRoomSlug: meeting.slug,
            })
        )
    }
}

export default useRedirectToMeetingRoom
