import React from 'react'
import styled from 'styled-components'
import colors from '../../../../constants/colors'
import { _ } from '../../../../utils/localization'
import ProgramItemRow from '../../../calendar/components/ProgramItemRow'
import { Booth } from '../../types'

const S = {
    Wrapper: styled.div`
        padding: 20px;
        @media (max-width: 932px) {
            padding: 0;
            padding-right: 6px;
        }
    `,
    Header: styled.h1`
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 500;
        color: ${colors.darkTitle};
        @media (max-width: 932px) {
            display: none
        }
    `,
    Description: styled.p`
        margin-bottom: 28px;
        font-size: 14px;
        font-weight: 300;
        color: ${colors.icon};
    `,
    ProgramItemRow: styled(ProgramItemRow)`
        margin-bottom: 18px;
    `
}

const SponsoredSessions = ({ boothDetail }: { boothDetail: Booth }) => (
    <S.Wrapper>
        <S.Header>
            {_('booth.tabs.sponsored_sessions')}
        </S.Header>
        <S.Description>
            {_('booth.tabs.sponsored_sessions.description')}
        </S.Description>
        {boothDetail.sponsored_sessions.map((session) => (
            <S.ProgramItemRow key={session.id} item={{sponsor: {id: boothDetail.id}, ...session}} />
        ))}
    </S.Wrapper>
)

export default SponsoredSessions
