import Logger from 'js-logger'

const Bool = (v: any, _default = false) => {
    switch (v) {
    case 'false': return false
    case 'true': return true
    case undefined: return _default
    case null: return _default
    default:
        Logger.error(`Env variable should be boolean, received ${v}`)
        return _default
    }
}

const env = Object.freeze({
    buildVersion: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENV,
    isProduction: process.env.REACT_APP_ENV === 'production',
    isAcceptance: process.env.REACT_APP_ENV === 'acceptance',
    isTestServer: process.env.REACT_APP_ENV === 'testserver',
    isDevelopment: process.env.REACT_APP_ENV === 'development',
    isLiteVersion: process.env.REACT_APP_LITE_VERSION === 'true',
    debug: ['development', 'testserver'].includes(process.env.REACT_APP_ENV),
    apiUrl: process.env.REACT_APP_API_URL,
    backendUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    websocketUrl: process.env.REACT_APP_WEBSOCKET_URL,
    virtualPortalUrl: process.env.REACT_APP_VIRTUAL_PORTAL_URL,
    appTitle: process.env.REACT_APP_TITLE,
    enableFullscreenToggle: Bool(process.env.REACT_APP_ENABLE_FULLSCREEN_TOGGLE, true),
    enableRouteLeavePrompt: Bool(process.env.REACT_APP_ENABLE_ROUTE_LEAVE_PROMPT, true),
    enableMobileWarning: Bool(process.env.REACT_APP_ENABLE_MOBILE_WARNING, true),
    sentryDns: process.env.REACT_APP_SENTRY_DNS,
    strictMode: Bool(process.env.REACT_APP_STRICT_MODE, true),
    sambaUrl: process.env.REACT_APP_SAMBA_URL,
    sambaUser: process.env.REACT_APP_SAMBA_USER,
    videocallCapacity: Number(process.env.REACT_APP_VIDEOCALL_CAPACITY)
})

if (env.sambaUrl.endsWith('/')) {
    throw new Error(`ENV variable sambaUrl with value ${env.sambaUrl} should not end with a slash`)
}

if (env.apiUrl.endsWith('/')) {
    throw new Error(`ENV variable apiUrl with value ${env.apiUrl} should not end with a slash`)
}

if (env.websocketUrl.endsWith('/')) {
    throw new Error(`ENV variable websocketUrl with value ${env.websocketUrl} should not end with a slash`)
}

if (!env.debug && !env.sentryDns) {
    throw new Error('If environment is production, ENV variable should contain sentry dns')
}

if (!env.debug && !env.appTitle) {
    throw new Error('If environment is production, ENV variable should contain appTitle')
}

export default env
