import { createSelector } from 'reselect'
import omit from 'lodash/omit'
import sortBy from 'lodash/sortBy'
import { RootState } from '../../../store'
import { ProgramSpace } from '../types'

const minutesPerDay = 60 * 24
export const mapGetDayWidthToProps = (state: RootState) => ({
    dayWidth: state.timetable.zoomLevel.widthPerMinute * minutesPerDay,
})

export const getProgramSpacesForTimeTable = createSelector(
    [
        (state: RootState) => state.timetable.spaces.data,
    ],
    (spaces): ProgramSpace[] => {
        if (!spaces) {
            return []
        }

        // [ORG-1354] - Always put booths in the bottom of the timetable below all other spaces.
        return sortBy(spaces, [(space) => space.type === 'booth'])
            // Remove spaces without items
            .filter((space) => space.items.length || space.containers.length)
            // add space props to items
            .map((space): ProgramSpace => ({
                ...space,
                items: space.items.map((item) => ({
                    ...item,
                    space: omit(space, ['containers', 'items'])
                })),
                containers: space.containers.map((item) => ({
                    ...item,
                    space: omit(space, ['containers', 'items']),
                    isContainer: true
                })),
            }))
    },
)
