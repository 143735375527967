import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { startOfDay } from 'date-fns'
import Popup from './Popup'
import { Area as AreaType } from '../types'
import mousePosition from '../../../utils/mousePosition'
import api from '../../../utils/api'
import useSlug from '../../../hooks/useSlug'
import { captureError } from '../../../utils/errorTracker'
import { RetrieveProgramsSpaceResponse, SpaceType } from '../../../types/swagger'
import WidgetArea from './WidgetArea'
import isHoveredStore from './isHoveredStore'
import { popupDataSelector, setPopupData } from '../redux/popupSlice'
import prefetchSpace from '../../../utils/spaces/prefetchSpace'
import isFetchedSelector from '../redux/prefetchedSpaceSelector'
import getPathForSpace from '../../../utils/spaces/getPathForSpace'
import { ParamSlug } from '../../../types'
import { setPosition } from '../redux/positionSlice'
import { showNotification } from '../../notifications'
import { _ } from '../../../utils/localization'
import { updatedSpaceSelector } from '../../spaces/spaceSlice'
import constants from '../../../constants/constants'
import { selectedDateSelector } from '../../timetable/redux/dateSlice'

const S = {
    Area: styled.area`
        border: none;
        outline: none !important;
    `
}
export interface ProgramSpace {
    id?: number;
    name: string;
    slug: string;
    image_url: string;
    event_slug: string;
    type?: SpaceType;
    items?: {
        id?: number;
        name?: string;
        description?: string;
        color?: string;
        start_date?: string | null;
        end_date?: string | null;
        only_for_segments?: number[];
    }[];
    display_name: string;
}

export interface AreaProps {
    area: AreaType
    offsetY?: number
    hoverOn: (animate?: boolean) => void
    hoverOff: () => void
}

const SpaceArea = ({
    area,
    hoverOn,
    hoverOff,
    offsetY,
}: AreaProps) => {
    const eventSlug = useSlug('eventSlug')
    const history = useHistory()
    const [showPopup, setShowPopup] = React.useState(false)
    const [popupPosition, setPopupPosition] = React.useState([])
    const [isPopupHovered, setIsPopupHovered] = React.useState(false)
    const spaceId = area.space
    const popupData = useSelector(popupDataSelector(spaceId))
    const dispatch = useDispatch()
    const space = popupData || {
        id: area.space,
        type: area.space_type as SpaceType,
        name: area.space_name,
        slug: area.space_slug,
        event_slug: eventSlug,
        items: [],
    }
    const params = useParams<ParamSlug>()
    const link = space && getPathForSpace(space, params)
    const isFetched = useSelector(isFetchedSelector(space.type, space.slug))
    const date = useSelector(selectedDateSelector)
    const updatedSpace = useSelector(updatedSpaceSelector(area.space))
    const updatedPublish = updatedSpace ? updatedSpace.published : area.published

    const loadData = () => {
        (async () => {
            if (!isFetched) {
                dispatch(prefetchSpace({ space }))
            }

            // No need to fetch popup data if item has no program
            if (!area.has_program) {
                return
            }

            if (!popupData) {
                const from = startOfDay(date).toISOString()
                const search = new URLSearchParams({
                    from_datetime: from,
                    limit: `${constants.MAX_POPOVER_ITEMS}`,
                }).toString()
                const url = `/events/${eventSlug}/virtualprogram/spaces/${spaceId}/?${search}`
                const { data, error } = await api.safeGet<RetrieveProgramsSpaceResponse>(url)
                if (data) {
                    dispatch(setPopupData(data))
                } else {
                    captureError(`${error}` || 'No Popup Data found')
                }
            }
        })()
    }

    const onPopupMouseEnter = () => {
        // Don't animate because the area is already being shown.
        hoverOn(false)
        setIsPopupHovered(true)
    }

    const onPopupMouseLeave = () => {
        hoverOff()
        setIsPopupHovered(false)
    }

    const onAreaMouseEnter = () => {
        // to preview UX when opening popup on hover
        isHoveredStore.set(area.id, true)
        setTimeout(() => {
            if (isHoveredStore.get(area.id)) {
                setShowPopup(true)
            }
        }, 250)
        setTimeout(() => { setPopupPosition(mousePosition.get()) }, 100)
        hoverOn(true)
        loadData()
    }

    const onAreaMouseLeave = () => {
        // to preview UX when opening popup on hover
        isHoveredStore.set(area.id, false)

        setShowPopup(false)
        hoverOff()
    }

    const onClick = (event) => {
        event.preventDefault()
        dispatch(setPosition({
            position: { x: popupPosition[0], y: popupPosition[1] },
            slug: params.mapSlug,
        }))
        history.push(link)
    }

    const showClosedSpaceNotification = () => {
        dispatch(showNotification({
            type: 'info',
            body: _('map.closed_space'),
            displayDuration: 2000,
        }))
    }

    return (
        <>
            <Popup
                show={showPopup || isPopupHovered}
                space={space}
                position={popupPosition}
                offsetY={offsetY}
                onMouseEnter={onPopupMouseEnter}
                onMouseLeave={onPopupMouseLeave}
                onAreaMouseLeave={onAreaMouseLeave}
                onClickArea={onClick}
                published={updatedPublish}
                showClosedSpaceNotification={showClosedSpaceNotification}
            />
            {/* eslint-disable-next-line */}
            <S.Area
                key={area.id}
                shape={area.shape}
                coords={area.coords.map(Math.round).join(',')}
                alt={`${area.id}`}
                onMouseEnter={onAreaMouseEnter}
                onMouseLeave={onAreaMouseLeave}
                onClick={updatedPublish ? onClick : showClosedSpaceNotification}
                href="#" // To show cursor:pointer in Safari
            />
        </>
    )
}

const Area = (props: AreaProps) => {
    const isSpaceLink = !!props.area.space
    const isWidget = !!props.area.widget

    if (!isSpaceLink && !isWidget) {
        return null
    }

    return isSpaceLink
        ? <SpaceArea {...props} />
        : <WidgetArea {...props} />
}

export default Area
