import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import useSlug from '../../../hooks/useSlug'
import sendToWs from '../../websocket/sendToWs'
import { fetchMeetingRoom, selectMeetingRoom } from '../meetingRoomSlice'
import { currentUserSelector } from '../../auth/authSlice'
import env from '../../../constants/env'
import { MeetingItem } from '../types'
import { User } from '../../auth/types'
import RouteLeavingPrompt from '../../../components/RouteLeavingPrompt'
import {_} from '../../../utils/localization'
import RecordingsButton from '../recording-components/RecordingsButton'

const S = {
    Wrapper: styled.div`
      height: calc(100% - 6px); //subtract a few pixels for the timetable
      width: 100%;
    `,
}

// hosts need to log in, other users can just have their name and a hash sent to Samba
export const getSambaUrl = (
    eventSlug: string,
    meeting: MeetingItem,
    currentUser: User
) => {
    if (!meeting) return

    if (meeting.samba_user_slug) {
        return `${env.sambaUrl}/invite/${meeting.samba_user_slug}`
    }
    const params = new URLSearchParams({
        hash: meeting.samba_hash,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name
    }).toString()
    return `${env.sambaUrl}/live/${env.sambaUser}/${meeting.samba_slug}?${params}`
}

const MeetingRoom = () => {
    const dispatch = useDispatch()
    const currentUser = useSelector(currentUserSelector())
    const eventSlug = useSlug('eventSlug')
    const meetingSpaceSlug = useSlug('meetingSlug')
    const roomSlug = useSlug('meetingRoomSlug')
    const meeting = useSelector(selectMeetingRoom)
    const [sambaUrl, setSambaUrl] = useState('')

    const subscribe = (slug: string) => sendToWs({
        type: 'join_meeting_room',
        slug,
        space_id: meeting?.space_id,
    })

    const unsubscribe = (slug: string) => sendToWs({
        type: 'leave_meeting_room',
        slug,
        space_id: meeting?.space_id,
    })

    useEffect(() => {
        dispatch(fetchMeetingRoom({ eventSlug, meetingSlug: meetingSpaceSlug, roomSlug }))
    }, [dispatch, eventSlug, meetingSpaceSlug, roomSlug])

    useEffect(() => {
        setSambaUrl(getSambaUrl(eventSlug, meeting, currentUser))
    }, [currentUser, eventSlug, meeting, meetingSpaceSlug])

    useEffect(() => {
        if (meeting) {
            dispatch(subscribe(roomSlug))
            return () => {
                dispatch(unsubscribe(roomSlug))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, meeting, roomSlug])

    return (
        <>
            { env.enableRouteLeavePrompt && (
                <RouteLeavingPrompt
                    when
                    confirmText={_('videocall.confirm_leave_question')}
                    okButton={_('videocall.confirm_leave_button_yes')}
                    cancelButton={_('videocall.confirm_leave_button_no')}
                />
            )}

            <S.Wrapper>
                <iframe
                    src={sambaUrl}
                    title="Samba Meeting"
                    allow="microphone *; camera *; autoplay; display-capture;"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    // allowusermedia is added in the samba code, but TS gives an error. Doesn't seem necessary on Firefox
                />
                {
                    meeting && (
                        <RecordingsButton meeting={meeting} />

                    )
                }
            </S.Wrapper>
        </>
    )
}

export default MeetingRoom
