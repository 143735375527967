import Logger from 'js-logger'
import { Action } from 'redux'
import env from '../../constants/env'

const enabled = !env.isProduction

export default () => (next) => (action: Action) => {
    if (enabled) {
        const { type } = action
        Logger.debug('redux: ', type, action)
    }
    return next(action)
}
