import { isAfter } from 'date-fns'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Box } from '@mui/material'
import StyledIcon from '../../../components/Icon'
import colors from '../../../constants/colors'
import { toLocalDateFormat } from '../../../utils/datetime'
import { _ } from '../../../utils/localization'
import Bookmark from '../../goodiebag/Bookmark'
import { GoodiebagType } from '../../goodiebag/types'
import JoinButton from '../../timetable/components/JoinButton'
import PlaybackButton from '../../timetable/components/PlaybackButton'
import Time from '../../timetable/components/Time'
import { Performance } from '../types'
import { Documents } from './Performer'
import useResponsive from '../../../hooks/useResponsive'

interface StyledProps {
    sessionImage?: string
    sessionColor?: string
    maxHeight?: number
    isUsedForGoodiebag?: boolean
}

const S = {
    Wrapper: styled.div<StyledProps>`
        position: relative;
        width: 100%;
        padding: 7px 10px;
        margin-bottom: ${({ isUsedForGoodiebag }) => (isUsedForGoodiebag ? 20 : 10)}px;
        border: 1px solid rgba(176, 190, 197, 0.3);
        box-sizing: border-box;
        border-radius: 4px;
        overflow: auto;
    `,
    TopDiv: styled.div`
        display: flex;
        align-items: center;
    `,
    Image: styled.div<StyledProps>`
        width: 90px;
        height: ${({ isUsedForGoodiebag }) => (isUsedForGoodiebag ? 90 : 60)}px;
        background: ${({ sessionImage, sessionColor }) => (sessionImage ? `url(${sessionImage})` : sessionColor || colors.gray2)};
        background-size: cover;
        background-position: center;
        margin-right: 20px;
        border-radius: ${({ isUsedForGoodiebag }) => (isUsedForGoodiebag ? '50%' : '4px')};
        @media (max-width: 932px) {
            align-self: flex-start;
        }
    `,
    Description: styled.div`
        flex: 1;
        overflow: hidden;
        padding-right: 16px;
    `,
    DescriptionTitle: styled.h3`
        width: 100%;
        font-size: 14px;
        text-transform: capitalize;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `,
    DescriptionFooter: styled.div`
        display: flex;
        width: 100%;
        overflow: hidden;
    `,
    DescriptionFooterItem: styled.div`
        display: flex;
        align-items: center;
        padding-right: 20px;
        font-size: 13px;
        color: #667283;
    `,
    ActionIcons: styled.div`
        display: flex;
        justify-content: flex-end;
        @media (max-width: 932px) {
            flex-direction: column-reverse;
            align-items: flex-end;
        }
    `,
    DetailContent: styled.div<StyledProps>`
        width: 100%;
        max-height: 320px;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid rgba(176, 190, 197, 0.3);
        overflow: auto;
        transform-origin: top;
    `,
    DocumentTitle: styled.h3`
        font-size: 16px;
        font-weight: 500;
        color: #4F555F;
        text-transform: uppercase;
    `,
    MobileButton: styled.div`
        position: absolute;
        top: 72px;
        left: 0;
        & > * {
            font-size: 11px;
        }
    `
}

const RelatedSession = (
    { performance, isUsedForGoodiebag }: {performance: Performance, isUsedForGoodiebag?: boolean}
) => {
    const [expand, setExpand] = useState(false)
    const maxHeight = isUsedForGoodiebag ? 110 : 74
    const isEnded = isAfter(new Date(), new Date(performance?.end_date))
    const {isMobile} = useResponsive()

    return (
        <S.Wrapper isUsedForGoodiebag={isUsedForGoodiebag} maxHeight={maxHeight}>
            <S.TopDiv>
                <S.Image
                    sessionImage={performance.image}
                    sessionColor={performance.color}
                    isUsedForGoodiebag={isUsedForGoodiebag}
                />
                <S.Description>
                    <S.DescriptionTitle>
                        {performance.name}
                    </S.DescriptionTitle>
                    <S.DescriptionFooter>
                        <Box display="flex" flexDirection={{xs: 'column', md: 'row'}}>
                            <S.DescriptionFooterItem>
                                <StyledIcon icon="calendarRange" style={{ margin: '0 3px 3px 0' }} size={18} />
                                {toLocalDateFormat(performance.start_date)}
                            </S.DescriptionFooterItem>
                            <S.DescriptionFooterItem>
                                <StyledIcon icon="clock" style={{ margin: '0 3px 3px 0' }} size={18} />
                                <Time
                                    start_date={performance.start_date}
                                    end_date={performance.end_date}
                                    hideLiveIcon
                                />
                            </S.DescriptionFooterItem>
                            <S.DescriptionFooterItem>
                                <StyledIcon icon="mapMarker" style={{ margin: '0 3px 3px 0' }} size={18} />
                                {performance.virtual_space?.name}
                            </S.DescriptionFooterItem>
                        </Box>
                    </S.DescriptionFooter>
                </S.Description>
                <S.ActionIcons>
                    {isEnded && performance?.playback_url && !isMobile && (
                        <PlaybackButton item={performance} />
                    )}
                    {!isMobile && (<JoinButton item={performance} />)}
                    <Bookmark size={20} item={{ type: GoodiebagType.ProgramItem, id: performance.id }} />
                    <StyledIcon
                        icon="chevronDown"
                        onClick={() => setExpand(!expand)}
                        style={{ transform: expand ? 'rotate(180deg)' : 'rotate(0deg)', transition: '0.3s', marginLeft: 5 }}
                    />
                </S.ActionIcons>
                <S.MobileButton>
                    {isEnded && performance?.playback_url && isMobile && (
                        <PlaybackButton item={performance} />
                    )}
                    {isMobile && (<JoinButton item={performance} />)}
                </S.MobileButton>
            </S.TopDiv>

            {expand && performance.description && (
                <S.DetailContent>
                    <div className="editor-content" dangerouslySetInnerHTML={{ __html: performance.description }} />
                    {performance?.attachments?.length ? (
                        <>
                            <S.DocumentTitle>{_('performer.program_item.documents')}</S.DocumentTitle>
                            <Documents documents={performance.attachments} />
                        </>
                    ) : null}
                </S.DetailContent>
            )}
        </S.Wrapper>
    )
}

export default RelatedSession
