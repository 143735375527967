import { Switch as MuiSwitch, styled } from '@mui/material'
import colors from '../constants/colors'

const Switch = styled(MuiSwitch)(() => ({
    width: 32,
    height: 16,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        paddingLeft: 1,
        paddingTop: 1,
    },
    '& .Mui-checked': {
        transform: 'translateX(16px) !important',
        color: 'white !important',
    },
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        width: 32,
        height: 16,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 10,
            height: 10,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 10,
        height: 10,
        margin: 2,
    },
    '& .Mui-checked+.MuiSwitch-track': {
        backgroundColor: `${colors.midBlue} !important`,
        opacity: '1 !important',
    }
}))

export default Switch
