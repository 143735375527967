import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { RequestStatus } from '../../types'
import { RetrievePosterSpaceResponse, ListPosterOpeningsResponse } from './types'
import api from '../../utils/api'
import { UpdatedPublishedSpace, WS as SpaceWS } from '../spaces/spaceSlice'

interface FetchPosterSpace {
    eventSlug: string
    posterSlug: string
}

export const fetchPosterSpace = createAsyncThunk(
    'FETCH_POSTER_SPACE',
    ({ eventSlug, posterSlug }: FetchPosterSpace) => (
        api.get(`/events/${eventSlug}/posters/${posterSlug}/`)
    )
)

interface FetchPosterList extends FetchPosterSpace{
    search?: string
    limit?: number
}

export const fetchPosters = createAsyncThunk(
    'FETCH_POSTER_LIST',
    ({
        eventSlug, posterSlug, search, limit
    }: FetchPosterList) => (
        api.get(`/events/${eventSlug}/posters/${posterSlug}/list/?search=${search}&limit=${limit}`)
    )
)

const posterSlice = createSlice({
    name: 'posterSlice',
    initialState: {
        selectedPosterId: undefined as number | undefined,
        postersData: {} as ListPosterOpeningsResponse,
        isFullScreen: false as boolean,
        isShowPublicChat: false as boolean,
        space: {} as Record<string, RetrievePosterSpaceResponse>,
        limitPosters: 10 as number,
        requestPostersStatus: 'idle' as RequestStatus,
        requestPosterSpaceStatus: 'idle' as RequestStatus,
    },
    reducers: {
        selectPoster(state, { payload }: PayloadAction<number>) {
            state.selectedPosterId = payload
        },
        toggleFullScreen(state) {
            state.isFullScreen = !state.isFullScreen
        },
        togglePublicChat(state) {
            state.isShowPublicChat = !state.isShowPublicChat
        },
        setLimitPosters(state, { payload }: PayloadAction<number>) {
            state.limitPosters = payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchPosterSpace.fulfilled, (state, { payload }: PayloadAction<RetrievePosterSpaceResponse>) => {
                state.space[payload?.meta?.slug] = payload
                state.requestPosterSpaceStatus = 'succeeded'
            }
        )
        builder.addCase(
            fetchPosterSpace.pending, (state) => {
                state.requestPosterSpaceStatus = 'loading'
            }
        )
        builder.addCase(
            fetchPosterSpace.rejected, (state) => {
                state.requestPosterSpaceStatus = 'failed'
            }
        )
        builder.addCase(
            fetchPosters.fulfilled, (state, { payload }: PayloadAction<ListPosterOpeningsResponse>) => {
                state.postersData = payload
                state.requestPostersStatus = 'succeeded'
                if (!state.selectedPosterId && state.postersData?.results?.length > 0) {
                    state.selectedPosterId = state.postersData.results[0].id
                }
            }
        )
        builder.addCase(fetchPosters.pending, (state) => {
            state.requestPostersStatus = 'loading'
        })
        builder.addCase(fetchPosters.rejected, (state) => {
            state.requestPostersStatus = 'failed'
        })
        builder.addCase(
            SpaceWS.publishedSpaceUpdated,
            (state, { payload }: PayloadAction<UpdatedPublishedSpace>) => {
                if (state.space[payload.space_slug]) {
                    state.space[payload.space_slug].meta.published = payload.published
                }
            }
        )
    }
})

export const selectPoster = posterSlice.actions.selectPoster
export const toggleFullScreen = posterSlice.actions.toggleFullScreen
export const togglePublicChat = posterSlice.actions.togglePublicChat
export const setLimitPosters = posterSlice.actions.setLimitPosters

export const postersSelector = (state: RootState) => state.poster.postersData?.results || []
export const posterIdSelector = (state: RootState) => state.poster.selectedPosterId
export const isFullScreenSelector = (state: RootState) => state.poster.isFullScreen
export const isShowPublicChatSelector = (state: RootState) => state.poster.isShowPublicChat
export const selectPosterSpace = (slug: string) => (state: RootState) => state.poster.space[slug]
export const limitPostersSelector = (state: RootState) => state.poster.limitPosters
export const canLoadMorePostersSelector = (state: RootState) => (
    state.poster.postersData?.count > state.poster.limitPosters
)
export const requestPostersStatusSelector = (state: RootState) => state.poster.requestPostersStatus
export const requestPosterSpaceStatusSelector = (state: RootState) => state.poster.requestPosterSpaceStatus

export default posterSlice.reducer
