import { isBefore } from 'date-fns/esm'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Divider from '../../../../components/Divider'
import Icon from '../../../../components/Icon'
import colors from '../../../../constants/colors'
import useSlug from '../../../../hooks/useSlug'
import { SimpleAppointmentSchedule } from '../../../../types'
import { VirtualAttendee } from '../../../../types/swagger'
import { formatStartAndEndDate } from '../../../../utils/datetime'
import { _ } from '../../../../utils/localization'
import { currentUserSelector } from '../../../auth/authSlice'
import AcceptScheduleButton from './AcceptScheduleButton'
import CancelScheduleButton from './CancelScheduleButton'
import DenyScheduleButton from './DenyScheduleButton'
import RescheduleRequestButton from './RescheduleRequestButton'

export const S = {
    HeaderWrapper: styled.div<{isDenied?: boolean}>`
        min-width: 350px;
        padding: 8px 12px;
        background-color: ${({ isDenied }) => (isDenied ? colors.icon : colors.primary)};
        @media (max-width: 932px) {
            min-width: calc(100vw - 135px);
        }
    `,
    Header: styled.h3`
        margin-bottom: 4px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        color: white;
    `,
    Date: styled.div`
        font-size: 12px;
        color: white;
        opacity: 0.75;
    `,
    Content: styled.div`
        padding: 8px 12px;
        background-color: ${colors.primaryOpacity(0.05)};
    `,
    Subject: styled.div`
        font-weight: 300;
        font-size: 12px;
    `,
    Info: styled.div`
        display: flex;
        align-items: center;
        font-size: 12px;
        color: ${colors.darkTitle};
    `,
    Actions: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    TextButton: styled.div<{ color: string }>`
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: ${({ color }) => color};
        cursor: pointer;

        &:hover {
            filter: brightness(70%);
        }
    `
}

interface Props {
    appointmentSchedule: SimpleAppointmentSchedule
    isSent?: boolean
    sender?: VirtualAttendee
    chatUser?: VirtualAttendee
    message: string
}

const AppointmentScheduleNotification = ({
    appointmentSchedule, isSent, sender, chatUser, message
}: Props) => {
    const eventSlug = useSlug('eventSlug')
    const currentUser = useSelector(currentUserSelector())

    return (
        <>
            <S.HeaderWrapper isDenied={message === 'DENIED' || message === 'CANCELLED'}>
                {message === 'CANCELLED' ? (
                    <S.Header>
                        {_('appointment_schedule.meeting_request_cancelled')}
                    </S.Header>
                ) : (
                    <S.Header>
                        {appointmentSchedule.previous_schedule
                            ? _('appointment_schedule.meeting_reschedule')
                            : _('appointment_schedule.meeting_request')}
                    </S.Header>
                )}
                <S.Date>
                    {formatStartAndEndDate(appointmentSchedule.start_date, appointmentSchedule.end_date)}
                </S.Date>
            </S.HeaderWrapper>
            <S.Content>
                <S.Subject className="text-cut-line-2 message.text">
                    {appointmentSchedule.subject}
                </S.Subject>
                {appointmentSchedule.location && (
                    <>
                        <Divider marginTop={10} marginBottom={8} />
                        <S.Info className="notranslate">
                            <Icon icon="mapMarker" size={16} className="mr-1" />
                            {appointmentSchedule.location}
                        </S.Info>
                    </>
                )}

                {/* Appointment status */}
                {message === 'DENIED' && (
                    <>
                        <Divider marginTop={10} marginBottom={8} />
                        <S.Info>
                            <Icon icon="close" size={16} className="mr-1" color={colors.danger} />
                            {_('appointment_schedule.invitation_is_denied')}
                        </S.Info>
                    </>
                )}
                {message === 'ACCEPTED' && (
                    <>
                        <Divider marginTop={10} marginBottom={8} />
                        <S.Info>
                            <Icon icon="checkCircle" size={16} className="mr-1" color={colors.success} />
                            {_('appointment_schedule.invitation_is_accepted')}
                        </S.Info>
                    </>
                )}
                {message === 'CANCELLED' && (
                    <>
                        <Divider marginTop={10} marginBottom={8} />
                        <S.Info>
                            <Icon icon="calendarRemove" size={16} className="mr-1" color={colors.danger} />
                            {_('appointment_schedule.invitation_is_cancelled')}
                        </S.Info>
                    </>
                )}

                {/* Appointment actions */}
                {appointmentSchedule.status === 'PENDING' && !isSent && (
                    <>
                        <Divider marginTop={10} marginBottom={8} />
                        <S.Actions>
                            <DenyScheduleButton
                                appointmentSchedule={appointmentSchedule}
                                currentUser={currentUser}
                                receiver={sender}
                                eventSlug={eventSlug}
                            />
                            <AcceptScheduleButton
                                appointmentSchedule={appointmentSchedule}
                                currentUser={currentUser}
                                receiver={sender}
                                eventSlug={eventSlug}
                            />
                        </S.Actions>
                    </>
                )}
                {appointmentSchedule.status === 'PENDING' && isSent && (
                    <>
                        <Divider marginTop={10} marginBottom={8} />
                        <S.Actions>
                            <CancelScheduleButton
                                appointmentSchedule={appointmentSchedule}
                                currentUser={currentUser}
                                receiver={chatUser}
                                eventSlug={eventSlug}
                            />
                            <RescheduleRequestButton
                                appointmentSchedule={appointmentSchedule}
                                receiver={chatUser}
                            />
                        </S.Actions>
                    </>
                )}
                {appointmentSchedule.status === 'ACCEPTED' && message === 'ACCEPTED'
                // Only can cancel a appointment that hasn't started yet
                && isBefore(new Date(), new Date(appointmentSchedule.start_date))
                && (
                    <>
                        <Divider marginTop={10} marginBottom={8} />
                        <S.Actions>
                            <div />
                            <CancelScheduleButton
                                appointmentSchedule={appointmentSchedule}
                                currentUser={currentUser}
                                receiver={isSent ? chatUser : sender}
                                eventSlug={eventSlug}
                            />
                        </S.Actions>
                    </>
                )}
            </S.Content>
        </>
    )
}

export default AppointmentScheduleNotification
