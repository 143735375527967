import { useDispatch, useSelector } from 'react-redux'
import { IconOption } from '../../components/Icon/icons'
import { addGoodiebag, removeGoodiebag } from './itemsSlice'
import { Goodiebag } from './types'
import isSavedSelector from './isSavedSelector'
import { showNotification } from '../notifications'
import { _ } from '../../utils/localization'

interface BookmarkHook {
    toggleBookmark: () => void
    isBookmarked: boolean
    bookmarkIcon: IconOption,
}

const useToggleBookmark = (goodiebag: Goodiebag): BookmarkHook => {
    const isBookmarked = useSelector(isSavedSelector(goodiebag))
    const dispatch = useDispatch()
    const toggleBookmark = () => {
        const action = isBookmarked ? removeGoodiebag : addGoodiebag
        dispatch(action({ goodiebag }))
        isBookmarked ? (
            dispatch(showNotification({ type: 'info', body: _('bookmark.unbookmarked'), displayDuration: 3000 }))
        ) : (
            dispatch(showNotification({ type: 'success', body: _('bookmark.bookmarked'), displayDuration: 3000 }))
        )
    }
    return {
        toggleBookmark,
        isBookmarked,
        bookmarkIcon: isBookmarked ? 'savedBookmark' : 'bookmark',
    }
}

export default useToggleBookmark
