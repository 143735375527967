import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { AxiosProvider } from 'react-axios'
import { MatomoProvider } from '@datapunt/matomo-tracker-react'
import Logger from 'js-logger'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-texty/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import App from './App'
import './index.scss'
import env from './constants/env'
import store from './store'
import { axios } from './utils/api'
import BuildInfo from './components/BuildInfo'
import { instance } from './components/MatomoProvider'
import { setupErrorTracking } from './utils/errorTracker'
import { getCurrentUser } from './features/auth/authSlice'
import prefetchEventPreview from './features/events/prefetchEventPreview'
import mockWsReceived from './features/websocket/mockWsReceived'
import { logBuildTime } from './utils/misc'

// eslint-disable-next-line react-hooks/rules-of-hooks
Logger.useDefaults()
logBuildTime()

if (env.isProduction) { // Disable logging in production
    const noop = () => {}
    ['log', 'info', 'table', 'debug', 'warn', 'error'].forEach((method) => {
        console[method] = noop
    })
}

if (env.debug) {
    // @ts-ignore
    window.mockWsReceived = (type = '', payload = {}) => store.dispatch(mockWsReceived(type, payload))
}

setupErrorTracking()
prefetchEventPreview()
store.dispatch(getCurrentUser())

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <>
        <BuildInfo />

        <Provider store={store}>
            {/* @ts-ignore */}
            <MatomoProvider value={instance}>
                <AxiosProvider instance={axios}>
                    <App />
                </AxiosProvider>
            </MatomoProvider>
        </Provider>
    </>
)
