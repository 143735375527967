import React from 'react'
import styled from 'styled-components'
import Toast from 'react-bootstrap/Toast'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { closeNotification } from './notificationsSlice'
import { Notification as NotificationType } from './types'
import { RootState } from '../../store'
import StyledIcon from '../../components/Icon'
import { IconOption } from '../../components/Icon/icons'
import colors from '../../constants/colors'
import styles from '../../constants/styles'
import constants from '../../constants/constants'

const S = {
    NotificationsContainer: styled.div<{top: number}>`
        position: fixed;
        top: ${({top}) => top}px;
        left: 50%;
        transform: translateX(-50%);
        padding: 10px;
        filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.2));
        z-index: ${constants.Z_INDEX.NOTIFICATION};
    `,
    NotificationBody: styled(Toast.Body)`
        max-width: 80vw !important;
        position: relative;
        display: flex;
        align-items: center;
        padding: 8px;
        padding-right: 36px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 4px;
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        color: ${colors.darkTitle};
        @media (max-width: 932px) {
            width: calc(100vw - 10px) !important;
        }
    `,
    IconWrapper: styled.div`
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-radius: 50%;
        background-color: ${({ color }) => color};
        flex-shrink: 0;
    `,
    CloseIcon: styled.div`
        position: absolute;
        top: 50%;
        right: 6px;
        cursor: pointer;
        transform: translateY(-50%);
    `,
}

interface NotificationIconType {
    path: IconOption
    color: string
}

const NotificationIcon = ({ type }: {type: NotificationType['type']}) => {
    const icon = { path: 'info', color: colors.primary } as NotificationIconType
    let paddingBottom = 4

    switch (type) {
    case 'error':
        icon.path = 'close'
        icon.color = colors.danger
        break
    case 'warning':
        icon.path = 'alert'
        icon.color = colors.warning
        break
    case 'success':
        icon.path = 'check'
        icon.color = colors.success
        break
    default:
        icon.path = 'info'
        icon.color = colors.primary
    }

    if (icon.path === 'info') {
        return (
            <S.IconWrapper color="transparent">
                <StyledIcon icon={icon.path} color={icon.color} size={22} />
            </S.IconWrapper>
        )
    }
    if (icon.path === 'alert') {
        paddingBottom = 6
    }

    // padding on Safari is different from the other browsers.
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
        paddingBottom = 2
        if (icon.path === 'alert') {
            paddingBottom = 4
        }
    }

    return (
        <S.IconWrapper color={icon.color} style={{ paddingBottom }}>
            <StyledIcon icon={icon.path} color="white" size={16} />
        </S.IconWrapper>
    )
}

const Notification = ({
    body,
    type,
    id,
    displayDuration = 7000,
    render = () => null,
}: NotificationType) => {
    const ANIMATION_TIME = 1000
    const dispatch = useDispatch()
    const [show, setShow] = React.useState(true)

    // Hide after displayDuration, unmount after displayDuration + animation time
    React.useEffect(() => {
        setTimeout(() => dispatch(closeNotification(id)), displayDuration + ANIMATION_TIME)
        setTimeout(() => {
            if (show) { setShow(false) }
        }, displayDuration)
    })
    return (
        <Toast show={show} onClose={() => setShow(false)}>
            <S.NotificationBody>
                <NotificationIcon type={type} />
                {body}
                {render(setShow)}
                <S.CloseIcon>
                    <StyledIcon icon="close" size={20} onClick={() => setShow(false)} />
                </S.CloseIcon>
            </S.NotificationBody>
        </Toast>
    )
}

const NotificationsContainer = () => {
    const location = useLocation()
    const isOnEnterEvent = location.pathname.includes('/enterevent')
    const top = isOnEnterEvent ? 0 : styles.topNavHeight
    const notifications = useSelector((state: RootState) => state.notification.notifications)

    return (
        <S.NotificationsContainer top={top}>
            { notifications.map((props: NotificationType) => <Notification {...props} key={props.id} />) }
        </S.NotificationsContainer>
    )
}

export default NotificationsContainer
