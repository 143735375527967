import { addSeconds } from 'date-fns/esm'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { addModal } from '../features/modals/modalSlice'
import CloseSpaceWarningModal from '../features/spaces/CloseSpaceWarningModal'
import {
    clearStandingSpace,
    isInSpaceSelector,
    setStandingSpace,
    updatedSpaceSelector
} from '../features/spaces/spaceSlice'
import useHomeLink from './useHomeLink'

const useWatchChangeInSpace = (
    spaceId: number
) => {
    const dispatch = useDispatch()
    const isInSpace = useSelector(isInSpaceSelector)
    const updatedSpace = useSelector(updatedSpaceSelector(spaceId))
    const homeLink = useHomeLink()
    const history = useHistory()

    useEffect(() => {
        dispatch(setStandingSpace(spaceId))
    }, [dispatch, spaceId])

    useEffect(() => {
        if (updatedSpace?.published === false && isInSpace) {
            const timeleft = addSeconds(new Date(), 31)
            // if user is in the space and space's published field changes from true -> false, show warning dialog.
            // Remove users from the space (redirect to home space) after 30 seconds
            dispatch(addModal({
                Component: ({ close }: {close: () => void}) => (
                    <CloseSpaceWarningModal datetime={timeleft} close={close} />
                ),
                padding: 30
            }))
            const closedSpace = setTimeout(() => {
                history.push(homeLink)
            }, 30000)

            return () => {
                clearTimeout(closedSpace)
                dispatch(clearStandingSpace())
            }
        }
    }, [dispatch, history, homeLink, isInSpace, updatedSpace])

    return null
}

export default useWatchChangeInSpace
