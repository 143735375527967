import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import SideBar from '../components/SideBar'
import colors from '../../../constants/colors'
import useSlug from '../../../hooks/useSlug'
import PosterDetail from '../components/PosterDetail'
import {
    isShowPublicChatSelector,
    posterIdSelector,
    selectPosterSpace,
    fetchPosterSpace,
    postersSelector,
    requestPosterSpaceStatusSelector,
} from '../posterSlice'
import PublicChatBar from '../../../components/PublicChatBar'
import { _ } from '../../../utils/localization'
import styles from '../../../constants/styles'
import useHelpContent from '../../../hooks/useHelpContent'
import Videocall from '../../videocall/views/Videocall'
import PosterImagePlaceholder from '../../../assets/Posters_placeholder.jpg'
import useWatchChangeInSpace from '../../../hooks/useWatchChangeInSpace'
import FullPageLoading from '../../../components/FullPageLoading'
import ClosedSpace from '../../../components/ClosedSpace'
import useCloseSpace from '../../../hooks/useCloseSpace'

const getBackground = ({ image }: { image?: string }) => (image
    ? `url(${image})`
    : colors.gray4)

const S = {
    Page: styled.div<{ image?: string }>`
      width: calc(100% - ${styles.sidebarWidth}px);
      height: 100%;
      overflow: hidden;
      background: ${getBackground};
      background-size: cover;
      background-position: center;
      @media (max-width: 1366px) {
        width: calc(100% - ${styles.smallerSidebarWidth}px);
      }
    `,
}

const Posters = () => {
    const eventSlug = useSlug('eventSlug')
    const posterSlug = useSlug('posterSlug')
    const isShowPublicChat = useSelector(isShowPublicChatSelector)
    const selectedPosterId = useSelector(posterIdSelector)
    const requestPosterSpaceStatus = useSelector(requestPosterSpaceStatusSelector)
    const posters = useSelector(postersSelector)
    const poster = posters.find(({ id }) => id === selectedPosterId)
    const space = useSelector(selectPosterSpace(posterSlug))
    const chatId = space?.meta?.chat_id && poster?.chat
    const dispatch = useDispatch()
    const hasData = !!space
    useHelpContent({data: space?.meta?.help_content, type: space?.meta?.help_content_type})

    const closeSpace = useCloseSpace(space?.meta?.published)
    useWatchChangeInSpace(space?.meta?.id)

    useEffect(() => {
        if (!hasData) {
            dispatch(fetchPosterSpace({ eventSlug, posterSlug }))
        }
    }, [dispatch, eventSlug, hasData, posterSlug])

    if (requestPosterSpaceStatus === 'idle' || requestPosterSpaceStatus === 'loading') {
        return <FullPageLoading />
    }

    if (closeSpace) {
        return <ClosedSpace />
    }

    return (
        <Switch>
            <Route path="/:eventSlug/posters/:posterSlug/videocall/:roomId">
                <Videocall />
            </Route>

            <Route>
                <S.Page image={space?.meta?.image || PosterImagePlaceholder}>
                    <PosterDetail posters={posters} chatId={chatId} space={space} />
                    <SideBar />
                    {isShowPublicChat && (
                        <PublicChatBar
                            isShowPublicChat={isShowPublicChat}
                            chatId={chatId}
                            header={_('poster.public_chat.title')}
                        />
                    )}
                </S.Page>
            </Route>
        </Switch>
    )
}

export default Posters
