import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom'
import { _ } from '../../../utils/localization'
import { ModalChildProps } from '../../modals/types'

const LeaveConfirmation = ({ close }: ModalChildProps) => {
    const history = useHistory()
    const confirm = () => {
        close()
        history.goBack()
    }
    return (
        <>
            <Modal.Body>
                <p>{_('videocall.confirm_leave_question')}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={confirm}>{_('videocall.confirm_leave_button_yes')}</Button>
                <Button variant="danger" onClick={close}>{_('videocall.confirm_leave_button_no')}</Button>
            </Modal.Footer>
        </>
    )
}

export default LeaveConfirmation
