import React from 'react'
import styled from 'styled-components'
import { useDrag } from 'react-dnd'
import { useSelector } from 'react-redux'
import { currentUserSelector } from '../../auth/authSlice'
import { ItemTypes } from './Map'
import getMugshotSrc from '../../../utils/getMugshotSrc'

interface ImageProps {
    top: number
    left: number
    mugshot: string
}

export const S = {
    Image: styled.div<ImageProps>`
        position: absolute;
        border: 2px solid white;
        background-color: white;
        cursor: move;
        height: 50px;
        width: 50px;
        border-radius: 120px;
        top: ${({ top }) => top}px;
        left: ${({ left }) => left}px;
        transform: translate(0,0);
        background-image: url(${({ mugshot }) => mugshot});
        background-size: cover;
    `,
}

const CurrentUserIcon = ({ left, top }: { left: number, top: number }) => {
    const currentUser = useSelector(currentUserSelector())
    const [, drag] = useDrag({
        item: { left, top, type: ItemTypes.CURRENT_USER },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    })
    return (
        <S.Image ref={drag} mugshot={getMugshotSrc(currentUser)} top={top} left={left} />
    )
}

export default CurrentUserIcon
