import { useMediaQuery } from 'react-responsive'

const useResponsive = () => {
    const isMobile = useMediaQuery({ maxWidth: 932 })
    const isTablet = useMediaQuery({ minWidth: 933, maxWidth: 1200 })
    const isDesktop = useMediaQuery({ minWidth: 1200 })

    return {
        isMobile, isTablet, isDesktop,
    }
}

export default useResponsive
