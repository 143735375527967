import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import LocationIndicator from '../../../components/LocationIndicator'
import colors from '../../../constants/colors'
import constants from '../../../constants/constants'
import { formatJobInfo } from '../../../utils/profile'
import Bookmark from '../../goodiebag/Bookmark'
import { GoodiebagType } from '../../goodiebag/types'
import { addModal } from '../../modals/modalSlice'
import { Performer as PerformerType } from '../../presentation/types'
import Performer from './Performer'
import useResponsive from '../../../hooks/useResponsive'

const S = {
    PerformerItemRow: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        padding: 8px;
    `,
    Performer: styled.div`
        display: flex;
        align-items: center;
        height: 52px;
        width: 100%;
    `,
    InfoWrapper: styled.div`
        flex-grow: 1;
        display: flex;
        align-items: center;
        overflow: hidden;
    `,
    Image: styled.img`
        flex-shrink: 0;
        width: 52px;
        height: 52px;
        margin-right: 8px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
    `,
    Info: styled.div`
    `,
    Name: styled.h3`
        margin-bottom: 3px;
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: ${colors.darkTitle};
    `,
    Description: styled.div`
        font-weight: 300;
        font-size: 12px;
        color: ${colors.darkTitle};
    `
}

const PerformerRow = ({ item }: {item: PerformerType}) => {
    const dispatch = useDispatch()
    const {isMobile} = useResponsive()

    const showPerformerDetail = (speaker) => {
        const Component = () => <Performer performer={speaker} />
        dispatch(addModal({
            Component, size: 'xl', padding: isMobile ? 0 : 24, showCloseIcon: true
        }))
    }

    return (
        <S.PerformerItemRow onClick={() => showPerformerDetail(item)}>
            <S.Performer>
                <S.InfoWrapper>
                    <S.Image src={item.mugshot || constants.urlThumbnailDefault} />
                    <S.Info>
                        <S.Name className="text-cut">{item.name}</S.Name>
                        <S.Description className="text-cut">{formatJobInfo(item)}</S.Description>
                        {item.location_type && (
                            <LocationIndicator location={item.location_type} />
                        )}
                    </S.Info>
                </S.InfoWrapper>

                <Bookmark item={{ id: item.id, type: GoodiebagType.Speaker }} />
            </S.Performer>
        </S.PerformerItemRow>
    )
}

export default PerformerRow
