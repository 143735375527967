import { isEmpty, map } from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components'
import { _ } from '../utils/localization'
import MultiSelectFilter from './MultiSelectFilter'
import colors from '../constants/colors'
import DropdownFilter from './DropdownFilter'
import { removeEmpty } from '../utils/common'

const S = {
    FilterWrapper: styled.div<{menuHeight?: string}>`
        position: relative;
        height: ${({menuHeight}) => (menuHeight || 'calc(var(--window-height) - 190px)')};
        width: 250px;
        padding: 16px;
        overflow: auto;
        @media (max-width: 932px) {
            width: calc(100vw - 32px);
            padding: 10px;
            overflow: auto;
        }
    `,
    FilterLabel: styled.div`
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        color: ${colors.grayTitleOpacity(0.9)};
        text-transform: capitalize;
    `,
}

const TagsFilter = ({
    tagCategories, value, onChange, menuHeight
}: {tagCategories: any, value: any, onChange: (value: any) => void, menuHeight?: string}) => {
    const [tagsFilter, setTagsFilter] = useState<Record<string, any[]>>(value)
    if (isEmpty(tagCategories)) {
        return null
    }

    return (
        <DropdownFilter hasFilter={!isEmpty(removeEmpty(tagsFilter))}>
            <S.FilterWrapper menuHeight={menuHeight}>
                <div className="title">{_('header.filters')}</div>
                {map(tagCategories, (_value, key) => (
                    <div key={key} onClick={(e) => e.stopPropagation()} className="notranslate">
                        <S.FilterLabel>{key}</S.FilterLabel>
                        <MultiSelectFilter
                            options={_value}
                            selected={tagsFilter[key] || []}
                            onSelect={(v) => {
                                onChange({...tagsFilter, [key]: v})
                                setTagsFilter({
                                    ...tagsFilter,
                                    [key]: v
                                })
                            }}
                            allSelectedText={_('tag_filter.select_all')}
                        />
                    </div>
                ))}
            </S.FilterWrapper>
        </DropdownFilter>
    )
}

export default TagsFilter
