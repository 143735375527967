import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import CompatibilityTestProgress, { SettingItem } from '../../components/CompatibilityTestProgress/CompatibilityTestProgress'
import CustomButton from '../../components/CustomButton'
import { _ } from '../../utils/localization'

const S = {
    Header: styled.div`
        display: flex;
        align-items: flex-start;
        margin-right: 20px;
        margin-bottom: 40px;
        & + button {
            margin-top: -6px!important;
            font-size: 37px;
            font-weight: 400;
            &:focus {
                outline: none;
            }
        }
    `,
    Title: styled.h3`
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px;
    `,
    Actions: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    `
}

const CompatibilityTestModal = ({ close }: { close: () => void }) => {
    const [statuses, setStatuses] = useState<SettingItem[]>([])
    const [statusSummary, setStatusSummary] = useState<SettingItem['status']>('processing')
    let header = _('user_setting.wait_for_checking_header')

    const onCompletion = (_statuses: SettingItem[]) => {
        setStatuses(_statuses)
        if (_statuses.find((e) => e.status === 'failed')) {
            setStatusSummary('failed')
            return
        }
        setStatusSummary('success')
    }

    if (statusSummary === 'failed') {
        header = _('user_setting.failed_checking_header')
    }
    if (statusSummary === 'success') {
        header = _('user_setting.successful_checking_header')
    }
    const errors = statuses.filter((e) => e.status === 'failed')

    return (
        <div>
            <Modal.Header closeButton style={{ padding: 0, border: 'none' }}>
                <S.Header>
                    <S.Title>{header}</S.Title>
                </S.Header>
            </Modal.Header>
            <CompatibilityTestProgress onCompletion={onCompletion} />
            <S.Actions>
                {errors.length > 0 ? (
                    <details>
                        <summary>
                            <span>{_('user_setting.more_about_issue')}</span>
                        </summary>
                        <ul>
                            {errors.map((e) => (
                                <li>{`${e.label}: ${e.message}`}</li>
                            ))}
                        </ul>
                    </details>
                ) : <div />}
                <CustomButton variant="secondary" onClick={close}>
                    {_('button.cancel')}
                </CustomButton>
            </S.Actions>
        </div>
    )
}

export default CompatibilityTestModal
