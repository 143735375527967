import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import colors from '../../../constants/colors'
import styles from '../../../constants/styles'
import { GoodiebagCategory, GoodiebagType } from '../types'
import { categorySelector, selectCategory } from '../categorySlice'
import { numberOfItemsSelector } from '../itemsSlice'
import getTitle from '../getTitle'
import { _ } from '../../../utils/localization'
import { selectEventFiles } from '../eventFilesSlice'
import constants from '../../../constants/constants'
import { selectGroupSessions } from '../../groupsessions/groupSessionsSlice'

const S = {
    Sidebar: styled.div`
        position: fixed;
        top: ${styles.topNavHeight}px;
        right: 0;
        width: 230px;
        height: 100%;
        background-color: white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);  
    `,
    SidebarTop: styled.div`
        width: 100%;
        height: 70px;
        background-color: ${colors.mineShaft};
        color: white;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 20px;
        padding-left: 10px;
        line-height: 70px;
    `,
    Item: styled.div`
        padding: 10px;
        font-weight: 500;
        color: rgba(79, 85, 95, 0.8);
        cursor: pointer;
        &:hover, &.active {
            background-color: #ececec;
            color: #4F555F;
        }
    `
}

const categoryToType = (category: GoodiebagCategory): GoodiebagType => category as unknown as GoodiebagType
const typeToCategory = (type: GoodiebagType): GoodiebagCategory => type as unknown as GoodiebagCategory

const Item = ({ type }: { type: GoodiebagType }) => {
    const category = useSelector(categorySelector)
    const amount = useSelector(numberOfItemsSelector(type))
    const dispatch = useDispatch()
    return (
        <S.Item
            className={categoryToType(category) === type ? 'active' : ''}
            onClick={() => dispatch(selectCategory(typeToCategory(type)))}
        >
            {_(getTitle(typeToCategory(type)))}
            {` (${amount || 0})`}
        </S.Item>
    )
}

const EventFiles = () => {
    const category = useSelector(categorySelector)
    const eventFiles = useSelector(selectEventFiles)
    const dispatch = useDispatch()
    return (
        <S.Item
            className={category === GoodiebagCategory.File ? 'active' : ''}
            onClick={() => dispatch(selectCategory(GoodiebagCategory.File))}
        >
            {_(getTitle(GoodiebagCategory.File))}
            {` (${eventFiles?.count || 0})`}
        </S.Item>
    )
}

// todo remove everything related to group sessions if we're sure we won't need to re-add it
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GroupSessions = () => {
    const category = useSelector(categorySelector)
    const groupSessions = useSelector(selectGroupSessions)
    const dispatch = useDispatch()
    return (
        <S.Item
            className={category === GoodiebagCategory.GroupSession ? 'active' : ''}
            onClick={() => dispatch(selectCategory(GoodiebagCategory.GroupSession))}
        >
            {_(getTitle(GoodiebagCategory.GroupSession))}
            {` (${groupSessions?.length || 0})`}
        </S.Item>
    )
}

const SideBar = () => {
    const isLiteVersion = constants.isLite

    return (
        <S.Sidebar>
            <S.SidebarTop>{_('goodiebag.sidebar.categories')}</S.SidebarTop>
            <div>
                <EventFiles />
                <Item type={GoodiebagType.Speaker} />
                <Item type={GoodiebagType.ProgramItem} />
                <Item type={GoodiebagType.Attendee} />
                {!isLiteVersion && <Item type={GoodiebagType.Booth} />}
                {!isLiteVersion && <Item type={GoodiebagType.Poster} />}
                <Item type={GoodiebagType.MeetingExpert} />
            </div>
        </S.Sidebar>
    )
}

export default SideBar
