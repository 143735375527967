import React, { useEffect } from 'react'
import { createInstance, useMatomo } from '@datapunt/matomo-tracker-react'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import env from '../constants/env'
import { currentUserSelector } from '../features/auth/authSlice'
import { eventSelector } from '../features/events/eventSlice'
import useDebounce from '../hooks/useDebounce'

export const instance = createInstance({
    urlBase: 'https://stats.lf8.nl/',
    siteId: 1,
    trackerUrl: 'https://stats.lf8.nl/matomo.php', // optional, default value: `${urlBase}matomo.php`
    disabled: env.isDevelopment,
    heartBeat: {
        active: true, // optional, default value: true
        seconds: 15, // optional, default value: `15
    },
    linkTracking: true,
})

const TrackPage = () => {
    const { trackPageView } = useMatomo()
    const location = useLocation()
    const debouncedTrackPageView = useDebounce(() => trackPageView({}), 500)

    useEffect(() => {
        debouncedTrackPageView()
    }, [location.pathname, debouncedTrackPageView])

    return null
}

// This is a separate component, so currentUser is received after Event component re-renders when logging in
const AttachUIDToMatomo = () => {
    const currentUser = useSelector(currentUserSelector())
    const event = useSelector(eventSelector())
    const { pushInstruction } = useMatomo()

    useEffect(() => {
        if (currentUser && currentUser.id && event) {
            const hideUserId = !event.current_user_is_public && !event.accreditation_active
            const userId = hideUserId ? 'anonymous' : `${currentUser.id}`
            pushInstruction('setUserId', userId)
        }
    }, [currentUser, event, pushInstruction])

    return null
}

// Use react component with useEffect to do side effects
const MatomoProvider = () => {
    const { pushInstruction } = useMatomo()
    useEffect(() => {
        pushInstruction('MediaAnalytics::enableMediaAnalytics')
        pushInstruction('MediaAnalytics::scanForMedia')
    }, [pushInstruction])

    return (
        <>
            <TrackPage />
            <AttachUIDToMatomo />
        </>
    )
}

export default MatomoProvider
