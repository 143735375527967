import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import StyledIcon from '../../../components/Icon'
import CardContainer from './CardContainer'
import { ContentProps } from './GoodiebagContent'
import Bookmark from '../Bookmark'
import { GoodiebagType, PosterData } from '../types'
import colors from '../../../constants/colors'
import { selectPoster } from '../../posters/posterSlice'
import useSlug from '../../../hooks/useSlug'

export const S = {
    Wrapper: styled.div`
        display: flex;
        width: 100%;
        height: 80px;
        margin-bottom: 20px;
        padding: 10px;
        border: 1px solid rgba(176, 190, 197, 0.3);
        border-radius: 4px;
        &:hover {
            background-color: ${colors.gray1};
        }
    `,
    Link: styled(Link)`
        display: flex;
        flex: 1;
        text-decoration: none!important;
    `,
    Image: styled.img`
        width: 90px;
        height: 60px;
        border-radius: 4px;
        object-fit: cover;
        object-position: center;
        margin-right: 20px;
    `,
    PreviewWrapper: styled.div`
        width: 90px;
        height: 60px;
        border-radius: 4px;
        margin-right: 20px;
        overflow: hidden;
    `,
    Content: styled.div`
        margin: auto;
        overflow: hidden;
        flex: 1;
    `,
    Title: styled.h3`
        margin: 0;
        font-weight: 700;
        font-size: 16px;
        color: ${colors.grayTitle};
        max-height: 38px;
    `,
    AuthorName: styled.span`
        font-size: 14px;
        color: ${colors.grayDescription};
    `,
    Actions: styled.div`
        display: flex;
        align-items: center;
    `,
}

const Poster = ({ poster }: { poster: PosterData }) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')

    return (
        <S.Wrapper>
            <S.Link
                to={`/${eventSlug}/posters/${poster.space_slug}`}
                onClick={() => dispatch(selectPoster(poster.id))}
            >
                {poster.preview
                    ? <S.Image src={poster.preview} />
                    : (
                        <S.PreviewWrapper>
                            <embed
                                id="iframepdf"
                                src={`${poster.pdf}#view=FitH&toolbar=0&navpanes=0&zoom-toolbar=0`}
                                title="pdf-file"
                                width="100%"
                                height="100%"
                            />
                        </S.PreviewWrapper>
                    )}

                <S.Content>
                    <S.Title className="text-cut-line-2">{poster.title}</S.Title>
                    <S.AuthorName className="notranslate">{`${poster.author_names}`}</S.AuthorName>
                </S.Content>
            </S.Link>

            <S.Actions>
                <Bookmark
                    item={{ id: poster.id, type: GoodiebagType.Poster }}
                    style={{ margin: '0 20px' }}
                    size={20}
                />
                <a
                    href={poster.pdf}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <StyledIcon icon="download" onClick={() => null} size={20} />
                </a>
            </S.Actions>
        </S.Wrapper>
    )
}

const Posters = ({ items }: ContentProps) => (
    <CardContainer>
        {items.map(({ poster }) => (
            <Poster poster={poster} key={poster.id} />
        ))}
    </CardContainer>
)

export default Posters
