import styled from 'styled-components'
import React from 'react'
import nestCoords from '../nestCoords'
// eslint-disable-next-line import/named
import { AreaProps } from './Area'
import EmbeddedVideo from '../../embeddedvideo/EmbeddedVideo'

interface Spacing {
    top: number
    left: number
    width: number
    height: number
}

export const getAreaEdges = (_coords: number[]) => {
    const coords = nestCoords(_coords)
    const xCoords = coords.map((coord) => coord.x)
    const yCoords = coords.map((coord) => coord.y)
    return {
        xMin: Math.min(...xCoords),
        xMax: Math.max(...xCoords),
        yMin: Math.min(...yCoords),
        yMax: Math.max(...yCoords),
    }
}

const getSpacing = (coords: number[]): Spacing => {
    const edges = getAreaEdges(coords)
    return {
        top: edges.yMin,
        left: edges.xMin,
        width: edges.xMax - edges.xMin,
        height: edges.yMax - edges.yMin,
    }
}

const S = {
    EmbeddedWidgetWrapper: styled.div<Spacing>`
        position: absolute;
        top: ${(props) => props.top}px;
        left: ${(props) => props.left}px;
        width: ${(props) => props.width}px;
        height: ${(props) => props.height}px;
    `,
}

const EmbeddedWidget = ({ area }: AreaProps) => (
    <S.EmbeddedWidgetWrapper {...getSpacing(area.coords)}>
        <EmbeddedVideo url={area.embedded_url} />
    </S.EmbeddedWidgetWrapper>
)

export default EmbeddedWidget
