import { SpaceType } from '../../types/swagger'
import { ParamSlug } from '../../types'
import { captureError } from '../errorTracker'
import isSpaceType from '../isSpaceType'
import { spaceTypeToRoute, spaceTypeToSlug } from './constants'

export interface SpacePathProps {
    type?: SpaceType | string;
    slug?: string;
}

const getPathForSpace = (space: SpacePathProps, currentParams: ParamSlug): string => {
    if (!currentParams.eventSlug) {
        captureError(`getPathForSpace was called without currentParams.eventSlug, received ${JSON.stringify(currentParams)}`)
        return ''
    }

    if (!space || !space.type) {
        return ''
    }

    if (!isSpaceType(space.type)) {
        captureError(`getPathForSpace was called space.type which is not valid, received: ${space.type}`)
        return ''
    }

    const route = spaceTypeToRoute[space.type]
    const params = { ...currentParams, [spaceTypeToSlug[space.type]]: space.slug }
    if (!route) {
        captureError(`Path for space ${space.type}/${space.slug} was not found`)
        return ''
    }

    return route.getPath(params)
}

export default getPathForSpace
