import React from 'react'
import { addModal } from '../../modals/modalSlice'
import RecordingsModal from './RecordingsModal'
import { MeetingItem } from '../types'
import { MeetingRecording } from '../../../types/__generated_swagger'
import RecordingApprovalModal from './RecordingApprovalModal'

export const showRecordingsModal = () => addModal({
    Component: ({ close }: {close: () => void}) => (
        <RecordingsModal close={close} />
    ),
    size: 'xl',
    padding: 0
})

export const showRecordingApprovalModal = (meeting: MeetingItem, recording: MeetingRecording) => addModal({
    Component: ({ close }: { close: () => void }) => (
        <RecordingApprovalModal
            recording={recording}
            meeting={meeting}
            close={close}
        />
    ),
    size: 'lg',
    padding: 20
})
