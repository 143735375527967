import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'

const performerSlice = createSlice({
    name: 'PerformerSlice',
    initialState: {
        isShow: false,
        isExpanding: false,
    },
    reducers: {
        toggleSidebar: (state, { payload }:PayloadAction<boolean>) => {
            if (payload !== undefined) {
                state.isShow = payload
                return
            }
            state.isShow = !state.isShow
        },
        setIsExpanding: (state, { payload }: PayloadAction<boolean>) => {
            state.isExpanding = payload
        },
        closeSidebar: (state) => {
            state.isExpanding = false
            state.isShow = false
        }
    }
})

export const { toggleSidebar, setIsExpanding, closeSidebar } = performerSlice.actions

export const isShowSidebarSelector = (state: RootState) => state.performer.isShow
export const isExpandingSelector = (state: RootState) => state.performer.isExpanding

export default performerSlice.reducer
