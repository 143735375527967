import React from 'react'
import styled from 'styled-components'
import colors from '../../../constants/colors'

const S = {
    Wrapper: styled.span`
        margin-left: 8px;
        width: 20px;
        height: 20px;
        display: flex;
    `,
    RedCircle: styled.span`
      background-color: ${colors.danger};
      border-radius: 99px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bold;
      color: white;
      font-size: 12px;
    `,
}

const AmountOfNotifications = ({ amount, onClick }: { amount: number, onClick?: () => void }) => (
    <S.Wrapper onClick={onClick}>
        {
            amount > 0
                ? <S.RedCircle>{amount > 99 ? '99+' : amount}</S.RedCircle>
                : ''
        }
    </S.Wrapper>
)

export default AmountOfNotifications
