import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../constants/colors'

const S = {
    Wrapper: styled.div`
        position: relative;
        padding-left: 28px;
        user-select: none;
        cursor: pointer;

        input {
            opacity: 0;
            height: 0;
            width: 0;
        }

        input:checked ~ .checkmark {
            background-color: ${colors.checkboxColor};
            border: 1px solid ${colors.checkboxColor};
            &:after {
                display: block;
            }
        }
    `,
    Checkmark: styled.span<{showMinus: boolean}>`
        position: absolute;
        top: 2px;
        left: 0;
        height: 18px;
        width: 18px;
        border: 2px solid ${colors.icon};
        border-radius: 3px;
        background-color: white;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 3px;
            width: 4px;
            height: 9px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }

        ${({ showMinus }) => showMinus && css`
            border: 2px solid ${colors.checkboxColor};
            background-color: ${colors.checkboxColor};
            &:after {
                top: 6px;
                left: 3.5px;
                display: block;
                border: none;
                width: 8px;
                height: 2px;
                background-color: white;
                transform: rotate(0);
            }
        `}
    `,
    Label: styled.span`
        opacity: 0.8;
    `
}

interface Props {
    label: string
    checked: boolean
    className?: string
    onChange?: () => void
    showMinus?: boolean
    preventOnClick?: boolean
}

const Checkbox = ({
    label,
    className,
    checked,
    onChange = () => null,
    showMinus,
    preventOnClick,
}: Props) => {
    const handleChange = (e) => {
        e.stopPropagation()
        !preventOnClick && onChange()
    }
    return (
        <S.Wrapper className={className} onClick={handleChange}>
            <S.Label className="checkbox-label">{label}</S.Label>
            <input type="checkbox" checked={checked} onChange={onChange} />
            <S.Checkmark className="checkmark" showMinus={showMinus} />
        </S.Wrapper>
    )
}

export default Checkbox
