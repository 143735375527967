import React, {
    useState,
    useEffect,
    useRef,
} from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Participant as ParticipantType } from '../useTwilio'
import { setSharingScreenId } from '../videocallSlice'

const S = {
    Video: styled.video`
        width: 100%;
        object-fit: cover;
        height: 100%;
        object-position: 50% 50%;
    `,
}

interface ParticipantProps {
    participant: ParticipantType
    borderRadius?: number
    hidden?: boolean
}

const Participant = ({ participant, borderRadius = 0, hidden = false, }: ParticipantProps) => {
    const dispatch = useDispatch()
    const [videoTracks, setVideoTracks] = useState([])
    const [audioTracks, setAudioTracks] = useState([])

    const videoRef = useRef()
    const audioRef = useRef()

    const trackpubsToTracks = (trackMap) => Array.from(trackMap.values())
        // onderstaande weghalen
        .map((publication: { track: Record<string, unknown> }) => publication.track)
        .filter((track) => track !== null)

    useEffect(() => {
        const trackSubscribed = (track) => {
            if (track.kind === 'video') {
                setVideoTracks((currentVideoTracks) => [...currentVideoTracks, track])
            } else {
                setAudioTracks((currentAudioTracks) => [...currentAudioTracks, track])
            }
        }

        const trackUnsubscribed = (track) => {
            if (track.kind === 'video') {
                setVideoTracks((currentVideoTracks) => currentVideoTracks.filter((v) => v !== track))
            } else {
                setAudioTracks((currentAudioTracks) => currentAudioTracks.filter((a) => a !== track))
            }
        }

        if (!hidden) {
            setVideoTracks(trackpubsToTracks(participant.videoTracks))
        }

        setAudioTracks(trackpubsToTracks(participant.audioTracks))

        participant.on('trackSubscribed', trackSubscribed)
        participant.on('trackUnsubscribed', trackUnsubscribed)

        return () => {
            setVideoTracks([])
            setAudioTracks([])
            participant.removeAllListeners()
        }
    }, [dispatch, participant, hidden])

    // Set screen sharing ID
    useEffect(() => {
        const screenShareTrack = videoTracks[1]
        if (screenShareTrack && participant.identity) {
            dispatch(setSharingScreenId(participant.identity))
        }
    }, [dispatch, participant.identity, videoTracks])

    // Set video track to HTML element
    useEffect(() => {
        if (hidden) {
            return
        }

        const webcamTrack = videoTracks[0]
        if (webcamTrack) {
            webcamTrack.attach(videoRef.current)
            return () => {
                webcamTrack.detach()
            }
        }
    }, [videoTracks, hidden])

    // Set audio track to HTML element
    useEffect(() => {
        const audioTrack = audioTracks[0]
        if (audioTrack) {
            audioTrack.attach(audioTrack.current)
            return () => {
                audioTrack.detach()
            }
        }
    }, [audioTracks])

    return (
        <>
            { !hidden
                && (
                    <S.Video
                        ref={videoRef}
                        autoPlay
                        style={{ borderRadius }}
                    />
                )}
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio ref={audioRef} autoPlay />
        </>
    )
}

export default Participant
