import React from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import colors from '../../constants/colors'
import { _ } from '../../utils/localization'

export const S = {
    Header: styled.h1`
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 46px;
        color: ${colors.darkTitle};
    `,
    Description: styled.p`
        font-weight: 300;
        font-size: 16px;
        color: ${colors.darkTitle};
    `
}

const RemoveAccountSuccessPage = () => (
    <Container style={{ padding: '50px 200px' }}>
        <S.Header>{_('gdpr.remove_user_account.success_header')}</S.Header>
        <S.Description>{_('gdpr.remove_user_account.success_description')}</S.Description>
    </Container>
)

export default RemoveAccountSuccessPage
