import { matchPath } from 'react-router-dom'
import { allRoutes } from '../routes'

const getCurrentRoute = (currentPath: string, routes = allRoutes) => routes
// converts routes with path like ["/path1/", ...]
// to seperate objects like: { path: "/path1/" } ...
    .flatMap((route) => (typeof route.path === 'string'
        ? route
        : route.path.map((path) => ({ ...route, path }))))
    .map((route) => matchPath(currentPath, route))
    .filter((v) => !!v)
    .sort((x, y) => Number(y.isExact) - Number(x.isExact))
    .find((v) => v)

export default getCurrentRoute
