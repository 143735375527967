/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import TimeTable from './components/TimeTable'
import config from './config'
import { getSpaceList } from './redux/timeTableSlice'
import { ParamSlug } from '../../types'
import { RootState } from '../../store'
// eslint-disable-next-line import/named
import { getProgramSpacesForTimeTable } from './redux/selectors'
import { _ } from '../../utils/localization'
import { collapse, expand, selectExpandFooter } from './redux/expandFooterSlice'
import { websocketAction } from '../../utils/redux'
import { useReduxBus } from '../../utils/reduxmiddleware/reduxEventBus'
import { getGroupSessions, getGroupSessionsForTimeTable } from '../groupsessions/groupSessionsSlice'
import { eventSelector } from '../events/eventSlice'
import { getProgramItemForCalendar } from '../calendar/calendarSlice'
import { eventPreviewSelector } from '../auth/eventPreviewSlice'
import { getLocalTimezone, isInBetween } from '../../utils/datetime'
import { selectDate } from './redux/dateSlice'
import Typography from '../../components/Typography'

interface StyledFooterProps {
    height: number
}
interface StyledHeaderTabProps {
    onClick: (e: Event) => void
}

const S = {
    Footer: styled.footer<StyledFooterProps>`
        position: fixed;
        height: ${({ height }) => height}px;
        transition: all .3s ease-in-out;
        bottom: 0;
        left: 0;
        right: 0;
        margin-bottom: 0;
        z-index: 10;
    `,
    HeaderTab: styled.div<StyledHeaderTabProps>`
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: -${config.tabHeight}px;
        padding-bottom: 3px;
        padding-left: 8px;
        padding-right: 8px;
        width: max-content;
        height: ${config.tabHeight}px;
        border-top: black solid;
        // first box shadow is top + right, second is left and a bit smaller
        box-shadow: 3px -3px 2px rgba(0, 0, 0, 0.5),-1px 0 1px rgba(0, 0, 0, 0.5);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: white;
        color: black;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translateX(-50%);
            width: 200vw;
            height: 10px;
            background-color: white;
        }
    `,
    FooterContent: styled.div`
        background-color: white;
        height: auto;
    `,
}

const WS = {
    timetableUpdated: websocketAction('timetable_updated'),
    groupSessionUpdated: websocketAction('group_session_updated'),
}

const TimeTableFooter = ({isMobile}: {isMobile?: boolean}) => {
    const dispatch = useDispatch()
    const contentRef = React.useRef<HTMLDivElement>()
    const [expandedHeight, setExpandedHeight] = React.useState(0)
    const { eventSlug } = useParams<ParamSlug>()
    const date = useSelector(({ timetable }: RootState) => timetable.date)
    const eventData = useSelector(eventSelector())

    const programSpaces = useSelector(getProgramSpacesForTimeTable)
    const groupSessions = useSelector(getGroupSessionsForTimeTable)
    const spaces = useSelector((state: RootState) => state.timetable.spaces)

    const expanded = useSelector(selectExpandFooter)
    const toggleExpand = () => dispatch(expanded ? collapse() : expand())

    const collapsedHeight = 0
    const height = expanded ? expandedHeight : collapsedHeight

    const eventPreview = useSelector(eventPreviewSelector)

    useEffect(() => {
        if (!eventPreview?.first_day_of_program) {
            dispatch(selectDate(new Date()))
            return
        }

        if (isInBetween(
            eventPreview.start_date, eventPreview.end_date, new Date(),
        )) {
            dispatch(selectDate(new Date()))
            return
        }

        dispatch(selectDate(new Date(eventPreview.first_day_of_program)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(eventPreview)])

    useReduxBus(() => {
        dispatch(getSpaceList({ eventSlug, date }))
        dispatch(getProgramItemForCalendar({ eventSlug, date }))
    }, WS.timetableUpdated.type)

    useReduxBus(() => {
        eventData.enable_group_sessions && dispatch(getGroupSessions(eventSlug))
    }, WS.groupSessionUpdated.type)

    React.useLayoutEffect(() => {
        setExpandedHeight(contentRef.current.clientHeight)
    }, [programSpaces, groupSessions])

    React.useEffect(() => {
        if (date) {
            dispatch(getSpaceList({ eventSlug, date }))
            dispatch(getProgramItemForCalendar({ eventSlug, date }))
        }

        eventData.enable_group_sessions && dispatch(getGroupSessions(eventSlug))
    }, [date, dispatch, eventData.enable_group_sessions, eventSlug])

    return (
        <S.Footer height={height} id="timetable-footer">
            <S.HeaderTab onClick={toggleExpand} style={{display: !expanded ? (isMobile ? 'none' : 'flex') : 'flex'}}>
                {_('timetable.timetable')}
                {' - '}
                <Typography className="ml-1">
                    {getLocalTimezone()}
                </Typography>
            </S.HeaderTab>

            <S.FooterContent ref={contentRef}>
                {
                    spaces.status === 'loading'
                        ? <p>Loading...</p>
                        : (
                            <TimeTable
                                programSpaces={programSpaces}
                                groupSessions={groupSessions}
                                expanded={expanded}
                            />
                        )
                }
            </S.FooterContent>
        </S.Footer>
    )
}

export default TimeTableFooter
