import React from 'react'
import CardContainer from './CardContainer'
import { ContentProps } from './GoodiebagContent'
import GoodiebagMeeting from './GoodiebagMeeting'

const MeetingExperts = ({ items }: ContentProps) => (
    <CardContainer>
        {items.map(({ meeting }) => {
            const goodiebagMeeting = {
                id: meeting.id,
                primarySpeakerId: meeting.primary_speaker_id,
                speakerCount: meeting.speaker_count,
                title: meeting.title,
                slug: meeting.slug,
                startsAt: meeting.starts_at,
                endsAt: meeting.ends_at,
                enableHostChat: meeting.enable_host_chat,
                type: 'meeting' as const
            }
            return (
                <GoodiebagMeeting item={goodiebagMeeting} key={meeting.id} />
            )
        })}
    </CardContainer>
)

export default MeetingExperts
