import sum from 'lodash/sum'
import {
    createAsyncThunk,
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import uniqBy from 'lodash/uniqBy'
import { websocketAction } from '../../utils/redux'
import { Chat, Message } from './types'
import api from '../../utils/api'
import {
    ListChatContactsResponse,
    ListPrivateMessagesResponse,
} from '../../types/__generated_swagger'
import { RootState } from '../../store'
import { createEmptyPagination } from '../../utils/pagination'
import { RequestStatus } from '../../types'
import logout from '../auth/logout'
import { raiseError } from '../../utils/errorTracker'

interface SetMessage {
    message: Message
    userId: number
}

interface ReceivedMessage {
    id: number
    text: string
    sent_at: string
    user: number
    appointment_schedule?: any
}

interface NewUnreadCount {
    user_chat_id: number
    new_count: number
}

export const getMessagesThunk = createAsyncThunk(
    'GET_MESSAGES',
    async ({ userId, offset, eventSlug }: { userId: number, offset?: number, eventSlug: string }) => {
        const resp = await api.get<ListPrivateMessagesResponse>(`/events/${eventSlug}/chat/${userId}/messages/?limit=15&offset=${offset}`)
        return { userId, resp }
    },
)

export const getChats = createAsyncThunk(
    'GET_CHATS',
    ({ eventSlug, limit }: {eventSlug: string, limit: number}) => (
        api.get<ListChatContactsResponse>(`/events/${eventSlug}/chat/?limit=${limit}`)
    )
)

export const WS = {
    receiveMessage: websocketAction<ReceivedMessage>('received_private_message'),
    newUnreadCount: websocketAction<NewUnreadCount>('updated_unread_messages_count'),
}

const initialState = {
    search: '',
    isOpened: false,
    currentChatId: undefined as number | undefined,
    idToMessages: {} as Record<number, ListPrivateMessagesResponse>, // userId to messages
    idToChat: {} as Record<number, Chat>, // userId to chat
    chats: {
        limit: 10,
        count: 0,
        requestStatus: 'idle' as RequestStatus
    },
    requestMessagesStatus: 'idle' as RequestStatus,
    selectingChatBot: false,
    selectingBroadcastMessage: false,
}

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        selectChat(state, { payload }: PayloadAction<number>) {
            state.currentChatId = payload
            if (state.idToChat[payload]) {
                state.idToChat[payload].unread = 0
            }
        },
        setUnreadToZero(state, { payload }: PayloadAction<number>) {
            if (state.idToChat[payload]) {
                state.idToChat[payload].unread = 0
            }
        },
        setIsOpened(state, { payload }: PayloadAction<boolean>) {
            state.isOpened = payload
        },
        setSearch(state, { payload }: PayloadAction<string>) {
            state.search = payload
        },
        setChat(state, { payload }: PayloadAction<Chat>) {
            state.idToChat[payload.user_id] = payload
        },
        setMessage(state, { payload }: PayloadAction<SetMessage>) {
            const messages: ListPrivateMessagesResponse = (
                state.idToMessages[payload.userId] || createEmptyPagination()
            )
            messages.results.push(payload.message)
            messages.count += 1
            state.idToMessages[payload.userId] = messages

            state.idToChat[payload.userId] = {
                user_id: payload.userId,
                unread: 0,
                last_sent: (new Date()).toISOString()
            }
        },
        setLimitGetChats: (state, { payload }: PayloadAction<number>) => {
            state.chats.limit = payload
        },
        clearCurrentChatId(state) {
            state.currentChatId = undefined
        },
        clearMessagesOfUser: (state, { payload }: PayloadAction<number>) => {
            state.idToMessages[payload].results = []
        },
        setSelectingChatBot: (state, { payload }: PayloadAction<boolean>) => {
            state.selectingChatBot = payload
        },
        setSelectingBroadcastMessage: (state, { payload }: PayloadAction<boolean>) => {
            state.selectingBroadcastMessage = payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMessagesThunk.fulfilled, (state, { payload }) => {
            let results = [...(state?.idToMessages[payload.userId]?.results || []), ...payload.resp.results]
                // Filter out negative ID's (ids generated in frontend) to fix
                // duplicated messages (See bug ORG-754)
                .filter(({ id }) => id > 0)
            results = uniqBy(results, 'id')
            state.idToMessages[payload.userId] = {
                ...payload.resp,
                results
            }
            state.requestMessagesStatus = 'succeeded'
        })
        builder.addCase(getMessagesThunk.pending, (state) => {
            state.requestMessagesStatus = 'loading'
        })
        builder.addCase(getChats.fulfilled, (state, { payload }) => {
            state.chats.requestStatus = 'succeeded'
            state.chats.count = payload.count
            payload.results.forEach((chat) => {
                state.idToChat[chat?.user_id] = chat
            })
        })
        builder.addCase(getChats.pending, (state) => {
            state.chats.requestStatus = 'loading'
        })
        builder.addCase(WS.receiveMessage, (state, { payload }) => {
            const messages: ListPrivateMessagesResponse = (
                state.idToMessages[payload.user] || createEmptyPagination()
            )
            messages.results.push({
                id: payload.id,
                text: payload.text,
                sent_at: payload.sent_at,
                appointment_schedule: payload.appointment_schedule,
                read: false,
                sent_by_current_user: false,
            })
            if (payload.appointment_schedule) {
                messages.results = messages.results.map((message) => {
                    if (!message.appointment_schedule) {
                        return message
                    }
                    if (
                        payload.appointment_schedule?.previous_schedule
                        && payload.appointment_schedule.status === 'PENDING'
                    ) {
                        if (
                            message.appointment_schedule.id
                            === payload.appointment_schedule.previous_schedule
                        ) {
                            message.appointment_schedule.status = 'UPDATED'
                        }
                        return message
                    }
                    if (
                        message.appointment_schedule.id === payload.appointment_schedule.id
                    ) {
                        message.appointment_schedule.status = payload.appointment_schedule.status
                        return message
                    }
                    return message
                })
            }
            state.idToMessages[payload.user] = messages
            if (!state.idToChat[payload.user]) {
                state.idToChat[payload.user] = {
                    user_id: payload.user,
                    unread: 1,
                    last_sent: (new Date()).toISOString()
                }
            }
        })
        builder.addCase(WS.newUnreadCount, (state, { payload }) => {
            if (!state.idToChat[payload.user_chat_id]) {
                state.idToChat[payload.user_chat_id] = {
                    user_id: payload.user_chat_id,
                    unread: payload.new_count,
                    last_sent: (new Date()).toISOString()
                }
            } else {
                state.idToChat[payload.user_chat_id].unread = payload.new_count
                state.idToChat[payload.user_chat_id].last_sent = (new Date()).toISOString()
            }
        })
        builder.addCase(logout.fulfilled, () => initialState)
    },
})

export const selectCurrentMessages = (state: RootState) => {
    const chatId = state.chat.currentChatId
    const idToMessages = state.chat.idToMessages
    const messages = idToMessages[chatId] || createEmptyPagination()

    // To fix https://sentry.io/organizations/twoppy-bv/issues/2191427758/?project=5394416&query=is%3Aunresolved
    // still log error if we find empty value because we should prevent this case
    const filteredMessages = messages.results.filter((v) => v)
    if (filteredMessages.length < messages.results.length) {
        messages.results = filteredMessages
        raiseError(Error('Empty messages were found in selectCurrentMessages'))
    }

    return messages
}

export const selectTotalUnread = (state: RootState) => (
    sum(Object.values(state.chat.idToChat).map((chat) => chat.unread || 0))
)

export const statusGetChatSelector = (state: RootState) => state.chat.chats.requestStatus
export const limitGetChatSelector = (state: RootState) => state.chat.chats.limit
export const canLoadMoreChatSelector = (state: RootState) => state.chat.chats.count > state.chat.chats.limit
export const idToChatSelector = (state: RootState) => state.chat.idToChat
export const requestMessagesStatus = (state: RootState) => state.chat.requestMessagesStatus
export const selectChatId = (state: RootState) => state.chat.currentChatId
export const selectingChatBotSelector = (state: RootState) => state.chat.selectingChatBot
export const selectingBroadcastSelector = (state: RootState) => state.chat.selectingBroadcastMessage

export const setUnreadToZero = chatSlice.actions.setUnreadToZero
export const selectChat = chatSlice.actions.selectChat
export const setChat = chatSlice.actions.setChat
export const setIsOpened = chatSlice.actions.setIsOpened
export const setMessage = chatSlice.actions.setMessage
export const setLimitGetChats = chatSlice.actions.setLimitGetChats
export const clearCurrentChatId = chatSlice.actions.clearCurrentChatId
export const clearMessagesOfUser = chatSlice.actions.clearMessagesOfUser
export const setSelectingChatBot = chatSlice.actions.setSelectingChatBot
export const setSelectingBroadcastMessage = chatSlice.actions.setSelectingBroadcastMessage

export default chatSlice.reducer
