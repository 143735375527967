import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef } from 'react'
import difference from 'lodash/difference'
import {
    getAttendeesForUsers,
    selectAttendeeMap,
} from './attendeesSlice'
import { VirtualAttendee } from '../../types/swagger'
import useSlug from '../../hooks/useSlug'
import { makeUnique } from '../../utils/arrays'
import useSubscribeAttendees from './useSubscribeAttendees'
import throttle from '../../utils/throttle'

const throttled = (() => {
    const idToAttendees: Record<string, number[]> = {}
    let lastAllIds: number[] = []

    const runActions = throttle((dispatch, eventSlug: string) => {
        const allIds: number[] = Object.values(idToAttendees).flatMap((ids) => ids)
        const toFetch = difference(allIds, lastAllIds)
        lastAllIds = allIds
        if (toFetch.length) {
            dispatch(getAttendeesForUsers({ eventSlug, userIds: makeUnique(toFetch) }))
        }
    }, 500)

    return {
        fetch: (dispatch, eventSlug: string, attendeesIds: number[], id: string) => {
            idToAttendees[id] = attendeesIds.filter((v) => v)
            runActions(dispatch, eventSlug)
        },
    }
})()

const createId = () => `${Math.round(Math.random() * 9999)}`

export default (attendeesIds: number[] = [], subscribeToUpdates = false) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const attendeeMap = useSelector(selectAttendeeMap)
    const idRef = useRef(createId())
    const id = idRef.current

    // Calculate missing Ids
    const attendees: VirtualAttendee[] = attendeesIds.map(
        (_id) => attendeeMap[_id]
    ).filter((v) => v)
    const missingIds = attendeesIds.filter((_id) => !attendeeMap[_id]).filter((v) => v)

    useSubscribeAttendees(subscribeToUpdates ? attendeesIds : [])

    useEffect(() => {
        throttled.fetch(dispatch, eventSlug, missingIds, id)
    }, [dispatch, eventSlug, id, missingIds])

    return attendees || []
}
