import React, { useEffect } from 'react'
import { Container, Form } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import Icon from '../../components/Icon'
import colors from '../../constants/colors'
import styles from '../../constants/styles'
import useDebounce from '../../hooks/useDebounce'
import useSlug from '../../hooks/useSlug'
import { _ } from '../../utils/localization'
import { tagsFilterSelector } from '../networking/networkingSlice'
import AttendeeGridItem from './AttendeeGridItem'
import {
    searchAttendeesSelector,
    selectAttendeeOffset,
    canLoadMoreSelector,
    publicAttendeesSelector,
    attendeesSelector,
    searchAttendees,
    getAttendeeListWithSearch,
    clearOffsetSearch,
    getAttendeeList,
    loadMore,
    toggleSideBar,
} from './attendeesSlice'
import useSubscribeAttendees from './useSubscribeAttendees'

const S = {
    Container: styled(Container)`
        height: calc(var(--window-height) - ${styles.topNavHeight}px);
        overflow: hidden;
    `,
    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        font-size: 24px;
        font-weight: bold;
        color: ${colors.grayTitleOpacity(0.9)};
    `,
    Actions: styled.div`
        display: flex;
        align-items: center;
    `,
    Filter: styled.div`
        position: relative;
        display: flex;
        background: ${colors.iconOpacity(0.05)};
        padding: 16px 10px;
        border-top: 1px solid ${colors.iconOpacity(0.1)};
        border-bottom: 1px solid ${colors.iconOpacity(0.1)};
    `,
    AttendeeList: styled.div`
        height: calc(100% - 240px);
        overflow-y: auto;
        padding-right: 8px;
        & > div {
            height: 100%;
            .infinite-scroll-component__outerdiv,
            .infinite-scroll-component {
                min-height: 100% !important;
            }
        }
    `,
    InputGroup: styled.div`
        position: relative;
        width: 100%;
    `,
    SearchIcon: styled.div`
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-53%);
    `,
    GridTemplate: styled.div`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    `,
    FilterWrapper: styled.div`
        position: relative;
        margin-right: -12px;
    `,
    Back: styled.div`
        margin-top: 30px;
        margin-bottom: -10px;
        text-transform: uppercase;
        font-size: 16px;
        color: ${colors.icon};
        cursor: pointer;
    `
}

const AttendeesGridList = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const eventSlug = useSlug('eventSlug')
    const search = useSelector(searchAttendeesSelector)
    const tagsFilter = useSelector(tagsFilterSelector)
    const hasSearchOrTagsFilter = !!search || !!tagsFilter
    const offset = useSelector(selectAttendeeOffset(hasSearchOrTagsFilter))
    const canLoadMore = useSelector(canLoadMoreSelector(hasSearchOrTagsFilter))
    const publicAttendees = useSelector(publicAttendeesSelector(hasSearchOrTagsFilter))
    const attendees = useSelector(attendeesSelector(hasSearchOrTagsFilter))
    useSubscribeAttendees(attendees.map((attendee) => attendee.id))
    const debounceSearch = useDebounce((value) => dispatch(searchAttendees(value)), 400)
    const handleSearch = (e) => {
        debounceSearch(e.target.value)
    }

    // Fetch attendee list
    useEffect(() => {
        if (search || tagsFilter) {
            dispatch(
                getAttendeeListWithSearch({
                    eventSlug,
                    offset,
                    search,
                })
            )
            return
        }
        dispatch(clearOffsetSearch())
        dispatch(getAttendeeList({ eventSlug, offset }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, eventSlug, search, offset, tagsFilter?.toString?.()])

    const back = () => {
        dispatch(toggleSideBar())
        history.goBack()
    }

    return (
        <S.Container>
            <S.Back onClick={back}>
                <Icon icon="chevronDown" style={{ transform: 'rotate(90deg)' }} />
                Back
            </S.Back>
            <S.Header>
                {_('attendee_list.header')}
            </S.Header>
            <S.GridTemplate style={{marginBottom: 32}}>
                <S.InputGroup>
                    <Form.Control
                        placeholder={_('attendee_sidebar.search_placeholder')}
                        onChange={handleSearch}
                    />
                    <S.SearchIcon>
                        <Icon icon="search" size={22} />
                    </S.SearchIcon>
                </S.InputGroup>
            </S.GridTemplate>
            <S.AttendeeList id="infinite-scroll-attendee">
                <InfiniteScroll
                    dataLength={publicAttendees.length}
                    next={() => dispatch(loadMore(hasSearchOrTagsFilter))}
                    hasMore={canLoadMore}
                    loader={<p className="ml-3">Loading...</p>}
                    scrollableTarget="infinite-scroll-attendee"
                >
                    <S.GridTemplate>
                        {publicAttendees.map((attendee) => (
                            <AttendeeGridItem attendee={attendee} key={attendee.id} />
                        ))}
                    </S.GridTemplate>
                </InfiniteScroll>
            </S.AttendeeList>
        </S.Container>
    )
}

export default AttendeesGridList
