import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Booth, SwipeDirection } from '../../types'
import StandLarge from './StandLarge'
import StandMedium from './StandMedium'
import StandSmall from './StandSmall'

const S = {
    Container: styled.div`
        display: block;
        width: 100%;
        height: 380px;
        margin: auto;
        padding: 10px 20px 20px;
        padding-bottom: 0;
        text-align: center;
    `,
    CustomImage: styled.img`
        width: calc(100% - 62px);
        height: 100%;
        object-fit: cover;
        object-position: center;
    `,
}

export enum BoothTypes {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export interface BoothPreviewProps {
    background: string
    color: string
    logo: string
    video?: { url?: string }
    slug?: string
}

interface Props {
    booth: Booth
    onSwipe: (direction: SwipeDirection) => void
}

const BoothPreview = ({ booth, onSwipe }: Props) => {
    const props: BoothPreviewProps = {
        background: booth.image,
        logo: booth.booth_logo_url,
        color: booth.color,
        video: booth.videos.find((v) => v),
        slug: booth.slug
    }
    const [touchStart, setTouchStart] = React.useState(0)
    const [touchEnd, setTouchEnd] = React.useState(0)

    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX)
    }

    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX)
    }

    const handleTouchEnd = () => {
        if (touchStart - touchEnd > 150) {
            onSwipe(SwipeDirection.RIGHT)
        }

        if (touchStart - touchEnd < -150) {
            onSwipe(SwipeDirection.LEFT)
        }
    }

    const Stand = useMemo(() => {
        switch (booth.type) {
        case BoothTypes.SMALL:
            return StandSmall
        case BoothTypes.MEDIUM:
            return StandMedium
        case BoothTypes.LARGE:
            return StandLarge
        default:
            return StandLarge
        }
    }, [booth.type])
    const customImage = booth.custom_booth_url

    return (
        <S.Container
            className="flex-grow-0 flex-grow-0"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
        >
            {customImage ? (
                <S.CustomImage src={customImage} alt="custom-booth" />
            ) : (
                <Stand {...props} />
            )}
        </S.Container>
    )
}

export default BoothPreview
