import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Goodiebag, GoodiebagType, isType } from './types'
import {
    CreateGoodiebagItemRequest,
    CreateGoodiebagItemResponse,
    GoodiebagResultExcludeRelated,
    ListGoodiebagItemsResponseExcludeRelated,
} from '../../types/swagger'
import api from '../../utils/api'
import { selectEventSlug } from '../events/eventSlice'
import { RootState } from '../../store'
import { raiseError } from '../../utils/errorTracker'

const itemToGoodiebag = (item: GoodiebagResultExcludeRelated): Goodiebag => {
    if (!isType(item.type)) {
        raiseError(`item.type ${item.type} is not valid GoodiebagType`)
        return
    }
    return {
        id: item.item_id,
        type: item.type,
    }
}

export const fetchGoodiebags = createAsyncThunk(
    'FETCH_GOODIEBAGS',
    async ({ eventSlug }: { eventSlug: string }, thunkAPI) => {
        const url = `events/${eventSlug}/goodiebag/?exclude_related=1`
        const resp = await api.get<ListGoodiebagItemsResponseExcludeRelated>(url)
        const goodiebags = resp.results
        const response = goodiebags.map(itemToGoodiebag)
        thunkAPI.dispatch(itemsSlice.actions.setGoodiebags(response))
        return response
    }
)

export const addGoodiebag = createAsyncThunk(
    'ADD_GOODIEBAG',
    ({ goodiebag }: { goodiebag: Goodiebag }, thunkAPI) => {
        const eventSlug = selectEventSlug(thunkAPI.getState() as RootState)
        thunkAPI.dispatch(itemsSlice.actions.addGoodiebag(goodiebag))
        const data: CreateGoodiebagItemRequest = {
            item_id: goodiebag.id,
            item_type: goodiebag.type
        }
        return api.post<CreateGoodiebagItemResponse>(`events/${eventSlug}/goodiebag/`, data)
    }
)

export const removeGoodiebag = createAsyncThunk(
    'REMOVE_GOODIEBAG',
    ({ goodiebag }: { goodiebag: Goodiebag }, thunkAPI) => {
        thunkAPI.dispatch(itemsSlice.actions.removeGoodiebag(goodiebag))
        const eventSlug = selectEventSlug(thunkAPI.getState() as RootState)
        const url = `events/${eventSlug}/goodiebag/${goodiebag.type}/${goodiebag.id}/`
        return api.delete<CreateGoodiebagItemResponse>(url)
    }
)

export const identifier = (goodiebag: Goodiebag) => `${goodiebag.id}_${goodiebag.type}`

const itemsSlice = createSlice({
    name: 'goodiebag',
    initialState: {} as Record<string, Goodiebag>,
    reducers: {
        setGoodiebags: (state, { payload }: PayloadAction<Goodiebag[]>) => {
            state = {}
            payload.forEach((goodiebag) => {
                state[identifier(goodiebag)] = goodiebag
            })
            return state
        },
        addGoodiebag: (state, { payload }: PayloadAction<Goodiebag>) => {
            state[identifier(payload)] = payload
        },
        removeGoodiebag: (state, { payload }: PayloadAction<Goodiebag>) => {
            delete state[identifier(payload)]
        },
    },
})

export const numberOfItemsSelector = (_type: GoodiebagType) => (state: RootState) => (
    Object.values(state.goodiebag.items).filter(({ type }) => type === _type).length
)

export const favoritesSelector = (state: RootState) => state.goodiebag.items

export default itemsSlice.reducer
