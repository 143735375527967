import { useEffect, useRef } from 'react'

// Reference Link - https://usehooks.com/usePrevious/

export default function usePrevious(value, ignoreUpdateNull?: boolean) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef()

    // Store current value in ref
    useEffect(() => {
        if (ignoreUpdateNull && !value) {
            return
        }

        ref.current = value
    }, [ignoreUpdateNull, value]) // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current
}
