import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import env from '../../constants/env'
import NotFound from '../../components/NotFound'

const EventOverview = () => {
    const history = useHistory()
    const [slug, setSlug] = React.useState('virtualtest')
    const { user, isLoggedIn } = useSelector((state: RootState) => state.auth)
    const onSubmit = (e) => {
        e.preventDefault()
        history.push(`${slug}`)
    }

    if (!env.debug) {
        return <NotFound />
    }

    return (
        <form onSubmit={onSubmit} style={{ margin: '200px' }}>
            {isLoggedIn ? (
                <h3>
                    Hello,
                    {`${user.first_name} ${user.last_name}`}
                </h3>
            ) : <a href="/login">Login</a>}
            <h1>Go to Event</h1>
            <h5>Type event slug</h5>
            <input onChange={(e) => setSlug(e.target.value)} />
            <button type="submit">Go</button>
        </form>
    )
}

export default EventOverview
