import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import colors from '../../../constants/colors'
import Bookmark from '../../goodiebag/Bookmark'
import { GoodiebagType } from '../../goodiebag/types'
import { ProgramItem } from '../../timetable/types'
import NoImage from '../../../assets/no-image.png'
import SponsorLogo from '../../../assets/sponsor.svg'
import LocationIndicator from '../../../components/LocationIndicator'
import FetchItemDetail from '../../imagemap/components/FetchItemDetail'
import useSlug from '../../../hooks/useSlug'
import { addModal, closeAllModal } from '../../modals/modalSlice'
import FetchProgramContainerModal from './FetchProgramContainerModal'
import { _ } from '../../../utils/localization'
import Time from '../../timetable/components/Time'

const S = {
    Wrapper: styled.div<{ color: string }>`
        position: relative;
        margin-bottom: 10px;
        padding: 4px 8px;
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-left: 4px solid ${({ color }) => color};
        border-radius: 4px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    `,

    RightSide: styled.div`
        display: flex;
        flex-shrink: 0;
    `,
    Image: styled.img`
        flex-shrink: 0;
        margin-right: 10px;
        width: 56px;
        height: 40px;
        border-radius: 2px;
        object-fit: cover;
        object-position: center;
    `,
    Info: styled.div`
        flex-grow: 1;
        overflow: hidden;
    `,
    ProgramName: styled.div`
        padding-right: 16px;
        font-size: 12px;
        color: ${colors.grayDescription};
    `,
    SponsorBadge: styled.img`
        position: absolute;
        top: -6px;
        right: -6px;
        width: 15px;
    `,
}

interface Props {
    item: ProgramItem
    onClick?: () => void
    className?: string
}

const ProgramItemRow = ({ item, onClick, className }: Props) => {
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const showProgramModal = () => {
        // eslint-disable-next-line react/prop-types
        const Component = () => <FetchItemDetail itemId={item.id} eventSlug={eventSlug} />
        dispatch(addModal({ Component }))
    }

    const showContainerModal = () => {
        dispatch(closeAllModal())
        dispatch(addModal({
            // eslint-disable-next-line react/prop-types
            Component: () => <FetchProgramContainerModal id={item.id} />,
            header: _('program.session_block'),
            padding: 0,
        }))
    }

    const handleItemClick = () => {
        if (item.is_container) {
            return showContainerModal()
        }

        return showProgramModal()
    }

    return (
        <S.Wrapper color={item.color} onClick={onClick || handleItemClick} className={className}>
            {item.sponsor && <S.SponsorBadge src={SponsorLogo} />}
            <S.Image src={item.image || item.virtual_image || NoImage} />
            <S.Info>
                <S.ProgramName className="text-cut">{item.name}</S.ProgramName>
                <LocationIndicator location={item.location_type} />
            </S.Info>
            <S.RightSide>
                <Time start_date={item.start_date} end_date={item.end_date} hideLiveIcon />
                <div className="flex-align-center">
                    <Bookmark item={{ id: item.id, type: GoodiebagType.ProgramItem }} style={{ margin: '0 16px'}} />
                </div>
            </S.RightSide>
        </S.Wrapper>
    )
}

export default ProgramItemRow
