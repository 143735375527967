import env from './env'

export default Object.freeze({
    urlThumbnailDefault: 'https://secure.gravatar.com/avatar/8fbf5ffda6230029170d4bf05c9f332a?s=140&d=mm',
    MOBILE_BREAKPOINT: 768,
    isLite: env.isLiteVersion || (
        env.debug && new URLSearchParams(window.location.search).get('liteVersion') === 'true'
    ),
    MAX_POPOVER_ITEMS: 5,
    Z_INDEX: {
        INTRO_VIDEO: 10000,
        MOBILE_WARNING: 10000,
        NETWORK_MONITOR: 9999,
        NOTIFICATION: 9999,
        LOADER: 9999,
        VIDEO: 9999,
        PROFILE_BADGE: 999,

        CALENDAR: 1031,
        ATTENDEE_SIDEBAR: 1030,
    }
})
