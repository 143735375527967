import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../utils/api'
import createRequestSlice from '../../utils/createRequestSlice'

export interface Category {
    id: number;
    name: string;
    num_of_booths?: number
}

export const fetchCategories = createAsyncThunk(
    'FETCHCATEGORYS',
    async ({ eventSlug, mapSlug }: { eventSlug: string, mapSlug: string }) => {
        const url = `events/${eventSlug}/maps/${mapSlug}/booths/categories/?limit=999`
        const { results } = await api.get(url)
        return results
    },
)

const categorySlice = createRequestSlice<Category[]>(fetchCategories, 'categorySlice')

export default categorySlice.reducer
