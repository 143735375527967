import React from 'react'
import styled from 'styled-components'
import colors from '../constants/colors'

interface Props {
    style?: React.CSSProperties,
    padding?: number,
    children?: React.ReactNode,
}

const CardContainer = styled.div<Props>`
    background: #FFFFFF;
    box-shadow: 0px 1px 4px ${colors.mineShaftOpacity(0.25)};
    border-radius: 4px;
    padding: ${({ padding = 0 }) => padding}px;
`

export default CardContainer
