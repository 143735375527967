import React, { memo } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import ListGroup from 'react-bootstrap/cjs/ListGroup'
import colors from '../../../constants/colors'
import { currentUserSelector } from '../../auth/authSlice'
import CallStatus from '../../entervideocall/CallStatus'
import { Videocall } from '../../../types/swagger'
import { joinVideocall } from '../../entervideocall/videocallActions'
import useFetchAttendees from '../../attendees/useFetchAttendees'

const S = {
    VideocallListItem: styled(ListGroup.Item)`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 125%;
      background-color: ${({ $isActive }) => ($isActive ? colors.gray3 : 'white')};
      border: none;
      border-radius: 0!important;
    `,
    Names: styled.div`
      display: flex;
      flex-direction: column;
    `,
    VideocallName: styled.span`
      font-size: 16px;
    `,
    HostName: styled.span`
      font-size: 12px;
      color: ${colors.gray6};
    `,
}

const VideocallListItem = ({ videocall } : {videocall: Videocall}) => {
    const currentUser = useSelector(currentUserSelector())
    const isActive = videocall.users.includes(currentUser.id)
    const dispatch = useDispatch()
    const isAdmin = currentUser.id === videocall.admin_id
    const [fetchedAdmin] = useFetchAttendees([videocall.admin_id])
    const admin = isAdmin ? currentUser : fetchedAdmin
    const amountOfUsers = videocall.users.length

    const handleClickCameraIcon = () => {
        dispatch(joinVideocall(videocall.status, admin, { requestInPopover: true, amountOfUsers }))
    }

    return (
        <S.VideocallListItem $isActive={isActive}>
            <S.Names>
                <S.VideocallName>{videocall.display_name}</S.VideocallName>
                <S.HostName className="notranslate">
                    {`Host: ${fetchedAdmin?.first_name || ''} ${fetchedAdmin?.last_name || ''}`}
                </S.HostName>
            </S.Names>
            <CallStatus
                status={videocall.status}
                amountOfUsers={videocall.users.length}
                onClick={handleClickCameraIcon}
            />
        </S.VideocallListItem>
    )
}

export default memo(VideocallListItem)
