import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import colors from '../../constants/colors'
import { showErrorNotification } from '../../features/notifications/notificationsSlice'
import { _ } from '../../utils/localization'

const S = {
    Wrapper: styled.div<{fade?: boolean}>`
        position: relative;
        display: flex;
        align-items: center;
        height: 44px;
        padding: 12px;
        padding-left: 16px;
        background: ${colors.light};
        border-radius: 4px;
        & > * {
            opacity: ${({fade}) => (fade ? 0.6 : 1)};
        }
    `,
    Text: styled.input`
        flex: 1;
        border: none;
        outline: none;
        background: transparent;
        font-size: 14px;
        color: ${colors.grayTitle};
        min-width: 0;
        &::placeholder {
            opacity: 0.5;
        }
    `,
    Side: styled.div`
        flex-shrink: 0;
    `,
    FilePlaceholder: styled.div`
        position: absolute;
        top: 12px;
        left: 16px;
        width: calc(100% - 56px);
        font-size: 14px;
        color: ${colors.grayTitle};
        z-index: 0;
    `
}

interface Props {
    start?: ReactNode
    end?: ReactNode
    placeholder?: string
    value: string
    onChange: (s: any) => void
    disabled?: boolean
    style?: React.CSSProperties
    type?: React.HTMLInputTypeAttribute
    accept?: string
}

const InputField = ({
    start,
    end,
    placeholder,
    value,
    onChange,
    disabled,
    style,
    type = 'text',
    accept,
}: Props) => {
    const dispatch = useDispatch()
    const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files.length) {
            const file = e.target.files[0].name
            const extension = file.split('.').pop()
            if (accept === 'application/pdf' && extension !== 'pdf') {
                dispatch(showErrorNotification(_('validation.only_accept_pdf')))
                return
            }
            const formData = new FormData()
            formData.append('file', e.target.files[0])
            onChange(formData)
        }
    }
    return (
        <S.Wrapper fade={disabled} style={style}>
            {start && (
                <S.Side>
                    {start}
                </S.Side>
            )}
            {type === 'file' ? (
                <>
                    <S.Text
                        onChange={handleUploadFile}
                        placeholder={placeholder}
                        disabled={disabled}
                        type={type}
                        style={{ opacity: 0, zIndex: 1 }}
                        accept={accept || 'application/pdf, image/*'}
                    />
                    <S.FilePlaceholder style={{opacity: value ? 1 : 0.5}} className="text-cut">
                        {(value && value.split('/').slice(-1).pop()) || placeholder}
                    </S.FilePlaceholder>
                </>
            ) : (
                <S.Text
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={placeholder}
                    disabled={disabled}
                    type={type}
                />
            )}
            {end && (
                <S.Side>
                    {end}
                </S.Side>
            )}
        </S.Wrapper>
    )
}

export default InputField
