import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import Form from 'react-bootstrap/Form'
import { _ } from '../../../utils/localization'
import Icon from '../../../components/Icon'
import colors from '../../../constants/colors'
import Alert from '../../../components/Alert'
import useResponsive from '../../../hooks/useResponsive'

const S = {
    Form: styled(Form)``,
    HorizontalBorder: styled.div`
        height: 100%;
        top: 0;
        bottom: 0;
    `,
    LeftBoarderWrapper: styled.div`
        align-self: stretch;
        display: flex;
        align-items: center;
        margin: 8px 0;
        padding: 1px 12px;
        border-left: ${colors.iconOpacity(0.25)} solid 1px;
    `,
    InputWrapper: styled.div<{disabled?: boolean}>`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 20px 20px 20px;
        border: 1px solid rgba(24, 28, 33, 0.15);
        border-radius: 4px;
        ${({ disabled }) => disabled && css`
            opacity: 0.5;
            cursor: none;
            pointer-events: none;
        `}
        @media (max-width: 932px) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 8px 12px 16px 12px;
            background: ${colors.light};
            margin: 0;
        }
    `,
    Input: styled(Form.Control)`
        border: 0 !important;
        outline: none !important;
        box-shadow: none !important;
        padding: 10px;
        margin-left: 4px;
        margin-right: 4px;
        font-size: 14px;
        font-weight: 400;
        color: ${colors.darkTitle};
        &::placeholder {
            font-size: 14px;
            color: ${colors.iconOpacity(0.5)};
        }
    `,
    CardHeader: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${colors.gray6};
        font-weight: bold;
    `,
    Alert: styled(Alert)`
        margin: 0 20px;
    `,
    MultiLineInput: styled.textarea`
        resize: none;
        width: 100%;
        padding: 12px 16px;
        border: none !important;
        outline: none !important;
        font-size: 14px;
        font-weight: 400;
        color: ${colors.darkTitle};
        border-radius: 4px;
        &::placeholder {
            font-size: 14px;
            color: ${colors.iconOpacity(0.5)};
        }
    `
}

interface MessageInputProps {
    send: (text: string) => void
    isOnline?: boolean
    disabled?: boolean
}

const MessageInput = ({ send, isOnline, disabled }: MessageInputProps) => {
    const {isMobile} = useResponsive()
    const [value, setValue] = React.useState('')
    const inputRef = useRef<HTMLTextAreaElement>(null)
    useEffect(() => {
        !disabled && !isMobile && inputRef.current.focus()
    }, [disabled, isMobile])

    const submit = (event) => {
        if (event) {
            event.preventDefault()
        }

        if (disabled) return

        if (value !== '') {
            send(value)
            setValue('')
        }
    }
    return (
        <S.Form onSubmit={submit}>
            {!isOnline && <S.Alert variant="info" text={_('chat.user_offline_note')} />}
            <S.InputWrapper disabled={disabled}>
                {isOnline ? (
                    <S.Input
                        type="text"
                        placeholder={disabled ? _('chat.can_not_send_message.placeholder') : _('chat.new_message_input.placeholder')}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        ref={inputRef}
                    />
                ) : (
                    <S.MultiLineInput
                        placeholder={_('chat.new_message_input.placeholder')}
                        rows={3}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        ref={inputRef}
                    />
                )}
                <S.LeftBoarderWrapper>
                    <Icon
                        onClick={submit}
                        icon={isOnline ? 'send' : 'emailSend'}
                        className="ma-4"
                        isActive={!!value}
                        activeColor={colors.lightPrimary}
                    />
                </S.LeftBoarderWrapper>
            </S.InputWrapper>
            <input style={{ display: 'none' }} type="submit" />
        </S.Form>
    )
}

export default MessageInput
