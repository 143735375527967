export const removeEmpty = (obj) => (
    Object.entries(obj)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, v]) => {
            if (Array.isArray(v)) {
                return v.length
            }
            return v != null
        })
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
)
