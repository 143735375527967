import React, { ReactNode } from 'react'
import styled from 'styled-components'

const S = {
    Wrapper: styled.div<{disabled: boolean}>`
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        width: 24px;
        height: 24px;
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
        opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
    `,
}

interface Props {
    children: ReactNode
    disabled?: boolean
    onClick?: () => void
}

const IconButton = ({ disabled, children, onClick }: Props) => (
    <S.Wrapper disabled={disabled} onClick={onClick}>
        {children}
    </S.Wrapper>
)

export default IconButton
