import React, { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'
import colors from '../constants/colors'

interface Props {
    fontSize?: number
    fontWeight?: number
    color?: string
    className?: string
    marginBottom?: number
    children: ReactNode
    style?: CSSProperties
    textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase'
}

const S = {
    Typography: styled.p<Props>`
        font-size: ${({ fontSize }) => fontSize}px;
        font-weight: ${({ fontWeight }) => fontWeight};
        color: ${({ color }) => color};
        margin-bottom: ${({ marginBottom }) => marginBottom}px;
        text-transform: ${({ textTransform }) => textTransform};
    `,
}

const Typography = ({
    fontSize = 16,
    fontWeight = 400,
    color = colors.grayTitle,
    textTransform = 'none',
    marginBottom = 0,
    children,
    className = '',
    style
}: Props) => (
    <S.Typography
        className={className}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={color}
        marginBottom={marginBottom}
        textTransform={textTransform}
        style={style}
    >
        {children}
    </S.Typography>
)

export default Typography
