import React, { CSSProperties } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import colors from '../../../constants/colors'
import { showSideBarSelector, toggleSideBar } from '../../../features/attendees/attendeesSlice'
import { closeSidebar as closePerformerSidebar } from '../../../features/performer/performerSlice'
import { changeShowTooltip } from '../../../features/events/fullScreenSlice'
import Icon from '../../Icon'
import { closeCalendar } from '../../../features/calendar/calendarSlice'
import { toggleSidebar as toggleBoothVisitorSidebar } from '../../../features/exhibition/boothVististorsSlice'
import { _ } from '../../../utils/localization'

const S = {
    Wrapper: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        margin-right: 16px;
        border-left: 1px solid ${colors.navDivider};
        border-right: 1px solid ${colors.navDivider};
        cursor: pointer;

        & > span.label {
            margin-left: 10px;
            font-size: 14px;
            color: ${colors.grayTitle};
        }
    `
}

const AttendeesSidebarToggle = ({
    style, showLabel
}: {style?: CSSProperties, showLabel?: boolean}) => {
    const dispatch = useDispatch()
    const showSideBar = useSelector(showSideBarSelector)

    const handleToggleAttendeesSidebar = () => {
        dispatch(changeShowTooltip(false))
        dispatch(closePerformerSidebar())
        dispatch(closeCalendar())
        dispatch(toggleBoothVisitorSidebar(false))

        dispatch(toggleSideBar())
    }

    return (
        <S.Wrapper style={style} onClick={handleToggleAttendeesSidebar}>
            <Icon
                icon="group"
                isActive={!!showSideBar}
            />
            {showLabel && <span className="label">{_('nav.attendee_icon.helper_text')}</span>}
        </S.Wrapper>
    )
}

export default AttendeesSidebarToggle
