import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import { shareScreenStore } from '../useTwilio'
import Icon from '../../../components/Icon'
import colors from '../../../constants/colors'
import { _ } from '../../../utils/localization'

// Took this from here
// https://github.com/twilio/twilio-video.js/blob/146453a512e60da465b6bb10db49eda20cbf686a/lib/media/track/mediatrack.js
const attach = (el, stream) => {
    stream.addTrack(stream.getVideoTracks()[0])
    el.srcObject = stream
    el.autoplay = true
    el.playsInline = true
}

const S = {
    Video: styled.video`
        width: 100%;
        object-fit: contain;
        height: 100%;
        object-position: 50% 50%;
        overflow: hidden;
        background: black;
    `,
    ScreenShareScreen: styled.div`
      width: 100%;
      height: 100%;
      padding-bottom: 58px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    Text: styled.p`
        margin-top: 10px;
        color: ${colors.gray7};
    `,
}

const ScreenShareScreen = () => (
    <S.ScreenShareScreen>
        <Icon
            icon="monitorShare"
            color={colors.gray7}
            size={80}
        />
        <S.Text>{_('videocall.screen_sharing_text')}</S.Text>
    </S.ScreenShareScreen>
)

const MyScreen = () => {
    const videoRef = useRef()
    const isSet = useRef(false)

    useLayoutEffect(() => {
        const handler = setInterval(() => {
            if (shareScreenStore.stream && videoRef?.current && !isSet.current) {
                attach(videoRef.current, shareScreenStore.stream)
                isSet.current = true
                clearInterval(handler)
            }
        }, 333)
    }, [videoRef, isSet])

    return <ScreenShareScreen />
}

export default MyScreen
