import { format, isBefore } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import { KeyedMutator } from 'swr'
import Avatar from '../../../components/Avatar'
import CustomButton from '../../../components/CustomButton'
import Divider from '../../../components/Divider'
import Icon from '../../../components/Icon'
import LocationIndicator from '../../../components/LocationIndicator'
import useSlug from '../../../hooks/useSlug'
import { AppointmentSchedule } from '../../../types/__generated_swagger'
import { _ } from '../../../utils/localization'
import { formatJobInfo, formatName } from '../../../utils/profile'
import useFetchAttendees from '../../attendees/useFetchAttendees'
import { currentUserSelector } from '../../auth/authSlice'
import AcceptScheduleButton from '../../chat/components/appointment_schedule/AcceptScheduleButton'
import CancelScheduleButton from '../../chat/components/appointment_schedule/CancelScheduleButton'
import DenyScheduleButton from '../../chat/components/appointment_schedule/DenyScheduleButton'
import RescheduleRequestButton from '../../chat/components/appointment_schedule/RescheduleRequestButton'
import { S as SForm } from '../AppointmentScheduleModal'
import useFetchBlockedSchedules from '../hooks/useFetchBlockedSchedules'
import { getUser } from './AppointmentRowInList'

interface Props {
    close: () => void
    appointment: AppointmentSchedule
    refresh?: KeyedMutator<AppointmentSchedule[][]>
}

const AppointmentDetailModal = ({ close, appointment, refresh }: Props) => {
    const eventSlug = useSlug('eventSlug')
    const currentUser = useSelector(currentUserSelector())
    const meetingUser = getUser(currentUser, appointment)
    const [meetingUserDetail] = useFetchAttendees([meetingUser.id]) // To get is_online status of the user
    const { mutate, removedMutate } = useFetchBlockedSchedules()
    // Only show actions when the appointment hasn't been started yet
    const shouldShowActions = isBefore(new Date(), new Date(appointment.start_date))

    const cancelCallback = () => {
        removedMutate(appointment.id)
        refresh()
        close()
    }

    const acceptCallback = () => {
        mutate(appointment)
        refresh()
        close()
    }

    const denyOrRescheduleCallback = () => {
        refresh()
        close()
    }

    return (
        <>
            <SForm.Header>
                <SForm.UserInfo>
                    <Avatar src={meetingUser.mugshot} width={80} />
                    <div>
                        <SForm.Name className="notranslate">{formatName(meetingUser)}</SForm.Name>
                        <SForm.JobTitle>{formatJobInfo(meetingUser)}</SForm.JobTitle>
                        <div className="flex-align-center">
                            <LocationIndicator location={appointment.location ? 'IN_PERSON' : 'VIRTUAL'} />
                            <SForm.VerticalDivider />
                            <SForm.DateTime>
                                <Icon icon="timetable" size={18} className="mb-2px mr-1" />
                                {`${format(new Date(appointment.start_date), 'dd MMM yyyy - HH:mm')}
                                    -${format(new Date(appointment.end_date), 'HH:mm')}
                                `}
                                <SForm.TimeZone>
                                    {Intl.DateTimeFormat().resolvedOptions().timeZone}
                                </SForm.TimeZone>
                            </SForm.DateTime>
                        </div>
                    </div>
                </SForm.UserInfo>
                <Icon size={28} icon="close" onClick={close} />
            </SForm.Header>
            <Divider marginTop={16} marginBottom={16} />
            {appointment.subject && (
                <>
                    <div className="light-description">
                        {appointment.subject}
                    </div>
                    <Divider marginTop={16} marginBottom={16} />
                </>
            )}
            {appointment.location && (
                <>
                    <div className="flex-align-center light-description font-weight-normal">
                        <Icon icon="mapMarker" size={16} className="mb-2px" />
                        {appointment.location}
                    </div>
                    <Divider marginTop={16} marginBottom={16} />
                </>
            )}
            {shouldShowActions && (
                <div className="flex-align-center">
                    {appointment.status === 'PENDING' && currentUser?.id === appointment.sender_info.id && (
                        <>
                            <CancelScheduleButton
                                receiver={meetingUserDetail}
                                appointmentSchedule={appointment}
                                currentUser={currentUser}
                                eventSlug={eventSlug}
                                renderCustomButton={() => <CustomButton variant="secondary">{_('button.cancel_meeting')}</CustomButton>}
                                callback={cancelCallback}
                            />
                            <RescheduleRequestButton
                                receiver={meetingUserDetail}
                                appointmentSchedule={appointment}
                                eventSlug={eventSlug}
                                renderCustomButton={() => <CustomButton variant="secondary">{_('button.reschedule')}</CustomButton>}
                                callback={denyOrRescheduleCallback}
                            />
                        </>
                    )}
                    {appointment.status === 'PENDING' && currentUser?.id === appointment.receiver_info.id && (
                        <>
                            <DenyScheduleButton
                                receiver={meetingUserDetail}
                                appointmentSchedule={appointment}
                                currentUser={currentUser}
                                eventSlug={eventSlug}
                                renderCustomButton={() => <CustomButton variant="secondary">{_('button.deny')}</CustomButton>}
                                callback={denyOrRescheduleCallback}
                            />
                            <AcceptScheduleButton
                                receiver={meetingUserDetail}
                                appointmentSchedule={appointment}
                                currentUser={currentUser}
                                eventSlug={eventSlug}
                                renderCustomButton={() => <CustomButton variant="primary">{_('button.accept')}</CustomButton>}
                                callback={acceptCallback}
                            />
                        </>
                    )}
                    {appointment.status === 'ACCEPTED' && (
                        <CancelScheduleButton
                            receiver={meetingUserDetail}
                            appointmentSchedule={appointment}
                            currentUser={currentUser}
                            eventSlug={eventSlug}
                            renderCustomButton={() => <CustomButton variant="secondary">{_('button.cancel_meeting')}</CustomButton>}
                            callback={cancelCallback}
                        />
                    )}
                </div>
            )}
        </>
    )
}

export default AppointmentDetailModal
