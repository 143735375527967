import React from 'react'
import { isAfter } from 'date-fns'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import Divider from '../../../components/Divider'
import colors from '../../../constants/colors'
import Time from '../../timetable/components/Time'
import { ProgramItem } from '../../timetable/types'
import ProgramItemRow from './ProgramItemRow'
import NoImage from '../../../assets/no-image.png'
import SponsorLogo from '../../../assets/sponsor.svg'
import { S as SProgram } from '../../timetable/components/ItemDetails'
import CustomButton from '../../../components/CustomButton'
import { _ } from '../../../utils/localization'
import useSlug from '../../../hooks/useSlug'
import { closeAllModal } from '../../modals/modalSlice'
import { collapse } from '../../timetable/redux/expandFooterSlice'
import { closeCalendar } from '../calendarSlice'
import PlaybackButton from '../../timetable/components/PlaybackButton'
import ReadMoreOrLess from '../../../components/ReadMoreOrLess'

const S = {
    Wrapper: styled.div`
        background-color: white;
    `,
    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 32px 0px 32px;
    `,
    Color: styled.div<{ color: string }>`
        margin-right: 5px;
        width: 10px;
        height: 10px;
        border-radius: 2px;
        background-color: ${({ color }) => color};
    `,
    Title: styled.div`
        font-size: 16px;
        color: ${colors.grayTitle};
    `,
    Time: styled.div`
        font-size: 12px;
        color: ${colors.icon};
    `,
    Content: styled.div`
        padding: 32px;
        padding-top: 16px;
        background-color: ${colors.iconOpacity(0.03)};
    `,
    ContainerContentWrapper: styled.div`
        position: relative;
        display: flex;
        width: 100%;
    `,
    ContainerImage: styled.img`
        flex-shrink: 0;
        margin-right: 8px;
        width: 84px;
        height: 60px;
        object-fit: cover;
        object-position: center;
        border-radius: 2px;
    `,
    ContainerDescription: styled.p`
        margin: 0;
        font-size: 12px;
        line-height: 14px;
        color: ${colors.grayDescription};
        overflow: auto;
    `,
    SponsorLogo: styled.img`
        position: absolute;
        top: 0;
        right: 0;
    `
}

interface Props {
    container: ProgramItem
}

const ProgramContainerModal = ({ container }: Props) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const history = useHistory()
    const isEnded = isAfter(new Date(), new Date(container?.end_date))

    const goToBooth = () => {
        container.sponsor && (
            history.push(`/${eventSlug}/map/${container.sponsor.map_slug}/booth/${container.sponsor.slug}`)
        )
        dispatch(closeAllModal())
        dispatch(collapse())
        dispatch(closeCalendar())
    }

    return (
        <S.Wrapper>
            <S.Header>
                <div className="flex-align-center">
                    <S.Color color={container.color} />
                    <S.Title>
                        {container.name}
                    </S.Title>
                </div>
                <div className="flex-align-center">
                    {isEnded && container?.playback_url && (
                        // @ts-ignore
                        <PlaybackButton item={container} />
                    )}
                    <Time start_date={container.start_date} end_date={container.end_date} />
                </div>
            </S.Header>
            <div style={{ padding: '0 32px'}}>
                <Divider marginBottom={16} marginTop={16} />
            </div>
            <S.Content>
                <S.ContainerContentWrapper>
                    <S.ContainerImage src={container.image || NoImage} />
                    <ReadMoreOrLess content={container.description} isHtmlContent maxHeight={120} />
                    {container.sponsor && <S.SponsorLogo src={SponsorLogo} />}
                </S.ContainerContentWrapper>
                <Divider marginTop={16} />
                {container.sponsor && (
                    <>
                        <SProgram.SponsorSession>
                            <SProgram.SponsorInfo>
                                <SProgram.SponsorLogo src={container.sponsor.logo} />
                                <div>
                                    <SProgram.Header>{container.sponsor.name}</SProgram.Header>
                                    <SProgram.SponsorSubtitle>
                                        {container.sponsor.subtitle}
                                    </SProgram.SponsorSubtitle>
                                </div>
                            </SProgram.SponsorInfo>
                            <CustomButton variant="secondary" onClick={goToBooth}>
                                {_('program.visit_booth')}
                            </CustomButton>
                        </SProgram.SponsorSession>
                        <Divider marginTop={16} />
                    </>
                )}
                {(container.items || []).map((item) => (
                    <ProgramItemRow item={item} key={item.id} />
                ))}
            </S.Content>
        </S.Wrapper>
    )
}
export default ProgramContainerModal
