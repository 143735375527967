import React from 'react'
import CardContainer from './CardContainer'
import { ContentProps } from './GoodiebagContent'
import RelatedSession from '../../performer/view/RelatedSession'

const ProgramItems = ({ items }: ContentProps) => (
    <CardContainer>
        {items.map(({ show }) => (
            <RelatedSession performance={show} key={show.id} isUsedForGoodiebag />
        ))}
    </CardContainer>
)

export default ProgramItems
