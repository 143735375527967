import React from 'react'
import styled from 'styled-components'
import NotSupportedBrowser from '../../assets/browser-not-support.png'
import colors from '../../constants/colors'
import { _ } from '../../utils/localization'

const S = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: var(--window-height);
        overflow: hidden;
    `,
    Image: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 352px;
        height: 352px;
        border-radius: 50%;
        background-color: #F9F9F9;
    `,
    Header: styled.h3`
        margin: 40px 0 20px 0;
        font-weight: 500;
        font-size: 36px;
        text-align: center;
        color: ${colors.darkTitle};
        opacity: 0.75;
    `,
    Description: styled.p`
        font-weight: 300;
        font-size: 14px;
        text-align: center;
        color: ${colors.darkTitle};
        opacity: 0.5;
    `
}

const UnsupportedBrowser = () => (
    <S.Wrapper>
        <S.Image>
            <img src={NotSupportedBrowser} alt="" />
        </S.Image>
        <S.Header>
            {_('unsupported_browser.header')}
        </S.Header>
        <S.Description>
            {_('unsupported_browser.description')}
        </S.Description>
    </S.Wrapper>
)

export default UnsupportedBrowser
