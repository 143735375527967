import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import useTwilio, { Participant as ParticipantType } from '../useTwilio'
import BottomBar from '../components/BottomBar'
import { _ } from '../../../utils/localization'
import DefaultBottomBarContent from '../components/DefaultBottomBarContent'
import {
    selectSpeaker,
    selectIsScreenSharingEnabled,
    selectSharingScreenId,
    selectLayout,
    selectIsSpeaker,
} from '../videocallSlice'
import SharedScreen from '../components/SharedScreen'
import MyScreen from '../components/MyScreen'
import ParticipantsLayout from '../components/ParticipantsLayout'
import SmallParticipantsLayout from '../components/SmallParticipantsLayout'
import FullPageLoading from '../../../components/FullPageLoading'
import Participant from '../components/Participant'
import env from '../../../constants/env'
import RouteLeavingPrompt from '../../../components/RouteLeavingPrompt'
import { RootState } from '../../../store'
import colors from '../../../constants/colors'
import Icon from '../../../components/Icon'

const S = {
    LayoutSelectWrapper: styled.div`
      height: 50px;
      margin-bottom: -50px;
      margin-left: auto;
      padding: 12px;
      z-index: 99;
    `,
    LayoutWrapper: styled.div`
      flex-grow: 1;
      background: lightgray;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      width: 100%;
      overflow: hidden;
    `,
    Room: styled.div`
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      overflow: hidden;
    `,
    LocalParticipantWrapper: styled.div`
      display: flex;
      height: 100%;
      width: 300px;
      border-radius: 16px;
      margin-right: 32px;
      overflow: hidden;
    `,

    WebcamDisabledScreen: styled.div`
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    Text: styled.p`
        margin-top: 10px;
        color: ${colors.gray3};
    `,
}

type EnlargeParticipantLayoutProps = { participants: ParticipantType[], enlarged: string }
const EnlargeParticipantLayout = ({ participants, enlarged }: EnlargeParticipantLayoutProps) => {
    const enlargedParticipant = (
        participants.find((p) => p.identity === enlarged)
    )
    const otherParticipants = participants.filter((p) => p.identity !== enlarged)
    return (
        <>
            {
                enlargedParticipant
                    ? <Participant participant={enlargedParticipant} />
                    : <FullPageLoading text={_('videocall.participant_not_available')} />
            }
            <SmallParticipantsLayout participants={otherParticipants} />
        </>
    )
}

type EnlargeLocalParticipantLayoutProps = {
    participants: ParticipantType[],
    localParticipant: ParticipantType
}
const EnlargeLocalParticipantLayout = ({
    participants,
    localParticipant,
}: EnlargeLocalParticipantLayoutProps) => (
    <>
        <Participant participant={localParticipant} />
        <SmallParticipantsLayout participants={participants} />
    </>
)

type LayoutProps = { participants: ParticipantType[], localParticipant: ParticipantType }
const Layout = ({ participants, localParticipant }: LayoutProps) => {
    const layout = useSelector(selectLayout)
    const speaker = useSelector(selectSpeaker)
    const currentUserIsSpeaker = useSelector(selectIsSpeaker)
    const isSharingMyScreen = useSelector(selectIsScreenSharingEnabled)
    const sharingScreenId = useSelector(selectSharingScreenId)
    const sharingScreenParticipant = sharingScreenId && (
        participants.find((p) => p.identity === sharingScreenId)
    )

    if (isSharingMyScreen) {
        return (
            <>
                <MyScreen />
                <SmallParticipantsLayout participants={participants} />
            </>
        )
    }

    if (sharingScreenParticipant) {
        return (
            <>
                <SharedScreen participant={sharingScreenParticipant} />
                <SmallParticipantsLayout participants={participants} />
            </>
        )
    }

    if (participants.length === 0) {
        return <FullPageLoading text={_('videocall.wait_for_participants')} />
    }

    if (layout === 'speaker' && currentUserIsSpeaker) {
        return (
            <EnlargeLocalParticipantLayout
                participants={participants}
                localParticipant={localParticipant}
            />
        )
    }

    if (speaker && layout === 'speaker') {
        return <EnlargeParticipantLayout participants={participants} enlarged={`${speaker}`} />
    }

    return <ParticipantsLayout participants={participants} />
}

const WebcamDisabledScreen = () => (
    <S.WebcamDisabledScreen>
        <Icon
            icon="videoOff"
            color={colors.gray3}
        />
        <S.Text>{_('videocall.screen_sharing_webcam_disabled')}</S.Text>
    </S.WebcamDisabledScreen>
)

const Videocall = ({ bottomBarContent }: { bottomBarContent?: JSX.Element }) => {
    const twilio = useTwilio()
    const isScreenSharingEnabled = useSelector((state: RootState) => state.videocall?.isScreenSharingEnabled)

    if (!twilio.isSupported) {
        return <p>Browser is not supported</p>
    }

    if (twilio.failedToJoin) {
        return <p>Failed to join room</p>
    }

    return (
        <S.Room>
            <S.LayoutWrapper>
                <Layout
                    localParticipant={twilio.localParticipant}
                    participants={twilio.participants}
                />
            </S.LayoutWrapper>

            { env.enableRouteLeavePrompt && (
                <RouteLeavingPrompt
                    when
                    confirmText={_('videocall.confirm_leave_question')}
                    okButton={_('videocall.confirm_leave_button_yes')}
                    cancelButton={_('videocall.confirm_leave_button_no')}
                />
            )}

            <BottomBar>
                <S.LocalParticipantWrapper>
                    { twilio.localParticipant
                    && (isScreenSharingEnabled
                        ? (<WebcamDisabledScreen />)
                        : (
                            <Participant participant={twilio.localParticipant} />
                        ))}
                </S.LocalParticipantWrapper>
                { bottomBarContent || (
                    <DefaultBottomBarContent />
                ) }
            </BottomBar>
        </S.Room>
    )
}

export default Videocall
