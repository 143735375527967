import React, { useEffect } from 'react'
import { uniqBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import useSlug from '../../hooks/useSlug'
import { _ } from '../../utils/localization'
import AttendeeListItem from '../networking/components/AttendeeListItem'
import {
    selectAttendeeOffset,
    canLoadMoreSelector,
    publicAttendeesSelector,
    attendeesSelector,
    getAttendeeListWithSearch,
    clearOffsetSearch,
    getAttendeeList,
    loadMore,
    toggleSideBar,
    toggleExpandedSidebar,
    expandedSideBarSelector,
    searchAttendeesSelector,
    searchAttendees,
    tagsFilterSelector,
    updateTagsFilter,
} from './attendeesSlice'
import useSubscribeAttendees from './useSubscribeAttendees'
import colors from '../../constants/colors'
import Icon from '../../components/Icon'
import SearchInput from '../../components/SearchInput'
import constants from '../../constants/constants'
import useAttendeeTags from './useAttendeeTags'
import { convertTagsGroupByCategory } from '../calendar/utils'
import CustomButton from '../../components/CustomButton'
import openChatModal from '../chat/openChatModal'
import { setSelectingBroadcastMessage } from '../chat/chatSlice'
import { isEventOwnerSelector } from '../auth/authSlice'
import { VirtualAttendee } from '../../types/swagger'
import TagsFilter from '../../components/TagsFilter'

export const S = {
    Wrapper: styled.div<{ isExpanding: boolean }>`
        position: fixed;
        top: 0;
        right: 0;
        width: ${({ isExpanding }) => (isExpanding ? 600 : 350)}px;
        height: var(--window-height);
        background-color: white;
        box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1);
        z-index: ${constants.Z_INDEX.ATTENDEE_SIDEBAR};
        transition: width 0.3s;
        @media (max-width: 932px) {
            position: absolute;
            width: 100%;
            box-shadow: none;
        }
    `,
    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 16px;
        font-size: 20px;
        font-weight: bold;
        color: ${colors.grayTitleOpacity(0.9)};
    `,
    Actions: styled.div`
        display: flex;
        align-items: center;
        @media (max-width: 932px) {
            & .expand-icon {
                display: none;
            }
        }
        
    `,
    Filter: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        background: ${colors.iconOpacity(0.05)};
        padding: 16px 10px;
        border-top: 1px solid ${colors.iconOpacity(0.1)};
        border-bottom: 1px solid ${colors.iconOpacity(0.1)};
    `,
    AttendeeList: styled.div<{ hasBroadcast?: boolean }>`
        height: calc(100% - ${({ hasBroadcast }) => (hasBroadcast ? 190 : 146)}px);
        padding-top: 10px;
        overflow-y: auto;
        & > div {
            height: 100%;
            .infinite-scroll-component__outerdiv,
            .infinite-scroll-component {
                min-height: 100% !important;
            }
        }
    `,
    ShowAll: styled(Link)`
        display: block;
        height: 32px;
        background-color: #f8f9fa00;
        line-height: 32px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        color: ${colors.icon};
        box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
        user-select: none;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    `,
    SearchInput: styled(SearchInput)<{ isFullWidth?: boolean }>`
        width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'calc(100% - 40px)')};
    `,
    Label: styled.div`
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        color: ${colors.grayTitleOpacity(0.9)};
        text-transform: capitalize;
    `,
    FilterWrapper: styled.div`
        height: calc(var(--window-height) - 138px);
        width: 250px;
        padding: 16px;
        overflow: auto;
    `,
    BroadcastMessage: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 44px;
        padding: 10px;
        font-weight: 500;
        font-size: 14px;
        color: ${colors.darkTitle};
        border-bottom: 1px solid ${colors.inputBorder};
        white-space: nowrap;
    `,
}

interface Props {
    header?: string
    boothSlug?: string
    addedUsers?: VirtualAttendee[]
    removeUserIds?: number[]
    handleClose?: () => void
    hideCloseButton?: boolean
    className?: string
}

const AttendeesSidebar = ({
    header, boothSlug, addedUsers, removeUserIds, handleClose, hideCloseButton, className
}: Props) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const search = useSelector(searchAttendeesSelector)
    const tagsFilter = useSelector(tagsFilterSelector)
    const hasSearchOrTagsFilter = !!search || !!tagsFilter
    const offset = useSelector(selectAttendeeOffset(hasSearchOrTagsFilter))
    const canLoadMore = useSelector(canLoadMoreSelector(hasSearchOrTagsFilter))
    let publicAttendees = useSelector(publicAttendeesSelector(hasSearchOrTagsFilter))
    publicAttendees = uniqBy([...addedUsers, ...publicAttendees], 'id').filter(
        (e) => !removeUserIds.includes(e.id)
    )
    const attendees = useSelector(attendeesSelector(hasSearchOrTagsFilter))
    const tags = useAttendeeTags()
    const tagCategories = convertTagsGroupByCategory(tags)
    const isEventOwner = useSelector(isEventOwnerSelector(eventSlug))

    useSubscribeAttendees([...attendees, ...publicAttendees].map((attendee) => attendee.id))

    const expandedSidebar = useSelector(expandedSideBarSelector)

    // Fetch attendee list when updating filter or search text
    useEffect(() => {
        dispatch(clearOffsetSearch())
        if (search || tagsFilter) {
            dispatch(
                getAttendeeListWithSearch({
                    eventSlug,
                    boothSlug,
                    offset: 0,
                    search,
                    tagsFilter,
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, eventSlug, boothSlug, tagsFilter])

    // Fetch attendee list for infinite scroll to load more
    useEffect(() => {
        if (search || tagsFilter) {
            dispatch(
                getAttendeeListWithSearch({
                    eventSlug,
                    boothSlug,
                    offset,
                    search,
                    tagsFilter,
                })
            )
            return
        }
        dispatch(getAttendeeList({ eventSlug, offset }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, eventSlug, boothSlug, offset])

    const _handleClose = () => {
        dispatch(updateTagsFilter(undefined))
        dispatch(toggleSideBar())
    }

    const openBroadcastMessage = () => {
        dispatch(openChatModal(eventSlug))
        dispatch(setSelectingBroadcastMessage(true))
    }

    return (
        <S.Wrapper isExpanding={expandedSidebar} className={className}>
            <S.Header className="attendee-header">
                <span>{header || _('attendee_sidebar.header')}</span>
                <S.Actions>
                    <Icon
                        className="mr-2 expand-icon"
                        icon={expandedSidebar ? 'collapseHorizontal' : 'expandHorizontal'}
                        onClick={() => dispatch(toggleExpandedSidebar())}
                    />
                    {!hideCloseButton && <Icon icon="close" onClick={handleClose || _handleClose} />}
                </S.Actions>
            </S.Header>
            <S.Filter>
                <S.SearchInput
                    value={search}
                    onSearch={(value) => dispatch(searchAttendees(value))}
                    placeholder={_('attendee_sidebar.search_placeholder')}
                    className="mr-2"
                />
                <TagsFilter
                    tagCategories={tagCategories}
                    value={tagsFilter}
                    onChange={(value) => dispatch(
                        updateTagsFilter(value)
                    )}
                />
            </S.Filter>
            {isEventOwner && (
                <S.BroadcastMessage>
                    <div className="flex-align-center">
                        <Icon icon="broadcastMessage" className="mr-2" />
                        {_('chat.broadcast_message')}
                    </div>
                    <CustomButton variant="secondary" onClick={openBroadcastMessage}>
                        {_('chat.broadcast_message.create_message')}
                    </CustomButton>
                </S.BroadcastMessage>
            )}
            <S.AttendeeList id="infinite-scroll-attendee" hasBroadcast={isEventOwner}>
                <InfiniteScroll
                    dataLength={publicAttendees.length}
                    next={() => dispatch(loadMore(hasSearchOrTagsFilter))}
                    hasMore={canLoadMore}
                    loader={<p className="ml-3">Loading...</p>}
                    scrollableTarget="infinite-scroll-attendee"
                >
                    {publicAttendees.map((attendee) => (
                        <AttendeeListItem attendee={attendee} key={attendee.id} />
                    ))}
                </InfiniteScroll>
            </S.AttendeeList>
        </S.Wrapper>
    )
}

AttendeesSidebar.defaultProps = {
    boothSlug: '',
    addedUsers: [],
    removeUserIds: [],
}

export default AttendeesSidebar
