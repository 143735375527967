import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Message as MessageType } from '../types'
import colors from '../../../constants/colors'
import UserThumbnail from './UserThumbnail'
import { formatTimeAgo } from '../../../utils/datetime'
import useInterval from '../../../hooks/useInterval'
import { _ } from '../../../utils/localization'
import Icon from '../../../components/Icon'
import AppointmentScheduleNotification from './appointment_schedule/AppointmentScheduleNotification'
import { VirtualAttendee } from '../../../types/swagger'
import { eventPreviewSelector } from '../../auth/eventPreviewSlice'
import { selectingChatBotSelector } from '../chatSlice'
import AppointmentScheduleNotificationFromChatBot from './appointment_schedule/AppointmentScheduleNotificationFromChatBot'
import getMugshotSrc from '../../../utils/getMugshotSrc'

interface StyledProps {
    isSent: boolean
    selectingChatBot?: boolean
}

interface MessageProps {
    isSent: boolean
    message: MessageType
    sender: VirtualAttendee
    chatUser: VirtualAttendee
    isSentEmail?: boolean
    isBroadcastMessage?: boolean
}

const getChatBackgroundColor = ({ isSent }: StyledProps) => (!isSent
    ? colors.iconOpacity(0.05)
    : colors.primaryOpacity(0.03))

const getMessageMargin = ({ isSent }: StyledProps) => (!isSent
    ? '8px 80px 8px 16px'
    : '8px 16px 8px 80px'
)

const getBorderRadius = ({ isSent }: StyledProps) => (!isSent
    ? '0px 5px 5px 5px'
    : '5px 0px 5px 5px'
)

const getJustifyText = ({ isSent }: StyledProps) => (!isSent
    ? 'flex-start'
    : 'flex-end')

const getFlexDirection = ({ isSent }: StyledProps) => (!isSent
    ? 'row'
    : 'row-reverse')

const getWidth = ({ selectingChatBot }: StyledProps) => (
    selectingChatBot ? '100%' : 'unset'
)

const S = {
    Message: styled.div<StyledProps>`
        min-height: 68px; // Fix bug can't scroll bottom on safari
        flex-direction: ${getFlexDirection};
        margin: ${getMessageMargin};
        display: flex;
        align-items: start;
    `,

    MessageInner: styled.div<StyledProps>`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: ${getJustifyText};
        margin-left: 8px;
        margin-right: 8px;
        width: ${getWidth};
    `,

    TextBox: styled.div<StyledProps>`
        background-color: ${getChatBackgroundColor};
        color: ${colors.darkTitle};
        justify-content: ${getJustifyText};
        display: flex;
        padding: 8px;
        font-weight: 300;
        font-size: 14px;
        border-radius: ${getBorderRadius};
        width: 100%;
        white-space: pre-line;
    `,

    Date: styled.div<StyledProps>`
        color: ${colors.icon};
        font-size: 10px;
        justify-content: ${getJustifyText};
        display: flex;
        flex-grow: 1;
        padding-top: 5px;
        width: 100%;
    `,
    SentEmail: styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: ${colors.primaryOpacity(0.03)};
        border-radius: 5px 0px 5px 5px;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 10px;
            background-color: #F9FAFF;
            border-radius: 0 0 5px 5px;
        }
        div {
            padding: 8px;
            background-color: ${colors.iconOpacity(0.05)};
            font-weight: 500;
            font-size: 12px;
            text-transform: uppercase;
            color: ${colors.grayDescription};
        }
        q {
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: pre-line;
            padding: 8px;
            font-size: 14px;
            font-style: italic;
            font-weight: 300;
            color: ${colors.grayDescription};
        }
    `,
    ScheduleWrapper: styled.div<StyledProps>`
        border-radius: ${getBorderRadius};
        overflow: hidden;
        width: ${getWidth};
    `,
    Broadcast: styled.div`
        flex-shrink: 0;
        display: grid;
        place-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid ${colors.inputBorder};
    `,
}

const Message = ({
    message, sender, isSent, isSentEmail, chatUser, isBroadcastMessage
}: MessageProps) => {
    const eventPreview = useSelector(eventPreviewSelector)
    const selectingChatBot = useSelector(selectingChatBotSelector)
    useInterval(1000 * 60) // To re-render this component every minute

    if (message.appointment_schedule && eventPreview?.enable_meeting_scheduling) {
        return (
            <S.Message isSent={isSent}>
                {sender?.mugshot && <UserThumbnail src={sender?.mugshot} alt="" />}
                <S.MessageInner isSent={isSent} selectingChatBot={selectingChatBot}>
                    <S.ScheduleWrapper isSent={isSent} selectingChatBot={selectingChatBot}>
                        {selectingChatBot ? (
                            <AppointmentScheduleNotificationFromChatBot
                                appointmentSchedule={message.appointment_schedule}
                            />
                        ) : (
                            <AppointmentScheduleNotification
                                appointmentSchedule={message.appointment_schedule}
                                sender={sender}
                                chatUser={chatUser}
                                isSent={isSent}
                                message={message.text}
                            />
                        )}
                    </S.ScheduleWrapper>
                    <S.Date isSent>
                        {formatTimeAgo(message.sent_at)}
                    </S.Date>
                </S.MessageInner>
            </S.Message>
        )
    }

    return (
        <>
            {isSentEmail && isSent && (
                <S.Message isSent={isSent}>
                    <UserThumbnail src={getMugshotSrc(sender)} alt="" />
                    <S.MessageInner isSent>
                        <S.SentEmail>
                            <div>
                                <Icon icon="email" size={18} className="mr-2 mb-1" />
                                {_('chat.email_was_sent')}
                            </div>
                            <q className="notranslate">{message.text}</q>
                        </S.SentEmail>
                        <S.Date isSent>
                            {formatTimeAgo(message.sent_at)}
                        </S.Date>
                    </S.MessageInner>
                </S.Message>
            )}
            <S.Message isSent={isSent}>
                {(isBroadcastMessage || selectingChatBot) ? (
                    <S.Broadcast>
                        <Icon icon="broadcastMessage" size={18} />
                    </S.Broadcast>
                ) : (
                    <UserThumbnail src={getMugshotSrc(sender)} alt="" />
                )}
                <S.MessageInner isSent={isSent}>
                    <S.TextBox isSent={isSent} className="notranslate">{message.text}</S.TextBox>
                    <S.Date isSent={isSent}>
                        {formatTimeAgo(message.sent_at)}
                    </S.Date>
                </S.MessageInner>
            </S.Message>
        </>
    )
}

export default Message
