import React from 'react'
import CollapsiblePanel from '../../../components/CollapsiblePanel'
import { _ } from '../../../utils/localization'
import PerformerRow from '../../performer/view/PerformerRow'
import { Performer as PerformerType } from '../../presentation/types'
import { SearchItemType } from '../types'

interface Props {
    data: {
        type: SearchItemType, items: PerformerType[]
    }
}

const Performers = ({ data }: Props) => (
    <CollapsiblePanel title={`${_('event_search.performers')} (${data.items.length})`} isDefaultCollapsed>
        <>
            {data.items.length ? data.items.map((item) => (
                <PerformerRow item={item} key={item.id} />
            )) : (
                <div>{_('general.no_data_available')}</div>
            )}
        </>
    </CollapsiblePanel>
)

export default Performers
