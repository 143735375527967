import React from 'react'
import styled from 'styled-components'
import Text from 'react-texty'
import colors from '../../../constants/colors'
import constants from '../../../constants/constants'
import { formatJobInfo } from '../../../utils/profile'

const S = {
    Profile: styled.div`
        display: flex;
        align-items: center;
        justify-content: start;
        cursor: pointer;
        min-width: 0;
    `,

    Thumbnail: styled.img`
        width: 50px;
        height: 50px;
        border-radius: 30px;
        object-fit: cover;
        margin-right: 16px;
    `,

    InfoWrapper: styled.div`
        overflow: hidden;
    `,

    Name: styled.p`
        margin: 0;
        font-size: 14px;
        color: ${colors.darkTitle};
    `,

    JobTitle: styled.span`
        display: block;
        font-size: 12px;
        color: ${colors.grayTitleOpacity(0.6)};
    `
}

export interface ProfileInfo {
    first_name?: string
    last_name?: string
    name?: string
    mugshot?: string
    jobtitle?: string
    company?: string
}

const Profile = ({ user, onClick }: { user: ProfileInfo, onClick?: () => void }) => {
    const name = user.name ? user.name : `${user.first_name} ${user.last_name}`
    return (
        <S.Profile onClick={onClick || (() => {})} className="mb-2">
            <S.Thumbnail src={user.mugshot || constants.urlThumbnailDefault} alt={name} />

            <S.InfoWrapper>
                <S.Name className="text-cut notranslate">{name}</S.Name>
                <S.JobTitle className="text-cut">
                    <Text
                        tooltip={formatJobInfo(user)}
                        tooltipClassName="truncate-tooltip"
                        hideArrow
                        placement="bottom"
                        tooltipMaxWidth={500}
                        hideDelay={0}
                        showDelay={0}
                        style={{marginLeft: 4}}
                    >
                        {formatJobInfo(user)}
                    </Text>
                </S.JobTitle>
            </S.InfoWrapper>
        </S.Profile>
    )
}

export default Profile
