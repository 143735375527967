import {
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RetrieveMapSpaceResponse } from '../../../types/swagger'
import api from '../../../utils/api'
import { RequestStatus } from '../../../types'
import { RootState } from '../../../store'

export const fetchMap = createAsyncThunk(
    'FETCHMAP',
    async ({ eventSlug, mapSlug }: { eventSlug: string, mapSlug: string }, thunkAPI) => {
        thunkAPI.dispatch(mapSlice.actions.startLoading(mapSlug))
        const resp = await api.get<RetrieveMapSpaceResponse>(`events/${eventSlug}/maps/${mapSlug}/`)
        return { mapSlug, resp }
    },
)

export interface UpdatedSpaceOnMap {
    id: number
    map_slug: string
    space_slug: string
    published: boolean
}

interface MapRequest {
    data?: RetrieveMapSpaceResponse
    status: RequestStatus
}

const mapSlice = createSlice({
    name: 'mapSlice',
    initialState: {
        requests: {} as Record<string, MapRequest>,
    },
    reducers: {
        startLoading: (state, { payload }: PayloadAction<string>) => {
            state.requests[payload] = state.requests[payload] || {
                status: 'loading',
                data: null,
            }
        },
        emptyData: (state, { payload }: PayloadAction<string>) => {
            delete state.requests[payload]
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMap.fulfilled, (state, { payload }) => {
            state.requests[payload.mapSlug] = {
                status: 'succeeded',
                data: payload.resp,
            }
        })
    },
})

export const mapSelector = (mapSlug: string) => createSelector(
    [(state: RootState) => state.map.getMap.requests[mapSlug]],
    (map) => map || { data: undefined, status: 'idle' },
)

export default mapSlice.reducer
