import { createAsyncThunk } from '@reduxjs/toolkit'
import add from 'date-fns/add'
import startOfDay from 'date-fns/startOfDay'
import api from '../../../utils/api'
import { ListProgramsSpacesResponse } from '../../../types/swagger'
import createRequestSlice from '../../../utils/createRequestSlice'
import { ProgramSpace } from '../types'
import { RootState } from '../../../store'

interface GetSpaceListProps {
    eventSlug?: string
    date?: Date
}

export const getSpaceList = createAsyncThunk(
    'FETCH_TIMETABLE_ITEMS',
    async ({ eventSlug, date }: GetSpaceListProps, thunkAPI) => {
        const state = thunkAPI.getState() as RootState
        const slug = eventSlug || state?.event?.current?.data?.slug
        const tomorrow = add(date, { days: 1 })
        const from = startOfDay(date).toISOString()
        const until = startOfDay(tomorrow).toISOString()
        const search = new URLSearchParams({
            from_datetime: from,
            until_datetime: until,
        }).toString()
        const response: ListProgramsSpacesResponse = await api.get<
            ListProgramsSpacesResponse
        >(`/events/${slug}/virtualprogram/spaces/?${search}`)
        return response.results
    },
)

const timeTableSlice = createRequestSlice<ProgramSpace[]>(getSpaceList, 'timeTableSlice')

export default timeTableSlice.reducer
