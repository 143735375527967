import { formatRoute } from 'react-router-named-routes'
import Logger from 'js-logger'
import {
    RouteProps,
    RoutePropsWithPathResolver,
} from '../types'
import env from '../constants/env'

const defaultGetPath = (path: string, params: Record<string, unknown> | undefined): string => {
    const formattedPath = formatRoute(path, params)
    if (env.debug && formattedPath.includes(':')) {
        Logger.warn(`
            getPath was called for path ${path} with params ${JSON.stringify(params)}. 
            Result seems like a parameter is missing, since ":" is still
            included. Result = ${formattedPath}
        `)
    }
    return formattedPath
}

// Suboptimal solution.
// If we provide multiple paths we should probably overwrite getPath anyway.
const toSinglePath = (path: string | string[]) => (typeof path === 'string'
    ? path
    : path[0])

let nextId = 1
export const createRoute = (route: RouteProps): RoutePropsWithPathResolver => ({
    ...route,
    title: route.title,
    id: nextId++,
    isEventRoute: route.isEventRoute || false,
    isPublic: route.isPublic || false,
    exact: route.exact !== false, // false if false, true if undefined
    /**
         * use getPath to get the path of a route. Example:
         * <Link to={routes.dashboard.getPath()} />
         * or if route accept params like "user/:id":
         * <Link to={routes.userDetails.getPath({ id: 1 })} />
         */
    getPath: route.getPath
        ? (params: Record<string, unknown> | undefined) => route.getPath(params)
        : (params: Record<string, unknown> | undefined) => defaultGetPath(
            // @ts-ignore
            toSinglePath(route.path), params
        ),
})

export const createEventRoute = (route: Omit<RouteProps, 'isEventRoute'>): RoutePropsWithPathResolver => createRoute({
    ...route,
    isEventRoute: true,
    path: typeof route.path === 'string'
        ? `/:eventSlug${route.path}`
        : route.path.map((path) => `/:eventSlug${path}`),
})
