import React, { useState } from 'react'
import styled from 'styled-components'
import useSlug from '../../../hooks/useSlug'
import styles from '../../../constants/styles'
import EmbeddedVideo from '../../embeddedvideo/EmbeddedVideo'
import { RetrieveVirtualEventResponse } from '../../../types/__generated_swagger'
import getThumbnailFromUrl from '../../../utils/getThumbnailOfVideo'
import constants from '../../../constants/constants'

const S = {
    VideoWrapper: styled.div`
        position: fixed;
        z-index: ${constants.Z_INDEX.INTRO_VIDEO};
        top: 0;
        left: 0;
        width: 100vw;
        height: var(--window-height);
        padding-top: ${styles.topNavHeight}px;
        background-color: black;
    `,
    ReplayThumbnailWrapper: styled.div`
        cursor: pointer;
    `,
    Thumbnail: styled.img`
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-position: center;
        object-fit: cover;
        filter: brightness(70%);
    `,
    CloseIcon: styled.div`
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: white;
        background-color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        cursor: pointer;
        user-select: none;
    `
}

const IntroVideo = ({ eventData, children }: { eventData: RetrieveVirtualEventResponse, children: any }) => {
    const eventSlug = useSlug('eventSlug')
    const videoHasBeenShown = window.localStorage.getItem(eventSlug) || false
    const autoplay = eventData?.autostart_intro_video && !videoHasBeenShown
    const [showVideo, setShowVideo] = useState(autoplay)
    const [thumbnail, setThumbnail] = useState(eventData.intro_thumbnail_url) // Priority use thumbnail by admin setting
    const introVideo = eventData?.intro_video

    React.useEffect(() => {
        if (!thumbnail && introVideo) { // If thumbnail wasn't set up, will get thumbnail from api
            getThumbnailFromUrl(introVideo).then((data) => setThumbnail(data))
        }
    }, [introVideo, thumbnail])

    React.useEffect(() => {
        // The EnterEvent component (parent): mount -> unmount -> mount in a very short period of time.
        // So, wait for this component is really mounted then update localstorage.
        // This is a work around to hot fix https://twoppy.atlassian.net/browse/ORG-1815.
        // TODO: Find the reason and prevent re-mounting this component from parent.
        setTimeout(() => {
            window.localStorage.setItem(eventSlug, 'entered')
        }, 5000)
    }, [eventSlug])

    if (!introVideo) {
        return null
    }

    return showVideo ? (
        <S.VideoWrapper>
            <EmbeddedVideo url={eventData.intro_video} />
            <S.CloseIcon onClick={() => setShowVideo(false)}>
                &#10005;
            </S.CloseIcon>
        </S.VideoWrapper>
    ) : (
        <S.ReplayThumbnailWrapper onClick={() => setShowVideo(true)}>
            {children}
        </S.ReplayThumbnailWrapper>
    )
}

export default IntroVideo
