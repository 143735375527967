import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useCallback } from 'react'
import { VirtualAttendee } from '../../types/swagger'
import { showNotification } from '../notifications'
import { _ } from '../../utils/localization'
import { inviteToNewCall, joinVideocall } from './videocallActions'
import { CallConfig } from './types'

export default (attendee: VirtualAttendee, config: CallConfig = {}) => {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    return useCallback(() => {
        // do nothing if user is not available
        if (!attendee.is_online || attendee.do_not_disturb) {
            return
        }

        // starting a new videocall while already in a videocall causes twilio to crash
        // and it doesn't make too much sense to create a new call while you're in one
        // so we ignore this case for now
        if (pathname.includes('videocall')) {
            dispatch(showNotification({
                body: _('videocall.notifications.cant_start_new_call_during_call'),
            }))
            return
        }

        // user is "currently in videocall" if number of users > 0
        const amountOfUsers = attendee?.videocall_status?.amount_of_users
        if (amountOfUsers) {
            // If user is in call, (ask to) join
            const status = attendee?.videocall_status?.status
            dispatch(joinVideocall(status, attendee, {...config, amountOfUsers}))
        } else {
            // If user is not in call, request call with user
            dispatch(inviteToNewCall(attendee.id, config))
        }
    }, [attendee, config, dispatch, pathname])
}
