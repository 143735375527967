import React from 'react'
import { useSelector } from 'react-redux'
import { VirtualAttendee } from '../../types/swagger'
import CallStatus from './CallStatus'
import useAttendeeCallClick from './useAttendeeCallClick'
import getAttendeeStatus from '../attendees/getAttendeeStatus'
import { CallConfig } from './types'
import { currentUserSelector } from '../auth/authSlice'

type AttendeeCallStatusProps = { attendee: VirtualAttendee, config?: CallConfig }

const AttendeeCallStatus = ({ attendee, config }: AttendeeCallStatusProps) => {
    const currentUser = useSelector(currentUserSelector())
    const onClick = useAttendeeCallClick(attendee, config)
    const isCurrentUserCallStatus = currentUser.id === attendee.id
    return (
        <CallStatus
            status={getAttendeeStatus(attendee)}
            amountOfUsers={attendee?.videocall_status?.amount_of_users}
            onClick={isCurrentUserCallStatus ? undefined : onClick}
        />
    )
}

export default AttendeeCallStatus
