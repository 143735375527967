import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import Slider, { CustomArrowProps } from 'react-slick'
import { useDispatch, useSelector } from 'react-redux'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Poster, RetrievePosterSpaceResponse } from '../types'
import Icon from '../../../components/Icon'
import {
    canLoadMorePostersSelector,
    isFullScreenSelector,
    posterIdSelector,
    requestPostersStatusSelector,
    selectPoster,
} from '../posterSlice'
import ActionBar from './ActionBar'
import FullPageLoading from '../../../components/FullPageLoading'
import colors from '../../../constants/colors'
import { _ } from '../../../utils/localization'

const S = {
    Wrapper: styled.div`
        position: relative;
        width: 100%;
        height: 100%;
    `,
    Slider: styled(Slider)`
        width: 100%;
        height: 100%;
        & .slick-list, & .slick-list * {
            height: 100%;
            outline: none!important; // hide outline on Safari
        }
        & .slick-current > div{
            display: flex;
            justify-content: center;
        }
    `,
    ContentWrapper: styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        margin: auto;
    `,
    SliderContent: styled.div<{isFullScreen: boolean, isFirefox: boolean}>`
        position: relative;
        margin: auto;
        display: flex;
        justify-content: center;
        width: ${({ isFullScreen }) => (isFullScreen ? '100%' : '800px')};
        padding: ${({ isFullScreen }) => (isFullScreen ? '0 50px' : '120px 0')};
        ${({ isFirefox, isFullScreen }) => isFirefox && css`
            top: ${isFullScreen ? '-33px' : 0};
            height: ${isFullScreen ? 'calc(100% + 23px)' : '100%'} !important;
            &::after {
                content: "";
                position: absolute;
                z-index: 10;
                top: ${isFullScreen ? 0 : '120px'};
                left: 0;
                width: 100%;
                height: 33px;
                background-color: rgba(237, 237, 240, 1);
            }
        `}
    `,
    ButtonArrow: styled.div<{disable: boolean, isPrev: boolean}>`
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: white;
        display: ${({ disable }) => (disable ? 'none' : 'flex')};
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: absolute;
        top: 50%;
        z-index: 99;
        cursor: pointer;
        ${({ isPrev }) => (isPrev ? css`
            left: calc((100% - 800px) / 2);
            transform: rotate(90deg) translateY(120%);
        ` : css`
            right: calc((100% - 800px) / 2);
            transform: rotate(-90deg) translateY(120%);
        `
    )}`,
    NoFileMessage: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: ${colors.grayTitle};
    `
}

const ArrowButton = ({
    isPrev, className, currentSlide, posters, onClick, ...props
}: CustomArrowProps & {isPrev?: boolean, posters: Poster[]}) => {
    const dispatch = useDispatch()
    const canLoadMore = useSelector(canLoadMorePostersSelector)
    const selectedPosterId = useSelector(posterIdSelector)
    const currentPosterIndex = posters.findIndex((e) => e.id === selectedPosterId)
    const index = isPrev ? currentPosterIndex - 1 : currentPosterIndex + 1
    const disable = isPrev && className.includes('slick-disable')

    const handleClick = () => {
        // Need setTimeout here to wait slide animation done in case user double click or click too quickly
        setTimeout(() => {
            if (!canLoadMore && index === posters.length) {
                dispatch(selectPoster(posters[0].id))
            } else {
                dispatch(selectPoster(posters[index].id))
            }
        }, 300)
    }

    return (
        <S.ButtonArrow disable={disable} isPrev={isPrev} onClick={handleClick}>
            <Icon
                {...props}
                size={30}
                icon="chevronDown"
                style={{ width: 30, height: 30 }}
                onClick={() => null}
            />
        </S.ButtonArrow>
    )
}

interface Props {
    posters: Poster[]
    chatId?: number
    space: RetrievePosterSpaceResponse
}

const PosterDetail = ({ posters, chatId, space }: Props) => {
    const sliderRef = useRef<Slider>()
    const selectedPosterId = useSelector(posterIdSelector)
    const isFullScreen = useSelector(isFullScreenSelector)
    const requestStatus = useSelector(requestPostersStatusSelector)
    const { trackEvent } = useMatomo()
    const trackAction = (action, name) => {
        trackEvent({
            category: `Poster space: ${space.meta.name}`,
            action,
            name,
        })
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        prevArrow: <ArrowButton isPrev posters={posters} />,
        nextArrow: <ArrowButton posters={posters} />,
        arrows: !isFullScreen,
    }

    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

    useEffect(() => {
        if (selectedPosterId) {
            trackAction('View poster', posters.find((e) => e.id === selectedPosterId)?.title)
            sliderRef.current.slickGoTo(posters.findIndex((e) => e.id === selectedPosterId))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPosterId]) // Only watch change on selectedPosterId

    return (
        <S.Wrapper>
            <S.Slider {...settings} ref={sliderRef}>
                {posters.map((poster) => (
                    <S.ContentWrapper key={poster.id}>
                        <S.SliderContent isFullScreen={isFullScreen} isFirefox={isFirefox}>
                            {poster.pdf ? (
                                <iframe
                                    key={poster.id}
                                    id="iframepdf"
                                    src={`${poster.pdf}#view=FitH&toolbar=0&navpanes=0&zoom-toolbar=0`}
                                    title="pdf-file"
                                    width="100%"
                                    height="100%"
                                />
                            ) : (
                                <S.NoFileMessage>
                                    {_('posters.no_file_available')}
                                </S.NoFileMessage>
                            )}
                        </S.SliderContent>

                        <ActionBar
                            poster={poster}
                            chatId={chatId}
                            space={space}
                        />
                    </S.ContentWrapper>
                ))}
            </S.Slider>
            {requestStatus === 'loading' && <FullPageLoading isAbsolute />}
        </S.Wrapper>
    )
}

export default PosterDetail
