import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { RetrieveProgramsSpaceResponse } from '../../../types/__generated_swagger'
import { ProgramSpace } from '../components/Area'

const popupSlice = createSlice({
    name: 'popupSlice',
    initialState: {
        data: {} as Record<number, RetrieveProgramsSpaceResponse>,
    },
    reducers: {
        setPopupData: (state, { payload }: PayloadAction<RetrieveProgramsSpaceResponse>) => {
            state.data[payload.id] = payload
        },
    }
})

export const popupDataSelector = (spaceId) => (state: RootState) => (
    state.map.popupData.data[spaceId] as ProgramSpace
)

export const setPopupData = popupSlice.actions.setPopupData

export default popupSlice.reducer
