import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AbsolutePopover from '../../../components/AbsolutePopover'
import { closePopover, selectPopover } from '../networkingSlice'

// TODO
//  handle case when call is all the way
//  in the top of the map, and we should
//  show the popover below the call icons
// const getPopoverPlacement = () => {
//     const yPosition = (meeting.y_position * containerHeight) / 100
//     return yPosition > MAX_POPOVER_HEIGHT ? 'top' : 'bottom'
// }

const NetworkingCallPopover = () => {
    const callIconSize = 40
    const dispatch = useDispatch()
    const popover = useSelector(selectPopover)
    const close = () => dispatch(closePopover())

    if (!popover) {
        return null
    }

    const { videocall, Component } = popover
    const { x_position, y_position } = videocall
    const x = `calc(${x_position}% + ${callIconSize / 2}px)`
    const y = `${y_position}%`
    return (
        <AbsolutePopover x={x} y={y}>
            <Component close={close} />
        </AbsolutePopover>
    )
}

export default NetworkingCallPopover
