import React, {
    useCallback, useEffect, useMemo, useRef, useState
} from 'react'
import { isToday, sub } from 'date-fns'
import sortBy from 'lodash/sortBy'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import useDraggableScroll from 'use-draggable-scroll'
import colors from '../../constants/colors'
import { padTime } from '../../utils/datetime'
import ProgramItem from './ProgramItem'
import CalendarItem from './CalendarItem'
import { calendarSidebarInfoSelector, sizeSelector } from './calendarSlice'
import { ProgramSpace } from '../timetable/types'
import { calculateBySize, getLocationTypeTranslation } from './utils'
import { getItemSpacingLeft, getSpacingLeft } from '../timetable/calculateSpace'
import { selectedDateSelector } from '../timetable/redux/dateSlice'
import { selectCategory } from '../goodiebag/categorySlice'
import { GoodiebagCategory, GoodiebagType } from '../goodiebag/types'
import { favoritesSelector } from '../goodiebag/itemsSlice'
import MyAppointments from './components/MyAppointments'
import { _ } from '../../utils/localization'
import Icon from '../../components/Icon'
import Typography from '../../components/Typography'
import useFetchAppointmentsInSelectedDate from './hooks/useFetchAppointmentsInSelectedDate'

const S = {
    ScrolledSchedule: styled.div<{ isExpanding: boolean }>`
        height: 100%;
        overflow: auto;
        border-top: 1px solid ${colors.iconOpacity(0.1)};
    `,
    Schedule: styled.div<{ isExpanding: boolean }>`
        position: relative;
        background-color: white;
        width: ${({ isExpanding }) => (isExpanding ? 'max-content' : '600px')};
    `,
    Header: styled.div<{ isExpanding: boolean, width: number }>`
        position: sticky;
        top: 24px;
        display: flex;
        height: 40px;
        min-width: ${({ isExpanding }) => (isExpanding ? 'calc(100vw - 320px)' : '600px')};
        width: ${({ width }) => width}px;
        z-index: 9;
        background-color: ${colors.lightBackground};
        border-bottom: 1px solid ${colors.iconOpacity(0.1)};
        &:after {
            content: "";
            position: absolute;
            top: 0;
            height: 24px;
            width: 100%;
            background-color: white;
            transform: translateY(-100%);
        }
    `,
    HeaderDummy: styled.div`
        position: sticky;
        left: 0;
        flex-shrink: 0;
        width: 41px;
        height: 40px;
        border-right: 1px solid ${colors.iconOpacity(0.1)};
        border-bottom: 1px solid ${colors.iconOpacity(0.1)};
        background-color: ${colors.lightBackground};
    `,
    HeaderItem: styled.div<{ width: number, isHiding?: boolean }>`
        display: ${({isHiding}) => (isHiding ? 'none' : 'block')};
        flex-shrink: 0;
        width: ${({ width }) => width}px;
        height: 100%;
        padding: 0 8px;
        border-right: 1px solid ${colors.iconOpacity(0.1)};
        text-align: center;
        font-size: 14px;
        color: ${colors.darkTitle};
    `,
    LocationIndicator: styled.div`
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: ${colors.iconOpacity(0.5)};
        text-transform: uppercase;
    `,
    ContentWrapper: styled.div<{ isExpanding: boolean, columnWidth: number, rowHeight: number, wrapperWidth: number }>`
        position: relative;
        display: flex;
        min-width: ${({ isExpanding }) => (isExpanding ? '100%' : '600px')};
        width: ${({ wrapperWidth }) => wrapperWidth}px;
        z-index: 8;
        background-image:
            repeating-linear-gradient(${colors.iconOpacity(0.2)} 0 1px, transparent 1px 100%),
            repeating-linear-gradient(90deg, ${colors.iconOpacity(0.2)} 0 1px, transparent 1px 100%);
        background-size: ${({ columnWidth, rowHeight }) => `${columnWidth}px ${rowHeight}px`};
        background-position: 40px 0;
    `,
    TimeColumn: styled.div`
        position: sticky;
        left: 0;
        z-index: 8;
        flex-shrink: 0;
        width: 40px;
        min-width: 40px;
        background-color: ${colors.lightBackground};
        transform: translateY(-8px);
        border-right: 1px solid ${colors.iconOpacity(0.1)};
    `,
    TimeItem: styled.div<{ height: number }>`
        width: 100%;
        height: ${({ height }) => height}px;
        font-size: 10px;
        color: ${colors.icon};
        text-align: center;
        &:first-child {
            opacity: 0;
        }
    `,
    DotLine: styled.div<{ height: number }>`
        width: 100%;
        height: ${({ height }) => height}px;
        transform: translateY(-50%);
        border-bottom: 1px dashed ${colors.iconOpacity(0.2)};
    `,
    Content: styled.div`
        position: relative;
        width: 100%;
    `,
    ContentColumn: styled.div<{ index: number, width: number, isHiding?: boolean }>`
        position: absolute;
        top: 0;
        left: ${({ index, width }) => index * width}px;
        display: ${({isHiding}) => (isHiding ? 'none' : 'block')};
        width: ${({ width }) => width}px;
        height: 100%;
    `,
    MyAppointmentsWrapper: styled.div<{width: number, isExpanding: boolean}>`
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        width: ${({ width, isExpanding }) => (isExpanding ? width : 600)}px;
        height: 24px;
        background-color: white;
        overflow: hidden;
        z-index: 10;
    `,
    MyAppointmentsControl: styled.div<{ width: number, collapse?: boolean, hasItem?: boolean }>`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${({ width, collapse }) => (collapse ? 38 : width)}px;
        margin-left: 40px;
        background-color: ${colors.midBlue};
        cursor: pointer;

        ${({collapse}) => collapse && css`
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        `}

        ${({hasItem, collapse}) => hasItem && css`
            &:after {
                content: '';
                position: absolute;
                top: ${collapse ? 0 : '7px'};
                right: -4px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: red;
            }
        `}
    `,
    RightArrow: styled(Icon)<{ isHidingMyAppointments: boolean }>`
        margin-bottom: -14px;
        margin-right: -10px;
        transform: rotate(0);
        ${({isHidingMyAppointments}) => !isHidingMyAppointments && css`
            margin-bottom: 15px;
            margin-right: -32px;
            transform: rotate(180deg);
        `};
    `
}

const Calendar = ({
    programSpaces, showFavorites
}: { programSpaces: ProgramSpace[], showFavorites?: boolean }) => {
    const dispatch = useDispatch()
    const favorites = useSelector(favoritesSelector)
    const favoritePrograms = useMemo(() => (
        Object.values(favorites || {})
            .filter((e) => e.type === GoodiebagType.ProgramItem).map((e) => e.id)
    ), [favorites])
    const { isExpanding } = useSelector(calendarSidebarInfoSelector)
    const [isHidingMyAppointments, setIsHidingAppointment] = useState(true)
    const size = useSelector(sizeSelector)
    const selectedDate = useSelector(selectedDateSelector)
    const itemWidth = calculateBySize('horizontal', size.horizontal)
    const itemHeight = calculateBySize('vertical', size.vertical)
    const calendarWidth = (programSpaces.length + (isHidingMyAppointments ? 0 : 1)) * itemWidth + 40
    const scrolledRef = useRef<HTMLDivElement>(null)
    const { onMouseDown } = useDraggableScroll(scrolledRef)
    const { data: plannedMeetings = [] } = useFetchAppointmentsInSelectedDate(selectedDate)

    useEffect(() => {
        if (plannedMeetings.length && !programSpaces?.length) {
            setIsHidingAppointment(false)
        } else {
            setIsHidingAppointment(true)
        }
    }, [plannedMeetings.length, programSpaces?.length])

    useEffect(() => {
        if (!favoritePrograms?.length) {
            dispatch(selectCategory(GoodiebagCategory.ProgramItem))
        }
    }, [dispatch, favoritePrograms])

    const scrollCalendar = useCallback(() => {
        const calendarHeight = scrolledRef.current?.scrollHeight || 0
        if (isToday(selectedDate)) {
            const spacingTopPercentage = getSpacingLeft(sub(new Date(), {hours: 1})) // percentage string (eg: "75.06%")
            const spacingTop = (
                +spacingTopPercentage.slice(0, spacingTopPercentage.length - 1) / 100
            ) * calendarHeight - 20
            scrolledRef.current.scrollTo(
                scrolledRef.current.scrollLeft,
                spacingTop,
            )
        } else {
            const items = programSpaces.reduce((acc, cur) => {
                acc.push(...cur.items)
                return acc
            }, [])
            const sortedItems = sortBy(items, [(item) => new Date(item.start_date)])
            const startsAt = sortedItems[0] ? new Date(sortedItems[0]?.start_date) : new Date(selectedDate)
            const spacingTopPercentage = getItemSpacingLeft(
                { startsAt: sub(startsAt, { hours: 1 }) }, selectedDate
            )
            const spacingTop = (
                +spacingTopPercentage.slice(0, spacingTopPercentage.length - 1) / 100
            ) * calendarHeight - 13
            scrolledRef.current.scrollTo(
                scrolledRef.current.scrollLeft,
                spacingTop,
            )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programSpaces.map((e) => e.id).toString(), selectedDate])

    useEffect(() => {
        if (scrolledRef.current) {
            scrollCalendar()
        }
    }, [selectedDate, scrollCalendar])

    return (
        <S.ScrolledSchedule isExpanding={isExpanding} ref={scrolledRef} onMouseDown={onMouseDown}>
            <S.MyAppointmentsWrapper width={calendarWidth} isExpanding={isExpanding}>
                <S.MyAppointmentsControl
                    width={itemWidth}
                    onClick={() => setIsHidingAppointment(!isHidingMyAppointments)}
                    collapse={isHidingMyAppointments}
                    hasItem={plannedMeetings?.length > 0}
                >
                    <Icon icon="calendarClock" size={14} color="white" className="mr-1 mb-2px" />
                    <Typography
                        fontSize={12}
                        fontWeight={700}
                        color="white"
                        textTransform="uppercase"
                        style={{ display: isHidingMyAppointments ? 'none' : 'block' }}
                    >
                        {_('calendar.schedule')}
                    </Typography>
                    <S.RightArrow icon="rightArrow" color="yellow" size={24} isHidingMyAppointments={isHidingMyAppointments} />
                </S.MyAppointmentsControl>
            </S.MyAppointmentsWrapper>

            <S.Schedule isExpanding={isExpanding}>
                <S.Header width={calendarWidth} isExpanding={isExpanding}>
                    <S.HeaderDummy />
                    <div className="d-flex">
                        <S.HeaderItem width={itemWidth} isHiding={isHidingMyAppointments}>
                            {_('calendar.my_appointments')}
                        </S.HeaderItem>
                        {programSpaces.map((space) => (
                            <S.HeaderItem key={space.id} className="text-cut" width={itemWidth}>
                                <S.LocationIndicator>
                                    {getLocationTypeTranslation(space.location_type)}
                                </S.LocationIndicator>
                                {space.name}
                            </S.HeaderItem>
                        ))}
                    </div>
                </S.Header>
                <S.ContentWrapper
                    isExpanding={isExpanding}
                    columnWidth={itemWidth}
                    rowHeight={itemHeight}
                    wrapperWidth={calendarWidth}
                >
                    <S.TimeColumn>
                        {Array(24).fill(null).map((v, i) => (
                            <S.TimeItem key={i} height={itemHeight}>{`${padTime(i)}:00`}</S.TimeItem>
                        ))}
                    </S.TimeColumn>
                    <S.Content>
                        {Array(24).fill(null).map((v, i) => (
                            <S.DotLine key={i} height={itemHeight} />
                        ))}

                        <S.ContentColumn index={0} width={itemWidth} isHiding={isHidingMyAppointments}>
                            <MyAppointments />
                        </S.ContentColumn>

                        {programSpaces.map((space, i) => (
                            <S.ContentColumn
                                key={space.id}
                                index={i + (isHidingMyAppointments ? 0 : 1)}
                                width={itemWidth}
                            >
                                {space.items.map((item) => (
                                    <CalendarItem
                                        item={item}
                                        key={item.id}
                                    >
                                        <ProgramItem
                                            item={item}
                                            shouldFade={
                                                showFavorites && !favoritePrograms.includes(item.id)
                                            }
                                        />
                                    </CalendarItem>
                                ))}
                            </S.ContentColumn>
                        ))}
                    </S.Content>
                </S.ContentWrapper>
            </S.Schedule>
        </S.ScrolledSchedule>
    )
}

export default Calendar
