import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import sortBy from 'lodash/sortBy'
import { RootState } from '../../store'
import { VerticalSize } from '../calendar/calendarSlice'
import { DayPart, TimeSlot } from './types'

const initialState = {
    date: undefined as Date,
    dayPart: undefined as DayPart,
    timeslots: [] as TimeSlot[],
    verticalSize: 'M' as VerticalSize
}

const appointmentSchedulesSlice = createSlice({
    name: 'AppointmentSchedule',
    initialState,
    reducers: {
        updateScheduleDate: (state, { payload }: PayloadAction<Date>) => {
            state.date = payload
        },
        updateScheduleDayPart: (state, { payload }) => {
            state.dayPart = payload
        },
        updateScheduleTimeSlots: (state, { payload }: PayloadAction<TimeSlot[]>) => {
            state.timeslots = payload
        },
        resetAppointmentScheduleState: () => initialState,
        updateVerticalSize: (state, { payload }: PayloadAction<VerticalSize>) => {
            state.verticalSize = payload
        },
    }
})

export const {
    updateScheduleDate,
    updateScheduleDayPart,
    updateScheduleTimeSlots,
    resetAppointmentScheduleState,
    updateVerticalSize,
} = appointmentSchedulesSlice.actions

export const dateOfAppointmentScheduleSelector = (state: RootState) => state.appointmentSchedule.date
export const dayPartOfAppointmentScheduleSelector = (state: RootState) => state.appointmentSchedule.dayPart
export const timeSlotsOfAppointmentScheduleSelector = (state: RootState) => (
    sortBy(state.appointmentSchedule.timeslots, 'id')
)
export const verticalSizeSelector = (state: RootState) => state.appointmentSchedule.verticalSize

export default appointmentSchedulesSlice.reducer
