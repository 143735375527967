import { useField } from 'formik'
import React from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import colors from '../constants/colors'
import { _ } from '../utils/localization'

const S = {
    Label: styled(Form.Label)`
        font-size: 14px;
        font-weight: 500;
        color: ${colors.grayTitleOpacity(0.9)};
    `,
}

interface Props {
    name: string
    label?: string
    placeholder?: string
    isRequired?: boolean
    isTextArea?: boolean
    isDisabled?: boolean
}

const TextField = ({
    name, placeholder, label, isRequired, isTextArea, isDisabled
}: Props) => {
    const [
        field,
        meta,
    ] = useField({ name })
    const { error, touched } = meta

    return (
        <Form.Group>
            <S.Label>
                {`${label} ${isRequired ? '*' : ''}`}
            </S.Label>

            <Form.Control
                placeholder={placeholder}
                isInvalid={touched && !!error}
                disabled={isDisabled}
                as={isTextArea ? 'textarea' : 'input'}
                {...field}
            />
            {touched && error && (
                <small className="text-danger">
                    {_(error)}
                </small>
            )}
        </Form.Group>
    )
}

export default TextField
