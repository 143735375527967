import React from 'react'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { isBefore } from 'date-fns'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CustomButton from '../../../components/CustomButton'
import useParams from '../../../hooks/useParams'
import { RetrieveProgramDetailResponse } from '../../../types/swagger'
import { _ } from '../../../utils/localization'
import getPathForSpace from '../../../utils/spaces/getPathForSpace'
import { closeAllModal } from '../../modals/modalSlice'
import { collapse } from '../redux/expandFooterSlice'
import { closeCalendar } from '../../calendar/calendarSlice'
import { isInBetween } from '../../../utils/datetime'

const S = {
    Link: styled(Link)`
        margin-right: 24px;
        text-decoration: none !important;
    `,
}

const handleEnterClick = createAsyncThunk(
    'HANDLE_ENTER_CLICK',
    (__, thunkAPI) => {
        thunkAPI.dispatch(closeAllModal())
        thunkAPI.dispatch(collapse())
        thunkAPI.dispatch(closeCalendar())
    },
)

const JoinButton = ({ item }: { item: Partial<RetrieveProgramDetailResponse> }) => {
    const { location } = useHistory()
    const params = useParams()
    const dispatch = useDispatch()
    const path = getPathForSpace(item.virtual_space, params)
    const enterLink = item.meeting_id
        ? `${path}?meetingId=${item.meeting_id}`
        : path

    if (isBefore(new Date(item.end_date), new Date(item.start_date))) {
        return null
    }

    if (isBefore(new Date(item.end_date), new Date())) {
        return null
    }

    const canJoin = isInBetween(
        item.start_date, item.end_date, new Date(),
    )

    return enterLink && enterLink !== location.pathname
        ? (
            <S.Link to={enterLink} onClick={() => dispatch(handleEnterClick())}>
                <CustomButton variant="success">
                    {canJoin ? (
                        _('item.details_modal.join_program_button')
                    ) : (
                        _('item.details_modal.enter_space_button')
                    )}
                </CustomButton>
            </S.Link>
        ) : <div />
}

export default JoinButton
