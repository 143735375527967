import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { _ } from '../../../utils/localization'
import { MeetingList } from '../types'
import MeetingCard from './MeetingCard'
import colors from '../../../constants/colors'
import { MeetingTypes } from '../utils'

const S = {
    MeetingCards: styled.div`
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 10px;
      row-gap: 10px;
      padding: 10px;
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
      @media (min-width: 769px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: 933px) {
        grid-template-columns: repeat(4, 1fr);
      }
    `,
    NoMeetings: styled.div`
      display: flex;
      justify-content: center;
      width: 100%;
      min-height: 500px;
    `,
    NoMeetingsHeader: styled.h1`
      padding: 60px;
      background: white;
      border-radius: 6px;
      margin: auto;
      color: ${colors.regentGray};
    `,
    LoadMoreContainer: styled.div`
      margin-bottom: 15px;
      padding: 10px; //same as MeetingCards
      display: flex;
      justify-content: end;
      min-height: 15px; // prevent timetable getting in front of the buttons when  there's no button
    `,
}

// const noMeetingStrings = {
//     myMeetings: _('meetingspace.no_meetings.my_meetings'),
//     live: _('meetingspace.no_meetings.live'),
//     upcoming: _('meetingspace.no_meetings.upcoming'),
// }

const MeetingCards = ({
    meetings, loadMore, meetingCount
}
: {meetings: MeetingList, name: MeetingTypes, loadMore: ()=> void, meetingCount: number}) => {
    if (meetings.length === 0) {
        return null
        // return (
        //     <S.NoMeetings>
        //         <S.NoMeetingsHeader>{_(noMeetingStrings[name])}</S.NoMeetingsHeader>
        //     </S.NoMeetings>
        // )
    }

    return (
        <div>
            <S.MeetingCards>
                {meetings.map((meeting) => (
                    <MeetingCard key={meeting.id} meeting={meeting} />
                ))}
            </S.MeetingCards>

            <S.LoadMoreContainer>
                {meetingCount > meetings.length && (
                    <Button onClick={() => loadMore()}>{_('meetingspace.load_more')}</Button>
                )}
            </S.LoadMoreContainer>

        </div>
    )
}

export default MeetingCards
