import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'

const helpContentSlice = createSlice({
    name: 'helpContent',
    initialState: {
        data: null as string,
        type: null as string,
    },
    reducers: {
        setHelpContent: (state, { payload }: PayloadAction<{data: string | null, type: string | null}>) => {
            state.data = payload?.data
            state.type = payload?.type
        }
    }
})

export const helpContentSelector = (state: RootState) => state.helpContent.data
export const helpContentTypeSelector = (state: RootState) => state.helpContent.type

export const setHelpContent = helpContentSlice.actions.setHelpContent

export default helpContentSlice.reducer
