/* eslint-disable react/prop-types */
import { format, isAfter } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import screenfull from 'screenfull'
import Logger from 'js-logger'
import CustomButton from '../../../components/CustomButton'
import Icon from '../../../components/Icon'
import colors from '../../../constants/colors'
import { RetrieveProgramDetailResponse } from '../../../types/__generated_swagger'
import { toLocalTimeFormat } from '../../../utils/datetime'
import { _ } from '../../../utils/localization'
import { addModal } from '../../modals/modalSlice'

const S = {
    Wrapper: styled.div<{isExpanding?: boolean}>`
        width: 100%;
        background-color: ${colors.darkTitle};
        ${({ isExpanding }) => isExpanding && css`
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            & .iframe-wrapper {
                max-height: calc(var(--window-height) - 64px);
                max-width: 100vw;
                aspect-ratio: 16/9;
            }
        `}
    `,
    Header: styled.div`
        display: flex;
        justify-content: space-between;
        padding: 8px 16px;
    `,
    InfoWrapper: styled.div`
        overflow: hidden;
    `,
    ModalTitle: styled.div`
        font-size: 20px;
        color: ${colors.light};
    `,
    Time: styled.div`
        font-size: 12px;
        color: white;
    `,
    IframeWrapper: styled.div`
        position: relative;
        width: 100%;
        aspect-ratio: 16/9;
    `,
}

const PlaybackModal = ({
    item, close
}: {item: Partial<RetrieveProgramDetailResponse>, close: () => void}) => {
    const [isFullScreen, setIsFullScreen] = useState(false)
    const { trackEvent } = useMatomo()
    const { pushInstruction } = useMatomo()

    useEffect(() => {
        pushInstruction('MediaAnalytics::scanForMedia')
    }, [pushInstruction])

    useEffect(() => {
        trackEvent({
            category: item.name,
            action: 'replay session',
            name: item.playback_url,
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setFullScreen = () => {
        if (screenfull.isEnabled) {
            if (screenfull.isFullscreen) {
                screenfull.exit()
            } else {
                screenfull.request()
                    .catch((error) => Logger.warn('Unable to request full screen', error))
            }
        }
    }
    useEffect(() => {
        if (screenfull.isEnabled) {
            screenfull.onchange(() => {
                // @ts-ignore
                setIsFullScreen(screenfull.isFullscreen)
            })
        }
    }, [])

    const handleClose = () => {
        close()
        if (screenfull.isEnabled) {
            screenfull.exit()
        }
    }

    return (
        <S.Wrapper isExpanding={isFullScreen}>
            <S.Header>
                <S.InfoWrapper>
                    <S.ModalTitle className="text-cut">
                        {item.name}
                    </S.ModalTitle>
                    <S.Time className="flex-align-center">
                        <span>{format(new Date(item.start_date), 'dd MMM yyyy - HH:mm')}</span>
                        <span className="mx-1">-</span>
                        <span>{toLocalTimeFormat(item.end_date, '')}</span>
                    </S.Time>
                </S.InfoWrapper>
                <div className="flex-align-center">
                    <Icon
                        icon={isFullScreen ? 'arrowCollapse' : 'arrowExpand'}
                        size={20}
                        color="white"
                        onClick={setFullScreen}
                        className="mr-3"
                    />
                    <Icon icon="close" size={24} color="white" onClick={handleClose} />
                </div>
            </S.Header>

            <S.IframeWrapper className="iframe-wrapper">
                <iframe
                    src={item.playback_url}
                    title="stream"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen; camera; microphone"
                    // @ts-ignore
                    allowFullScreen="true"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                />
            </S.IframeWrapper>
        </S.Wrapper>
    )
}

const PlaybackButton = ({ item }: {item: Partial<RetrieveProgramDetailResponse>}) => {
    const dispatch = useDispatch()
    const canPlay = isAfter(new Date(), new Date(item.release_date))

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {_('show.disabled_play_back.tooltip', {
                release_date_time: format(new Date(item.release_date), 'dd MMM yyyy - hh:mm')
            })}
        </Tooltip>
    )

    const handleClick = () => {
        dispatch(addModal({
            Component: ({close}: {close: () => void}) => <PlaybackModal item={item} close={close} />,
            size: 'xl',
            padding: 0,
        }))
    }

    return canPlay ? (
        <CustomButton variant="primary" disabled={!canPlay} onClick={handleClick} className="mr-3">
            {_('button.replay')}
        </CustomButton>
    ) : (
        <OverlayTrigger placement="bottom" overlay={renderTooltip}>
            <div className="mr-3">
                <CustomButton variant="primary" disabled={!canPlay}>
                    {_('button.replay')}
                </CustomButton>
            </div>
        </OverlayTrigger>
    )
}

export default PlaybackButton
