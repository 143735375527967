import React, { useRef } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import StyledIcon from '../../components/Icon'
import colors from '../../constants/colors'
import { RootState } from '../../store'
import { uploadProfileImage } from './editProfileSlice'
import constants from '../../constants/constants'

const S = {
    ProfileImage: styled.div<{ profileImage?: string }>`
        position: absolute;
        bottom: 0;
        left: 35px;
        transform: translateY(50%);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 5px solid white;
        background: url(${({ profileImage }) => (profileImage || constants.urlThumbnailDefault)}) no-repeat center;
        background-size: cover;
        background-position: center;
        background-color: white;
    `,
    EditButton: styled.div`
        position: absolute;
        bottom: 0;
        right: 0;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${colors.primary};
        cursor: pointer;
    `,
}

const ProfileImage = ({ profileImage, userId }: { profileImage: string, userId: number }) => {
    const { profileImage: profileUploadData } = useSelector((state: RootState) => state.editProfile)
    const uploadRef = useRef<HTMLInputElement>()
    const dispatch = useDispatch()
    const uploadedMugshot = profileUploadData.data?.mugshot

    if (profileUploadData.status === 'loading') {
        return (
            <S.ProfileImage>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </S.ProfileImage>
        )
    }

    const handleChange = (e) => {
        if (e.target.files.length) {
            const formData = new FormData()
            formData.append('file', e.target.files[0])
            dispatch(uploadProfileImage({ id: userId, body: formData }))
        }
    }

    return (
        <S.ProfileImage profileImage={uploadedMugshot || profileImage}>
            <S.EditButton onClick={() => uploadRef.current.click()}>
                <StyledIcon icon="pencil" color="white" size={16} />
                <input type="file" id="file" ref={uploadRef} style={{ display: 'none' }} onChange={handleChange} accept="image/*" />
            </S.EditButton>
        </S.ProfileImage>
    )
}

export default ProfileImage
