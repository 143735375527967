import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import { _ } from '../../utils/localization'
import colors from '../../constants/colors'
import Icon from '../../components/Icon'

const S = {
    Form: styled(Form)`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px;
        @media (max-width: 932px) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 8px 12px 16px 12px;
            background: ${colors.light};
            margin: 0;
        }
    `,
    SendButton: styled.button`
        border-color: transparent;
        background: transparent;

        &:active, &:focus {
            box-shadow: none !important;
            outline: none !important;
        }
    `,
    Input: styled(Form.Control)`
        border: 1px solid;
        border-color: ${colors.gray3};
        padding: 10px;
        box-shadow: none;
        outline: none;

        &:active, &:focus {
            box-shadow: none !important;
            outline: none !important;
        }
    `,
}

interface MessageInputProps {
    send: (text: string) => void
}

const MessageInput = ({ send }: MessageInputProps) => {
    const [value, setValue] = React.useState('')
    const inputRef = useRef<HTMLInputElement>()
    useEffect(() => {
        // Focus into input text when component mounted
        inputRef.current.focus()
    }, [])

    const submit = (event: Event | undefined) => {
        if (event) {
            event.preventDefault()
        }
        if (value !== '') {
            send(value)
            setValue('')
        }
    }
    return (
        <S.Form onSubmit={submit}>
            <S.Input
                type="text"
                placeholder={_('publicchat.new_message_input.placeholder')}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                ref={inputRef}
            />
            <S.SendButton type="submit">
                <Icon icon="send" size={28} />
            </S.SendButton>
        </S.Form>
    )
}

export default MessageInput
