import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import {
    OverlayTrigger, Popover, PopoverTitle, PopoverContent, Tooltip
} from 'react-bootstrap'
import { Placement } from 'react-bootstrap/cjs/Overlay'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import colors from '../../../../constants/colors'
import { ScheduleStatus } from '../../../../types'
import { AppointmentSchedule } from '../../../../types/swagger'
import { currentUserSelector } from '../../../auth/authSlice'
import { getUser } from '../AppointmentRowInList'
import { formatJobInfo, formatName } from '../../../../utils/profile'
import { _ } from '../../../../utils/localization'
import Avatar from '../../../../components/Avatar'
import Divider from '../../../../components/Divider'
import CustomButton from '../../../../components/CustomButton'
import useFetchAttendees from '../../../attendees/useFetchAttendees'
import getMugshotSrc from '../../../../utils/getMugshotSrc'
import CancelScheduleButton from '../../../chat/components/appointment_schedule/CancelScheduleButton'
import useSlug from '../../../../hooks/useSlug'
import useFetchBlockedSchedules from '../../hooks/useFetchBlockedSchedules'
import { addModal } from '../../../modals/modalSlice'
import ConfirmModal from '../../../../components/ConfirmModal'
import { setIsPageLoading } from '../../../events/pageLoadingSlice'
import api from '../../../../utils/api'
import Icon from '../../../../components/Icon'
import openChatModal from '../../../chat/openChatModal'
import JoinAppointmentButton from '../../../chat/components/appointment_schedule/JoinAppointmentButton'

const S = {
    ContenWrapper: styled.div<{ status?: ScheduleStatus }>`
        position: relative;
        display: flex;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        padding: 8px 2px;
        background-color: ${({ status }) => (status !== 'BLOCKED' ? colors.midBlue : colors.grayDescription)};
        color: white;
        gap: 4px;
    `,
    Title: styled.div`
        flex: 1;
        font-size: 10px;
    `,
    Time: styled.div`
        flex-shrink: 0;
        font-size: 10px;
        font-weight: 300;
    `,
    UserInfo: styled.div`
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 16px;
        overflow: hidden;
    `,
    Name: styled.div`
        font-size: 14px;
        color: ${colors.darkTitle};
        font-weight: 500;
    `,
    Jobtitle: styled.div`
        font-size: 12px;
        color: ${colors.grayDescription};
    `,
    Subject: styled.div`
        font-size: 12px;
        font-weight: 300;
    `,
    Actions: styled.div`
        display: flex;
        justify-content: flex-end;
    `,
    Popover: styled(Popover)`
        max-width: 400px;
    `
}

const AppointmentInCalendar = ({ event }: { event: AppointmentSchedule }) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const currentUser = useSelector(currentUserSelector())
    const userInfo = getUser(currentUser, event)
    const [participant] = useFetchAttendees([userInfo?.id])
    const { removedMutate } = useFetchBlockedSchedules()
    const ref = React.useRef(null)
    const [popoverPlacement, setPopoverPlacement] = useState<Placement>('right')

    useEffect(() => {
        if (!ref.current) {
            return
        }
        const {x: left, right} = ref.current.getBoundingClientRect()
        if (right - left > window.innerWidth - 300) {
            setPopoverPlacement('top')
            return
        }
        setPopoverPlacement(ref.current.getBoundingClientRect().right > window.innerWidth / 2 ? 'left' : 'right')
    }, [])

    const deleteBlockedTimeSlot = async () => {
        dispatch(setIsPageLoading(true))
        await api.delete(`/events/${eventSlug}/appointment_schedules/blocked/${event.id}/`)
        removedMutate(event.id)
        dispatch(setIsPageLoading(false))
    }

    const showDeletingBlockedTimeSlotConfirmModal = () => {
        dispatch(addModal({
            Component: ({ close }: {close: () => void}) => (
                <ConfirmModal
                    header={_('appointment_schedule.delete_blocked_timeslot.header')}
                    body={_('appointment_schedule.delete_blocked_timeslot.body')}
                    cancelText={_('buton.no')}
                    confirmText={_('buton.yes')}
                    onConfirm={deleteBlockedTimeSlot}
                    close={close}
                />
            ),
        }))
    }

    const handleOpenChat = () => {
        dispatch(openChatModal(eventSlug, participant?.id))
        window.document.body.click()
    }

    const popover = (
        <Popover id="popover-contained" style={{ maxWidth: 400 }}>
            <PopoverTitle as="h3" style={{ display: 'flex', justifyContent: 'space-between'}}>
                {_('appointment_schedule.popover.title')}
                <Icon
                    icon="messageProcessing"
                    className="ml-3"
                    onClick={handleOpenChat}
                />
            </PopoverTitle>
            <PopoverContent>
                <S.UserInfo>
                    <Avatar src={getMugshotSrc(participant)} />
                    <div className="ml-2" style={{ overflow: 'hidden' }}>
                        <S.Name className="text-cut notranslate">{formatName(participant)}</S.Name>
                        <S.Jobtitle className="text-cut">{formatJobInfo(participant)}</S.Jobtitle>
                    </div>
                </S.UserInfo>
                <Divider marginBottom={16} />
                <S.Subject>{event.subject}</S.Subject>
                <Divider marginTop={16} />
                <S.Actions onClick={() => document.body.click()}>
                    <CancelScheduleButton
                        receiver={participant}
                        appointmentSchedule={event}
                        currentUser={currentUser}
                        eventSlug={eventSlug}
                        renderCustomButton={() => <CustomButton variant="secondary">{_('button.cancel_meeting')}</CustomButton>}
                        callback={() => removedMutate(event.id)}
                    />
                    <JoinAppointmentButton appointment={event} variant="success" />
                </S.Actions>
            </PopoverContent>
        </Popover>
    )

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {_('tooltip.click_to_delete')}
        </Tooltip>
    )

    return (
        <div ref={ref} style={{ height: '100%' }}>
            {event.status === 'BLOCKED' ? (
                <OverlayTrigger placement={popoverPlacement} overlay={renderTooltip}>
                    <S.ContenWrapper
                        status={event.status}
                        onClick={showDeletingBlockedTimeSlotConfirmModal}
                    >
                        <S.Title>{event.subject}</S.Title>
                        <S.Time>
                            {`${format(new Date(event.start_date), 'HH:mm')} - ${format(new Date(event.end_date), 'HH:mm')}`}
                        </S.Time>
                    </S.ContenWrapper>
                </OverlayTrigger>
            ) : (
                <OverlayTrigger placement={popoverPlacement} overlay={popover} trigger="click" rootClose>
                    <S.ContenWrapper status={event.status}>
                        <S.Title>{formatName(userInfo)}</S.Title>
                        <S.Time>
                            {`${format(new Date(event.start_date), 'HH:mm')} - ${format(new Date(event.end_date), 'HH:mm')}`}
                        </S.Time>
                    </S.ContenWrapper>
                </OverlayTrigger>
            )}
        </div>
    )
}

export default AppointmentInCalendar
