import React, { useState } from 'react'
import styled from 'styled-components'
import StyledIcon from '../../../components/Icon'
import useSlug from '../../../hooks/useSlug'
import { _ } from '../../../utils/localization'
import { Performer as PerformerType } from '../../presentation/types'
import RelatedSession from './RelatedSession'
import { ListProgramsResponse } from '../../../types/__generated_swagger'
import constants from '../../../constants/constants'
import Get from '../../../components/Get'
import Bookmark from '../../goodiebag/Bookmark'
import { GoodiebagType } from '../../goodiebag/types'
import { formatJobInfo } from '../../../utils/profile'
import colors from '../../../constants/colors'
import LocationIndicator from '../../../components/LocationIndicator'
import ReadMoreOrLess from '../../../components/ReadMoreOrLess'

const S = {
    Header: styled.div`
        display: flex;
        align-items: center;
        padding: 16px;
    `,
    Body: styled.div`
        padding: 16px;
    `,
    PerformerImage: styled.img`
        width: 80px;
        height: 80px;
        margin-right: 16px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        @media (max-width: 932px) {
            width: 70px;
            height: 70px;
        }
    `,
    PerformerName: styled.h4`
        margin: 0;
        margin-right: 8px;
        font-size: 24px;
        @media (max-width: 932px) {
            font-size: 16px;
        }
    `,
    Description: styled.div`
        font-weight: 500;
        font-size: 12px;
        color: ${colors.grayDescription};
    `,
    Divider: styled.div`
        width: 100%;
        height: 1px;
        margin: 20px 0;
        font-size: 14px;
        background-color: rgba(176, 190, 197, 0.3);
    `,
    Title: styled.h3`
        margin-bottom: 10px;
        font-size: 18px;
        text-transform: uppercase;
        @media (max-width: 932px) {
            font-size: 16px;
        }
    `,
    AboutContent: styled(ReadMoreOrLess)`
        font-weight: 300;
        font-size: 14px;
        color: ${colors.grayDescription};
    `,
    DocumentItem: styled.a`
        text-decoration: none;
        font-size: 16px;
        color: ${colors.grayDescription};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: 0.2s;
        &:hover {
            text-decoration: none;
            color: black;
        }
    `,
    ShowMoreWrapper: styled.div`
        margin-top: 20px;
        text-align: center;
    `,
    ShowMoreSessions: styled.span`
        font-size: 16px;
        font-weight: 500;
        color: ${colors.icon};
        cursor: pointer;
        &:hover {
            color: #1e688e;
            & path{
                fill: #1e688e!important;
            }
        }
    `,
    Loading: styled.div`
        margin-top: 20px;
        display: flex;
        justify-content: center;
    `
}

export const Documents = ({ documents }: { documents: PerformerType['attachments'] }) => {
    if (documents?.length === 0) {
        return <p style={{ color: colors.grayDescription }}>{_('performer.no_attachment_available')}</p>
    }

    return (
        <>
            { documents.map((document) => (
                <div key={document.id}>
                    <S.DocumentItem href={document.url} target="_blank" rel="noopener noreferrer">
                        <StyledIcon icon="download" size={20} style={{ marginRight: 3 }} />
                        {document.name}
                    </S.DocumentItem>
                </div>
            ))}
        </>
    )
}

const RelatedSessions = ({ performerId }: { performerId: number }) => {
    const DEFAULT_AMOUNT_OF_ITEMS = 3
    const INCREMENT_AMOUNT_OF_ITEMS = 3

    const eventSlug = useSlug('eventSlug')
    const [amountOfItems, setAmountOfItems] = useState(DEFAULT_AMOUNT_OF_ITEMS)
    const showMore = () => setAmountOfItems(amountOfItems + INCREMENT_AMOUNT_OF_ITEMS)
    const url = `events/${eventSlug}/virtualprogram/?performer=${performerId}&limit=${amountOfItems}`
    return (
        <Get url={url}>
            {(data: ListProgramsResponse) => (
                <>
                    {(data.results || []).map((performance) => (
                        <RelatedSession
                            performance={performance}
                            key={performance.id}
                        />
                    ))}

                    {amountOfItems < data.count && (
                        <S.ShowMoreWrapper>
                            <S.ShowMoreSessions onClick={showMore}>
                                {_('performer.show_more_session')}
                                <StyledIcon
                                    icon="chevronDown"
                                    color={colors.icon}
                                    style={{ marginLeft: 5 }}
                                />
                            </S.ShowMoreSessions>
                        </S.ShowMoreWrapper>
                    )}
                </>
            )}
        </Get>
    )
}

const Performer = ({ performer }: { performer: PerformerType }) => (
    <div>
        <S.Header>
            <S.PerformerImage src={performer.mugshot || constants.urlThumbnailDefault} alt="Performer Avt" />
            <div style={{marginRight: 40}}>
                <div className="flex-align-center">
                    <S.PerformerName>{performer.name}</S.PerformerName>
                    <Bookmark item={{ type: GoodiebagType.Speaker, id: performer.id }} />
                </div>
                <S.Description>{formatJobInfo(performer)}</S.Description>
                <LocationIndicator location={performer.location_type} />
            </div>
        </S.Header>

        <S.Body>
            <S.Divider />

            <S.Title>{_('performer.about')}</S.Title>
            <S.AboutContent content={performer.about} isHtmlContent />

            <S.Divider />

            <S.Title>{_('performer.documents')}</S.Title>
            <Documents documents={performer.attachments} />

            <S.Divider />

            <S.Title style={{ marginTop: 40 }}>{_('performer.related_sessions')}</S.Title>
            <RelatedSessions performerId={performer.id} />
        </S.Body>
    </div>
)

export default Performer
