import React from 'react'
import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import { PayloadAction } from '@reduxjs/toolkit'
import { RequestVideoAccess, WS } from './videocallSlice'
import { addModal } from '../modals/modalSlice'
import ReceiveAccessRequest from './components/ReceiveAccessRequest'

const pipeAccessRequestsToModal = (action$) => action$.pipe(
    ofType(WS.accessRequested.type),
    map((accessRequest: PayloadAction<RequestVideoAccess>) => {
        const Component = ({ close }: { close: () => void }) => (
            <ReceiveAccessRequest close={close} accessRequest={accessRequest.payload} />
        )
        return addModal({
            Component,
            size: 'sm',
            padding: 0,
            backdrop: 'static'
        })
    }),
)

export default [pipeAccessRequestsToModal]
