import React, { ReactChild, ReactChildren } from 'react'
import intl from 'react-intl-universal'
import { useSelector } from 'react-redux'
import { eventLocaleSelector } from '../features/events/eventSlice'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const EN_JSON = require('../messages/EN-US.json')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const NL_JSON = require('../messages/NL-NL.json')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const DE_JSON = require('../messages/DE-DE.json')

const locales = {
    'en-US': EN_JSON,
    en: EN_JSON,
    nl: NL_JSON,
    'nl-NL': NL_JSON,
    'vi-VN': EN_JSON,
    de: DE_JSON,
    'de-DE': DE_JSON,
}

interface LocaleProviderProps {
    children: ReactChild | ReactChildren;
}

const warningHandler = () => {}

const LocaleProvider = ({ children }: LocaleProviderProps) => {
    const [isLoaded, setLoaded] = React.useState(false)
    const eventLocale = useSelector(eventLocaleSelector)

    React.useEffect(() => {
        intl
            .init({
                currentLocale: eventLocale || 'en-US',
                fallbackLocale: 'en-US',
                locales,
                warningHandler,
            })
            .then(() => { setLoaded(true) })
    }, [eventLocale])

    return isLoaded
        ? <>{children}</>
        : null
}

export default LocaleProvider
