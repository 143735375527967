import { useHistory } from 'react-router-dom'
import useSlug from '../../hooks/useSlug'
import routes from '../../routes'
import { CallConfig } from './types'

export const getPath = (twilioName: string, eventSlug: string, config: CallConfig): string => {
    if (config.networkingSlug) {
        return routes.networkingArea.getPath({
            eventSlug,
            networkingSlug: config.networkingSlug,
            roomId: twilioName,
        })
    }

    if (config.posterSlug) {
        return routes.posters.getPath({
            eventSlug,
            posterSlug: config.posterSlug,
            roomId: twilioName,
        })
    }

    return routes.videoCall.getPath({ eventSlug, roomId: twilioName })
}

const useRedirectToRoom = (twilioName: string, config: CallConfig = {}) => {
    const history = useHistory()
    const eventSlug = useSlug('eventSlug')
    return () => history.push(getPath(twilioName, eventSlug, config))
}

export default useRedirectToRoom
