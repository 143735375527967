import { sortBy } from 'lodash'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { KeyedMutator } from 'swr'
import CollapsiblePanel from '../../../components/CollapsiblePanel'
import Icon from '../../../components/Icon'
import colors from '../../../constants/colors'
import { ScheduleStatus, SimpleAppointmentSchedule } from '../../../types'
import { AppointmentSchedule } from '../../../types/swagger'
import { toLocalTimeFormat } from '../../../utils/datetime'
import { formatName } from '../../../utils/profile'
import { currentUserSelector } from '../../auth/authSlice'
import { eventTimeFormatSelector } from '../../auth/eventPreviewSlice'
import { User } from '../../auth/types'
import JoinAppointmentButton from '../../chat/components/appointment_schedule/JoinAppointmentButton'
import { addModal } from '../../modals/modalSlice'
import { getStatusTranslation } from '../utils'
import AppointmentDetailModal from './AppointmentDetailModal'

const S = {
    RowWrapper: styled.div<{ status: ScheduleStatus }>`
        padding: 8px 0;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr) 200px;
        column-gap: 24px;
        border-bottom: 1px solid ${colors.iconOpacity(0.1)};
        opacity: ${({ status }) => (['CANCELLED', 'DENIED'].includes(status) ? 0.5 : 1)};
        cursor: pointer;
        user-select: none;

        & > div {
            min-width: 0;
        }
        &:last-child {
            border: none;
        }
    `,
    UserInfo: styled.div`
        display: flex;
        align-items: center;
    `,
    Image: styled.img`
        width: 48px;
        height: 48px;
        margin-right: 8px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        flex-shrink: 0;
    `,
    Info: styled.div`
        flex-grow: 1;
        overflow: hidden;
    `,
    Name: styled.p`
        margin-bottom: 4px;
        font-weight: 600;
        color: ${colors.darkTitle};
    `,
    Date: styled.div`
        color: ${colors.icon};
        font-size: 12px;
    `,
    Subject: styled.div`
        font-size: 14px;
        font-weight: 300;
        color: ${colors.icon};
    `,
    Status: styled.div`
        display: flex;
        justify-content: flex-end;
        text-transform: uppercase;
        color: ${colors.icon};
        font-size: 14px;
        font-weight: 500;
    `,
    Location: styled.div`
        display: flex;
        align-items: center;
        color: ${colors.icon};
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
    `,
    Link: styled(Link)<{disabled?: boolean}>`
        text-decoration: none !important;
        ${({disabled}) => disabled && css`
            pointer-events: none;
            opacity: 0.5;
        `}
    `
}

interface Props {
    rowData: AppointmentSchedule[]
    title: string
    defaultExpanded?: boolean
    refresh?: KeyedMutator<AppointmentSchedule[][]>
}

export const getUser = (currentUser: User, appointment: AppointmentSchedule) => (
    appointment.sender_info?.id === currentUser?.id ? appointment.receiver_info : appointment.sender_info
)

const AppointmentRowInList = ({
    rowData, title, defaultExpanded, refresh
}: Props) => {
    const dispatch = useDispatch()
    const currentUser = useSelector(currentUserSelector())
    const timeFormat = useSelector(eventTimeFormatSelector)

    const handleShowAppointmentDetailModal = useCallback((appointment: SimpleAppointmentSchedule) => {
        dispatch(addModal({
            Component: ({close}: {close: () => void}) => (
                <AppointmentDetailModal close={close} appointment={appointment} refresh={refresh} />
            ),
        }))
    }, [dispatch, refresh])

    return (
        <CollapsiblePanel title={title} isDefaultCollapsed={!defaultExpanded}>
            <>
                {sortBy<AppointmentSchedule>(rowData, (e) => new Date(e.start_date)).map((appointment) => (
                    <S.RowWrapper
                        status={appointment.status}
                        key={appointment.id}
                        onClick={() => handleShowAppointmentDetailModal(appointment)}
                    >
                        <S.UserInfo>
                            <S.Image src={getUser(currentUser, appointment).mugshot} />
                            <S.Info>
                                <S.Name className="text-cut notranslate">
                                    {formatName(getUser(currentUser, appointment))}
                                </S.Name>
                                <S.Date>
                                    <span>{toLocalTimeFormat(appointment.start_date, timeFormat)}</span>
                                    <span className="mx-1">-</span>
                                    <span>{toLocalTimeFormat(appointment.end_date, timeFormat)}</span>
                                </S.Date>
                            </S.Info>
                        </S.UserInfo>
                        <S.Subject>{appointment.subject}</S.Subject>
                        <S.Status>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {appointment.status === 'ACCEPTED' ? (
                                // eslint-disable-next-line no-nested-ternary
                                appointment.location ? (
                                    <S.Location>
                                        <Icon icon="mapMarker" size={16} className="mb-2px mr-1" />
                                        {appointment.location}
                                    </S.Location>
                                ) : (appointment.twilio_name ? (
                                    <JoinAppointmentButton appointment={appointment} />
                                ) : getStatusTranslation(appointment.status)
                                )
                            ) : (
                                getStatusTranslation(appointment.status)
                            )}
                        </S.Status>
                    </S.RowWrapper>
                ))}
            </>
        </CollapsiblePanel>
    )
}

export default AppointmentRowInList
