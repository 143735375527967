import { createSlice } from '@reduxjs/toolkit'
import { websocketAction } from '../../utils/redux'

const WS = {
    loginFailed: websocketAction('login_failed'),
    authenticated: websocketAction('authenticated'),
    open: websocketAction('open'),
    reconnected: websocketAction('reconnected'),
    disconnected: websocketAction('disconnected'),
    broken: websocketAction('broken'),
    closed: websocketAction('closed'),
}

const initialState = {
    isConnected: false,
    isLoggedIn: false,
}

const setConnected = (state) => {
    state.isConnected = true
}

const setLoggedIn = (state) => {
    state.isLoggedIn = true
}

const setDisconnected = (state) => {
    state.isConnected = false
    state.isLoggedIn = false
}

const websocketSlice = createSlice({
    name: 'websocket',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(WS.authenticated, setLoggedIn)
        builder.addCase(WS.open, setConnected)
        builder.addCase(WS.reconnected, setConnected)
        builder.addCase(WS.disconnected, setDisconnected)
        builder.addCase(WS.broken, setDisconnected)
        builder.addCase(WS.closed, setDisconnected)
        builder.addCase(WS.loginFailed, setDisconnected)
    },
})

export default websocketSlice.reducer
