import React, { CSSProperties, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    AppBar,
    Badge,
    Box,
    Divider,
    Drawer,
    List,
    ListItemButton,
    Toolbar,
} from '@mui/material'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import useHomeLink from '../../hooks/useHomeLink'
import { RootState } from '../../store'
import LogoPlaceholder from '../../assets/Logo_placeholder.png'
import Icon from '../Icon'
import ProfileDropdown from '../Nav/components/ProfileDropdown'
import { navSelector } from '../../features/events/navigationSlice'
import SpaceNavItemMobile from './SpaceNavItemMobile'
import colors from '../../constants/colors'
import { _ } from '../../utils/localization'
import {
    showAttendeesOutsideSelector,
    showSpeakerIconSelector,
    showCalendarIconSelector,
} from '../../features/auth/eventPreviewSlice'
import AttendeesSidebarToggle from '../Nav/components/AttendeesSidebarToggle'
import CalendarToggle from '../Nav/components/CalendarToggle'
import PerformerSidebarToggle from '../Nav/components/PerformerSidebarToggle'
import { MessagesToggle } from '../Nav/Nav'
import { selectTotalUnread } from '../../features/chat/chatSlice'
import DropdownButton from '../DropdownButton'
import GoogleTranslateButton from '../GoogleTranslateButton'
import HelpContent from "../../features/helpcontent/HelpContent"

const S = {
    LogoLink: styled(Link)`
        height: 100%;
        display: flex;
        align-items: center;
        padding: 16px 0;
        justify-self: center;
   
    `,
    LogoNav: styled.img`
        max-height: 26px;
        max-width: 160px;
        object-fit: cover;
        object-position: center;
    `,
    LogoSidebar: styled.img`
        max-height: 58px;
        max-width: 160px;
        margin: 8px 16px;
        object-fit: cover;
        object-position: top left;
    `,
    SidebarWrapper: styled.div`
        width: min(80vw, 350px);
    `,
    NavigationSectionHeader: styled.div`
        margin-top: 16px;
        padding-left: 16px;
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        color: ${colors.iconOpacity(0.75)};
    `,
}

const IconActionStyle: CSSProperties = {
    border: 'none',
    margin: 0,
    padding: 0,
    width: '100%',
    justifyContent: 'flex-start',
}

const NavMobile = () => {
    const homeLink = useHomeLink()
    const navigation = useSelector(navSelector)
    const event = useSelector((state: RootState) => state.event.current)
    const enablePageTranslation = event.data.enable_page_translation
    const logo = event?.data?.logo || LogoPlaceholder
    const [openSidebar, setOpenSidebar] = useState(false)
    const showAttendeesOutside = useSelector(showAttendeesOutsideSelector)
    const showSpeakerIcon = useSelector(showSpeakerIconSelector)
    const showCalendarIcon = useSelector(showCalendarIconSelector)
    const totalUnread = useSelector(selectTotalUnread)

    return (
        <>
            <AppBar position="fixed" sx={{ height: 58, background: 'white', zIndex: 99 }}>
                <Toolbar
                    sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', minHeight: '58px !important' }}
                >
                    <Badge color="error" variant="dot" invisible={totalUnread <= 0}>
                        <Icon icon="menu" onClick={() => setOpenSidebar(true)} />
                    </Badge>

                    <S.LogoLink to={homeLink}>
                        <S.LogoNav alt="logo" src={logo} />
                    </S.LogoLink>
                    <Box display="flex" justifySelf="end" alignItems="center" sx={{'&>div:first-child': {padding: 0, ml: 0, mr: 2, border: "none"}}}>
                        <HelpContent />
                        <ProfileDropdown hideDropdownIcon />
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={openSidebar}
                onClose={() => setOpenSidebar(false)}
            >
                <S.SidebarWrapper>
                    <S.LogoSidebar src={logo} />
                    {navigation.map((item, index) => (
                        <SpaceNavItemMobile
                            // @ts-ignore
                            item={item}
                            key={index}
                            onCloseSidebar={() => setOpenSidebar(false)}
                        />
                    ))}

                    <S.NavigationSectionHeader>
                        {_('nav.navigation')}
                    </S.NavigationSectionHeader>

                    <List onClick={() => setOpenSidebar(false)} disablePadding sx={{mb: 2}}>
                        {showSpeakerIcon && (
                            <>
                                <ListItemButton>
                                    <PerformerSidebarToggle
                                        style={IconActionStyle}
                                        showLabel
                                    />
                                </ListItemButton>
                                <Divider />
                            </>
                        )}
                        {showCalendarIcon && (
                            <>
                                <ListItemButton>
                                    <CalendarToggle
                                        style={IconActionStyle}
                                        showLabel
                                    />
                                </ListItemButton>
                                <Divider />
                            </>
                        )}
                        {/* <ListItemButton onClick={() => dispatch(expand())}>
                            <Icon icon="timetable" size={20} />
                            <div style={{fontSize: 14, marginLeft: 14}}>{_('timetable.timetable')}</div>
                        </ListItemButton> */}
                        <Divider />
                        {showAttendeesOutside && (
                            <>
                                <ListItemButton>
                                    <AttendeesSidebarToggle
                                        style={IconActionStyle}
                                        showLabel
                                    />
                                </ListItemButton>
                                <Divider />
                            </>
                        )}
                        <>
                            <ListItemButton>
                                <MessagesToggle style={IconActionStyle} showLabel />
                            </ListItemButton>
                        </>
                        <Divider />
                        {enablePageTranslation && (
                            <DropdownButton name="Translate" className="mx-2 mt-2">
                                <GoogleTranslateButton />
                            </DropdownButton>
                        )}
                    </List>
                </S.SidebarWrapper>
            </Drawer>
        </>
    )
}

export default NavMobile
