import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
    SerializedError,
} from '@reduxjs/toolkit'
import api from '../../utils/api'
import { ListBoothSpacesResponse } from '../../types/__generated_swagger'
import { RequestStatus } from '../../types'
import { RootState } from '../../store'

interface fetchBoothsProps {
    eventSlug: string
    mapSlug: string
    search: string
    categoryId?: undefined | number | string
}

export const fetchBooths = createAsyncThunk(
    'FETCH_BOOTHS',
    async ({
        eventSlug, mapSlug, categoryId = '', search = ''
    }: fetchBoothsProps) => {
        // Because we use the carousel to show the booth and can access any booth on map.
        // If we go to the booth which isn't available for first fetch booth list
        // (eg: limit = 10 and that booth is the 15th booth), it isn't in carousel list to slide and carousel doesn't work.
        // In that case, we need to determine the exact index of that booth in booth list,
        // then fetch the data including that booth (limit = 16 for above example) -> It's quite complex to implement.

        // Beside, the data from API aren't much. We can fetch all booths for this API
        // And do some hacks to paginate booth list in sidebar at frontend.
        const params = `?category_id=${categoryId}&search=${search}&limit=999`
        const url = mapSlug ? `/events/${eventSlug}/maps/${mapSlug}/booths/${params}` : `/events/${eventSlug}/booths/${params}`
        const response = await api.get<ListBoothSpacesResponse>(url)
        if (!categoryId && !search) {
            return {
                ...response,
                totalBooths: response.count
            }
        }
        return response
    },
)

const boothsSlice = createSlice({
    name: 'boothSlice',
    initialState: {
        data: null as ListBoothSpacesResponse['results'],
        status: 'idle' as RequestStatus,
        error: null as SerializedError & {detail?: string, code?: string, error?: string},
        limit: 15 as number,
        totalBooths: 0,
    },
    reducers: {
        emptyData: (state) => {
            state.data = null
            state.status = 'idle'
        },
        setLimit: (state, { payload }: PayloadAction<number>) => {
            state.limit = payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchBooths.fulfilled,
            (state, { payload }: PayloadAction<ListBoothSpacesResponse & {totalBooths?: number}>) => {
                state.data = payload.results
                if (payload.totalBooths) {
                    state.totalBooths = payload.totalBooths
                }
                state.status = 'succeeded'
            }
        )
        builder.addCase(fetchBooths.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchBooths.rejected, (state, action) => {
            state.status = 'failed'
            state.data = null
            state.error = action.payload ? action.payload : action.error
        })
    },
})

export const limitSelector = (state: RootState) => state.exhibition?.list?.limit
export const boothListSelector = (state: RootState) => state.exhibition?.list?.data
export const boothListRequestStatusSelector = (state: RootState) => state.exhibition?.list?.status

export const setBoothLimit = boothsSlice.actions.setLimit

export default boothsSlice.reducer
