import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { MeetingItem } from '../types'
import getMugshotSrc from '../../../utils/getMugshotSrc'
import { formatJobInfo, formatName } from '../../../utils/profile'
import CapacityAndDateTime from './CapacityAndDateTime'
import JoinMeetingButton from './JoinMeetingButton'
import { _ } from '../../../utils/localization'
import openChatModal from '../../chat/openChatModal'
import useSlug from '../../../hooks/useSlug'
import { currentUserSelector } from '../../auth/authSlice'
import openBusinessCard from '../../profile/openBusinessCard'
import { User } from '../../auth/types'
import colors from '../../../constants/colors'
import showMeetingInfoModal from './showMeetingInfoModal'
import useFetchAttendees from '../../attendees/useFetchAttendees'
import Bookmark from '../../goodiebag/Bookmark'
import { GoodiebagType } from '../../goodiebag/types'
import Icon from '../../../components/Icon'
import ThumbnailWithCounter from '../../../components/ThumbnailWithCounter'
import usePrevious from '../../../hooks/usePrevious'
import WalkInMeetingImage from '../../../assets/Walk-in-meeting.png'
import { VirtualAttendee } from '../../../types/swagger'
import { attendeeForUsersStatusSelector } from '../../attendees/attendeesSlice'
import { RequestStatus } from '../../../types'

const S = {
    Body: styled(Card.Body)`
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 376px;
    `,
    BookMarkPosition: styled.div`
      position: absolute;
    `,
    InfoWrapper: styled.div`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
    `,
    Name: styled.p`
      font-size: 16px;
      font-weight: 500;
      height: 24px;
      margin: 0;
    `,
    JobInfo: styled.p`
      color: ${colors.regentGray};
      font-size: 12px;
      margin-top: 0;
      margin-bottom: 3px;
      height: 18px;
    `,
    MeetingTitle: styled.div`
      font-size: 16px;
      font-weight: 500;
      height: 68px;
      display: flex;
      align-items: center;
    `,
    CapacityAndDateTime: styled.div`
      height: 48px;
      display: flex;
      width: 100%;
    `,
    Hr: styled.hr`
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    `,
    Buttons: styled.div`
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 0 0 0;
    `,
    WaitForHost: styled.div`
      display: flex;
      align-items: center;
      color: ${colors.mineShaft};
      font-size: 14px;
    `,
    Bookmark: styled.div`
      position: absolute;
      top: 20px;
      left: 20px;
    `,
    MessageIcon: styled.div`
      position: absolute;
      top: 20px;
      right: 20px;
    `,
}

interface ThumbnailInfoProps {
    primarySpeaker: VirtualAttendee
    isWalkInMeeting: boolean
    speakerCounter: number
    onClick: () => void
    requestStatus: RequestStatus
    primarySpeakerId?: number
}

const ThumbnailInfo = ({
    primarySpeaker, isWalkInMeeting, speakerCounter, onClick, requestStatus, primarySpeakerId
}: ThumbnailInfoProps) => (
    <S.InfoWrapper onClick={onClick}>
        <ThumbnailWithCounter
            image={isWalkInMeeting ? WalkInMeetingImage : getMugshotSrc(primarySpeaker)}
            size={84}
            counter={speakerCounter}
        />
        {isWalkInMeeting ? (
            <S.Name className="text-cut-line-1">
                {_('meeting.walk_in_meeting')}
            </S.Name>
        ) : (
            <S.Name className="text-cut-line-1">
                {
                    // We have to use primarySpeakerId here instead of primarySpeaker
                    // Because primarySpeaker is undefined if that user's invite is still pending (he hasn't ever logged in yet)
                    // eslint-disable-next-line no-nested-ternary
                    primarySpeakerId ? (
                        (requestStatus === 'succeeded' && !primarySpeaker?.last_login)
                            ? _('meeting.host_not_confirmed')
                            : formatName(primarySpeaker)
                    ) : _('meeting.no_host_assigned')
                }
            </S.Name>
        )}
        <S.JobInfo className="text-cut-line-1">
            {isWalkInMeeting ? '' : formatJobInfo(primarySpeaker)}
        </S.JobInfo>
    </S.InfoWrapper>
)

const MeetingCard = ({ meeting }: { meeting: MeetingItem }) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const currentUser = useSelector(currentUserSelector())
    const isHost = meeting?.host_user_ids?.includes(currentUser.id)
    const isPrimarySpeaker = meeting?.primary_speaker_id === currentUser.id
    const [fetchedPrimarySpeaker] = useFetchAttendees([meeting?.primary_speaker_id])
    const prevFetchedPrimarySpeaker = usePrevious(fetchedPrimarySpeaker, true)
    const [primarySpeaker, setPrimarySpeaker] = useState(undefined)
    const attendeeForUsersStatus = useSelector(attendeeForUsersStatusSelector)
    const [requestStatus, setRequestStatus] = useState<RequestStatus>('idle')

    useEffect(() => {
        // When opening the chat modal, the useFetchAttendees is called with a different array,
        // then closing modal, the useFetchAttendees in this component is re-called,
        // So the fetchedPrimarySpeaker will be refreshed (currentValue -> null -> currentValue). That causes the card flash.
        // If the fetchedPrimarySpeaker is null and the previous fetched host is available, we don't update the host.
        if (!fetchedPrimarySpeaker && prevFetchedPrimarySpeaker) {
            return
        }

        setPrimarySpeaker(isPrimarySpeaker ? currentUser : fetchedPrimarySpeaker)

        // To avoid flashing the host name after fetching data
        setRequestStatus(attendeeForUsersStatus)
    }, [
        attendeeForUsersStatus,
        currentUser,
        fetchedPrimarySpeaker,
        isPrimarySpeaker,
        prevFetchedPrimarySpeaker
    ])

    // Checking for host prevents the host name being displayed as "undefined undefined" during loading
    return (
        <Card>
            <S.Body>
                <S.Bookmark>
                    <Bookmark item={{ id: meeting.id, type: GoodiebagType.MeetingExpert }} />
                </S.Bookmark>
                <ThumbnailInfo
                    primarySpeaker={primarySpeaker}
                    primarySpeakerId={meeting?.primary_speaker_id}
                    speakerCounter={meeting?.speaker_count || 0}
                    isWalkInMeeting={meeting?.is_walk_in_meeting}
                    onClick={() => primarySpeaker && dispatch(openBusinessCard(primarySpeaker as User))}
                    requestStatus={requestStatus}
                />
                {meeting.enable_host_chat && !isPrimarySpeaker && primarySpeaker && (
                    <S.MessageIcon>
                        <Icon
                            icon="messageText"
                            onClick={() => dispatch(openChatModal(eventSlug, meeting?.primary_speaker_id))}
                            size={20}
                        />
                    </S.MessageIcon>
                )}
                <S.Hr />
                <S.InfoWrapper onClick={() => dispatch(showMeetingInfoModal(meeting))}>
                    <S.MeetingTitle>
                        <div className="text-cut-line-1">{meeting.title}</div>
                    </S.MeetingTitle>
                    <S.Hr />
                    <S.CapacityAndDateTime>
                        <CapacityAndDateTime meeting={meeting} showDate />
                    </S.CapacityAndDateTime>
                </S.InfoWrapper>
                <S.Hr />
                <S.Buttons>
                    {!meeting.is_host_in_meeting && !isHost && !meeting.is_walk_in_meeting
                        ? (
                            <S.WaitForHost>
                                <Icon
                                    icon="info"
                                    color={colors.primary}
                                    size={18}
                                    style={{ marginBottom: 3, marginRight: 6 }}
                                />
                                {_('meetingspace.waiting_for_host')}
                            </S.WaitForHost>
                        ) : (
                            <div />
                        )}
                    <JoinMeetingButton meeting={meeting} location="card" />
                </S.Buttons>
            </S.Body>
        </Card>
    )
}
export default MeetingCard
