import React from 'react'
import Container from 'react-bootstrap/Container'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { currentUserSelector, getCurrentUser } from '../authSlice'
import auth from '../../../utils/auth'
import { showErrorNotification } from '../../notifications/notificationsSlice'
import env from '../../../constants/env'
import ProfileBadge from '../../profile/ProfileBadge'
import { addModal } from '../../modals/modalSlice'

// dev only file so...
/* eslint-disable react/prop-types */

const Login = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const currentUser = useSelector(currentUserSelector())
    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')

    // Redirect to home if user is logged in
    React.useEffect(() => {
        if (currentUser && currentUser.id) {
            history.push('/')
        }
    }, [currentUser, history])

    const onSubmit = async (event) => {
        event.preventDefault()
        const response = await auth.login({ username, password })
        const { error } = response
        if (error) {
            dispatch(showErrorNotification(error))
        } else {
            dispatch(getCurrentUser())
            setTimeout(() => {
                dispatch(addModal({
                    Component: ({close}: {close: () => void}) => (
                        <ProfileBadge close={close} />
                    ),
                    padding: 0,
                }))
            }, 1000)
        }
    }

    if (!env.debug) {
        return null
    }

    return (
        <Container>
            <br />
            <br />
            <h1>Login</h1>
            <br />
            <form onSubmit={onSubmit}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="username"><b>Username</b></label>
                <input
                    type="text"
                    placeholder="Enter Username"
                    name="username"
                    required
                    onChange={(e) => setUsername(e.target.value)}
                />

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="password"><b>Password</b></label>
                <input
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                />

                <button type="submit">Login</button>
            </form>
        </Container>
    )
}

export default Login
