import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import React from 'react'
import { WS } from './inviteActions'
import InviteeModal from './components/InviteeModal'
import { addModal } from '../modals/modalSlice'
import { CallConfig } from './types'
import { ReceivedFromUserPayload } from '../websocket/sendToUser'

const pipeNewVideocallInviteToModal = (action$) => action$.pipe(
    ofType(WS.newVideoCallInvited.type),
    map(({ payload }: { payload: ReceivedFromUserPayload<CallConfig> }) => {
        const Component = ({ close }: { close: () => void }) => (
            <InviteeModal
                close={close}
                inviterId={payload.sender}
                config={payload?.data || {}}
            />
        )
        return addModal({
            Component,
            size: 'sm',
            padding: 0,
            backdrop: 'static'
        })
    }),
)

export default [pipeNewVideocallInviteToModal]
