import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../utils/api'
import createRequestSlice from '../../utils/createRequestSlice'
import { _ } from '../../utils/localization'
import { showNotification } from '../notifications'

export interface Category {
    id: number;
    name: string;
}

export type RequestBody = {
    staff?: number
    message?: string
    include_email?: boolean
    is_auto?: boolean
    ignore_incognito_mode?: boolean
}

interface LeaveBusinessCardParams {
    eventSlug: string
    spaceSlug: string
    requestBody: RequestBody
    preventNotification?: boolean
}

export const leaveBusinessCard = createAsyncThunk(
    'LEAVE_BUSINESS_CARD',
    async ({
        eventSlug, spaceSlug, requestBody, preventNotification
    }: LeaveBusinessCardParams, thunkAPI) => {
        const url = `events/${eventSlug}/booths/${spaceSlug}/give-business-card/`
        try {
            const response = await api.post(url, requestBody)
            if (!preventNotification) {
                thunkAPI.dispatch(showNotification({
                    body: _('booth.leave_businesscard.success'),
                    type: 'success',
                    displayDuration: 3000,
                }))
            }
            return response
        } catch (error) {
            if (!preventNotification) {
                thunkAPI.dispatch(showNotification({
                    body: _('booth.leave_businesscard.error'),
                    type: 'error',
                    displayDuration: 3000,
                }))
            }
            return thunkAPI.rejectWithValue(error)
        }
    },
)

const leaveBusinessCardSlice = createRequestSlice(leaveBusinessCard, 'leaveBusinessCardSlice')

export const emptyData = leaveBusinessCardSlice.actions.emptyData

export default leaveBusinessCardSlice.reducer
