import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import FullPageLoading from '../../components/FullPageLoading'
import useSlug from '../../hooks/useSlug'
import { _ } from '../../utils/localization'
import Performers from './components/Performers'
import Programs from './components/Programs'
import {
    clearData, searchSelector, searchEntireEvent, entireEventSearchDataSelector
} from './searchEntireEventSlice'
import { SearchItemType } from './types'

const S = {
    Wrapper: styled.div<{ isExpanding?: boolean }>`
        height: 100%;
        width: ${({ isExpanding }) => (isExpanding ? '100%' : '606px')};
        padding: 16px;
        overflow: auto;
    `
}

const typeToComponent = {
    [SearchItemType.ProgramItem]: Programs,
    [SearchItemType.Speaker]: Performers,
}

const EventSearch = ({ isExpanding }: { isExpanding?: boolean }) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const search = useSelector(searchSelector)
    const { data, requestStatus } = useSelector(entireEventSearchDataSelector)

    useEffect(() => {
        if (!search) {
            dispatch(clearData())
            return
        }

        dispatch(searchEntireEvent({ eventSlug, search }))
    }, [dispatch, eventSlug, search])

    if (requestStatus === 'succeeded' && !data.length) {
        return <div>{_('general.no_data_available')}</div>
    }

    return (
        <S.Wrapper isExpanding={isExpanding}>
            {data.map((category) => {
                const Component = typeToComponent[category.type]
                if (!Component) {
                    return <></>
                }

                return <Component data={category} key={category.type} />
            })}
            {requestStatus === 'loading' && <FullPageLoading isAbsolute />}
        </S.Wrapper>
    )
}

export default EventSearch
