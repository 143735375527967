import { DateTime, isInBetween, toDate } from '../../utils/datetime'
import { MeetingItem } from './types'

export const isLive = (meeting: MeetingItem, dateTime: DateTime): boolean => (
    isInBetween(meeting.starts_at, meeting.ends_at, toDate(dateTime))
)

export const isUpcoming = (meeting: MeetingItem, dateTime: DateTime): boolean => (
    toDate(meeting.starts_at) >= toDate(dateTime)
)

export const isAbleToJoin = (
    meeting: MeetingItem, currentUserId: number, dateTime: DateTime, isInvited: boolean
): boolean => {
    // the meeting is full and current user isn't invited
    if (meeting.capacity_left === 0 && !isInvited) {
        return false
    }

    // the meeting is not in progress
    if (!isLive(meeting, dateTime)) {
        return false
    }

    // TODO: do authorization server-side
    if (meeting.participating_users.includes(currentUserId)) {
        return true
    }

    return !meeting.closed
}

export enum MeetingTypes {
    myMeetings = 'myMeetings',
    upcoming = 'upcoming',
    live = 'live'
}
