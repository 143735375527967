import React, { useEffect, useState } from 'react'
import Tabs from 'react-bootstrap/cjs/Tabs'
import Tab from 'react-bootstrap/cjs/Tab'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { _ } from '../../../utils/localization'
import MeetingCards from './MeetingCards'
import {
    fetchLiveMeetingList,
    fetchMyMeetingList,
    fetchUpcomingMeetingList,
    liveMeetingsCount,
    myMeetingsCount,
    selectLiveMeetingList,
    selectMyMeetingList,
    selectUpcomingMeetingList,
    upcomingMeetingsCount
} from '../meetingListSlice'
import useSlug from '../../../hooks/useSlug'
import { MeetingTypes } from '../utils'
import { withCapture } from '../../../utils/errorTracker'
import showMeetingInfoModal from './showMeetingInfoModal'
import MeetingImagePlaceholder from '../../../assets/Meeting_placeholder.jpg'

const S = {
    Page: styled.div<{ image?: string }>`
      width: 100%;
      background: #f9f9f9;
      min-height: 100%;
      background-image: url('${({ image }) => (image)}');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
      background-position: center;
    `,
    Background: styled.div`
      background: white;
      width: 100%;
      height: 95px;
      margin-bottom: -95px;
    `,
    MeetingsOverviewContainer: styled.div`
      max-width: 1500px;
      margin-left: auto;
      margin-right: auto;
    `,
    Tabs: styled(Tabs)`
        width: 100%;
        padding-left: 10px;
        overflow: auto;
        scrollbar-width: none; /* for Firefox */
        flex-wrap: nowrap;
        &::-webkit-scrollbar {
            display: none;
        }
        & > a {
            flex-shrink: 0;
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border: none!important;
            transition: 0.3s;
            text-transform: uppercase;
            &[aria-selected="true"] {
                border-bottom: 3px solid gray!important;
            }
            @media (max-width: 932px) {
                width: max-content;
            }
        }
    `,
    SpaceHeader: styled.h4`
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        margin-left: 20px;
    `,
}

// todo these components with less copying
const UpcomingMeetingsTab = () => {
    const history = useHistory()
    const eventSlug = useSlug('eventSlug')
    const meetingSlug = useSlug('meetingSlug')
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)
    const meetings = useSelector(selectUpcomingMeetingList)
    const name = MeetingTypes.upcoming
    const meetingCount = useSelector(upcomingMeetingsCount)

    const selectedMeetingId = withCapture(() => (
        +new URLSearchParams(history.location.search).get('meetingId')
    ))

    // todo add status while making sure not the entire tab turns into a loader
    //    when new meetings are loaded
    // const status = useSelector(selectUpcomingListFetchStatus)

    const loadMore = () => {
        setPage(page + 1)
    }

    useEffect(() => {
        dispatch(fetchUpcomingMeetingList({ eventSlug, meetingSlug, page }))
    }, [dispatch, eventSlug, meetingSlug, page])

    const selectedMeeting = meetings.find((v) => v.id === selectedMeetingId)
    useEffect(() => {
        if (selectedMeeting) {
            dispatch(showMeetingInfoModal(selectedMeeting))
            // remove search from history
            history.replace(history.location.pathname)
        }
    }, [dispatch, history, selectedMeeting])

    return (
        <MeetingCards meetings={meetings} name={name} loadMore={loadMore} meetingCount={meetingCount} />
    )
}

const LiveMeetingsTab = () => {
    const eventSlug = useSlug('eventSlug')
    const meetingSlug = useSlug('meetingSlug')
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)
    const meetings = useSelector(selectLiveMeetingList)
    const meetingCount = useSelector(liveMeetingsCount)

    const name = MeetingTypes.live

    const loadMore = () => {
        setPage(page + 1)
    }

    useEffect(() => {
        dispatch(fetchLiveMeetingList({ eventSlug, meetingSlug, page }))
    }, [dispatch, eventSlug, meetingSlug, page])

    return (
        <MeetingCards meetings={meetings} name={name} loadMore={loadMore} meetingCount={meetingCount} />
    )
}

const MyMeetingsTab = () => {
    const eventSlug = useSlug('eventSlug')
    const meetingSlug = useSlug('meetingSlug')
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)
    const meetings = useSelector(selectMyMeetingList)
    const name = MeetingTypes.myMeetings
    const meetingCount = useSelector(myMeetingsCount)

    const loadMore = () => {
        setPage(page + 1)
    }

    useEffect(() => {
        dispatch(fetchMyMeetingList({ eventSlug, meetingSlug, page }))
    }, [dispatch, eventSlug, meetingSlug, page])

    return (
        <MeetingCards meetings={meetings} name={name} loadMore={loadMore} meetingCount={meetingCount} />
    )
}

const MeetingsOverview = ({ space } : { space: { image?: string, name?: string } }) => {
    const [activeTab, setActiveTab] = useState(MeetingTypes.upcoming)
    const onTabSelect = (eventKey) => {
        setActiveTab(eventKey)
    }
    const _myMeetingsCount = useSelector(myMeetingsCount)
    const _liveMeetingsCount = useSelector(liveMeetingsCount)
    const _upcomingMeetingsCount = useSelector(upcomingMeetingsCount)

    return (
        <S.Page image={space?.image || MeetingImagePlaceholder}>
            <S.Background />
            <S.MeetingsOverviewContainer>
                <S.SpaceHeader>{space?.name}</S.SpaceHeader>
                <S.Tabs
                    defaultActiveKey={activeTab}
                    activeKey={activeTab}
                    onSelect={onTabSelect}
                >
                    <Tab
                        eventKey={MeetingTypes.myMeetings}
                        title={`${_('meetingspace.tab_headers.my_meetings')} (${_myMeetingsCount})`}
                    >
                        <MyMeetingsTab />
                    </Tab>
                    <Tab
                        eventKey={MeetingTypes.upcoming}
                        title={`${_('meetingspace.tab_headers.upcoming')} (${_upcomingMeetingsCount})`}
                    >
                        <UpcomingMeetingsTab />
                    </Tab>
                    <Tab
                        eventKey={MeetingTypes.live}
                        title={`${_('meetingspace.tab_headers.live')} (${_liveMeetingsCount})`}
                    >
                        <LiveMeetingsTab />
                    </Tab>
                </S.Tabs>
            </S.MeetingsOverviewContainer>
        </S.Page>
    )
}

export default MeetingsOverview
