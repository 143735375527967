import React, { useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/cjs/Form'
import styled from 'styled-components'
import { RootState } from '../../../store'
import { currentUserSelector } from '../../auth/authSlice'
import useSlug from '../../../hooks/useSlug'
import { updateRoomName, updateRoomStatus } from '../videocallSlice'
import { _ } from '../../../utils/localization'
import colors from '../../../constants/colors'
import { VideocallRoomStatus } from '../types'

interface VideocallStatusOption {
    text: string
    value: VideocallRoomStatus
}

const videocallStatusOptions: Record<VideocallRoomStatus, VideocallStatusOption> = {
    open: {
        value: 'open',
        get text() { return _('videocall.meetingstatus.open', {}, false) },
    },
    request: {
        value: 'request',
        get text() { return _('videocall.meetingstatus.request', {}, false) },
    },
    closed: {
        value: 'closed',
        get text() { return _('videocall.meetingstatus.closed', {}, false) },
    },
}

const S = {
    RoomNameWrapper: styled.div`
      display: flex;
      min-width: 300px;
      justify-content: space-between;
`,
    RoomName: styled.h4`
      margin-right: 16px;     
`,
    RoomInfoWrapper: styled.div`
      display: flex;
      flex-direction: column;     
      margin-right: 80px;
`,
    PrivacyStatus: styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
`,
    ChangeTitleLink: styled.span`
        text-decoration: underline;
        color: ${colors.orange};
        cursor: pointer;
    `,
}

const EditRoomName = ({ close }: { close: () => void }) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const inputRef = useRef<HTMLInputElement>()
    const { display_name, id } = useSelector((state: RootState) => state.videocall.current)
    const [roomName, setRoomName] = useState(display_name)

    const changeRoomName = (e) => {
        e.preventDefault()
        dispatch(updateRoomName({ name: roomName, roomId: id, eventSlug }))
        close()
    }

    useLayoutEffect(() => { inputRef.current.focus() })

    return (
        <form onSubmit={changeRoomName}>
            <Form.Control
                ref={inputRef}
                type="text"
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
                onBlur={changeRoomName}
            />
            {/* Hidden submit for form submit accessability */}
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="submit" style={{ display: 'none' }} />
        </form>
    )
}

const RoomName = () => {
    const call = useSelector((state: RootState) => state.videocall.current)
    const [isEditingRoomName, setIsEditingRoomName] = useState(false)
    const currentUser = useSelector(currentUserSelector())
    return (
        <S.RoomNameWrapper>
            {
                isEditingRoomName
                    ? <EditRoomName close={() => setIsEditingRoomName(false)} />
                    : (
                        <>
                            <S.RoomName>{call?.display_name}</S.RoomName>
                            {
                                currentUser.id === call?.admin_id
                                    ? (
                                        <S.ChangeTitleLink
                                            onClick={() => setIsEditingRoomName(!isEditingRoomName)}
                                        >
                                            {_('videocall.change_meeting_title')}
                                        </S.ChangeTitleLink>
                                    )
                                    : ''
                            }

                        </>
                    )
            }
        </S.RoomNameWrapper>
    )
}

const RoomStatus = () => {
    const call = useSelector((state: RootState) => state.videocall.current)
    const currentUser = useSelector(currentUserSelector())
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()

    const setRoomStatus = (newStatus) => {
        dispatch(updateRoomStatus({ status: newStatus, roomId: call?.id, eventSlug }))
    }

    return call && (
        <S.PrivacyStatus>
            <span>{_('videocall.privacy_status')}</span>
            <DropdownButton
                variant="secondary"
                id="dropdown-basic-button"
                title={videocallStatusOptions[call.status].text}
                onSelect={setRoomStatus}
                disabled={currentUser?.id !== call?.admin_id}
            >
                {
                    Object.values(videocallStatusOptions).map((statusOption) => (
                        <Dropdown.Item
                            key={statusOption.value}
                            eventKey={statusOption.value}
                        >
                            {_(statusOption.text)}
                        </Dropdown.Item>
                    ))
                }
            </DropdownButton>
        </S.PrivacyStatus>
    )
}

const DefaultBottomBarContent = () => (
    <S.RoomInfoWrapper>
        <RoomName />
        <RoomStatus />
    </S.RoomInfoWrapper>
)

export default DefaultBottomBarContent
