import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../utils/api'
import createRequestSlice from '../../utils/createRequestSlice'

export type RequestBody = {
    done?: boolean
    user?: number
    assigned_to?: number
    booth?: number
}

interface LeaveBusinessCardParams {
    eventSlug: string
    boothSlug: string
    id: number
    requestBody: RequestBody
}

export const manageBusinessCard = createAsyncThunk(
    'MANAGE_BUSINESS_CARD',
    async ({
        eventSlug, boothSlug, id, requestBody
    }: LeaveBusinessCardParams) => {
        const url = `/events/${eventSlug}/organizer/booths/${boothSlug}/business-cards/${id}/`
        return api.put(url, requestBody)
    },
)

const manageBusinessCardSlice = createRequestSlice(manageBusinessCard, 'manageBusinessCardSlice')

export const emptyData = manageBusinessCardSlice.actions.emptyData

export default manageBusinessCardSlice.reducer
