import * as Sentry from '@sentry/react'
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import api from '../../utils/api'
import auth, { Jwt } from '../../utils/auth'
import { User } from './types'
import { RootState } from '../../store'
import { withCapture } from '../../utils/errorTracker'
import { getRefreshToken, isTokenExpired } from '../../utils/jwt'
import logout from './logout'
import constants from '../../constants/constants'

export interface AuthState {
    readonly isLoadingAuth: boolean
    readonly isLoggedIn: boolean
    readonly user?: User
    readonly accessToken?: string
}

const initAuthState: AuthState = {
    isLoadingAuth: true,
    isLoggedIn: false,
    user: undefined,
    // TODO remove accessToken from redux state sent to sentry
    accessToken: undefined,
}

export const activateUser = createAsyncThunk(
    'ACTIVATE_USER',
    async ({ activationKey, eventSlug }: { activationKey, eventSlug }) => {
        const user = { terms_agreed: true }
        const data = { activation_key: activationKey, user }
        const response = await api.put<Jwt>(`/profiles/activate/?event_slug=${eventSlug}`, data)
        auth.storeTokens(response)
        return 'response'
    },
)

export const getCurrentUser = createAsyncThunk(
    'CURRENT_USER_ACTION',
    async (params, thunkAPi) => {
        let eventSlug = window.location.pathname.split('/')?.[1] || ''
        // We first call /auth/current_user/, because the "refreshToken"
        // endpoint is slow, and if the current_user endpoint fails
        // the refreshToken will also fail.
        // We choose to not call them both at the same time, because
        // we want to optimize load time for first login. And for this case
        // calling them both at the same time will result in longer load.
        const refreshToken = getRefreshToken()
        if (isTokenExpired(refreshToken)) {
            return thunkAPi.rejectWithValue('Refresh Token is expired')
        }
        const noEventSlugPaths = ['login', 'logout', 'remove-account', 'remove-account-success', 'reactivate-account']
        if (noEventSlugPaths.includes(eventSlug)) {
            eventSlug = ''
        }
        const user = await api.get(`/auth/current_user/?event_slug=${eventSlug}`)
        withCapture(() => Sentry.setUser({ id: user.id }))
        const accessToken = await auth.refreshToken()
        return { user, accessToken }
    },
)

export const updatePrivacyStatus = createAsyncThunk(
    'UPDATE_CURRENT_USER',
    async ({ eventSlug, is_public }: {eventSlug: string, is_public: boolean}) => {
        await api.patch(`/events/${eventSlug}/update_privacy_status/`, { is_public })
    },
)

const setUser = (state: AuthState, action): AuthState => (!action.payload.user.id
    ? onUnauthenticated()
    : ({
        isLoadingAuth: false,
        isLoggedIn: true,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
    }))

const onUnauthenticated = (): AuthState => ({
    isLoadingAuth: false,
    isLoggedIn: false,
    user: undefined,
    accessToken: undefined,
})

const authSlice = createSlice({
    name: 'auth',
    initialState: initAuthState,
    reducers: {
        editUser: (state, { payload }) => {
            state.user = { ...state.user, ...payload }
        },
        removeUser: () => onUnauthenticated(),
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentUser.fulfilled, (state, action) => setUser(state, action))
        builder.addCase(getCurrentUser.rejected, () => onUnauthenticated())
        builder.addCase(logout.fulfilled, () => onUnauthenticated())
    },
})

export const currentUserSelector = () => createSelector(
    [(state: RootState) => state.auth],
    ({ user }) => {
        if (user?.incognito_mode) {
            return {
                ...user,
                mugshot: constants.urlThumbnailDefault,
                first_name: user?.alias_name || 'Anonymous',
                last_name: '',
                jobtitle: (user?.hidden_profile_fields || []).includes('jobtitle') ? '' : user.jobtitle,
                company: (user?.hidden_profile_fields || []).includes('company') ? '' : user.company,
            }
        }
        return user
    },
)

export const isAuthenticatedSelector = () => createSelector(
    [(state: RootState) => state.auth],
    ({ user }) => !!user,
)

export const isEventOwnerSelector = (eventSlug: string) => (state: RootState) => {
    const user = state.auth.user
    return !!(user?.owned_events || []).find((event) => event.slug === eventSlug)
}

export const updateCurrentUser = authSlice.actions.editUser
export const removeCurrentUser = authSlice.actions.removeUser
export default authSlice.reducer
