import { ListGoodiebagItemsResponse } from '../../types/__generated_swagger'

type GoodiebagResult = ListGoodiebagItemsResponse['results'][0]
export type SpeakerData = GoodiebagResult['performer']
export type AttendeeData = GoodiebagResult['user']
export type BoothData = GoodiebagResult['booth']
export type PosterData = GoodiebagResult['poster']
export type MeetingData = GoodiebagResult['meeting']

// Items that a user can view on the goodiebag page
export enum GoodiebagCategory {
    File = 'file',
    ProgramItem = 'show',
    Speaker = 'performer',
    Attendee = 'user',
    Booth = 'booth',
    Poster = 'poster',
    MeetingExpert = 'meeting',
    GroupSession = 'session',
}

// Items a user can add / remove from goodiebag
export enum GoodiebagType {
    ProgramItem = 'show',
    Speaker = 'performer',
    Attendee = 'user',
    Booth = 'booth',
    Poster = 'poster',
    MeetingExpert = 'meeting',
}

export interface Goodiebag {
    type: GoodiebagType
    id: number
}

export const isType = (v: string) => (
    Object.values(GoodiebagType).includes(v as GoodiebagType)
)
