import sendToUser, { receivedFromUserAction } from '../websocket/sendToUser'
import { CallConfig } from './types'

export interface InviteAcceptedProps {
    twilioName: string
}

export const WS = {
    newVideoCallInvited: receivedFromUserAction<CallConfig>('invited_for_new_videocall'),
    inviteAccepted: receivedFromUserAction<InviteAcceptedProps>('invite_accepted'),
    inviteRejected: receivedFromUserAction('invite_rejected'),
    inviteCancelled: receivedFromUserAction('invite_canceled'),
}

export const inviteToCall = (receiver: number, config: CallConfig) => sendToUser({
    event: 'invited_for_new_videocall',
    receiverId: receiver,
    data: config,
})

export const acceptInvite = (receiver: number, twilioName: string) => sendToUser({
    event: 'invite_accepted',
    receiverId: receiver,
    data: { twilioName },
})

export const rejectInvite = (receiver: number) => sendToUser({
    event: 'invite_rejected',
    receiverId: receiver,
})

export const cancelInvite = (receiver: number) => sendToUser({
    event: 'invite_canceled',
    receiverId: receiver,
})
