import styled, { css } from 'styled-components'
import Tabs from 'react-bootstrap/cjs/Tabs'
import ListGroup from 'react-bootstrap/cjs/ListGroup'
import colors from '../../../constants/colors'
import styles from '../../../constants/styles'

const mediumGray = colors.gray5
const lightGray = colors.gray3

const S = {
    SideBar: styled.div<{ isExpanding: boolean }>`
        background: white;
        width: ${({ isExpanding }) => (isExpanding ? 2 * styles.sidebarWidth : styles.sidebarWidth)}px;
        height: 100%;
        position: fixed;
        right: 0;
        transition: width 0.3s;

        &:hover .expand-button {
            opacity: 1;
            visibility: visible;
        }

        @media (max-width: 1366px) {
            width: ${({ isExpanding }) => (isExpanding ? 2 * styles.smallerSidebarWidth : styles.smallerSidebarWidth)}px;
        }
    `,
    SideBarTop: styled.div`
        background: ${colors.mineShaft};
        width: 100%;
        position: relative;
    `,
    ExpandButton: styled.div<{isExpanding: boolean}>`
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 14px;
        left: -12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border: 1px solid ${colors.iconOpacity(0.5)};
        transform: ${({ isExpanding }) => (isExpanding ? 'rotate(-90deg)' : 'rotate(90deg)')};
        cursor: pointer;
        transition: 0.2s;
        & > span {
            margin-bottom: 3px;
        }
        &:hover {
            background-color: #007BFF;
            
        }
        &:hover path {
            fill: white !important;
        }
    `,
    Instructions: styled.div`
        background: white;
        font-size: 11px;
        text-align: center;
        padding: 4px;
    `,
    IconWrapper: styled.div`
        position: relative;
        width: 55px;
        height: 55px;
    `,
    CurrentUser: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: ${lightGray};
        padding-right: 10px;
        padding-left: 8px;
        padding-top: 12px;
        line-height: 100%;
    `,
    UserInfoWrapper: styled.div`
        display: flex;
        flex-direction: row;
    `,
    CurrentUserInfo: styled.div`
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 220px;
        @media (max-width: 1366px) {
            width: 150px;
        }
    `,
    Name: styled.div`
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    JobDescription: styled.div`
        font-size: 12px;
        color: ${mediumGray};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    EditProfileLink: styled.span`
        font-size: 12px;
        color: ${colors.orange};
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            color: ${colors.orange};
            text-decoration: none;
        }
    `,
    InputGroup: styled.div`
        position: relative;
        padding: 8px 8px 10px 8px;
    `,
    SearchIcon: styled.div`
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-53%);
    `,
    Tabs: styled(Tabs)`
        & a {
            width: 175px;
            @media (max-width: 1366px) {
                width: 140px;
            }
            border-radius: 0 !important;
            outline: none;
            &:focus {
                outline: none !important;
            }
            &:not([class='active']) {
                background-color: ${colors.mineShaft};
                color: ${mediumGray}!important;
            }
        }
    `,
    TabTitle: styled.span`
        display: flex;
        align-items: center;
        font-size: 15px;
    `,
    ListGroup: styled(ListGroup)`
        // 195px of top action space + 5px for the timetable
        height: calc(var(--window-height) - ${styles.topNavHeight}px - 200px);
        padding-bottom: 16px;
        overflow-y: auto;
    `,
    SwitchSidebar: styled.div`
        position: absolute;
        left: 0;
        top: 50%;
        width: 36px;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 8px 0 0 8px;
        transform: translate(-100%, -50%);
        box-shadow: -2px 1px 2px rgba(0, 0, 0, 0.1);
    `,
    ActionIcon: styled.div<{ isActive: boolean }>`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        border: 3px solid transparent;
        cursor: pointer;
        &:hover {
            background: ${colors.gray2};
        }
        ${({ isActive }) => (isActive && css`
            background: ${colors.gray2};
            border-right-color: gray;
        `)}
    `,
    TagFilter: styled.div`
        padding: 9px;
        margin-bottom: 12px;
        border-bottom: 1px solid ${colors.mineShaftOpacity(0.05)};
    `,
    FilterLabel: styled.p`
        margin-bottom: 4px;
        margin-top: 16px;
        font-weight: 500;
        font-size: 14px;
        color: ${colors.grayTitleOpacity(0.9)};
    `,
    FilterWrapper: styled.div`
        flex-shrink: 0;
        overflow: auto;
        height: calc(var(--window-height) - 220px);
        width: 250px;
        padding: 16px;
    `,
    SearchAndFilter: styled.div`
        display: flex;
        align-items: center;
        padding-right: 4px;
    `
}

export default S
