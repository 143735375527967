import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import omit from 'lodash/omit'
import { FC } from 'react'
import api from '../../utils/api'
import { _ } from '../../utils/localization'
import {
    RetrieveNetworkingSpaceResponse,
    CreateCreateVideocallResponse,
    CreateCreateVideocallRequest,
} from '../../types/swagger'
import { RootState } from '../../store'
import { RequestStatus } from '../../types'
import { showErrorNotification } from '../notifications/notificationsSlice'
import { UpdatedPublishedSpace, WS as SpaceWS } from '../spaces/spaceSlice'

type NetworkingData = Omit<RetrieveNetworkingSpaceResponse, 'videocalls'>

interface RenderPopover {
    videocall: { x_position?: number, y_position?: number }
    Component: FC<{ close: () => void }>
}

interface FetchVideoCallsThunk {
    eventSlug: string
    networkingSlug: string
}

interface CreateNetworkingVideocallThunk {
    x?: number
    y?: number
    pageData?: RetrieveNetworkingSpaceResponse,
    eventSlug: string
}

export const fetchNetworkingArea = createAsyncThunk(
    'GET_NETWORKING_PAGE',
    async ({ eventSlug, networkingSlug }: FetchVideoCallsThunk) => {
        const url = `/events/${eventSlug}/networking/${networkingSlug}/`
        return api.get<RetrieveNetworkingSpaceResponse>(url)
    },
)

export const createNetworkingVideocall = createAsyncThunk(
    'CREATE_VIDEO_CALL',
    async (params: CreateNetworkingVideocallThunk, thunkAPI) => {
        const {
            x, y, pageData, eventSlug,
        } = params
        const url = `/events/${eventSlug}/videocalls/create/`

        const requestParams: CreateCreateVideocallRequest = {
            x_position: x,
            y_position: y,
            networking_page: pageData.id,
        }

        const response = await api.safePost<CreateCreateVideocallResponse>(url, requestParams)
        if (response.error) {
            // TODO (also in backend) user error code instead
            // @ts-ignore
            const message = response.error?.detail === 'Networking calls have reached its capacity'
                ? _('networking.call_capacity_reached')
                : response.error
            thunkAPI.dispatch(showErrorNotification(message))
            thunkAPI.rejectWithValue(response.error)
        } else {
            return response.data
        }
    },
)

const networkingSlice = createSlice({
    name: 'networking',
    initialState: {
        search: '' as string,
        tagsFilter: {} as Record<string, number[]>,
        pageData: {} as Record<string, NetworkingData>,
        fetchNetworkingStatus: 'idle' as RequestStatus,
        videoCallData: null as CreateCreateVideocallResponse,
        popoverComponent: null as RenderPopover,
        ignoreLeaveNetworking: false as boolean,
    },
    reducers: {
        emptyData: (state, { payload }: PayloadAction<string>) => {
            state.pageData[payload] = null
        },
        setPopover: (state, { payload }: PayloadAction<RenderPopover>) => {
            state.popoverComponent = payload
        },
        closePopover: (state) => {
            state.popoverComponent = null
        },
        setSearch(state, { payload }: PayloadAction<string | null>) {
            state.search = payload
        },
        updateTagsFilter: (state, { payload }: PayloadAction<Record<string, number[]>>) => {
            state.tagsFilter = {
                ...state.tagsFilter,
                ...payload
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNetworkingArea.fulfilled, (
            state, { payload }: PayloadAction<RetrieveNetworkingSpaceResponse>
        ) => {
            state.fetchNetworkingStatus = 'succeeded'
            state.pageData[payload.meta.slug] = omit(payload, 'videocalls')
        })
        builder.addCase(fetchNetworkingArea.pending, (state) => {
            state.fetchNetworkingStatus = 'loading'
        })
        builder.addCase(fetchNetworkingArea.rejected, (state) => {
            state.fetchNetworkingStatus = 'failed'
        })
        builder.addCase(createNetworkingVideocall.fulfilled, (state, { payload }) => {
            state.videoCallData = payload
        })
        builder.addCase(
            SpaceWS.publishedSpaceUpdated,
            (state, { payload }: PayloadAction<UpdatedPublishedSpace>) => {
                if (state.pageData[payload.space_slug]) {
                    state.pageData[payload.space_slug].meta.published = payload.published
                }
            }
        )
    },
})

export const selectPageData = (slug: string) => (state: RootState) => state.networking.pageData[slug]
export const selectNetworkSearch = (state: RootState) => state.networking.search
export const selectPopover = (state: RootState) => state.networking.popoverComponent
export const fetchNetworkingStatusSelector = (state: RootState) => state.networking.fetchNetworkingStatus
export const tagsFilterSelector = (state: RootState) => state.networking.tagsFilter

export const closePopover = networkingSlice.actions.closePopover
export const setPopover = networkingSlice.actions.setPopover
export const emptyData = networkingSlice.actions.emptyData
export const setSearch = networkingSlice.actions.setSearch
export const updateTagsFilter = networkingSlice.actions.updateTagsFilter
export default networkingSlice.reducer
