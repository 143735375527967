import React from 'react'
import styled from 'styled-components'
import Icon from '../../../components/Icon'
import colors from '../../../constants/colors'

const S = {
    Wrapper: styled.div`
        display: flex;
        align-items: center;
        width: fit-content;
        height: 32px;
        border-radius: 2px;
        border: 1px solid ${colors.inputBorder};
        overflow: hidden;
    `,
    Direction: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 100%;
        background-color: ${colors.grayTitleOpacity(0.1)};
    `,
    Sizes: styled.div`
        display: flex;
        align-items: center;
        padding: 4px 8px;
        gap: 8px;
    `,
    Size: styled.div<{active: boolean}>`
        width: 20px;
        height: 20px;
        border-radius: 2px;
        color: ${({ active }) => (active ? 'white' : colors.iconOpacity(0.5))};
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        background-color: ${({ active }) => (active ? colors.primary : 'transparent')};
        cursor: pointer;
        user-select: none;
    `
}

export type SizeDirection = 'horizontal' | 'vertical'

interface Props {
    direction: SizeDirection
    sizes: string[]
    selectedSize: string
    onSelect: (value) => void
    className?: string
}

const CalendarSizes = ({
    direction, selectedSize, onSelect, sizes, className
}: Props) => (
    <S.Wrapper className={className}>
        <S.Direction>
            <Icon
                icon={direction === 'horizontal' ? 'arrowLeftRight' : 'arrowUpDown'}
                color={colors.iconOpacity(0.75)}
                size={18}
                style={{ marginTop: -4 }}
            />
        </S.Direction>
        <S.Sizes>
            {sizes.map((size) => (
                <S.Size key={size} active={selectedSize === size} onClick={() => onSelect(size)}>
                    {size}
                </S.Size>
            ))}
        </S.Sizes>
    </S.Wrapper>
)

export default CalendarSizes
