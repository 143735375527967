// to preview UX when opening popup on hover
export default (
    () => {
        const s = {}
        return {
            set: (id: number, value: any) => { s[id] = value },
            get: (id: number) => s[id],
        }
    }
)()
