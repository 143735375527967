import { createRoute } from '../../utils/routes'
import GdprPage from './GdprPage'
import ReactivateAccountPage from './ReactivateAccountPage'
import RemoveAccountSuccessPage from './RemoveAccountSuccessPage'

export default {
    removeUserData: createRoute({
        title: 'Remove personal data',
        path: '/remove-account',
        component: GdprPage,
        isPublic: true,
    }),
    removeAccountSuccess: createRoute({
        title: 'Remove account success',
        path: '/remove-account-success',
        component: RemoveAccountSuccessPage,
        isPublic: true
    }),
    reactivateAccount: createRoute({
        title: 'Reactivate account',
        path: '/reactivate-account',
        component: ReactivateAccountPage,
        isPublic: true
    }),
}
