import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import CardContainer from '../../components/CardContainer'
import ConfirmModal from '../../components/ConfirmModal'
import CustomButton from '../../components/CustomButton'
import colors from '../../constants/colors'
import api from '../../utils/api'
import auth, { Jwt } from '../../utils/auth'
import { _ } from '../../utils/localization'
import { currentUserSelector, getCurrentUser } from '../auth/authSlice'
import { addModal } from '../modals/modalSlice'
import { showErrorNotification } from '../notifications/notificationsSlice'
import Icon from '../../components/Icon'
import FullPageLoading from '../../components/FullPageLoading'
import { User } from '../auth/types'

const S = {
    Title: styled.h1`
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: ${colors.grayTitle};
    `,
    Description: styled.p`
        margin-bottom: 12px;
        padding: 20px;
        font-weight: 300;
        font-size: 14px;
        color: ${colors.grayTitleOpacity(0.9)};
    `,
    Divider: styled.div`
        width: 100%;
        height: 0;
        border-top: 1px solid ${colors.iconOpacity(0.1)};
    `,
    Actions: styled.div<{hasNotes?: boolean}>`
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        background-color: ${({hasNotes}) => (hasNotes ? colors.primaryOpacity(0.02) : 'white')};
    `,
    NoteTitle: styled.div`
        display: flex;
        align-items: center;
        font-size: 14px;
        color: ${colors.grayTitle};
    `,
    NoteList: styled.ul`
        margin-bottom: 0;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: ${colors.iconColor};
    `
}

interface NoteItems {
    ownedOrganizations?: string[]
    ownedEvents?: {
        name: string
        slug: string
    }[]
    attendingEvents?: string[]
}

const Notes = ({ noteItems }: {noteItems: NoteItems}) => {
    const mappedTranslation = {
        ownedOrganizations: _('gdpr.remove_personal_data.owned_organizations_note'),
        ownedEvents: _('gdpr.remove_personal_data.owned_events_note'),
        attendingEvents: _('gdpr.remove_personal_data.attending_events_note'),
    }
    if (isEmpty(noteItems)) {
        return <div />
    }
    const keys = Object.keys(noteItems)
    if (keys.length === 1) {
        return (
            <S.NoteTitle>
                <Icon icon="info" color={colors.primaryOpacity(0.75)} className="mr-1" />
                {`${mappedTranslation[keys[0]]}: ${noteItems[keys[0]].join(', ')}`}
            </S.NoteTitle>
        )
    }

    return (
        <div>
            <S.NoteTitle>
                <Icon icon="info" color={colors.primaryOpacity(0.75)} className="mr-1" />
                {_('gdpr.remove_personal_data.multiple_lines_note')}
            </S.NoteTitle>
            <S.NoteList>
                {Object.keys(noteItems).map((key) => (
                    <li key={key} className="mt-1">
                        {`${mappedTranslation[key]}: ${noteItems[key].join(', ')}`}
                    </li>
                ))}
            </S.NoteList>
        </div>
    )
}

const getNoteItems = (currentUser: User): NoteItems => {
    const result: NoteItems = {}
    if (currentUser?.owned_organizations?.length) {
        result.ownedOrganizations = currentUser.owned_organizations
    }
    if (currentUser?.owned_events?.length) {
        result.ownedEvents = currentUser.owned_events.map((e) => e?.name)
    }
    if (currentUser?.attending_events?.length) {
        result.attendingEvents = currentUser.attending_events
    }
    return result
}

const GdprPage = () => {
    const [isUploading, setIsUploading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const currentUser = useSelector(currentUserSelector())
    const { search } = useLocation()
    const code = new URLSearchParams(search).get('code')
    const email = new URLSearchParams(search).get('email')

    useEffect(() => {
        const login = async () => {
            if (code && email && currentUser?.email !== email) {
                const response = await api.safePost<Jwt>('/auth/obtain/', { email, code })
                if (!response.error) {
                    auth.storeTokens(response.data)
                    dispatch(getCurrentUser())
                }
            }
        }
        login()
    }, [code, currentUser, dispatch, email, history])

    const removeUserData = async (close) => {
        setIsUploading(true)
        const response = await api.safePost('/remove-user-account/')
        setIsUploading(false)
        if (response.error) {
            return dispatch(showErrorNotification(response.error))
        }
        close()
        auth.logout()
        history.push('/remove-account-success')
    }

    const showConfirm = () => {
        dispatch(addModal({
            Component: ({ close }: {close: () => null}) => (
                <ConfirmModal
                    header={_('gdpr.remove_personal_data.modal_header')}
                    body={_('gdpr.remove_personal_data.modal_body')}
                    onConfirm={() => removeUserData(close)}
                    close={close}
                />
            ),
            padding: 30
        }))
    }

    return (
        <>
            <Container>
                <S.Title>
                    {_('gdpr.title')}
                </S.Title>
                <CardContainer>
                    <S.Description>
                        {_('gdpr.description')}
                    </S.Description>
                    <S.Divider />
                    <S.Actions hasNotes={!isEmpty(getNoteItems(currentUser))}>
                        <Notes noteItems={getNoteItems(currentUser)} />
                        <CustomButton
                            variant="danger"
                            style={{ background: colors.lightRed }}
                            onClick={showConfirm}
                            className="ml-5"
                        >
                            {_('gdpr.remove_personal_data')}
                        </CustomButton>
                    </S.Actions>
                </CardContainer>
            </Container>
            {isUploading && <FullPageLoading isAbsolute />}
        </>
    )
}

export default GdprPage
