import React, { useState } from 'react'
import { Action, createAsyncThunk } from '@reduxjs/toolkit'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Form } from 'react-bootstrap'
import { addModal } from '../modals/modalSlice'
import { _ } from '../../utils/localization'
import { RootState } from '../../store'
import Icon from '../../components/Icon'
import { showNotification } from '../notifications'
import api from '../../utils/api'
import { showErrorNotification } from '../notifications/notificationsSlice'
import { eventSelector } from '../events/eventSlice'
import { ClickableWidget } from './types'
import CustomButton from '../../components/CustomButton'
import colors from '../../constants/colors'
import EmbeddedVideo from '../embeddedvideo/EmbeddedVideo'
import { MapItemType } from './views/Map'

const S = {
    Form: styled(Form)`
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    SendButton: styled(Button)`
        background-color: transparent !important;  // important to disable hover
        border-color: transparent;
    `,
    Input: styled(Form.Control)`
        border: 0;
    `,
    ExternalLinkActions: styled.div`
        display: flex;
        justify-content: flex-end;
    `,
    Description: styled.p`
        font-size: 14px;
        color: ${colors.grayDescription};
    `
}

const sendToSupport = (message: string) => createAsyncThunk(
    'SEND_TO_SUPPORT',
    async (__, thunkAPI) => {
        const rootState = thunkAPI.getState() as RootState
        const url = `/events/${rootState.event.current.data.slug}/contact/`
        const resp = await api.safePost(url, { message })
        const { error } = resp
        if (error) {
            thunkAPI.dispatch(showErrorNotification(error))
        } else {
            thunkAPI.dispatch(showNotification({
                body: _('support.questions.is_sent_notification.body'),
                type: 'info',
            }))
        }
    },
)()

const AskSupport = ({ close }: { close: () => void }) => {
    const dispatch = useDispatch()
    const [text, setText] = useState('')
    const submit = (e) => {
        e.preventDefault()
        dispatch(sendToSupport(text))
        setText('')
        close()
    }

    return (
        <S.Form onSubmit={submit}>
            <S.Input
                type="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder={_('support.questions.question_field.placeholder')}
            />
            <S.SendButton>
                <Icon icon="send" onClick={submit} />
            </S.SendButton>
        </S.Form>
    )
}

const EventInfo = () => {
    const event = useSelector(eventSelector())
    return <div className="editor-content" dangerouslySetInnerHTML={{ __html: event.description }} />
}

const ExternalWidgetLink = ({
    close, widgetLink
}: {close: () => void, widgetLink: string}) => {
    const openExternalLink = () => {
        window.open(widgetLink)
        close()
    }
    return (
        <>
            <S.Description>
                {_('external_widget_link.body')}
            </S.Description>
            <S.ExternalLinkActions>
                <CustomButton variant="success" icon="openInNew" onClick={openExternalLink}>
                    {_('external_widget_link.button.enter')}
                </CustomButton>
                <CustomButton variant="danger" icon="close" onClick={close}>
                    {_('external_widget_link.button.cancel')}
                </CustomButton>
            </S.ExternalLinkActions>
        </>
    )
}

const openReception = () => addModal({
    Component: AskSupport,
    header: _('support.questions.header'),
})

const openEventInfo = () => addModal({
    Component: EventInfo,
    header: _('eventinfo.header'),
})

const openExternalLinkConfirm = (item: MapItemType) => addModal({
    Component: ({ close }: {close: () => void}) => (
        <ExternalWidgetLink
            widgetLink={item.widget_link}
            close={close}
        />
    ),
    header: _('external_widget_link.header'),
})

const openEmbeddedModal = (item: MapItemType) => addModal({
    Component: () => (
        <EmbeddedVideo url={item.embedded_url} />
    ),
    header: item.name,
})

const widgetActions: Record<ClickableWidget, (item?: MapItemType) => Action> = {
    reception: openReception,
    event_info: openEventInfo,
    external_link: openExternalLinkConfirm,
    embedded: openEmbeddedModal,
}

const handleWidgetClick = (
    item: MapItemType
) => createAsyncThunk(
    'HANDLE_WIDGET_CLICK',
    (__, thunkApi) => {
        const actionCreator = widgetActions[item.widget]
        if (!actionCreator) {
            throw Error(`No action creator for widget ${item.widget}`)
        }
        if (item.widget_link) {
            thunkApi.dispatch(actionCreator(item))
        } else if (item.embedded_url) {
            thunkApi.dispatch(actionCreator(item))
        } else {
            thunkApi.dispatch(actionCreator())
        }
    },
)()

export default handleWidgetClick
