import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../constants/colors'
import StyledIcon from './Icon'
import { IconOption } from './Icon/icons'

interface CustomButtonProps {
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'midBlue'
    children?: React.ReactNode
    icon?: IconOption
    style?: React.CSSProperties
    onClick?: () => void
    className?: string
    disabled?: boolean
    type?: 'button' | 'submit'
}

const S = {
    Button: styled.button<CustomButtonProps>`
        display: inline-flex;
        align-items: center;
        max-width: 175px;
        height: 30px;
        padding: 0 10px;
        margin-left: 10px;
        font-size: 13px;
        border-radius: 4px;
        color: ${({ variant }) => (variant === 'secondary' ? '#4E5155' : 'white')};
        background-color: ${({ variant }) => (variant === 'secondary' ? 'white' : colors[variant])};
        border: ${({ variant }) => (variant === 'secondary' ? '1px solid rgba(78, 81, 85, 0.2)' : 'none')};
        cursor: pointer;
        transition: 0.2s;
        &:hover {
            filter: grayscale(30%);
            background-color: ${({ variant }) => variant === 'secondary' && colors.gray3}!important;
        }
        ${({ disabled }) => disabled && css`
            pointer-events: none;
            opacity: 0.5;
        `}
    `,
    Text: styled.span`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `,
}

const CustomButton = ({
    variant,
    children,
    icon,
    style,
    onClick,
    className,
    disabled,
    type = 'button'
}: CustomButtonProps) => (
    <S.Button
        style={{ ...style }}
        onClick={onClick}
        variant={variant}
        className={className}
        disabled={disabled}
        type={type}
    >
        {icon
        && (
            <StyledIcon
                icon={icon}
                size={14}
                style={{ marginBottom: '3px', marginRight: '3px' }}
                color={variant !== 'secondary' ? 'white' : '#4E5155'}
            />
        )}
        <S.Text>{children}</S.Text>
    </S.Button>
)

export default CustomButton
