import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import uniqBy from 'lodash/uniqBy'
import styled from 'styled-components'
import useSlug from '../../../hooks/useSlug'
import { boothDetailSelector, fetchBoothDetail } from '../boothDetailSlice'
import BoothContent from './BoothContent'
import BoothView from './BoothView'
import Loader from '../../../components/Loader'
import BoothStaffs from './BoothContentComponents/BoothStaffs'
import { currentUserSelector } from '../../auth/authSlice'
import useFetchAttendees from '../../attendees/useFetchAttendees'
import useSubscribeAttendees from '../../attendees/useSubscribeAttendees'
import { SwipeDirection } from '../types'
import Icon from '../../../components/Icon'
import colors from '../../../constants/colors'

const S = {
    PhysicalLocation: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 20px;
        margin-bottom: 16px;
        font-size: 14px;
        color: ${colors.icon};
    `
}

interface Props {
    boothSlug: string
    onSwipe: (direction: SwipeDirection) => void
}

const BoothOverview = ({ boothSlug, onSwipe }: Props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const eventSlug = useSlug('eventSlug')
    const mapSlug = useSlug('mapSlug')
    const boothDetail = useSelector(boothDetailSelector(boothSlug))
    const staffIds = boothDetail?.data?.staff_ids || []
    const currentUser = useSelector(currentUserSelector())
    const staffData = useFetchAttendees(staffIds)
    const userIsStaff = staffIds.includes(currentUser.id)
    const staffs = uniqBy([...staffData, userIsStaff && currentUser].filter((v) => !!v), 'id')

    useSubscribeAttendees(staffIds)

    // Make sure the mapSlug is valid for the booth
    useEffect(() => {
        if (
            boothDetail.status === 'succeeded'
            && history.location.pathname.includes('/:mapSlug/')
        ) {
            const newUrl = history.location.pathname.replace(
                '/:mapSlug/',
                `/${boothDetail.data.map_slug}/`,
            )
            history.replace(newUrl)
        }
    }, [boothDetail, mapSlug, history])

    useEffect(() => {
        if (boothDetail.status === 'idle') {
            dispatch(fetchBoothDetail({ eventSlug, boothSlug }))
        }
    }, [boothDetail, eventSlug, boothSlug, dispatch])

    if (boothDetail.status === 'loading') {
        return <Loader />
    }

    return boothDetail.data
        ? (
            <>
                <BoothView booth={boothDetail.data} onSwipe={onSwipe} />
                <S.PhysicalLocation>
                    {boothDetail.data?.physical_location && (
                        <>
                            <Icon icon="mapMarker" size={15} className="mr-1" style={{ marginTop: -5}} />
                            {boothDetail.data.physical_location}
                        </>
                    )}
                </S.PhysicalLocation>
                {staffs.length ? (
                    <BoothStaffs boothDetail={boothDetail.data} staffs={staffs} />
                ) : <div style={{ height: 50 }} />}
                <BoothContent boothDetail={boothDetail.data} staffs={staffs} />
            </>
        )
        : null
}

export default BoothOverview
