import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { S } from './Speakers'
import { DateTime, toLocalDateFormat, toLocalTimeFormat } from '../../../utils/datetime'
import useSlug from '../../../hooks/useSlug'
import useFetchAttendees from '../../attendees/useFetchAttendees'
import openChatModal from '../../chat/openChatModal'
import getMugshotSrc from '../../../utils/getMugshotSrc'
import { formatName } from '../../../utils/profile'
import Icon from '../../../components/Icon'
import colors from '../../../constants/colors'
import Bookmark from '../Bookmark'
import { GoodiebagType } from '../types'
import ThumbnailWithCounter from '../../../components/ThumbnailWithCounter'
import { eventTimeFormatSelector } from '../../auth/eventPreviewSlice'
import { currentUserSelector } from '../../auth/authSlice'

type GoodiebagMeetingType = {
    id: number
    primarySpeakerId: number,
    speakerCount: number,
    title: string
    slug: string
    startsAt: DateTime
    endsAt: DateTime
    enableHostChat: boolean
    type: 'meeting' | 'groupSession'
}

// todo this name isn't ideal, since this can be a meeting or a group session
const GoodiebagMeeting = ({ item }: { item: GoodiebagMeetingType }) => {
    const dispatch = useDispatch()
    const timeFormat = useSelector(eventTimeFormatSelector)
    const eventSlug = useSlug('eventSlug')
    const [host] = useFetchAttendees([item.primarySpeakerId], true)
    const chatWithHost = () => dispatch(openChatModal(eventSlug, item.primarySpeakerId))
    const currentUser = useSelector(currentUserSelector())
    const link = item.type === 'meeting'
        ? `/${eventSlug}/meetings/${item.slug}?meetingId=${item.id}`
        : `/${eventSlug}/videocall/${item.slug}`

    return (
        <S.Wrapper>
            <S.Link
                to={link}
            >
                <div className="d-flex">
                    <ThumbnailWithCounter
                        image={getMugshotSrc(host)}
                        size={84}
                        counter={item?.speakerCount}
                        className="mr-2"
                    />
                    <S.Content>
                        <S.Title className="text-cut">{item.title}</S.Title>
                        <S.Description className="notranslate">
                            {host && formatName(host)}
                        </S.Description>
                        <S.SubDescription>
                            <Icon
                                icon="calendarRange"
                                size={16}
                                color={colors.regentGray}
                                style={{margin: '0 3px 3px 0'}}
                            />
                            <span>{toLocalDateFormat(item.startsAt)}</span>
                            <div className="mr-3" />
                            <Icon
                                icon="clock"
                                size={16}
                                color={colors.regentGray}
                                style={{margin: '0 3px 3px 0'}}
                            />
                            <span>{`${toLocalTimeFormat(item.startsAt, timeFormat)} - ${toLocalTimeFormat(item.endsAt, timeFormat)}`}</span>
                        </S.SubDescription>
                    </S.Content>
                </div>
            </S.Link>

            <S.Actions>
                {item.type === 'meeting' && (
                    <Bookmark
                        item={{ id: item.id, type: GoodiebagType.MeetingExpert }}
                        style={{ margin: '0 20px' }}
                        size={20}
                    />
                )}
                {item.enableHostChat && item.primarySpeakerId !== currentUser.id ? (
                    <Icon
                        icon="message"
                        size={20}
                        onClick={chatWithHost}
                        color={colors.icon}
                    />
                ) : (
                    <Icon
                        icon="message"
                        size={20}
                        color={colors.gray2}
                    />
                )}
            </S.Actions>
        </S.Wrapper>
    )
}

export default GoodiebagMeeting
