import React from 'react'
import styled, { css } from 'styled-components'
import Slider, { CustomArrowProps } from 'react-slick'
import colors from '../../../../constants/colors'
import StaffAvatar from './StaffAvatar'
import CardContainer from '../../../../components/CardContainer'
import Icon from '../../../../components/Icon'
import { Booth } from '../../types'
import { _ } from '../../../../utils/localization'
import { VirtualAttendee } from '../../../../types/swagger'
import useResponsive from '../../../../hooks/useResponsive'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'

const S = {
    Header: styled.div`
        padding: 10px;
        font-size: 24px;
        font-weight:500;
        border-bottom: 1px solid ${colors.gray4};
    `,
    Slider: styled(Slider)`
        padding: 0 24px;
        & .slick-next {
            top: 52px;
            right: 4px;
            &:before {
                color: black;
            }
        }
    `,
    ButtonArrow: styled.div<{disable: boolean, isPrev: boolean}>`
        width: 30px;
        display: ${({ disable }) => (disable ? 'none' : 'inline-block')};
        position: absolute;
        top: 36px;
        ${({ isPrev }) => (isPrev ? css`
            left: 0;
            transform: rotate(90deg);
        ` : css`
            right: 0;
            transform: rotate(-90deg);
        `
    )}`,
}

const ArrowButton = ({ isPrev, className, ...props }: CustomArrowProps & {isPrev?: boolean}) => (
    <S.ButtonArrow disable={className.includes('slick-disable')} isPrev={isPrev}>
        <Icon
            {...props}
            size={30}
            icon="chevronDown"
        />
    </S.ButtonArrow>
)

export const StaffSlider = ({ boothDetail, staffs }: { boothDetail: Booth, staffs: VirtualAttendee[] }) => {
    const {isMobile} = useResponsive()
    const {width} = useWindowDimensions()

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: isMobile ? width / 110 : 10,
        slidesToScroll: isMobile ? 3 : 5,
        prevArrow: <ArrowButton isPrev />,
        nextArrow: <ArrowButton />,
        arrows: !isMobile,
    }

    return (
        <S.Slider {...settings}>
            {(staffs || []).map((staff) => (
                <StaffAvatar staff={staff} key={staff.id} boothDetail={boothDetail} />
            ))}
        </S.Slider>
    )
}

const BoothStaffs = ({ boothDetail, staffs }: { boothDetail: Booth, staffs: VirtualAttendee[] }) => (
    staffs.length > 0 ? (
        <CardContainer>
            <S.Header>
                {_('booth.tabs.staffs')}
            </S.Header>
            <StaffSlider boothDetail={boothDetail} staffs={staffs} />
        </CardContainer>
    ) : (
        <div style={{height: 157}} />
    )
)

export default BoothStaffs
