import React from 'react'
import styled from 'styled-components'
import { Participant as ParticipantType } from '../useTwilio'
import Participant from './Participant'

const S = {
    RemoteParticipant: styled.div`
      display: none;
    `,
}

const SmallParticipantsLayout = ({ participants }: { participants: ParticipantType[] }) => (
    <>
        {participants.map((participant) => (
            <S.RemoteParticipant key={participant.sid}>
                <Participant
                    participant={participant}
                    borderRadius={12}
                    hidden
                />
            </S.RemoteParticipant>
        ))}
    </>
)

export default SmallParticipantsLayout
