/**
 * Make it convenient to use icons in Components by defining typescript
 * icon types and mapping them to MDI Icons.
 *
 * Also add colors and sizes
 */
import { IconOption, iconPaths } from './icons'

export { default } from './Icon'

export const isIconOption = (str: string): str is IconOption => !!iconPaths[str]
