import React from 'react'
import { isAfter } from 'date-fns'
import BSModal from 'react-bootstrap/Modal'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Time from './Time'
import Bookmark from '../../goodiebag/Bookmark'
import { _ } from '../../../utils/localization'
import { Goodiebag, GoodiebagType } from '../../goodiebag/types'
import { RetrieveProgramDetailResponse } from '../../../types/swagger'
import { addModal, closeAllModal } from '../../modals/modalSlice'
import PerformerProfile from '../../auth/components/PerformerProfile'
import CustomButton from '../../../components/CustomButton'
import Icon from '../../../components/Icon'
import colors from '../../../constants/colors'
import useSlug from '../../../hooks/useSlug'
import Divider from '../../../components/Divider'
import FetchProgramContainerModal from '../../calendar/components/FetchProgramContainerModal'
import JoinButton from './JoinButton'
import { collapse } from '../redux/expandFooterSlice'
import { closeCalendar } from '../../calendar/calendarSlice'
import PlaybackButton from './PlaybackButton'
import ReadMoreOrLess from '../../../components/ReadMoreOrLess'
import useResponsive from '../../../hooks/useResponsive'

export const S = {
    HeaderContent: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 4px);
    `,

    ModalTitle: styled.h6`
        margin-bottom: 0;
        font-size: 20px;
        line-height: 26px;
        font-weight: bold;
        color: ${colors.darkTitle};
        @media (max-width: 932px) {
            font-size: 16px;
        }
    `,

    Header: styled.p`
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        color: ${colors.darkTitle};
        text-transform: uppercase;
        @media (max-width: 932px) {
            font-size: 14px;
        }
    `,

    SponsorBar: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 32px;
        color: white;
        background-color: ${colors.darkGray};
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
    `,

    SponsorSession: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,

    SponsorInfo: styled.div`
        display: flex;
        align-items: center;
    `,

    SponsorLogo: styled.img`
        width: 56px;
        height: 40px;
        margin-right: 12px;
        border-radius: 2px;
        object-fit: contain;
        object-position: center;
    `,

    SponsorSubtitle: styled.div`
        font-weight: 300;
        font-size: 14px;
        color: ${colors.grayDescription};
    `,

    VerticalLine: styled.span`
        margin: 0 6px;
    `,

    SubTitle: styled.div`
        display: flex;
        align-content: center;
        align-items: center;
        font-size: 12px;
        color: ${colors.icon};
    `,

    AlignActions: styled.div`
        display: flex;
        align-items: center;
        margin-bottom: auto;
        margin-top: auto;
        margin-right: 8px;
        flex-shrink: 0;
    `,

    EscapeContainer: styled.div`
    `,

    Image: styled.img`
        object-fit: cover;
        width: 100%;
        min-height: 100px;  // to prevent jump in height after load                       
    `,

    Description: styled(ReadMoreOrLess)`
        font-weight: 300;
        font-size: 14px;
        color: ${colors.grayDescription};
    `,

    HeaderBar: styled.p`
        margin-top: 32px;
        margin-bottom: 16px;
        padding: 4px 16px;
        background-color: #386080;
        color: white;
        font-weight: bold;
    `,
    Link: styled(Link)`
        margin-right: 24px;
        text-decoration: none !important;
    `,
    DocumentWrapper: styled.div``,
    DocumentItem: styled.a`
        color: ${colors.icon};
        display: flex;
        align-items: center;
        width: 100%;
        &:hover {
            text-decoration: none;
        }
    `,
    Performers: styled.div`
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        @media (max-width: 932px) {
            grid-template-columns: 1fr;
        }
    `,
    BackIcon: styled(Icon)`
        margin-bottom: 3px;
        margin-right: 4px;
    `,
    ActionsMobile: styled.div`
        margin-top: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    `
}

export const createBookmark = (id: number): Goodiebag => ({
    id,
    type: GoodiebagType.ProgramItem,
})

const ItemDetails = ({hideHeader, ...item}: RetrieveProgramDetailResponse & {hideHeader: boolean}) => {
    const {isMobile} = useResponsive()
    const eventSlug = useSlug('eventSlug')
    const history = useHistory()
    const dispatch = useDispatch()
    const attachments = item?.attachments || []
    const isEnded = isAfter(new Date(), new Date(item?.end_date))

    const goToBooth = () => {
        item.sponsor && (
            history.push(`/${eventSlug}/map/${item.sponsor.map_slug}/booth/${item.sponsor.slug}`)
        )
        dispatch(closeAllModal())
        dispatch(collapse())
        dispatch(closeCalendar())
    }

    const showContainerModal = () => {
        dispatch(closeAllModal())
        dispatch(addModal({
            Component: () => <FetchProgramContainerModal id={item.container} />,
            header: _('program.session_block'),
            padding: 0,
        }))
    }

    return (
        <div style={{overflow: 'auto'}}>
            {!hideHeader && (
                <BSModal.Header closeButton style={{ paddingTop: 0, paddingLeft: 0 }}>
                    <S.HeaderContent>
                        <div style={{ overflow: 'hidden' }}>
                            <div className="flex-align-center">
                                {item.container && <S.BackIcon icon="leftArrow" size={18} onClick={showContainerModal} />}
                                <S.ModalTitle className="text-cut">
                                    {item.name}
                                </S.ModalTitle>
                            </div>
                            <S.SubTitle>
                                <Time start_date={item.start_date} end_date={item.end_date} />
                                <S.VerticalLine>|</S.VerticalLine>
                                <span>{item.virtual_space?.name || item.mapitem?.name}</span>
                            </S.SubTitle>
                        </div>

                        <S.AlignActions>
                            {!isEnded && !isMobile && <JoinButton item={item} />}
                            {isEnded && !isMobile && item?.playback_url && (
                                <PlaybackButton item={item} />
                            )}
                            <Bookmark item={createBookmark(item.id)} style={{ marginRight: 4 }} />
                        </S.AlignActions>
                    </S.HeaderContent>
                </BSModal.Header>
            )}
            {isMobile && (
                <S.ActionsMobile>
                    {!isEnded && <JoinButton item={item} />}
                    {isEnded && item?.playback_url && (
                        <PlaybackButton item={item} />
                    )}
                </S.ActionsMobile>
            )}

            {item.sponsor && (
                <S.EscapeContainer>
                    <S.SponsorBar>
                        <Icon icon="ribbon" size={20} color="white" />
                        {_('program.sponsor_session')}
                    </S.SponsorBar>
                </S.EscapeContainer>
            )}

            { item.image && (
                <S.EscapeContainer>
                    <S.Image src={item.image} alt="" />
                </S.EscapeContainer>
            )}

            {item.sponsor && (
                <>
                    <S.Header className="mt-2 mb-2">{_('program.sponsor_info')}</S.Header>
                    <S.SponsorSession>
                        <S.SponsorInfo>
                            <S.SponsorLogo src={item.sponsor.logo} />
                            <div>
                                <S.Header>{item.sponsor.name}</S.Header>
                                <S.SponsorSubtitle>{item.sponsor.subtitle}</S.SponsorSubtitle>
                            </div>
                        </S.SponsorInfo>
                        <CustomButton variant="secondary" onClick={goToBooth}>
                            {_('program.visit_booth')}
                        </CustomButton>
                    </S.SponsorSession>
                    <Divider className="mt-2" />
                </>
            )}

            <S.Header className="mt-2 mb-2">{_('program.about_session')}</S.Header>

            <S.Description content={item.description} isHtmlContent />

            <Divider marginTop={16} />

            {attachments.length > 0 && (
                <>
                    <S.DocumentWrapper>
                        <S.Header>
                            {_('item.details_modal.documents')}
                        </S.Header>
                        {attachments.map((attachment) => (
                            <S.DocumentItem href={attachment?.url} target="_blank" rel="noopener noreferrer">
                                <Icon icon="download" size={20} />
                                {attachment?.name}
                            </S.DocumentItem>
                        ))}
                    </S.DocumentWrapper>
                    <Divider className="mt-2" />
                </>
            )}

            {
                item.performers.length > 0
                && (
                    <>
                        <S.Header>
                            {_('item.details_modal.related_speakers')}
                        </S.Header>
                        <Divider />

                        <S.Performers>
                            { item.performers.map((performer) => (
                                <PerformerProfile
                                    key={performer.id}
                                    performer={performer}
                                />
                            )) }
                        </S.Performers>
                    </>
                )
            }
        </div>
    )
}

export default ItemDetails
