import { useDispatch } from 'react-redux'
import { clearMessagesOfUser, getMessagesThunk } from '../features/chat/chatSlice'
import useSlug from './useSlug'

const useReloadMessages = (userId) => {
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()

    return () => {
        if (userId) {
            dispatch(clearMessagesOfUser(userId))
            dispatch(getMessagesThunk({
                eventSlug,
                userId,
                offset: 0,
            }))
        }
    }
}

export default useReloadMessages
