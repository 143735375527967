import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Icon from '../../../../components/Icon'
import colors from '../../../../constants/colors'
import { _ } from '../../../../utils/localization'
import {
    selectChat, selectingBroadcastSelector, setSelectingBroadcastMessage, setSelectingChatBot
} from '../../chatSlice'
import openChatModal from '../../openChatModal'
import useSlug from '../../../../hooks/useSlug'

const S = {
    Wrapper: styled.div<{isActive?: boolean}>`
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: ${({ isActive }) => (isActive ? `${colors.primaryOpacity(0.05)}` : 'transparent')};
        cursor: pointer;
        user-select: none;
    `,
    Avatar: styled.div`
        flex-shrink: 0;
        display: grid;
        place-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid ${colors.inputBorder};
    `,
    Info: styled.div`
        margin-left: 10px;
        overflow: hidden;
    `,
    Name: styled.p`
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        color: ${colors.darkTitle};
    `,
    Description: styled.p`
        margin-bottom: 0;
        font-size: 12px;
        color: ${colors.icon};
    `
}

const BroadcastChatItem = () => {
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const selectingBroadcast = useSelector(selectingBroadcastSelector)
    const handleSelect = () => {
        dispatch(setSelectingBroadcastMessage(true))
        dispatch(setSelectingChatBot(false))
        dispatch(selectChat(-1))
        dispatch(openChatModal(eventSlug))
    }

    return (
        <S.Wrapper onClick={handleSelect} isActive={selectingBroadcast}>
            <S.Avatar>
                <Icon icon="broadcastMessage" style={{ marginBottom: -3 }} />
            </S.Avatar>
            <S.Info>
                <S.Name className="text-cut">
                    {_('chat.broadcast_message')}
                </S.Name>
                <S.Description className="text-cut">
                    {_('chat.broadcast_message.description')}
                </S.Description>
            </S.Info>
        </S.Wrapper>
    )
}

export default BroadcastChatItem
