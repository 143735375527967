import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Divider from '../../../components/Divider'
import { SimpleAppointmentSchedule } from '../../../types'
import { _ } from '../../../utils/localization'
import {
    dateOfAppointmentScheduleSelector,
    dayPartOfAppointmentScheduleSelector,
    updateScheduleDayPart,
    updateScheduleTimeSlots,
} from '../appointmentSchedulesSlice'
import { convertDateToDayPart } from '../utils'
import { S } from './TimeSlots'

const DayParts = ({ previousSchedule }: { previousSchedule?: SimpleAppointmentSchedule }) => {
    const dispatch = useDispatch()
    const dayPartOfAppointmentSchedule = useSelector(dayPartOfAppointmentScheduleSelector)
    const dateOfAppointmentSchedule = useSelector(dateOfAppointmentScheduleSelector)
    const previousScheduleDayPart = previousSchedule
        && convertDateToDayPart(new Date(previousSchedule.start_date))

    const handleChangeDayPart = (part) => {
        dispatch(updateScheduleDayPart(part.toUpperCase()))
        dispatch(updateScheduleTimeSlots([]))
    }

    return (
        <>
            <Divider className="mt-4" />
            <S.Header>{_('appointment_schedule.select_day_part')}</S.Header>
            {(dateOfAppointmentSchedule || previousSchedule) && (
                <S.ItemsWrapper>
                    {['Night', 'Morning', 'Afternoon', 'Evening'].map((part) => (
                        <S.Item
                            isActive={dayPartOfAppointmentSchedule === part.toUpperCase()}
                            isScheduled={previousScheduleDayPart === part.toUpperCase()}
                            onClick={() => handleChangeDayPart(part)}
                            key={part}
                        >
                            {part}
                        </S.Item>
                    ))}
                </S.ItemsWrapper>
            )}
        </>
    )
}

export default DayParts
