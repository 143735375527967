import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import sendToWs from '../websocket/sendToWs'

export const useSubscribeToPublishedSpace = (eventSlug: string) => {
    const dispatch = useDispatch()
    useEffect(() => {
        eventSlug && dispatch(sendToWs({ type: 'subscribe_to_space', event_slug: eventSlug }))
        return () => {
            eventSlug && dispatch(sendToWs({ type: 'unsubscribe_from_space', event_slug: eventSlug }))
        }
    }, [dispatch, eventSlug])
}
