import React, {
    useCallback, useEffect, useRef, useState
} from 'react'
import Video, { isSupported } from 'twilio-video'
import uniq from 'lodash/uniq'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { isAuthenticatedSelector } from '../auth/authSlice'
import {
    joinRoom, jwtSelector, leaveCall, setIsScreenSharing
} from './videocallSlice'
import useSlug from '../../hooks/useSlug'

export interface Participant {
    sid: string
    identity: string
    videoTracks: any[]
    audioTracks: any[]
    tracks: any[]
    state: 'connected' | 'disconnected'
    on: (...args: any[]) => void
    removeAllListeners: () => void
}

// Recommended video and audio settings from here. Right now we use collaboration without a dominant speaker
// ideally we'd switch modes depending on if screensharing is enabled or not, or always use a dominant speaker
// https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#grid-mode
const createOptions = (roomName: string) => ({
    name: roomName,
    audio: true,
    video: { height: 720, frameRate: 24, width: 1280 },
    bandwidthProfile: {
        video: {
            mode: 'collaboration' as const,
        }
    },
    maxAudioBitrate: 16000,
    preferredVideoCodecs: [{ codec: 'VP8' as const, simulcast: true }],
    networkQuality: {local: 1 as const, remote: 1 as const}
})

// If there are multiple attendees for 1 participant, keep the last (most recently connected) one
// @ts-ignore
const makeParticipantsUnique = (participants: Participant[]) => uniq(participants.reverse(), 'identiy')

const filterParticipants = (participants: Participant[]): Participant[] => makeParticipantsUnique(
    participants
        // Remove disconnected participants
        .filter((participant) => participant.state !== 'disconnected')
)

/* eslint-disable */
// https://stackoverflow.com/a/9851769/8469046
// @ts-ignore
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]' }(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification)))
/* eslint-enable */

export const isScreenShareSupported = !isSafari

export const shareScreenStore = {
    stream: null
}

const useParticipants = (roomName: string, token: string) => {
    const [room, setRoom] = useState(null)
    const [participants, setParticipants] = useState<Participant[]>([])
    const dispatch = useDispatch()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const stopScreenShareRef = useRef<() => void>(null!)
    const [lastConnection, setLastConnection] = useState(1)

    const {
        isAudioEnabled,
        isVideoEnabled,
        isScreenSharingEnabled,
    } = useSelector((state: RootState) => state.videocall)

    const enableVideo = useCallback(() => {
        room.localParticipant.videoTracks.forEach((publication) => publication.track.enable())
    }, [room])

    const disableVideo = useCallback(() => {
        room.localParticipant.videoTracks.forEach((publication) => publication.track.disable())
    }, [room])

    const enableAudio = useCallback(() => {
        room.localParticipant.audioTracks.forEach((publication) => publication.track.enable())
    }, [room])

    const disableAudio = useCallback(() => {
        room.localParticipant.audioTracks.forEach((publication) => publication.track.disable())
    }, [room])

    const participantConnected = useCallback((participant: Participant) => {
        setParticipants((prevParticipants) => [...prevParticipants, participant])
    }, [])

    const participantDisconnected = useCallback((participant: Participant) => {
        setParticipants((prevParticipants) => prevParticipants.filter(
            (p) => p.identity !== participant.identity
        ))
    }, [])

    // Toggle Audio
    React.useEffect(() => {
        if (!room) { return }
        isAudioEnabled
            ? enableAudio()
            : disableAudio()
    }, [disableAudio, enableAudio, isAudioEnabled, room])

    // Toggle Video
    React.useEffect(() => {
        if (!room) { return }
        isVideoEnabled && !isScreenSharingEnabled
            ? enableVideo()
            : disableVideo()
    }, [disableVideo, enableVideo, isScreenSharingEnabled, isVideoEnabled, room])

    // Toggle Screen sharing
    const shareScreen = useCallback(() => {
        // TS doesn't know getDisplayMedia() exists
        // https://github.com/microsoft/TypeScript/issues/33232
        // @ts-ignore
        navigator.mediaDevices.getDisplayMedia()
            .then((stream) => {
                const track = stream.getTracks()[0]
                shareScreenStore.stream = stream
                room.localParticipant.publishTrack(track)
                    .then((trackPublication) => {
                        dispatch(setIsScreenSharing(true))
                        stopScreenShareRef.current = () => {
                            room.localParticipant.unpublishTrack(track)
                            // TODO: remove this if the SDK is updated to emit this event
                            room.localParticipant.emit('trackUnpublished', trackPublication)
                            track.stop()
                            dispatch(setIsScreenSharing(false))
                            shareScreenStore.stream = null
                        }
                        track.onended = stopScreenShareRef.current
                    })
            })
    }, [dispatch, room])

    React.useEffect(() => {
        if (!room) { return }

        // before sharing your screen stopScreenShareRef.current is null,
        // and this functions tries to call it because isScreenSharingEnabled is false.
        // Since share() needs to be called before stopScreenShareRef.current exists
        // we need to check if it does between share() and stopSharing
        if (isScreenSharingEnabled) {
            shareScreen()
        } else {
            stopScreenShareRef?.current?.()
        }
    }, [isScreenSharingEnabled, room, shareScreen])

    // Connect on mount
    useEffect(() => {
        // temporary check to prevent trying to connect with twilio multiple times
        const currentTimeStamp = Date.now()
        if (token && roomName) {
            if (lastConnection && currentTimeStamp - lastConnection > 1000) {
                setLastConnection(currentTimeStamp)
                Video.connect(token, createOptions(roomName))
                    .then((newRoom) => {
                        setRoom(newRoom)
                        // @ts-ignore
                        newRoom.on('participantConnected', participantConnected)
                        // @ts-ignore
                        newRoom.on('participantDisconnected', participantDisconnected)
                        // @ts-ignore
                        newRoom.participants.forEach(participantConnected)
                    })
            }
        }
    }, [lastConnection, participantConnected, participantDisconnected, roomName, token])

    // Disconnect on unmount
    useEffect(() => () => {
        if (room && room.localParticipant.state === 'connected') {
            room.localParticipant.tracks.forEach((trackPublication) => {
                trackPublication.track.stop()
            })
            room.disconnect()
        }
    }, [room])

    return {
        // filter participants, because of twilio bugs sometimes there
        // are two participants for 1 attendee, or there is a disconnected
        // participant still in participants array.
        participants: filterParticipants(participants),
        localParticipant: room?.localParticipant,
    }
}

export const useTwilio = () => {
    const dispatch = useDispatch()
    const roomName = useSlug('roomId')
    const eventSlug = useSlug('eventSlug')
    const websocketState = useSelector((state: RootState) => state.websocket)
    const jwt = useSelector(jwtSelector())
    const isAuthenticated = useSelector(isAuthenticatedSelector())
    const { participants, localParticipant } = useParticipants(roomName, jwt)
    const failedToJoin = jwt === undefined
    const ready = !!jwt && localParticipant

    // Get JWT from server
    React.useEffect(() => {
        // We need to make sure websocket is logged in,
        // as the endpoint relies on it.
        if (isAuthenticated && websocketState.isLoggedIn) {
            dispatch(joinRoom(eventSlug, roomName))
        }
        // Leave call on unmount
        return () => {
            dispatch(leaveCall())
        }
    }, [isAuthenticated, eventSlug, dispatch, roomName, websocketState.isLoggedIn])

    return {
        ready,
        failedToJoin,
        isSupported,
        localParticipant,
        participants,
    }
}

export default useTwilio
