import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import map from 'lodash/map'
import styled from 'styled-components'
import { programTagsSelector } from '../../events/eventSlice'
import {
    locationFilterSelector,
    LocationType,
    tagsFilterSelector,
    updateLocationFilter,
    updateTagsFilter,
} from '../calendarSlice'
import colors from '../../../constants/colors'
import { _ } from '../../../utils/localization'
import MultiSelectFilter from '../../../components/MultiSelectFilter'
import { convertTagsGroupByCategory } from '../utils'

const S = {
    Label: styled.div`
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        color: ${colors.grayTitleOpacity(0.9)};
        text-transform: capitalize;
    `
}

const ProgramFilters = () => {
    const dispatch = useDispatch()
    const tagsFilter = useSelector(tagsFilterSelector)
    const locationFilter = useSelector(locationFilterSelector)
    const programTags = useSelector(programTagsSelector)
    const tagCategories = convertTagsGroupByCategory(programTags)
    const locationOptions = useMemo(() => (
        [
            { label: _('program_calendar.location_type.virtual'), value: LocationType.VIRTUAL },
            { label: _('program_calendar.location_type.in_person'), value: LocationType.IN_PERSON },
            { label: _('program_calendar.location_type.hybrid'), value: LocationType.HYBRID },
        ]
    ), [])

    return (
        <>
            {map(tagCategories, (value, key) => (
                <div key={key}>
                    <S.Label>{key}</S.Label>
                    <MultiSelectFilter
                        options={value}
                        selected={tagsFilter[key] || []}
                        onSelect={(_value) => {
                            dispatch(updateTagsFilter({
                                [key]: _value
                            }))
                        }}
                        allSelectedText={_('tag_filter.select_all')}
                    />
                </div>
            ))}
            <S.Label>{_('program_calendar.filter_location')}</S.Label>
            <MultiSelectFilter
                options={locationOptions}
                onSelect={(value) => dispatch(updateLocationFilter(value))}
                selected={locationFilter}
                allSelectedText={_('tag_filter.select_all')}
            />
        </>
    )
}

export default ProgramFilters
