import { format, isAfter } from 'date-fns'
import React from 'react'
import {
    OverlayTrigger, Popover, PopoverContent, PopoverTitle
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Avatar from '../../../components/Avatar'
import CustomButton from '../../../components/CustomButton'
import Divider from '../../../components/Divider'
import Icon from '../../../components/Icon'
import Typography from '../../../components/Typography'
import colors from '../../../constants/colors'
import useSlug from '../../../hooks/useSlug'
import { AppointmentSchedule } from '../../../types/__generated_swagger'
import getMugshotSrc from '../../../utils/getMugshotSrc'
import { _ } from '../../../utils/localization'
import { formatJobInfo, formatName } from '../../../utils/profile'
import { getUser } from '../../appointment_schedules/components/AppointmentRowInList'
import { currentUserSelector } from '../../auth/authSlice'
import CancelScheduleButton from '../../chat/components/appointment_schedule/CancelScheduleButton'
import JoinAppointmentButton from '../../chat/components/appointment_schedule/JoinAppointmentButton'
import openChatModal from '../../chat/openChatModal'
import { selectedDateSelector } from '../../timetable/redux/dateSlice'
import CalendarItem from '../CalendarItem'
import useFetchAppointmentsInSelectedDate from '../hooks/useFetchAppointmentsInSelectedDate'

const S = {
    Wrapper: styled.div`
        height: 100%;
        padding: 4px;
    `,
    UserInfo: styled.div`
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 16px;
        overflow: hidden;
    `,
    Actions: styled.div`
        display: flex;
        justify-content: flex-end;
    `,
}

const AppointmentItem = ({
    appointment,
    eventSlug,
    removedMutate,
}: {
    appointment: AppointmentSchedule
    eventSlug: string
    removedMutate: (id: any) => Promise<AppointmentSchedule[]>
}) => {
    const dispatch = useDispatch()
    const currentUser = useSelector(currentUserSelector())
    const participant = getUser(currentUser, appointment)
    const isEnded = isAfter(new Date(), new Date(appointment?.end_date))

    const handleOpenChat = () => {
        dispatch(openChatModal(eventSlug, participant?.id))
        window.document.body.click()
    }

    const popover = (
        <Popover id="popover-contained" style={{ maxWidth: 400 }}>
            <PopoverTitle as="h3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {_('appointment_schedule.popover.title')}
                <Icon icon="messageProcessing" className="ml-2" onClick={handleOpenChat} />
            </PopoverTitle>
            <PopoverContent>
                <S.UserInfo>
                    <Avatar src={getMugshotSrc(participant)} />
                    <div className="ml-3" style={{ overflow: 'hidden' }}>
                        <Typography
                            fontSize={14}
                            fontWeight={500}
                            color={colors.darkTitle}
                            className="text-cut notranslate"
                        >
                            {formatName(participant)}
                        </Typography>
                        <Typography fontSize={12} color={colors.grayDescription} className="text-cut">
                            {formatJobInfo(participant)}
                        </Typography>
                    </div>
                </S.UserInfo>
                <Divider marginBottom={16} />
                <Typography fontSize={12} fontWeight={300}>
                    {appointment.subject}
                </Typography>
                {!isEnded && (
                    <>
                        <Divider marginTop={16} />
                        <S.Actions onClick={() => document.body.click()}>
                            <CancelScheduleButton
                                receiver={participant}
                                appointmentSchedule={appointment}
                                currentUser={currentUser}
                                eventSlug={eventSlug}
                                renderCustomButton={() => (
                                    <CustomButton variant="secondary">{_('button.cancel_meeting')}</CustomButton>
                                )}
                                callback={() => removedMutate(appointment.id)}
                            />
                            <JoinAppointmentButton appointment={appointment} variant="success" />
                        </S.Actions>
                    </>
                )}
            </PopoverContent>
        </Popover>
    )

    return (
        <OverlayTrigger placement="right" overlay={popover} trigger="click" rootClose>
            <CalendarItem
                item={{ ...appointment, color: colors.darkOpacity(0.5) }}
                key={appointment.id}
                onClick={() => null}
            >
                <S.Wrapper>
                    <Typography fontSize={12} fontWeight={300} color={colors.icon}>
                        {`${format(new Date(appointment.start_date), 'HH:mm')} - ${format(
                            new Date(appointment.end_date),
                            'HH:mm'
                        )}`}
                    </Typography>
                    <Typography fontSize={12} color={colors.grayDescription} className="notranslate">
                        {formatName(getUser(currentUser, appointment))}
                    </Typography>
                </S.Wrapper>
            </CalendarItem>
        </OverlayTrigger>
    )
}

const MyAppointments = () => {
    const eventSlug = useSlug('eventSlug')
    const selectedDate = useSelector(selectedDateSelector)
    const { data, removedMutate } = useFetchAppointmentsInSelectedDate(selectedDate)

    if (!data) {
        return null
    }

    return (
        <>
            {data.map((appointment) => (
                <AppointmentItem
                    key={appointment.id}
                    eventSlug={eventSlug}
                    appointment={appointment}
                    removedMutate={removedMutate}
                />
            ))}
        </>
    )
}

export default MyAppointments
