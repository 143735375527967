import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import colors from '../../../constants/colors'
import SideBar from '../components/SideBar'
import GoodiebagContent from '../components/GoodiebagContent'
import { _ } from '../../../utils/localization'
import { eventSelector } from '../../events/eventOverviewSlice'

const S = {
    Wrapper: styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        min-height: 100%;
        padding: 40px 250px 40px 40px;
        background-color: ${colors.goodiebag.background};
    `,
    Container: styled.div`
        max-width: 1140px;
        width: 100%;
    `,
    Title: styled.h3`
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 20px;
        color: ${colors.grayTitle};
    `,
    Description: styled.p`
        color: ${colors.grayDescription};
        font-weight: 300;
    `
}

const Goodiebag = () => {
    const event = useSelector(eventSelector())
    const goodieBagName = event.goodiebag_name === 'Favorites' ? _('goodiebag.name') : event.goodiebag_name

    return (
        <S.Wrapper>
            <S.Container>
                <S.Title>{goodieBagName}</S.Title>
                <S.Description>{_('goodiebag.description')}</S.Description>
                <GoodiebagContent />
            </S.Container>
            <SideBar />
        </S.Wrapper>
    )
}

export default Goodiebag
