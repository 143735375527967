import useSWR from 'swr'
import useSlug from '../../../hooks/useSlug'
import { AppointmentSchedule } from '../../../types/swagger'
import { listFetcher } from '../../../utils/api'
import { TimeSlot } from '../types'

const useFetchAndConvertBlockedTimeSlots = (receiverId: number): {data: TimeSlot[], isLoading: boolean} => {
    const eventSlug = useSlug('eventSlug')
    const { data, error } = useSWR<AppointmentSchedule[]>(
        `/events/${eventSlug}/appointment_schedules/?filter=upcomming&receiver=${receiverId}`,
        listFetcher,
        { dedupingInterval: 15000, revalidateOnFocus: false }
    )

    if (!data && !error) {
        return {
            data: [],
            isLoading: true
        }
    }

    if (error) {
        return {
            data: [],
            isLoading: false
        }
    }

    return {
        data: (data || []).map((appointment) => ({
            start: new Date(appointment.start_date),
            end: new Date(appointment.end_date),
        })),
        isLoading: false
    }
}

export default useFetchAndConvertBlockedTimeSlots
