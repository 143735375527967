import { combineReducers } from 'redux'
import categorySlice from './categorySlice'
import itemsSlice from './itemsSlice'
import eventFilesSlice from './eventFilesSlice'

export default combineReducers({
    category: categorySlice,
    items: itemsSlice,
    eventItems: eventFilesSlice,
})
