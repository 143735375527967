import _axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import Logger from 'js-logger'
import { compose } from 'redux'
import env from '../constants/env'
import { RequestStatus } from '../types'
import { _ } from './localization'
import networkMonitor from './networkMonitor'

interface SafeResponse<T> {
    data?: T
    code?: number
    error?: string | string[] | Record<string, unknown>
}

export interface RequestState<T> {
    status: RequestStatus
    data: undefined | null | T
    error?: string | string[] | Record<string, unknown>
}

const withMonitor = (() => {
    // Should we maybe do something in production if a client sends
    // too many requests?
    if (!env.debug) {
        return (fn) => fn
    }

    return (fn) => (url: string, data?: any, config?: AxiosRequestConfig) => {
        networkMonitor.http.add(url)
        return fn(url, data, config)
    }
})()

const withUrlCheck = (fn) => (url: string, data?: any, config?: AxiosRequestConfig) => {
    if (!env.debug) {
        return fn(url, data, config)
    }
    const path = url.split('?')[0]
    if (!path.endsWith('/')) {
        Logger.error(`Path ${path} does not end in slash. Django might return 500 for this request`)
    }
    if (url.startsWith('api/') || url.startsWith('/api/')) {
        Logger.error(`URL ${url} starts with /api/, this should be omitted because its included in the base url`)
    }
    return fn(url, data, config)
}

/**
 * withParseResponse gets the data from a response.
 */
const withParseResponse = (fn) => async <T>(...args): Promise<T> => {
    const resp: AxiosResponse = await fn(...args)
    if (resp && resp.data) {
        return resp.data
    }
    return resp as unknown as Promise<T>
}

/**
 * makeSafe will never raise, but return the error in resp.error instead
 */
const makeSafe = (fn) => async <T=any>(
    url: string, data?: any, config?: AxiosRequestConfig,
): Promise<SafeResponse<T>> => {
    try {
        const resp = await fn(url, data, config)
        return { data: resp?.data, code: resp?.status }
    } catch (error) {
        if (error?.response) {
            // Request made and server responded
            return { error: error?.response?.data, code: error?.response?.status }
        } if (error?.request) {
            // The request was made but no response was received
            return { error: _('cant_reach_server') }
        }
        // Something happened in setting up the request that triggered an Error
        return { error: error.message }
    }
}

export const getPlainAxios = () => _axios.create({
    baseURL: env.apiUrl,
})

const wrapSafeRequest = !env.debug
    ? makeSafe
    : compose(makeSafe, withUrlCheck, withMonitor)

const wrapPlainRequest = !env.debug
    ? withParseResponse
    : compose(withUrlCheck, withMonitor, withParseResponse)

export const axios = getPlainAxios()

export const listFetcher = (url) => wrapPlainRequest(axios.get)(url).then((response) => response.results) // For swr

export default {
    // These functions may raise an error
    post: wrapPlainRequest(axios.post),
    get: wrapPlainRequest(axios.get),
    put: wrapPlainRequest(axios.put),
    patch: wrapPlainRequest(axios.patch),
    delete: wrapPlainRequest(axios.delete),

    // These functions will never raise, but return the error as data
    safePost: wrapSafeRequest(axios.post),
    safeGet: wrapSafeRequest(axios.get),
    safePut: wrapSafeRequest(axios.put),
    safePatch: wrapSafeRequest(axios.patch),
    safeDelete: wrapSafeRequest(axios.delete),
}
