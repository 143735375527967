import React, {
    useLayoutEffect, useState,
} from 'react'
import Popover from 'react-bootstrap/cjs/Popover'
import ListGroup from 'react-bootstrap/cjs/ListGroup'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from 'react-bootstrap/cjs/Button'
import omit from 'lodash/omit'

import { getLocalTimezone, isUpcomingDate } from '../../../utils/datetime'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { ProgramItem as ProgramItemType } from '../../timetable/types'
import ProgramItem from '../../timetable/components/ProgramItem'
import styles from '../../../constants/styles'
import { _ } from '../../../utils/localization'
import Icon from '../../../components/Icon'
import constants from '../../../constants/constants'
import Typography from '../../../components/Typography'

interface PopupProps {
    space: {
        items?: Omit<ProgramItemType, 'space'>[],
        containers?: Omit<ProgramItemType, 'space'>[],
        name?: string,
        type?: string,
        slug?: string,
    }
    show: boolean
    onMouseEnter: () => void
    onMouseLeave: () => void
    onAreaMouseLeave: () => void
    position: number[]
    offsetY: number
    onClickArea?: (event) => void
    published?: boolean
    showClosedSpaceNotification?: () => void
}

type PopoverProps = { showProgram: boolean }

const getWidth = ({ showProgram }: PopoverProps) => (
    showProgram
        ? 300
        : 'unset'
)

const S = {
    PopoverContent: styled(Popover.Content)`
        padding: 0;
    `,
    Popover: styled(Popover)<PopoverProps>`
        top: unset;
        bottom: 0;
        padding: 0;
        max-width: ${getWidth};
        width: ${getWidth};
        min-width: 180px;
    `,
    PopoverNoProgram: styled(Popover)`
        width: 200px;
        background: #2D3035;
        opacity: 0.95;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        h3 {
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: white;
            background: #2D3035;
            border-color: rgb(150, 161, 169, 0.2);
        }
        p {
            margin: 8px 0;
            font-style: italic;
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: white;
            opacity: 0.75;
        }
    `,
    ConfirmEnterSpace: styled.div`
        display: flex;
        align-items: center;
        font-size: 12px;
        width: 100%;
        padding: 0 6px;
    `,
    EnterSpaceButton: styled(Button)`
        display: flex;
        align-items: center;
        overflow: unset;
        margin: 6px;
        white-space: nowrap;
    `,
    EnterLink: styled(Link)`
        text-decoration: none;
        pointer-events: none;
        &:hover {
            text-decoration: none;
        }
    `,
    ListGroupItem: styled(ListGroup.Item)`
        padding: 0;
    `,
}

const Popup = ({
    show,
    position,
    onMouseEnter,
    onMouseLeave,
    space,
    onAreaMouseLeave,
    offsetY,
    onClickArea,
    published,
    showClosedSpaceNotification
}: PopupProps) => {
    const windowSize = useWindowDimensions()
    const items = ((space && [...(space?.items || []), ...(space.containers || [])]) || [])
        .filter((e) => isUpcomingDate(e.start_date, e.end_date))
        .sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime())
        .slice(0, constants.MAX_POPOVER_ITEMS)
        // add space props to items
        .map((item): ProgramItemType => ({
            ...item,
            space: omit<Partial<ProgramItemType>>(space, ['items', 'containers']),
        }))
    const [popupHeight, setPopupHeight] = useState(100)

    useLayoutEffect(() => {
        if (show && items.length) {
            setPopupHeight(38 + 63 * items.length) // Popover Header height: 38, Popover Item height: 63.
        }
    }, [show, items.length])

    const close = () => {
        onAreaMouseLeave()
        onMouseLeave()
    }

    const onClick = () => {
        onAreaMouseLeave()
        onMouseLeave()
        close()
    }

    if (!show || !position || !space) {
        return null
    }

    const showProgram = items.length > 0
    const padding = 20
    let minTopPosition = popupHeight + offsetY + padding
    const spaceNeededOnRight = 350
    const maxLeftPosition = windowSize.width - spaceNeededOnRight
    let topPosition = position[1] > minTopPosition ? position[1] : minTopPosition
    const leftPosition = position[0] > maxLeftPosition ? maxLeftPosition : position[0]

    // On safari, the offset of move position is different from another browser
    // It changes follow moving wrapper. In case tooMuchHeight, we move wrapper to top a distance equal tooMuchHeight/2 to center map.
    // And topPosition on Safari will move to top follow this distance.
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
        minTopPosition = popupHeight + padding + styles.topNavHeight
        topPosition = position[1] > minTopPosition ? position[1] : minTopPosition
        topPosition += offsetY - styles.topNavHeight
    }

    return (
        <div
            style={{
                left: leftPosition - 10,
                top: topPosition - 10,
                position: 'absolute',
            }}
        >
            {
                showProgram && published ? (
                    <S.Popover
                        id="kaas"
                        placement="auto"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        $showProgram={showProgram}
                    >
                        <Popover.Title as="h3" onClick={onClickArea}>
                            {space.name}
                            <Typography style={{ fontSize: 12 }}>
                                {`(Timezone ${getLocalTimezone()})`}
                            </Typography>
                        </Popover.Title>
                        <S.PopoverContent>
                            <ListGroup style={{ width: 350 }}>
                                {items.map((item) => (
                                    <S.ListGroupItem key={item.id}>
                                        <ProgramItem
                                            item={item}
                                            onClick={onClick}
                                        />
                                    </S.ListGroupItem>
                                ))}
                            </ListGroup>
                        </S.PopoverContent>
                    </S.Popover>
                ) : (
                    <S.PopoverNoProgram
                        id="kaas"
                        placement="auto"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        $showProgram={showProgram}
                        onClick={published ? onClickArea : showClosedSpaceNotification}
                    >
                        <Popover.Title as="h3">{space.name}</Popover.Title>
                        <S.PopoverContent>
                            {
                                published ? (
                                    <p>{_('map.popup.click_to_enter')}</p>
                                ) : (
                                    <p>
                                        <Icon icon="eyeOff" size={16} color="white" className="mb-1 mr-2" />
                                        {_('map.popup.space_closed')}
                                    </p>
                                )
                            }
                        </S.PopoverContent>
                    </S.PopoverNoProgram>
                )
            }
        </div>
    )
}

export default Popup
