import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import screenfull from 'screenfull'
import { RootState } from '../../store'
import { captureError } from '../../utils/errorTracker'
import { eventSelector } from './eventSlice'
import fetchEvent from './fetchEvent'
import { showErrorNotification } from '../notifications/notificationsSlice'
import exitEvent from './exitEvent'
import { _ } from '../../utils/localization'
import { changeShowTooltip, changeShowHelpPopover } from './fullScreenSlice'
import logout from '../auth/logout'
import FullPageLoading from '../../components/FullPageLoading'
import { useSubscribeToPublishedSpace } from '../spaces/subscriptions'
import { eventPreviewSelector } from "../auth/eventPreviewSlice"

const getErrorNotificationAction = (eventError) => {
    if (eventError.code === 'inviteonly') {
        return showErrorNotification(_('event_access.error.not_allow_message'))
    }

    if (eventError.detail === 'Not found.') {
        return showErrorNotification(_('event_access.error.not_found_event_message'))
    }

    return showErrorNotification(
        eventError.message
        || eventError.error
        || eventError.detail
        || _('event_access.error.not_allow_message')
    )
}

/**
 * Wraps all eventRoutes so the children can assume event is loaded and available.
 */
const EventProvider = ({ children, eventSlug }: any) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const fetchEventStatus = useSelector((state: RootState) => state.event.current.status)
    const eventError = useSelector((state: RootState) => state.event.current.error)
    const event = useSelector(eventSelector())
    const eventSlugHasChanged = event && event.slug && (event.slug.toLowerCase() !== eventSlug.toLowerCase())
    const refetchEvent = eventSlugHasChanged && fetchEventStatus !== 'loading'
    const eventPreview = useSelector(eventPreviewSelector)

    useSubscribeToPublishedSpace(eventSlug)

    // Start fetch
    React.useEffect(() => {
        if (fetchEventStatus === 'idle' || refetchEvent) {
            dispatch(fetchEvent({ eventSlug }))
            if (screenfull.isEnabled && !screenfull.isFullscreen && eventPreview?.popover === 'FULL_SCREEN') {
                dispatch(changeShowTooltip(true))
            }
        }
    }, [dispatch, eventSlug, fetchEventStatus, refetchEvent])

    React.useEffect(() => {
        if (eventPreview?.popover === 'HELP') {
            dispatch(changeShowHelpPopover(true))
        }
    }, [eventPreview?.popover])

    // Cleanup on unmount
    React.useEffect(() => () => { dispatch(exitEvent()) }, [dispatch])

    // If event fetching failed, go to home page.
    React.useEffect(() => {
        if (fetchEventStatus === 'failed') {
            captureError(
                `Event with slug ${eventSlug} could not be
                 get, probably because of a lack of permissions.
                 User will be logged out and redirected to EnterEvent`
            )

            dispatch(logout({ eventSlug }))
            dispatch(getErrorNotificationAction(eventError))
            history.push(`/${eventSlug}/enterevent`)
        }
    }, [fetchEventStatus, eventSlug, history, dispatch, eventError])

    return fetchEventStatus === 'succeeded' ? children : <FullPageLoading text={_('general.website_loading')} />
}

export default React.memo(EventProvider)
