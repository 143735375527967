import React from 'react'
import { Spinner } from 'react-bootstrap'
import styled, { css } from 'styled-components'
import colors from '../constants/colors'
import constants from '../constants/constants'

const S = {
    FullPageLoading: styled.div<{isAbsolute: boolean, shouldBeWrapped?: boolean}>`
        width: 100%;
        height: 100%;
        padding-bottom: 58px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        ${({ isAbsolute, shouldBeWrapped }) => (isAbsolute || shouldBeWrapped) && css`
            position: ${shouldBeWrapped ? 'absolute' : 'fixed'};
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: ${constants.Z_INDEX.LOADER};
            background: rgba(0, 0, 0, 0.2);
        `}
    `,
    LoadingIndicator: styled(Spinner)`
        width: 80px;
        height: 80px;
    `,
    Text: styled.p`
        margin-top: 10px;
        color: ${colors.gray7};
    `,
}

interface Props {
    text?: string
    isAbsolute?: boolean
    // For eg: In sidebar, we would like to show absolute loader in only sidebar
    shouldBeWrapped?: boolean
}

const FullPageLoading = ({ text, isAbsolute, shouldBeWrapped }: Props) => (
    <S.FullPageLoading isAbsolute={isAbsolute} shouldBeWrapped={shouldBeWrapped}>
        <S.LoadingIndicator animation="border" role="status" variant="secondary" />
        {text && <S.Text>{text}</S.Text>}
    </S.FullPageLoading>
)

export default FullPageLoading
