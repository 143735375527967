import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import ConfirmModal from '../../../../components/ConfirmModal'
import colors from '../../../../constants/colors'
import useReloadMessages from '../../../../hooks/useReloadMessages'
import { SimpleAppointmentSchedule } from '../../../../types'
import { VirtualAttendee } from '../../../../types/swagger'
import api from '../../../../utils/api'
import { captureError } from '../../../../utils/errorTracker'
import { _ } from '../../../../utils/localization'
import { setIsPageLoading } from '../../../events/pageLoadingSlice'
import { addModal } from '../../../modals/modalSlice'
import { showErrorNotification } from '../../../notifications/notificationsSlice'
import sendMessage from '../../sendMessage'
import { S } from './AppointmentScheduleNotification'

export interface ScheduleActionsProps {
    eventSlug?: string
    currentUser?: VirtualAttendee
    receiver?: VirtualAttendee
    appointmentSchedule?: SimpleAppointmentSchedule
    className?: string
    renderCustomButton?: () => ReactElement
    callback?: () => void
}

const DenyScheduleButton = ({
    eventSlug, currentUser, receiver, appointmentSchedule, callback
}: ScheduleActionsProps) => {
    const dispatch = useDispatch()
    const reloadMessages = useReloadMessages(receiver?.id)

    const denySchedule = async (close) => {
        try {
            dispatch(setIsPageLoading(true))
            await api.post(`/events/${eventSlug}/appointment_schedules/${appointmentSchedule.id}/deny/`)
            dispatch(sendMessage({
                eventSlug,
                text: 'DENIED',
                receiverId: receiver.id,
                currentUserId: currentUser.id,
                shouldSendEmail: !receiver?.is_online,
                appointmentSchedule,
                callback: reloadMessages,
            }))
            close()
            callback?.()
        } catch (error) {
            captureError(error)
            dispatch(showErrorNotification(_('appointment_schedule.deny_schedule_failed')))
        } finally {
            dispatch(setIsPageLoading(false))
        }
    }

    const showDenyConfirm = () => {
        dispatch(addModal({
            Component: ({ close }: {close: () => null}) => (
                <ConfirmModal
                    header={_('appointment_schedule.deny_schedule.modal_header')}
                    body={_('appointment_schedule.deny_schedule.modal_body')}
                    onConfirm={() => denySchedule(close)}
                    close={close}
                    confirmText={_('button.yes')}
                    cancelText={_('button.no')}
                />
            ),
            padding: 30
        }))
    }

    return (
        <S.TextButton
            color={colors.icon}
            className="mr-2"
            onClick={showDenyConfirm}
        >
            {_('button.deny')}
        </S.TextButton>
    )
}

export default DenyScheduleButton
