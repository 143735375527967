import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { add, startOfDay } from 'date-fns'
import sortBy from 'lodash/sortBy'
import { RootState } from '../../store'
import { RequestStatus } from '../../types'
import api from '../../utils/api'
import { ProgramsByLocationType, ProgramSpace } from '../timetable/types'

type HorizontalSize = 'XS' | 'S' | 'M' | 'L' | 'XL'
export type VerticalSize = 'S' | 'M' | 'L'

export const getProgramItemForCalendar = createAsyncThunk(
    'FETCH_CALENDAR_ITEMS',
    async ({
        eventSlug, date
    }: {eventSlug: string, date: Date}, thunkAPI) => {
        const state = thunkAPI.getState() as RootState
        const slug = eventSlug || state?.event?.current?.data?.slug
        const tomorrow = add(date, { days: 1 })
        const from = startOfDay(date).toISOString()
        const until = startOfDay(tomorrow).toISOString()
        const searchParams = new URLSearchParams({
            from_datetime: from,
            until_datetime: until,
        }).toString()
        const response: ProgramsByLocationType = await api.get<
            ProgramsByLocationType
        >(`/events/${slug}/virtualprogram/programs-by-location-type/?${searchParams}`)
        return response
    },
)

const calendarSlice = createSlice({
    name: 'Calendar',
    initialState: {
        isShowing: false,
        isExpanding: false,
        search: '',
        tagsFilter: {} as Record<string, number[]>,
        horizontalSize: 'M' as HorizontalSize,
        verticalSize: 'M' as VerticalSize,
        locationFilter: [],
        data: undefined as ProgramsByLocationType,
        status: 'idle' as RequestStatus,
    },
    reducers: {
        toggleSidebar: (state) => {
            state.isShowing = !state.isShowing
        },
        toggleExpanding: (state) => {
            state.isExpanding = !state.isExpanding
        },
        searchProgramSpace: (state, { payload }) => {
            state.search = payload
        },
        changeHorizontalSize: (state, { payload }: PayloadAction<HorizontalSize>) => {
            state.horizontalSize = payload
        },
        changeVerticalSize: (state, { payload }: PayloadAction<VerticalSize>) => {
            state.verticalSize = payload
        },
        updateTagsFilter: (state, { payload }: PayloadAction<Record<string, number[]>>) => {
            state.tagsFilter = {
                ...state.tagsFilter,
                ...payload
            }
        },
        updateLocationFilter: (state, { payload }) => {
            state.locationFilter = payload
        },
        closeCalendar: (state) => {
            state.isShowing = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProgramItemForCalendar.fulfilled, (state, { payload }: PayloadAction<any>) => {
            state.data = payload
            state.status = 'succeeded'
        })
        builder.addCase(getProgramItemForCalendar.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(getProgramItemForCalendar.rejected, (state) => {
            state.status = 'failed'
            state.data = null
        })
    },
})

export const calendarSidebarInfoSelector = (state: RootState) => ({
    isShowing: state.calendar.isShowing,
    isExpanding: state.calendar.isExpanding,
})
export const searchProgramSpaceSelector = (state: RootState) => state.calendar.search
export const sizeSelector = (state: RootState) => ({
    horizontal: state.calendar.horizontalSize,
    vertical: state.calendar.verticalSize
})
export const tagsFilterSelector = (state: RootState) => state.calendar.tagsFilter
export const locationFilterSelector = (state: RootState) => state.calendar.locationFilter
export const requestStatusSelector = (state: RootState) => state.calendar.status

const handleSpaces = (spaces: ProgramSpace[], type) => (
    sortBy(spaces, [(space) => space.type === 'booth'])
        // Remove spaces without items
        .filter((space: ProgramSpace) => space.items.length || space.containers.length)
        // add space props to items
        .map((space): ProgramSpace => ({
            ...space,
            location_type: type,
            items: [...space.items, ...space.containers]
        }))
)

export enum LocationType {
    VIRTUAL = 'VIRTUAL',
    IN_PERSON = 'IN_PERSON',
    HYBRID = 'HYBRID',
}

export type LocationTypeKeys = keyof typeof LocationType

export const getProgramByLocationType = (state: RootState) => {
    const data = state.calendar.data
    if (!data) {
        return []
    }

    const virtualPrograms = handleSpaces(data.virtual, LocationType.VIRTUAL)
    const hybridPrograms = handleSpaces(data.hybrid, LocationType.HYBRID)

    const inPersonPrograms = data.in_person
        // Remove locations without items
        .filter((location) => location.items.length || location.containers.length)
        .map((location) => ({
            ...location,
            location_type: LocationType.IN_PERSON,
            items: [...location.items, ...location.containers]
        }))

    return [
        ...virtualPrograms,
        ...hybridPrograms,
        ...inPersonPrograms
    ]
}

export const {
    toggleSidebar,
    toggleExpanding,
    searchProgramSpace,
    changeHorizontalSize,
    changeVerticalSize,
    updateTagsFilter,
    updateLocationFilter,
    closeCalendar,
} = calendarSlice.actions

export default calendarSlice.reducer
