import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { createVideocall } from '../../videocall/videocallSlice'
import useSlug from '../../../hooks/useSlug'
import { _ } from '../../../utils/localization'
import CustomPopover from '../../../components/CustomPopover'
import { acceptInvite, rejectInvite, WS } from '../inviteActions'
import { getPath } from '../useRedirectToRoom'
import { CallConfig } from '../types'
import useFetchAttendees from '../../attendees/useFetchAttendees'
import Loader from '../../../components/Loader'
import { useReduxBus } from '../../../utils/reduxmiddleware/reduxEventBus'
import AttendeeInfoWithTags from '../../networking/components/AttendeeInfoWithTags'

interface CallInviteProps {
    inviterId: number
    close: () => void
    config: CallConfig
}

const InviteeModal = ({ inviterId, close, config }: CallInviteProps) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const [isLoading, setLoading] = useState(false)
    const [inviter] = useFetchAttendees([inviterId])

    useReduxBus(() => {
        close()
    }, WS.inviteCancelled.type)

    const accept = async () => {
        setLoading(true)
        const result = await dispatch(createVideocall({
            eventSlug,
            admin: config.adminId,
        }))
        // @ts-ignore  TODO I do not understand this type...
        const videocall = unwrapResult(result)
        const twilioName = videocall.twilio_name
        dispatch(acceptInvite(inviterId, twilioName))
        dispatch(push(getPath(twilioName, eventSlug, config)))
        close()
    }

    const reject = () => {
        dispatch(rejectInvite(inviterId))
        close()
    }

    return (
        <CustomPopover
            title={_('receive_videocall_invite_modal.invite')}
            cancelButton={!isLoading && _('button.decline')}
            confirmButton={!isLoading && _('button.accept')}
            onCancel={reject}
            onConfirm={accept}
            cancelIcon="close"
            confirmIcon="check"
        >
            {isLoading ? <Loader /> : inviter && (
                <AttendeeInfoWithTags attendee={inviter} />
            )}
        </CustomPopover>
    )
}

export default InviteeModal
