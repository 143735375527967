import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import styled from 'styled-components'
import { _ } from '../../../../utils/localization'
import JobModal from '../../../jobs/JobModal'
import LoadMore from '../../../../components/LoadMore'
import Get from '../../../../components/Get'
import { ListJobOpenings2Response } from '../../../../types/swagger'
import useSlug from '../../../../hooks/useSlug'
import { Job } from '../../../jobs/types'
import { addModal } from '../../../modals/modalSlice'
import colors from '../../../../constants/colors'

const S = {
    Header: styled.div`
        padding: 20px;
        background: transparent;
        font-size: 22px;
        font-weight: 500;
        @media (max-width: 932px) {
            display: none;
        }
    `,
    ListGroup: styled(ListGroup)`
        border-radius: 0;
    `,
    JobItem: styled(ListGroup.Item)`
        cursor: pointer;
        &:hover {
            background-color: ${colors.gray2};
        }
    `
}

const BoothJobs = ({slug}: {slug: string}) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const [limit, setLimit] = useState(10)
    const loadMore = () => setLimit(limit + 10)
    const url = `/events/${eventSlug}/jobs/?booth_slug=${slug}`

    const showJobDetailModal = (job: Job) => () => {
        const Component = ({ close }: { close: () => void }) => (
            <JobModal job={job} close={close} />
        )
        dispatch(addModal({
            Component,
            padding: 0,
        }))
    }

    return (
        <div>
            <S.Header>
                {_('booth.tabs.jobs')}
            </S.Header>
            <Get url={url} params={{ limit }}>
                {(resp: ListJobOpenings2Response) => (
                    <>
                        <S.ListGroup>
                            {resp.results.map((job) => (
                                <S.JobItem onClick={showJobDetailModal(job)} className="text-cut">
                                    {job.title}
                                </S.JobItem>
                            ))}
                            {(resp.count > limit) && (
                                <LoadMore onClick={loadMore}>
                                    {_('jobs.sidebar.load_more')}
                                </LoadMore>
                            )}
                        </S.ListGroup>
                    </>
                )}
            </Get>
        </div>
    )
}

export default BoothJobs
