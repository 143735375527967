import sortBy from 'lodash/sortBy'
import { createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { ListNavigationItemsResponse } from '../../types/swagger'
import api from '../../utils/api'
import createRequestSlice from '../../utils/createRequestSlice'
import { RootState } from '../../store'

export const fetchNavigation = createAsyncThunk(
    'GET_NAVIGATIONS',
    async ({ eventSlug }: { eventSlug: string }) => api.get<ListNavigationItemsResponse>(`/events/${eventSlug}/navigation/`),
)

const navigationSlice = createRequestSlice<ListNavigationItemsResponse>(fetchNavigation, 'fetchNavigation')
export const { emptyData } = navigationSlice.actions
export default navigationSlice.reducer

export const navSelector = createSelector(
    [(state: RootState) => state.event.navigation],
    (navigation) => {
        if (navigation.status !== 'succeeded') {
            return []
        }

        return sortBy(Object.values(navigation.data || {}).flat(), 'order')
    },
)
