import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Participant } from '../useTwilio'
import { setSharingScreenId } from '../videocallSlice'

const S = {
    Video: styled.video`
      width: 100%;
      object-fit: contain;
      height: 100%;
      object-position: 50% 50%;
      overflow: hidden;
      background: black;
    `,
}

const SharedScreen = ({ participant }: { participant: Participant }) => {
    const dispatch = useDispatch()
    const [videoTracks, setVideoTracks] = useState([])
    const videoRef = useRef()

    const trackpubsToTracks = (trackMap) => Array.from(trackMap.values())
        // onderstaande weghalen
        .map((publication: {track: Record<string, unknown>}) => publication.track)
        .filter((track) => track !== null)

    useEffect(() => {
        const trackSubscribed = (track) => {
            if (track.kind === 'video') {
                setVideoTracks((currentVideoTracks) => [...currentVideoTracks, track])
            }
        }

        const trackUnsubscribed = (track) => {
            if (track.kind === 'video') {
                dispatch(setSharingScreenId(null))
                setVideoTracks((currentVideoTracks) => currentVideoTracks.filter((v) => v !== track))
            }
        }

        setVideoTracks(trackpubsToTracks(participant.videoTracks))
        participant.on('trackSubscribed', trackSubscribed)
        participant.on('trackUnsubscribed', trackUnsubscribed)
        return () => {
            setVideoTracks([])
            participant.removeAllListeners()
        }
    }, [dispatch, participant])

    useEffect(() => {
        const screenShareTrack = videoTracks[1]
        if (screenShareTrack) {
            screenShareTrack.attach(videoRef.current)
            return () => {
                screenShareTrack.detach()
            }
        }
    }, [dispatch, videoTracks])

    return <S.Video ref={videoRef} autoPlay />
}

export default SharedScreen
