import { RootState } from '../../../store'
import { SpaceType } from '../../../types/swagger'

const isFetchedSelector = (type: SpaceType, slug: string) => (state: RootState): boolean => {
    const spaceData: Record<SpaceType, any> = {
        map: state.map.getMap.requests[slug],
        presentation: state.presentationRoom.presentation.requests[slug],
        booth: state.exhibition.detail.requests[slug],
        networking: state.networking.pageData[slug],
        meeting: state.meeting.space.data[slug],
        cinema: state.cinema.cinema.data[slug],
        jobs: null, // TODO
        posters: state.poster.space[slug],
        chat_roulette: null, // TODO
        embedded: null, // TODO
    }
    return !!spaceData[type]
}

export default isFetchedSelector
