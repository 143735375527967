import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ItemDetails from '../../timetable/components/ItemDetails'
import {
    getProgramItem,
    programSelector,
} from '../../timetable/redux/programSlice'

const FetchItemDetail = ({
    eventSlug, itemId, hideHeader
}: { eventSlug: string, itemId: number, hideHeader?: boolean }) => {
    const dispatch = useDispatch()
    const program = useSelector(programSelector(itemId))

    useEffect(() => {
        dispatch(getProgramItem({ eventSlug, itemId }))
    }, [dispatch, eventSlug, itemId])

    if (program.status !== 'succeeded') {
        return <p>Loading...</p>
    }

    return <ItemDetails {...program.data} hideHeader={hideHeader} />
}

export default FetchItemDetail
