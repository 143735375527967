import NetworkingArea from './views/NetworkingArea'
import { createEventRoute } from '../../utils/routes'

interface GetNetworkingPath {
    eventSlug: string
    networkingSlug: string
    roomId?: string
}

export default {
    // This route is required so "getCurrentRoute" knows it exists.
    networkingAreaVideocall: createEventRoute({
        title: 'Networking Area',
        path: '/networking/:networkingSlug/videocall/:roomId',
        exact: true,
        component: NetworkingArea,
    }),
    networkingArea: createEventRoute({
        title: 'Networking Area',
        path: '/networking/:networkingSlug/',
        exact: false,
        component: NetworkingArea,
        getPath: ({ roomId, eventSlug, networkingSlug }: GetNetworkingPath) => (roomId
            ? `/${eventSlug}/networking/${networkingSlug}/videocall/${roomId}`
            : `/${eventSlug}/networking/${networkingSlug}`),
    }),
}
