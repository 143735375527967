import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import Icon from '../Icon'
import { IconOption } from '../Icon/icons'
import { colorStatus, renderIcon } from './utils'
import checkCompatibleSetting from '../../features/browsersupport/checkCompatibleSetting'

const S = {
    CompatibilityTestProgress: styled.div<{numOfItems: number}>`
        display: grid;
        grid-template-columns: repeat(${({ numOfItems }) => numOfItems - 1}, 1fr) 60px;
        align-items: center;
        width: 100%;
        padding: 20px 20px 20px 0;
    `,
    Wrapper: styled.div`
    `,
    Item: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
    `,
    Label: styled.span`
        position: absolute;
        display: flex;
        flex-wrap: nowrap;
        top: -25px;
        left: 35px;
        font-size: 14px;
        text-align: center;
        color: rgba(24, 28, 50, 0.9);
        transform: translateX(-50%);
    
    `,
    IconWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: ${({ color }) => color};
        & > span {
            margin-bottom: 2px;
        }
    `,
    Line: styled.div<{color: string}>`
        width: calc(100% - 40px);
        height: 3px;
        background-color: ${({ color }) => color};
    `,
    Spinner: styled(Spinner)`
        margin-left: 10px;
        width: 20px;
        height: 20px;
        border-width: 1px ;
    `
}

export interface SettingItem {
    label: string
    icon: IconOption
    noLine?: boolean
    type?: 'audio' | 'video' | 'sharedScreen'
    isCompleted?: boolean
    status?: 'pending' | 'processing' | 'success' | 'failed'
    message?: string
}

interface ItemProps {
    item: SettingItem
    failedLine?: boolean
    prevItemDone?: boolean
}

const Result = ({ item, failedLine, prevItemDone }: ItemProps) => {
    let status = 'pending' as SettingItem['status']
    if (prevItemDone) {
        status = 'processing'
    }

    if (item.isCompleted) {
        status = item.status
    }

    return (
        <S.Wrapper>
            <S.Item>
                <S.Label>
                    {item.label}
                    {status === 'processing'
                        ? <S.Spinner animation="border" />
                        : <div className="d-inline-block" style={{width: 30}} />}
                </S.Label>

                <S.IconWrapper color={colorStatus[status]}>
                    <Icon
                        icon={renderIcon(item, status)}
                        color="white"
                    />
                </S.IconWrapper>
                {!item.noLine && (
                    <S.Line color={failedLine ? colorStatus.failed : colorStatus[status]} />
                )}
            </S.Item>
        </S.Wrapper>
    )
}

const CompatibilityTestProgress = ({
    onCompletion = () => null
}: {onCompletion?: (status: SettingItem[]) => void}) => {
    const SETTING_ITEMS: SettingItem[] = useMemo(() => (
        [
            {
                label: 'Audio', icon: 'mic', type: 'audio'
            },
            {
                label: 'Videocall', icon: 'video', type: 'video'
            },
            {
                label: 'Screenshare', icon: 'monitorShare', noLine: true, type: 'sharedScreen'
            },
        ]
    ), [])
    const [settings, setSettings] = useState<SettingItem[]>(SETTING_ITEMS)

    async function handleCheckSetting(i: number) {
        const result = await checkCompatibleSetting(settings[i])
        setSettings(settings.map((e, index) => {
            if (index === i) {
                e.status = result.status
                e.message = result.message
                e.isCompleted = true
            }
            return e
        }))
    }

    useEffect(() => {
        settings.forEach((_, i) => {
            // Wait 3s before showing loading/success/fail state
            setTimeout(() => handleCheckSetting(i), (i + 1) * 3000)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const completed = !settings.find((e) => !e.isCompleted)
    useEffect(() => {
        if (completed) {
            onCompletion(settings)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completed])

    return (
        <S.CompatibilityTestProgress numOfItems={settings.length}>
            {settings.map((item, index) => (
                <Result
                    item={item}
                    key={index}
                    failedLine={settings[index + 1]?.status === 'failed'}
                    prevItemDone={index === 0 || settings[index - 1].isCompleted}
                />
            ))}
        </S.CompatibilityTestProgress>
    )
}

export default CompatibilityTestProgress
