import { combineReducers } from 'redux'
import boothsSlice from './boothsSlice'
import boothDetail from './boothDetailSlice'
import categorySlice from './categorySlice'
import leaveBusinessCardSlice from './leaveBusinessCardSlice'
import boothVististorsSlice from './boothVististorsSlice'

export default combineReducers({
    list: boothsSlice,
    detail: boothDetail,
    categories: categorySlice,
    leaveBusinessCard: leaveBusinessCardSlice,
    boothVisitors: boothVististorsSlice,
})
