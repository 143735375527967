import useSWR from 'swr'
import useSlug from '../../../hooks/useSlug'
import { listFetcher } from '../../../utils/api'
import { getUniqByDay } from '../../../utils/datetime'

const useFetchAllProgramDates = (ignoreInPersonProgram?: boolean) => {
    const eventSlug = useSlug('eventSlug')
    const { data } = useSWR<string[]>(
        `/events/${eventSlug}/virtualprogram/all-dates-has-program/?ignore-in-person=${ignoreInPersonProgram ? 'true' : ''}`,
        listFetcher,
        { dedupingInterval: 60000 }
    )
    const refinement_data = getUniqByDay(data)
    return refinement_data
}

export default useFetchAllProgramDates
