import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import StyledIcon from '../../../components/Icon'
import CardContainer from './CardContainer'
import { S } from './Speakers'
import { ContentProps } from './GoodiebagContent'
import Bookmark from '../Bookmark'
import { BoothData, GoodiebagType } from '../types'
import { eventSelector } from '../../events/eventSlice'
import LogoPlaceholder from '../../../assets/logo-placeholder.webp'

const Booth = ({ booth }: { booth: BoothData }) => {
    const event = useSelector(eventSelector())

    return (
        <S.Wrapper>
            <S.Image src={booth.logo || LogoPlaceholder} />
            <S.Content>
                <S.Title>{booth.name}</S.Title>
                <S.Description>
                    {booth.subtitle}
                </S.Description>
            </S.Content>
            <S.Actions>
                <Bookmark
                    item={{ id: booth.id, type: GoodiebagType.Booth }}
                    style={{ margin: '0 20px' }}
                    size={20}
                />
                <Link to={`/${event.slug}/map/${booth.map_slug}/booth/${booth.slug}`}>
                    <StyledIcon icon="eye" onClick={() => null} size={20} />
                </Link>
            </S.Actions>
        </S.Wrapper>
    )
}

const Booths = ({ items }: ContentProps) => (
    <CardContainer>
        {items.map(({ booth }) => (
            <Booth booth={booth} key={booth.id} />
        ))}
    </CardContainer>
)

export default Booths
