/**
 * Singleton always keeping track of mouse position
 */
const mousePosition = (() => {
    let _pos = null
    const _set = (event: MouseEvent) => {
        if (event) {
            _pos = [event.offsetX, event.offsetY]
        }
    }
    window.addEventListener('mousemove', _set)
    return { get: () => _pos }
})()

export default mousePosition
