import styled from 'styled-components'

interface DividerProps {
    extend?: number
    marginBottom?: number
    marginTop?: number
}

const Divider = styled.div<DividerProps>`
    // Extend divider to full width of form (cover padding/margin of form)
    width: calc(100% + ${({ extend }) => 2 * extend}px);
    height: 0;
    margin: 0 -${({ extend }) => extend}px;
    margin-top: ${({ marginTop }) => marginTop}px;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
    border-bottom: 1px solid rgba(120, 144, 156, 0.2);
`

Divider.defaultProps = {
    extend: 0,
    marginBottom: 20,
    marginTop: 0
}

export default Divider
