import React from 'react'
import styled from 'styled-components'

interface Props {
    children: any | any[]
}

interface OncChildProps {
    children: any
}

interface ChildrenProps {
    children: any[]
}

const S = {
    FillScreen: styled.div`
      height: 100%;
      width: 100%;
      overflow: hidden;
    `,
    DivideHorizontal: styled.div`
      max-height: 100%;
      overflow: hidden;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    `,
    DivideVertical: styled.div`
      max-height: 100%;
      overflow: hidden;
      flex-grow: 1;
      display: flex;
      flex-direction: row;
    `,
    HalfWidthCol: styled.div`
      width: 50%;
      overflow: hidden;
    `,
    HalfHeightRow: styled.div`
      height: 50%;
      overflow: hidden;
      display: flex;
      flex-direction: row;
    `,
    OneThirdHeightRow: styled.div`
      height: 33.3333%;
      overflow: hidden;
      display: flex;
      flex-direction: row;
    `,
    RowItem: styled.div`
      display: flex;
      flex-grow: 1;
    `,
}

const OneChild = ({ children }: OncChildProps) => (
    <S.FillScreen>
        {children}
    </S.FillScreen>
)

const TwoChildren = ({ children }: ChildrenProps) => (
    <S.DivideVertical>
        <S.HalfWidthCol>{children[0]}</S.HalfWidthCol>
        <S.HalfWidthCol>{children[1]}</S.HalfWidthCol>
    </S.DivideVertical>
)

const ThreeChildren = ({ children }: ChildrenProps) => (
    <S.DivideVertical>
        <S.HalfWidthCol>{children[0]}</S.HalfWidthCol>
        <S.HalfWidthCol>
            <S.DivideHorizontal>
                <S.HalfHeightRow>
                    <S.RowItem>{children[1]}</S.RowItem>
                </S.HalfHeightRow>
                <S.HalfHeightRow>
                    <S.RowItem>{children[2]}</S.RowItem>
                </S.HalfHeightRow>
            </S.DivideHorizontal>
        </S.HalfWidthCol>
    </S.DivideVertical>
)

const isEven = (n: number) => n % 2 === 0

const TwoRows = ({ children }: ChildrenProps) => (
    <S.DivideHorizontal>
        <S.HalfHeightRow>
            {children.map((child, i) => (!isEven(i) ? <S.RowItem>{child}</S.RowItem> : null))}
        </S.HalfHeightRow>
        <S.HalfHeightRow>
            {children.map((child, i) => (isEven(i) ? <S.RowItem>{child}</S.RowItem> : null))}
        </S.HalfHeightRow>
    </S.DivideHorizontal>
)

const ThreeRows = ({ children }: ChildrenProps) => (
    <S.DivideHorizontal>
        <S.OneThirdHeightRow>
            {children.map((child, i) => (i % 3 === 0 ? <S.RowItem>{child}</S.RowItem> : null))}
        </S.OneThirdHeightRow>

        <S.OneThirdHeightRow>
            {children.map((child, i) => (i % 3 === 1 ? <S.RowItem>{child}</S.RowItem> : null))}
        </S.OneThirdHeightRow>

        <S.OneThirdHeightRow>
            {children.map((child, i) => (i % 3 === 2 ? <S.RowItem>{child}</S.RowItem> : null))}
        </S.OneThirdHeightRow>
    </S.DivideHorizontal>
)

const ScreenDivider = ({ children }: Props) => {
    const amount = children.length || 1
    const Component = {
        1: OneChild,
        2: TwoChildren,
        3: ThreeChildren,
    }[amount] || (amount < 7 ? TwoRows : ThreeRows)
    return (
        <Component>
            {children}
        </Component>
    )
}

export default ScreenDivider
