import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Slider, { CustomArrowProps } from 'react-slick'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import colors from '../../../../constants/colors'
import EmbeddedVideo from '../../../embeddedvideo/EmbeddedVideo'
import VideoPlayer from '../../../presentation/components/VideoPlayer'
import Icon from '../../../../components/Icon'
import getThumbnailFromUrl from '../../../../utils/getThumbnailOfVideo'
import { Booth, BoothVideosType } from '../../types'
import Loader from '../../../../components/Loader'
import { _ } from '../../../../utils/localization'
import { captureError } from '../../../../utils/errorTracker'
import useResponsive from '../../../../hooks/useResponsive'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'

const S = {
    Header: styled.div`
        padding: 20px;
        background: transparent;
        font-size: 22px;
        font-weight: 500;
        @media (max-width: 932px) {
            display: none;
        }
    `,
    VideoPlayer: styled.div`
        width: 100%;
        height: 400px;
        margin-bottom: 4px;
        background: black;
    `,
    VideoList: styled.div`
        width: 100%;
        height: 150px;
        padding: 10px 0;
        background: ${colors.mineShaft};
        border-radius: 0 0 4px 4px;
    `,
    Slider: styled(Slider)`
        padding: 0 32px;
        & .slick-next {
            top: 52px;
            right: 4px;
            &:before {
                color: black;
            }
        }
    `,
    ButtonArrow: styled.div<{disable: boolean, isPrev: boolean}>`
        width: 30px;
        display: ${({ disable }) => (disable ? 'none' : 'inline-block')};
        position: absolute;
        top: 36px;
        ${({ isPrev }) => (isPrev ? css`
            left: 0;
            transform: rotate(90deg);
        ` : css`
            right: 0;
            transform: rotate(-90deg);
        `
    )}`,
    TitleVideoList: styled.h3`
        margin: 0 0 10px 32px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
    `,
    BottomVideo: styled.div`
        width: 150px;
        overflow: hidden;
        cursor: pointer;
    `,
    Image: styled.img`
        width: 100%;
        height: 85px;
        border-radius: 4px;
        object-fit: cover;
        object-position: center;
    `,
    TitleBottomVideo: styled.p`
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: white;
    `
}

const ArrowButton = ({ isPrev, className, ...props }: CustomArrowProps & {isPrev?: boolean}) => (
    <S.ButtonArrow disable={className.includes('slick-disable')} isPrev={isPrev}>
        <Icon
            {...props}
            size={30}
            icon="chevronDown"
        />
    </S.ButtonArrow>
)

const VideoThumbnail = ({ video, onClick }: {video: BoothVideosType[0], onClick: () => void}) => {
    const [thumbnail, setThumbnail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsLoading(true)
        getThumbnailFromUrl(video.embedded_url)
            .then((data) => {
                setThumbnail(data)
                setIsLoading(false)
            })
            .catch((err) => {
                captureError(err)
                setThumbnail('')
                setIsLoading(false)
            })
    }, [video.embedded_url])

    return (
        <S.BottomVideo onClick={onClick}>
            {isLoading ? <div style={{ height: 85 }}><Loader /></div> : <S.Image src={thumbnail} />}
            <S.TitleBottomVideo>{video.name}</S.TitleBottomVideo>
        </S.BottomVideo>
    )
}

const BoothVideos = ({ boothDetail }: {boothDetail: Booth}) => {
    const {isMobile} = useResponsive()
    const {width} = useWindowDimensions()
    const [video, setVideo] = useState(boothDetail.videos[0])
    const { trackEvent } = useMatomo()
    const { pushInstruction } = useMatomo()

    useEffect(() => {
        pushInstruction('MediaAnalytics::scanForMedia')
    }, [pushInstruction])

    const trackAction = (action, name) => {
        trackEvent({
            category: boothDetail.name,
            action,
            name,
        })
    }
    useEffect(() => {
        trackAction('video played', video.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [video])

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: isMobile ? width / 230 : 4,
        slidesToScroll: isMobile ? 2 : 4,
        prevArrow: <ArrowButton isPrev />,
        nextArrow: <ArrowButton />,
    }

    const selectVideo = (selectedVideo) => {
        setVideo(boothDetail.videos.find((_video) => _video.id === selectedVideo.id))
    }

    return (
        <div>
            <S.Header>
                {video.name}
            </S.Header>
            <S.VideoPlayer key={video.id}>
                {
                    video.url
                        ? <VideoPlayer src={video.url} />
                        : <EmbeddedVideo url={video.embedded_url} />
                }
            </S.VideoPlayer>
            {boothDetail.videos.length > 1 && (
                <S.VideoList>
                    <S.TitleVideoList>
                        {_('booth.tabs.videos.other_videos')}
                    </S.TitleVideoList>
                    <S.Slider {...settings}>
                        {boothDetail.videos.filter((e) => e.id !== video.id).map((e) => (
                            <VideoThumbnail
                                video={e}
                                key={e.id}
                                onClick={() => selectVideo(e)}
                            />
                        ))}
                    </S.Slider>
                </S.VideoList>
            )}
        </div>
    )
}

export default BoothVideos
