import { createEventRoute } from '../../utils/routes'
import Posters from './views/Posters'

interface GetPostersPath {
    eventSlug: string
    posterSlug: string
    roomId?: string
}

export default {
    // This route is required so "getCurrentRoute" knows it exists.
    postersVideocall: createEventRoute({
        title: 'Posters',
        path: '/posters/:posterSlug/videocall/:roomId',
        exact: true,
        component: Posters,
    }),
    posters: createEventRoute({
        title: 'Posters',
        path: '/posters/:posterSlug/',
        exact: false,
        component: Posters,
        getPath: ({ roomId, eventSlug, posterSlug }: GetPostersPath) => (roomId
            ? `/${eventSlug}/posters/${posterSlug}/videocall/${roomId}`
            : `/${eventSlug}/posters/${posterSlug}`),
    }),
}
