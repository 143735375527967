import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Area from '../components/Area'
import { Canvas } from '../canvas'
import styles from '../../../constants/styles'
import { clearPosition, positionSelector } from '../redux/positionSlice'
import { Area as AreaType } from '../types'
import { RetrieveMapSpaceResponse } from '../../../types/__generated_swagger'
import useParams from '../../../hooks/useParams'
import { fetchMap, mapSelector } from '../redux/getMapSlice'
import constants from '../constants'
import ShowDebugCoords from '../components/ShowDebugCoords'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import useHelpContent from '../../../hooks/useHelpContent'
import Loader from '../../../components/Loader'
import Sidebar from '../components/Sidebar'
import MapImagePlaceholder from '../../../assets/Map_placeholder.jpg'
import useCloseSpace from '../../../hooks/useCloseSpace'
import useWatchChangeInSpace from '../../../hooks/useWatchChangeInSpace'
import ClosedSpace from '../../../components/ClosedSpace'
import FullPageLoading from '../../../components/FullPageLoading'
import { eventPreviewSelector } from '../../auth/eventPreviewSlice'
import useResponsive from '../../../hooks/useResponsive'
import MobileMap from './MobileMap'

export type MapType = RetrieveMapSpaceResponse;
export type MapItemType = MapType['items'][0]
const zoomFactor = 2
const S = {
    Canvas: styled.canvas`
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none; // Make sure the underlying map gets the click events
    `,
    Image: styled.img`
        object-fit: contain;
        object-position: center;
        height: 100%;
        width: 100%;
    `,
    Map: styled.map`
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
    `,
    Page: styled.div`
        height: 100%;
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    `,
    MapWrapper: styled.div`
        position: relative;
        overflow: hidden;
        margin: auto;
    `,
    ZoomDiv: styled.div<{ activePosition: { x: number, y: number }, sWidth: number, sHeight: number }>`
        overflow: hidden;
        position: relative;
        height: 100%;
        width: 100%;
        transition: ${constants.transitionSpeed}ms ease-in-out!important;
        ${({ activePosition, sWidth, sHeight }) => activePosition && css`
            opacity: 0.3!important;
            transform: translate(
                ${(sWidth / 2 - activePosition.x) * zoomFactor}px,
                ${(sHeight / 2 - activePosition.y) * zoomFactor}px
            ) scale(${zoomFactor});
        `}
    `,
}

const getScreenWidth = (windowWidth) => (windowWidth < styles.minPageWidth
    ? styles.minPageWidth
    : windowWidth)

const getScreenHeight = () => window.innerHeight - styles.topNavHeight

const getScaling = (map: MapType, windowWidth: number) => {
    const { image_width, image_height } = map
    const screenWidth = getScreenWidth(windowWidth)
    const imageRatio = image_width / image_height
    const imageHeight = screenWidth / imageRatio
    const tooMuchHeight = imageHeight - getScreenHeight()
    return {
        width: screenWidth,
        height: imageHeight,
        scale: imageHeight / image_height,
        tooMuchHeight: tooMuchHeight > 0 ? tooMuchHeight : 0,
    }
}

const scaleCords = (coords: number[] = [], scale: number) => coords.map((cord) => cord * scale)
const scaleAreas = (areas: AreaType[], scale: number) => areas.map((area) => (
    { ...area, coords: scaleCords(area.coords, scale) }
))

const DisplayMap = ({ map }: { map: MapType }) => {
    const { width: windowWidth } = useWindowDimensions()
    const {
        width, height, scale, tooMuchHeight,
    } = getScaling(map, windowWidth)
    const scaledAreas = scaleAreas(map.items, scale)
    const canvasElementRef = React.useRef<HTMLCanvasElement>()
    const imageElementRef = React.useRef<HTMLImageElement>()
    const canvas = React.useRef<ReturnType<typeof Canvas> | undefined>()
    const activePosition = useSelector(positionSelector(map.meta.slug))
    const offsetY = tooMuchHeight / 2
    const chatId = map?.meta?.chat_id
    const eventPreview = useSelector(eventPreviewSelector)

    React.useLayoutEffect(() => {
        canvasElementRef.current.width = width
        canvasElementRef.current.height = height
        canvas.current = Canvas(
            canvasElementRef.current,
            width,
            height,
        )
        return () => canvas.current && canvas.current.destroy()
    }, [canvas, width, height])

    return (
        <S.Page>
            <S.ZoomDiv activePosition={activePosition} sWidth={width} sHeight={height}>
                <S.MapWrapper style={{ width, height, transform: `translateY(-${offsetY}px)` }}>
                    <ShowDebugCoords width={width} height={height} areas={scaledAreas} />
                    <S.Image
                        ref={imageElementRef}
                        src={map.meta.image || MapImagePlaceholder}
                        useMap="#map"
                    />
                    <S.Canvas ref={canvasElementRef} />
                    <S.Map name="map">
                        {
                            !activePosition && scaledAreas.map((area) => (
                                <Area
                                    key={area.id}
                                    area={area}
                                    hoverOn={(animate = true) => (
                                        canvas.current && canvas.current.draw(area, animate)
                                    )}
                                    hoverOff={() => canvas.current && canvas.current.erase()}
                                    offsetY={offsetY}
                                />
                            ))
                        }
                    </S.Map>
                </S.MapWrapper>
            </S.ZoomDiv>
            {(chatId || eventPreview?.twitter_search) && (
                <Sidebar chatId={chatId} twitterSearch={eventPreview?.twitter_search} />
            )}
        </S.Page>
    )
}

const Map = () => {
    const dispatch = useDispatch()
    const { eventSlug, mapSlug } = useParams()
    const map = useSelector(mapSelector(mapSlug))
    const requestStatus = map.status
    const { isMobile } = useResponsive()

    const closeSpace = useCloseSpace(map?.data?.meta?.published)
    useWatchChangeInSpace(map?.data?.meta?.id)

    useHelpContent({data: map?.data?.meta.help_content, type: map?.data?.meta.help_content_type})

    // Fetch
    useEffect(() => {
        dispatch(fetchMap({ eventSlug, mapSlug }))
    }, [dispatch, eventSlug, mapSlug])

    // Clear position
    useEffect(() => () => {
        dispatch(clearPosition(mapSlug))
    }, [dispatch, mapSlug])

    if (requestStatus !== 'succeeded') {
        return <FullPageLoading />
    }

    if (closeSpace) {
        return <ClosedSpace />
    }

    // eslint-disable-next-line no-nested-ternary
    return map.data ? (
        isMobile ? (
            <MobileMap map={map.data} />
        ) : (
            <DisplayMap map={{...map.data, items: (map.data.items || []).filter((e) => !e.for_mobile)}} />
        )
    ) : <Loader />
}

export default Map
