import { startOfDay } from 'date-fns'
import useSWR from 'swr'
import useSlug from '../../../hooks/useSlug'
import { AppointmentSchedule } from '../../../types/__generated_swagger'
import { listFetcher } from '../../../utils/api'

const useFetchAppointmentsInSelectedDate = (selectedDate?: Date) => {
    const eventSlug = useSlug('eventSlug')
    const { data, mutate } = useSWR<AppointmentSchedule[]>(
        `/events/${eventSlug}/appointment_schedules/?date=${selectedDate ? startOfDay(selectedDate).toISOString() : ''}&status=ACCEPTED`,
        listFetcher,
        { dedupingInterval: 5000 }
    )
    return {
        data,
        removedMutate: (id) => mutate(
            data.filter((e) => e.id !== id),
            false
        ),
    }
}

export default useFetchAppointmentsInSelectedDate
