import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../utils/api'
import { RequestStatus } from '../../types'
import auth from '../../utils/auth'
import { showNotification } from '../notifications'
import { activateUser, getCurrentUser } from './authSlice'
import { showErrorNotification } from '../notifications/notificationsSlice'
import { RetrieveVirtualEventResponse } from '../../types/__generated_swagger'
import { activateProfile } from '../profile/editProfileSlice'
import { RootState } from '../../store'

export const getEventPreview = createAsyncThunk(
    'GET_EVENT_PREVIEW',
    (eventSlug: string) => api.get<RetrieveVirtualEventResponse>(`events/${eventSlug}/preview/?from_vep=true`),
)

export const enterEvent = createAsyncThunk(
    'ENTER_EVENT',
    async (params: {email: string, code: string, eventSlug: string}, thunkApi) => {
        const { email, code, eventSlug } = params
        const response = await auth.login({ email, code, event_slug: eventSlug })
        if (response.error) {
            thunkApi.dispatch(showErrorNotification(response.error))
            return response
        }

        // If activation_key is returned instead of JWT, the account is new
        // and needs to be activated.
        // See https://twoppy.atlassian.net/browse/ORG-788
        // Swagger doesnt know this, so ts-ignore
        // @ts-ignore
        const activationKey = response.data.activation_key
        if (activationKey) {
            await thunkApi.dispatch(activateUser({ activationKey, eventSlug }))
            thunkApi.dispatch(activateProfile())
        }

        // If getCurrentUser is rejected, the email does not correspond to a user
        // and we can not allow access to the event.
        const { type } = await thunkApi.dispatch(getCurrentUser())
        if (type === getCurrentUser.rejected.type) {
            thunkApi.dispatch(showErrorNotification('This is an invite only event'))
            return response
        }

        thunkApi.dispatch(getEventPreview(eventSlug))

        return response
    },
)

export const requestCode = createAsyncThunk(
    'REQUEST_CODE',
    async (params: {email: string, eventSlug: string}, thunkApi) => {
        const { email, eventSlug } = params
        //  We used to differentiate between 'code' and 'link'. The latter gave too many issues
        //  so we decided to stick only with the 'code' type login.
        //  See https://twoppy.atlassian.net/browse/APP-612
        //
        //  By passing 'type: "code"', the backend just sends a plain code
        //  instead of a deeplink to an app
        const data = { email, type: 'code', event_slug: eventSlug }
        const response = await api.safePost('/auth/callback/', data)
        if (response.error) {
            thunkApi.dispatch(showErrorNotification(response.error))
            return response
        }
        thunkApi.dispatch(showNotification({
            body: 'Email has been sent',
            type: 'success',
        }))
        return response
    },
)

const eventPreviewSlice = createSlice({
    name: 'event_preview',
    initialState: {
        eventData: undefined as RetrieveVirtualEventResponse,
        requestStatus: 'idle' as RequestStatus,
    },
    reducers: {
        clearEventPreview: (state) => ({
            ...state,
            eventData: undefined,
            requestStatus: 'idle'
        })
    },
    extraReducers: (builder) => {
        builder.addCase(getEventPreview.fulfilled, (state, { payload }) => {
            state.eventData = payload
            state.requestStatus = 'succeeded'
        })
        builder.addCase(getEventPreview.pending, (state) => {
            state.requestStatus = 'loading'
        })
        builder.addCase(getEventPreview.rejected, (state) => {
            state.requestStatus = 'failed'
        })
    },
})

export const eventLogoSelector = (state: RootState) => state.eventPreview?.eventData?.logo
export const eventTimeFormatSelector = (state: RootState) => state.eventPreview?.eventData?.time_format
export const showAttendeesOutsideSelector = (state: RootState) => (
    state.eventPreview?.eventData?.show_attendee_list_outside
)
export const showJobsInsideBoothsSelector = (state: RootState) => (
    state.eventPreview?.eventData?.show_jobs_inside_booths
)
export const eventPreviewSelector = (state: RootState) => state.eventPreview?.eventData
export const showSpeakerIconSelector = (state: RootState) => (
    state.eventPreview?.eventData?.show_speaker_icon
)
export const showCalendarIconSelector = (state: RootState) => (
    state.eventPreview?.eventData?.show_calendar_icon
)

export const clearEventPreviewState = eventPreviewSlice.actions.clearEventPreview

export default eventPreviewSlice.reducer
