import { createSlice } from '@reduxjs/toolkit'

const fullScreenSlice = createSlice({
    name: 'fullScreenSlice',
    initialState: {
        isShowTooltip: false as boolean,
        isShowHelpPopover: false,
    },
    reducers: {
        changeShowTooltip: (state, { payload }) => {
            state.isShowTooltip = payload
        },
        changeShowHelpPopover: (state, { payload }) => {
            state.isShowHelpPopover = payload
        }
    }
})
export const changeShowTooltip = fullScreenSlice.actions.changeShowTooltip
export const changeShowHelpPopover = fullScreenSlice.actions.changeShowHelpPopover
export default fullScreenSlice.reducer
