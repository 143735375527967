import React, { useEffect, useState } from 'react'
import {
    Redirect,
    Route,
    Switch,
    useParams,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import intl from 'react-intl-universal'
import Logger from 'js-logger'
import styled from 'styled-components'
import { withErrorBoundary } from '@sentry/react'
import { ThemeProvider } from '@mui/material'
import history from './history'
import routes, { eventRouteList, noEventRouteList } from './routes'
import { ParamSlug, RouteProps } from './types'
import { RootState } from './store'
import ModalContainer from './features/modals/ModalContainer'
import LocaleProvider from './components/LocaleProvider'
import NotificationsContainer from './features/notifications/NotificationsContainer'
import env from './constants/env'
import Nav from './components/Nav'
import styles from './constants/styles'
import TimeTableFooter from './features/timetable/TimeTableFooter'
import NotFound from './components/NotFound'
import EventProvider from './features/events/EventProvider'
import WebsocketProvider from './features/websocket/WebsocketProvider'
import MatomoProvider from './components/MatomoProvider'
import EventMapRedirect from './features/events/EventMapRedirect'
import TransitionSwitch from './components/TransitionSwitch'
import { eventLocaleSelector } from './features/events/eventSlice'
import LeaveNetworking from './features/networking/components/LeaveNetworking'
import FullPageLoading from './components/FullPageLoading'
import { _ } from './utils/localization'
import checkSupportedBrowser from './features/browsersupport/checkSupportedBrowser'
import UnsupportedBrowser from './features/browsersupport/UnsupportedBrowser'
import ErrorPage from './components/ErrorPage'
import SoundNotification from './features/soundnotifications/SoundNotification'
import AttendeesSidebar from './features/attendees/AttendeesSidebar'
import { showSideBarSelector } from './features/attendees/attendeesSlice'
import { showAttendeesOutsideSelector, showCalendarIconSelector } from './features/auth/eventPreviewSlice'
import ProgramCalendar from './features/calendar/ProgramCalendar'
import { calendarSidebarInfoSelector } from './features/calendar/calendarSlice'
import PerformerSidebar from './features/performer/PerformerSidebar'
import { isShowSidebarSelector } from './features/performer/performerSlice'
import { isPageLoadingSelector } from './features/events/pageLoadingSlice'
import theme from './theme'
import useResponsive from './hooks/useResponsive'
import NavMobile from './components/NavMobile/NavMobile'
import { preventHideProfileSelector } from './features/profile/editProfileSlice'
import { addModal } from './features/modals/modalSlice'
import ProfileBadge from './features/profile/ProfileBadge'

interface RoutesSwitchProps {
    isLoggedIn: boolean
}
const S = {
    Page: styled.div`
        padding-top: ${styles.topNavHeight}px; // don't put content underneath navigation
        position: absolute;
        height: 100%;
        width: 100%;
        min-width: 100vw;
        min-height: var(--window-height);
        background: #f9f9f9;
`,
}

/**
 * The navigation and authorization should work as follows:
 *
 * User navigates to Event Route:
 * 1. User must be logged in, if not -> go to EnterEvent
 * 2. User must have access to event (Is Event Attendee), if not -> go to EnterEvent
 *
 * User navigates to Not-public route:
 * 1. User must be logged in, if not -> Redirected to home page
 *
 * User enters credentials at EnterEvent
 * 1. If credentials correct -> Navigate to home space of event.
 */

const createTitle = (routeTitle: string) => {
    const titlePrefix = routeTitle && `${routeTitle} | `
    return `${titlePrefix}${env.appTitle}`
}

const CreateRouteRenderer = ({ route, isLoggedIn, ...props }: {route: RouteProps, isLoggedIn: boolean}) => {
    const authorized = isLoggedIn || route.isPublic
    Logger.debug(`Render route ${route.title} authorized=${authorized}`)
    const Component = withErrorBoundary(route.component || NotFound, { fallback: ErrorPage })

    return !authorized
        ? <Redirect to="/" />
        : (
            <S.Page id="page-content">
                <Helmet>
                    <title>{ createTitle(route.title || '') }</title>
                </Helmet>
                <Component {...props} />
            </S.Page>
        )
}

// Event component should only be rendered if user is logged in.
const Event = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
    const { isMobile } = useResponsive()
    const { eventSlug } = useParams<ParamSlug>()
    const showAttendeesSidebar = useSelector(showSideBarSelector)
    const showAttendeesOutside = useSelector(showAttendeesOutsideSelector)
    const { isShowing: showCalendar } = useSelector(calendarSidebarInfoSelector)
    const showPerformerSidebar = useSelector(isShowSidebarSelector)
    const showCalendarIcon = useSelector(showCalendarIconSelector)

    return !isLoggedIn
        ? <Redirect to={routes.enterEvent.getPath({ eventSlug })} />
        : (
            <EventProvider eventSlug={eventSlug}>
                {showPerformerSidebar && <PerformerSidebar />}
                <WebsocketProvider />
                {isMobile ? <NavMobile /> : <Nav />}
                <TransitionSwitch>
                    { eventRouteList.map((route, index) => (
                        <Route
                            path={route.path}
                            exact={route.exact}
                            key={index}
                        >
                            <CreateRouteRenderer route={route} isLoggedIn={isLoggedIn} />
                        </Route>
                    ))}
                    <Route component={EventMapRedirect} path="*" />
                </TransitionSwitch>
                {showCalendarIcon && <TimeTableFooter isMobile={isMobile} />}
                {showCalendarIcon && showCalendar && <ProgramCalendar />}
                {showAttendeesSidebar && showAttendeesOutside && <AttendeesSidebar />}
            </EventProvider>
        )
}

const RoutesSwitch = ({ isLoggedIn }: RoutesSwitchProps) => (
    <Switch>
        { noEventRouteList.map((route, index) => (
            <Route
                path={route.path}
                exact={route.exact}
                key={index}
            >
                <CreateRouteRenderer route={route} isLoggedIn={isLoggedIn} />
            </Route>
        ))}

        <Route path="/:eventSlug" exact={false}>
            <Event isLoggedIn={isLoggedIn} />
        </Route>

        <Route path="*" component={NotFound} />
    </Switch>
)

const AppContent = () => {
    const dispatch = useDispatch()
    const auth = useSelector((state: RootState) => state.auth)
    const eventLocale = useSelector(eventLocaleSelector)
    const [, setLocale] = useState('en-US') // Help to re-render when locale changes
    const isSupportedBrowser = checkSupportedBrowser()
    const isPageLoading = useSelector(isPageLoadingSelector)
    const preventHideProfile = useSelector(preventHideProfileSelector)

    useEffect(() => {
        const windowHeight = () => {
            const doc = document.documentElement
            doc.style.setProperty('--window-height', `${window.innerHeight}px`)
        }
        window.addEventListener('resize', windowHeight)
        windowHeight()
    }, [])

    useEffect(() => {
        // At first time, the eventProvider is not rendered. So we can't get language from event.current
        // then LocaleProvider will use the default locale (en-US) to render the whole app
        // When App is rendered. The eventProvider will fetch event/access and update language of event
        // And LocaleProvider will update InitOptions with language of event.

        // Has a weird issue here is when LocaleProvider is updated, the intl.getInitOptions().currentLocale is still en-US
        // Set state here to help this component re-render and update locale.
        if (eventLocale !== intl.getInitOptions().currentLocale) {
            setLocale(eventLocale)
        }
    }, [eventLocale])

    useEffect(() => {
        if (preventHideProfile) {
            dispatch(addModal({
                Component: ({close}: {close: () => void}) => (
                    <ProfileBadge close={close} />
                ),
                backdrop: false,
                padding: 0,
            }))
        }
    }, [dispatch, preventHideProfile])

    if (!isSupportedBrowser) {
        return <UnsupportedBrowser />
    }

    return auth.isLoadingAuth
        ? <FullPageLoading text={_('general.website_loading')} />
        : (
            <ConnectedRouter history={history}>
                <MatomoProvider />
                <ModalContainer />
                <RoutesSwitch isLoggedIn={auth.isLoggedIn} />
                <NotificationsContainer />
                <LeaveNetworking />
                <SoundNotification />
                {isPageLoading && <FullPageLoading isAbsolute />}
            </ConnectedRouter>
        )
}

const App = () => (
    <LocaleProvider>
        <ThemeProvider theme={theme}>
            <AppContent />
        </ThemeProvider>
    </LocaleProvider>
)

export default App
