import React from 'react'
import { urlOptions, getIsValidStreamUrl, processUrl } from '../../../embeddedvideo/embedded_urls'

interface EmbeddedVideoProps {
    width?: number | string;
    height?: number | string;
    url?: string;
}

const DebugMessage = ({ url }: { url: string }) => (
    <div style={{ background: 'black', color: 'white' }}>
        Test server only debugging message: url
        {' '}
        {url}
        {' '}
        is not a valid
        lifestream. Choose one of:
        {' '}
        {urlOptions.join(', ')}
    </div>
)

const EmbeddedVideo = ({ url, width = '100%', height = '100%' }: EmbeddedVideoProps) => {
    const isValidStreamUrl = getIsValidStreamUrl(url)

    if (!isValidStreamUrl) {
        return <DebugMessage url={url} />
    }

    return (
        <iframe
            src={processUrl(url || '')}
            title="stream"
            width={width}
            height={height}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
        />
    )
}

export default EmbeddedVideo
