import React from 'react'
import styled from 'styled-components'
import GeneralInfoWithThumbnail
    from '../../../components/GeneralInfoWithThumbnail'
import getMugshotSrc from '../../../utils/getMugshotSrc'
import { VirtualAttendee } from '../../../types/swagger'
import useFetchAttendees from '../../attendees/useFetchAttendees'
import useSubscribeAttendees from '../../attendees/useSubscribeAttendees'

export const S = {
    AttendeeWrapper: styled.div`
        max-height: 200px;
        overflow-y: auto;
    `,
}

const AttendeeList = ({ attendees = [] }: { attendees: number[] }) => {
    useSubscribeAttendees(attendees)
    const attendeeObjects = useFetchAttendees(attendees)
    return (attendeeObjects.length === 0 ? null : (
        <S.AttendeeWrapper>
            { attendeeObjects.map((attendee: VirtualAttendee) => (
                <GeneralInfoWithThumbnail
                    key={attendee.id}
                    name={`${attendee.first_name} ${attendee.last_name}`}
                    image={getMugshotSrc(attendee)}
                    noBorder
                    size="sm"
                />
            )) }
        </S.AttendeeWrapper>
    ))
}
export default React.memo(AttendeeList)
