import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { PayloadAction } from '@reduxjs/toolkit'
import { RequestReplied, WS } from '../features/videocall/videocallSlice'
import sendToWs from '../features/websocket/sendToWs'
import { useReduxBus } from '../utils/reduxmiddleware/reduxEventBus'
import { ReceivedFromUserPayload } from '../features/websocket/sendToUser'

const useRequestAccess = ({ videocallId, redirect, close }) => {
    const dispatch = useDispatch()
    const [isAccessRequestSent, setIsAccessRequestSent] = useState(false)
    const [isRequestDenied, setIsRequestDenied] = useState(false)

    const handleAccessGranted = () => {
        close()
        redirect()
    }

    const requestVideocallAccess = () => {
        dispatch(sendToWs({
            videocall_id: videocallId,
            type: 'request_videocall_access',
        }))
        setIsAccessRequestSent(true)
    }

    const cancelAccessRequest = () => {
        dispatch(sendToWs({
            videocall_id: videocallId,
            type: 'cancel_videocall_access_request',
        }))
    }

    // Handle reply
    useReduxBus(({ payload }: PayloadAction<ReceivedFromUserPayload<RequestReplied>>) => {
        payload?.data?.accepted
            ? handleAccessGranted()
            : setIsRequestDenied(true)
    }, WS.requestReplied.type)

    return {
        isAccessRequestSent,
        isRequestDenied,
        requestVideocallAccess,
        cancelAccessRequest
    }
}

export default useRequestAccess
