import React from 'react'
import styled from 'styled-components'
import { VirtualAttendee } from '../../../types/swagger'
import { Booth } from '../types'
import BoothDetail from './BoothContentComponents/BoothDetail'

const S = {
    Wrapper: styled.div`
        margin: 0 auto;
        min-height: 350px;
        display: flex;
    `,
}

const BoothContent = ({ boothDetail, staffs }: {boothDetail: Booth, staffs: VirtualAttendee[]}) => (
    <S.Wrapper>
        <BoothDetail boothDetail={boothDetail} staffs={staffs} />
    </S.Wrapper>
)

export default BoothContent
