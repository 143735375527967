import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components'
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap'
import Icon from './Icon'
import colors from '../constants/colors'

const S = {
    Container: styled.div`
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 1px solid ${colors.inputBorder};
    `,
    ToggleContainer: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        margin-bottom: 16px;
        cursor: pointer;
    `,
    Title: styled.span`
        color: ${colors.grayTitle};
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        &:hover {
            color: ${colors.iconOpacity(1)};
        }
    `,
    ExpansionIcon: styled(Icon)<{ isExpanded: boolean }>`
        margin-left: 4px;
        margin-bottom: ${({ isExpanded }) => (isExpanded ? '-8px' : 0)};
        transform: ${({ isExpanded }) => (isExpanded ? 'rotate(-180deg) translateY(4px)' : 'none')};
        transition: 0.3s;
    `,
    NumberIndicator: styled.span`
        font-weight: 300;
        font-size: 16px;
        color: ${colors.iconOpacity(0.75)};
    `
}

const CustomToggle = ({
    eventKey, title, counter
}: { eventKey: string, title: string, counter?: number }) => {
    const currentEventKey = useContext(AccordionContext)
    const decoratedOnClick = useAccordionToggle(eventKey)

    const isCurrentEventKey = currentEventKey === eventKey

    return (
        <S.ToggleContainer onClick={decoratedOnClick}>
            <S.Title>{title}</S.Title>
            <div className="flex-align-center">
                {!!counter && <S.NumberIndicator>{counter}</S.NumberIndicator>}
                <S.ExpansionIcon
                    icon="chevronDown"
                    size={18}
                    color={colors.iconOpacity(0.5)}
                    isExpanded={isCurrentEventKey}
                />
            </div>
        </S.ToggleContainer>
    )
}

export interface CollapsiblePanelProps {
    title: string
    children: ReactElement
    isDefaultCollapsed?: boolean
    className?: string
    counter?: number
}

const CollapsiblePanel = ({
    title,
    children,
    isDefaultCollapsed = false,
    className,
    counter,
}: CollapsiblePanelProps) => (
    <S.Container className={className}>
        <Accordion defaultActiveKey={isDefaultCollapsed ? '' : '0'}>
            <CustomToggle eventKey="0" title={title} counter={counter} />
            <Accordion.Collapse eventKey="0" className="content">
                {children}
            </Accordion.Collapse>
        </Accordion>
    </S.Container>
)

export default CollapsiblePanel
