import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { Divider } from "@mui/material"
import Icon from '../../components/Icon'
import { addModal } from '../modals/modalSlice'
import { helpContentSelector, helpContentTypeSelector } from './HelpContentSlice'
import { _ } from '../../utils/localization'
import colors from '../../constants/colors'
import { RootState } from "../../store"
import { changeShowHelpPopover } from "../events/fullScreenSlice"
import { HtmlTooltip } from "../../components/Nav/components/FullScreenToggle"
import { processUrl } from "../embeddedvideo/embedded_urls"
import CustomButton from "../../components/CustomButton"

const S = {
    Content: styled.div`
        overflow-x: hidden;
    `,
    IconWrapper: styled.div`
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
        margin-left: 18px;
        margin-right: 6px;
        border-left: 1px solid ${colors.navDivider};
        border-right: 1px solid ${colors.navDivider};
    `,
    Actions: styled.div`
        display: flex;
        justify-content: flex-end;
    `,
}

export const useOpenHelpModal = () => {
    const dispatch = useDispatch()
    const helpContent = useSelector(helpContentSelector)
    const helpContentType = useSelector(helpContentTypeSelector)

    // In quill editor, when user clears the content, it doesn't become a empty string but the string '<p><br></p>'
    // https://github.com/quilljs/quill/issues/1235
    // So we don't need to show help content in this case.
    if (!helpContent || helpContent === '<p><br></p>') {
        return null
    }

    return () => dispatch(addModal({
        Component: () => (helpContentType === 'VIDEO' ? (
            <div style={{width: '100%', aspectRatio: '16/9'}}>
                <iframe
                    src={processUrl(helpContent)}
                    title="Tutorial"
                    width="100%"
                    allow="autoplay; fullscreen;"
                    style={{aspectRatio: '16/9'}}
                />
            </div>
        ) : <S.Content className="editor-content" dangerouslySetInnerHTML={{ __html: helpContent }} />),
        size: 'xl',
        header: _('help_context.title'),
        padding: helpContentType === 'VIDEO' ? 0 : 16,
    }))
}

const HelpContent = () => {
    const dispatch = useDispatch()
    const onClick = useOpenHelpModal()
    const isShowHelpPopover = useSelector((state: RootState) => state.event.fullScreen.isShowHelpPopover)
    const eventPreview = useSelector((state: RootState) => state.eventPreview)

    const popover = (
        <div>
            <h6>{_('help_popover.title')}</h6>
            <Divider sx={{mb: 1}} />
            <p style={{fontSize: '12px'}}>{_('help_popover.description')}</p>
            <S.Actions>
                <Button
                    variant="success"
                    size="sm"
                    onClick={() => dispatch(changeShowHelpPopover(false))}
                >
                    {_('button.understand')}
                </Button>
            </S.Actions>
        </div>
    )

    if (!onClick) {
        return <div className="mx-2" style={{ minWidth: eventPreview?.eventData?.use_menu_buttons ? 68 : 54 }} />
    }

    return (
        <HtmlTooltip open={isShowHelpPopover} placement="bottom-end" title={popover} arrow>
            <S.IconWrapper onClick={() => dispatch(changeShowHelpPopover(false))}>
                {eventPreview?.eventData?.use_menu_buttons
                    ? (
                        <CustomButton
                            onClick={onClick}
                            style={{backgroundColor: eventPreview?.eventData?.secondary_brand_color || 'white', color: "black", margin: 0}}
                        >Tutorial
                        </CustomButton>
                    ) : <Icon icon="question" onClick={onClick} />}
            </S.IconWrapper>
        </HtmlTooltip>
    )
}

export default HelpContent
