import React from 'react'
import { Area as AreaType } from '../types'
import env from '../../../constants/env'

const DISABLED = true

/**
 * Displays all coords including their index on the map. Useful for debugging.
 */

const ShowDebugCoords = ({ width, height, areas }: { width: number, height: number, areas: AreaType[] }) => {
    if (!env.debug) {
        return null
    }

    if (DISABLED) {
        return null
    }

    const toPoints = (coords) => {
        const points = []
        for (let i = 0; i < coords.length; i += 2) {
            points.push({ x: coords[i], y: coords[i + 1] })
        }
        return points
    }
    return !areas ? null : (
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
            {
                areas.flatMap(({ coords }, y) => toPoints(coords).map((point, i) => (
                    <div
                        key={i + y * 10}
                        style={{
                            left: `${(point.x / width) * 100}%`,
                            top: `${(point.y / height) * 100}%`,
                            position: 'absolute',
                            background: 'black',
                            color: 'white',
                            padding: 3,
                            borderRadius: 99,
                            width: 5,
                            height: 5,
                            fontSize: 14,
                            zIndex: 99999,
                        }}
                    >
                        {i + 1}
                        {' '}
                        {Math.round(point.x)}
                        /
                        {Math.round(point.y)}
                    </div>
                )))
            }
        </div>
    )
}

export default ShowDebugCoords
