import Booth from './views/Booth'
import { createEventRoute } from '../../utils/routes'
import BoothView from './views/BoothView'

export default {
    booth: createEventRoute({
        title: 'Booth',
        path: '/map/:mapSlug/booth/:boothSlug',
        component: BoothView,
    }),
    boothDetail: createEventRoute({
        title: 'Booth',
        path: '/booths/:boothSlug',
        component: Booth,
    }),
}
