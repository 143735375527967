import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { _ } from '../utils/localization'
import colors from '../constants/colors'

const S = {
    Wrapper: styled.div<{ maxHeight: number; expand: boolean }>`
        position: relative;
        width: 100%;
        max-height: ${({ maxHeight, expand }) => (expand ? 5000 : maxHeight)}px;
        overflow: hidden;
    `,
    ReadMoreOrLessButton: styled.div<{expand: boolean}>`
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        width:100%;
        height: 75px;
        text-align: center;
        color: ${colors.midBlue};
        font-size: 16px;
        padding-top: 52px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.35), white);
        cursor: pointer;

        ${({expand}) => expand && css`
            position: relative;
            background-image: none;
            padding-top: 6px;
            height: 30px;
        `}
    `,
}

interface Props {
    content: string
    isHtmlContent?: boolean
    maxHeight?: number
    className?: string
}

const ReadMoreOrLess = ({
    content,
    isHtmlContent,
    maxHeight = 200,
    className,
}: Props) => {
    const ref = useRef<HTMLDivElement>()
    const [showReadMore, setShowReadMore] = useState(false)
    const [expand, setExpand] = useState(false)

    useEffect(() => {
        if (ref.current) {
            setShowReadMore(ref.current.scrollHeight > ref.current.clientHeight)
        }
    }, [])

    return (
        <S.Wrapper
            maxHeight={maxHeight}
            expand={expand}
            ref={ref}
            className={className}
        >
            {isHtmlContent ? (
                <div
                    className="editor-content"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            ) : (
                content
            )}
            {showReadMore && (
                <S.ReadMoreOrLessButton
                    expand={expand}
                    onClick={() => setExpand(!expand)}
                >
                    {expand ? _('button.read_less') : _('button.read_more')}
                </S.ReadMoreOrLessButton>
            )}
        </S.Wrapper>
    )
}

export default ReadMoreOrLess
