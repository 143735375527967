import colors from '../../constants/colors'
import { AttendeeCallStatus } from './types'

interface StatusValues {
    color: string
    value: AttendeeCallStatus
}

const statuses: Record<AttendeeCallStatus, StatusValues> = {
    unavailable: {
        value: 'unavailable',
        color: colors.gray2,
    },
    available: {
        value: 'available',
        color: colors.green,
    },
    open: {
        value: 'open',
        color: colors.green,
    },
    request: {
        value: 'request',
        color: colors.orange,
    },
    closed: {
        value: 'closed',
        color: colors.red,
    },
}

export const getStatusColor = (status: AttendeeCallStatus) => statuses[status].color

export default statuses
