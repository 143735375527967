import { createEventRoute } from '../../utils/routes'
import Jobs from './Jobs'

export default {
    jobs: createEventRoute({
        title: 'Jobs',
        path: '/jobs/:jobsSlug',
        exact: true,
        component: Jobs,
    }),
}
