import { createAsyncThunk } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import BSModal from 'react-bootstrap/Modal'
import React from 'react'
import { closeAllModal } from '../../modals/modalSlice'
import { collapse } from '../../timetable/redux/expandFooterSlice'
import Time from '../../timetable/components/Time'
import CustomButton from '../../../components/CustomButton'
import { _ } from '../../../utils/localization'
import { S } from '../../timetable/components/ItemDetails'
import { GroupSessionType } from '../groupSessionsSlice'
import useSlug from '../../../hooks/useSlug'
import { getPath } from '../../entervideocall/useRedirectToRoom'

const handleEnterClick = createAsyncThunk(
    'HANDLE_ENTER_CLICK',
    (__, thunkAPI) => {
        thunkAPI.dispatch(closeAllModal())
        thunkAPI.dispatch(collapse())
    },
)

const GroupSessionDetails = ({item}: {item:GroupSessionType}) => {
    const { location } = useHistory()
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const path = getPath(item.videocall.twilio_name, eventSlug, {})

    return (
        <>
            <BSModal.Header closeButton style={{ paddingTop: 0 }}>
                <S.HeaderContent>
                    <div>
                        <S.ModalTitle>{item.videocall.display_name}</S.ModalTitle>
                        <S.SubTitle>
                            <Time start_date={item.starts_at} end_date={item.ends_at} />
                        </S.SubTitle>
                    </div>

                    <S.AlignActions>
                        {!location.pathname.includes(item.videocall.twilio_name)
                        && (
                            <S.Link to={path} onClick={() => dispatch(handleEnterClick())}>
                                <CustomButton variant="success">
                                    {_('item.details_modal.join_button')}
                                </CustomButton>
                            </S.Link>
                        )}
                    </S.AlignActions>
                </S.HeaderContent>
            </BSModal.Header>

            <S.Description content={item.description} isHtmlContent />
        </>
    )
}

export default GroupSessionDetails
