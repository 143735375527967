import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../store'

const expandFooterSlice = createSlice({
    name: 'EXPAND_FOOTER_SLICE',
    initialState: false,
    reducers: {
        expand: () => true,
        collapse: () => false,
    },
})

export const selectExpandFooter = (state: RootState) => state.timetable.expand
export const { expand } = expandFooterSlice.actions
export const { collapse } = expandFooterSlice.actions
export default expandFooterSlice.reducer
