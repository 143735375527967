import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
    Route, useHistory, useParams
} from 'react-router-dom'
import { Carousel } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import fill from 'lodash/fill'
import BoothOverview from '../components/BoothOverview'
import SideBar from '../components/SideBar'
import StyledIcon from '../../../components/Icon'
import colors from '../../../constants/colors'
import { ListBoothSpacesResponse } from '../../../types/swagger'
import usePrevious from '../../../hooks/usePrevious'
import styles from '../../../constants/styles'
import { boothListSelector, limitSelector, setBoothLimit } from '../boothsSlice'
import useSlug from '../../../hooks/useSlug'
import { SwipeDirection } from '../types'
import { eventPreviewSelector } from '../../auth/eventPreviewSlice'
import { showSidebarSelector } from '../boothVististorsSlice'

const S = {
    BoothWrapper: styled.div`
        width: 100%;
        display: flex;
        background: #E5E5E5;
        overflow: hidden;
    `,
    WhiteBar: styled.div`
      width: 100%;
      position: absolute;
      height: 473px;
      background: ${colors.white};
      border-bottom: 1px solid ${colors.gray4};
    `,
    Carousel: styled(Carousel)`
      width: 980px;
      height: 100%;
      & a.carousel-control-prev, & a.carousel-control-next {
        position: absolute;
        width: 36px;
        height: 36px;
        top: 160px;
        z-index: 9;
      };
      & a.carousel-control-prev {
        left: 16px;
      }
      & a.carousel-control-next {
        right: 16px;
      };
      & div.carousel-inner {
        height: 100%;
        overflow: visible;
      }
      @media (max-width: 1360px) {
        width: 100%;
        padding: 0 10px;
      }
    `,
    CarouselItem: styled(Carousel.Item)`
      height: 100%;
      transition: transform .6s ease-in-out!important; //To avoid @media (prefers-reduced-motion: reduce) set transition:none
    `,
    BoothOverViewWrapper: styled.div<{hideSidebar?: boolean}>`
      width: 100%;
      height: 100%;
      margin-right: ${styles.sidebarWidth}px;
      padding-bottom: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
      @media (max-width: 1366px) {
        margin-right: ${styles.smallerSidebarWidth}px;
      }
      ${({hideSidebar}) => hideSidebar && css`
          margin-right: 0 !important;
      `}
    `,
}

const BoothCarousel = ({
    booths, boothIndex, hideNavigations
}: {booths: ListBoothSpacesResponse['results']} & {boothIndex: number, hideNavigations?: boolean}) => {
    const history = useHistory()
    const params = useParams<{boothSlug: string, mapSlug?: string}>()
    const activeBoothSlug = params.boothSlug
    const [carouselBooths, setCarouselBooths] = useState([])
    const [activeIndex, setActiveIndex] = useState(boothIndex)
    const prevCarouselBooths = usePrevious(carouselBooths)
    const limit = useSelector(limitSelector)
    const dispatch = useDispatch()
    const canLoadMore = booths?.length > limit

    useEffect(() => {
        // When still have booths to load more and the index of active booth is equal limit
        // (that means we are at the last item and need to load more)
        // This case is for when we click next button of carrousel (same as logic in Posters)
        if (canLoadMore && activeIndex === limit) {
            dispatch(setBoothLimit(limit + 10))
        }

        // This case is for when we go to booth by clicking on map
        // If the index of active booth > limit, we need to increase limit to activeIndex + 1
        if (activeIndex > limit) {
            dispatch(setBoothLimit(activeIndex + 1))
        }
    }, [activeIndex, canLoadMore, limit, dispatch])

    const fillValueIntoCarouselBooths = (index) => {
        if (index === 0) {
            index = 1
        } else if (index === booths.length - 1) {
            index = booths.length - 2
        }
        // Limit 3 items in carousel. If index=0: [0,1,2], index=length-1: [length-3, length-2, length-1],
        // the other index: [index-1, index, index+1].
        const indexes = [index - 1, index, index + 1]
        // Fill null array has length equal booths.length, and replace 3 items with index in indexes, the other item is null
        // E.g: The carousel booths will be [null, null, item2, item3, item4, null, null, ...]
        // correspond to items in booths if active index is (2 or 3 or 4)
        const limitBooths = fill([...booths], null)
        indexes.forEach((i) => { limitBooths[i] = booths[i] })
        setCarouselBooths(limitBooths)
    }

    useEffect(() => {
        if (booths) {
            let activeBoothIndex = booths.findIndex((e) => e.slug === activeBoothSlug)
            activeBoothIndex = activeBoothIndex === -1 ? 0 : activeBoothIndex
            setActiveIndex(activeBoothIndex)

            // When navigating from Exhibition Map A to Exhibition Map B,
            // the booth list of Exhibition Map A is still available until fetching Exhibition Map B is done.
            // We need to make sure booth list of new map is fetched successfully, and includes activeBoothSlug
            if (booths.map((e) => e.slug).includes(activeBoothSlug)) {
                history.push(history.location.pathname.replace(
                    activeBoothSlug, booths[activeBoothIndex] && booths[activeBoothIndex].slug
                ))
            }

            if (carouselBooths[activeBoothIndex] === booths[activeBoothIndex]
                && carouselBooths.length === booths.length
            ) {
                return
            }

            if (booths.length > 3) {
                fillValueIntoCarouselBooths(activeBoothIndex)
            } else {
                setCarouselBooths(booths)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeBoothSlug, booths, activeIndex, carouselBooths])

    const handleClick = (eventKey: number) => {
        if (booths?.length < 2) {
            return
        }

        setActiveIndex(eventKey)
        const replacePattern = new RegExp(`/booth/${activeBoothSlug}$`)
        history.push(history.location.pathname.replace(
            replacePattern, `/booth/${booths[eventKey]?.slug}`
        ))
    }

    const handleSwipe = (direction: SwipeDirection) => {
        if (direction === SwipeDirection.LEFT) {
            const index = activeIndex === 0 ? carouselBooths.length - 1 : activeIndex - 1
            handleClick(index)
        }
        if (direction === SwipeDirection.RIGHT) {
            const index = activeIndex === (carouselBooths.length - 1) ? 0 : activeIndex + 1
            handleClick(index)
        }
    }

    // Fix issue when clicking next/prev button of Carousel in case search/filter booth have no result
    if (activeBoothSlug === 'undefined') {
        return <div />
    }

    return (
        <S.Carousel
            interval={null}
            nextIcon={hideNavigations ? <div /> : <StyledIcon icon="rightDropCircle" color="black" size={36} />}
            prevIcon={hideNavigations ? <div /> : <StyledIcon icon="leftDropCircle" color="black" size={36} />}
            onSelect={handleClick}
            indicators={false}
            activeIndex={activeIndex > (prevCarouselBooths || []).length - 1 ? 0 : activeIndex}
            touch={false}
        >
            { carouselBooths.map((booth, index) => (booth ? (
                <S.CarouselItem key={index}>
                    <BoothOverview boothSlug={booth.slug} onSwipe={handleSwipe} />
                </S.CarouselItem>
            ) : <S.CarouselItem key={index} />))}
        </S.Carousel>
    )
}

const Booth = () => {
    const booths = useSelector(boothListSelector) || []
    const boothSlug = useSlug('boothSlug')
    const eventPreview = useSelector(eventPreviewSelector)
    const params = useParams<{mapSlug?: string}>()
    const showVisitorSidebar = useSelector(showSidebarSelector)
    const hideSidebar = !params.mapSlug || !eventPreview?.show_booth_sidebar
    let boothIndex = booths.findIndex((e) => e.slug === boothSlug)
    // Must make sure the activeIndex of carousel isn't equal -1, otherwise carousel is broken
    boothIndex = boothIndex === -1 ? 0 : boothIndex

    return (
        <S.BoothWrapper>
            <S.WhiteBar />
            {!!booths.length && (
                <S.BoothOverViewWrapper hideSidebar={showVisitorSidebar ? false : hideSidebar}>
                    {params.mapSlug ? (
                        <Route
                            path="/:eventSlug/map/:mapSlug/booth/:boothSlug"
                            render={(props) => (
                                <BoothCarousel
                                    {...props}
                                    booths={booths}
                                    boothIndex={boothIndex}
                                    hideNavigations={hideSidebar}
                                />
                            )}
                        />
                    ) : (
                        <BoothCarousel
                            booths={booths}
                            boothIndex={boothIndex}
                            hideNavigations={hideSidebar}
                        />
                    )}
                </S.BoothOverViewWrapper>
            )}
            <SideBar isHidden={hideSidebar} />
        </S.BoothWrapper>
    )
}

export default React.memo(Booth)
