import React from 'react'
import { map } from 'lodash'
import styled from 'styled-components'
import { AppointmentSchedule } from '../../../types/swagger'
import { _ } from '../../../utils/localization'
import AppointmentRowInList from '../components/AppointmentRowInList'
import { groupAppointmentsByDate } from '../utils'
import colors from '../../../constants/colors'
import useFetchAppointmentList from '../hooks/useFetchAppointmentList'
import LoadMore from '../../../components/LoadMore'
import FullPageLoading from '../../../components/FullPageLoading'

const S = {
    Wrapper: styled.div`
        width: 100%;
        min-height: 400px;
        height: calc(var(--window-height) - 220px);
        overflow: auto;
        padding: 0 16px;
    `,
    Header: styled.div`
        margin-top: 24px;
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: 500;
        color: ${colors.darkTitle};
    `
}

const List = ({filter}: {filter: 'upcoming' | 'past'}) => {
    const {
        isLoading, data, size, setSize, isLoadingMore, isReachingEnd, isRefreshing, refresh
    } = useFetchAppointmentList(filter)

    if (isLoading) {
        return (
            <FullPageLoading isAbsolute />
        )
    }

    const groupedData = groupAppointmentsByDate(data)

    return (
        <>
            {(isLoadingMore || isRefreshing) && <FullPageLoading isAbsolute />}
            {data?.length > 0 && (
                <>
                    <S.Header>{filter === 'upcoming' ? _('my_appointments.upcoming') : _('my_appointments.past')}</S.Header>
                    {map(
                        groupedData,
                        (value: AppointmentSchedule[], key) => (
                            <AppointmentRowInList
                                rowData={value}
                                title={key}
                                key={key}
                                defaultExpanded={Object.keys(groupedData)[0] === key}
                                refresh={refresh}
                            />
                        )
                    )}
                    {!isReachingEnd && (
                        <LoadMore onClick={() => setSize(size + 1)} style={{ marginBottom: 10, border: 'none' }}>
                            {_('button.load_more')}
                        </LoadMore>
                    )}
                </>
            )}
        </>
    )
}

const AppointmentList = () => (
    <S.Wrapper>
        <List filter="upcoming" />
        <List filter="past" />
    </S.Wrapper>
)

export default AppointmentList
