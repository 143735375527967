import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import ClosedSpaceImage from '../assets/closed-space.png'
import colors from '../constants/colors'
import useHomeLink from '../hooks/useHomeLink'
import { _ } from '../utils/localization'
import CustomButton from './CustomButton'

const S = {
    Wrapper: styled.div`
        position: fixed;
        width: 100vw;
        top: 58px;
        left: 0;
        bottom: 0;
        padding: 32px;
        padding-top: 60px;
        z-index: 11;
        background-color: white;
    `,
    ImageWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 352px;
        height: 352px;
        margin: auto;
        border-radius: 50%;
        background-color: #F9F9F9;
    `,
    Image: styled.img`
        width: 232px;
        height: 166px;
        object-fit: contain;
    `,
    Header: styled.h3`
        font-weight: 500;
        font-size: 36px;
        color: ${colors.darkTitle};
        opacity: 0.75;
        margin: 32px 0 20px 0;
        text-align: center;
    `,
    Description: styled.p`
        max-width: 1000px;
        margin: auto;
        font-weight: 300;
        font-size: 14px;
        color: ${colors.darkTitle};
        opacity: 0.5;
        text-align: center;
    `
}

const ClosedSpace = () => {
    const homeLink = useHomeLink()
    const history = useHistory()

    return (
        <S.Wrapper>
            <S.ImageWrapper>
                <S.Image src={ClosedSpaceImage} />
            </S.ImageWrapper>
            <S.Header>
                {_('closed_space.header')}
            </S.Header>
            <S.Description>
                {_('closed_space.title')}
            </S.Description>
            <p className="mt-3 text-center">
                <CustomButton variant="secondary" onClick={() => history.push(homeLink)}>
                    {_('button.got_it')}
                </CustomButton>
            </p>
        </S.Wrapper>
    )
}

export default ClosedSpace
