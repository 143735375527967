import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'

const padgeLoadingSlice = createSlice({
    name: 'PageLoader',
    initialState: {
        isLoading: false
    },
    reducers: {
        setIsPageLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload
        }
    }
})

export const isPageLoadingSelector = (state: RootState) => state.pageLoading.isLoading
export const { setIsPageLoading } = padgeLoadingSlice.actions

export default padgeLoadingSlice.reducer
