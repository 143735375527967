import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { minutesToTime } from '../../../utils/datetime'
import config from '../config'
import colors from '../../../constants/colors'
import NowIndicator from './NowIndicator'

const S = {
    GridWrapper: styled.div<{type?: TimeGridType, tableHeight?: number}>`
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        flex-grow: 1;
        width: 100%;
        height: ${({tableHeight}) => tableHeight + 40}px;
        position: absolute;
        top: 0;
        z-index: 1;
        ${({type}) => type === 'timeLine' && `
            position: sticky;
            background-color: ${config.timelineNavColor};
            height: ${config.timelineNavHeight}px;
            z-index: 99;
        `}
    `,
    TimeColumn: styled.div`
        height: 100%;
        flex-grow: 1;
    `,
    VerticalGridLine: styled.div`
        height: 100%;
        flex-grow: 1;
        border-left: 1px solid ${colors.gray3};
        position: absolute;
    `,
    TimeNavigation: styled.div`
        height: ${config.timelineNavHeight}px;
    `,
    TextCenter: styled.div`
        height: ${config.timelineNavHeight}px;
        // make sure all texts are the same width to it doesnt affect width of parent
        width: 16px;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        color: white;
        margin-left: -14px;
        align-items: center;
    `,
}

type TimeGridType = 'timeLine' | 'verticalLine'

interface TimeColumnProps {
    time: string
    type?: TimeGridType
}

const TimeColumn = ({ time, type }: TimeColumnProps) => (
    <S.TimeColumn>
        {type === 'timeLine' && (
            <S.TimeNavigation>
                <S.TextCenter>
                    {time}
                </S.TextCenter>
            </S.TimeNavigation>
        )}

        {type === 'verticalLine' && (
            <S.VerticalGridLine />
        )}
    </S.TimeColumn>
)

interface TimeGridProps {
    tableHeight?: number
    type?: TimeGridType
}

const TimeGrid = ({ tableHeight, type }: TimeGridProps) => {
    const { columns } = useSelector(
        ({ timetable }: RootState) => timetable.zoomLevel,
    )
    const minutesPerColumn = (24 * 60) / columns
    const getTime = (index: number): string => {
        const EXCLUDE = ['00:00', '24:00']
        const time = minutesToTime(minutesPerColumn * index)
        return EXCLUDE.includes(time) ? '' : time
    }
    return (
        <S.GridWrapper type={type} tableHeight={tableHeight}>
            {type === 'timeLine' && <NowIndicator tableHeight={tableHeight} />}
            { Array(columns).fill(null).map((_, i) => (
                <TimeColumn key={i} time={getTime(i)} type={type} />
            )) }
        </S.GridWrapper>
    )
}

export default TimeGrid
