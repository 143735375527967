import Logger from 'js-logger'
import {
    clearAuthTokens,
    getAccessToken,
    setAuthTokens,
    useAuthTokenInterceptor,
    refreshTokenIfNeeded,
} from './jwt'
import api, { axios, getPlainAxios } from './api'
import { CreateObtainAuthTokenRequest } from '../types/swagger'
import { ignoreError } from './errorTracker'

// Swagger docs are incorrect so type manually
export interface Jwt {
    readonly refresh: string
    readonly access: string
    readonly token: string
}

// Swagger docs are incorrect so type manually
export interface LoginInput {
    username: string
    password: string
}

const storeTokens = (jwt: Jwt) => setAuthTokens({
    accessToken: jwt.access,
    refreshToken: jwt.refresh,
})

const requestRefresh = async (refresh: string): Promise<string> => {
    // This request should not be send with our axios client that has
    // the token interceptor enabled.
    const jwt = await getPlainAxios().post<Jwt>('/auth/jwt/refresh/', { refresh })
    return jwt.data.access
}

// eslint-disable-next-line react-hooks/rules-of-hooks
useAuthTokenInterceptor(axios, { requestRefresh })

const loginUsername = async (params: LoginInput) => {
    const response = await api.safePost<Jwt>('/auth/jwt/login/', params)
    if (response.error) { return response }
    Logger.debug('JWT is set')
    storeTokens(response.data)
    return response
}

const enterEvent = async (params: CreateObtainAuthTokenRequest) => {
    const response = await api.safePost<Jwt>('/auth/obtain/', params)
    if (response.error) { return response }
    storeTokens(response.data)
    return response
}

type LoginInputUnion = LoginInput | CreateObtainAuthTokenRequest;

function isLoginInput(input: LoginInputUnion): input is LoginInput {
    return (input as LoginInput).password !== undefined
}

export default {
    getAccessToken,
    storeTokens,
    refreshToken: () => ignoreError(() => refreshTokenIfNeeded(requestRefresh)),
    login: async (input: LoginInputUnion) => {
        if (isLoginInput(input)) {
            return loginUsername(input)
        }
        return enterEvent(input)
    },
    logout: clearAuthTokens,
}
