import { createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import api from '../../../utils/api'
import { RetrieveProgramDetailResponse } from '../../../types/swagger'
import createRequestMapSlice, { RequestMapAction } from '../../../utils/createRequestMapSlice'
import { RootState } from '../../../store'

type ID = number
type Resp = RetrieveProgramDetailResponse
type Args = {eventSlug: string, itemId: string | number}

export const getProgramItem = createAsyncThunk<RequestMapAction<Resp, ID>, Args>(
    'FETCH_PROGRAM_DETAIL',
    async ({ eventSlug, itemId }) => {
        const response: Resp = await api.get(`/events/${eventSlug}/virtualprogram/${itemId}/`)
        return {
            identifier: response.id,
            data: response,
        }
    },
)

const programSlice = createRequestMapSlice<RetrieveProgramDetailResponse, ID>(getProgramItem, 'programSlice')

export const programSelector = (id) => createSelector(
    [(state: RootState) => state.timetable.programmes],
    (programmes) => programmes[id] || { data: null, status: 'idle' },
)

export default programSlice.reducer
