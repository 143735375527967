import { useMatomo } from '@datapunt/matomo-tracker-react'
import React from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import Popover from 'react-bootstrap/cjs/Popover'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import GeneralInfoWithThumbnail from '../../../../components/GeneralInfoWithThumbnail'
import Icon from '../../../../components/Icon'
import colors from '../../../../constants/colors'
import useSlug from '../../../../hooks/useSlug'
import { VirtualAttendee } from '../../../../types/swagger'
import openChatModal from '../../../chat/openChatModal'
import CallStatus from '../../../entervideocall/CallStatus'
import getAttendeeStatus from '../../../attendees/getAttendeeStatus'
import { getStatusColor } from '../../../attendees/statuses'
import useAttendeeCallClick from '../../../entervideocall/useAttendeeCallClick'
import AppointmentScheduleModal from '../../../appointment_schedules/AppointmentScheduleModal'
import { addModal } from '../../../modals/modalSlice'
import { eventPreviewSelector } from '../../../auth/eventPreviewSlice'
import { formatJobInfo } from '../../../../utils/profile'
import { currentUserSelector } from '../../../auth/authSlice'
import constants from '../../../../constants/constants'
import { Booth } from "../../types"

const S = {
    Wrapper: styled.div`
        width: 70px;
        margin: 8px 0px;
        cursor: pointer;
    `,
    Thumbnail: styled.div<{imageUrl: string}>`
        position: relative;  
        width: 60px;
        height: 60px;
        margin: auto;
        border-radius: 50%;
        background: url(${({ imageUrl }) => imageUrl});
        background-size: cover;
        background-position: center;
    `,
    VideocallStatus: styled.div<{color: string}>`
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${({ color }) => (color === colors.gray2 ? 'gray' : color)};
        & span {
            position: absolute;
            top: -4.5px;
            left: 2px;
        }
    `,
    Name: styled.p`
        width: 100%;
        margin: 0;
        margin-top: 5px;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    Popover: styled(Popover)`
        max-width: 500px;
        min-width: 300px;
        padding: 10px;
    `
}

interface Props {
    staff: VirtualAttendee
    boothDetail: Booth
}

const StaffAvatar = ({ boothDetail, staff }: Props) => {
    const eventSlug = useSlug('eventSlug')
    const eventPreview = useSelector(eventPreviewSelector)
    const currentUser = useSelector(currentUserSelector())
    const dispatch = useDispatch()
    const { trackEvent } = useMatomo()
    const trackAction = (action, name) => {
        trackEvent({
            category: boothDetail.name,
            action,
            name,
        })
    }
    const videocallStatus = getAttendeeStatus(staff)

    const showAppointmentSchedule = () => dispatch(addModal({
        Component: ({close}: {close: () => void}) => (
            <AppointmentScheduleModal close={close} receiver={staff} />
        )
    }))

    const handleAttendeeCall = useAttendeeCallClick(staff as VirtualAttendee, { adminId: staff.id })

    const popover = (
        <S.Popover id={`popover-${staff.id}`}>
            <GeneralInfoWithThumbnail
                name={`${staff.first_name} ${staff.last_name}`}
                description={formatJobInfo(staff)}
                image={staff.mugshot}
            >
                <div onClick={() => document.body.click()} className="flex-align-center">
                    {eventPreview?.enable_meeting_scheduling && (
                        <Icon icon="appointmentSchedule" onClick={showAppointmentSchedule} size={20} className="mr-2" />
                    )}
                    {boothDetail?.enable_chat && (
                        <Icon
                            icon="forum"
                            size={20}
                            className="mr-2"
                            onClick={() => {
                                dispatch(openChatModal(eventSlug, staff.id))
                                trackAction('staff message sent', staff.id)
                            }}
                        />
                    )}

                    {boothDetail?.enable_videocall && (
                        <CallStatus
                            status={videocallStatus}
                            amountOfUsers={staff?.videocall_status?.amount_of_users}
                            onClick={handleAttendeeCall}
                            hide={staff.id === currentUser.id}
                        />
                    )}
                </div>
            </GeneralInfoWithThumbnail>
        </S.Popover>
    )
    return (
        <OverlayTrigger trigger="click" placement="top" overlay={staff.id !== currentUser.id ? popover : <div />} rootClose transition>
            <S.Wrapper>
                <S.Thumbnail imageUrl={staff.mugshot || constants.urlThumbnailDefault}>
                    {boothDetail?.enable_videocall && (
                        <S.VideocallStatus color={getStatusColor(videocallStatus)}>
                            <Icon icon="video" size={16} color="white" />
                        </S.VideocallStatus>
                    )}
                </S.Thumbnail>
                <S.Name className="notranslate">{`${staff.first_name} ${staff.last_name}`}</S.Name>
            </S.Wrapper>
        </OverlayTrigger>
    )
}

export default StaffAvatar
