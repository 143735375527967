import React, { useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import StyledIcon from '../../components/Icon'
import colors from '../../constants/colors'
import { RootState } from '../../store'
import { User } from '../auth/types'
import { saveProfileBadge, uploadProfileDocument } from './editProfileSlice'
import { _ } from '../../utils/localization'
import { showErrorNotification, showNotification } from '../notifications/notificationsSlice'
import InputField from '../../components/formfields/InputField'
import VisualIcon from '../../components/formfields/VisualIcon'
import Divider from '../../components/Divider'
import Switch from '../../components/Switch'
import useSlug from '../../hooks/useSlug'

type Social = 'linkedIn' | 'facebook' | 'twitter' | 'instagram';

const S = {
    FormContent: styled.div`
        max-width: 100%;
    `,
    FormInput: styled(Form.Control)`
        margin-bottom: 10px;
        resize: none;
        &::placeholder {
            font-style: italic;
            color: ${colors.gray5};
        }
    `,
    Button: styled(Button)`
        display: flex;
        align-items: center;
    `,
    Grid: styled.div`
        display: flex;
        margin-bottom: 20px;
        gap: 20px;
        & > div {
            flex: 1;
        }
        @media (max-width: 932px) {
            flex-direction: column;
        }
    `,
    FormHeader: styled.h3`
        font-weight: 700;
        font-size: 12px;
        color: ${colors.grayDescription};
        text-transform: uppercase;
    `,
    About: styled(Form.Control)`
        padding: 14px 16px;
        margin-bottom: 20px;
        background: ${colors.light};
        border: none;
        border-radius: 4px;
        resize: none;
        &::placeholder {
            opacity: 0.5;
        }
    `,
    Actions: styled.div`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    `,
    DocumentHeader: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 932px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 10px;
        }
    `
}

const checkEmptyString = (inputString = '') => !inputString.trim()
// eslint-disable-next-line
const urlPattern = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
const checkValidSocials = (link) => {
    if (link) {
        return urlPattern.test(link)
    }
    return true
}

export const getSocialIconColor = (social: 'linkedin' | 'facebook' | 'twitter' | 'instagram') => ({
    linkedin: '#0A66C2',
    facebook: '#3B5998',
    twitter: '#00ACEE',
    instagram: '#FF5176',
})[social]

const EditProfileForm = ({ profile, close }: { profile: Partial<User>, close: () => void }) => {
    const dispatch = useDispatch()
    const editProfileStatus = useSelector((state: RootState) => state.editProfile.editProfileStatus)
    const isPreventHideProfile = useSelector((state: RootState) => state.editProfile.preventHideProfile)
    const eventSlug = useSlug('eventSlug')

    const [firstName, setFirstName] = useState(profile.first_name)
    const [lastName, setLastName] = useState(profile.last_name)
    const [company, setCompany] = useState(profile.company)
    const [jobTitle, setJobTitle] = useState(profile.jobtitle)
    const [about, setAbout] = useState(profile.about)
    const [incognitoMode, setIncognitoMode] = useState(profile.incognito_mode)
    const [alias, setAlias] = useState(profile.alias_name)
    const [hideDocument, setHideDocument] = useState(profile.hide_document)
    const [documentName, setDocumentName] = useState(profile.document_name)
    const [hiddenFields, setHiddenFields] = useState(profile.hidden_profile_fields || [])
    const [socialData, setSocialData] = useState<Record<Social, string>>({
        facebook: profile.facebook,
        linkedIn: profile.linkedin,
        twitter: profile.twitter,
        instagram: profile.instagram,
    })

    const handleSubmitForm = () => {
        if (checkEmptyString(firstName) || checkEmptyString(lastName)) {
            dispatch(showErrorNotification(_('profile.firstname_lastname_required')))
            return
        }
        if (!checkValidSocials(socialData.linkedIn)
            || !checkValidSocials(socialData.facebook)
            || !checkValidSocials(socialData.twitter)
            || !checkValidSocials(socialData.instagram)
        ) {
            dispatch(showErrorNotification(_('profile.invalid_social_link')))
            return
        }
        const newProfile = {
            ...profile,
            eventSlug,
            id: profile.id,
            first_name: firstName,
            last_name: lastName,
            company,
            jobtitle: jobTitle,
            about,
            linkedin: socialData.linkedIn,
            facebook: socialData.facebook,
            twitter: socialData.twitter,
            instagram: socialData.instagram,
            hidden_profile_fields: hiddenFields,
            hide_document: hideDocument,
            document_name: documentName,
            incognito_mode: incognitoMode,
            alias_name: alias,
        }
        // If don't change profile, but in case the account is new
        // and needs to be activated, still call API to save info and update isPreventHideProfile to false
        if (isEqual(profile, newProfile) && !isPreventHideProfile) {
            close()
            return
        }
        dispatch(saveProfileBadge(newProfile))
        dispatch(showNotification({
            type: 'success',
            body: _('profile.edit_profile.success'),
            displayDuration: 3000,
        }))
        close()
    }

    const updateHiddenFields = (field: string) => {
        if (hiddenFields.includes(field)) {
            setHiddenFields([...hiddenFields.filter((e) => e !== field)])
        } else {
            setHiddenFields([...hiddenFields, field])
        }
    }

    return (
        <S.FormContent>
            <S.Grid>
                <InputField
                    value={firstName}
                    onChange={setFirstName}
                    placeholder={_('profile.first_name').concat('*')}
                    disabled={incognitoMode}
                    end={<VisualIcon show={!incognitoMode} disabled />}
                />

                <InputField
                    value={lastName}
                    onChange={setLastName}
                    placeholder={_('profile.last_name').concat('*')}
                    disabled={incognitoMode}
                    end={<VisualIcon show={!incognitoMode} disabled />}
                />
            </S.Grid>

            <S.Grid>
                <InputField
                    value={company}
                    onChange={setCompany}
                    placeholder={_('profile.company_name')}
                    end={<VisualIcon show={!hiddenFields.includes('company')} onChange={() => updateHiddenFields('company')} />}
                />

                <InputField
                    value={jobTitle}
                    onChange={setJobTitle}
                    placeholder={_('profile.job_title')}
                    end={<VisualIcon show={!hiddenFields.includes('jobtitle')} onChange={() => updateHiddenFields('jobtitle')} />}
                />
            </S.Grid>

            <Divider marginBottom={20} />

            <S.FormHeader>
                {_('profile.incognito_mode_settings_header')}
            </S.FormHeader>

            <S.Grid>
                <InputField
                    value={alias}
                    onChange={setAlias}
                    placeholder={_('profile.alias_name')}
                />
                <InputField
                    value={_('profile.use_profile_in_incognito_mode')}
                    onChange={() => null}
                    end={(
                        <Switch
                            checked={incognitoMode}
                            onChange={() => setIncognitoMode(!incognitoMode)}
                        />
                    )}
                />
            </S.Grid>

            <Divider marginBottom={20} />

            <S.About
                as="textarea"
                rows={3}
                placeholder={_('profile.about')}
                className="form-control form-control-sm mb-3"
                value={about}
                onChange={(e) => setAbout(e.target.value)}
            />

            <Divider marginBottom={20} />

            <S.FormHeader>
                {_('profile.social_header')}
            </S.FormHeader>

            <S.Grid>
                <InputField
                    value={socialData.linkedIn}
                    onChange={(value) => setSocialData({ ...socialData, linkedIn: value })}
                    placeholder="URL"
                    start={<StyledIcon icon="linkedIn" color={getSocialIconColor('linkedin')} className="mr-2" />}
                    end={<VisualIcon show={!hiddenFields.includes('linkedin')} onChange={() => updateHiddenFields('linkedin')} />}
                />
                <InputField
                    value={socialData.twitter}
                    onChange={(value) => setSocialData({ ...socialData, twitter: value })}
                    placeholder="URL"
                    start={<StyledIcon icon="twitter" color={getSocialIconColor('twitter')} className="mr-2" />}
                    end={<VisualIcon show={!hiddenFields.includes('twitter')} onChange={() => updateHiddenFields('twitter')} />}
                />
            </S.Grid>
            <S.Grid>
                <InputField
                    value={socialData.facebook}
                    onChange={(value) => setSocialData({ ...socialData, facebook: value })}
                    placeholder="URL"
                    start={<StyledIcon icon="facebook" color={getSocialIconColor('facebook')} className="mr-2" />}
                    end={<VisualIcon show={!hiddenFields.includes('facebook')} onChange={() => updateHiddenFields('facebook')} />}
                />
                <InputField
                    value={socialData.instagram}
                    onChange={(value) => setSocialData({ ...socialData, instagram: value })}
                    placeholder="URL"
                    start={(
                        <StyledIcon
                            icon="instagram"
                            color={getSocialIconColor('instagram')}
                            className="mr-2"
                        />
                    )}
                    end={<VisualIcon show={!hiddenFields.includes('instagram')} onChange={() => updateHiddenFields('instagram')} />}
                />
            </S.Grid>

            <Divider marginBottom={20} />

            <S.DocumentHeader>
                <S.FormHeader>
                    {_('profile.upload_document_header')}
                </S.FormHeader>
                <div className="flex-align-center" style={{fontSize: '12px', gap: 8}}>
                    {_('profile.hide_document')}
                    <Switch checked={hideDocument} onChange={() => setHideDocument(!hideDocument)} />
                </div>

            </S.DocumentHeader>

            <Typography variant="text" marginBottom={1} display="block">
                {_('profile.upload_document_description')}
            </Typography>

            <S.Grid>
                <InputField
                    value={profile.document}
                    onChange={(formData) => dispatch(uploadProfileDocument({
                        id: profile.id, body: formData, eventSlug
                    }))}
                    placeholder="URL"
                    end={<StyledIcon icon="upload" />}
                    type="file"
                    accept="application/pdf"
                />
                <InputField
                    value={documentName}
                    onChange={setDocumentName}
                    placeholder={_('placeholder.document_name')}
                />
            </S.Grid>

            <Divider marginBottom={20} />

            <S.Actions>
                <S.Button variant="primary" onClick={handleSubmitForm}>
                    {_('profile.save_badge')}
                    {
                        editProfileStatus === 'loading'
                            ? <Spinner animation="border" as="span" size="sm" style={{ color: 'white', marginLeft: 5 }} />
                            : <StyledIcon icon="check" color="white" style={{ margin: '0 3px 3px 5px' }} size={20} />
                    }
                </S.Button>
            </S.Actions>
        </S.FormContent>
    )
}

export default EditProfileForm
