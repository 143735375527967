import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { RequestStatus } from '../../types'
import api from '../../utils/api'
import { EventSearchResponse } from './types'

export const searchEntireEvent = createAsyncThunk(
    'SEARCH_ENTIRE_EVENT',
    ({ eventSlug, search }: { eventSlug: string, search: string }) => {
        const url = `events/${eventSlug}/search/?search=${search}`
        return api.get<EventSearchResponse>(url)
    },
)

const entireEventSeachSlice = createSlice({
    name: 'EntireEventSeach',
    initialState: {
        search: '',
        data: [] as any[],
        requestStatus: 'idle' as RequestStatus,
    },
    reducers: {
        updateSearch: (state, { payload }) => {
            state.search = payload
        },
        clearData: (state) => {
            state.data = []
        }
    },
    extraReducers(builder) {
        builder.addCase(searchEntireEvent.fulfilled, (state, { payload }) => {
            state.data = payload.categories
            state.requestStatus = 'succeeded'
        })

        builder.addCase(searchEntireEvent.pending, (state) => {
            state.requestStatus = 'loading'
        })

        builder.addCase(searchEntireEvent.rejected, (state) => {
            state.requestStatus = 'failed'
            state.data = []
        })
    },
})

export const searchSelector = (state: RootState) => state.eventSearch.search
export const entireEventSearchDataSelector = (state: RootState) => ({
    data: state.eventSearch.data,
    requestStatus: state.eventSearch.requestStatus,
})
export const { updateSearch, clearData } = entireEventSeachSlice.actions

export default entireEventSeachSlice.reducer
