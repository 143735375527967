import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { Button } from 'react-bootstrap'
import { MeetingItem } from '../types'
import { currentUserSelector } from '../../auth/authSlice'
import { addModal } from '../../modals/modalSlice'
import JoinConfirmModal from './JoinConfirmModal'
import RegisterModal from './RegisterModal'
import { _ } from '../../../utils/localization'
import UnregisterModal from './UnregisterModal'
import { isUpcoming } from '../utils'
import useSlug from '../../../hooks/useSlug'
import useInterval from '../../../hooks/useInterval'
import useRedirectToMeetingRoom from '../useRedirectToMeetingRoom'

const getTranslations = () => ({
    joinButton: {
        infoModal: _('meetingspace.info_modal.join_button'),
        card: _('meetingspace.card.join_button'),
        sidebar: _('meetingspace.sidebar.join_button')
    },
    registerButton: {
        infoModal: _('meetingspace.info_modal.register_button'),
        card: _('meetingspace.card.register_button'),
        sidebar: _('meetingspace.sidebar.register_button')
    },
    unregisterButton: {
        infoModal: _('meetingspace.info_modal.unregister_button'),
        card: _('meetingspace.card.unregister_button'),
        sidebar: _('meetingspace.sidebar.unregister_button')
    },
    hostEnterMeetingButton: {
        infoModal: _('meetingspace.info_modal.host_enter_meeting_button'),
        card: _('meetingspace.card.host_enter_meeting_button'),
        sidebar: _('meetingspace.sidebar.host_enter_meeting_button')
    },
})

type Location = 'infoModal' | 'card' | 'sidebar'

interface JoinMeetingButtonProps {
    meeting: MeetingItem
    isInList?: boolean
    location: Location
}

const JoinMeetingButton = ({
    meeting, isInList, location
}: JoinMeetingButtonProps) => {
    const meetingSlug = useSlug('meetingSlug') || meeting?.space?.slug
    const redirect = useRedirectToMeetingRoom(meeting, meetingSlug)
    const currentUser = useSelector(currentUserSelector())
    const dispatch = useDispatch()
    const isHost = meeting?.host_user_ids?.includes(currentUser.id)
    const isInvited = meeting?.participating_users?.includes(currentUser.id)
    const dateTime = useInterval(1000)

    const showJoinConfirmModal = () => (
        dispatch(addModal({
            Component: ({ close }: {close: () => void}) => (
                <JoinConfirmModal meeting={meeting} close={close} />
            ),
            size: 'sm',
            padding: 0
        }))
    )

    const showRegisterModal = () => {
        dispatch(addModal({
            Component: ({ close }: {close: () => void}) => <RegisterModal meeting={meeting} close={close} />,
            header: _('meetingspace.register_modal.header')
        }))
    }

    const showUnregisterModal = () => {
        dispatch(addModal({
            Component: ({ close }: {close: () => void}) => (
                <UnregisterModal
                    meeting={meeting}
                    close={close}
                />
            ),
            header: _('meetingspace.unregister_modal.header')
        }))
    }

    if (isHost) {
        return (
            <Button
                onClick={redirect}
                size={isInList ? 'sm' : null}
                block={isInList}
            >
                {getTranslations().hostEnterMeetingButton[location]}
            </Button>
        )
    }

    if (isUpcoming(meeting, dateTime) && isInvited) {
        return (
            <Button
                variant="secondary"
                onClick={showUnregisterModal}
                size={isInList ? 'sm' : null}
                block={isInList}
            >
                {getTranslations().unregisterButton[location]}
            </Button>
        )
    }

    if (isUpcoming(meeting, dateTime) && meeting.is_walk_in_meeting) {
        return (
            <Button
                variant="success"
                disabled
                size={isInList ? 'sm' : null}
                block={isInList}
            >
                {getTranslations().joinButton[location]}
            </Button>
        )
    }

    if (isUpcoming(meeting, dateTime)) {
        return (
            <Button
                variant="success"
                onClick={showRegisterModal}
                disabled={meeting.capacity_left === 0}
                size={isInList ? 'sm' : null}
                block={isInList}
            >
                {getTranslations().registerButton[location]}
            </Button>
        )
    }

    return (
        <Button
            variant="success"
            onClick={showJoinConfirmModal}
            size={isInList ? 'sm' : null}
            block={isInList}
            disabled={meeting.capacity_left === 0 && !isInvited}
        >
            {getTranslations().joinButton[location]}
        </Button>
    )
}

export default JoinMeetingButton
