import EventOverview from './EventOverview'
import { createRoute } from '../../utils/routes'

export default {
    eventOverview: createRoute({
        title: 'Event Overview',
        path: '/',
        isPublic: true,
        component: EventOverview,
    }),
}
