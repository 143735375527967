import {createAsyncThunk} from '@reduxjs/toolkit'
import {push} from 'connected-react-router'
import auth from '../../utils/auth'

const logout = createAsyncThunk(
    'LOGOUT_USER',
    async ({ eventSlug }: {eventSlug?: string}, thunkAPI) => {
        auth.logout()
        // Cant use routes.enterEvent.getPath here because of circular import issue
        thunkAPI.dispatch(push(`/${eventSlug}/enterevent/`))
    },
)

export default logout
