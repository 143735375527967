import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import colors from '../../../constants/colors'
import Icon from '../../../components/Icon/Icon'
import { _ } from '../../../utils/localization'
import {
    toLocalDateFormat, toLocalTimeFormat
} from '../../../utils/datetime'
import { isLive } from '../utils'
import { MeetingItem } from '../types'
import useInterval from '../../../hooks/useInterval'
import { eventTimeFormatSelector } from '../../auth/eventPreviewSlice'

const S = {
    CapacityAndDateTime: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 6px;
    `,
    IconWrapper: styled.div<{darkBackground?: boolean, showIndicator?: boolean}>`
      position: relative;
      display: flex;
      align-items: center;
      color: ${colors.regentGray};
      font-size: 14px;
      user-select: none;

      ${({ darkBackground }) => darkBackground && css`
        background-color: #42454A;
        color: white;
        font-weight: 700;
        border-radius: 4px;
        padding: 1px 6px;
      `}

      ${({ showIndicator }) => showIndicator && css`
        &::after {
          content: '';
          position: absolute;
          top: 2px;
          right: -6px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #FE6C3F;
        }
      `}
    `,
    CapacityIndicator: styled.span`
      font-weight: 700; 
    `
}

type CapacityAndDateTimeProps = {meeting: MeetingItem, darkCapacityBackground?: boolean, showDate?: boolean}

const CapacityAndDateTime = ({ meeting, darkCapacityBackground, showDate }: CapacityAndDateTimeProps) => {
    const dateTime = useInterval(1000)
    const currentAndMaxCapacity = `${meeting.capacity - meeting.capacity_left}/${meeting.capacity}`
    const getCapacityIndicator = () => {
        if (darkCapacityBackground) {
            return currentAndMaxCapacity
        }
        return meeting.capacity_left > 0 ? currentAndMaxCapacity : _('meetingspace.full')
    }
    const timeFormat = useSelector(eventTimeFormatSelector)

    return (
        <S.CapacityAndDateTime>
            <S.IconWrapper darkBackground={darkCapacityBackground}>
                <Icon
                    icon="group"
                    size={16}
                    style={{ margin: '0 5px 3px 0' }}
                    color={darkCapacityBackground ? 'white' : colors.regentGray}
                />
                <S.CapacityIndicator>{getCapacityIndicator()}</S.CapacityIndicator>
            </S.IconWrapper>

            {showDate && (
                <S.IconWrapper>
                    <Icon icon="calendarRange" size={16} style={{ margin: '0 5px 3px 0' }} />
                    {toLocalDateFormat(meeting.starts_at)}
                </S.IconWrapper>
            )}

            <S.IconWrapper showIndicator={isLive(meeting, dateTime)}>
                <Icon icon="clock" size={16} style={{ margin: '0 5px 3px 0' }} />
                {`${toLocalTimeFormat(meeting.starts_at, timeFormat)} - ${toLocalTimeFormat(meeting.ends_at, timeFormat)}`}
            </S.IconWrapper>
        </S.CapacityAndDateTime>
    )
}

export default CapacityAndDateTime
