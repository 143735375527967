import {
    DateTime, getMinutes, getStartOfDay, getStartOfToday, toDate,
} from '../../utils/datetime'

export interface TimeTableItem {
    startsAt?: DateTime
    endsAt?: DateTime
    color?: string
}

const minutesPerDay = 60 * 24
const getPercentage = (minutes) => `${(minutes / minutesPerDay) * 100}%`

export const getItemSpacingLeft = (item: TimeTableItem, selectedDate: DateTime) => {
    const start = getMinutes(toDate(item.startsAt))
    const startOfSelectedDay = getMinutes(getStartOfDay(selectedDate))
    const minutes = start - startOfSelectedDay
    return getPercentage(minutes)
}

export const getItemWidth = (item: TimeTableItem) => {
    const start = getMinutes(toDate(item.startsAt))
    const end = getMinutes(toDate(item.endsAt))
    return getPercentage(end - start)
}

export const getSpacingLeft = (dateTime: DateTime) => {
    const start = getMinutes(dateTime)
    const startOfToday = getMinutes(getStartOfToday())
    const minutes = (start - startOfToday) % minutesPerDay
    return getPercentage(minutes)
}
