import React from 'react'
import { Modal } from 'react-bootstrap'

import styled from 'styled-components'
import CustomButton from '../../components/CustomButton'
import colors from '../../constants/colors'
import useCountDown from '../../hooks/useCountDown'
import { _ } from '../../utils/localization'

const S = {
    Header: styled.div`
        display: flex;
        align-items: flex-start;
        margin-right: 20px;
        margin-bottom: 20px;
        & + button {
            margin-top: -6px!important;
            font-size: 37px;
            font-weight: 400;
            &:focus {
                outline: none;
            }
        }
    `,
    Title: styled.h3`
        font-size: 30px;
        font-weight: 500;
        color: ${colors.darkTitle};
        margin-bottom: 10px;
    `,
    Actions: styled.div`
        display: flex;
        justify-content: flex-end;
        margin-top: 32px;
    `
}

const CloseSpaceWarningModal = ({ close, datetime }: {close: () => void, datetime: Date}) => {
    const { secondsLeft } = useCountDown(datetime)
    if (secondsLeft === 0) {
        close()
    }

    return (
        <div>
            <Modal.Header closeButton style={{ padding: 0, border: 'none' }}>
                <S.Header>
                    <S.Title>{_('closed_space.attention_header')}</S.Title>
                </S.Header>
            </Modal.Header>
            <div>
                <span>
                    {_('closed_space.attention_description')}
                    <strong className="text-lowercase">
                        &nbsp;
                        {secondsLeft}
                        &nbsp;
                        {_('presentation.countdown.seconds', {}, false)}
                    </strong>
                </span>
                <S.Actions>
                    <CustomButton variant="secondary" onClick={close}>
                        {_('button.got_it')}
                    </CustomButton>
                </S.Actions>
            </div>
        </div>
    )
}

export default CloseSpaceWarningModal
