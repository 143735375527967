import React, { Suspense } from 'react'
import useResponsive from '../../../hooks/useResponsive'

const Booth = React.lazy(() => import('./Booth'))
const BoothDetailMobile = React.lazy(() => import('./BoothDetailMobile'))

const BoothView = () => {
    const {isMobile} = useResponsive()

    return isMobile ? (
        <Suspense fallback={null}>
            <BoothDetailMobile />
        </Suspense>
    ) : (
        <Suspense fallback={null}>
            <Booth />
        </Suspense>
    )
}

export default BoothView
