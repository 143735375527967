import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import sortBy from 'lodash/sortBy'
import { Link } from 'react-router-dom'
import colors from '../../constants/colors'
import Icon from '../Icon'
import getPathForSpace from '../../utils/spaces/getPathForSpace'
import useParams from '../../hooks/useParams'
import useClickOutSide from '../../hooks/useClickOutSide'
import { allUpdatedSpacesSelector } from '../../features/spaces/spaceSlice'
import { _ } from '../../utils/localization'
import { RootState } from "../../store"
import CustomButton from "../CustomButton"

const S = {
    Wrapper: styled.div<{hideBorder?: boolean}>`
        position: relative;
        height: 28px;
        padding: ${({hideBorder}) => (hideBorder ? '0 8px' : '0 16px')};
        border-right: ${({hideBorder}) => (hideBorder ? 'none' : `1px solid ${colors.navDivider}`)};
        font-size: 14px;
        color: ${colors.navText};
    `,
    MenuItem: styled.div`
        display: flex;
        align-items: center;
        max-width: 180px;
        cursor: pointer;
        user-select: none;
    `,
    Icon: styled(Icon)<{isShow: boolean}>`
        margin: 0 -4px 2px 6px;
        transform: rotate(${({isShow}) => (isShow ? '-180deg' : 0)});
        transition: 0.3s;
    `,
    SubMenu: styled.div<{isShow: boolean}>`
        position: absolute;
        display: ${({isShow}) => (isShow ? 'flex' : 'none')};
        bottom: -8px;
        left: 16px;
        height: ${({isShow}) => (isShow ? 'auto' : 0)};
        padding: ${({isShow}) => (isShow ? '16px 0' : 0)};
        background-color: white;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        opacity: ${({isShow}) => (isShow ? 1 : 0)};
        transform: translateY(100%);
        transition: 0.3s;
    `,
    SubMenuWrapper: styled.div`
        border-right: 1px solid ${colors.navDivider};
        padding: 0 16px;
        & > a:last-child {
            margin-bottom: 0;
        }
    `,
    SubMenuHeader: styled.h1<{isShow: boolean}>`
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        color: ${colors.navText};
        text-transform: uppercase;
        white-space: nowrap;
        opacity: ${({isShow}) => (isShow ? 1 : 0)};
        cursor: default;
        user-select: none;
    `,
    SubMenuItemWrapper: styled.div<{ $isDisable: boolean }>`
        ${({ $isDisable }) => $isDisable && css`
            color: ${colors.gray5};
            user-select: none;
            position: relative;
            &::after {
                content: "${_('space.is_closed')}";
                display: none;
                position: absolute;
                bottom: -36px;
                left: 0;
                color: white;
                white-space: nowrap;
                background-color: ${colors.grayTitle};
                padding: 5px 16px;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 300;
                z-index: 1;
            }
            &:hover {
                color: ${colors.gray5};
            }
            &:hover::after {
                display: block;
            }
        `}
    `,
    SubMenuItem: styled(Link)<{ $isDisable: boolean }>`
        display: block;
        max-width: 250px;
        margin-bottom: 8px;
        font-size: 14px;
        color: ${colors.icon};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        &:hover{
            color: ${colors.navText};
            text-decoration: none;
        }
        ${({ $isDisable }) => $isDisable && css`
            color: ${colors.gray5};
            user-select: none;
            &:hover {
                color: ${colors.gray5};
            }
        `}
    `,
    Link: styled(Link)<{ $isDisable: boolean}>`
        color: ${colors.navText};
        &:hover {
            color: black;
            text-decoration: none;
        }
        
        ${({ $isDisable }) => $isDisable && css`
            color: ${colors.gray5};
            user-select: none;
            position: relative;
            &::after {
                content: "${_('space.is_closed')}";
                display: none;
                position: absolute;
                bottom: -36px;
                left: 0;
                color: white;
                white-space: nowrap;
                background-color: ${colors.grayTitle};
                padding: 5px 16px;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 300;
                opacity: 0.95;
            }
            &:hover {
                color: ${colors.gray5};
            }
            &:hover::after {
                display: block;
            }
        `}
    `,
}

export interface NavSpace {
    id: number
    name: string
    published: boolean
    slug: string
    type?: string
}
export interface NavItem {
    id: number
    order: number
    space: NavSpace
    linked_space: NavSpace
    text: string
    sub_menus?: {
        id: number
        text: string
        items: {
            id: number
            text: string
            order: number
            space: NavSpace
            linked_space: NavSpace
        }[]
    }[]
}

const SpaceNavItem = ({item}: {item: NavItem}) => {
    const eventPreview = useSelector((state: RootState) => state.eventPreview)
    const submenu = item.sub_menus
    const [showSubMenu, setShowSubMenu] = useState(false)
    const subMenuRef = useRef<HTMLDivElement>()
    useClickOutSide(subMenuRef, () => showSubMenu && setShowSubMenu(false))
    const params = useParams()
    if (item.linked_space && item.space.type === 'booth') {
        params.mapSlug = item.linked_space.slug
    }
    const updatedSpaces = useSelector(allUpdatedSpacesSelector)
    const getPublishedState = (space: NavSpace) => (
        updatedSpaces[space.id] ? updatedSpaces[space.id].published : space.published
    )
    const preventClick = (event, $isDisable) => {
        $isDisable && event.preventDefault()
    }

    return (
        <S.Wrapper ref={subMenuRef} hideBorder={eventPreview?.eventData?.use_menu_buttons}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {submenu ? (
                <div onClick={() => setShowSubMenu(!showSubMenu)}>
                    {eventPreview?.eventData?.use_menu_buttons ? (
                        <CustomButton
                            style={{backgroundColor: eventPreview?.eventData?.secondary_brand_color || 'white', color: "black", margin: 0}}
                        >
                            <span className="text-cut">{item.text}</span>
                            <S.Icon isShow={showSubMenu} icon="chevronDown" color={colors.navText} size={16} />
                        </CustomButton>
                    ) : (
                        <S.MenuItem>
                            <span className="text-cut">{item.text}</span>
                            <S.Icon isShow={showSubMenu} icon="chevronDown" color={colors.navText} size={16} />
                        </S.MenuItem>
                    )}

                    <S.SubMenu isShow={showSubMenu}>
                        {sortBy(submenu, 'order').map((_item, index) => (
                            <S.SubMenuWrapper key={index}>
                                <S.SubMenuHeader isShow={showSubMenu}>{_item.text}</S.SubMenuHeader>
                                {/* @ts-ignore */}
                                {(sortBy(_item?.items, 'order')).map((e) => {
                                    if (e.linked_space && e.space.type === 'booth') {
                                        params.mapSlug = e.linked_space.slug
                                    }
                                    return (
                                        <S.SubMenuItemWrapper
                                            $isDisable={!getPublishedState(e.space)}
                                            key={e.id}
                                        >
                                            <S.SubMenuItem
                                                to={getPathForSpace(e.space, params)}
                                                key={e.id}
                                                $isDisable={!getPublishedState(e.space)}
                                                onClick={(event) => (
                                                    preventClick(event, !getPublishedState(e.space))
                                                )}
                                            >
                                                {e.text}
                                            </S.SubMenuItem>
                                        </S.SubMenuItemWrapper>
                                    )
                                })}
                            </S.SubMenuWrapper>
                        ))}
                    </S.SubMenu>
                </div>
            ) : (
                eventPreview?.eventData?.use_menu_buttons ? (
                    <Link to={getPathForSpace(item.space, params)}>
                        <CustomButton
                            style={{backgroundColor: eventPreview?.eventData?.secondary_brand_color || 'white', color: "black", margin: 0}}
                        >{item.text}
                        </CustomButton>
                    </Link>
                ) : (
                    <S.Link
                        to={getPathForSpace(item.space, params)}
                        $isDisable={!getPublishedState(item.space)}
                        onClick={(event) => preventClick(event, !getPublishedState(item.space))}
                    >
                        {item.text}
                    </S.Link>
                )
            )}
        </S.Wrapper>
    )
}

export default SpaceNavItem
