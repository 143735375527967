import { createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { RetrieveEventResponse } from '../../types/swagger'
import api from '../../utils/api'
import createRequestSlice from '../../utils/createRequestSlice'
import { RootState } from '../../store'

export const fetchEventOverview = createAsyncThunk(
    'GET_EVENT_OVERVIEW',
    ({ eventSlug }: { eventSlug: string }) => api.get<RetrieveEventResponse>(`/events/${eventSlug}/`),
)

const eventOverviewSlice = createRequestSlice<RetrieveEventResponse>(fetchEventOverview, 'fetchEventOverview')
export const { emptyData } = eventOverviewSlice.actions
export default eventOverviewSlice.reducer

export const eventSelector = () => createSelector(
    [(state: RootState) => state.event.current],
    (event) => event.data,
)
