import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchNetworkingArea } from './networkingSlice'
import { UpdateVideocallResponse, Videocall } from '../../types/swagger'
import { websocketAction } from '../../utils/redux'
import { RootState } from '../../store'
import search from '../../utils/search'

export const WS = {
    updatedCall: websocketAction<UpdateVideocallResponse>('updated_networking_videocall'),
}

const networkingCallsSlice = createSlice({
    name: 'networkingCallSlice',
    initialState: {} as Record<string, Videocall>,
    reducers: {
        emptyData: () => ({}),
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNetworkingArea.fulfilled, (state, { payload }) => {
            payload.videocalls.forEach((videocall) => {
                state[videocall.id] = videocall
            })
        })
        builder.addCase(WS.updatedCall, (state, { payload }: PayloadAction<UpdateVideocallResponse>) => {
            const shouldBeDeleted = payload.ended || payload.users.length === 0
            const exists = payload.id in state
            if (exists && shouldBeDeleted) {
                delete state[payload.id]
            } else {
                state[payload.id] = payload
            }
        })
    },
})

export const networkingCallsSelector = () => createSelector(
    [(state: RootState) => state.videocalls],
    (videocalls) => Object.values(videocalls)
)

export const searchNetworkingCallsSelector = () => createSelector(
    [
        (state: RootState) => state.videocalls,
        (state: RootState) => state.networking.search,
    ],
    (networkingCalls, _search) => Object.values(networkingCalls)
        .filter(({ display_name }) => !_search || search(display_name, _search))
        .filter(({ users }) => users.length)
)

export const emptyData = networkingCallsSlice.actions.emptyData
export default networkingCallsSlice.reducer
