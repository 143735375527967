import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import React from 'react'
import { PayloadAction } from '@reduxjs/toolkit'
import colors from '../../constants/colors'
import useParams from '../../hooks/useParams'
import getPathForSpace from '../../utils/spaces/getPathForSpace'
import { _ } from '../../utils/localization'
import { addModal } from '../modals/modalSlice'
import { websocketAction } from '../../utils/redux'

const S = {
    Description: styled.p`
          color: ${colors.grayDescription};
          margin-top: 12px;
          margin-bottom: 48px;
        `,
    ButtonWrapper: styled.div`
          display: flex;
          justify-content: end;
        `,
}

export type ChatRouletteStarted = {'slug': string}

export const WS = {
    chatRouletteStarted: websocketAction<ChatRouletteStarted>('chatroulette_started'),
}

export default ({ payload }: PayloadAction<ChatRouletteStarted>) => addModal({
    Component: ({ close }: { close: () => void }) => {
        const params = useParams()
        const path = getPathForSpace({
            type: 'chat_roulette',
            slug: payload.slug,
        }, params)
        return (
            <>
                <S.Description>{_('chatroulette.started_modal.description')}</S.Description>

                <S.ButtonWrapper>
                    <Button type="button" onClick={close} variant="light">
                        {_('chatroulette.started_modal.dont_go')}
                    </Button>

                    <Link to={path} onClick={close}>
                        <Button type="button" onClick={close} style={{ marginLeft: 16 }}>
                            {_('chatroulette.started_modal.go')}
                        </Button>
                    </Link>
                </S.ButtonWrapper>
            </>
        )
    },
    header: _('chatroulette.started_modal.header'),
})
