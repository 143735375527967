import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'

type Position = { x:number, y:number }
type SetPositionProps = { position: Position, slug: string }

const positionSlice = createSlice({
    name: 'positionSlice',
    initialState: {} as Record<string, Position>, // Position per map slug
    reducers: {
        setPosition: (state, { payload }: PayloadAction<SetPositionProps>) => {
            state[payload.slug] = payload.position
        },
        clearPosition: (state, { payload }: PayloadAction<string>) => {
            delete state[payload]
        },
    },
})

export const setPosition = positionSlice.actions.setPosition
export const clearPosition = positionSlice.actions.clearPosition
export const positionSelector = (slug: string) => (state: RootState) => state.map.position[slug]
export default positionSlice.reducer
