import React from 'react'
import Popover from 'react-bootstrap/cjs/Popover'
import ListGroup from 'react-bootstrap/cjs/ListGroup'
import styled from 'styled-components'
import Button from 'react-bootstrap/cjs/Button'

import { _ } from '../../../utils/localization'
import { ClickableWidget } from '../types'
import styles from '../../../constants/styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

interface PopupProps {
    widget: ClickableWidget
    show: boolean
    onMouseEnter: () => void
    onMouseLeave: () => void
    position: number[]
    offsetY: number,
    widgetName?: string
    onClickArea?: (event) => void
}

interface PopupTexts {
    title: string
    confirm: string
    cancel: string
}

const POPUP_TEXT: Record<ClickableWidget, PopupTexts> = {
    reception: {
        get title() { return _('map.popup.widget.reception.title') },
        get confirm() { return _('map.popup.widget.reception.confirm') },
        get cancel() { return _('map.popup.widget.reception.cancel') },
    },
    event_info: {
        get title() { return _('map.popup.widget.event_info.title') },
        get confirm() { return _('map.popup.widget.event_info.confirm') },
        get cancel() { return _('map.popup.widget.event_info.cancel') },
    },
    external_link: {
        get title() { return _('map.popup.widget.external_link.title') },
        get confirm() { return _('map.popup.widget.external_link.confirm') },
        get cancel() { return _('map.popup.widget.external_link.cancel') },
    },
    embedded: undefined
}

const S = {
    PopoverContent: styled(Popover.Content)`
        padding: 0;
    `,
    Popover: styled(Popover)`
        top: unset;
        bottom: 0;
        padding: 0;
        width: 260px;
        background: #2D3035;
        opacity: 0.95;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        h3 {
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: white;
            background: #2D3035;
            border-color: rgb(150, 161, 169, 0.2);
        }
    `,
    ConfirmClickWidget: styled.div`
        display: flex;
        align-items: center;
        font-size: 12px;
        width: 100%;
        padding: 0 6px;
        overflow: hidden;
    `,
    ClickWidgetButton: styled(Button)`
        display: flex;
        align-items: center;
        overflow: unset;
        margin: 6px;
        width: calc(50% - 12px);
        white-space: nowrap;
        text-overflow: ellipsis;
    `,
    ListGroupItem: styled(ListGroup.Item)`
        padding: 0;
    `,
}

const WidgetPopup = ({
    show,
    position,
    onMouseEnter,
    onMouseLeave,
    widget,
    offsetY,
    onClickArea,
    widgetName
}: PopupProps) => {
    const windowSize = useWindowDimensions()

    if (!show || !position) {
        return null
    }

    const minTopPosition = 200
    const spaceNeededOnRight = 280
    const maxLeftPosition = windowSize.width - spaceNeededOnRight
    let topPosition = position[1] > minTopPosition ? position[1] : minTopPosition
    const leftPosition = position[0] > maxLeftPosition ? maxLeftPosition : position[0]

    // On safari, the offset of move position is different from another browser
    // It changes follow moving wrapper. In case tooMuchHeight, we move wrapper to top a distance equal tooMuchHeight/2 to center map.
    // And topPosition on Safari will move to top follow this distance.
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
        topPosition = position[1] > minTopPosition ? position[1] : minTopPosition
        topPosition += offsetY - styles.topNavHeight
    }

    const texts = POPUP_TEXT[widget]

    return (
        <div
            style={{
                left: leftPosition - 10,
                top: topPosition - 10,
                position: 'absolute',
            }}
        >
            <S.Popover
                id="kaas"
                placement="auto"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onClickArea}
            >
                <Popover.Title as="h3">
                    {widget === 'external_link' ? widgetName : texts.title}
                </Popover.Title>
                <div style={{ height: 3 }} />
            </S.Popover>
        </div>
    )
}

export default WidgetPopup
