import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import colors from '../../constants/colors'
import { VirtualAttendee } from '../../types/swagger'
import { formatJobInfo, formatName } from '../../utils/profile'
import { User } from '../auth/types'
import { TagWithColor } from '../networking/components/AttendeeInfoWithTags'
import openBusinessCard from '../profile/openBusinessCard'

const S = {
    Wrapper: styled.div<{isOnline: boolean}>`
        position: relative;
        display: flex;
        height: 90px;
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, ${({isOnline}) => (isOnline ? 0.1 : 0.05)});
        border-radius: 5px;
        cursor: pointer;
        opacity: ${({isOnline}) => (isOnline ? 1 : 0.5)};
    `,
    Avatar: styled.img`
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
    `,
    Info: styled.div`
        margin-left: 16px;
    `,
    Name: styled.h3`
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        color: ${colors.mineShaft};
    `,
    Description: styled.p`
        margin: 0;
        font-size: 12px;
        color: ${colors.icon};
    `,
    TagLine: styled.div`
        display: flex;
        align-items: center;
        margin-top: -6px;
    `,
}

const AttendeeGridItem = ({ attendee }: {attendee: VirtualAttendee}) => {
    const dispatch = useDispatch()

    return (
        <S.Wrapper
            isOnline={attendee.is_online}
            onClick={() => dispatch(openBusinessCard(attendee as User))}
        >
            <S.Avatar src={attendee.mugshot} />
            <S.Info>
                <S.Name className="notranslate">{formatName(attendee)}</S.Name>
                <S.Description>{formatJobInfo(attendee)}</S.Description>
                <S.TagLine>
                    {attendee.tags.map((tag) => (
                        <TagWithColor tag={tag} />
                    ))}
                </S.TagLine>
            </S.Info>
        </S.Wrapper>
    )
}

export default AttendeeGridItem
