import { SWRConfiguration } from 'swr'
import useSWRInfinite from 'swr/infinite'
import { listFetcher } from '../utils/api'

const useFetchInfiniteList = <T>(getKey: any, limit = 10, options: SWRConfiguration) => {
    const {
        data, error, size, setSize, mutate, isValidating
    } = useSWRInfinite<T[]>(
        getKey,
        listFetcher,
        options,
    )

    const isRefreshing = isValidating && data && data.length === size
    const isLoadingInitialData = !data && !error
    const isLoadingMore = isLoadingInitialData
        || (size > 0 && data && typeof data[size - 1] === 'undefined')
    const isEmpty = data?.[0]?.length === 0
    const isReachingEnd = isEmpty || (data && data[data.length - 1]?.length < limit)

    if (!data && !error) {
        return {
            isLoading: true
        }
    }

    if (error) {
        return {
            isLoading: false,
        }
    }

    return {
        isLoading: false,
        isRefreshing,
        data: (data ? [].concat(...data) : []) as T[],
        isLoadingMore,
        isReachingEnd,
        size,
        setSize,
        refresh: mutate
    }
}

export default useFetchInfiniteList
