import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Tab from 'react-bootstrap/cjs/Tab'
import {
    setSearch, selectNetworkSearch, tagsFilterSelector, updateTagsFilter
} from '../networkingSlice'
import { searchNetworkingCallsSelector } from '../networkingCallsSlice'
import { _ } from '../../../utils/localization'
import Icon from '../../../components/Icon'
import VideocallListItem from './VideocallListItem'
import CurrentUserIcon from './CurrentUserIcon'
import AttendeeListItem from './AttendeeListItem'
import { User } from '../../auth/types'
import useSlug from '../../../hooks/useSlug'
import {
    publicAttendeesSelector,
    canLoadMoreSelector,
    getAttendeeList,
    loadMore,
    selectAttendeeOffset,
    selectAttendeeStatus,
    getAttendeeListWithSearch,
    clearOffsetSearch,
    attendeesSelector,
} from '../../attendees/attendeesSlice'
import LoadMore from '../../../components/LoadMore'
import Loader from '../../../components/Loader'
import useSubscribeAttendees from '../../attendees/useSubscribeAttendees'
import PublicChatBar from '../../../components/PublicChatBar'
import ShowMeToggle from '../../events/ShowMeToggle'
import S from './StyledSidebarComponents'
import SearchInput from '../../../components/SearchInput'
import useAttendeeTags from '../../attendees/useAttendeeTags'
import { convertTagsGroupByCategory } from '../../calendar/utils'
import { formatJobInfo } from '../../../utils/profile'
import { addModal } from '../../modals/modalSlice'
import ProfileBadge from '../../profile/ProfileBadge'
import TagsFilter from '../../../components/TagsFilter'

const NetworkingCallsList = () => {
    const calls = useSelector(searchNetworkingCallsSelector())
    return (
        <S.ListGroup>
            {' '}
            {
                calls.map((call) => (
                    <VideocallListItem key={call.id} videocall={call} />
                ))
            }
        </S.ListGroup>
    )
}

export const AttendeeList = () => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const requestStatus = useSelector(selectAttendeeStatus)
    const search = useSelector(selectNetworkSearch)
    const tagsFilter = useSelector(tagsFilterSelector)
    const hasSearchOrTagsFilter = !!search || !!tagsFilter
    const offset = useSelector(selectAttendeeOffset(hasSearchOrTagsFilter))
    const canLoadMore = useSelector(canLoadMoreSelector(hasSearchOrTagsFilter))
    const publicAttendees = useSelector(publicAttendeesSelector(hasSearchOrTagsFilter))
    const attendees = useSelector(attendeesSelector(hasSearchOrTagsFilter))
    useSubscribeAttendees(attendees.map((attendee) => attendee.id))

    // Fetch attendee list when updating filter or search text
    useEffect(() => {
        dispatch(clearOffsetSearch())
        if (search || tagsFilter) {
            dispatch(
                getAttendeeListWithSearch({
                    eventSlug,
                    offset: 0,
                    search,
                    tagsFilter,
                })
            )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, tagsFilter])

    // Fetch attendee list for infinite scroll to load more
    useEffect(() => {
        if (search || tagsFilter) {
            dispatch(
                getAttendeeListWithSearch({
                    eventSlug,
                    offset,
                    search,
                    tagsFilter,
                })
            )
            return
        }
        dispatch(getAttendeeList({ eventSlug, offset }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, eventSlug, offset])

    return (
        <S.ListGroup>
            {
                publicAttendees.map((attendee) => (
                    <AttendeeListItem
                        attendee={attendee}
                        key={attendee.id}
                    />
                ))
            }
            {requestStatus === 'loading' && <Loader />}
            {canLoadMore
            && (
                <LoadMore onClick={() => dispatch(loadMore(hasSearchOrTagsFilter))}>
                    {_('networking.sidebar.load_more_attendees')}
                </LoadMore>
            )}
        </S.ListGroup>
    )
}

const CurrentUser = ({ currentUser }: { currentUser: User }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(addModal({
            Component: ({close}: {close: () => void}) => (
                <ProfileBadge close={close} />
            ),
            padding: 0,
        }))
    }
    return (
        <S.CurrentUserInfo>
            <S.Name className="notranslate">
                {currentUser.first_name}
                {' '}
                {currentUser.last_name}
            </S.Name>
            <S.JobDescription>
                {formatJobInfo(currentUser)}
            </S.JobDescription>
            <S.EditProfileLink onClick={onClick}>{_('networking.sidebar.edit_profile')}</S.EditProfileLink>
        </S.CurrentUserInfo>
    )
}

const SideBar = ({ currentUser, chatId }: { currentUser: User, chatId?: number }) => {
    const dispatch = useDispatch()
    const [isDefaultSidebar, setDefaultSidebar] = useState(true)
    const [expandSidebar, setExpandSidebar] = useState(false)
    const search = useSelector(selectNetworkSearch)
    const tags = useAttendeeTags()
    const tagCategories = convertTagsGroupByCategory(tags)
    const tagsFilter = useSelector(tagsFilterSelector)

    return (
        <S.SideBar isExpanding={expandSidebar}>
            {chatId && (
                <S.SwitchSidebar>
                    <S.ActionIcon isActive={isDefaultSidebar} onClick={() => setDefaultSidebar(true)}>
                        <Icon icon="personCircle" />
                    </S.ActionIcon>
                    <S.ActionIcon isActive={!isDefaultSidebar} onClick={() => setDefaultSidebar(false)}>
                        <Icon icon="forum" />
                    </S.ActionIcon>
                </S.SwitchSidebar>
            )}

            <S.SideBarTop>
                <S.Instructions>{_('networking.sidebar.drag_instructions')}</S.Instructions>
                <S.ExpandButton
                    className="expand-button"
                    isExpanding={expandSidebar}
                    onClick={() => setExpandSidebar(!expandSidebar)}
                >
                    <Icon icon="chevronDown" size={20} />
                </S.ExpandButton>
                <S.CurrentUser>
                    <S.UserInfoWrapper>
                        <S.IconWrapper>
                            <CurrentUserIcon left={0} top={0} />
                        </S.IconWrapper>
                        <CurrentUser currentUser={currentUser} />
                    </S.UserInfoWrapper>

                    <ShowMeToggle />
                </S.CurrentUser>

                <S.SearchAndFilter>
                    <SearchInput
                        value={search}
                        onSearch={(value) => dispatch(setSearch(value))}
                        placeholder={_('networking.sidebar.search_placeholder')}
                        className="p-1"
                    />
                    <TagsFilter
                        tagCategories={tagCategories}
                        value={tagsFilter}
                        onChange={(value) => dispatch(
                            updateTagsFilter(value)
                        )}
                        menuHeight="calc(var(--window-height) - 210px)"
                    />
                </S.SearchAndFilter>
            </S.SideBarTop>

            <S.Tabs defaultActiveKey="attendees" id="uncontrolled-tab-example">
                <Tab
                    eventKey="attendees"
                    title={(
                        <S.TabTitle>
                            <Icon icon="personCircle" size={22} style={{ marginRight: 5 }} />
                            {' '}
                            {_('networking.sidebar.attendees_tab_title')}
                        </S.TabTitle>
                    )}
                >
                    <AttendeeList />
                </Tab>
                <Tab
                    eventKey="networkingCalls"
                    title={(
                        <S.TabTitle>
                            <Icon icon="groupCircle" size={22} style={{ marginRight: 5 }} />
                            {' '}
                            {_('networking.sidebar.meetings_tab_title')}
                        </S.TabTitle>
                    )}
                >
                    <NetworkingCallsList />
                </Tab>
            </S.Tabs>
            {chatId && (
                <PublicChatBar
                    isShowPublicChat={!isDefaultSidebar}
                    chatId={chatId}
                    header={_('networking.public_chat.title')}
                />
            )}
        </S.SideBar>
    )
}

export default SideBar
