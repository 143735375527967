export default (fn: (...args: any) => any, INTERVAL = 300) => {
    let timeoutHandler = null
    return (...args) => {
        if (timeoutHandler == null) {
            timeoutHandler = setTimeout(() => {
                fn(...args)
                clearTimeout(timeoutHandler)
                timeoutHandler = null
            }, INTERVAL)
        }
    }
}
