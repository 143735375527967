import colors from '../../constants/colors'
import { IconOption } from '../Icon/icons'
import { SettingItem } from './CompatibilityTestProgress'

export const colorStatus = {
    pending: colors.iconOpacity(0.2),
    processing: colors.primary,
    success: '#2DB3AC',
    failed: '#F05359'
}

export const renderIcon = (item: SettingItem, progressStatus: SettingItem['status']): IconOption => {
    if (progressStatus === 'success') {
        return 'check'
    }
    if (progressStatus === 'failed') {
        return 'info'
    }
    return item.icon
}
