import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { Goodiebag } from './types'
import { identifier } from './itemsSlice'

const isSavedSelector = (goodiebag: Goodiebag) => createSelector(
    [(state: RootState) => state.goodiebag.items],
    (goodiebags) => !!goodiebags[identifier(goodiebag)],
)

export default isSavedSelector
