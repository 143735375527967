import { combineReducers } from 'redux'
import getMapSlice from './getMapSlice'
import positionSlice from './positionSlice'
import popupSlice from './popupSlice'

export default combineReducers({
    getMap: getMapSlice,
    position: positionSlice,
    popupData: popupSlice
})
