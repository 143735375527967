import React from 'react'
import { Goodiebag } from './types'
import Icon from '../../components/Icon'
import useToggleBookmark from './useToggleBookmark'

interface BookMarkProps {
    item: Goodiebag
    size?: number
    style?: React.CSSProperties
    className?: string
}

const Bookmark = ({
    size = 20, item, style, className
}: BookMarkProps) => {
    const { toggleBookmark, isBookmarked, bookmarkIcon } = useToggleBookmark(item)
    const handleClick = (event) => {
        event.stopPropagation()
        toggleBookmark()
    }

    return (
        <Icon
            onClick={handleClick}
            size={size}
            icon={bookmarkIcon}
            isActive={isBookmarked}
            style={{ transform: 'scaleY(1.1)', ...style }}
            className={className}
        />
    )
}

export default Bookmark
