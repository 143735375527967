import { Field, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import ConfirmModal from '../../../../components/ConfirmModal'
import FullPageLoading from '../../../../components/FullPageLoading'
import Icon from '../../../../components/Icon'
import colors from '../../../../constants/colors'
import useSlug from '../../../../hooks/useSlug'
import api from '../../../../utils/api'
import { _ } from '../../../../utils/localization'
import { LocationType, LocationTypeKeys } from '../../../calendar/calendarSlice'
import { setIsPageLoading } from '../../../events/pageLoadingSlice'
import { addModal } from '../../../modals/modalSlice'
import { showErrorNotification, showNotification } from '../../../notifications/notificationsSlice'
import { byDate } from '../Chat'
import Message from '../Message'
import useFetchBroadcastMessages from './useFetchBroadcastMessages'
import TagsFilter from '../../../../components/TagsFilter'
import useAttendeeTags from '../../../attendees/useAttendeeTags'
import { convertTagsGroupByCategory } from '../../../calendar/utils'
import useDebounceValue from '../../../../hooks/useDebounceValue'

const S = {
    Container: styled.div`
        position: relative;
        height: 100%;
        width: 100%;
        padding-top: 66px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 932px) {
            height: calc(var(--window-height) - 160px);
        }
    `,
    ScrolledContainer: styled.div`
        height: 100%;
        overflow: auto;
        position: relative;
    `,
    InfiniteScroll: styled(InfiniteScroll)`
        display: flex;
        flex-direction: column-reverse;
    `,
    SelectBar: styled.div`
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 10px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        background: ${colors.gray1};
        font-size: 14px;
    `,
    InputWrapper: styled.div<{disabled?: boolean}>`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 20px 20px 20px;
        border: 1px solid rgba(24, 28, 33, 0.15);
        border-radius: 4px;
        ${({ disabled }) => disabled && css`
            opacity: 0.5;
            cursor: none;
            pointer-events: none;
        `}
        @media (max-width: 932px) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 8px 12px 16px 12px;
            background: ${colors.light};
            margin: 0;
        }
    `,
    MultiLineInput: styled.textarea`
        resize: none;
        width: 100%;
        padding: 12px 16px;
        border: none !important;
        outline: none !important;
        font-size: 14px;
        font-weight: 400;
        color: ${colors.darkTitle};
        border-radius: 4px;
        &::placeholder {
            font-size: 14px;
            color: ${colors.iconOpacity(0.5)};
        }
    `,
    LeftBoarderWrapper: styled.div`
        align-self: stretch;
        display: flex;
        align-items: center;
        margin: 8px 0;
        padding: 1px 12px;
        border-left: ${colors.iconOpacity(0.25)} solid 1px;
    `,
    Messages: styled.div``,
    NumOfAttendee: styled.div`
        display: flex;
        align-items: center;
        width: auto;
        margin: 0 16px;
        padding: 10px;
        background: ${colors.primaryOpacity(0.03)};
        border-radius: 5px;
        font-size: 12px;
        & > div {
            margin-left: 8px;
            overflow: hidden;
            display: flex;
            align-items: center;
            & .text-cut {
                flex: 1;
            }
            & :not(.text-cut) {
                flex-shrink: 0;
            }
        }
        & > span {
            flex-shrink: 0;
            margin-top: -4px;
        }
    `,
}

export const getSendBroadcastTranslation = (selection: LocationTypeKeys) => (
    {
        [LocationType.HYBRID]: _('chat.broadcast_messages.send_both'),
        [LocationType.VIRTUAL]: _('chat.broadcast_messages.send_vep_user'),
        [LocationType.IN_PERSON]: _('chat.broadcast_messages.send_app_user'),
    }[selection]
)

const BroadcastMessageBoard = () => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const {
        data, refresh, size, setSize, isReachingEnd
    } = useFetchBroadcastMessages()
    const [shouldScrollBottom, setShouldScrollBottom] = useState(true)
    const bottomOfMessagesRef = useRef(null)
    const scrolledRef = useRef(null)
    const tags = useAttendeeTags()
    const tagCategories = convertTagsGroupByCategory(tags)
    const [tagsFilter, setTagsFilter] = useState<Record<string, any[]>>({})
    const tagsFilterDebounce = useDebounceValue(tagsFilter, 1000) as Record<string, any[]>
    const tagByCategory = Object.values(tagsFilterDebounce)
        .filter((category) => category.length)
        .map((category) => category.map((e) => e.value).join(','))
        .join('__')
    const [numOfAttendees, setNumOfAttendees] = useState(0)

    useEffect(() => {
        async function fetchNumOfAttendees() {
            const res = await api.safeGet<{count: number}>(`/events/${eventSlug}/attendees/count/?tags=${tagByCategory}`)
            setNumOfAttendees(res?.data?.count || 0)
        }
        fetchNumOfAttendees()
    }, [eventSlug, tagByCategory])

    useEffect(() => {
        if (scrolledRef.current && shouldScrollBottom) {
            scrolledRef.current.scrollTop = scrolledRef.current.scrollHeight
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.length])

    const onScroll = () => {
        const refCurrent = scrolledRef.current

        if (!refCurrent) {
            return
        }

        // When more messages can be loaded, but the scroll top is 0
        // the user can not scroll up to load more messages.
        // With scrollTop to 2px, user can scroll up to load more messages.
        if (refCurrent.scrollTop === 0 && !isReachingEnd) {
            refCurrent.scrollTop = 2
        }

        // When scroll height is 100px or more,
        // set shouldScrollBottom to false.
        // Because we don't want to scroll to bottom
        // if the user scrolled up to read older messages
        const isBottom = (refCurrent.scrollHeight - refCurrent.scrollTop - 100) < refCurrent.clientHeight
        if (isBottom) {
            setShouldScrollBottom(true)
        } else {
            setShouldScrollBottom(false)
        }
    }

    const handleLoadmore = () => {
        setShouldScrollBottom(false)
        setSize(size + 1)
    }

    const showConfirmModal = (values, { setFieldValue }) => {
        dispatch(addModal({
            Component: ({ close }: {close: () => void}) => (
                <ConfirmModal
                    header={_('send_broadcast_message.confirm_modal.header')}
                    body={_('send_broadcast_message.confirm_modal.body', { numOfAttendees })}
                    confirmText={_('buton.yes')}
                    onConfirm={() => sendBroadcastMessages(values, { setFieldValue })}
                    close={close}
                />
            ),
        }))
    }

    const sendBroadcastMessages = async (values, { setFieldValue }) => {
        try {
            dispatch(setIsPageLoading(true))
            await api.post(`/events/${eventSlug}/chat/broadcast_messages/?tags=${tagByCategory}`, values)
            setFieldValue('text', '')
            dispatch(showNotification({
                type: 'success',
                body: _('send_broadcast_message.success')
            }))
            refresh()
            bottomOfMessagesRef.current?.scrollIntoView?.()
        } catch (err) {
            dispatch(showErrorNotification(_('send_broadcast_message.failed')))
        } finally {
            dispatch(setIsPageLoading(false))
        }
    }

    return (
        <S.Container>
            <S.SelectBar onClick={(e) => e.preventDefault()}>
                <div className="flex-align-center">
                    <Icon icon="info" size={18} color={colors.primary} className="mb-2px mr-1" />
                    {_('chat.broadcast_message.select_target')}
                </div>

                <TagsFilter
                    tagCategories={tagCategories}
                    value={tagsFilter}
                    onChange={setTagsFilter}
                />
            </S.SelectBar>
            <S.ScrolledContainer id="scrollable-broadcast-messages" ref={scrolledRef}>
                <S.InfiniteScroll
                    dataLength={(data || []).length}
                    next={handleLoadmore}
                    hasMore={!isReachingEnd}
                    loader={<FullPageLoading isAbsolute />}
                    onScroll={onScroll}
                    inverse
                    scrollableTarget="scrollable-broadcast-messages"
                >
                    {(data || []).sort(byDate).map((message, i) => (
                        <Message
                            key={`${message.id}${i}`}
                            message={message}
                            isSent={false}
                            sender={{id: -1}}
                            chatUser={{id: -1}}
                            isSentEmail={false}
                            isBroadcastMessage
                        />
                    ))}
                </S.InfiniteScroll>
                <div ref={bottomOfMessagesRef} />
            </S.ScrolledContainer>
            <S.NumOfAttendee>
                <Icon icon="group" color={colors.midBlue} />
                <div>
                    <span className="text-cut mr-1">{_('broadcast_message.number_of_attendees_description')}</span>
                    <strong>{numOfAttendees}</strong>
                </div>
            </S.NumOfAttendee>
            <Formik onSubmit={showConfirmModal} initialValues={{target: 'ALL', text: ''}}>
                {({
                    // setFieldValue,
                    values,
                    handleSubmit
                }) => (
                    <S.InputWrapper>
                        <Field name="text">
                            {({field}) => (
                                <S.MultiLineInput
                                    placeholder={_('chat.new_message_input.placeholder')}
                                    rows={3}
                                    {...field}
                                />
                            )}
                        </Field>
                        <S.LeftBoarderWrapper>
                            <Icon
                                onClick={() => values.text && handleSubmit()}
                                icon="send"
                                className="ma-4"
                                isActive={values.text}
                                activeColor={colors.lightPrimary}
                            />
                        </S.LeftBoarderWrapper>
                    </S.InputWrapper>
                )}
            </Formik>
        </S.Container>
    )
}

export default BroadcastMessageBoard
