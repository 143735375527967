import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
    DateTime,
    isInBetween,
    toLocalTimeFormat,
    toDate,
} from '../../../utils/datetime'
import useInterval from '../../../hooks/useInterval'
import colors from '../../../constants/colors'
import Icon from '../../../components/Icon'
import { eventTimeFormatSelector } from '../../auth/eventPreviewSlice'

const S = {
    NowTag: styled.span`
      display: flex;
      align-items: center;
  `,

    Text: styled.span`
       font-weight: bold;
       color: ${colors.red};
       font-size: 12px;
       margin-left: 3px;
  `,

    Divider: styled.span`
      margin: 0 2px;
  `,

    Time: styled.span`
      display: flex;
      align-items: center;
      margin: 0;
      font-size: 12px;
      overflow: hidden;
      color: ${colors.icon};
    `,
}

const NowTag = ({hideLiveIcon}: {hideLiveIcon?: boolean}) => (
    <S.NowTag>
        {!hideLiveIcon && <Icon icon="monitorEye" size={16} />}
        <S.Text>NOW</S.Text>
    </S.NowTag>
)

const Time = ({
    start_date, end_date, hideLiveIcon,
}: { start_date: DateTime, end_date: DateTime, hideLiveIcon?: boolean }) => {
    const time = useInterval(1000 * 60) // check every minute
    const isNow = isInBetween(toDate(start_date), toDate(end_date), time)
    const timeFormat = useSelector(eventTimeFormatSelector)

    return (
        <S.Time>
            {
                isNow
                    ? <NowTag hideLiveIcon={hideLiveIcon} />
                    : <span>{toLocalTimeFormat(start_date, timeFormat)}</span>
            }
            <S.Divider>-</S.Divider>
            <span>{toLocalTimeFormat(end_date, timeFormat)}</span>
        </S.Time>
    )
}

export default Time
