import React, { ReactNode } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'
import colors from '../constants/colors'
import Icon from './Icon'

export const S = {
    Tooltip: styled(Tooltip)<Props>`
        & .arrow {
            display: none;
        }
        & .${({ bsPrefix }) => bsPrefix}-inner {
            max-width: 250px;
            padding: 8px 10px;
            background: ${({ theme }) => (theme === 'dark' ? colors.dark : 'white')};
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
            text-align: center;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            border-radius: 5px;
        }
    `,
    StatusIcon: styled(Icon)`
        margin: 0 0 4px 4px;
        cursor: pointer;
        &:hover > svg path {
            fill: ${colors.primary} !important;
        }
    `
}

interface Props {
    size?: number
    text?: string
    className?: string
    placement?: 'top' | 'bottom' | 'right' | 'left'
    theme?: 'dark' | 'light'
    bsPrefix?: string
    children: ReactNode
    delay?: number | {
        show: number;
        hide: number;
    };
}

const BaseTooltip = ({
    text,
    children,
    placement = 'bottom',
    theme = 'dark',
    bsPrefix = 'tooltip',
    delay = 0,
}: Props) => (
    text ? (
        <OverlayTrigger
            placement={placement}
            overlay={(
                <S.Tooltip id="info-tooltip" theme={theme} bsPrefix={bsPrefix}>
                    {text}
                </S.Tooltip>
            )}
            delay={delay}
        >
            <div>{children}</div>
        </OverlayTrigger>
    ) : <></>
)

export default BaseTooltip
