import React from 'react'
import { useDispatch } from 'react-redux'
import GeneralInfoWithThumbnail from '../../../components/GeneralInfoWithThumbnail'
import openChat from '../openChat'
import getMugshotSrc from '../../../utils/getMugshotSrc'
import AmountOfNotifications from './AmountOfNotifications'
import { ChatWithAttendee } from '../types'
import useSlug from '../../../hooks/useSlug'
import NotificationBotImg from '../../../assets/notification_bot.png'
import { _ } from '../../../utils/localization'
import { setSelectingBroadcastMessage, setSelectingChatBot } from '../chatSlice'
import { formatJobInfo } from '../../../utils/profile'
import openChatModal from '../openChatModal'
import useResponsive from '../../../hooks/useResponsive'
import { closeAllModal } from '../../modals/modalSlice'

interface ChatListItemProps {
    chat: ChatWithAttendee
    active: boolean
    isChatBot?: boolean
}

const ChatListItem = ({ chat, active = false, isChatBot }: ChatListItemProps) => {
    const {isMobile} = useResponsive()
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const onClick = active
        ? () => null
        : () => {
            if (isMobile) {
                dispatch(closeAllModal())
                dispatch(openChatModal(eventSlug, chat.user_id))
            } else {
                dispatch(openChat(eventSlug, chat.user_id))
            }
            dispatch(setSelectingChatBot(isChatBot))
            dispatch(setSelectingBroadcastMessage(false))
        }
    return (
        <GeneralInfoWithThumbnail
            key={chat.user_id}
            onClick={onClick}
            active={active}
            image={isChatBot ? NotificationBotImg : getMugshotSrc(chat.user)}
            name={isChatBot ? _('chat.notification_bot') : `${chat.user.first_name} ${chat.user.last_name}`}
            description={isChatBot ? '' : formatJobInfo(chat.user)}
            // eslint-disable-next-line no-nested-ternary
            badge={isChatBot ? undefined : (chat.user.is_online ? 'success' : 'danger')}
            size="sm"
            style={{ padding: 10 }}
        >
            <AmountOfNotifications amount={chat.unread} onClick={onClick} />
        </GeneralInfoWithThumbnail>
    )
}

export default ChatListItem
