import MeetingSpace from './views/MeetingSpace'
import MeetingRoom from './views/MeetingRoom'
import { createEventRoute } from '../../utils/routes'
import AllMeetings from './views/AllMeetings'

export default {
    meetingRoom: createEventRoute({
        title: 'Meeting',
        path: '/meetings/:meetingSlug/:meetingRoomSlug',
        exact: true,
        component: MeetingRoom,
    }),
    meeting: createEventRoute({
        title: 'Meeting Space',
        path: '/meetings/:meetingSlug',
        exact: true,
        component: MeetingSpace,
    }),
    allMeetings: createEventRoute({
        title: 'My Meetings',
        path: '/allmeetings',
        exact: true,
        component: AllMeetings,
    }),
}
