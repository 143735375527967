import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import config from '../config'
import { mapGetDayWidthToProps } from '../redux/selectors'
import colors from '../../../constants/colors'

interface StyledRowProps {
    backgroundColor: string
}

interface StyledItemWrapperProps {
    dayWidth: number
}

const S = {
    TimeTableRow: styled.div<StyledRowProps>`
        display: flex;
        flex-direction: row;
        overflow: hidden;
        height: ${config.rowHeight}px;
        background-color: ${({ backgroundColor }) => (backgroundColor)};
        &:last-child {
            height: ${config.rowHeight + 3}px; // To avoid scroll bar overlap the item
        }
    `,
    ItemWrapper: connect(mapGetDayWidthToProps)(styled.div<StyledItemWrapperProps>`
        position: absolute;
        width: ${({ dayWidth }) => dayWidth}px;
        height: ${config.rowHeight}px;
        overflow: hidden;
    `),
}

const getBackgroundColor = (isGroupSession, index) => {
    if (isGroupSession) {
        return '#ea868f' // colors.red with 0.6 opacity
    }
    return index % 2 ? 'transparent' : colors.gray1
}

type TimeTableRowProps = {
    children: any
    isGroupSession?: boolean
    index: number
}

const TimeTableRow = ({ children, isGroupSession, index }: TimeTableRowProps) => (
    <S.TimeTableRow backgroundColor={getBackgroundColor(isGroupSession, index)}>
        <S.ItemWrapper>
            { children }
        </S.ItemWrapper>
    </S.TimeTableRow>
)

export default TimeTableRow
