import React, { Suspense } from 'react'
import useResponsive from '../../../hooks/useResponsive'

const Cinema = React.lazy(() => import('./Cinema'))
const CinemaMobile = React.lazy(() => import('./CinemaMobile'))

const CinemaView = () => {
    const {isMobile} = useResponsive()
    return isMobile ? (
        <Suspense fallback={null}>
            <CinemaMobile />
        </Suspense>
    ) : (
        <Suspense fallback={null}>
            <Cinema />
        </Suspense>
    )
}

export default CinemaView
