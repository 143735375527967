import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { GoodiebagCategory } from './types'

const categorySlice = createSlice({
    name: 'goodiebag',
    initialState: GoodiebagCategory.File,
    reducers: {
        selectCategory: (state, { payload }: PayloadAction<GoodiebagCategory>) => payload
    },
})

export const categorySelector = (state: RootState) => state.goodiebag.category
export const selectCategory = categorySlice.actions.selectCategory
export default categorySlice.reducer
