import React, { CSSProperties } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Icon from './NavIcon'
import openChatModal from '../../features/chat/openChatModal'
import useHomeLink from '../../hooks/useHomeLink'
import { navSelector } from '../../features/events/navigationSlice'
import { RootState } from '../../store'
import styles from '../../constants/styles'
import ProfileDropdown from './components/ProfileDropdown'
import { selectTotalUnread } from '../../features/chat/chatSlice'
import FullScreenToggle from './components/FullScreenToggle'
import HelpContent from '../../features/helpcontent/HelpContent'
import useSlug from '../../hooks/useSlug'
import SpaceNavItem from './SpaceNavItem'
import colors from '../../constants/colors'
import LogoPlaceholder from '../../assets/Logo_placeholder.png'
import AttendeesSidebarToggle from './components/AttendeesSidebarToggle'
import { expandedSideBarSelector, showSideBarSelector } from '../../features/attendees/attendeesSlice'
import { showAttendeesOutsideSelector, showCalendarIconSelector, showSpeakerIconSelector } from '../../features/auth/eventPreviewSlice'
import CalendarToggle from './components/CalendarToggle'
import PerformerSidebarToggle from './components/PerformerSidebarToggle'
import constants from '../../constants/constants'
import { isExpandingSelector, isShowSidebarSelector } from '../../features/performer/performerSlice'
import { closeCalendar } from '../../features/calendar/calendarSlice'
import { showSidebarSelector } from '../../features/exhibition/boothVististorsSlice'
import BaseTooltip from '../BaseTooltip'
import { _ } from '../../utils/localization'
import DropdownButton from '../DropdownButton'
import GoogleTranslateButton from '../GoogleTranslateButton'
import CustomButton from "../CustomButton"

const S = {
    Navbar: styled(Navbar)`
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
        z-index: ${constants.Z_INDEX.ATTENDEE_SIDEBAR - 1};
    `,
    LeftItemsWrapper: styled.div`
        display: flex;
        align-items: center;
        & > div:last-child {
            border-right: none;
        }
    `,
    ItemDivider: styled.span`
        border-left: rgba(0, 0, 0, 0.3) 1px solid;
        margin-top: -15px;
        margin-bottom: -15px;
    `,
    NavItemsWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-right: 30px;
        flex-grow: 1;
    `,
    RightItemsWrapper: styled.div`
        display: flex;
        height: ${styles.topNavHeight}px;
        justify-content: space-between;
        align-items: center;
    `,
    Logo: styled.img`
        height: 46px;
        max-width: 220px;
        margin: 6px 48px 6px 20px; 
        object-fit: contain;
    `,
    Message: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 8px;
        margin-right: 20px;
        cursor: pointer;
        border-right: 1px solid ${colors.navDivider};
        & > span.label {
            margin-left: 9px;
            font-size: 14px;
            color: ${colors.grayTitle};
        }
    `,
    AmountOfNotifications: styled.div`
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        top: -4px;
        left: 12px;
        background: ${colors.danger};
        color: white;
        font-size: 8px;
        font-weight: 500;
        text-align: center;
        line-height: 18px;
    `,
    ExpandArea: styled.div<{width: number}>`
        width: 100px;
    `
}

export const MessagesToggle = ({
    style, showLabel
}: {style?: CSSProperties, showLabel?: boolean}) => {
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const totalUnread = useSelector(selectTotalUnread)

    const onClick = () => {
        dispatch(closeCalendar())
        dispatch(openChatModal(eventSlug))
    }
    return (
        <S.Message onClick={onClick} style={style}>
            <Icon icon="email" />
            {totalUnread > 0 && (
                <S.AmountOfNotifications>
                    {totalUnread > 99 ? '99+' : totalUnread}
                </S.AmountOfNotifications>
            )}
            {showLabel && <span className="label">{_('nav.message_icon.helper_text')}</span>}
        </S.Message>
    )
}

const IconWithTooltip = ({ Component, text }: { Component: (props: any) => JSX.Element, text: string }) => (
    <BaseTooltip text={text} delay={{show: 1000, hide: 0}}>
        <Component />
    </BaseTooltip>
)

const Nav = () => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const homeLink = useHomeLink()
    const event = useSelector((state: RootState) => state.event.current)
    const enablePageTranslation = event.data.enable_page_translation
    const logo = event?.data?.logo || LogoPlaceholder
    const navigation = useSelector(navSelector)
    const showAttendeesSideBar = useSelector(showSideBarSelector)
    const showBoothVisitorsSideBar = useSelector(showSidebarSelector)
    const showPerformerSideBar = useSelector(isShowSidebarSelector)
    const expandedAttendeesSideBar = useSelector(expandedSideBarSelector)
    const isExpandingPerformerSideBar = useSelector(isExpandingSelector)
    const showAttendeesOutside = useSelector(showAttendeesOutsideSelector)
    const showSpeakerIcon = useSelector(showSpeakerIconSelector)
    const showCalendarIcon = useSelector(showCalendarIconSelector)

    // eslint-disable-next-line no-nested-ternary
    const spacingRight = (showAttendeesSideBar || showPerformerSideBar || showBoothVisitorsSideBar)
        ? (expandedAttendeesSideBar || isExpandingPerformerSideBar) ? 600 : 350
        : 0

    // Only render nav after loading event and entering a route with event slug available
    return !(event && eventSlug) ? null : (
        <S.Navbar bg="white" fixed="top" className="p-0">
            <S.NavItemsWrapper>
                <S.LeftItemsWrapper onClick={() => dispatch(closeCalendar())}>
                    <Navbar.Brand className="p-0 m-0">
                        <Link to={homeLink}><S.Logo src={logo} /></Link>
                    </Navbar.Brand>

                    {navigation.map((item, index) => (
                        // @ts-ignore
                        <SpaceNavItem item={item} key={index} />
                    ))}
                </S.LeftItemsWrapper>

                <S.RightItemsWrapper>
                    {showSpeakerIcon && (
                        <IconWithTooltip
                            text={_('nav.performer_icon.helper_text')}
                            Component={PerformerSidebarToggle}
                        />
                    )}
                    {showCalendarIcon && (
                        <IconWithTooltip
                            text={_('nav.calendar_icon.helper_text')}
                            Component={CalendarToggle}
                        />
                    )}
                    {showAttendeesOutside && (
                        <IconWithTooltip
                            text={_('nav.attendee_icon.helper_text')}
                            Component={AttendeesSidebarToggle}
                        />
                    )}
                    <IconWithTooltip
                        text={_('nav.message_icon.helper_text')}
                        Component={MessagesToggle}
                    />
                    <IconWithTooltip
                        text={_('nav.profile_dropdown.helper_text')}
                        Component={ProfileDropdown}
                    />
                    <HelpContent />
                    {enablePageTranslation && (
                        <DropdownButton
                            className="mx-2"
                            hideOnClick={false}
                            renderToggleButton={() => (
                                <CustomButton
                                    variant="primary"
                                    style={{background: event.data.secondary_brand_color || 'white', color: "black", margin: 0}}
                                >Translate
                                </CustomButton>
                            )}
                            showCloseButton
                        >
                            <GoogleTranslateButton />
                        </DropdownButton>
                    )}
                    <FullScreenToggle />
                    <div
                        style={{
                            width: spacingRight
                        }}
                    />
                </S.RightItemsWrapper>
            </S.NavItemsWrapper>
        </S.Navbar>
    )
}

export default Nav
