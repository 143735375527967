import React from 'react'
import { useDispatch } from 'react-redux'
import colors from '../../../../constants/colors'
import { _ } from '../../../../utils/localization'
import AppointmentScheduleModal from '../../../appointment_schedules/AppointmentScheduleModal'
import { addModal } from '../../../modals/modalSlice'
import { S } from './AppointmentScheduleNotification'
import { ScheduleActionsProps } from './DenyScheduleButton'

const RescheduleRequestButton = ({
    receiver, appointmentSchedule, renderCustomButton, callback
}: ScheduleActionsProps) => {
    const dispatch = useDispatch()

    const showRescheduleModal = () => {
        dispatch(addModal({
            Component: ({ close }: {close: () => null}) => (
                <AppointmentScheduleModal
                    close={close}
                    receiver={receiver}
                    previousSchedule={appointmentSchedule}
                    callback={callback}
                />
            ),
            padding: 30
        }))
    }

    return renderCustomButton ? (
        <div onClick={showRescheduleModal}>
            {renderCustomButton()}
        </div>
    ) : (
        <S.TextButton
            color={colors.icon}
            className="mr-2"
            onClick={showRescheduleModal}
        >
            {_('button.reschedule')}
        </S.TextButton>
    )
}

export default RescheduleRequestButton
