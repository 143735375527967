import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/cjs/Form'
import Button from 'react-bootstrap/cjs/Button'
import { Tab, Tabs } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { _ } from '../../../utils/localization'
import { enterEvent, requestCode } from '../eventPreviewSlice'
import { addModal } from '../../modals/modalSlice'
import { RetrieveVirtualEventResponse } from '../../../types/__generated_swagger'
import colors from '../../../constants/colors'
import useSlug from '../../../hooks/useSlug'
import useResponsive from '../../../hooks/useResponsive'
import Divider from '../../../components/Divider'

export const S = {
    FormWrapper: styled.div`
        flex: 1;
        overflow: auto;
        padding-right: 10px;
    `,
    Tabs: styled(Tabs)<{$isMobile: boolean}>`
        min-width: 0;
        margin-top: 34px;
        position: relative;
        display: flex;
        border: none;
        &::after {
            content: '';
            margin: 0;
            height: 1px;
            position: absolute;
            width: 100%;
            bottom: -1px;
            box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
            background: rgba(0, 0, 0, 0.1);
        }
        & > a {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border: none!important;
            transition: 0.3s;
            border: 3px solid transparent !important;
            font-size: ${({$isMobile}) => ($isMobile ? 14 : 16)}px;
            text-transform: uppercase;
            font-weight: 400;
            color: ${colors.darkTitle} !important;
            text-align: center;
            opacity: 0.75;
            &:hover {
                color: black;
                opacity: 1;
            }
            &[aria-selected="true"] {
                font-weight: 700;
                color: ${colors.primary} !important;
                border-bottom: 3px solid ${colors.primary} !important;
                opacity: 1;
            }
        }
    `,
    CheckBoxForm: styled(Form.Group)`
        margin-top: 28px;
        color: ${colors.grayDescription};
    `,
    Checkbox: styled(Form.Check)`
      display: inline;
      margin-right: 0.5rem;
    `,
    ButtonWrapper: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: start;
        margin: 32px 0 8px 0;
    `,
    Button: styled(Button)<{$isMobile: boolean}>`
        max-width: ${({$isMobile}) => ($isMobile ? '100%' : '300px')};
        width: ${({$isMobile}) => ($isMobile ? '100%' : 'auto')};
    `,
    Label: styled(Form.Label)`
        font-size: 12px;
        font-weight: 500;
        color: ${colors.label};
    `,
    Link: styled.a`
        color: ${colors.primary};
        text-overflow: ellipsis;
        overflow: hidden;
        text-decoration: underline;
        cursor: pointer;
    `,
    Subtitle: styled.p<{isMobile: boolean}>`
        margin: ${({isMobile}) => (isMobile ? '24px 0 16px 0' : '50px 0 20px 0')};
        font-size: 14px;
        font-weight: 300;
        word-break: break-word;
        color: ${colors.grayDescription};
    `,
    Addendum: styled.div``,
}

const TermsModal = ({ eventData }: { eventData: RetrieveVirtualEventResponse }) => (
    <div className="editor-content" dangerouslySetInnerHTML={{ __html: eventData.terms }} />
)

const PrivacyModal = ({ eventData }: { eventData: RetrieveVirtualEventResponse }) => (
    <div className="editor-content" dangerouslySetInnerHTML={{ __html: eventData.privacy_statement }} />
)

const TicketCodeForm = ({ eventData }: { eventData: RetrieveVirtualEventResponse }) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [isAgree, setIsAgree] = useState(false)
    const [activeKey, setActiveKey] = useState('requestCode')
    const [requestCodeValidated, setRequestCodeValidated] = useState(false)
    const [loginValidated, setLoginValidated] = useState(false)
    const {isMobile} = useResponsive()

    useEffect(() => {
        if (eventData?.start_with_login_tab) {
            setActiveKey('login')
        } else {
            setActiveKey('requestCode')
        }
    }, [eventData])

    const onSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.stopPropagation()
            setLoginValidated(true)
        } else {
            dispatch(enterEvent({ eventSlug, email, code }))
        }
    }

    const onSubmitRequestCode = (event) => {
        event.preventDefault()
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.stopPropagation()
            setRequestCodeValidated(true)
        } else {
            dispatch(requestCode({ email, eventSlug }))
            setActiveKey('login')
        }
    }

    const showTerms = () => {
        dispatch(addModal({
            Component: () => <TermsModal eventData={eventData} />,
            header: _('auth.enter_event.t_and_c_header'),
        }))
    }

    const showPrivacyStatement = () => {
        dispatch(addModal({
            Component: () => <PrivacyModal eventData={eventData} />,
            header: _('auth.enter_event.privacy_statement_header'),
        }))
    }

    return (
        <S.FormWrapper>
            <S.Tabs
                id="login-request-code-tabs"
                activeKey={activeKey}
                onSelect={(key) => setActiveKey(key)}
                $isMobile={isMobile}
            >
                <Tab
                    eventKey="login"
                    title={_('auth.enter_event.sign_in.tab')}
                >
                    <S.Subtitle isMobile={isMobile}>{_('auth.enter_event.sign_in.subtitle')}</S.Subtitle>
                    <Form noValidate validated={loginValidated} onSubmit={onSubmit}>
                        <Form.Group>
                            <S.Label>
                                {`${_('auth.enter_event.email.label')} *`}
                            </S.Label>

                            <Form.Control
                                value={email}
                                required
                                type="email"
                                placeholder={_('auth.enter_event.email.place_holder')}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {_('form.validation.email_address.required')}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <S.Label>
                                {`${_('auth.enter_event.access_code.label')} *`}
                            </S.Label>

                            <Form.Control
                                required
                                type="text"
                                placeholder={_('auth.enter_event.access_code.place_holder')}
                                onChange={(e) => setCode(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {_('form.validation.access_code.required')}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <S.CheckBoxForm controlId="formBasicCheckbox" className="no-validate">
                            <S.Checkbox
                                custom
                                type="checkbox"
                                label={_('auth.enter_event.agree_checkbox')}
                                checked={isAgree}
                                onChange={() => setIsAgree(!isAgree)}
                            />
                            <S.Link href="#" onClick={showTerms}>
                                {_('auth.enter_event.terms')}
                            </S.Link>
                            {' '}
                            {_('auth.enter_event.and')}
                            {' '}
                            <S.Link href="#" onClick={showPrivacyStatement}>
                                {_('auth.enter_event.privacy_statement')}
                            </S.Link>
                        </S.CheckBoxForm>
                        <S.ButtonWrapper>
                            <S.Button type="submit" disabled={!isAgree} $isMobile={isMobile}>
                                {_('auth.enter_event.submit_code')}
                            </S.Button>
                        </S.ButtonWrapper>
                        {eventData.login_form_addendum && (
                            <>
                                <Divider marginTop={20} marginBottom={20} />
                                <S.Addendum className="editor-content" dangerouslySetInnerHTML={{ __html: eventData.login_form_addendum }} />
                            </>
                        )}
                    </Form>
                </Tab>
                <Tab
                    eventKey="requestCode"
                    title={_('auth.enter_event.request_code.tab')}
                >
                    <S.Subtitle isMobile={isMobile}>{_('auth.enter_event.request_code.subtitle')}</S.Subtitle>
                    <Form noValidate validated={requestCodeValidated} onSubmit={onSubmitRequestCode}>
                        <Form.Group>
                            <S.Label>
                                {`${_('auth.enter_event.email.label')} *`}
                            </S.Label>

                            <Form.Control
                                value={email}
                                required
                                type="email"
                                placeholder={_('auth.enter_event.email.place_holder')}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {_('form.validation.email_address.required')}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <S.ButtonWrapper>
                            <S.Button type="submit" $isMobile={isMobile}>
                                {_('auth.enter_event.request_access_code')}
                            </S.Button>
                        </S.ButtonWrapper>

                        {eventData.login_form_addendum && (
                            <>
                                <Divider marginTop={20} marginBottom={20} />
                                <S.Addendum className="editor-content" dangerouslySetInnerHTML={{ __html: eventData.login_form_addendum }} />
                            </>
                        )}
                    </Form>
                </Tab>
            </S.Tabs>
        </S.FormWrapper>
    )
}

export default TicketCodeForm
