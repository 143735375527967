import Map from './views/Map'
import { createEventRoute } from '../../utils/routes'

export default {
    map: createEventRoute({
        title: 'Map',
        path: '/map/:mapSlug/',
        component: Map,
    }),
}
