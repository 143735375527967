import styled from 'styled-components'
import React from 'react'
import { useDispatch } from 'react-redux'
import config from '../config'
import { incrementZoom, decrementZoom } from '../redux/zoomLevelSlice'
import Icon from '../../../components/Icon'

const S = {
    ZoomControl: styled.div`
        background-color: ${config.timelineNavColor};
        height: ${config.timelineNavHeight}px;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        align-items: center;
    `,
}

const ZoomControl = () => {
    const dispatch = useDispatch()
    return (
        <S.ZoomControl>
            <Icon
                icon="remove"
                onClick={() => dispatch(decrementZoom())}
                color="white"
            />

            <Icon
                icon="zoom"
                color="white"
            />

            <Icon
                icon="add"
                onClick={() => dispatch(incrementZoom())}
                color="white"
            />
        </S.ZoomControl>
    )
}

export default ZoomControl
