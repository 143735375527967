import React from 'react'
import styled from 'styled-components'

const S = {
    Avatar: styled.img<{ width: number }>`
        flex-shrink: 0;
        width: ${({ width }) => width}px;
        height: ${({ width }) => width}px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
    `
}

const Avatar = ({ width = 48, src, className }: {width?: number, src: string, className?: string }) => (
    <S.Avatar width={width} src={src} className={className} />
)

export default Avatar
