import React from 'react'
import CardContainer from './CardContainer'
import GoodiebagMeeting from './GoodiebagMeeting'
import { ListGroupSessionsResponse } from '../../groupsessions/groupSessionsSlice'

const GroupSessions = ({ items }: {items: ListGroupSessionsResponse['results']}) => (
    <CardContainer>
        {items.map((groupSession) => {
            const goodiebagMeeting = {
                id: groupSession.id,
                primarySpeakerId: groupSession.videocall.admin_id,
                speakerCount: 0,
                title: groupSession.videocall.display_name,
                slug: groupSession.videocall.twilio_name,
                startsAt: groupSession.starts_at,
                endsAt: groupSession.ends_at,
                enableHostChat: groupSession.enable_host_chat,
                type: 'groupSession' as const
            }
            return (
                <GoodiebagMeeting item={goodiebagMeeting} key={groupSession.id} />
            )
        })}
    </CardContainer>
)

export default GroupSessions
