import React from 'react'
import styled from 'styled-components'
import BSModal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'react-bootstrap/cjs/Button'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Job } from './types'
import colors from '../../constants/colors'
import Icon from '../../components/Icon'
import openChatModal from '../chat/openChatModal'
import { currentUserSelector } from '../auth/authSlice'
import { raiseError } from '../../utils/errorTracker'
import { RetrieveJobOpeningDetailResponse } from '../../types/__generated_swagger'
import Get from '../../components/Get'
import useSlug from '../../hooks/useSlug'
import { _ } from '../../utils/localization'

type JobModalProps = { job: Job, close: () => void }

const S = {
    Header: styled(BSModal.Header)`
        padding: 16px;
    `,
    HeaderInner: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        & + button {
            margin-top: -6px!important;
            font-size: 37px;
            font-weight: 400;
            &:focus {
                outline: none;
            }
        }
    `,
    Title: styled(BSModal.Title)`
        margin-top: auto;
        margin-bottom: auto;
        font-size: 20px;
        line-height: unset;
        color: ${colors.gray6};
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `,
    Body: styled.div`
        padding: 16px;
        font-size: 14px;
        color: #667283;
    `,
    BodyWrapper: styled.div`
        min-height: 100px;
    `
}

// Quick fix for:
// https://sentry.io/organizations/twoppy-bv/issues/2057350754/?project=5394416&query=is%3Aunresolved
const getSubjectParams = (jobTitle: string) => {
    try {
        return new URLSearchParams({ subject: jobTitle })
            .toString()
            .replace(/\+/g, '%20') // replaceAll function just support safari from version 13.1
    } catch (e) {
        raiseError(e)
        return ''
    }
}

const JobModal = ({ job, close }: JobModalProps) => {
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const currentUser = useSelector(currentUserSelector())
    const message = () => dispatch(openChatModal(eventSlug, job.assigned_staff))
    const { trackEvent } = useMatomo()
    const url = `/events/${eventSlug}/jobs/item/${job.id}/`
    const trackAction = () => {
        trackEvent({
            category: job.booth.name,
            action: 'apply button clicked',
            name: job.title,
        })
    }

    return (
        <div>
            <S.Header>
                <S.HeaderInner>
                    <S.Title>{job?.title}</S.Title>
                    <span>
                        {(job.assigned_staff && currentUser.id !== job.assigned_staff) && (
                            <Icon icon="message" className="mr-2" onClick={message} />
                        ) }
                        <Icon icon="close" onClick={close} />
                    </span>
                </S.HeaderInner>
            </S.Header>

            <S.BodyWrapper>
                <Get url={url}>
                    {({ description }: RetrieveJobOpeningDetailResponse) => (
                        <S.Body className="editor-content" dangerouslySetInnerHTML={{ __html: description }} />
                    )}
                </Get>
            </S.BodyWrapper>

            <BSModal.Footer>
                <Button as="a" href={`mailto:${job.email}?${getSubjectParams(job.title)}`} onClick={() => trackAction()}>
                    {_('jobs.modal.button.apply')}
                </Button>
            </BSModal.Footer>
        </div>
    )
}

export default JobModal
