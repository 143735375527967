import { format } from 'date-fns'
import { Form, Formik } from 'formik'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'
import Avatar from '../../components/Avatar'
import CustomButton from '../../components/CustomButton'
import Divider from '../../components/Divider'
import FullPageLoading from '../../components/FullPageLoading'
import Icon from '../../components/Icon'
import LocationIndicator from '../../components/LocationIndicator'
import colors from '../../constants/colors'
import useReloadMessages from '../../hooks/useReloadMessages'
import useSlug from '../../hooks/useSlug'
import { SimpleAppointmentSchedule } from '../../types'
import { VirtualAttendee } from '../../types/swagger'
import api from '../../utils/api'
import { captureError } from '../../utils/errorTracker'
import { _ } from '../../utils/localization'
import { formatJobInfo, formatName } from '../../utils/profile'
import { currentUserSelector } from '../auth/authSlice'
import sendMessage from '../chat/sendMessage'
import { setIsPageLoading } from '../events/pageLoadingSlice'
import { closeNotification, showErrorNotification, showNotification } from '../notifications/notificationsSlice'
import { dateOfAppointmentScheduleSelector, resetAppointmentScheduleState, timeSlotsOfAppointmentScheduleSelector } from './appointmentSchedulesSlice'
import AvailableDates from './components/AvailableDates'
import DayParts from './components/DayParts'
import SubjectAndLocationForm from './components/SubjectAndLocationForm'
import TimeSlots from './components/TimeSlots'
import useFetchAndConvertBlockedTimeSlots from './hooks/useFetchAndConvertBlockedTimeSlots'
import { TimeSlot } from './types'
import { checkConsecutiveArray, convertDateTimeForRequest } from './utils'

export const S = {
    FormWrapper: styled.div``,
    Header: styled.div`
        display: flex;
        justify-content: space-between;
    `,
    UserInfo: styled.div`
        flex-grow: 1;
        display: flex;
        align-items: center;
        gap: 18px;
    `,
    Name: styled.div`
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        color: ${colors.darkTitle};
    `,
    JobTitle: styled.div`
        font-size: 12px;
        font-weight: 500;
        color: ${colors.grayDescription};
    `,
    Actions: styled.div`
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    VerticalDivider: styled.div`
        margin-left: 24px;
        margin-right: 10px;
        width: 0;
        height: 20px;
        border-left: 1px solid ${colors.iconOpacity(0.15)};
        @media (max-width: 932px) {
            display: none;
        }
    `,
    DateTime: styled.span`
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 300;
        color: ${colors.icon};
    `,
    TimeZone: styled.span`
        margin-left: 8px;
        color: ${colors.darkGray};
        font-weight: 400;
    `,
    Info: styled.div`
        display: flex;
        align-items: center;
        @media (max-width: 932px) {
            flex-direction: column;
            align-items: flex-start;
        }
    `
}

const SelectDateTimeForm = ({
    previousSchedule, blockedTimeslots
}: {previousSchedule: SimpleAppointmentSchedule, blockedTimeslots: TimeSlot[]}) => (
    <>
        <AvailableDates previousSchedule={previousSchedule} />
        <DayParts previousSchedule={previousSchedule} />
        <TimeSlots previousSchedule={previousSchedule} unavailableTimeSlots={blockedTimeslots} />
        <Divider marginTop={24} />
    </>
)

const validationSchema = Yup.object().shape({
    locationType: Yup.string(),
    location: Yup.string().when('locationType', {
        is: 'IN_PERSON',
        then: Yup.string().required('Required')
    })
})

interface Props {
    receiver: VirtualAttendee
    close: () => void
    previousSchedule?: SimpleAppointmentSchedule
    callback?: (data?: any) => void
}

const AppointmentScheduleModal = ({
    previousSchedule, receiver, close, callback
}: Props) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const currentUser = useSelector(currentUserSelector())
    const [formStep, setFormStep] = useState(0)
    const selectedDate = useSelector(dateOfAppointmentScheduleSelector)
    const timeSlots = useSelector(timeSlotsOfAppointmentScheduleSelector)
    const { startDate, endDate } = formStep === 1
        ? convertDateTimeForRequest(selectedDate, timeSlots)
        : { startDate: new Date(), endDate: new Date() }
    const timeSlotIds = timeSlots.map((e) => e.id)
    const reloadMessages = useReloadMessages(receiver?.id)
    const initValuesForForm = useMemo(() => ({
        locationType: previousSchedule?.location ? 'IN_PERSON' : 'VIRTUAL',
        location: previousSchedule?.location || '',
        subject: previousSchedule?.subject || '',
    }), [previousSchedule])
    const {isLoading, data: blockedTimeslots} = useFetchAndConvertBlockedTimeSlots(receiver.id)

    const handleClose = () => {
        close()
        dispatch(resetAppointmentScheduleState())
    }

    const goToNextStep = (handleSubmit) => {
        dispatch(closeNotification(49))

        if (formStep === 1) {
            return handleSubmit()
        }

        if (!checkConsecutiveArray(timeSlotIds)) {
            dispatch(showNotification({
                type: 'error',
                body: _('appointment_schedule.time_slots_are_not_consecutive'),
                displayDuration: 3000,
                id: 49,
            }))
            return
        }
        setFormStep(formStep + 1)
    }

    const _handleSubmit = async (values) => {
        dispatch(setIsPageLoading(true))
        const data = {
            subject: values.subject,
            location: values.location,
            start_date: startDate,
            end_date: endDate,
            receiver: receiver.id,
        }
        try {
            let response
            if (!previousSchedule) {
                response = await api.post(`/events/${eventSlug}/appointment_schedules/`, data)
                dispatch(sendMessage({
                    eventSlug,
                    text: 'PENDING',
                    receiverId: receiver.id,
                    currentUserId: currentUser.id,
                    shouldSendEmail: !receiver?.is_online,
                    appointmentSchedule: {
                        ...data,
                        id: response.id,
                    },
                }))
            } else {
                response = await api.post(`/events/${eventSlug}/appointment_schedules/${previousSchedule.id}/reschedule/`, data)
                dispatch(sendMessage({
                    eventSlug,
                    text: 'PENDING',
                    receiverId: receiver.id,
                    currentUserId: currentUser.id,
                    shouldSendEmail: !receiver?.is_online,
                    appointmentSchedule: {
                        ...data,
                        previous_schedule: previousSchedule.id,
                        id: response.id,
                    },
                    callback: reloadMessages,
                }))
            }
            dispatch(showNotification({
                type: 'success',
                body: _('appointment_schedule.schedule_meeting_successfully'),
            }))
            close()
            dispatch(resetAppointmentScheduleState())
            callback?.()
        } catch (error) {
            dispatch(showErrorNotification(_('appointment_schedule.schedule_meeting_failed')))
            captureError(error)
        } finally {
            dispatch(setIsPageLoading(false))
        }
    }

    return (
        <div>
            <Formik
                onSubmit={_handleSubmit}
                initialValues={initValuesForForm}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ handleSubmit, values }) => (
                    <Form>
                        <S.Header>
                            <S.UserInfo>
                                <Avatar src={receiver.mugshot} width={80} />
                                <div>
                                    <S.Name className="notranslate">{formatName(receiver)}</S.Name>
                                    <S.JobTitle>{formatJobInfo(receiver)}</S.JobTitle>
                                    <S.Info>
                                        <LocationIndicator location={values.locationType} />
                                        <S.VerticalDivider />
                                        {formStep === 1 && (
                                            <S.DateTime>
                                                <Icon icon="timetable" size={18} className="mb-2px mr-1" />
                                                {`${format(startDate, 'dd MMM yyyy - HH:mm')}-${format(endDate, 'HH:mm')}`}
                                                <S.TimeZone>
                                                    {Intl.DateTimeFormat().resolvedOptions().timeZone}
                                                </S.TimeZone>
                                            </S.DateTime>
                                        )}
                                    </S.Info>
                                </div>
                            </S.UserInfo>
                            <Icon size={28} icon="close" onClick={handleClose} />
                        </S.Header>

                        {formStep === 0 && (
                            <SelectDateTimeForm
                                previousSchedule={previousSchedule}
                                blockedTimeslots={blockedTimeslots}
                            />
                        )}

                        {formStep === 1 && (
                            <SubjectAndLocationForm />
                        )}

                        <S.Actions>
                            {formStep === 1 ? (
                                <CustomButton variant="secondary" onClick={() => setFormStep(formStep - 1)}>
                                    {_('button.back')}
                                </CustomButton>
                            ) : <div />}
                            <div>
                                <CustomButton variant="secondary" onClick={handleClose}>
                                    {_('button.cancel')}
                                </CustomButton>
                                <CustomButton
                                    variant="primary"
                                    onClick={() => goToNextStep(handleSubmit)}
                                    disabled={formStep === 0 && !timeSlots.length}
                                >
                                    {formStep === 0 ? _('button.next') : _('button.schedule_meeting')}
                                </CustomButton>
                            </div>
                        </S.Actions>
                        {isLoading && <FullPageLoading isAbsolute />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default AppointmentScheduleModal
