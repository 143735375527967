import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'
import { currentUserSelector } from '../../auth/authSlice'
import Message from './Message'
import MessageInput from './MessageInput'
import { Message as MessageType } from '../types'
import sendMessage from '../sendMessage'
import { compareDate } from '../../../utils/datetime'
import {
    selectChatId,
    selectCurrentMessages,
    getMessagesThunk,
    requestMessagesStatus,
    selectingChatBotSelector,
} from '../chatSlice'
import { User } from '../../auth/types'
import FullPageLoading from '../../../components/FullPageLoading'
import GeneralInfoWithThumbnail from '../../../components/GeneralInfoWithThumbnail'
import colors from '../../../constants/colors'
import getMugshotSrc from '../../../utils/getMugshotSrc'
import useFetchAttendees from '../../attendees/useFetchAttendees'
import useSlug from '../../../hooks/useSlug'
import { formatJobInfo } from '../../../utils/profile'

export const byDate = (a: MessageType, b: MessageType) => -compareDate(a.sent_at, b.sent_at)

const S = {
    UserMessage: styled.h3`
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        width: 100%;
        font-weight: bold;
    `,
    Container: styled.div<{noPadding?: boolean}>`
        position: relative;
        height: 100%;
        width: 100%;
        padding-top: ${({ noPadding }) => (noPadding ? 0 : 41)}px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 932px) {
            height: calc(var(--window-height) - 50px);
        }
    `,
    ScrolledContainer: styled.div<{isOnline?: boolean}>`
        height: 100%;
        overflow: auto;
        position: relative;
        @media (max-width: 932px) {
            height: calc(100% - ${({ isOnline }) => (isOnline ? 70 : 100)}px);
        }
    `,
    InfiniteScroll: styled(InfiniteScroll)`
        display: flex;
        flex-direction: column-reverse;
    `,
    UserInfoWrapper: styled.div`
        width: 100%;
        padding: 0 16px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        background: ${colors.gray1};
        .general-info-wrapper {
            background: ${colors.gray1}!important;
        }
    `,
}

const LIMIT = 15

const Chat = () => {
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const chatId = useSelector(selectChatId)
    const messages = useSelector(selectCurrentMessages)
    const currentUser = useSelector(currentUserSelector())
    const requestStatus = useSelector(requestMessagesStatus)
    const selectingChatBot = useSelector(selectingChatBotSelector)
    const [chatUser] = useFetchAttendees([chatId])

    const divRef = useRef<HTMLDivElement>()
    const [shouldScrollBottom, setShouldScrollBottom] = useState(true)
    const [offset, setOffset] = useState(0)
    const hasMore = messages.results.length < messages.count

    const fetchNextMessages = () => {
        setShouldScrollBottom(false)
        setOffset(offset + LIMIT)
        dispatch(getMessagesThunk({
            eventSlug,
            userId: chatId,
            offset: offset + LIMIT
        }))
    }

    // After first load or after receiving a message, scroll message list to bottom
    useEffect(() => {
        if (divRef.current && shouldScrollBottom) {
            divRef.current.scrollTop = divRef.current.scrollHeight
        }
        // No need to watch divRef
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages.results.length])

    const submitMessage = (value: string) => {
        dispatch(sendMessage({
            eventSlug,
            text: value,
            receiverId: chatId,
            currentUserId: currentUser.id,
            shouldSendEmail: !chatUser?.is_online,
        }))
    }

    const getIsSent = (message: MessageType) => message.sent_by_current_user
    const getSender = (message: MessageType): User => (getIsSent(message)
        ? currentUser as User
        : chatUser as User)

    const onScroll = () => {
        const refCurrent = divRef.current

        if (!refCurrent) {
            return
        }

        // When more messages can be loaded, but the scroll top is 0
        // the user can not scroll up to load more messages.
        // With scrollTop to 2px, user can scroll up to load more messages.
        if (refCurrent.scrollTop === 0 && hasMore) {
            refCurrent.scrollTop = 2
        }

        // When scroll height is 100px or more,
        // set shouldScrollBottom to false.
        // Because we don't want to scroll to bottom
        // if the user scrolled up to read older messages
        const isBottom = (refCurrent.scrollHeight - refCurrent.scrollTop - 100) < refCurrent.clientHeight
        if (isBottom) {
            setShouldScrollBottom(true)
        } else {
            setShouldScrollBottom(false)
        }
    }

    if (!currentUser?.id) {
        return null
    }

    return (
        <S.Container noPadding={selectingChatBot}>
            { chatUser && (
                <S.UserInfoWrapper>
                    <GeneralInfoWithThumbnail
                        image={getMugshotSrc(chatUser)}
                        name={`${chatUser?.first_name} ${chatUser?.last_name}`}
                        description={formatJobInfo(chatUser)}
                        size="sm"
                    />
                </S.UserInfoWrapper>
            )}

            <S.ScrolledContainer ref={divRef} id="scrollable-chat-content" isOnline={chatUser?.is_online}>
                <S.InfiniteScroll
                    dataLength={messages.results.length}
                    next={fetchNextMessages}
                    hasMore={hasMore}
                    loader={<FullPageLoading isAbsolute />}
                    inverse
                    scrollableTarget="scrollable-chat-content"
                    onScroll={onScroll}
                >
                    {(requestStatus === 'loading' && messages.results.length === 0) && (
                        <FullPageLoading isAbsolute />
                    )}

                    {/* Copy array otherwise we get readonly error */}
                    {([...messages.results]).sort(byDate).map((message, i) => (
                        <Message
                            key={`${message.id}${i}`}
                            message={message}
                            isSent={getIsSent(message)}
                            sender={getSender(message)}
                            chatUser={chatUser}
                            isSentEmail={message?.sent_email}
                        />
                    ))}
                </S.InfiniteScroll>
            </S.ScrolledContainer>

            {(requestStatus === 'succeeded' || messages.results.length > 0) && (
                <MessageInput
                    send={submitMessage}
                    isOnline={chatUser?.is_online || selectingChatBot}
                    disabled={selectingChatBot}
                />
            )}
        </S.Container>
    )
}

export default Chat
