import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import {
    chat,
    getMessagesThunk,
    PublicMessage,
    selectPublicMessages,
    selectSenderIds,
    sendMessagesThunk,
    openNewChat,
} from './messagesSlice'
import { currentUserSelector } from '../auth/authSlice'
import { compareDate } from '../../utils/datetime'
import Message from './Message'
import Loader from '../../components/Loader'
import useFetchAttendees from '../attendees/useFetchAttendees'
import { VirtualAttendee } from '../../types/swagger'
import MessageInput from './MessageInput'
import useSlug from '../../hooks/useSlug'

const S = {
    Container: styled.div`
        flex-grow: 1;
        flex-basis: 400px;
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 932px) {
            position: relative;
            height: calc(100% - 56px) !important;
        }
    `,
    ScrolledContainer: styled.div`
        height: 100%;
        overflow: auto;
        position: relative;
        padding-bottom: 10px;
    `,
    InfiniteScroll: styled(InfiniteScroll)`
        display: flex;
        flex-direction: column-reverse;
    `,
    LoaderWrapper: styled.div`
        position: absolute;
        top: 50%;
        left: 50%;
    `
}

const LIMIT = 20

const bySent = (a: { sent_at: number }, b: { sent_at: number }) => (
    (a?.sent_at && b?.sent_at)
        ? -compareDate(a.sent_at, b.sent_at)
        : 0
)

const PublicChat = ({ chatId, moderatorId }: { chatId: number, moderatorId?: number }) => {
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const currentUser = useSelector(currentUserSelector())
    const messages = useSelector(selectPublicMessages)
    const [offset, setOffset] = useState(0)
    const divRef = useRef<HTMLDivElement>()
    const [shouldScrollBottom, setShouldScrollBottom] = useState(true)
    const hasMore = messages.results.length < messages.count
    const senderIds = useSelector(selectSenderIds)
    const senders = useFetchAttendees(senderIds)

    // copy array to make it not-readonly
    const sortedMessages = [...messages.results].sort(bySent)

    const submitMessage = (text: string) => {
        setShouldScrollBottom(true)
        divRef.current.scrollTop = divRef.current.scrollHeight
        dispatch(sendMessagesThunk({
            text,
            chatId,
            currentUserId: currentUser.id,
        }))
    }

    const fetchData = () => {
        setShouldScrollBottom(false)
        setOffset(offset + LIMIT)
        dispatch(getMessagesThunk({
            eventSlug,
            chatId,
            offset: offset + LIMIT,
            limit: LIMIT
        }))
    }
    const checkIsSent = (message: PublicMessage) => message.sender === currentUser.id

    const getSender = (message: PublicMessage): VirtualAttendee => {
        if (message.sender === currentUser.id) {
            return currentUser
        }
        return senders.find((e) => e.id === message.sender)
    }

    const onScroll = () => {
        const refCurrent = divRef.current

        if (!refCurrent) {
            return
        }

        // When more messages can be loaded, but the scroll top is 0
        // the user can not scroll up to load more messages.
        // With scrollTop to 2px, user can scroll up to load more messages.
        if (refCurrent.scrollTop === 0 && hasMore) {
            refCurrent.scrollTop = 2
        }

        // When scroll height is 100px or more,
        // set shouldScrollBottom to false.
        // Because we don't want to scroll to bottom
        // if the user scrolled up to read older messages
        const isBottom = (refCurrent.scrollHeight - refCurrent.scrollTop - 100) < refCurrent.clientHeight
        if (isBottom) {
            setShouldScrollBottom(true)
        } else {
            setShouldScrollBottom(false)
        }
    }

    useEffect(() => {
        // First load, after fetching messages, scroll message list to bottom
        if (divRef.current && shouldScrollBottom) {
            divRef.current.scrollTop = divRef.current.scrollHeight
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages.results.length])

    // Subscribe / unsubscribe to chat updates
    useEffect(() => {
        dispatch(chat.subscribe(chatId))
        return () => {
            dispatch(chat.unsubscribe(chatId))
        }
    }, [chatId, dispatch])

    // Clear messages of other chats and set chat ID to reducer
    useEffect(() => {
        dispatch(openNewChat(chatId))
    }, [chatId, dispatch])

    // Fetch/clear messages
    useEffect(() => {
        dispatch(getMessagesThunk({
            eventSlug,
            chatId,
            offset,
            limit: LIMIT
        }))
    }, [eventSlug, chatId, dispatch, offset])

    return (
        <S.Container>
            <S.ScrolledContainer
                id="scrollableDiv"
                ref={divRef}
            >
                <S.InfiniteScroll
                    dataLength={messages.results.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<S.LoaderWrapper><Loader /></S.LoaderWrapper>}
                    inverse
                    scrollableTarget="scrollableDiv"
                    onScroll={onScroll}
                >
                    {sortedMessages.map((message, i) => (
                        <Message
                            key={`${message.id}${i}`}
                            message={message}
                            sender={getSender(message)}
                            isSent={checkIsSent(message)}
                            moderatorId={moderatorId}
                        />
                    ))}
                </S.InfiniteScroll>
            </S.ScrolledContainer>
            <MessageInput send={submitMessage} />
        </S.Container>
    )
}

export default PublicChat
