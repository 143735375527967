import Goodiebag from './views/Goodiebag'
import { createEventRoute } from '../../utils/routes'

export default {
    goodiebag: createEventRoute({
        title: 'Goodiebag',
        path: '/goodiebag',
        exact: true,
        component: Goodiebag,
    }),
}
