import React from 'react'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../store'
import EditProfileForm from './EditProfileForm'
import Background from '../../assets/profile_background.jpg'
import Icon from '../../components/Icon/Icon'
import { preventHideProfileSelector } from './editProfileSlice'
import ProfileImage from './ProfileImage'
import { _ } from '../../utils/localization'
import colors from '../../constants/colors'

const S = {
    Wrapper: styled.div`
        
    `,
    HeaderSection: styled.div`
        position: relative;
        height: 110px;
        width: 100%;
        margin-bottom: 38px;
        background: url(${Background});
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
        color: ${colors.icon};
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    `,
    Avatar: styled.img`
        position: absolute;
        bottom: 0;
        left: 35px;
        transform: translateY(50%);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 5px solid white;
        background-color: white;
    `,
    CloseIcon: styled.div`
        position: absolute;
        top: 24px;
        right: 24px;
    `,
    Form: styled(Form)`
        width: 100%;
        padding: 20px 30px;
    `,
}

const ProfileBadge = ({close}: {close: () => void}) => {
    const profile = useSelector((state: RootState) => state.auth.user)
    const preventHideProfile = useSelector(preventHideProfileSelector)
    const _close = preventHideProfile ? () => null : close

    return (
        <S.Wrapper>
            <S.HeaderSection>
                <span>{_('profile.edit_your_profile')}</span>
                <ProfileImage profileImage={profile?.mugshot} userId={profile?.id} />
                <S.CloseIcon>
                    <Icon icon="close" size={28} onClick={_close} />
                </S.CloseIcon>
            </S.HeaderSection>
            <S.Form>
                {profile
                    && (
                        <EditProfileForm profile={profile} close={close} />
                    )}
            </S.Form>
        </S.Wrapper>
    )
}

export default ProfileBadge
