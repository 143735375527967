import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    Collapse,
    Divider,
    List,
    ListItemButton,
    ListItemText,
} from '@mui/material'
import { sortBy } from 'lodash'
import { NavItem, NavSpace } from '../Nav/SpaceNavItem'
import useParams from '../../hooks/useParams'
import { allUpdatedSpacesSelector } from '../../features/spaces/spaceSlice'
import getPathForSpace from '../../utils/spaces/getPathForSpace'
import Icon from '../Icon'
import colors from '../../constants/colors'

const S = {
    Wrapper: styled.div<{ isOpen?: boolean }>`
        color: ${colors.grayTitle};
        background-color: ${({ isOpen }) => (isOpen ? colors.gray1 : 'white')};
        & span {
            font-size: 14px;
        }
        .parent-menu > span {
            font-weight: 600;
            letter-spacing: 1;
        }
    `,
    MenuHeader: styled.div<{ isOpen?: boolean }>`
        flex: 1;
        font-size: 14px;
        color: ${colors.grayTitle};
        font-weight: ${({ isOpen }) => (isOpen ? 600 : 400)};
    `,
    Link: styled(Link)`
        color: ${colors.grayTitle};
        &:hover,
        &:active {
            color: ${colors.grayTitle};
        }
    `,
    Icon: styled(Icon)<{ isShow: boolean }>`
        margin: 0 -4px 2px 6px;
        transform: rotate(${({ isShow }) => (isShow ? '-180deg' : 0)});
        transition: 0.3s;
    `,
    SubMenuHeader: styled.div`
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        color: ${colors.iconOpacity(0.75)};
    `,
}

const SpaceNavItemMobile = ({
    item,
    onCloseSidebar,
}: {
    item: NavItem
    onCloseSidebar: () => void
}) => {
    const hasSubMenu = !!item.sub_menus
    const [showSubMenu, setShowSubMenu] = useState(false)
    const params = useParams()
    if (item.linked_space && item.space.type === 'booth') {
        params.mapSlug = item.linked_space.slug
    }
    const updatedSpaces = useSelector(allUpdatedSpacesSelector)
    const getPublishedState = (space: NavSpace) => (updatedSpaces[space.id]
        ? updatedSpaces[space.id].published
        : space.published)

    return (
        <S.Wrapper isOpen={showSubMenu}>
            {hasSubMenu ? (
                <>
                    <ListItemButton
                        onClick={() => setShowSubMenu(!showSubMenu)}
                    >
                        <S.MenuHeader isOpen={showSubMenu}>
                            {item.text}
                        </S.MenuHeader>
                        <S.Icon
                            isShow={showSubMenu}
                            icon="chevronDown"
                            color={colors.navText}
                            size={16}
                        />
                    </ListItemButton>
                    <Collapse in={showSubMenu} timeout="auto" unmountOnExit>
                        <List component="div" sx={{pl: 2}}>
                            {sortBy(item.sub_menus, 'order').map((_item) => (
                                <React.Fragment key={_item.id}>
                                    <S.SubMenuHeader>
                                        {_item.text}
                                    </S.SubMenuHeader>
                                    {sortBy(_item?.items, 'order').map((e, index) => {
                                        if (
                                            e.linked_space
                                            && e.space.type === 'booth'
                                        ) {
                                            params.mapSlug = e.linked_space.slug
                                        }

                                        return (
                                            getPublishedState(e.space) && (
                                                <S.Link
                                                    key={index}
                                                    to={getPathForSpace(
                                                        e.space,
                                                        params
                                                    )}
                                                    onClick={onCloseSidebar}
                                                >
                                                    <ListItemButton>
                                                        <ListItemText
                                                            primary={e.text}
                                                        />
                                                    </ListItemButton>
                                                </S.Link>
                                            )
                                        )
                                    })}
                                </React.Fragment>
                            ))}
                        </List>
                    </Collapse>
                    <Divider />
                </>
            ) : (
                getPublishedState(item.space) && (
                    <>
                        <S.Link
                            to={getPathForSpace(item.space, params)}
                            onClick={onCloseSidebar}
                        >
                            <ListItemButton>
                                <ListItemText primary={item.text} />
                            </ListItemButton>
                        </S.Link>
                        <Divider />
                    </>
                )
            )}
        </S.Wrapper>
    )
}

export default SpaceNavItemMobile
