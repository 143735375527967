import React from 'react'
import useRedirectToRoom from './useRedirectToRoom'
import { _ } from '../../utils/localization'
import CustomPopover from '../../components/CustomPopover'
import AttendeeList from '../networking/components/AttendeeList'
import { Videocall } from '../../types/swagger'
import useRequestAccess from '../../hooks/useRequestAccess'
import { CallConfig } from './types'

interface RequestAccessProps {
    videocall: Videocall
    close: () => void
    config: CallConfig
}

const RequestAccess = ({ videocall, close, config }: RequestAccessProps) => {
    const redirect = useRedirectToRoom(videocall.twilio_name, config)
    const {
        isRequestDenied,
        isAccessRequestSent,
        requestVideocallAccess,
        cancelAccessRequest
    } = useRequestAccess({ videocallId: videocall.id, redirect, close })

    const cancelAndClose = () => {
        cancelAccessRequest()
        close()
    }

    if (isRequestDenied) {
        return (
            <CustomPopover
                title={_('networking.popovers.sorry')}
                text={_('networking.popovers.request_declined')}
                cancelButton={_('button.cancel')}
                onCancel={close}
                cancelIcon="close"
            />
        )
    }

    if (isAccessRequestSent) {
        return (
            <CustomPopover
                title={_('networking.popovers.request_sent')}
                text={_('networking.popovers.waiting_for_response')}
                cancelButton={_('button.cancel')}
                onCancel={cancelAndClose}
                cancelIcon="close"
            />
        )
    }

    return (
        <CustomPopover
            title={`${_('networking.popovers.request_to_join')} '${videocall.display_name}'`}
            cancelButton={_('networking.popovers.not_now_button')}
            confirmButton={_('networking.popovers.request_to_join_button')}
            onCancel={close}
            cancelIcon="close"
            onConfirm={requestVideocallAccess}
            confirmIcon="check"
        >
            <AttendeeList attendees={videocall.users} />
        </CustomPopover>
    )
}

export default RequestAccess
