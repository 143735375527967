import React, { useState } from 'react'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/cjs/ListGroup'
import { useDispatch } from 'react-redux'

import { InputGroup } from 'react-bootstrap'
import { ListJobOpenings2Response } from '../../types/__generated_swagger'
import LogoPlaceholder from '../../assets/logo-placeholder.webp'
import GeneralInfoWithThumbnail
    from '../../components/GeneralInfoWithThumbnail'
import Icon from '../../components/Icon'
import LoadMore from '../../components/LoadMore'

import { _ } from '../../utils/localization'
import Get from '../../components/Get'
import colors from '../../constants/colors'
import useSlug from '../../hooks/useSlug'
import styles from '../../constants/styles'
import { addModal } from '../modals/modalSlice'
import { Job } from './types'
import JobModal from './JobModal'

const S = {
    SideBar: styled.div`
        background: white;
        display: flex;
        flex-direction: column;
        width: ${styles.sidebarWidth}px;
        height: calc(100% - ${styles.topNavHeight}px - 10px); // 10px of timetable's border
        position: fixed;
        right: 0;
        @media (max-width: 1366px) {
            width: ${styles.smallerSidebarWidth}px;
        }
    `,
    SideBarTop: styled.div`
        background: ${colors.gray8};
        width: 100%;
        padding: 20px;
    `,
    Title: styled.p`
        font-size: 20px;
        font-weight: 500;
        color: white;
    `,
    SearchGroup: styled(Form.Group)`
        position: relative;
        margin-bottom: 20px;
    `,
    SearchInput: styled(Form.Control)`
        padding-right: 35px;
        &::placeholder {
            font-style: italic;
        }
    `,
    SearchIcon: styled.div`
        position: absolute;
        top: 4px;
        right: 5px;
    `,
    ListGroup: styled(ListGroup)`
        flex: 1 1 auto;
        overflow-y: auto;
    `
}

const SideBar = () => {
    const initSearch = new URLSearchParams(window.location.search).get('search')
    const boothSlug = new URLSearchParams(window.location.search).get('booth_slug')
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const [search, setSearch] = useState(initSearch || '')
    const [limit, setLimit] = useState(10)
    const loadMore = () => setLimit(limit + 10)
    const url = `/events/${eventSlug}/jobs/`

    const createOpenJob = (job: Job) => () => {
        const Component = ({ close }: { close: () => void }) => (
            <JobModal job={job} close={close} />
        )
        dispatch(addModal({
            Component,
            padding: 0,
        }))
    }

    return (
        <S.SideBar>
            <S.SideBarTop>
                <S.Title>
                    {_('jobs.sidebar.title')}
                </S.Title>

                <InputGroup>
                    <Form.Control
                        placeholder={_('jobs.sidebar.search_placeholder')}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <S.SearchIcon>
                        <Icon icon="search" size={22} />
                    </S.SearchIcon>
                </InputGroup>
            </S.SideBarTop>

            <Get url={url} params={{ search, limit, booth_slug: boothSlug }}>
                {(resp: ListJobOpenings2Response) => (
                    <>
                        <S.ListGroup>
                            {resp.results.map((job) => (
                                <GeneralInfoWithThumbnail
                                    name={job.title}
                                    image={job.booth.logo || LogoPlaceholder}
                                    description={job.booth.name}
                                    onClick={createOpenJob(job)}
                                />
                            ))}
                            {(resp.count > limit) && (
                                <LoadMore onClick={loadMore}>
                                    {_('jobs.sidebar.load_more')}
                                </LoadMore>
                            )}
                        </S.ListGroup>
                    </>
                )}
            </Get>
        </S.SideBar>
    )
}

export default SideBar
