import { format } from 'date-fns'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import Divider from '../../../components/Divider'
import colors from '../../../constants/colors'
import { SimpleAppointmentSchedule } from '../../../types'
import { _ } from '../../../utils/localization'
import {
    dateOfAppointmentScheduleSelector,
    dayPartOfAppointmentScheduleSelector,
    timeSlotsOfAppointmentScheduleSelector,
    updateScheduleTimeSlots,
} from '../appointmentSchedulesSlice'
import { TimeSlot } from '../types'
import {
    checkIsInDuration, convertItemToTimeSlot, getAllTimeSlotsByDayPart, updateScheduledTimeSlots
} from '../utils'

export const S = {
    Header: styled.h1`
        font-size: 16px;
        font-weight: 500;
        color: ${colors.darkTitle};
    `,
    ItemsWrapper: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        
    `,
    Item: styled.div<{isScheduled?: boolean, isBlocked?: boolean, isActive?: boolean}>`
        padding: 8px 16px;
        background-color: white;
        border-radius: 20px;
        border: 1px solid ${colors.iconOpacity(0.16)};
        font-size: 14px;
        color: ${colors.darkTitle};
        cursor: pointer;
        user-select: none;
        opacity: ${({ isBlocked }) => (isBlocked ? 0.4 : 1)};
        text-transform: capitalize;
        pointer-events: ${({ isBlocked }) => (isBlocked ? 'none' : 'auto')};;

        &:hover {
            background-color: ${colors.primaryOpacity(0.05)};
        }

        ${({ isScheduled }) => isScheduled && css`
            background-color: ${colors.gray1};
            border: 1px dashed ${colors.primary};
        `}

        ${({ isActive }) => isActive && css`
            border: 1px solid ${colors.primary};
            background-color: ${colors.primary} !important;
            color: white;
        `}
    `
}

interface Props {
    previousSchedule?: SimpleAppointmentSchedule
    unavailableTimeSlots?: TimeSlot[] // All time slots are already in the sender/recevier's personal schedule
}

const TimeSlots = ({
    unavailableTimeSlots = [], previousSchedule
}: Props) => {
    const dispatch = useDispatch()
    const dayPart = useSelector(dayPartOfAppointmentScheduleSelector)
    const selectedDate = useSelector(dateOfAppointmentScheduleSelector)
    const selectedTimeSlots = useSelector(timeSlotsOfAppointmentScheduleSelector)
    const selectedTimeSlotIds = selectedTimeSlots.map((e) => e.id)
    const allTimeSlotsByDayPart = getAllTimeSlotsByDayPart(dayPart)
    const availableTimeSlots = allTimeSlotsByDayPart
        .map(updateScheduledTimeSlots(previousSchedule && convertItemToTimeSlot(previousSchedule)))
        .map((timeslot) => {
            if (!unavailableTimeSlots.length) return timeslot
            if (unavailableTimeSlots.some((e) => checkIsInDuration(timeslot, e, selectedDate))) {
                timeslot.isBlocked = true
            }
            return timeslot
        })

    const handleClickItem = (timeslot: TimeSlot) => {
        if (selectedTimeSlotIds.includes(timeslot.id)) {
            dispatch(updateScheduleTimeSlots(selectedTimeSlots.filter((e) => e.id !== timeslot.id)))
        } else {
            dispatch(updateScheduleTimeSlots([...selectedTimeSlots, timeslot]))
        }
    }

    return (
        <>
            <Divider className="mt-4" />
            <S.Header>{_('appointment_schedule.choose_time_slots')}</S.Header>
            <S.ItemsWrapper>
                {availableTimeSlots.map((timeslot, i) => (
                    <S.Item
                        isScheduled={timeslot.isScheduled}
                        isBlocked={timeslot.isBlocked}
                        isActive={selectedTimeSlotIds.includes(timeslot.id)}
                        key={i}
                        onClick={() => !timeslot.isBlocked && handleClickItem(timeslot)}
                    >
                        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                        {format(timeslot.start, 'HH:mm')} - {format(timeslot.end, 'HH:mm')}
                    </S.Item>
                ))}
            </S.ItemsWrapper>
        </>
    )
}

export default TimeSlots
