import React, { useEffect, useState } from 'react'
import { Form, FormCheck, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'react-bootstrap/cjs/Button'
import GeneralInfoWithThumbnail from '../../../../components/GeneralInfoWithThumbnail'
import colors from '../../../../constants/colors'
import { _ } from '../../../../utils/localization'
import { currentUserSelector } from '../../../auth/authSlice'
import { leaveBusinessCard, RequestBody } from '../../leaveBusinessCardSlice'
import useSlug from '../../../../hooks/useSlug'
import { RootState } from '../../../../store'
import FullPageLoading from '../../../../components/FullPageLoading'
import { VirtualAttendee } from '../../../../types/swagger'
import { formatJobInfo } from '../../../../utils/profile'
import { BoothBusinessCard } from '../../types'
import Alert from '../../../../components/Alert'
import BaseTooltip from '../../../../components/BaseTooltip'
import { getLeftBusinessCardKey } from './BoothDetail'
import { fetchBoothDetail } from '../../boothDetailSlice'

const S = {
    Wrapper: styled.div`
        padding: 20px;
    `,
    Header: styled.h3`
        margin: 0;
        font-size: 30px;
        font-weight: 500;
        @media (max-width: 932px) {
            font-size: 20px;
        }
    `,
    Divider: styled.div`
        width: 100%;
        height: 1px;
        margin: 20px 0;
        background-color: ${colors.gray2};
    `,
    ToggleWrapper: styled(Form.Group)`
        display: flex;
        padding-left: 12px;
    `,
    ToggleLabel: styled.div`
        margin-left: 10px;
        font-size: 14px;
        line-height: 24px;
    `,
    Toggle: styled(FormCheck)`
        transform: scale(1.5);
    `,
    Label: styled.p`
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
        color: ${colors.gray7};
    `,
    Actions: styled.div`
        display: flex;
        justify-content: flex-end;
    `
}

const renderOption = (staff) => ({
    value: staff.id,
    label: <GeneralInfoWithThumbnail
        name={`${staff.first_name} ${staff.last_name}`}
        description={formatJobInfo(staff)}
        image={staff.mugshot}
    />
})

interface Props {
    staffs: VirtualAttendee[]
    close: () => void
    businesscard?: BoothBusinessCard
}

const LeaveBusinessCardModal = ({ staffs, close, businesscard }: Props) => {
    const eventSlug = useSlug('eventSlug')
    const spaceSlug = useSlug('boothSlug')
    const [includedEmail, setIncludedEmail] = useState(true)
    const [ignoreIncognitoMode, setIgnoreIncognitoMode] = useState(false)
    const [note, setNote] = useState('')
    const options = staffs.map((staff) => renderOption(staff))
    const [selectedOption, setSelectedOption] = useState({
        label: _('booth.leave_businesscard.leave_for_all_staffs'), // Default value of selector is all booth staffs
        value: null,
    })

    const currentUser = useSelector(currentUserSelector())
    const dispatch = useDispatch()
    const requestStatus = useSelector((state: RootState) => state.exhibition.leaveBusinessCard.status)

    useEffect(() => {
        if (businesscard) {
            if (!businesscard.is_auto) {
                setIncludedEmail(!!businesscard.user_email)
            }
            setIgnoreIncognitoMode(businesscard.ignore_incognito_mode)
            setNote(businesscard.message)
            // @ts-ignore
            setSelectedOption(options.find((e) => e.value === businesscard.assigned_to))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(businesscard)])

    const handleChange = (_selectedOption: any) => {
        setSelectedOption(_selectedOption)
    }

    const sendToBoothStaffs = () => {
        const requestBody: RequestBody = {
            include_email: includedEmail,
            message: note,
            staff: selectedOption?.value,
            is_auto: false,
            ignore_incognito_mode: ignoreIncognitoMode,
        }
        dispatch(leaveBusinessCard({ eventSlug, spaceSlug, requestBody }))
        localStorage.setItem(getLeftBusinessCardKey(eventSlug, spaceSlug), 'true')
        setTimeout(() => dispatch(fetchBoothDetail({eventSlug, boothSlug: spaceSlug})), 1500)
        close()
    }

    return (
        <S.Wrapper>
            <Modal.Header closeButton style={{ padding: 0, border: 'none' }}>
                <S.Header>{_('booth.leave_businesscard.title')}</S.Header>
            </Modal.Header>
            {businesscard && !businesscard.is_auto && (
                <Alert
                    text={_('booth.leave_businesscard.already_left_businesscard_message')}
                    variant="info"
                    className="mt-4"
                />
            )}
            <S.Divider />
            <GeneralInfoWithThumbnail
                name={`${currentUser.first_name} ${currentUser.last_name}`}
                description={formatJobInfo(currentUser)}
                image={currentUser.mugshot}
                style={{ userSelect: 'none' }}
            />
            <S.Divider />
            <S.ToggleWrapper controlId="include-email-toggle">
                {businesscard?.user_email ? (
                    <BaseTooltip text={_('booth.leave_businesscard.already_included_email_tooltip')} placement="right">
                        <S.Toggle
                            type="switch"
                            label=""
                            checked={includedEmail}
                            onChange={() => null}
                            disabled
                        />
                    </BaseTooltip>
                ) : (
                    <S.Toggle
                        type="switch"
                        label=""
                        checked={includedEmail}
                        onChange={() => setIncludedEmail(!includedEmail)}
                    />
                )}
                <S.ToggleLabel>{_('booth.leave_businesscard.include_email')}</S.ToggleLabel>
            </S.ToggleWrapper>

            <S.ToggleWrapper controlId="ignore-incognito-toggle">
                <BaseTooltip text={_('booth.leave_businesscard.ignore_incognito_mode_tooltip')} placement="right">
                    <S.Toggle
                        type="switch"
                        label=""
                        checked={ignoreIncognitoMode}
                        onChange={() => setIgnoreIncognitoMode(!ignoreIncognitoMode)}
                    />
                </BaseTooltip>
                <S.ToggleLabel>{_('booth.leave_businesscard.ignore_incognito_mode')}</S.ToggleLabel>
            </S.ToggleWrapper>
            <S.Label>{_('booth.leave_businesscard.select_booth_staffs')}</S.Label>
            <Select
                value={selectedOption}
                onChange={handleChange}
                // @ts-ignore
                options={options}
                styles={{
                    control: (styles) => ({ ...styles, height: '60px', marginBottom: 20 }),
                    valueContainer: (styles) => ({ ...styles, height: '60px' }),
                    menuPortal: (styles) => ({ ...styles, borderRadius: '4px' }),
                }}
                isDisabled={!!businesscard?.assigned_to}
            />
            <S.Label>{_('booth.leave_businesscard.leave_note')}</S.Label>
            <Form.Control
                as="textarea"
                rows={6}
                className="form-control form-control-sm mb-3"
                value={note}
                onChange={(e) => setNote(e.target.value)}
            />
            <S.Actions>
                <Button
                    size="sm"
                    variant="success"
                    style={{ padding: '5px 20px' }}
                    onClick={sendToBoothStaffs}
                >
                    {_('booth.leave_businesscard.send_button')}
                </Button>
                <Button
                    size="sm"
                    variant="light"
                    style={{ marginLeft: 10, border: colors.outlineButton, padding: '5px 20px' }}
                    onClick={close}
                >
                    {_('button.cancel')}
                </Button>
            </S.Actions>
            {requestStatus === 'loading' && <FullPageLoading isAbsolute />}
        </S.Wrapper>
    )
}

export default LeaveBusinessCardModal
