import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Participant as ParticipantType } from '../useTwilio'
import ScreenDivider from './ScreenDivider'
import useFetchAttendees from '../../attendees/useFetchAttendees'
import Participant from './Participant'

const S = {
    RemoteParticipant: styled.div`
    position: relative;
    max-height: 100%;
    height: 100%;
    max-width: 100%;
    flex-grow: 1;
    display: block;
`,
    ParticipantName: styled.h5`
    position: absolute;
    top: 12px;
    left: 0;
    padding-left: 12px;
    background: rgba(98, 98, 98, 0.9);
    color: white;
    width: 250px;
    overflow: hidden; 
`,
    MutedIndicator: styled.h4`
    position: absolute;
    bottom: 12px;
    left: 12px;
`,
}

const RemoteParticipant = ({
    participant,
}: {
    participant: ParticipantType,
}) => {
    const [isAudioEnabled, setIsAudioEnabled] = useState(true)
    const [attendee] = useFetchAttendees([+participant.identity], true)

    const handleMute = (track) => {
        track.on('disabled', () => {
            setIsAudioEnabled(false)
        })
        track.on('enabled', () => {
            setIsAudioEnabled(true)
        })
    }

    useEffect(() => {
        participant.tracks.forEach((publication) => {
            if (publication.isSubscribed && publication.kind === 'audio') {
                handleMute(publication.track)
            }
            publication.on('subscribed', handleMute)
        })
    }, [participant.tracks])

    return (
        <S.RemoteParticipant>
            <S.ParticipantName className="notranslate">
                {attendee?.first_name}
                {' '}
                {attendee?.last_name}
            </S.ParticipantName>

            {!isAudioEnabled && <S.MutedIndicator>Muted</S.MutedIndicator>}

            <Participant
                participant={participant}
                hidden={false}
            />
        </S.RemoteParticipant>
    )
}

const ParticipantsLayout = ({ participants }: { participants: ParticipantType[] }) => (
    <ScreenDivider>
        {participants.map((participant) => (
            <RemoteParticipant
                participant={participant}
                key={participant.sid}
            />
        ))}
    </ScreenDivider>
)

export default ParticipantsLayout
