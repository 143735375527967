import React from 'react'
import Icon from '../Icon'
import { IconProps } from '../Icon/Icon'

const NavIcon = (props: IconProps) => (
    <span style={{ marginRight: 8 }}>
        <Icon size={props.size || 18} {...props} />
    </span>
)

export default NavIcon
