import {
    add, endOfDay, format, isWithinInterval, setHours, setMinutes, setSeconds, startOfDay, sub
} from 'date-fns'
import { groupBy, sortBy } from 'lodash'
import { ScheduleStatus } from '../../types'
import { AppointmentSchedule, AppointmentScheduleListResponse } from '../../types/swagger'
import { isInBetween } from '../../utils/datetime'
import { _ } from '../../utils/localization'
import { DayPart, TimeSlot } from './types'

const splitTimeByFifteenMinutes = (startTime: Date, endTime: Date) => {
    const timeSlots: TimeSlot[] = []
    let start = new Date(startTime.getTime())
    let id = 0
    while (start < endTime) {
        timeSlots.push({
            id,
            start,
            end: add(start, { minutes: 15 }),
        })
        start = add(start, { minutes: 15 })
        id += 1
    }
    return timeSlots
}

export const getAllTimeSlotsByDayPart = (daypart: DayPart) => {
    const today = startOfDay(new Date())

    if (daypart === 'NIGHT') {
        const startTime = setHours(today, 0)
        const endTime = setHours(today, 6)
        return splitTimeByFifteenMinutes(startTime, endTime)
    }
    if (daypart === 'MORNING') {
        const startTime = setHours(today, 6)
        const endTime = setHours(today, 12)
        return splitTimeByFifteenMinutes(startTime, endTime)
    }
    if (daypart === 'AFTERNOON') {
        const startTime = setHours(today, 12)
        const endTime = setHours(today, 18)
        return splitTimeByFifteenMinutes(startTime, endTime)
    }
    if (daypart === 'EVENING') {
        const startTime = setHours(today, 18)
        const endTime = setHours(today, 24)
        return splitTimeByFifteenMinutes(startTime, endTime)
    }

    return []
}

export const convertItemToTimeSlot = (item) => ({
    start: new Date(item.start_date),
    end: new Date(item.end_date),
})

export const checkIsInDuration = (timeslot: TimeSlot, comparedTime: TimeSlot, selectedDate: Date) => {
    const timeslotByComparedTime: TimeSlot = {
        ...timeslot,
        start: setMinutes(
            setHours(selectedDate, timeslot.start.getHours()),
            timeslot.start.getMinutes()
        ),
        end: setMinutes(setHours(selectedDate, timeslot.end.getHours()), timeslot.end.getMinutes()),
    }
    return isInBetween(
        comparedTime.start,
        sub(comparedTime.end, { seconds: 1 }),
        timeslotByComparedTime.start
    )
}

export const updateScheduledTimeSlots = (comparedTime: TimeSlot) => (timeslot: TimeSlot) => {
    if (!comparedTime) {
        return timeslot
    }

    if (checkIsInDuration(timeslot, comparedTime, new Date(comparedTime.start))) {
        timeslot.isScheduled = true
    }

    return timeslot
}

export const getAvailableDates = (startDate: Date, endDate: Date, selectedDate?: Date) => {
    const today = new Date()
    if (today > endDate) {
        return []
    }

    let start = new Date(startDate) || new Date(selectedDate)

    if (today > startDate) {
        start = today
    }

    const availableDates = []

    while (start < add(endDate, { minutes: 1 })) {
        availableDates.push(startOfDay(start))
        start = add(start, { days: 1 })
    }

    return availableDates
}

export const checkConsecutiveArray = (array: number[]) => {
    if (array.length <= 1) {
        return true
    }

    const sortedArray = array.sort((a, b) => a - b)
    return sortedArray.every((element, i) => i === 0 || +element === +array[i - 1] + 1)
}

export const convertDateTimeForRequest = (date: Date, timeslots: TimeSlot[]) => {
    if (!timeslots.length) {
        return {
            startDate: new Date(),
            endDate: new Date(),
        }
    }

    const startTime = timeslots[0].start
    const endTime = timeslots[timeslots.length - 1].end

    let startDate = setHours(date, startTime.getHours())
    startDate = setMinutes(startDate, startTime.getMinutes())
    startDate = setSeconds(startDate, 0)

    let endDate = setHours(date, endTime.getHours())
    endDate = setMinutes(endDate, endTime.getMinutes())
    endDate = setSeconds(endDate, 0)

    return {
        startDate,
        endDate,
    }
}

export const convertDateToDayPart = (date: Date): DayPart => {
    const startOfDate = startOfDay(date)

    const lowerBoundOfNight = startOfDay(date)
    const upperBoundOfNight = setHours(startOfDate, 6)
    const lowerBoundOfMorning = setHours(startOfDate, 6)
    const upperBoundOfMorning = setHours(startOfDate, 12)
    const lowerBoundOfAfternoon = setHours(startOfDate, 12)
    const upperBoundOffternoon = setHours(startOfDate, 18)
    const lowerBoundOfEvening = setHours(startOfDate, 18)
    const upperBoundOfEvening = endOfDay(date)

    if (isWithinInterval(
        date,
        { start: lowerBoundOfMorning, end: sub(upperBoundOfMorning, {seconds: 1}) }
    )) {
        return 'MORNING'
    }
    if (isWithinInterval(
        date,
        { start: lowerBoundOfAfternoon, end: sub(upperBoundOffternoon, {seconds: 1}) }
    )) {
        return 'AFTERNOON'
    }
    if (isWithinInterval(
        date,
        { start: lowerBoundOfEvening, end: sub(upperBoundOfEvening, {seconds: 1}) }
    )) {
        return 'EVENING'
    }
    if (isWithinInterval(
        date,
        { start: lowerBoundOfNight, end: sub(upperBoundOfNight, {seconds: 1}) }
    )) {
        return 'NIGHT'
    }
}

export const groupAppointmentsByDate = (appointments: AppointmentScheduleListResponse['results']) => {
    const convertedAppointments = (appointments || []).map((e) => ({
        ...e,
        date: format(new Date(e.start_date), 'dd LLL yyyy'),
    }))

    const groupedAppointments = groupBy(convertedAppointments, 'date')

    return sortBy(Object.keys(groupedAppointments), (e) => new Date(e)).reduce((obj, key) => {
        obj[key] = groupedAppointments[key]
        return obj
    }, {})
}

export const getStatusTranslation = (status: ScheduleStatus) => ({
    PENDING: _('appointment_schedule.request_status.pending'),
    DENIED: _('appointment_schedule.request_status.denied'),
    ACCEPTED: _('appointment_schedule.request_status.accepted'),
    CANCELLED: _('appointment_schedule.request_status.cancelled'),
}[status])

export const showJoinButton = (appointment: AppointmentSchedule) => (
    isInBetween(sub(new Date(appointment.start_date), {minutes: 5}), appointment.end_date, new Date())
)

export const heightMapper = {
    S: 10,
    M: 20,
    L: 40,
}
