import CinemaView from './views/CinemaView'
import { createEventRoute } from '../../utils/routes'

export default {
    cinema: createEventRoute({
        title: 'Cinema',
        path: '/cinema/:cinemaSlug',
        exact: true,
        component: CinemaView,
    }),
}
