import React from 'react'
import twilio from 'twilio-video'
import Bowser from 'bowser'
import { Badge, Table } from 'react-bootstrap'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import useInterval from '../../hooks/useInterval'
import { isScreenShareSupported } from '../videocall/useTwilio'
import { _ } from '../../utils/localization'
import colors from '../../constants/colors'
import Icon from '../../components/Icon'

const SupportedBadge = ({ supported }: { supported: boolean }) => (
    <Badge variant={supported ? 'success' : 'danger'}>
        {
            supported
                ? _('browser_support.supported')
                : _('browser_support.not_supported')
        }
    </Badge>
)

const useIsMatomoAvailable = () => {
    // Re-render every 250ms in case Matomo was loaded in the meantime
    useInterval(250)
    // @ts-ignore
    return window?.Matomo?.initialized
}

const browser = Bowser.getParser(navigator.userAgent)

const S = {
    Page: styled.div`
      display: flex;
      flex-direction: column;
      padding: 60px;
      justify-content: center;
    `,
    GoBack: styled.div`
        margin-top: 40px;
    `,
    TroubleshootGuide: styled.div`
        margin-top: 80px;
    `,
    Table: styled(Table)`
      margin-top: 36px;
      max-width: 500px;
    `,
    Header: styled.h1`
      color: ${colors.gray6};
      font-weight: bold;
      margin-bottom: 36px;
    `,
    SupportsInfo: styled.h5`
      color: ${colors.gray8};
      margin-top: 36px;
    `,
}

const getBrowserRecommendation = () => {
    const isUsingRecommendedBrowser = browser.satisfies({
        chrome: '>1',
        edge: '>1',
        firefox: '>1',
    })
    const hasUpToDateBrowser = browser.satisfies({
        chrome: '>83',
        edge: '>40',
        firefox: '>80',
    })
    const isUsingOutdatedBrowser = isUsingRecommendedBrowser && !hasUpToDateBrowser

    if (!isUsingRecommendedBrowser) {
        return _('browser_support.recommendation.not_using_recommended_browser')
    }

    return isUsingOutdatedBrowser
        ? _('browser_support.recommendation.using_recommended_outdated_browser')
        : _('browser_support.recommendation.using_recommended_browser')
}

const GoBack = () => {
    const history = useHistory()
    return (
        <S.GoBack>
            <Button onClick={() => history.goBack()} variant="light">
                <span>
                    {_('browser_support.exit')}
                    <Icon icon="exit" style={{ marginLeft: 6 }} />
                </span>
            </Button>
        </S.GoBack>
    )
}

const BrowserSupportView = () => (
    <S.Page>
        <S.Header>{_('browser_support.header')}</S.Header>

        <h4>
            {_('browser_support.you_are_using')}
            {': '}
            <b>
                {browser.getBrowserName()}
                {' '}
                {browser.getBrowserVersion()}
            </b>
        </h4>

        <h4>{getBrowserRecommendation()}</h4>

        <S.Table>
            <tbody>

                <tr>
                    <td>{_('browser_support.supports_video_calling')}</td>
                    <td><SupportedBadge supported={twilio.isSupported} /></td>
                </tr>

                <tr>
                    <td>{_('browser_support.supports_screen_sharing')}</td>
                    <td><SupportedBadge supported={twilio.isSupported && isScreenShareSupported} /></td>
                </tr>

                <tr>
                    <td>{_('browser_support.supports_accreditation_tracking')}</td>
                    <td><SupportedBadge supported={useIsMatomoAvailable()} /></td>
                </tr>

            </tbody>
        </S.Table>

        <GoBack />
    </S.Page>
)

export default BrowserSupportView
