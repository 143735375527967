import React, { useEffect, useState } from 'react'
import {
    Button, Modal, OverlayTrigger, Tooltip
} from 'react-bootstrap'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import colors from '../../../constants/colors'
import { _ } from '../../../utils/localization'
import {
    formatTimeAgo, toLocalDateFormat, toLocalTimeFormat
} from '../../../utils/datetime'
import { eventTimeFormatSelector } from '../../auth/eventPreviewSlice'
import Icon from '../../../components/Icon'
import { fetchMeetingRoom, selectMeetingRoom } from '../meetingRoomSlice'
import { MeetingRecording } from '../../../types/__generated_swagger'
import { MeetingItem } from '../types'
import { showNotification } from '../../notifications'
import { showRecordingApprovalModal } from './utils'
import { addModal } from '../../modals/modalSlice'
import PreviewModal from './PreviewModal'
import DeleteConfirmationModal from './DeleteConfirmationModal'
import useSlug from '../../../hooks/useSlug'

const S = {
    Wrapper: styled.div`
      padding: 10px 20px;
    `,
    Title: styled.h3`
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 10px;
      color: ${colors.darkTitle};
    `,
    MeetingTitle: styled.h4`
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      color: ${colors.darkTitle};
    `,
    DateTime: styled.p`
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
      color: ${colors.grayDescription};
    `,
    NoRecordingsMessage: styled.div`
      font-size: 16px;
    `,
    ListItem: styled(ListGroup.Item)`
      display: flex;
      justify-content: space-between;
    `,
    RecordingTitle: styled.div`
      color: ${colors.darkTitle};
      font-size: 14px;
      flex-basis: 30%;
    `,
    ListTime: styled(OverlayTrigger)`
      flex-basis: 30%;
    `,
    Icons: styled.div`
      display: flex;
      gap: 5px;
      flex-basis: 30%;
      justify-content: flex-end;
    `,
    Actions: styled.div`
        display: flex;
        justify-content: flex-end;
    `,
    UploadInProgress: styled.div`
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: ${colors.regentGray};
      flex-basis: 30%;
    `,
    UploadComplete: styled.div`
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: ${colors.green};
      flex-basis: 30%;
      display: flex;
      align-items: baseline;
      gap: 5px;
      justify-content: flex-end;
    `,
    CloseButton: styled(Button)`
      margin-top: 20px;
    `,
}

const showDeleteConfirmationModal = (meeting, recording, title) => addModal({
    Component: ({ close }: {close: () => void}) => (
        <DeleteConfirmationModal meeting={meeting} recording={recording} title={title} close={close} />
    )
})

const showPreviewModal = (meeting: MeetingItem, recording: MeetingRecording) => addModal({
    Component: ({ close }: { close: () => void }) => (
        <PreviewModal
            recording={recording}
            meeting={meeting}
            close={close}
        />
    ),
    size: 'xl',
    padding: 0
})

const RecordingsModal = ({ close }: { close: () => void}) => {
    const timeFormat = useSelector(eventTimeFormatSelector)
    const [availableRecordings, setAvailableRecordings] = useState([])
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const meetingSpaceSlug = useSlug('meetingSlug')
    const roomSlug = useSlug('meetingRoomSlug')
    const meeting = useSelector(selectMeetingRoom)

    useEffect(() => {
        dispatch(fetchMeetingRoom({ eventSlug, meetingSlug: meetingSpaceSlug, roomSlug }))
    }, [dispatch, eventSlug, meetingSpaceSlug, roomSlug])

    useEffect(() => {
        setAvailableRecordings(meeting.recordings.filter((e) => !e.is_deleted || !!e.vimeo_recording))
    }, [meeting.recordings])

    return (
        <S.Wrapper>
            <Modal.Header closeButton style={{ padding: 0, border: 'none' }}>
                <S.Title>
                    {_('meetingspace.recordings_modal.title')}
                    {' '}
                    (
                    {availableRecordings.length}
                    )
                </S.Title>
            </Modal.Header>
            <S.MeetingTitle>
                {meeting.title}
            </S.MeetingTitle>
            <S.DateTime>
                {`${toLocalDateFormat(meeting.starts_at)} - ${toLocalTimeFormat(meeting.starts_at, timeFormat)} - ${toLocalTimeFormat(meeting.ends_at, timeFormat)}`}
            </S.DateTime>
            {
                !availableRecordings.length ? (
                    <S.NoRecordingsMessage>{_('meetingspace.recordings_modal.no_recordings')}</S.NoRecordingsMessage>
                ) : (
                    <ListGroup>
                        {reverse(sortBy(availableRecordings, 'created')).map((recording) => (
                            recording.vimeo_recording ? (
                                <VimeoListItem
                                    meeting={meeting}
                                    recording={recording}
                                    key={recording.id}
                                />

                            ) : (
                                <SambaListItem
                                    meeting={meeting}
                                    recording={recording}
                                    key={recording.id}
                                />
                            )

                        ))}
                    </ListGroup>
                )
            }
            <S.Actions>
                <S.CloseButton
                    variant="light"
                    onClick={close}
                >
                    {_('button.close')}
                </S.CloseButton>
            </S.Actions>
        </S.Wrapper>
    )
}

interface VimeoListItemProps {
    meeting: MeetingItem
    recording: MeetingRecording
}

interface SambaListItemProps {
    meeting: MeetingItem
    recording: MeetingRecording
}

const ListTime = ({datetime}: {datetime: string}) => {
    const renderTooltip = (props) => (
        <Tooltip id="time-tooltip" {...props}>
            {`${toLocalDateFormat(datetime)} - ${toLocalTimeFormat(datetime)}`}
        </Tooltip>
    )

    return (
        <S.ListTime
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            <div>
                {`${formatTimeAgo(datetime)}`}
            </div>
        </S.ListTime>
    )
}

// todo we can probably merge this with SambaListItem, since they are pretty similar
const VimeoListItem = ({meeting, recording} : VimeoListItemProps) => {
    const dispatch = useDispatch()
    const copyVideoUrl = (event) => {
        event.stopPropagation()
        const url = `https://vimeo.com/${recording.vimeo_recording.external_id}`
        navigator.clipboard.writeText(url).then(() => dispatch(
            showNotification({
                body: _('meetingspace.recordings_modal.link_copied'),
            })
        ))
    }
    const title = `${meeting.title} - ${toLocalDateFormat(recording.created)} ${recording.external_id}`
    const deleteRec = (event) => {
        event.stopPropagation()
        dispatch(showDeleteConfirmationModal(meeting, recording, title))
    }

    return (
        <S.ListItem action>
            <S.RecordingTitle>
                {recording.vimeo_recording.host_name}
                {' - '}
                {meeting.title}
            </S.RecordingTitle>
            <ListTime datetime={recording.created} />
            {recording.vimeo_recording.status === 'complete' || !recording.vimeo_recording
                ? ((
                    <S.UploadComplete>
                        <Icon icon="checkCircle" color={colors.green} />
                        <span>{_('meetingspace.recordings_modal.upload_complete')}</span>
                        <Icon icon="copy" onClick={copyVideoUrl} />
                        <Icon icon="delete" onClick={deleteRec} />
                    </S.UploadComplete>
                ))
                : (<S.UploadInProgress>{_('meetingspace.recordings_modal.upload_in_progress')}</S.UploadInProgress>) }
        </S.ListItem>
    )
}

const SambaListItem = ({meeting, recording }: SambaListItemProps) => {
    const dispatch = useDispatch()
    const [hover, setHover] = useState(false)
    const title = `${meeting.title} - ${toLocalDateFormat(recording.created)} (${recording.external_id})`

    const deleteRec = (event) => {
        event.stopPropagation()
        dispatch(showDeleteConfirmationModal(meeting, recording, title))
    }

    const openAcceptRecordingModal = (event) => {
        event.stopPropagation()
        dispatch(showRecordingApprovalModal(meeting, recording))
    }

    const openPreviewModal = () => {
        dispatch(showPreviewModal(meeting, recording))
    }

    return (
        <S.ListItem
            action
            onClick={openPreviewModal}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <S.RecordingTitle>
                {title}
            </S.RecordingTitle>
            <ListTime datetime={recording.created} />
            {hover ? (
                <S.Icons>
                    <Icon icon="delete" onClick={deleteRec} />
                    <Icon icon="checkCircle" onClick={openAcceptRecordingModal} />
                </S.Icons>
            ) : (<S.Icons />)}

        </S.ListItem>
    )
}

export default RecordingsModal
