import React from 'react'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { addModal } from '../modals/modalSlice'
import { User } from '../auth/types'
import BusinessCard from './BusinessCard'

const openBusinessCard = (user: User) => (createAsyncThunk(
    'OPENBUSINESSCARD',
    (_, thunkAPI) => {
        const Component = ({close}: {close: () => void}) => <BusinessCard user={user} close={close} />
        thunkAPI.dispatch(addModal({
            Component,
            padding: 0,
        }))
    },
))()

export default openBusinessCard
