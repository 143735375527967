import React, {
    ReactNode, useMemo, useRef, useState
} from 'react'
import styled from 'styled-components'
import colors from '../constants/colors'
import useClickOutSide from '../hooks/useClickOutSide'
import Icon from './Icon'
import { IconOption } from './Icon/icons'
import CustomButton from "./CustomButton"

const S = {
    Wrapper: styled.div`
        position: relative;
        cursor: pointer;
    `,
    DropdownToggleOutline: styled.div`
        padding: 8px ${(props: { hasValue: boolean }) => (props.hasValue ? '20px' : '28px')} 8px 10px;
        position: relative;
        background: #FFFFFF;
        border: 1px solid rgba(13, 14, 15, 0.1);
        border-radius: 4px;
        color: ${colors.icon};
        font-size: 14px;
        line-height: 16px;
        &::after {
            position: absolute;
            top: 12px;
            right: 10px;
            content: '';
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-top-color: ${colors.darkOpacity(0.6)};
        }
        
        & span {
            margin: 0 10px 0 15px;
        }
    `,
    DropdownToggleLight: styled.div`
        padding: 4px ${(props: { hasValue: boolean }) => (props.hasValue ? '20px' : '28px')} 4px 0;
        position: relative;
        background: transparent;
        font-size: 16px;
        line-height: 19px;
        color: ${colors.darkOpacity(0.75)};
        &::after {
            position: absolute;
            top: 12px;
            right: 0;
            content: '';
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-top-color: ${colors.icon};
        }
        
        & span {
            margin: 0 10px 0 15px;
        }
    `,
    DropdownTogglePrimary: styled.div`
        padding: 4px 10px;
        position: relative;
        background: ${colors.primary};
        font-size: 14px;
        line-height: 16px;
        color: white;
        border-radius: 4px;
    `,
    DropMenu: styled.div<{ direction: 'up' | 'down'; visible: boolean }>`
        position: absolute;
        z-index: 100;
        min-width: 105px;
        right: 0;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 2px;

        ${({ direction }) => (direction === 'up' ? `
        top: -5px;
        transform: translateY(-100%);
        ` : `
        bottom: -5px;
        transform: translateY(100%);
        `)}

        ${({ visible }) => !visible && `
        opacity: 0;
        pointer-events: none;
        `}

        & > * {
            display: block;
            white-space: nowrap;
            padding: 8px 10px;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            color: ${colors.icon};
            &:not(:last-child) {
                border-bottom: 1px solid rgba(176, 190, 197, 0.3);
            }
            &:hover {
                background-color: ${colors.light};
            }
        }
    `
}

interface Props {
    variant?: 'outline' | 'light' | 'primary'
    name?: string
    children?: ReactNode
    className?: string
    value?: any
    direction?: 'up' | 'down'
    icon?: IconOption
    renderToggleButton?: () => ReactNode
    hideOnClick?: boolean
    showCloseButton?: boolean
}

const ToggleVariant = {
    outline: S.DropdownToggleOutline,
    light: S.DropdownToggleLight,
    primary: S.DropdownTogglePrimary,
}

const colorMapper = {
    primary: 'white',
    outline: colors.icon,
    light: colors.darkOpacity(0.75),
}

const DropdownButton = ({
    variant = 'outline',
    name,
    children,
    className,
    value,
    direction,
    icon,
    renderToggleButton,
    hideOnClick = true,
    showCloseButton
}: Props) => {
    const [show, setShow] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>()
    const dropMenuRef = useRef<HTMLDivElement>()
    useClickOutSide(dropdownRef, () => show && setShow(false))
    const ToggleComponent = ToggleVariant[variant]

    const dropdownDirection = useMemo(() => {
        if (show && dropdownRef && dropMenuRef) {
            const pageContentHeight = document.getElementById('page-content').scrollHeight
            if (pageContentHeight - dropdownRef.current?.offsetTop > dropMenuRef.current?.clientHeight) {
                return 'down'
            }
        }
        return 'up'
    }, [show])

    const handleToggleMenu = (evt) => {
        evt.stopPropagation()
        if (show) {
            hideOnClick && setShow(false)
            return
        }
        setShow(true)
    }

    return (
        <S.Wrapper
            onClick={handleToggleMenu}
            ref={dropdownRef}
            className={className}
        >
            {renderToggleButton?.() || (
                <ToggleComponent hasValue={!!value}>
                    {icon && <Icon icon={icon} size={18} color={colorMapper[variant]} className="mr-1" />}
                    {name}
                    {value && <span>{value}</span>}
                </ToggleComponent>
            )}
            <S.DropMenu ref={dropMenuRef} visible={show} direction={direction || dropdownDirection}>
                {children}
                {showCloseButton && (
                    <CustomButton onClick={() => setShow(false)} variant="secondary" style={{position: 'absolute', right: 10, bottom: 10 }}>
                        Close
                    </CustomButton>
                )}
            </S.DropMenu>
        </S.Wrapper>
    )
}

export default DropdownButton
