import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import StyledIcon from '../../../components/Icon'
import colors from '../../../constants/colors'
import { GoodiebagType, SpeakerData } from '../types'
import CardContainer from './CardContainer'
import Bookmark from '../Bookmark'
import { ContentProps } from './GoodiebagContent'
import Performer from '../../performer/view/Performer'
import { addModal } from '../../modals/modalSlice'
import { formatJobInfo } from '../../../utils/profile'
import constants from '../../../constants/constants'

export const S = {
    Wrapper: styled.div`
        display: flex;
        width: 100%;
        height: 112px;
        margin-bottom: 20px;
        padding: 10px;
        border: 1px solid rgba(176, 190, 197, 0.3);
        border-radius: 4px;
    `,
    Image: styled.img`
        width: 90px;
        height: 90px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        margin-right: 20px;
    `,
    Content: styled.div`
        margin: auto;
        overflow: hidden;
        flex: 1;
        padding: 6px 0;
    `,
    Title: styled.h3`
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: ${colors.grayTitle};
    `,
    Description: styled.div`
        margin: 0;
        max-height: 55px;
        font-weight: 300;
        font-size: 14px;
        color: ${colors.goodiebag.darkenDescription};
        line-height: 17px;
    `,
    Actions: styled.div`
        display: flex;
        align-items: center;
    `,
    SubDescription: styled.div`
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 14px;
        color: ${colors.regentGray};
    `,
    Link: styled(Link)`
        display: block;
        flex: 1;
        overflow: hidden;
        text-decoration: none !important;
        cursor: pointer;
    `
}

const Speaker = ({ speaker }: { speaker: SpeakerData }) => {
    const dispatch = useDispatch()
    const showPerformerDetail = () => {
        const Component = () => <Performer performer={speaker} />
        dispatch(addModal({
            Component, size: 'xl', padding: 40, showCloseIcon: true
        }))
    }
    return (
        <S.Wrapper>
            <S.Image src={speaker.mugshot || constants.urlThumbnailDefault} />
            <S.Content>
                <S.Title>{speaker.name}</S.Title>
                <S.Description className="text-cut-line-3">
                    {formatJobInfo(speaker)}
                </S.Description>
            </S.Content>
            <S.Actions>
                <Bookmark item={{ type: GoodiebagType.Speaker, id: speaker.id }} size={20} style={{ margin: '0 20px' }} />
                <StyledIcon icon="eye" onClick={showPerformerDetail} size={20} />
            </S.Actions>
        </S.Wrapper>
    )
}

const Speakers = ({ items }: ContentProps) => (
    <CardContainer>
        {items.map(({ performer }) => (
            <Speaker speaker={performer} key={performer.id} />
        ))}
    </CardContainer>
)

export default Speakers
