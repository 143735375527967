import React from 'react'
import Container from 'react-bootstrap/Container'

const NotFound = () => (
    <Container style={{ height: 'var(--window-height)', display: 'flex' }}>
        <h1 style={{ margin: 'auto', fontWeight: 'bold' }}>404</h1>
    </Container>
)

export default NotFound
