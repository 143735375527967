import styled from 'styled-components'

const NetworkingLayout = styled.div`
      display: flex;
      height: 100%;
      width: 100%;
      overflow: hidden;
`

export default NetworkingLayout
