import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CustomButton from '../../../../components/CustomButton'
import useSlug from '../../../../hooks/useSlug'
import { AppointmentSchedule } from '../../../../types/__generated_swagger'
import { _ } from '../../../../utils/localization'
import { showJoinButton } from '../../../appointment_schedules/utils'
import { getPath } from '../../../entervideocall/useRedirectToRoom'
import { closeAllModal } from '../../../modals/modalSlice'

const JoinAppointmentButton = ({
    appointment, variant = 'primary'
}: { appointment: AppointmentSchedule, variant?: 'primary' | 'secondary' | 'success' | 'danger' }) => {
    const eventSlug = useSlug('eventSlug')
    const history = useHistory()
    const dispatch = useDispatch()

    if (!appointment.twilio_name) {
        return <></>
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {_('appointment_schedule.inactive_join_button_tooltip')}
        </Tooltip>
    )

    const joinVideocall = () => {
        history.push(getPath(appointment.twilio_name, eventSlug, {}))
        dispatch(closeAllModal())
    }

    return (
        <OverlayTrigger placement="top" overlay={renderTooltip}>
            <div onClick={(event) => event.stopPropagation()}>
                <CustomButton
                    disabled={!showJoinButton(appointment)}
                    variant={variant}
                    onClick={joinVideocall}
                >
                    {_('button.join')}
                </CustomButton>
            </div>
        </OverlayTrigger>
    )
}

export default JoinAppointmentButton
