import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import ListGroup from 'react-bootstrap/ListGroup'
import { useDispatch, useSelector } from 'react-redux'
import { _ } from '../../../utils/localization'
import Icon from '../../../components/Icon'
import Get from '../../../components/Get'
import { ListVirtualAttendeesResponse } from '../../../types/__generated_swagger'
import useSlug from '../../../hooks/useSlug'
import { Chat, ChatWithAttendee } from '../types'
import ChatListItem from './ChatListItem'
import LoadMore from '../../../components/LoadMore'
import {
    getChats,
    limitGetChatSelector,
    setLimitGetChats,
    canLoadMoreChatSelector,
    statusGetChatSelector,
    idToChatSelector,
    selectChatId,
    selectingBroadcastSelector,
} from '../chatSlice'
import Loader from '../../../components/Loader'
import colors from '../../../constants/colors'
import { currentUserSelector, isEventOwnerSelector } from '../../auth/authSlice'
import { compareDate } from '../../../utils/datetime'
import useFetchAttendees from '../../attendees/useFetchAttendees'
import useSubscribeAttendees from '../../attendees/useSubscribeAttendees'
import openChat from '../openChat'
import { RootState } from '../../../store'
import useDebounceValue from '../../../hooks/useDebounceValue'
import BroadcastChatItem from './broadcast_message/BroadcastChatItem'
import useResponsive from '../../../hooks/useResponsive'

const S = {
    SideBar: styled.div`
        display: flex;
        flex-direction: column;
    `,
    SideBarContentMobile: styled.div`
        padding-bottom: 70px;
        overflow: auto;
    `
}

const Search = ({
    onChange = (e?: any) => e
}: {onChange?: (e) => void}) => {
    const [search, setSearch] = useState('')
    const searchParam = useDebounceValue(search, 300)

    useEffect(() => {
        onChange(searchParam)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam])

    // Remove search when selecting chat
    const chatId = useSelector((state: RootState) => state.chat.currentChatId)
    useEffect(() => {
        setSearch('')
    }, [chatId])

    return (
        <InputGroup>
            <Form.Control
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={_('chat.contacts.search_placeholder')}
                style={{ height: 40 }}
            />
            <InputGroup.Append>
                <InputGroup.Text id="basic-addon1">
                    <Icon icon="search" size={22} />
                </InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    )
}

export const byLastSent = (a: Chat, b: Chat) => (
    (a.last_sent && b.last_sent)
        ? -compareDate(a.last_sent, b.last_sent)
        : 0
)

interface ChatListProps {
    chatList: ChatWithAttendee[]
    chatBot?: Chat
}

const ChatList = ({ chatList, chatBot }: ChatListProps) => {
    const eventSlug = useSlug('eventSlug')
    const chatId = useSelector(selectChatId)
    const currentUser = useSelector(currentUserSelector())
    const selectingBroadcast = useSelector(selectingBroadcastSelector)
    const isEventOwner = useSelector(isEventOwnerSelector(eventSlug))
    const sortedChatList = chatList
        .filter((chat) => chat.user_id !== currentUser?.id)
        .sort(byLastSent)
    const dispatch = useDispatch()
    const {isMobile} = useResponsive()

    // Set default chat
    useEffect(() => {
        if (!chatId && sortedChatList.length > 0 && !selectingBroadcast && !isMobile) {
            dispatch(openChat(eventSlug, sortedChatList[0].user_id))
        }
    }, [chatBot, chatId, dispatch, eventSlug, sortedChatList, selectingBroadcast, isMobile])

    return (
        <ListGroup>
            {isEventOwner && <BroadcastChatItem />}
            {chatBot && (
                <ChatListItem
                    chat={{...chatBot, user: { id: chatBot.user_id }}}
                    key={chatBot.user_id}
                    active={chatBot.user_id === chatId}
                    isChatBot
                />
            )}
            {
                chatList.length === 0
                    ? <p className="ml-2 mt-2">{_('chat.no_users_available')}</p>
                    : sortedChatList
                        .map((chat) => (
                            <ChatListItem
                                chat={chat}
                                key={chat.user_id}
                                active={chat.user_id === chatId}
                            />
                        ))
            }
        </ListGroup>
    )
}

const ExistingChatList = () => {
    const chatList = Object.values(useSelector(idToChatSelector))
    const dispatch = useDispatch()
    const requestStatus = useSelector(statusGetChatSelector)
    const limit = useSelector(limitGetChatSelector)
    const canLoadMore = useSelector(canLoadMoreChatSelector)
    const eventSlug = useSlug('eventSlug')

    const chatBot = chatList.find((chat) => chat.is_chat_bot)
    const userIds = chatList.map((chat) => chat.user_id)
    const users = useFetchAttendees(userIds)
    const chatListWithUsers = chatList
        .map((chat) => ({
            ...chat,
            user: users.find((user) => user.id === chat.user_id)
        }))
        // Filter out users that we don't have profile data for,
        // These are not in the current event.
        .filter((chat) => !!chat.user)

    useSubscribeAttendees(userIds)

    useEffect(() => {
        dispatch(getChats({ eventSlug, limit }))
    }, [eventSlug, limit, dispatch])

    if (requestStatus === 'loading') {
        return <Loader isCenter color={colors.gray4} className="mt-4" />
    }

    return (
        <>
            <ChatList chatList={chatListWithUsers} chatBot={chatBot} />
            {canLoadMore && (
                <LoadMore onClick={() => dispatch(setLimitGetChats(limit + 10))}>
                    {_('chat.load_more')}
                </LoadMore>
            )}
        </>
    )
}

const userToChat = (user) => ({
    user_id: user.id,
    user,
})

const StartNewChatList = ({ search }: { search: string }) => {
    const eventSlug = useSlug('eventSlug')
    const [limit, setLimit] = useState(20)
    const loadMore = () => setLimit(limit + 20)
    return (
        <Get
            url={`/events/${eventSlug}/virtual_attendees/`}
            params={{ search, limit }}
        >
            {(resp: ListVirtualAttendeesResponse) => (
                <>
                    <ChatList chatList={resp.results.map(userToChat)} />
                    {resp.count > limit
                    && (
                        <LoadMore onClick={loadMore}>
                            {_('chat.load_more')}
                        </LoadMore>
                    )}
                </>
            )}
        </Get>
    )
}

const SideBar = () => {
    const [search, setSearch] = useState('')
    const {isMobile} = useResponsive()
    return isMobile ? (
        <S.SideBar>
            <S.SideBarContentMobile>
                {
                    search
                        ? <StartNewChatList search={search} />
                        : <ExistingChatList />
                }
            </S.SideBarContentMobile>
            <footer className="footer-mobile">
                <Search onChange={setSearch} />
            </footer>
        </S.SideBar>
    ) : (
        <S.SideBar>
            <Search onChange={setSearch} />
            {
                search
                    ? <StartNewChatList search={search} />
                    : <ExistingChatList />
            }
        </S.SideBar>
    )
}

export default SideBar
