const whiteScale = {
    white: '#ffffff',
    gray1: '#F9F9F9',
    gray2: '#efefef',
    gray3: '#e7e7e7',
    gray4: '#dcdcdc',
    gray5: '#bdbdbd',
    gray6: '#7f7f7f',
    gray7: '#505050',
    gray8: '#444444',
    gray9: '#2b2b2b',
    black: '#000',
}

const colorTemplate = {
    blue: '#3068F4',
    indigo: '#6610f2',
    purple: '#6f42c1',
    pink: '#e83e8c',
    red: '#dc3545',
    orange: '#fd7e14',
    yellow: '#ffc107',
    green: '#28a745',
    teal: '#20c997',
    cyan: '#17a2b8',
    mineShaft: '#343434',
    regentGray: '#78909C',
    outlineButton: '1px solid #E8E9E9',
    grayTitle: '#4E5155',
    grayDescription: '#667283',
    icon: '#78909C',
    label: 'rgba(66, 69, 74, 0.8)',
    navText: '#42454A',
    navDivider: 'rgb(120,144,156, 0.1)',
}

const goodiebag = {
    darkenDescription: '#2D3035',
    sidebar: 'rgba(45, 48, 53, 0.9)',
    background: '#F9F9F9',
    icon: '#78909C',
    border: 'rgba(176, 190, 197, 0.3)',
    headerTable: 'rgba(102, 114, 131, 0.8)',
    fileText: '#4F555F',
    sidebarText: 'rgba(79, 85, 95, 0.8)',
}

const colors = {
    primary: colorTemplate.blue,
    secondary: whiteScale.gray6,
    success: colorTemplate.green,
    info: colorTemplate.cyan,
    warning: colorTemplate.yellow,
    danger: colorTemplate.red,
    light: whiteScale.gray1,
    dark: whiteScale.gray8,
}

export default Object.freeze({
    ...whiteScale,
    ...colorTemplate,
    ...colors,
    iconColor: colors.secondary,
    activeIconColor: colors.primary,
    dividerColor: whiteScale.gray5,
    goodiebag,
    iconOpacity: (opacity) => `rgba(120, 144, 156, ${opacity})`,
    darkTitle: '#2D3035',
    inputBorder: 'rgba(24, 28, 33, 0.15)',
    checkboxColor: '#1E88E5',
    grayTitleOpacity: (opacity) => `rgba(78, 81, 85, ${opacity})`,
    mineShaftOpacity: (opacity) => `rgba(52, 52, 52, ${opacity})`,
    primaryOpacity: (opacity) => `rgba(48, 104, 244, ${opacity})`,
    lightPrimary: '#628DF7',
    lightRed: '#FF4141',
    lightBackground: '#FEFEFE',
    darkGray: '#4F5B6C',
    darkOpacity: (opacity) => `rgba(45, 48, 53, ${opacity})`,
    midBlue: '#359EFF',
    divider: 'rgba(176, 190, 197, 0.2)',
})
