import Login from './views/Login'
import Logout from './views/Logout'
import EnterEvent from './views/EnterEvent'
import { createRoute } from '../../utils/routes'

export default {
    enterEvent: createRoute({
        title: 'Event Entry',
        path: '/:eventSlug/enterevent/',
        component: EnterEvent,
        isPublic: true,
    }),
    enterEventBackStage: createRoute({
        title: 'Event Entry',
        path: '/:eventSlug/backstage/',
        component: EnterEvent,
        isPublic: true,
    }),
    login: createRoute({
        title: 'Login',
        path: '/login',
        component: Login,
        isPublic: true,
    }),
    logout: createRoute({
        title: 'Logout',
        path: '/logout',
        component: Logout,
        isPublic: true,
    }),
}
