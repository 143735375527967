import React, { useState } from 'react'
import Button from 'react-bootstrap/cjs/Button'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { _ } from '../../../utils/localization'
import { MeetingItem } from '../types'
import { setRegisterStatus } from '../meetingListSlice'
import { eventSelector } from '../../events/eventSlice'
import useSlug from '../../../hooks/useSlug'
import { registerForMeeting, resetRequestStatus } from '../meetingSpaceSlice'

const S = {
    ButtonWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    `
}

const UnregisterModal = ({ meeting, close }: {meeting: MeetingItem, close: () => void}) => {
    const dispatch = useDispatch()
    const [isUnregistered, setIsUnregistered] = useState(false)
    const event = useSelector(eventSelector())
    const eventSlug = useSlug('eventSlug')
    const meetingSpaceSlug = useSlug('meetingSlug')

    const resetStateAndClose = () => {
        dispatch(resetRequestStatus())
        close()
    }

    const sendRequest = () => {
        dispatch(registerForMeeting({
            eventSlug, meetingSpaceSlug, meetingSlug: meeting.slug, register: false
        }))
    }

    const unregister = () => {
        sendRequest()
        setIsUnregistered(true)
        dispatch(setRegisterStatus({
            meetingId: meeting.id, isRegistered: false, attendeeId: event.current_user_attendee_id
        }))
        dispatch(resetRequestStatus())
    }

    if (!isUnregistered) {
        return (
            <div>
                <div>{_('meetingspace.unregister_pre_send_body')}</div>
                <S.ButtonWrapper>
                    <Button variant="danger" onClick={resetStateAndClose}>{_('button.cancel')}</Button>
                    <Button variant="success" onClick={unregister}>{_('meetingspace.sidebar.unregister')}</Button>
                </S.ButtonWrapper>
            </div>
        )
    }
    return (
        <div>
            <div>{_('meetingspace.unregister_post_send_body')}</div>
            <S.ButtonWrapper>
                {/* empty div to right align the button */}
                <div />
                <Button variant="success" onClick={resetStateAndClose}>{_('button.okay')}</Button>
            </S.ButtonWrapper>
        </div>
    )
}

export default UnregisterModal
