import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ListGroup from 'react-bootstrap/cjs/ListGroup'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useDispatch, useSelector } from 'react-redux'
import { _ } from '../../../../utils/localization'
import Icon from '../../../../components/Icon'
import { Booth } from '../../types'
import languages from '../../../../constants/supportedLanguages'
import SelectWithAllOption from '../../../../components/SelectWithAllOption'
import colors from '../../../../constants/colors'
import { eventPreviewSelector } from '../../../auth/eventPreviewSlice'
import CustomButton from '../../../../components/CustomButton'
import { addModal } from '../../../modals/modalSlice'
import useSlug from '../../../../hooks/useSlug'
import api from '../../../../utils/api'
import Typography from '../../../../components/Typography'
import Loader from '../../../../components/Loader'
import { transformErrorObjectToText } from '../../../notifications/notificationsSlice'

const S = {
    Header: styled.div`
        padding: 20px;
        background: transparent;
        font-size: 22px;
        font-weight: 500;
        @media (max-width: 932px) {
            display: none;
        }
    `,
    DocumentWrapper: styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        border-bottom: 1px solid ${colors.inputBorder};
        flex-wrap: wrap;
    `,
    DocumentItem: styled.a`
        display: flex;
        align-items: center;
        &.active {
            background-color: inherit;
            border-color: inherit;
            color: inherit;
        }
    `
}

const TranslateDoc = ({documentId, close}: {documentId: number, close: () => void}) => {
    const eventSlug = useSlug('eventSlug')
    const boothSlug = useSlug('boothSlug')
    const [language, setLanguage] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [url, setUrl] = useState('')
    const [error, setError] = useState('')

    const translateDoc = async () => {
        if (!language) {
            setError(_('validation.required_language'))
            return
        }
        setIsLoading(true)
        setError('')
        const response = await api.safePost(`/events/${eventSlug}/booths/${boothSlug}/translate_doc/`, {
            id: documentId,
            language: language?.code,
        })
        setIsLoading(false)
        if (response.error) {
            setError(transformErrorObjectToText(response.error))
            return
        }
        setUrl(response.data?.url)
    }

    useEffect(() => {
        setError('')
    }, [language])

    return (
        <div>
            <SelectWithAllOption
                optionLabel="language"
                optionValue="code"
                options={languages}
                value={language}
                onChange={setLanguage}
                placeholder={_('placeholder.select_language')}
            />
            <Typography color={colors.danger} fontSize={14} className="mt-3">
                {error}
            </Typography>
            {isLoading && <Loader />}
            {url && (
                <div className="mt-3">
                    <a href={url} target="_blank" rel="noreferrer">
                        {url}
                    </a>
                </div>
            )}
            <CustomButton variant="primary" className="ml-0 mt-4" onClick={translateDoc} disabled={isLoading}>
                {_('button.get_translated_document')}
            </CustomButton>
            <CustomButton variant="secondary" onClick={close}>{_('button.close')}</CustomButton>
        </div>
    )
}

const BoothDocuments = ({ boothDetail }: {boothDetail: Booth}) => {
    const dispatch = useDispatch()
    const eventPreview = useSelector(eventPreviewSelector)
    const { trackEvent } = useMatomo()
    const trackAction = (action, name) => {
        trackEvent({
            category: boothDetail.name,
            action,
            name,
        })
    }

    const onClick = (id: number) => {
        dispatch(addModal({
            Component: ({close}: {close: () => void}) => (
                <TranslateDoc close={close} documentId={id} />
            ),
            header: _('booth.translate_document'),
            padding: 40,
        }))
    }

    return (
        <div style={{paddingBottom: 20}}>
            <S.Header>
                {_('booth.tabs.documents')}
            </S.Header>
            <ListGroup style={{ borderRadius: 0 }}>
                {boothDetail.documents.map((document) => (
                    <S.DocumentWrapper key={document.id}>
                        <S.DocumentItem
                            href={document.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => trackAction('file downloaded', document.name)}
                        >
                            <Icon icon="file" style={{ margin: '0 20px 3px 0' }} />
                            {document.name}
                        </S.DocumentItem>
                        {eventPreview?.enable_page_translation && document.support_translate && (
                            <CustomButton variant="primary" onClick={() => onClick(document.id)}>
                                {_('booth.translate_document')}
                            </CustomButton>
                        )}
                    </S.DocumentWrapper>
                ))}
            </ListGroup>
        </div>
    )
}

export default BoothDocuments
