import React from 'react'
import styled from 'styled-components'
import styles from '../constants/styles'
import PublicChat from '../features/publicchat/PublicChat'

const S = {
    Wrapper: styled.div<{isShowPublicChat: boolean}>`
        background: white;
        width: ${styles.sidebarWidth}px;
        height: 100%;
        position: fixed;
        z-index: 9;
        top: 0;
        display: flex;
        flex-direction: column;
        padding-top: ${styles.topNavHeight}px;
        padding-bottom: 10px;
        right: ${({ isShowPublicChat }) => (isShowPublicChat ? 0 : -(styles.sidebarWidth))}px;
        transition: 0.75s;
        @media (max-width: 1366px) {
            width: ${styles.smallerSidebarWidth}px;
            right: ${({ isShowPublicChat }) => (isShowPublicChat ? 0 : -(styles.smallerSidebarWidth))}px;
        }
    `,
    Header: styled.div`
        padding: 12px 10px;
        font-size: 20px;
        font-weight: 500;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    `
}

interface Props {
    isShowPublicChat: boolean
    chatId: number
    moderatorId?: number
    header?: string
}

const PublicChatBar = (
    {
        isShowPublicChat,
        chatId,
        moderatorId,
        header
    }: Props
) => (
    <S.Wrapper isShowPublicChat={isShowPublicChat}>
        <S.Header>
            {header}
        </S.Header>
        {isShowPublicChat
        // add key to PublicChat so it will remount if we change chat
        && (
            <PublicChat
                key={chatId}
                chatId={chatId}
                moderatorId={moderatorId}
            />
        )}
    </S.Wrapper>
)

export default PublicChatBar
