import { createEventRoute } from '../../utils/routes'
import EmbeddedSpace from './EmbeddedSpace'

export default {
    embedded: createEventRoute({
        title: 'Embedded Space',
        path: '/embedded/:embeddedSlug/',
        exact: true,
        component: EmbeddedSpace,
    }),
}
