import { connect, disconnect, send } from '@giantmachines/redux-websocket/dist'
import { createAsyncThunk } from '@reduxjs/toolkit'
import env from '../../constants/env'

interface LoginAction {
    type: string,
    token: string,
    event_slug: string
}

export interface LoginThunkProps {
    eventSlug: string
    accessToken: string
}

const loginThunk = createAsyncThunk(
    'LOGIN_WEBSOCKET',
    (params: LoginThunkProps, thunkApi) => {
        // TODO check if token is expired
        const { eventSlug, accessToken } = params
        const loginAction: LoginAction = { type: 'login', token: accessToken, event_slug: eventSlug }
        thunkApi.dispatch(send(loginAction))
    },
)

const connectThunk = createAsyncThunk(
    'CONNECT_WEBSOCKET',
    (params, thunkApi) => {
        thunkApi.dispatch(connect(env.websocketUrl))
    },
)

const disconnectThunk = createAsyncThunk(
    'DISCONNECT_WEBSOCKET',
    (params, thunkApi) => {
        thunkApi.dispatch(disconnect())
    },
)

export default {
    disconnect: disconnectThunk,
    connect: connectThunk,
    login: loginThunk,
}
