import React, { ReactNode } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import CustomButton from './CustomButton'
import { _ } from '../utils/localization'
import colors from '../constants/colors'

const S = {
    ModalHeader: styled(Modal.Header)`
        padding: 0;
        border: none;
        margin-bottom: 20px;
    `,
    Header: styled.div`
        display: flex;
        align-items: flex-start;
        margin-right: 20px;
        & + button {
            margin-top: -8px !important;
            font-size: 32px;
            font-weight: 400;
            color: ${colors.icon};
            opacity: 1;
            &:focus {
                outline: none;
            }
        }
    `,
    Title: styled.h3`
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 0;
        color: ${colors.grayTitle};
    `,
    Body: styled.div`
        max-height: calc(var(--window-height) - 250px);
        overflow: auto;
        font-weight: 300;
        color: ${colors.grayDescription};
    `,
    Actions: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    `
}

interface ConfirmModalProps {
    close: () => void
    header: string
    body: string | ReactNode
    onConfirm?: (data?: any) => void
    confirmVariant?: 'primary' | 'secondary' | 'success' | 'danger'
    confirmText?: string
    cancelVariant?: 'primary' | 'secondary' | 'success' | 'danger'
    cancelText?: string
    className?: string
}

const ConfirmModal = ({
    header,
    body,
    close,
    onConfirm,
    confirmVariant = 'primary',
    confirmText = _('button.accept'),
    cancelVariant = 'secondary',
    cancelText = _('button.cancel'),
    className,
}: ConfirmModalProps) => {
    const handleConfirm = () => {
        onConfirm()
        close()
    }

    return (
        <div className={className}>
            <S.ModalHeader closeButton>
                <S.Header>
                    <S.Title>{header}</S.Title>
                </S.Header>
            </S.ModalHeader>
            <S.Body>
                {body}
            </S.Body>
            <S.Actions>
                <div />
                <div>
                    {onConfirm && (
                        <CustomButton variant={confirmVariant} onClick={handleConfirm}>
                            {confirmText}
                        </CustomButton>
                    )}
                    <CustomButton variant={cancelVariant} onClick={close}>
                        {cancelText}
                    </CustomButton>
                </div>
            </S.Actions>
        </div>
    )
}

export default ConfirmModal
