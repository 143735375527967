import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Icon from '../../../components/Icon'
import colors from '../../../constants/colors'
import { VirtualAttendee } from '../../../types/swagger'
import openChatModal from '../../chat/openChatModal'
import AttendeeCallStatus from '../../entervideocall/AttendeeCallStatus'
import useFetchAttendees from '../../attendees/useFetchAttendees'
import { IconProps } from '../../../components/Icon/Icon'
import {
    isFullScreenSelector,
    toggleFullScreen,
    togglePublicChat
} from '../posterSlice'
import { Poster, RetrievePosterSpaceResponse } from '../types'
import useSlug from '../../../hooks/useSlug'
import getMugshotSrc from '../../../utils/getMugshotSrc'
import { addModal } from '../../modals/modalSlice'
import EmbeddedVideo from '../../embeddedvideo/EmbeddedVideo'
import { formatJobInfo, formatName } from '../../../utils/profile'
import { currentUserSelector } from '../../auth/authSlice'
import openBusinessCard from '../../profile/openBusinessCard'

const S = {
    ActionBar: styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 50px;
      height: 400px!important;
      padding: 6px 0;
      position: absolute;
      z-index: 9;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      border-radius: 4px 0 0 4px;
      box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.15);
      background-color: white;
      & * {
        height: unset!important;
      }
    `,
    CenterIconsWrapper: styled.div`
      display: flex;
      flex-direction: column;
    `,
    ActionIcon: styled.div`
      margin: 0 12px;
      padding: 6px 0;
      & span {
        display: flex;
        align-items: center;
      }
    `,
    Thumbnail: styled.img`
      width: 39px;
      height: 39px;
      border-radius: 30px;
      object-fit: cover;
    `,
    Link: styled(Link)`
      position: relative;
    `,
    VideoModal: styled.div`
      position: relative;
      width: 800px;
      height: 450px;
      background: black;
    `,
    CloseVideoIcon: styled.div`
      position: absolute;
      top: -40px;
      right: -40px;
    `,
}

const ActionItem = ({
    icon, onClick, disabled = false, ...props
}: IconProps & { onClick?: () => void, disabled?: boolean }) => (
    <S.ActionIcon>
        <Icon
            icon={icon}
            onClick={disabled ? undefined : onClick}
            {...props}
        />
    </S.ActionIcon>
)

const VideoCallAction = ({ posterAuthorId, posterSlug }: { posterAuthorId: number, posterSlug: string }) => {
    const [user] = useFetchAttendees([posterAuthorId])
    return user ? (
        <S.ActionIcon>
            <AttendeeCallStatus
                attendee={user as VirtualAttendee}
                config={{ posterSlug, adminId: posterAuthorId }}
            />
        </S.ActionIcon>
    ) : <div />
}

const FullScreenAction = ({ isFullScreen }: {isFullScreen: boolean}) => {
    const icon = isFullScreen ? 'fullscreenExit' : 'fullscreen'
    const dispatch = useDispatch()

    return <ActionItem icon={icon} onClick={() => dispatch(toggleFullScreen())} />
}

const PlayVideoAction = ({ poster }: {poster: Poster}) => {
    const dispatch = useDispatch()
    const openVideoModal = () => {
        dispatch(addModal({
            Component: ({ close }: {close: () => void}) => (
                <S.VideoModal>
                    <S.CloseVideoIcon>
                        <Icon icon="close" color="white" size={32} onClick={close} />
                    </S.CloseVideoIcon>
                    <EmbeddedVideo url={poster.video_url} />
                </S.VideoModal>
            ),
            size: 'lg',
            padding: 0,
            backgroundColor: 'rgba(0, 0, 0,0.6)'
        }))
    }
    return <ActionItem icon="youtube" color={colors.icon} onClick={openVideoModal} />
}

interface Props {
    poster: Poster
    chatId?: number
    space: RetrievePosterSpaceResponse
}

const ActionBar = ({ poster, chatId, space }: Props) => {
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const currentUser = useSelector(currentUserSelector())
    const isFullScreen = useSelector(isFullScreenSelector)
    const [author] = useFetchAttendees([poster.author], true) // useSubscribeAttendees
    const currentUserIsAuthor = poster.author === currentUser.id
    return (
        <S.ActionBar>
            {author ? (
                <OverlayTrigger
                    placement="bottom"
                    overlay={(
                        <Tooltip id="author-info">
                            <b className="notranslate">{formatName(author)}</b>
                            <br />
                            {formatJobInfo(author)}
                        </Tooltip>
                    )}
                >
                    <S.Thumbnail onClick={() => dispatch(openBusinessCard(author))} alt="" src={getMugshotSrc(author)} />
                </OverlayTrigger>
            ) : <div />}

            <S.CenterIconsWrapper>
                {chatId && <ActionItem icon="forum" onClick={() => dispatch(togglePublicChat())} />}

                <a
                    href={poster.pdf}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {/* Add onClick={() => null} to hight-light icon when hovering. */}
                    <ActionItem icon="download" onClick={() => null} />
                </a>

                {poster.video_url && <PlayVideoAction poster={poster} />}

                {space?.enable_messaging && author && (
                    <ActionItem
                        icon="message"
                        onClick={() => dispatch(openChatModal(eventSlug, poster.author))}
                        disabled={currentUserIsAuthor}
                    />
                )}

                {space?.enable_video && author && (
                    <VideoCallAction
                        posterAuthorId={poster.author}
                        posterSlug={poster.space_slug}
                    />
                )}
            </S.CenterIconsWrapper>

            <FullScreenAction isFullScreen={isFullScreen} />
        </S.ActionBar>
    )
}

export default ActionBar
