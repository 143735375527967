import useRoute from './useRoute'
import { ParamSlug } from '../types'

/**
 * This function allows us to get the params
 * outside of a component within <ReactRouter.Switch>
 * Because it is computed from the route list and current URL.
 */
const useParams = () => {
    const route = useRoute()
    return (route ? route.params : {}) as ParamSlug
}

export default useParams
