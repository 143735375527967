import React from 'react'
import { AreaProps } from './Area'
import { WidgetOption } from './types'
import ClickableWidgetArea from './ClickableWidgetArea'
import EmbeddedWidget from './EmbededWidgetArea'

const widgetToComponent: Record<WidgetOption, React.FC<AreaProps>> = {
    reception: ClickableWidgetArea,
    event_info: ClickableWidgetArea,
    embedded: EmbeddedWidget,
    external_link: ClickableWidgetArea,
}

const WidgetArea = (props: AreaProps) => {
    const Widget = widgetToComponent[props.area.widget]
    return <Widget {...props} />
}

export default WidgetArea
