import React from 'react'
import { MultiSelect } from 'react-multi-select-component'
import styled from 'styled-components'
import colors from '../constants/colors'
import Checkbox from './Checkbox'
import { _ } from '../utils/localization'

const S = {
    MultiSelect: styled(MultiSelect)`
        .dropdown-heading-value {
            font-size: 14px;
            color: ${colors.icon};
        }
    `,
    ColorIndicator: styled.div`
        flex-shrink: 0;
        display: inline-block;
        margin-left: auto;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        background-color: ${({ color }) => color};
    `,
    CustomOption: styled.div`
        height: 20;
        margin-bottom: 0;
    `,
    Label: styled.div`
        color: ${colors.icon};
        margin-left: 4px;
        margin-right: 16px;
    `,
}

export type Option = { value: any; label: string }
interface Props {
    options: Option[]
    selected: any[]
    onSelect: (value) => void
    label?: string
    placeholder?: string
    allSelectedText?: string
    className?: string
}

const CustomItemRenderer = ({
    checked,
    option,
    onClick,
    disabled,
}: any) => (
    <S.CustomOption className={`${disabled && 'disabled'} flex-align-center`}>
        <Checkbox
            preventOnClick
            onChange={onClick}
            checked={checked}
            label=""
        />
        <S.Label className="text-cut">{option.label}</S.Label>
        {option.color && <S.ColorIndicator color={option.color} />}
    </S.CustomOption>
)

const MultiSelectFilter = ({
    options,
    selected,
    onSelect,
    label,
    placeholder,
    allSelectedText = _('placeholder.all_selected'),
    className,
}: Props) => {
    const valueRenderer = (_selected: typeof options) => {
        if (!_selected.length) {
            return placeholder
        }

        if (_selected.length === options.length) {
            return allSelectedText
        }

        return _selected.map(({ label: _label }) => `${_label}, `)
    }

    return (
        <div className={className}>
            {label && <p style={{ fontSize: 14, color: colors.grayDescription, marginBottom: 4 }}>{label}</p>}
            <S.MultiSelect
                options={options}
                value={selected}
                onChange={onSelect}
                labelledBy="select"
                valueRenderer={valueRenderer}
                ItemRenderer={CustomItemRenderer}
                overrideStrings={{
                    allItemsAreSelected: _('placeholder.all_selected'),
                    noOptions: _('placeholder.no_options'),
                    search: _('placeholder.search'),
                    selectSomeItems: _('placeholder.select'),
                    selectAll: _('placeholder.select_all')
                }}
            />
        </div>
    )
}

export default MultiSelectFilter
