import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import sendToWs from '../websocket/sendToWs'

const createSubscription = (joinType: string, leaveType: string) => (id: string) => {
    const dispatch = useDispatch()
    useEffect(() => {
        if (id) {
            dispatch(sendToWs({ type: joinType, id }))
        }
        return () => {
            id && dispatch(sendToWs({ type: leaveType, id }))
        }
    }, [dispatch, id])
}

export const useJoinBooth = createSubscription(
    'join_booth_space', 'leave_booth_space',
)

export const useSubscribeToBoothVistors = createSubscription(
    'subscribe_to_booth_vistors', 'unsubscribe_from_booth_vistors',
)
