import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setHelpContent } from '../features/helpcontent/HelpContentSlice'

const useHelpContent = (payload: {data: string, type: string}, disableDispatch?: boolean) => {
    const dispatch = useDispatch()

    useEffect(() => {
        // Need delay because TransitionSwitch delay 1000ms unmounting a component to show animation when redirecting.
        // Make sure setHelpContent(data) of new component's called after setHelpContent(null) of previous one
        !disableDispatch && setTimeout(() => dispatch(setHelpContent(payload)), 1100)

        return () => {
            dispatch(setHelpContent({data: null, type: null}))
        }
    }, [JSON.stringify(payload), disableDispatch, dispatch])
    return null
}

export default useHelpContent
