import { SpaceType } from '../../types/swagger'
import { RouteProps, Slug } from '../../types'
import routes from '../../routes'

export const spaceTypeToSlug: Record<SpaceType, Slug | null> = {
    map: 'mapSlug',
    presentation: 'presentationSlug',
    booth: 'boothSlug',
    networking: 'networkingSlug',
    cinema: 'cinemaSlug',
    jobs: 'jobsSlug',
    meeting: 'meetingSlug',
    chat_roulette: 'chatRouletteSlug',
    posters: 'posterSlug',
    embedded: 'embeddedSlug',
}

export const spaceTypeToRoute: Record<SpaceType, RouteProps> = {
    // Getters because map is otherwise undefined
    // because of circular import
    get map() { return routes.map },
    get presentation() { return routes.presentationRoom },
    get booth() { return routes.booth },
    get networking() { return routes.networkingArea },
    get cinema() { return routes.cinema },
    get jobs() { return routes.jobs },
    get meeting() { return routes.meeting },
    get posters() { return routes.posters },
    get chat_roulette() { return routes.chatRoulette },
    get embedded() { return routes.embedded },
}
