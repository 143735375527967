import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import CardContainer from './CardContainer'
import { ContentProps } from './GoodiebagContent'
import { AttendeeData, GoodiebagType } from '../types'
import Bookmark from '../Bookmark'
import { S as BusinessCardStyle } from '../../profile/BusinessCard'
import { _ } from '../../../utils/localization'
import openChatModal from '../../chat/openChatModal'
import getMugshotSrc from '../../../utils/getMugshotSrc'
import GeneralInfoWithThumbnail from '../../../components/GeneralInfoWithThumbnail'
import StyledIcon from '../../../components/Icon'
import useSlug from '../../../hooks/useSlug'
import { formatJobInfo } from '../../../utils/profile'
import { getSocialIconColor } from '../../profile/EditProfileForm'
import colors from '../../../constants/colors'
import CustomButton from '../../../components/CustomButton'
import Icon from '../../../components/Icon/Icon'

const S = {
    ...BusinessCardStyle,
    Wrapper: styled.div`
        width: 100%;
        margin-bottom: 20px;
        padding: 10px;
        border: 1px solid rgba(176, 190, 197, 0.3);
        border-radius: 4px;
    `,
    BusinessCardFooterHeader: styled.p`
        padding: 0 16px;
        background-color: #386080;
        color: white;
        font-weight: bold;
    `,
    BusinessCardFooter: styled.div`
        display: flex;
        gap: 20px;
    `,
    Social: styled.a<{disabled: boolean}>`
        display: grid;
        place-items: center;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background: white;
        box-shadow: 0px 0px 2px rgba(41, 41, 41, 0.1), inset 0px 0px 2px rgba(41, 41, 41, 0.1);
        cursor: pointer;
        pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
    `,
    // eslint-disable-next-line react/display-name
    Icon: (props: any) => <StyledIcon size={30} {...props} />,
    Actions: styled.div`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;
    `
}

const Attendee = ({ attendee }: { attendee: AttendeeData }) => {
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const description = formatJobInfo(attendee)

    return (
        <S.Wrapper>
            <div>
                <GeneralInfoWithThumbnail
                    image={getMugshotSrc(attendee)}
                    name={`${attendee.first_name} ${attendee.last_name}`}
                    description={description}
                    noBorder
                    badge={attendee.is_online ? 'success' : 'danger'}
                >
                    <Bookmark
                        item={{ id: attendee.id, type: GoodiebagType.Attendee }}
                        size={20}
                    />
                </GeneralInfoWithThumbnail>

                <S.BusinessCardBody>
                    {attendee.about || _('profile.card.no_about_for_user')}
                </S.BusinessCardBody>

                <S.BusinessCardFooter>
                    <>
                        <S.Social href={attendee.linkedin} target="__blank" disabled={!attendee.linkedin}>
                            <S.Icon
                                icon="linkedIn"
                                color={attendee.linkedin ? getSocialIconColor('linkedin') : colors.icon}
                                size={24}
                            />
                        </S.Social>
                        <S.Social href={attendee.facebook} target="__blank" disabled={!attendee.facebook}>
                            <S.Icon
                                icon="facebook"
                                color={attendee.facebook ? getSocialIconColor('facebook') : colors.icon}
                                size={24}
                            />
                        </S.Social>

                        <S.Social href={attendee.twitter} target="__blank" disabled={!attendee.twitter}>
                            <S.Icon
                                icon="twitter"
                                color={attendee.twitter ? getSocialIconColor('twitter') : colors.icon}
                                size={24}
                            />
                        </S.Social>
                        <S.Social href={attendee.instagram} target="__blank" disabled={!attendee.instagram}>
                            <S.Icon
                                icon="instagram"
                                color={attendee.instagram ? getSocialIconColor('instagram') : colors.icon}
                                size={24}
                            />
                        </S.Social>
                    </>
                </S.BusinessCardFooter>
                <S.Actions>
                    <CustomButton
                        variant="secondary"
                        onClick={() => dispatch(openChatModal(eventSlug, attendee.id))}
                    >
                        <Icon icon="message" size={18} className="mr-2" />
                        {_('networking.attendee_list.open_chat')}
                    </CustomButton>
                </S.Actions>
            </div>
        </S.Wrapper>
    )
}

const Attendees = ({ items }: ContentProps) => (
    <CardContainer>
        {items.map(({ user }) => (
            <Attendee attendee={user} key={user.id} />
        ))}
    </CardContainer>
)

export default Attendees
