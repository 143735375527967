import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Text from 'react-texty'
import getMugshotSrc from '../../../utils/getMugshotSrc'
import colors from '../../../constants/colors'
import { formatJobInfo } from '../../../utils/profile'
import { VirtualAttendee, VirtualAttendeeTags } from '../../../types/swagger'
import openBusinessCard from '../../profile/openBusinessCard'
import { User } from '../../auth/types'
import { expandedSideBarSelector } from '../../attendees/attendeesSlice'

const S = {
    InfoWrapper: styled.div<{ isOnline: boolean }>`
        display: flex;
        align-items: center;
        flex-grow: 1;
        cursor: pointer;
        overflow: hidden;
        opacity: ${({ isOnline }) => (isOnline ? 1 : 0.5)};
    `,
    Image: styled.img`
        width: 42px;
        height: 42px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-right: 10px;
        flex-shrink: 0;
    `,
    Info: styled.div`
        flex-grow: 1;
        overflow: hidden;
    `,
    Name: styled.p`
        margin-bottom: 0;
        padding-right: 75px;
        font-weight: 500;
        font-size: 14px;
        color: ${colors.mineShaft};
    `,
    Description: styled.p`
        margin-bottom: 0;
        padding-right: 75px;
        font-size: 12px;
        color: ${colors.icon};
    `,
    TagLine: styled.div`
        display: flex;
        align-items: center;
        margin-top: -4px;
        min-height: 14px;
    `,
    TagWrapper: styled.div<{expanded: boolean}>`
        min-width: 40px;
        display: flex;
        flex-shrink: ${({expanded}) => (expanded ? 0 : 1)};
        align-items: center;
        overflow: hidden;
        margin-right: 12px;
    `,
    Tag: styled.span`
        font-size: 12px;
        line-height: 14px;
        color: ${colors.darkTitle};
        opacity: 0.75;
    `,
    Dot: styled.div<{ color: string }>`
        width: 6px;
        height: 6px;
        flex-shrink: 0;
        margin-bottom: -6px;
        border-radius: 50%;
        background-color: ${({ color }) => color};
    `,
    TagNumber: styled.div`
        flex-shrink: 0;
        margin-bottom: -6px;
        width: 30px;
        height: 12px;
        background-color: ${colors.icon};
        font-weight: 500;
        font-size: 10px;
        text-align: center;
        line-height: 12px;
        color: white;
        border-radius: 6px;
    `,
    Tooltip: styled(Tooltip)`
        & .arrow {
            display: none;
        }
        & .tooltip-inner {
            max-width: unset;
            background-color: white;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }
    `,
}

interface TagWithColorProps {
    tag: VirtualAttendeeTags[0]
    className?: string
    expanded?: boolean
}

export const TagWithColor = ({
    tag, className, expanded
}: TagWithColorProps) => (
    <S.TagWrapper className={className} expanded={expanded}>
        <S.Dot color={tag.color} />
        <Text
            tooltip={tag.text}
            tooltipClassName="truncate-tooltip"
            hideArrow
            placement="bottom"
            tooltipMaxWidth={500}
            hideDelay={0}
            showDelay={0}
            style={{marginLeft: 4}}
            className="notranslate"
        >
            <S.Tag>{tag.text}</S.Tag>
        </Text>
    </S.TagWrapper>
)

const TagNumber = ({ tags }: { tags: VirtualAttendeeTags }) => {
    const [show, setShow] = useState(false)
    return (
        <OverlayTrigger
            placement="bottom-end"
            overlay={(
                <S.Tooltip id="tooltip-tags">
                    <S.TagLine>
                        {tags.map((tag, i) => (
                            <TagWithColor key={i} tag={tag} expanded className="tag-color" />
                        ))}
                    </S.TagLine>
                </S.Tooltip>
            )}
        >
            <S.TagNumber onClick={() => setShow(!show)}>{`+${tags.length}`}</S.TagNumber>
        </OverlayTrigger>
    )
}

const NUMBER_SHOWED_TAGS = 3
const NUMBER_SHOWED_TAGS_EXPANDING = 6

const AttendeeInfoWithTags = ({ attendee }: { attendee: VirtualAttendee }) => {
    const dispatch = useDispatch()
    const description = formatJobInfo(attendee)
    const showBusinessCard = () => dispatch(openBusinessCard(attendee as User))
    const expandedSidebar = useSelector(expandedSideBarSelector)
    const numberOfShowedTags = expandedSidebar ? NUMBER_SHOWED_TAGS_EXPANDING : NUMBER_SHOWED_TAGS
    const showedTags = attendee.tags.slice(0, numberOfShowedTags)
    const hiddenTags = attendee.tags.slice(numberOfShowedTags)
    const shouldExpandTags = (index: number) => (
        (index < showedTags.length - 1 && expandedSidebar) || showedTags.length < NUMBER_SHOWED_TAGS
    )

    return (
        <S.InfoWrapper onClick={showBusinessCard} isOnline={attendee.is_online}>
            <S.Image src={getMugshotSrc(attendee)} />
            <S.Info>
                <S.Name className="text-cut notranslate">{`${attendee.first_name} ${attendee.last_name}`}</S.Name>
                <S.Description className="text-cut">{description}</S.Description>
                <S.TagLine>
                    {showedTags.map((tag, i) => (
                        <TagWithColor
                            key={i}
                            tag={tag}
                            className="tag-color"
                            expanded={shouldExpandTags(i)}
                        />
                    ))}
                    {!!hiddenTags.length && <TagNumber tags={hiddenTags} />}
                </S.TagLine>
            </S.Info>
        </S.InfoWrapper>
    )
}

export default AttendeeInfoWithTags
