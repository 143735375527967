import { RoutesMapWithPathResolvers } from './types'

// Import routes from features
import videoCallRoutes from './features/videocall/routes'
import imageMapRoutes from './features/imagemap/routes'
import authRoutes from './features/auth/routes'
import presentationRoutes from './features/presentation/routes'
import networkingAreaRoutes from './features/networking/routes'
import eventRoutes from './features/events/routes'
import boothRoutes from './features/exhibition/routes'
import goodiebagRoutes from './features/goodiebag/routes'
import cinemaRoutes from './features/cinema/routes'
import meetingSpaceRoutes from './features/meetings/routes'
import jobsRoutes from './features/jobs/routes'
import posterRoutes from './features/posters/routes'
import browserStatusRoutes from './features/browsersupport/routes'
import chatrouletteRoutes from './features/chatroulette/routes'
import attendeeRoutes from './features/attendees/routes'
import gdprRoutes from './features/gdpr/routes'
import appointmentRoutes from './features/appointment_schedules/routes'
import embeddedRoutes from './features/embedded_space/routes'
import env from './constants/env'

const routes: RoutesMapWithPathResolvers = {
    ...browserStatusRoutes,
    ...authRoutes,
    ...videoCallRoutes,
    ...imageMapRoutes,
    ...eventRoutes,
    ...presentationRoutes,
    ...networkingAreaRoutes,
    ...boothRoutes,
    ...goodiebagRoutes,
    ...cinemaRoutes,
    ...meetingSpaceRoutes,
    ...jobsRoutes,
    ...posterRoutes,
    ...chatrouletteRoutes,
    // Only add chatroulette in test and local
    ...(env.debug ? chatrouletteRoutes : {}),
    ...attendeeRoutes,
    ...gdprRoutes,
    ...appointmentRoutes,
    ...embeddedRoutes,
}

export const allRoutes = Object.values(routes)
export const eventRouteList = allRoutes.filter(({ isEventRoute }) => isEventRoute)
export const noEventRouteList = allRoutes.filter(({ isEventRoute }) => !isEventRoute)
export default routes
