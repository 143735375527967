import getCurrentRoute from '../../utils/getCurrentRoute'
import { ParamSlug } from '../../types'
import { getEventPreview } from '../auth/eventPreviewSlice'
import store from '../../store'

/**
 * Used to prefetch "EventPreview" upon first load
 */

export default () => {
    // Use window.location.pathname since we are outside of react router context
    const path = window.location.pathname
    if (path.length > 1) { // Path is not empty or just "/"
        const route = getCurrentRoute(path)
        const params = route?.params as ParamSlug

        const eventSlug = route
            ? params?.eventSlug
            // In this case there is an event slug, but no route was matched
            // So just get the event slug from the path. Most probably path is
            // "/:eventSlug/", which is not a path in itself.
            : path.replace(/\//g, '')

        if (eventSlug) {
            store.dispatch(getEventPreview(eventSlug))
        }
    }
}
