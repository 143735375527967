import React from 'react'
import NavigationPrompt from 'react-router-navigation-prompt'
import Modal from 'react-bootstrap/cjs/Modal'
import Button from 'react-bootstrap/cjs/Button'

interface Props {
    when: boolean | ((crntLocation, nextLocation) => boolean)
    confirmText: string
    okButton?: string
    cancelButton?: string
}
const RouteLeavingPrompt = ({
    when,
    confirmText,
    okButton = 'Yes',
    cancelButton = 'Cancel',
}: Props) => {
    const [show, setShow] = React.useState(true)
    const close = (onCancel) => {
        setShow(false)
        setTimeout(() => {
            onCancel()
            setShow(true)
        }, 250)
    }
    const handleConfirm = (onConfirm) => {
        setShow(false)
        onConfirm()
    }
    return (
        <NavigationPrompt when={when}>
            {({ onConfirm, onCancel }) => (
                <Modal centered show={show} onHide={() => close(onCancel)}>
                    <Modal.Body>
                        <p>{confirmText}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setTimeout(() => close(onCancel), 200)} variant="danger">
                            {cancelButton}
                        </Button>
                        <Button onClick={() => handleConfirm(onConfirm)} variant="success">
                            {okButton}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </NavigationPrompt>
    )
}
export default RouteLeavingPrompt
