import { Theme, ThemeOptions, createTheme as createThemeMui } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import colors from './constants/colors'

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        header: true
        description: true
        text: true
        icon: true
    }
}

interface ExtendedTypographyOptions extends TypographyOptions {
    header: React.CSSProperties
    description: React.CSSProperties
    text: React.CSSProperties
    icon: React.CSSProperties
}

declare module '@mui/material/styles' {
    interface CustomTheme extends Theme {
        customAttribute?: {
            test?: string
        }
    }
    // allow configuration using `createTheme`
    interface CustomThemeOptions extends ThemeOptions {
        customAttribute?: {
            test?: string
        }
    }
    export function createTheme(options?: CustomThemeOptions): CustomTheme;
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        footer: true;
    }
  }

const theme: ThemeOptions = createThemeMui({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 932,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: colors.secondary,
        },
        success: {
            main: colors.success,
        },
        info: {
            main: colors.info,
        },
        warning: {
            main: colors.warning,
        },
        error: {
            main: colors.danger,
        },
        divider: '#bdbdbd',
        grey: {
            50: '#F9F9F9',
            300: '#9898A4',
            500: '#667283',
            800: '#343434',
            900: '#2D3035',
        },
    },
    typography: {
        header: {
            fontSize: '20px',
            color: '#2D3035',
            fontWeight: 500,
        },
        description: {
            fontSize: '14px',
            color: '#667283',
            fontWeight: 300,
        },
        text: {
            fontSize: '14px',
            color: '#343434',
            fontWeight: 400,
        },
        icon: {
            fontSize: '14px',
            color: '#78909C',
            fontWeight: 400,
        }
    } as ExtendedTypographyOptions,
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    height: 44,
                    fontSize: '16px',
                }
            },
            variants: [
                {
                    props: {
                        variant: 'footer'
                    },
                    style: {
                        position: 'fixed',
                        bottom: 'max(env(safe-area-inset-bottom), 10px)',
                        left: 0,
                        width: '100vw',
                        textTransform: 'none',
                        borderRadius: 0,
                        background: colors.midBlue,
                        color: 'white',
                        '&:hover': {
                            background: colors.midBlue,
                            color: 'white',
                        }
                    },
                },
            ],
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: 'rgba(176, 190, 197, 0.2)',
                }
            }
        }
    },
})

export default theme
