import useFetchInfiniteList from '../../../hooks/useFetchInfiniteList'
import useSlug from '../../../hooks/useSlug'
import { Tweet } from '../../../types/__generated_swagger'

const LIMIT = 4

const getKey = (pageIndex, previousPageData, eventSlug) => {
    if (previousPageData && previousPageData.length < LIMIT) {
        // reached the end
        return null
    }
    const searchParams = new URLSearchParams({
        offset: `${pageIndex * LIMIT}`,
        limit: `${LIMIT}`,
    }).toString()
    return `/events/${eventSlug}/tweets/?${searchParams}`
}

export const useFetchTweets = () => {
    const eventSlug = useSlug('eventSlug')
    const result = useFetchInfiniteList<Tweet>(
        (pageIndex, previousPageData) => getKey(pageIndex, previousPageData, eventSlug),
        LIMIT,
        { dedupingInterval: 15000, refreshInterval: 60000 },
    )

    return result
}
