import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TicketCodePage from '../components/TicketCodePage'
import { RootState } from '../../../store'
import { getEventPreview } from '../eventPreviewSlice'
import useSlug from '../../../hooks/useSlug'
import preloadImage from '../../../utils/preloadImage'
import useHelpContent from '../../../hooks/useHelpContent'
import EventNotFound from '../components/EventNotFound'
import FullPageLoading from '../../../components/FullPageLoading'
import useResponsive from '../../../hooks/useResponsive'
import TicketCodePageMobile from '../components/TicketCodePageMobile'

const EnterEvent = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const { eventData, requestStatus } = useSelector((state: RootState) => state.eventPreview)
    const { isLoggedIn } = useSelector((state: RootState) => state.auth)
    const homeSpaceImage = eventData?.home_space?.image
    useHelpContent({data: eventData?.help_content, type: eventData?.help_content_type})
    const { isMobile } = useResponsive()

    useEffect(() => {
        if (isLoggedIn) {
            history.push(`/${eventSlug}`)
        }
    }, [eventSlug, history, isLoggedIn])

    // Fetch page
    useEffect(() => {
        dispatch(getEventPreview(eventSlug))
    }, [dispatch, eventSlug])

    // Preload home space if it exists
    useEffect(() => {
        if (homeSpaceImage) {
            preloadImage(homeSpaceImage)
        }
    }, [homeSpaceImage])

    if (['loading', 'idle'].includes(requestStatus)) {
        return <FullPageLoading />
    }
    if (requestStatus === 'failed' || !eventData) {
        return <EventNotFound />
    }

    return isMobile ? (
        <TicketCodePageMobile eventData={eventData} />
    ) : (
        <TicketCodePage eventData={eventData} />
    )
}

export default EnterEvent
