import React from 'react'
import styled from 'styled-components'
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import { withCapture } from '../../../utils/errorTracker'

const S = {
    Video: styled.video`
        width: 100%;
        height: 100%;
    `,
}

const VideoPlayer = ({ src, autoplay = false }: {src: string, autoplay?: boolean}) => {
    const disablePictureInPicture = true
    const videoRef = React.useRef()
    React.useLayoutEffect(() => {
        const options = {
            autoplay,
            controls: true,
            sources: [{
                src,
                type: 'video/mp4',
            }],
        }
        const onPlayerReady = () => {
            withCapture(() => {
                const vid = document.getElementsByTagName('video').item(0) as HTMLVideoElement & {disablePictureInPicture: boolean}
                vid.disablePictureInPicture = disablePictureInPicture
            })
        }
        const player = videojs(videoRef.current, options, onPlayerReady)
        return () => withCapture(player.dispose)
    })

    return (
        <div data-vjs-player>
            {
                // wrap the player in a div with a `data-vjs-player` attribute
                // so videojs won't create additional wrapper in the DOM
                // see https://github.com/videojs/video.js/pull/3856
            }
            <S.Video
                disablePictureInPicture={disablePictureInPicture}
                ref={videoRef}
                className="video-js vjs-fill vjs-big-play-centered"
            />
        </div>
    )
}

export default VideoPlayer
