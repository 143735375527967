import React, { useEffect } from 'react'
import Button from 'react-bootstrap/cjs/Button'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { MeetingItem } from '../types'
import { _ } from '../../../utils/localization'
import { registerForMeeting, resetRequestStatus } from '../meetingSpaceSlice'
import useSlug from '../../../hooks/useSlug'
import { RootState } from '../../../store'
import Loader from '../../../components/Loader'
import { setRegisterStatus } from '../meetingListSlice'
import { eventSelector } from '../../events/eventSlice'

const RegisterModal = ({ meeting, close }: {meeting: MeetingItem, close: () => void}) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const meetingSpaceSlug = useSlug('meetingSlug')
    const { registerRequestStatus } = useSelector((state: RootState) => state.meeting.space)
    const event = useSelector(eventSelector())

    useEffect(() => {
        if (registerRequestStatus === 'succeeded') {
            dispatch(setRegisterStatus({
                meetingId: meeting.id,
                isRegistered: true,
                attendeeId: event.current_user_attendee_id
            }))
        }
    }, [dispatch, event, meeting.id, registerRequestStatus])

    const S = {
        ButtonWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    `
    }

    const resetStateAndClose = () => {
        dispatch(resetRequestStatus())
        close()
    }

    const sendRequest = () => {
        dispatch(registerForMeeting({
            eventSlug, meetingSpaceSlug, meetingSlug: meeting.slug, register: true
        }))
    }

    if (registerRequestStatus === 'idle') {
        return (
            <div>
                {_('meetingspace.register_modal.body.pre_request')}
                {' '}
                {meeting.title}
                <S.ButtonWrapper>
                    <Button variant="danger" onClick={resetStateAndClose}>{_('button.cancel')}</Button>
                    <Button variant="success" onClick={sendRequest}>{_('meetingspace.register_modal.send_button')}</Button>
                </S.ButtonWrapper>
            </div>
        )
    }
    if (registerRequestStatus === 'failed') {
        return (
            <div>
                {_('meetingspace.register_modal.body.already_registered')}
                <S.ButtonWrapper>
                    <Button variant="danger" onClick={resetStateAndClose}>{_('button.cancel')}</Button>
                </S.ButtonWrapper>
            </div>
        )
    }
    if (registerRequestStatus === 'succeeded') {
        return (
            <div>
                {_('meetingspace.register_modal.body.success')}
                <S.ButtonWrapper>
                    <div />
                    <Button variant="success" onClick={resetStateAndClose}>{_('button.okay')}</Button>
                </S.ButtonWrapper>
            </div>
        )
    }

    return (
        <Loader />

    )
}

export default RegisterModal
