import React, {
    useRef, useState, useEffect, useLayoutEffect
} from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDrop, XYCoord } from 'react-dnd'
import { useSelector, useDispatch } from 'react-redux'

import CurrentUserIcon from './CurrentUserIcon'
import { createNetworkingVideocall, selectPageData } from '../networkingSlice'
import { networkingCallsSelector } from '../networkingCallsSlice'
import CallOnMap from './CallOnMap'
import useSlug from '../../../hooks/useSlug'
import { RootState } from '../../../store'
import networkingArea from '../routes'
import { _ } from '../../../utils/localization'
import NetworkingCallPopover from './NetworkingCallPopover'
import styles from '../../../constants/styles'
import { Videocall } from '../../../types/swagger'
import NetworkingImagePlaceholder from '../../../assets/Networking_placeholder.jpg'

const S = {
    Wrapper: styled.div`
      flex-grow: 1;
      height: 100%;
      position: relative;
      margin-right: ${styles.sidebarWidth}px;
      @media (max-width: 1366px) {
        margin-right: ${styles.smallerSidebarWidth}px;
      }
    `,
    Map: styled.div<{ backgroundImage: string }>`
      background-image: url(${({ backgroundImage }) => backgroundImage || NetworkingImagePlaceholder});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: ${({ backgroundImage }) => (backgroundImage ? 'right top' : 'center')}; 
      flex-grow: 1;
      height: 100%;
    `,
    UserThumbnail: styled.img`
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 3px;
      border: white solid 2px;
      position: absolute;
    `,

}

export const ItemTypes = {
    CURRENT_USER: 'currentUser',
}

interface DragItem {
    type: string
    top: number
    left: number
}

const DECIMAL = 100
const toPercentage = (total, n) => Math.round(((n / total) * 100 * DECIMAL) / DECIMAL)

const MappedCalls = ({ calls } : { calls: Videocall[] }) => (
    <>
        { calls.map((call) => (
            <CallOnMap
                call={call}
                key={call.id}
            />
        )) }
    </>
)

const Map = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const networkingSlug = useSlug('networkingSlug')
    const calls = useSelector(networkingCallsSelector())
    const mappedCalls = calls.filter(({ status }) => status !== 'closed')
    const {
        fetchNetworkingStatus,
        videoCallData,
    } = useSelector((state: RootState) => state.networking)
    const pageData = useSelector(selectPageData(networkingSlug))
    const backgroundImage = pageData && ((pageData.meta && pageData.meta.image))
    const containerRef = useRef<HTMLDivElement>(undefined)
    const [showIcon, setShowIcon] = useState(false)
    const [containerHeight, setContainerHeight] = useState<number>()
    // position in pixels, used for react-dnd
    const [iconPosition, setIconPosition] = React.useState({
        top: 50,
        left: 50,
    })
    const [shouldCreateCall, setShouldCreateCall] = useState(false)
    const isFetched = fetchNetworkingStatus === 'succeeded'
    const isNotFound = isFetched && !pageData

    const [, drop] = useDrop({
        accept: ItemTypes.CURRENT_USER,
        drop: (item:DragItem, monitor) => {
            const newPos = monitor.getClientOffset() as XYCoord

            // don't place icon if drop is handled by child component
            if (!monitor.didDrop()) {
                const left = Math.round(newPos.x - containerRef.current.offsetLeft)
                const top = Math.round(newPos.y - containerRef.current.offsetTop)

                setShowIcon(true)
                moveIcon(left, top)
            }

            return undefined
        },
    })

    useLayoutEffect(() => {
        if (containerRef.current) {
            setContainerHeight(containerRef.current.clientHeight)
        }
    }, [containerRef])

    useEffect(() => {
        if (videoCallData && shouldCreateCall) {
            history.push(networkingArea.networkingArea.getPath({
                eventSlug,
                networkingSlug,
                roomId: videoCallData.twilio_name,
            }))
        }
        setShouldCreateCall(false)

        // eslint-disable-next-line
    }, [videoCallData, eventSlug, networkingSlug]); //Don't want to add history into dependency array.

    const moveIcon = async (left: number, top: number) => {
        setIconPosition({
            left,
            top,
        })
        const x = toPercentage(containerRef.current.clientWidth, left)
        const y = toPercentage(containerRef.current.clientHeight, top)
        dispatch(createNetworkingVideocall({
            x, y, pageData, eventSlug,
        }))
        setShouldCreateCall(true)
    }

    if (isNotFound) {
        return <h1>Not found</h1>
    }

    return (
        <S.Wrapper ref={containerRef}>
            {
                isFetched && (
                    <S.Map ref={drop} backgroundImage={backgroundImage}>
                        <NetworkingCallPopover />
                        {!pageData.meta.image && _('networking.no_background_image')}
                        { containerHeight && <MappedCalls calls={mappedCalls} />}
                        {
                            showIcon && (
                                <CurrentUserIcon
                                    left={iconPosition.left}
                                    top={iconPosition.top}
                                />
                            )
                        }
                    </S.Map>
                )
            }
        </S.Wrapper>
    )
}

export default Map
