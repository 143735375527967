import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from '../../utils/api'
import { MeetingSpace } from './types'
import { RootState } from '../../store'
import { RequestStatus } from '../../types'
import { UpdatedPublishedSpace, WS as SpaceWS } from '../spaces/spaceSlice'

interface FetchMeeting {
    eventSlug: string
    meetingSlug: string
}

interface RegisterForMeeting {
    eventSlug: string
    meetingSpaceSlug: string
    meetingSlug: string
    register: boolean
}

export const fetchMeetingSpace = createAsyncThunk(
    'FETCH_MEETING_SPACE',
    ({ eventSlug, meetingSlug }: FetchMeeting) => (
        api.get(`/events/${eventSlug}/meetings/${meetingSlug}/`)
    )
)

export const registerForMeeting = createAsyncThunk(
    'REGISTER_FOR_MEETING',
    ({
        eventSlug, meetingSpaceSlug, meetingSlug, register
    }: RegisterForMeeting) => (
        api.post(`/events/${eventSlug}/meetings/${meetingSpaceSlug}/meetings/${meetingSlug}/register/`, { register })
    )
)

const meetingSpaceSlice = createSlice({
    name: 'meetingSpace',
    initialState: {
        registerRequestStatus: 'idle' as RequestStatus,
        data: {} as Record<string, MeetingSpace>,
        status: 'idle' as RequestStatus,
    },
    reducers: {
        resetRequestStatus: (state) => {
            state.registerRequestStatus = 'idle'
        },
    },
    extraReducers: (builder) => {
        builder.addCase(registerForMeeting.fulfilled, (state) => {
            state.registerRequestStatus = 'succeeded'
        })
        builder.addCase(registerForMeeting.pending, (state) => {
            state.registerRequestStatus = 'loading'
        })
        builder.addCase(registerForMeeting.rejected, (state) => {
            state.registerRequestStatus = 'failed'
        })
        builder.addCase(fetchMeetingSpace.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchMeetingSpace.fulfilled, (state, { payload }: PayloadAction<MeetingSpace>) => {
            state.status = 'succeeded'
            state.data[payload?.meta?.slug] = payload
        })
        builder.addCase(
            SpaceWS.publishedSpaceUpdated,
            (state, { payload }: PayloadAction<UpdatedPublishedSpace>) => {
                if (state.data[payload.space_slug]) {
                    state.data[payload.space_slug].meta.published = payload.published
                }
            }
        )
    }
})

export const selectMeetingSpace = (slug: string) => (state: RootState) => state.meeting.space.data[slug]
export const selectMeetingSpaceStatus = (state: RootState) => state.meeting.space.status

export default meetingSpaceSlice.reducer

export const resetRequestStatus = meetingSpaceSlice.actions.resetRequestStatus
