import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Logger from 'js-logger'
import useSlug from '../../hooks/useSlug'
import { RootState } from '../../store'
import websocket, { LoginThunkProps } from '.'
import sendToWs from './sendToWs'
import useDebounce from '../../hooks/useDebounce'
import { raiseError } from '../../utils/errorTracker'

// server sets user to offline after 5 mins without keep alive
const KEEP_ALIVE_INTERVAL = 60 * 1000 * 2 // 2 mins

const ATTEMPTS = 50
let connectAttempts = 0 // not a state so we dont trigger rerenders

const WebsocketProvider = () => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const accessToken = useSelector((state: RootState) => state.auth.accessToken)
    const websocketState = useSelector((state: RootState) => state.websocket)

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (websocketState.isConnected) {
                dispatch(sendToWs({ type: 'user_is_alive' }))
            }
        }, KEEP_ALIVE_INTERVAL)

        return () => {
            clearInterval(intervalId)
        }
    }, [dispatch, websocketState.isConnected])

    // Debounce to prevent double calls
    const loginWs = useDebounce((args: LoginThunkProps) => {
        dispatch(websocket.login(args))
    }, 1000)

    // Debounce to prevent double calls
    const connectWs = useDebounce(() => {
        if (++connectAttempts > ATTEMPTS) {
            raiseError(`Connect websocket attempt limit of ${ATTEMPTS} has been reached`)
        } else {
            dispatch(websocket.connect())
        }
    }, 1000)

    useEffect(() => {
        if (!accessToken && websocketState.isConnected) {
            Logger.debug('WebsocketProvider: Dispatch Disconnect')
            dispatch(websocket.disconnect())
            return
        }

        if (!accessToken) {
            return
        }

        if (!websocketState.isConnected) {
            Logger.debug('WebsocketProvider: Dispatch Connect')
            connectWs()
            return
        }

        if (eventSlug) {
            Logger.debug(`WebsocketProvider: Dispatch Login with eventslug ${eventSlug}`)
            const args: LoginThunkProps = { eventSlug, accessToken }
            loginWs(args)
        } else {
            Logger.debug('WebsocketProvider: Can not dispatch login without eventslug')
        }

        // Disconnect on unmounting websocket provider
        return () => {
            Logger.info('Disconnect websocket because WebsocketProvider is umounted')
            dispatch(websocket.disconnect())
        }
    }, [accessToken, connectWs, dispatch, eventSlug, loginWs, websocketState.isConnected])

    if (websocketState.isLoggedIn) {
        connectAttempts = 0
    }

    return null
}

export default WebsocketProvider
