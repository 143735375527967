import { useSelector } from 'react-redux'
import { isInSpaceSelector } from '../features/spaces/spaceSlice'

const useCloseSpace = (spacePublished) => {
    const isInSpace = useSelector(isInSpaceSelector)

    // If user is in space, don't show close space immediately
    // wait for showing warning dialog and 30s count down
    if (isInSpace && !spacePublished) {
        return false
    }

    return !spacePublished
}

export default useCloseSpace
