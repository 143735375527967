import React from 'react'
import styled from 'styled-components'
import { IconOption } from '../../../components/Icon/icons'
import colors from '../../../constants/colors'
import { _ } from '../../../utils/localization'
import Icon from '../../../components/Icon'
import { EventContentProps } from './GoodiebagContent'
import { EventFileItem } from '../eventFilesSlice'

const S = {
    FileTable: styled.table`
        width: 100%;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        background-color: white;
    `,
    TableHeaderRow: styled.tr`
        width: 100%;
        height: 56px;
    `,
    Header: styled.th<{width?: number}>`
        font-weight: 700;
        color: ${colors.goodiebag.headerTable};
        min-width: ${({ width = 150 }) => width}px;
    `,
    TableRow: styled.tr`
        width: 100%;
        height: 50px;
        border-top: 1px solid ${colors.goodiebag.border};
    `,
    TableColumn: styled.td`
        color: ${colors.goodiebag.fileText};
        width: 40px;
    `,
    TableColumnAction: styled.td`
        display: flex;
        justify-content: flex-end;
        height: 50px;
        align-items: center;
    `
}

type FileType = {type: string, icon: IconOption}

const getFileType = (url: string): FileType => {
    if (!url.includes('.')) {
        return { type: 'unknow', icon: 'file' }
    }
    const extension = url.split('.').pop()
    if (extension.startsWith('pp')) { // pptx, ppt, pps...
        return { type: extension, icon: 'filepowerpoint' }
    }
    if (extension.startsWith('doc')) { // doc, docx, ...
        return { type: extension, icon: 'fileword' }
    }
    if (extension === 'pdf') {
        return { type: extension, icon: 'filepdf' }
    }
    if (['jpg', 'jpeg', 'bmp', 'gif', 'png'].includes(extension)) {
        return { type: extension, icon: 'fileimage' }
    }
    return { type: extension, icon: 'file' }
}

const FileRow = ({ file }: { file: EventFileItem }) => {
    const { type, icon } = getFileType(file.url)
    return (
        <S.TableRow>
            <S.TableColumn style={{ padding: '0 21px' }}>
                <Icon icon={icon} color={colors.goodiebag.icon} />
            </S.TableColumn>
            <S.TableColumn style={{ textTransform: 'uppercase' }}>{type}</S.TableColumn>
            <S.TableColumn style={{ width: '100%' }}>{file.name}</S.TableColumn>
            <S.TableColumnAction>
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                    <Icon icon="download" color={colors.goodiebag.icon} style={{ margin: '0 20px' }} />
                </a>
            </S.TableColumnAction>
        </S.TableRow>
    )
}

const FileTable = ({ items }: EventContentProps) => (
    <S.FileTable>
        <thead>
            <S.TableHeaderRow>
                <S.Header width={66} />
                <S.Header>{_('goodiebag.files.table.type_header')}</S.Header>
                <S.Header style={{ flex: 'grow' }}>{_('goodiebag.files.table.name_header')}</S.Header>
                <S.Header />
            </S.TableHeaderRow>
        </thead>
        <tbody>
            {items.map((file) => (
                <FileRow file={file} key={file.id} />
            ))}
        </tbody>
    </S.FileTable>
)

export default FileTable
