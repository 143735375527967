import React from 'react'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { DateTime, isSameDay } from '../../../utils/datetime'
import { getSpacingLeft } from '../calculateSpace'
import useInterval from '../../../hooks/useInterval'

import { mapGetDayWidthToProps } from '../redux/selectors'
import { selectedDateSelector } from '../redux/dateSlice'

interface WrapperProps {
    dayWidth: number
}

const S = {
    Wrapper: connect(mapGetDayWidthToProps)(styled.div<WrapperProps>`
        overflow: visible;
        width: ${({ dayWidth }) => dayWidth}px;
        position: absolute;
    `),

    Indicator: styled.div<StyledIndicatorProps>`
    margin-left: ${({ currentDateTime }) => getSpacingLeft(currentDateTime)};
    position: absolute;

    ::after {
        content: '';
        position: absolute;
        z-index: 99;
        left: -17px; // to center shape
        width: 21px;
        height: ${({tableHeight}) => tableHeight + 40}px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        top: 0;
        border-top: 10px solid red;
        background-color: red;
        background-clip: content-box;
    }
    `,
}

interface StyledIndicatorProps {
    currentDateTime: DateTime
    selectedDate: DateTime
    tableHeight?: number
}

const NowIndicator = ({tableHeight = 0}: {tableHeight: number}) => {
    const time = useInterval(1000 * 60) // refresh every minute
    const selectedDate = useSelector(selectedDateSelector)
    const isToday = isSameDay(selectedDate, new Date())

    return (
        <S.Wrapper>
            {isToday && (
                <S.Indicator currentDateTime={time} selectedDate={selectedDate} tableHeight={tableHeight} />
            )}
        </S.Wrapper>
    )
}

export default NowIndicator
