import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// eslint-disable-next-line import/named
import { getItemSpacingLeft, getItemWidth, TimeTableItem } from '../calculateSpace'
import config from '../config'
import { DateTime } from '../../../utils/datetime'
import { selectedDateSelector } from '../redux/dateSlice'

const S = {
    ItemWrapper: styled.div<{item: TimeTableItem, selectedDate: DateTime}>`
        height: ${config.rowHeight}px;
        position: absolute;
        margin-left: ${({ item, selectedDate }) => getItemSpacingLeft(item, selectedDate)};
        width: ${({ item }) => getItemWidth(item)};
        z-index: 9;
        overflow: hidden;
    `,
}

const TimetableItem = ({item, children}: {item: TimeTableItem, children: any}) => {
    const selectedDate = useSelector(selectedDateSelector)
    return (
        <S.ItemWrapper item={item} selectedDate={selectedDate}>
            { children }
        </S.ItemWrapper>
    )
}

export default TimetableItem
