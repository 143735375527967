import { useDispatch } from 'react-redux'
import React, { useCallback, useState } from 'react'
import { PayloadAction } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'
import {
    cancelInvite,
    InviteAcceptedProps,
    inviteToCall,
    WS
} from '../inviteActions'
import { _ } from '../../../utils/localization'
import CustomPopover from '../../../components/CustomPopover'
import { User } from '../../auth/types'
import { ReceivedFromUserPayload } from '../../websocket/sendToUser'
import { CallConfig } from '../types'
import { getPath } from '../useRedirectToRoom'
import useFetchAttendees from '../../attendees/useFetchAttendees'
import { useReduxBus } from '../../../utils/reduxmiddleware/reduxEventBus'
import useSlug from '../../../hooks/useSlug'

const ModalBody = (
    { isSent, isRejected, invitee }: {isSent: boolean, isRejected: boolean, invitee: User}
) => {
    if (isRejected) {
        return (
            <div>
                {_('send_videocall_invite_modal.invite_denied')}
            </div>
        )
    }
    if (isSent) {
        return (
            <div>
                {_('send_videocall_invite_modal.waiting_for_reply')}
            </div>
        )
    }
    return (
        <div className="notranslate">
            {_('send_videocall_invite_modal.text_before_name')}
            {' '}
            {invitee.first_name}
            {' '}
            {invitee.last_name}
            {' '}
            {_('send_videocall_invite_modal.text_after_name')}
        </div>
    )
}

type InviterModalProps = {inviteeId: number, close: () => void, config: CallConfig}

const InviterModal = ({ inviteeId, close, config }: InviterModalProps) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const eventSlug = useSlug('eventSlug')
    const [isSent, setIsSent] = useState(false)
    const [isRejected, setIsRejected] = useState(false)
    const [invitee] = useFetchAttendees([inviteeId])

    // Handle reply
    useReduxBus((action: PayloadAction<ReceivedFromUserPayload<InviteAcceptedProps>>) => {
        history.push(getPath(action.payload.data.twilioName, eventSlug, config))
        close()
    }, WS.inviteAccepted.type)

    useReduxBus(() => {
        setIsRejected(true)
    }, WS.inviteRejected.type)

    const onConfirm = () => {
        dispatch(inviteToCall(inviteeId, config))
        setIsSent(true)
    }

    const onCancel = useCallback(() => {
        dispatch(cancelInvite(inviteeId))
        close()
    }, [close, dispatch, inviteeId])

    return (
        <CustomPopover
            title={_('send_videocall_invite_modal.header')}
            confirmButton={!isSent && _('send_videocall_invite_modal.invite_button')}
            cancelButton={_('button.cancel')}
            onConfirm={onConfirm}
            onCancel={onCancel}
            cancelIcon="close"
            confirmIcon="check"
        >
            {invitee && (
                <ModalBody
                    isSent={isSent}
                    isRejected={isRejected}
                    invitee={invitee}
                />
            )}
        </CustomPopover>
    )
}

export default InviterModal
