import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Icon from '../../../components/Icon'
import styles from '../../../constants/styles'
import useClickOutSide from '../../../hooks/useClickOutSide'
import { _ } from '../../../utils/localization'
import { useFetchTweets } from '../../integrations/twitter/hooks'
import Twitter from '../../integrations/twitter/Twitter'
import PublicChat from '../../publicchat/PublicChat'

const S = {
    Wrapper: styled.div<{isShow: boolean}>`
        background: white;
        width: ${styles.sidebarWidth}px;
        height: 100%;
        position: fixed;
        top: 0;
        display: flex;
        flex-direction: column;
        padding-top: ${styles.topNavHeight}px;
        padding-bottom: 10px;
        right: ${({ isShow }) => (isShow ? 0 : -(styles.sidebarWidth))}px;
        transition: 0.75s;
        @media (max-width: 1366px) {
            width: ${styles.smallerSidebarWidth}px;
            right: ${({ isShow }) => (isShow ? 0 : -(styles.smallerSidebarWidth))}px;
        }
    `,
    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 10px;
        font-size: 20px;
        font-weight: 500;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    `,
    Tab: styled.div`
        position: absolute;
        left: 0;
        top: 50%;
        width: 36px;
        padding: 24px 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        flex-direction: column;
        gap: 10px;
        border-radius: 8px 0 0 8px;
        transform: translate(-100%, -50%);
        box-shadow: -2px 1px 2px rgba(0, 0, 0, 0.1);
    `,
    TweetButton: styled.a`
        display: flex;
        align-items: center;
        padding: 4px 12px;
        font-size: 12px;
        border-radius: 99px;
        color: white;
        background-color: #1d9bf0;
        font-weight: 500;
        cursor: pointer;
        &:hover {
            color: white;
            background-color: #0c7abf;
        }
    `
}

const Sidebar = (
    { chatId, twitterSearch }: {chatId?: number, twitterSearch?: string}
) => {
    const [tab, setTab] = useState<'' | 'publicChat' | 'twitter'>('')
    const ref = useRef(null)
    const fetchedTweetsProps = useFetchTweets()

    useClickOutSide(ref, () => tab !== '' && setTab(''))

    return (
        <S.Wrapper isShow={!!tab} ref={ref}>
            <S.Tab>
                {chatId && (
                    <Icon
                        icon="forum"
                        onClick={() => setTab(tab === 'publicChat' ? '' : 'publicChat')}
                        isActive={tab === 'publicChat'}
                    />
                )}

                {twitterSearch && (
                    <Icon
                        icon="twitter"
                        onClick={() => setTab(tab === 'twitter' ? '' : 'twitter')}
                        isActive={tab === 'twitter'}
                    />
                )}
            </S.Tab>
            <S.Header>
                {tab === 'publicChat' ? _('presentation.public_chat.title') : 'Twitter feed'}
                {tab === 'twitter' && twitterSearch && (
                    <S.TweetButton
                        href={`https://twitter.com/intent/tweet?hashtags=${twitterSearch.replace(/#/g, '')}`}
                    >
                        <Icon icon="twitter" size={18} className="mr-1 mb-2px" color="white" />
                        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                        Tweet {twitterSearch}
                    </S.TweetButton>
                )}
            </S.Header>
            {tab === 'publicChat' && chatId && <PublicChat chatId={chatId} />}
            <Twitter twitterSearch={twitterSearch} isSelected={tab === 'twitter'} {...fetchedTweetsProps} />
        </S.Wrapper>
    )
}

export default Sidebar
