import { createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { RetrieveAccessEventResponse } from '../../types/swagger'
import api from '../../utils/api'
import createRequestSlice from '../../utils/createRequestSlice'
import { RootState } from '../../store'

export const fetchEvent = createAsyncThunk(
    'GET_EVENTS',
    async ({ eventSlug }: { eventSlug: string }, thunkApi) => {
        const { error, data } = await api.safeGet<RetrieveAccessEventResponse>(`/events/${eventSlug}/access/`)
        if (error) {
            return thunkApi.rejectWithValue(error)
        }
        return data
    },
)

const eventSlice = createRequestSlice<RetrieveAccessEventResponse>(fetchEvent, 'fetchEvent')
export const emptyData = eventSlice.actions.emptyData
export const selectEventSlug = (state: RootState) => state.event.current.data.slug
export const eventSelector = () => createSelector(
    [(state: RootState) => state.event.current],
    (event) => event.data,
)
export const programTagsSelector = (state: RootState) => (state.event.current?.data?.program_tags || [])
export const eventLocaleSelector = (state: RootState) => state?.eventPreview?.eventData?.language
export const currentUserIsMeetingSpeakerSelector = (state: RootState) => (
    state?.event.current?.data?.current_user_is_meeting_speaker
)

export default eventSlice.reducer
