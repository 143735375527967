import addDays from 'date-fns/addDays'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../store'

const initState: Date | null = null

const dateSlice = createSlice({
    name: 'date',
    initialState: initState,
    reducers: {
        selectDate: (state, { payload }) => payload,
        incrementDate: (state) => {
            if (!state) {
                return addDays(new Date(), 1)
            }
            return addDays(state, 1)
        },
        decrementDate: (state) => {
            if (!state) {
                return addDays(new Date(), -1)
            }
            return addDays(state, -1)
        },
    },
})

export const selectedDateSelector = (state: RootState) => state.timetable.date || new Date()

export const { incrementDate, decrementDate, selectDate } = dateSlice.actions

export default dateSlice.reducer
