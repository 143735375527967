import { VirtualAttendee } from '../../types/swagger'
import { AttendeeCallStatus } from './types'
import statuses from './statuses'

export default (attendee: VirtualAttendee): AttendeeCallStatus => {
    if (attendee.do_not_disturb || !attendee.is_online) {
        return statuses.unavailable.value
    }
    return attendee?.videocall_status?.status || statuses.available.value
}
