import React from 'react'
import styled from 'styled-components'

const S = {
    Container: styled.div`
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    ContentWrapper: styled.div`
        min-width: 450px;
        max-width: 510px;
        max-height: 80vh;
        padding: 30px;
        border-radius: 10px;
        background: rgba(240, 240, 240, 0.9);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        overflow: auto;
    `,
}

const EventNotFound = () => (
    <S.Container>
        <S.ContentWrapper>
            <h3>404 - Event not found</h3>
        </S.ContentWrapper>
    </S.Container>
)

export default EventNotFound
