import React from 'react'
import styled from 'styled-components'
import Icon from '../../components/Icon'
import { getStatusColor } from '../attendees/statuses'
import { AttendeeCallStatus } from '../attendees/types'

const S = {
    CallStatus: styled.div`
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
    `,
    NumberOfUsers: styled.span`
      position: absolute;
      font-size: 10px;
      margin-left: 30%;
      margin-top: 4%;
      color: white;
    `,
}

type CallStatusProps = {
  status: AttendeeCallStatus, amountOfUsers: number, onClick?: () => void, hide?: boolean
}

const CallStatus = ({ status = 'open', amountOfUsers = 0, onClick, hide }: CallStatusProps) => (
    <S.CallStatus className="status" onClick={onClick} style={{display: hide ? 'none' : 'block'}}>
        <S.NumberOfUsers>{ amountOfUsers || '' }</S.NumberOfUsers>
        <Icon icon="video" color={getStatusColor(status)} />
    </S.CallStatus>
)

export default CallStatus
