import { format, startOfDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import _formatDistance from 'date-fns/formatDistance'
import parseISO from 'date-fns/parseISO'
import uniqBy from 'lodash/uniqBy'
import { raiseError } from './errorTracker'

export type DateTime = Date | string | number;

const parseDateTimeString = (dateTime: string) => {
    // E.g: 2020-10-10T04:13:00 or 2020-10-10T04:13 or 2020-10-10T04:13:00.45623
    const noTimeZoneRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}){0,1}(\.\d+){0,1}$/
    if (noTimeZoneRegex.test(dateTime)) {
        // The dateTime return from server is UTC dateTime by convention, but the format likes 2020-10-10T04:13:00
        // The UTC ISO format for parseISO likes 2020-10-10T04:13:00+00:00. So concat suffix (+00:00) to follow ISO 8601 format
        const ISODatetime = parseISO(`${dateTime}+00:00`)
        if (isDate(ISODatetime)) { return ISODatetime }
        raiseError(`dateTime ${dateTime} passed noTimeZoneRegex, but could not be converted into Date object`)
    }

    return new Date(dateTime)
}

const isDate = (dateTime: Date | undefined): dateTime is Date => !!dateTime && !!dateTime.getDate

const toDateSwitch = (dateTime: DateTime): Date | undefined => {
    switch (typeof dateTime) {
    case 'number': return new Date(dateTime as number) || undefined
    case 'string': return parseDateTimeString(dateTime)
    case 'object': return isDate(dateTime) ? (dateTime as Date) : undefined
    default: return undefined
    }
}

export const toDate = (dateTime: DateTime): Date => {
    const value = toDateSwitch(dateTime)
    if (isDate(value)) {
        return value
    }
    raiseError(`toDate can't create date for value ${dateTime}`)
}

export const padTime = (n: number): string => `${n < 10 ? '0' : ''}${n}`

export const toLocalDateTimeFormat = (dateTime: DateTime, timeFormat?: string): string => (
    `${toLocalTimeFormat(dateTime, timeFormat)} | ${toLocalDateFormat(dateTime)}`
)

export const toLocalDateFormat = (dateTime: DateTime): string => {
    const date = toDate(dateTime)
    const year = `${date.getFullYear()}`
    return date.toDateString().replace(year, '')
}

export const getTimeFormat = (timeFormat: string): string => {
    if (timeFormat === 'P') {
        return 'hh:mm a'
    }

    return 'HH:mm'
}

export const toLocalTimeFormat = (dateTime: DateTime, timeFormat = ''): string => (
    format(new Date(dateTime), getTimeFormat(timeFormat))
)

export const isSameDay = (dateTimeA: DateTime, dateTimeB: DateTime): boolean => (
    toDate(dateTimeA).getDate() === toDate(dateTimeB).getDate()
)

export const isInBetween = (
    fromDateTime: DateTime, untilDateTime: DateTime, compareDateTime: DateTime,
): boolean => {
    const isAfter = toDate(fromDateTime) <= toDate(compareDateTime)
    const isBefore = toDate(untilDateTime) >= toDate(compareDateTime)
    return isAfter && isBefore
}

export const getMinutes = (dateTime: DateTime): number => toDate(dateTime).getTime() / 1000 / 60

export const getStartOfToday = (): Date => {
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    return now
}

export const getStartOfDay = (dateTime: DateTime): Date => {
    const now = new Date(dateTime)
    now.setHours(0, 0, 0, 0)
    return now
}

export const minutesToTime = (totalMinutes: number): string => {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    return `${padTime(hours)}:${padTime(minutes % 60)}`
}

export const compareDate = (a: DateTime, b: DateTime): number => toDate(a).getTime() - toDate(b).getTime()

export const getTimeZone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone

// As discussed, all datetimes that are returned from the server are in UTC.
// This function to convert string dateTime from server (eg: "2020-09-21T03:00:00") to UTC datetime.
const dateTimeToUtc = (dateTime: DateTime): number => {
    const dateTimeObject = new Date(dateTime)
    return Date.UTC(
        dateTimeObject.getFullYear(),
        dateTimeObject.getMonth(),
        dateTimeObject.getDate(),
        dateTimeObject.getHours(),
        dateTimeObject.getMinutes(),
        dateTimeObject.getSeconds(),
        dateTimeObject.getMilliseconds(),
    )
}

// Convert UTC DateTime get from server to DateTime with current TimeZone
export const toDateWithTimeZone = (dateTime: DateTime): Date => {
    const timeZone = getTimeZone()
    const utcDateTime = dateTimeToUtc(dateTime)
    return utcToZonedTime(utcDateTime, timeZone)
}

export const isUpcomingDate = (start_date: DateTime, end_date: DateTime): boolean => (
    isInBetween(toDate(start_date), toDate(end_date), new Date())
    || compareDate(toDate(start_date), new Date()) >= 0
)

export const formatDistance = (date1: DateTime, date2: DateTime): string => (
    _formatDistance(toDate(date1), toDate(date2))
)

export const formatTimeAgo = (date: DateTime): string => (
    `${formatDistance(Date.now(), date)} ago`
)

export const formatStartAndEndDate = (startDate: DateTime, endDate: DateTime) => (
    `${format(new Date(startDate), 'MMM dd, yyyy - HH:mm')} - ${format(new Date(endDate), 'HH:mm')}`
)

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getLocalTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export const getUniqByDay = (data: string[]) => uniqBy(
    data, (date) => startOfDay(new Date(date)).toISOString()
).map((date) => new Date(date))
