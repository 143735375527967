import React, { ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import colors from '../constants/colors'
import constants from '../constants/constants'
import ProfileBadge from '../features/profile/ProfileBadge'
import { addModal } from '../features/modals/modalSlice'

export interface GeneralInfoProps {
    children?: ReactNode
    name?: string
    description?: string
    image?: string
    noBorder?: boolean
    onClick?: () => void
    active?: boolean
    size?: 'sm'
    badge?: 'success' | 'danger'
    editProfileText?: string
    style?: React.CSSProperties
    noImage?: boolean
    className?: string
}

const S = {
    Wrapper: styled.div<GeneralInfoProps & {showPointer?: () => void}>`
        width: 100%;
        // min-height is required for safari. Otherwise, the height of item will be reduce in a overflow-y:auto container.
        min-height: ${({ size }) => (size === 'sm' ? 38 : 48)}px!important;
        display: flex;
        align-items: center;
        padding: ${({ size }) => (size === 'sm' ? '1px 3px' : '3px 5px')};
        ${({ noBorder }) => noBorder && css`
            border: 0;
        `};
        background-color: ${({ active }) => (active ? `${colors.primaryOpacity(0.05)}` : 'transparent')}!important;
        cursor: pointer;
    `,
    InfoWrapper: styled.div`
        flex: 1;
        display: flex;
        align-items: center;
        margin-right: 10px;
        overflow: hidden;
    `,
    ImageWrapper: styled.div<GeneralInfoProps>`
        position: relative;
        &::before {
            content: '';
            display: ${({ badge }) => (badge ? 'block' : 'none')};
            position: absolute;
            top: 0px;
            left: ${({ size }) => (size === 'sm' ? 2 : 4)}px;
            width: ${({ size }) => (size === 'sm' ? 6 : 8)}px;
            height: ${({ size }) => (size === 'sm' ? 6 : 8)}px;
            border-radius: 50%;
            background-color: ${({ badge }) => colors[badge]}
        }
    `,
    Image: styled.img<GeneralInfoProps>`
        width: ${({ size }) => (size === 'sm' ? 32 : 42)}px;
        height: ${({ size }) => (size === 'sm' ? 32 : 42)}px;
        border-radius: 50%;
        object-fit: cover;
    `,
    Info: styled.div`
        flex: 1;
        overflow: hidden;
        margin-left: 8px;
    `,
    Name: styled.div<GeneralInfoProps>`
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: ${colors.mineShaft};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    Description: styled.div<GeneralInfoProps>`
        font-size: 12px;
        line-height: 16px;
        color: ${colors.icon};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    Icons: styled.div`
        display: flex;
        margin-left: 5px;
    `,
    EditProfile: styled.div`
        font-size: 12px;
        line-height: 16px;
        color: ${colors.orange};
        text-decoration: underline;
        cursor: pointer;
        &:hover{
            color: ${colors.orange};
            text-decoration: none;
        }
    `,
}

const GeneralInfoWithThumbnail = ({
    children,
    name,
    description,
    image = constants.urlThumbnailDefault,
    noBorder,
    onClick,
    active,
    size,
    badge,
    editProfileText,
    style,
    noImage,
    className
}: GeneralInfoProps) => {
    const dispatch = useDispatch()

    const showProfileForm = () => {
        dispatch(addModal({
            Component: ({close}: {close: () => void}) => (
                <ProfileBadge close={close} />
            ),
            padding: 0,
        }))
    }

    return (
        <S.Wrapper
            noBorder={noBorder}
            active={active}
            size={size}
            className={`${className} general-info-wrapper`}
            style={style}
        >
            <S.InfoWrapper onClick={onClick} className="general-info">
                {!noImage && (
                    <S.ImageWrapper badge={badge} size={size} className="general-info-image">
                        <S.Image src={image || constants.urlThumbnailDefault} size={size} />
                    </S.ImageWrapper>
                )}
                <S.Info className="general-info-name_description">
                    <S.Name size={size} className="general-info-name notranslate">{name}</S.Name>
                    <S.Description size={size} className="general-info-description editor-content" dangerouslySetInnerHTML={{ __html: description }} />
                    {editProfileText && (
                        <S.EditProfile onClick={showProfileForm}>
                            {editProfileText}
                        </S.EditProfile>
                    )}
                </S.Info>
            </S.InfoWrapper>
            <S.Icons>
                {children}
            </S.Icons>
        </S.Wrapper>
    )
}

export default GeneralInfoWithThumbnail
