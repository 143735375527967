import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useSlug from '../../../hooks/useSlug'
import { fetchMeetingSpace, selectMeetingSpace } from '../meetingSpaceSlice'
import useHelpContent from '../../../hooks/useHelpContent'
import sendToWs from '../../websocket/sendToWs'
import MeetingsOverview from '../components/MeetingsOverview'
import { emptyMeetings, removeOldMeetings } from '../meetingListSlice'
import FullPageLoading from '../../../components/FullPageLoading'
import useInterval from '../../../hooks/useInterval'
import useCloseSpace from '../../../hooks/useCloseSpace'
import useWatchChangeInSpace from '../../../hooks/useWatchChangeInSpace'
import ClosedSpace from '../../../components/ClosedSpace'
import useDispatchAndSelect from '../../../hooks/useDispatchAndSelect'

const subscribe = (slug: string) => sendToWs({
    type: 'join_meeting_space',
    slug,
})

const unsubscribe = (slug: string) => sendToWs({
    type: 'leave_meeting_space',
    slug,
})

const MeetingSpace = () => {
    const eventSlug = useSlug('eventSlug')
    const meetingSlug = useSlug('meetingSlug')
    const space = useDispatchAndSelect(() => fetchMeetingSpace({ eventSlug, meetingSlug }),
        selectMeetingSpace(meetingSlug))
    const dispatch = useDispatch()
    const datetime = useInterval(1000)

    const closeSpace = useCloseSpace(space?.meta?.published)
    useWatchChangeInSpace(space?.meta?.id)

    useHelpContent({data: space?.meta?.help_content, type: space?.meta?.help_content_type})

    useEffect(() => {
        dispatch(emptyMeetings())
    }, [dispatch])

    useEffect(() => {
        dispatch(removeOldMeetings({datetime}))
    }, [datetime, dispatch])

    useEffect(() => {
        dispatch(subscribe(meetingSlug))
        return () => {
            dispatch(unsubscribe(meetingSlug))
        }
    }, [dispatch, meetingSlug])

    if (!space) {
        return <FullPageLoading />
    }

    if (closeSpace) {
        return <ClosedSpace />
    }

    return <MeetingsOverview space={space?.meta} />
}

export default MeetingSpace
