import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { eventPreviewSelector } from '../../auth/eventPreviewSlice'
import { MapType } from '../views/Map'
import { addModal } from '../../modals/modalSlice'
import { _ } from '../../../utils/localization'
import Icon from '../../../components/Icon'
import Twitter from '../../integrations/twitter/Twitter'
import { useFetchTweets } from '../../integrations/twitter/hooks'
import PublicChat from '../../publicchat/PublicChat'

const S = {
    ActionsFooter: styled.div`
        position: fixed;
        bottom: 0;
        left: 0;
        padding-bottom: 14px;
        width: 100vw;
        height: 46px;
        background: #343a40;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 14px;
    `,
    Action: styled.div`
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    Divider: styled.div`
        height: 20px;
        width: 0;
        border: 1px solid #f9f9f9;
        opacity: 0.5;
    `,
}

const MobileFooter = ({ map }: { map: MapType }) => {
    const dispatch = useDispatch()
    const chatId = map?.meta?.chat_id
    const eventPreview = useSelector(eventPreviewSelector)
    const twitterSearch = eventPreview?.twitter_search
    const fetchedTweetsProps = useFetchTweets()

    const showChat = () => {
        dispatch(
            addModal({
                header: _('presentation.public_chat.title'),
                Component: () => <div style={{height: '100%', overflow: 'auto'}}><PublicChat chatId={chatId} /></div>,
                padding: 0,
            })
        )
    }
    const showTwitter = () => {
        dispatch(
            addModal({
                header: 'Twitter',
                Component: () => (
                    <Twitter
                        twitterSearch={twitterSearch}
                        isSelected
                        {...fetchedTweetsProps}
                    />
                ),
                padding: 0,
            })
        )
    }

    if (!chatId && !twitterSearch) {
        return null
    }

    return (
        <S.ActionsFooter>
            {chatId && (
                <S.Action onClick={showChat}>
                    <Icon
                        icon="forum"
                        color="white"
                        size={18}
                        className="mr-1"
                    />{' '}
                    {_('presentation.public_chat.title')}
                </S.Action>
            )}

            {chatId && twitterSearch && <S.Divider />}

            {twitterSearch && (
                <S.Action onClick={showTwitter}>
                    <Icon
                        icon="twitter"
                        color="white"
                        size={18}
                        className="mr-1"
                    />{' '}
                    Twitter
                </S.Action>
            )}
        </S.ActionsFooter>
    )
}

export default MobileFooter
