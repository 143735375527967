import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import FullPageLoading from '../../components/FullPageLoading'
import api from '../../utils/api'
import auth, { Jwt } from '../../utils/auth'
import { _ } from '../../utils/localization'
import { currentUserSelector, getCurrentUser } from '../auth/authSlice'
import { S } from './RemoveAccountSuccessPage'

const ReactivateAccountPage = () => {
    const currentUser = useSelector(currentUserSelector())
    const [processing, setProcessing] = useState(true)
    const dispatch = useDispatch()
    const { search } = useLocation()
    const code = new URLSearchParams(search).get('code')
    const email = new URLSearchParams(search).get('email')

    useEffect(() => {
        const login = async () => {
            if (code && email && currentUser?.email !== email) {
                const response = await api.safePost<Jwt>('/auth/obtain/', { email, code })
                if (!response.error) {
                    auth.storeTokens(response.data)
                    dispatch(getCurrentUser())
                }
            }
            setProcessing(false)
        }
        login()
    }, [code, currentUser, dispatch, email])

    if (processing) {
        return <FullPageLoading />
    }

    return (
        <Container style={{ padding: '50px 200px' }}>
            <S.Header>
                {currentUser
                    ? _('gdpr.reactivate_user_account.success_header')
                    : _('gdpr.reactivate_user_account.failed_header')}
            </S.Header>
            <S.Description>
                {currentUser
                    ? _('gdpr.reactivate_user_account.success_description')
                    : _('gdpr.reactivate_user_account.failed_description')}
            </S.Description>
        </Container>
    )
}

export default ReactivateAccountPage
