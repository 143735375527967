import { combineReducers } from 'redux'
import zoomLevelReducer from './zoomLevelSlice'
import dateReducer from './dateSlice'
import programReducer from './programSlice'
import expandFooterSlice from './expandFooterSlice'
import timeTableSlice from './timeTableSlice'

export default combineReducers({
    zoomLevel: zoomLevelReducer,
    date: dateReducer,
    expand: expandFooterSlice,
    spaces: timeTableSlice,
    programmes: programReducer,
})
