import React, { useCallback, useEffect, useRef } from 'react'
import { format, isAfter, isToday, startOfDay, sub } from 'date-fns'
import { sortBy } from 'lodash'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import useDraggableScroll from 'use-draggable-scroll'
import { ProgramSpace } from '../types'
import TimeTableRow from './TimeTableRow'
import config from '../config'
import TimeGrid from './TimeGrid'
import { mapGetDayWidthToProps } from '../redux/selectors'
import ZoomControl from './ZoomControl'
import colors from '../../../constants/colors'
import DateControl from './DateControl'
import { getItemSpacingLeft, getSpacingLeft } from '../calculateSpace'
import { RootState } from '../../../store'
import TimetableItem from './TimetableItem'
import ProgramItem from './ProgramItem'
import { GroupSessionType, selectGroupSessions } from '../../groupsessions/groupSessionsSlice'
import { _ } from '../../../utils/localization'
import GroupSession from '../../groupsessions/components/GroupSession'
import { selectDate, selectedDateSelector } from '../redux/dateSlice'
import calendarScheduleIcon from '../../../assets/calendar-schedule.svg'
import useFetchAllProgramDates from '../../calendar/hooks/useFetchAllProgramDates'
import Icon from '../../../components/Icon'
import { getUniqByDay } from '../../../utils/datetime'

interface StyledTimeTableWidthProps {
    dayWidth: number
}

const S = {
    Layout: styled.div`
        display: grid;
        grid-template-columns: ${config.headerColumnWidth}px auto;
        grid-template-rows: ${config.timelineNavHeight}px auto;
        grid-template-areas: 
        "left right"
        "left right";
    `,
    LeftWrapper: styled.div`
        grid-area: left;
        max-height: 80vh;
        overflow: auto;
        // Hide scrollbar of the header column
        &::-webkit-scrollbar {
            width: 0;
            background: transparent; /* Chrome/Safari/Webkit */
        }
        & {
            scrollbar-width: none; /* Firefox */
        }
        &:hover {
            overflow: hidden;
        }
    `,
    DateSelectWrapper: styled.div`
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: ${config.timelineNavColor};
    `,
    HeaderColumnWrapper: styled.div`
        position: relative;
        background-color: rgba(128, 128, 128, 0.35);
        padding-bottom: 8px;
        
    `,
    TimeTableWrapper: styled.div`
        grid-area: right;
        position: relative;
        overflow-x: scroll;
        // This works on safari and chrome only:
        overflow-x: overlay;
        scroll-behavior: smooth;
        max-width: calc(100vw - ${config.headerColumnWidth}px);
        max-height: 80vh;
        overflow: auto;
    `,
    TimeTableWidth: styled.div<StyledTimeTableWidthProps>`
        position: relative;
        width: ${({ dayWidth }) => dayWidth}px;
    `,
    AreaHeader: styled.div<{isGroupSession?: boolean}>`
        width: ${config.headerColumnWidth}px;
        font-size: 18px;
        height: ${config.rowHeight}px;
        padding-left: 12px;
        display: flex;
        align-items: center;
        background-color: ${({ isGroupSession }) => (isGroupSession ? colors.red : colors.gray6)};
        color: white;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    `,
    ZoomPosition: styled.div`
        position: absolute;
        right: 0;
        z-index: 100;
    `,
    TimeTable: styled.div`
        
    `,
    UpcomingDateCard: styled.div`
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: calc(100% - 40px);
        padding: 16px 32px;
        background: ${colors.lightBackground};

        & > img {
            opacity: 0.6;
        }

        & > h5 {
            color: ${colors.grayTitle};
            font-size: 20px;
            font-weight: 500;
            margin-top: 16px;
            margin-bottom: 8px;
        }
        & > p {
            color: ${colors.grayDescription};
            font-size: 14px;
        }
        & > div {
            width: auto;
            padding: 4px 8px 4px 16px;
            border-radius: 4px;
            background: ${colors.midBlue};
            color: white;
            font-size: 14px;
            cursor: pointer;
            transition: 0.25s;
            &:hover {
                background: ${colors.blue};
            }
        }
    `
}

interface TimeTableProps {
    programSpaces: ProgramSpace[]
    groupSessions: GroupSessionType[]
    expanded: boolean
}

const TimeTable = ({ programSpaces = [], groupSessions = [], expanded }: TimeTableProps) => {
    const tableRef = useRef<HTMLDivElement>()
    const headerRef = useRef<HTMLDivElement>()
    const timeTableRef = useRef<HTMLDivElement>()
    const {dayWidth} = useSelector(mapGetDayWidthToProps)
    const { onMouseDown } = useDraggableScroll(tableRef)
    const selectedDate = useSelector(selectedDateSelector)
    const zoomLevel = useSelector((state: RootState) => state.timetable.zoomLevel)
    const allProgramDates = useFetchAllProgramDates(true)
    const allGroupSessionDates = useSelector(selectGroupSessions) || []
    const allPlannedDates = sortBy(
        // @ts-ignore
        [...allProgramDates, ...getUniqByDay(allGroupSessionDates.map((e) => e.starts_at))]
    )
    const firstUpcomingDate = allPlannedDates.find((_date) => isAfter(_date, selectedDate))
    // @ts-ignore
    const latestPreviousDate = allPlannedDates.findLast((_date) => isAfter(selectedDate, _date))
    const dispatch = useDispatch()

    const hasPlannedActivity = !!programSpaces.length || !!groupSessions.length

    const scrollTimeTable = useCallback(() => {
        const timetableWidth = tableRef.current?.scrollWidth || 0
        if (isToday(selectedDate)) {
            const spacingLeftPercentage = getSpacingLeft(sub(new Date(), {hours: 1})) // percentage string (eg: "75.06%")
            const spacingLeft = (
                +spacingLeftPercentage.slice(0, spacingLeftPercentage.length - 1) / 100
            ) * timetableWidth
            tableRef.current.scrollTo(
                spacingLeft,
                tableRef.current.scrollTop,
            )
        } else {
            const items = programSpaces.reduce((acc, cur) => {
                acc.push(...cur.items)
                return acc
            }, [])
            const sortedItems = sortBy(items, [(item) => new Date(item.start_date)])
            const startsAt = sortedItems[0] ? new Date(sortedItems[0]?.start_date) : new Date(selectedDate)
            const spacingTopPercentage = getItemSpacingLeft(
                { startsAt: sub(startsAt, { hours: 1 }) }, selectedDate
            )
            const spacingLeft = (
                +spacingTopPercentage.slice(0, spacingTopPercentage.length - 1) / 100
            ) * timetableWidth
            tableRef.current.scrollTo(
                spacingLeft,
                tableRef.current.scrollTop,
            )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programSpaces.map((e) => e.id).toString(), selectedDate, zoomLevel, expanded])

    useEffect(() => {
        if (tableRef.current && hasPlannedActivity) {
            scrollTimeTable()
        }
    }, [selectedDate, scrollTimeTable, hasPlannedActivity])

    useEffect(() => {
        const _tableRef = tableRef?.current
        const handleSyncScroll = () => {
            if (_tableRef && headerRef.current) {
                // We would like to fix the date control and date grid on top when scrolling time table vertically.
                // But header column and timeTable content are in the different blocks.
                // So we refer to y-offset scroll of time table to scroll the header column to the proper position.
                headerRef.current.scrollTo(
                    headerRef.current.scrollLeft,
                    _tableRef.scrollTop
                )
            }
        }

        if (_tableRef) {
            _tableRef.addEventListener('scroll', handleSyncScroll)
        }

        return () => _tableRef?.removeEventListener?.('scroll', handleSyncScroll)
    }, [])

    const groupSessionsOffset = groupSessions.length === 0 ? 0 : 1

    return (
        <S.Layout>
            <S.LeftWrapper ref={headerRef}>
                <S.DateSelectWrapper>
                    <DateControl />
                </S.DateSelectWrapper>

                <S.HeaderColumnWrapper>
                    {groupSessions.length > 0 && (
                        <S.AreaHeader isGroupSession>
                            {_('timetable.row_header.group_sessions')}
                        </S.AreaHeader>
                    )}

                    {programSpaces.map(({id, name}) => (
                        <S.AreaHeader key={id}>
                            {name}
                        </S.AreaHeader>
                    ))}
                </S.HeaderColumnWrapper>
            </S.LeftWrapper>

            <S.TimeTableWrapper ref={tableRef} onMouseDown={onMouseDown}>
                <S.TimeTableWidth dayWidth={dayWidth}>
                    <TimeGrid
                        tableHeight={timeTableRef.current?.scrollHeight}
                        type="timeLine"
                    />
                    <TimeGrid
                        tableHeight={timeTableRef.current?.scrollHeight}
                        type="verticalLine"
                    />

                    <S.TimeTable ref={timeTableRef}>
                        {
                            groupSessions.length > 0 && (
                                <TimeTableRow index={0} isGroupSession>
                                    { groupSessions.map((item) => (
                                        <TimetableItem
                                            item={{startsAt: item.starts_at, endsAt: item.ends_at}}
                                            key={item.id}
                                        >
                                            <GroupSession item={item} />
                                        </TimetableItem>
                                    )) }
                                </TimeTableRow>
                            )
                        }

                        {programSpaces.map((area, i) => (
                            <TimeTableRow key={area.id} index={i + groupSessionsOffset}>
                                { area.items.map((item) => (
                                    <TimetableItem
                                        item={{startsAt: item.start_date, endsAt: item.end_date}}
                                        key={item.id}
                                    >
                                        <ProgramItem item={item} />
                                    </TimetableItem>
                                )) }
                                { area.containers.map((item) => (
                                    <TimetableItem
                                        item={{startsAt: item.start_date, endsAt: item.end_date}}
                                        key={item.id}
                                    >
                                        <ProgramItem item={item} />
                                    </TimetableItem>
                                )) }
                            </TimeTableRow>
                        ))}
                    </S.TimeTable>
                </S.TimeTableWidth>
                {!hasPlannedActivity && (
                    <S.UpcomingDateCard>
                        <img src={calendarScheduleIcon} alt="" />
                        <h5>{_('calendar.no_planned_activities_header')}</h5>
                        {(firstUpcomingDate || latestPreviousDate) ? (
                            <>
                                <p>
                                    {firstUpcomingDate
                                        ? _('calendar.first_upcoming_activity_description')
                                        : _('calendar.latest_previous_activity_description')}
                                    {' '}
                                    {format((firstUpcomingDate || latestPreviousDate), 'MMM do yyyy - HH:mm')}
                                </p>
                                <div
                                    onClick={() => (
                                        dispatch(
                                            selectDate(startOfDay(firstUpcomingDate || latestPreviousDate))
                                        )
                                    )}
                                >
                                    {_('calendar.go_to_button')} {format(firstUpcomingDate || latestPreviousDate, 'MMM dd yyyy')}
                                    <Icon icon="chevronDown" style={{transform: 'rotate(-90deg)'}} color="white" />
                                </div>
                            </>
                        ) : (
                            <p>{_('calendar.no_upcoming_activity_description')}</p>
                        )}
                    </S.UpcomingDateCard>
                )}
            </S.TimeTableWrapper>

            <S.ZoomPosition>
                <ZoomControl />
            </S.ZoomPosition>
        </S.Layout>
    )
}

export default TimeTable
