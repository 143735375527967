import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import colors from '../../constants/colors'
import constants from '../../constants/constants'
import useInterval from '../../hooks/useInterval'
import { VirtualAttendee } from '../../types/swagger'
import { formatTimeAgo } from '../../utils/datetime'
import { User } from '../auth/types'
import openBusinessCard from '../profile/openBusinessCard'
import { PublicMessage } from './messagesSlice'

interface IsSentProps {
    isSent: boolean
}

const getJustifyText = ({ isSent }: IsSentProps) => (!isSent
    ? 'flex-start'
    : 'flex-end')

const getFlexDirection = ({ isSent }: IsSentProps) => (!isSent
    ? 'row'
    : 'row-reverse')

const S = {
    Message: styled.div<IsSentProps>`
        min-height: 62px; // Fix bug can't scroll bottom on safari
        flex-direction: ${getFlexDirection};
        margin: 6px 10px;
        display: flex;
        align-items: start;
        overflow: hidden;
    `,
    UserThumbnail: styled.img`
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
    `,
    MessageInner: styled.div<IsSentProps>`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: ${getJustifyText};
        margin-left: 8px;
        margin-right: 8px;
        overflow: hidden;
    `,

    TextBox: styled.div`
        width: 100%;
        padding: 5px 10px;
        background-color: ${colors.mineShaftOpacity(0.05)};
        border-radius: 4px;
    `,
    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
    `,
    SenderName: styled.p`
        margin: 0;
        font-size: 14px;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `,
    ModeratorIndicator: styled.span`
        color: ${colors.primary};
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 500;
        margin-left: 20px;
        opacity: 0.75;
    `,
    Text: styled.p`
        margin: 0;
        color: ${colors.mineShaftOpacity(0.6)};
        font-size: 12px;
        line-height: 14px;
    `,

    Date: styled.div<IsSentProps>`
        color: ${colors.gray5};
        font-size: 10px;
        flex-grow: 1;
        padding: 3px 8px;
        width: 100%;
    `,
}

interface MessageProps {
    message: PublicMessage
    isSent: boolean
    sender: VirtualAttendee
    moderatorId?: number
}

const Message = ({
    message, isSent, sender, moderatorId
}: MessageProps) => {
    const dispatch = useDispatch()
    useInterval(1000 * 60) // To re-render this component every minute
    const isModerator = sender && moderatorId === sender?.id

    return (
        <S.Message isSent={isSent}>
            <S.UserThumbnail
                src={sender?.mugshot || constants.urlThumbnailDefault}
                alt=""
                onClick={() => dispatch(openBusinessCard(sender as User))}
            />
            <S.MessageInner isSent={isSent}>
                <S.TextBox>
                    <S.Header>
                        <S.SenderName className="notranslate">
                            {`${sender?.first_name || ''} ${sender?.last_name || ''}`}
                        </S.SenderName>
                        {isModerator && (
                            <S.ModeratorIndicator>
                                Moderator
                            </S.ModeratorIndicator>
                        )}
                    </S.Header>
                    <S.Text className="notranslate">{message.text}</S.Text>
                </S.TextBox>
                <S.Date isSent={isSent}>
                    {message.sent_at && formatTimeAgo(message.sent_at)}
                </S.Date>
            </S.MessageInner>
        </S.Message>
    )
}

export default Message
