import React from 'react'
import styled from 'styled-components'
import { Tabs as RTabs, TabsProps as RTabsProps, Tab as RTab } from 'react-bootstrap'
import colors from '../constants/colors'

interface Tab {
    id: string
    name: string
    Component?: React.FC
    hidden?: boolean
}

interface TabsProps extends RTabsProps {
    tabs: Tab[]
    className?: string
}

const S = {
    Container: styled.div`
        margin: 0px;
    `,
    Wrapper: styled.div`
        position: relative;
        padding: 0 10px 20px 10px;
        background: white;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
    `,
    Tabs: styled(RTabs)`
        min-width: 0;
        position: relative;
        border: none;
        &::after {
            content: '';
            margin: 0 -10px;
            height: 0;
            position: absolute;
            width: calc(100% + 20px);
            bottom: -1px;
            border-bottom: 1px solid rgba(176, 190, 197, 0.2);
        }
        & > a {
            min-width: 140px;
            max-width: 250px;
            padding-left: 10px;
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border: none!important;
            font-size: 14px;
            line-height: 16px;
            padding-top: 20px;
            text-transform: uppercase;
            font-weight: 400;
            color: ${colors.grayTitle} !important;
            text-align: center;
            border-bottom: 2px solid transparent !important;
            &[aria-selected="true"] {
                font-weight: 700;
                border-bottom: 2px solid ${colors.grayTitle} !important;
                opacity: 1;
            }
        }
    `,
    TabContent: styled.div`
        display: flex;
        flex-wrap: wrap;
    `,
}

const Tabs = ({
    tabs,
    className,
    ...rest
}: TabsProps) => (
    <S.Container className={className}>
        <S.Wrapper>
            <S.Tabs defaultActiveKey={tabs[0]?.id} {...rest}>
                {tabs.map((tab) => (
                    <RTab eventKey={tab.id} title={tab.name} key={tab.id}>
                        <S.TabContent>
                            <tab.Component />
                        </S.TabContent>
                    </RTab>
                ))}
            </S.Tabs>
        </S.Wrapper>
    </S.Container>
)

export default Tabs
