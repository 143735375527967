import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import {
    Dropdown,
    Form,
    Tab,
    Tabs
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import pickBy from 'lodash/pickBy'
import useSWR from 'swr'
import GeneralInfoWithThumbnail from '../../../../components/GeneralInfoWithThumbnail'
import colors from '../../../../constants/colors'
import useSlug from '../../../../hooks/useSlug'
import { VirtualAttendee } from '../../../../types/swagger'
import { getTimeZone, toLocalDateFormat, toLocalTimeFormat } from '../../../../utils/datetime'
import { _ } from '../../../../utils/localization'
import useFetchAttendees from '../../../attendees/useFetchAttendees'
import { BoothBusinessCardProps, BoothBusinessCardResponse, BusinessCardManagementTabs } from '../../types'
import Icon from '../../../../components/Icon'
import openChatModal from '../../../chat/openChatModal'
import AttendeeCallStatus from '../../../entervideocall/AttendeeCallStatus'
import { manageBusinessCard, RequestBody } from '../../manageBusinessCardSlice'
import BoothBusinessCardModal from './BoothBusinessCardModal'
import { addModal } from '../../../modals/modalSlice'
import useAttendeeCallClick from '../../../entervideocall/useAttendeeCallClick'
import { eventTimeFormatSelector } from '../../../auth/eventPreviewSlice'
import { formatJobInfo } from '../../../../utils/profile'
import BaseTooltip from '../../../../components/BaseTooltip'
import openBusinessCard from '../../../profile/openBusinessCard'
import { listFetcher } from '../../../../utils/api'
import constants from '../../../../constants/constants'

const template = 'auto 130px 160px 50px 50px 60px'

const S = {
    Wrapper: styled.div<{paddingBottom: number}>`
        padding: 10px;
        padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
    `,
    Tabs: styled(Tabs)`
        flex-wrap: nowrap;
        & > a {
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border: none!important;
            transition: 0.3s;
            &[aria-selected="true"] {
                border-bottom: 3px solid gray!important;
            }

            @media (max-width: 932px) {
                flex-shrink: 0;
                width: fit-content;
            }
        }

    `,
    GridRow: styled.div`
        display: grid;
        grid-template-columns: ${template};
        grid-column-gap: 12px;
        border-bottom: 1px solid ${colors.gray2};
        & > * {
            min-width: 0;   
            max-width: 100%;
            display: flex;
            align-items: center;
        }
    `,
    HeaderItem: styled.div<{isRightAlign?: boolean}>`
        font-size: 14px;
        font-weight: 700;
        padding: 20px 0;
        text-align: ${({ isRightAlign }) => (isRightAlign ? 'right' : 'left')};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `,
    Date: styled.div`
        display: flex;
        align-items: center;
        font-size: 14px;
        color: ${colors.grayTitle};
    `,
    FormGroup: styled(Form.Group)`
        margin: 0;
        justify-content: center;
    `,
    UserInfo: styled(GeneralInfoWithThumbnail)`
        cursor: pointer !important;
        padding-left: 0;
        padding-right: 0;
        & > .general-info {
            margin: 0!important;
        }
    `,
    IconActions: styled.div`
        justify-content: flex-end;
    `,
    DropDownToggle: styled(Dropdown.Toggle)`
        margin: 0 5px;
        padding: 0 3px 2px 3px;
        background-color: transparent!important;
        border: none;
        border-radius: 0;
        outline: none;
        box-shadow: none!important;
        &::after {
            display: none;
        }
        &:hover, &:focus, &:active {
            background-color: transparent;
            box-shadow: none!important;
        }
        &[aria-expanded="true"] {
            background-color: transparent!important;
        }
    `,
    DropDownItem: styled(Dropdown.Item)`
        display: flex;
        align-items: center;
    `,
    MoreOptionText: styled.span`
        display: inline-block;
        padding-left: 10px;
    `,
}

const headers = [
    { get name() { return _('booth.leave_businesscard_management.prospect') } },
    { get name() { return _('booth.leave_businesscard_management.updated_date') } },
    { get name() { return _('booth.leave_businesscard_management.assigned_to') }, isRightAlign: true },
    { get name() { return _('booth.leave_businesscard_management.is_auto') } },
    { get name() { return _('booth.leave_businesscard_management.done') } },
    { get name() { return '' } }
]

const StaffSelector = ({
    staffs, onChangeStaff, assignedTo
}: {staffs: VirtualAttendee[], onChangeStaff: (id) => void, assignedTo?: number}) => {
    const formatLabel = (staff) => (staff ? `${staff.first_name} ${staff.last_name}` : '')
    const selectedOption = {
        label: (
            assignedTo
                ? formatLabel(staffs.find((e) => e.id === assignedTo))
                : _('booth.leave_businesscard_management.select_staff')
        ),
        value: assignedTo,
    }
    const options = staffs.map((staff) => ({ value: staff.id, label: `${staff.first_name} ${staff.last_name}` }))

    return (
        <div>
            <Select
                value={selectedOption}
                onChange={(_selectedOption) => onChangeStaff(_selectedOption.value)}
                options={options}
                styles={{
                    container: (styles) => ({ ...styles, flex: 1 }),
                    control: (styles) => ({ ...styles, border: 'none', boxShadow: 'none' }),
                    indicatorSeparator: () => ({ display: 'none' }),
                    singleValue: (styles) => ({ ...styles, fontSize: 14 }),
                    valueContainer: (styles) => ({ ...styles, padding: 0 }),
                    dropdownIndicator: (styles) => ({ ...styles, paddingRight: 0, paddingLeft: 0 }),
                    menuList: (styles) => ({ ...styles, fontSize: 14 }),
                }}
            />
        </div>
    )
}

const MoreOptionDropDown = ({ attendee }: {attendee: VirtualAttendee}) => {
    const dispatch = useDispatch()
    const videoCall = useAttendeeCallClick(attendee)
    const eventSlug = useSlug('eventSlug')

    return (
        <Dropdown>
            <S.DropDownToggle id="dropdown-basic" variant="success">
                <Icon icon="dotsVertical" size={22} />
            </S.DropDownToggle>

            <Dropdown.Menu>
                {attendee.email && (
                    <S.DropDownItem href={`mailto:${attendee?.email}`}>
                        <Icon icon="email" />
                        <S.MoreOptionText>
                            {_('booth.leave_businesscard_management.email')}
                        </S.MoreOptionText>
                    </S.DropDownItem>
                )}
                <S.DropDownItem onClick={() => dispatch(openChatModal(eventSlug, attendee.id))}>
                    <Icon icon="forum" color={colors.primary} />
                    <S.MoreOptionText>{_('booth.leave_businesscard_management.open_chat')}</S.MoreOptionText>
                </S.DropDownItem>
                <S.DropDownItem style={{ display: 'flex' }} onClick={videoCall}>
                    {attendee && <AttendeeCallStatus attendee={attendee} />}
                    <S.MoreOptionText>{_('booth.leave_businesscard_management.video_call')}</S.MoreOptionText>
                </S.DropDownItem>
            </Dropdown.Menu>
        </Dropdown>
    )
}

const BoothBusinessCardRow = ({
    businessCard, staffs, userIds, eventKey
}: BoothBusinessCardProps) => {
    const [done, setDone] = useState(businessCard.done)
    const users = useFetchAttendees(userIds, true)
    const user = users.find((e) => businessCard.user === e.id)
    const [assignedTo, setAssignedTo] = useState(businessCard.assigned_to)
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const boothSlug = useSlug('boothSlug')
    const timeFormat = useSelector(eventTimeFormatSelector)

    useEffect(() => {
        setDone(businessCard.done)
    }, [businessCard.done])

    const callAPI = (assignedToId, doneStatus) => {
        const requestBody = pickBy<RequestBody>({
            ...businessCard,
            done: doneStatus,
            assigned_to: assignedToId,
        }, (e) => e !== null)
        dispatch(manageBusinessCard({
            eventSlug,
            boothSlug,
            id: businessCard.id,
            requestBody,
        }))
    }

    const handleChangeStaff = (id) => {
        callAPI(id, done)
        setAssignedTo(id)
    }

    const handleChangeDoneStatus = () => {
        callAPI(assignedTo, !done)
        setDone(!done)
    }

    const openNote = () => {
        const {user_email, message} = businessCard
        dispatch(addModal({
            Component: ({ close }: {close: () => void}) => (
                <BoothBusinessCardModal
                    user={{ ...user, email: user_email }}
                    close={close}
                    message={message}
                />
            ),
            size: 'lg',
            padding: 0
        }))
    }

    return (
        <S.GridRow key={businessCard.id}>
            {user ? (
                <S.UserInfo
                    image={
                        (businessCard.ignore_incognito_mode ? businessCard.mugshot : user.mugshot)
                        || constants.urlThumbnailDefault
                    }
                    name={(
                        businessCard.ignore_incognito_mode
                            ? `${businessCard.first_name} ${businessCard.last_name}`
                            : `${user.first_name} ${user.last_name}`
                    )}
                    description={formatJobInfo(user)}
                    onClick={() => dispatch(openBusinessCard(user))}
                />
            ) : <div />}
            <S.Date>
                <BaseTooltip
                    text={`Created at: ${toLocalDateFormat(businessCard.created)} ${toLocalTimeFormat(businessCard.created, timeFormat)} - ${getTimeZone()}`}
                >
                    {`${toLocalDateFormat(businessCard.updated)} ${toLocalTimeFormat(businessCard.updated, timeFormat)}`}
                </BaseTooltip>
            </S.Date>
            <StaffSelector
                staffs={staffs}
                onChangeStaff={handleChangeStaff}
                assignedTo={assignedTo}
            />
            <S.FormGroup>
                <Form.Check
                    custom
                    type="checkbox"
                    label=""
                    checked={businessCard.is_auto}
                    onChange={() => null}
                    style={{ pointerEvents: 'none' }}
                    disabled
                />
            </S.FormGroup>
            <S.FormGroup controlId={`form-check-${eventKey}_${businessCard.id}`}>
                <Form.Check
                    custom
                    type="checkbox"
                    label=""
                    checked={done}
                    onChange={handleChangeDoneStatus}
                />
            </S.FormGroup>
            <S.IconActions>
                {businessCard.message && <Icon icon="textBox" onClick={openNote} />}
                <MoreOptionDropDown attendee={{ ...user, email: businessCard.user_email }} />
            </S.IconActions>
        </S.GridRow>
    )
}

const BoothBusinessCardTable = ({
    businessCards, staffs, eventKey
}: BoothBusinessCardProps) => {
    const userIds = businessCards.map((e) => e.user)
    return (
        <>
            <S.GridRow>
                {headers.map((header, index) => (
                    <div key={index}>
                        <S.HeaderItem isRightAlign={header.isRightAlign}>
                            {header.name}
                        </S.HeaderItem>
                    </div>
                ))}
            </S.GridRow>
            {businessCards.map((item) => (
                <BoothBusinessCardRow
                    businessCard={item}
                    staffs={staffs}
                    userIds={userIds}
                    key={item.id}
                    eventKey={eventKey}
                />
            ))}
        </>
    )
}

const BusinessCardManagement = ({ staffs }: {staffs: VirtualAttendee[]}) => {
    const eventSlug = useSlug('eventSlug')
    const boothSlug = useSlug('boothSlug')
    const [searchParams, setSearchParams] = useState({})
    // Currently, don't update the limit of business cards (fixed 999). Will update when having request and design
    const [limit] = useState(999)

    const searchTxt = new URLSearchParams(searchParams).toString()
    const { data } = useSWR<BoothBusinessCardResponse['results']>(
        `events/${eventSlug}/organizer/booths/${boothSlug}/business-cards/?limit=${limit}&${searchTxt}`,
        listFetcher,
        { dedupingInterval: 2000, refreshInterval: 15000 }
    )

    // To avoid overflow-hidden of wrapper cut off the select popup
    // 37px is height of 1 item; 8px is padding top-bottom of popup
    const paddingBottom = staffs.length * 37 + 8

    const [activeTab, setActiveTab] = useState(BusinessCardManagementTabs.ALL)
    const onTabSelect = (eventKey) => {
        setActiveTab(eventKey)
        if (eventKey === BusinessCardManagementTabs.ASSIGNED_TO_ME) {
            setSearchParams({ assigned_to_me: 1 })
        } else if (eventKey === BusinessCardManagementTabs.NOT_ASSIGNED) {
            setSearchParams({ not_assigned: 1 })
        } else if (eventKey === BusinessCardManagementTabs.ALL) {
            setSearchParams({})
        }
    }

    return (
        <S.Wrapper paddingBottom={paddingBottom}>
            <S.Tabs
                defaultActiveKey={activeTab}
                id="uncontrolled-tab-example"
                onSelect={onTabSelect}
            >
                <Tab
                    eventKey={BusinessCardManagementTabs.ALL}
                    title={_('booth.leave_businesscard_management.tabs.all')}
                >
                    <BoothBusinessCardTable
                        businessCards={data || []}
                        staffs={staffs}
                        eventKey={BusinessCardManagementTabs.ALL}
                    />
                </Tab>
                <Tab
                    eventKey={BusinessCardManagementTabs.ASSIGNED_TO_ME}
                    title={_('booth.leave_businesscard_management.tabs.assigned_to_me')}
                >
                    <BoothBusinessCardTable
                        businessCards={data || []}
                        staffs={staffs}
                        eventKey={BusinessCardManagementTabs.ASSIGNED_TO_ME}
                    />
                </Tab>
                <Tab
                    eventKey={BusinessCardManagementTabs.NOT_ASSIGNED}
                    title={_('booth.leave_businesscard_management.tabs.not_assigned')}
                >
                    <BoothBusinessCardTable
                        businessCards={data || []}
                        staffs={staffs}
                        eventKey={BusinessCardManagementTabs.NOT_ASSIGNED}
                    />
                </Tab>
            </S.Tabs>
        </S.Wrapper>
    )
}

export default BusinessCardManagement
