import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { eventLocaleSelector } from '../features/events/eventSlice'

const GoogleTranslateButton = () => {
    const eventLanguage = useSelector(eventLocaleSelector)
    const googleTranslateElementInit = (_eventLanguage) => {
        // @ts-ignore
        // eslint-disable-next-line no-new
        new window.google.translate.TranslateElement({
            pageLanguage: _eventLanguage,
            // @ts-ignore
            layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT
        }, 'google_translate_element')
    }

    useEffect(() => {
        const googleElement = document.getElementById('google_translate_element')
        if (googleElement.hasChildNodes()) {
            return
        }
        const addScript = document.createElement('script')
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
        document.body.appendChild(addScript)
        // @ts-ignore
        window.googleTranslateElementInit = googleTranslateElementInit
    }, [eventLanguage])

    const showOriginal = () => {
        const iframe = document.getElementById(':1.container')
        if (!iframe) return
        // @ts-ignore
        const innerDoc = iframe.contentDocument || iframe.contentWindow.document
        innerDoc.getElementById(':1.restore')?.click()
    }
    const translated = document.getElementById(':1.container')

    return (
        <>
            <div id="google_translate_element" />
            {translated && (
                <Button onClick={showOriginal} className="m-1" size="small" sx={{fontSize: 12, textTransform: 'capitalize'}}>
                    Show original
                </Button>
            )}
        </>
    )
}

export default GoogleTranslateButton
