import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import GeneralInfoWithThumbnail from '../../../components/GeneralInfoWithThumbnail'
import StyledIcon from '../../../components/Icon'
import colors from '../../../constants/colors'
import getMugshotSrc from '../../../utils/getMugshotSrc'
import { User } from '../../auth/types'
import openChatModal from '../../chat/openChatModal'
import openBusinessCard from '../../profile/openBusinessCard'
import { MeetingItem } from '../types'

import { currentUserSelector } from '../../auth/authSlice'
import useSlug from '../../../hooks/useSlug'
import CapacityAndDateTime from './CapacityAndDateTime'
import JoinMeetingButton from './JoinMeetingButton'
import useFetchAttendees from '../../attendees/useFetchAttendees'
import { _ } from '../../../utils/localization'
import { formatJobInfo, formatName } from '../../../utils/profile'

const S = {
    Wrapper: styled.div`
        padding: 40px;
    `,
    Header: styled.div`
        display: flex;
        align-items: flex-start;
        margin-right: 20px;
        & + button {
            margin-top: -6px!important;
            font-size: 37px;
            font-weight: 400;
            &:focus {
                outline: none;
            }
        }
    `,
    CapacityWrapper: styled.div`
      width: 350px;
    `,
    Title: styled.h3`
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px;
    `,
    Divider: styled.div`
        width: 100%;
        height: 1px;
        margin: 20px 0;
        background-color: ${colors.gray2};
    `,
    Description: styled.div`
        &, & * {
            margin: 0;
            font-size: 15px;
            line-height: 18px;
            font-weight: 300;
            color: #667283;
        }
    `,
    Footer: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        & .general-info-wrapper {
            max-width: 400px;
            & .general-info {
                flex: none;
            }
        }
    `,
    ImageOverlay: styled.div`
      background-color: rgba(0, 0, 0, 0.5);
      width: 21px;
      height: 42px;
      margin: 0 0 0 26px;
      position: absolute;
      z-index: 5;
      display: flex;
      border-bottom-right-radius: 120px;
      border-top-right-radius: 120px;
    `,
    ImageOverlayText: styled.div`
      margin: auto;
      color: white;
      font-size: 12px;
      font-weight: 700;
    `,
}

const MeetingInfoModal = ({ meeting, close }: {meeting: MeetingItem, close: () => void}) => {
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const currentUser = useSelector(currentUserSelector())
    const isPrimarySpeaker = meeting?.primary_speaker_id === currentUser.id
    const [fetchedPrimarySpeaker] = useFetchAttendees([meeting?.primary_speaker_id])
    const primarySpeaker = isPrimarySpeaker ? currentUser : fetchedPrimarySpeaker

    return (
        <S.Wrapper>
            <Modal.Header closeButton style={{ padding: 0, border: 'none' }}>
                <S.Header>
                    <S.Title>{meeting.title}</S.Title>
                    {/* <StyledIcon
                        icon="bookmark"
                        color="rgba(100, 124, 138, 0.5)"
                        style={{ marginLeft: 12 }}
                    /> */}
                </S.Header>
            </Modal.Header>
            <S.CapacityWrapper>
                <CapacityAndDateTime meeting={meeting} darkCapacityBackground showDate />
            </S.CapacityWrapper>
            <S.Divider />
            {meeting.description
                ? (<S.Description className="editor-content" dangerouslySetInnerHTML={{ __html: meeting.description }} />)
                : (<S.Description>{_('meetings.info_modal.no_description')}</S.Description>)}
            <S.Divider />
            <S.Footer>
                {/* todo either re-use this in MeetingCard or move it to ImageOverlay */}
                {!!meeting.speaker_count && (
                    <S.ImageOverlay>
                        <S.ImageOverlayText>
                            +
                            {meeting.speaker_count}
                        </S.ImageOverlayText>
                    </S.ImageOverlay>
                )}
                {primarySpeaker ? (
                    <GeneralInfoWithThumbnail
                        name={formatName(primarySpeaker)}
                        description={formatJobInfo(primarySpeaker)}
                        image={getMugshotSrc(primarySpeaker)}
                        noBorder
                        onClick={() => dispatch(openBusinessCard(primarySpeaker as User))}
                    >
                        {meeting.enable_host_chat && !isPrimarySpeaker && (
                            <StyledIcon
                                icon="forum"
                                color={colors.regentGray}
                                onClick={() => dispatch(
                                    openChatModal(eventSlug, meeting?.primary_speaker_id)
                                )}
                            />
                        )}
                    </GeneralInfoWithThumbnail>
                ) : <div />}
                <div onClick={close}>
                    <JoinMeetingButton meeting={meeting} location="infoModal" />
                </div>
            </S.Footer>
        </S.Wrapper>
    )
}

export default MeetingInfoModal
