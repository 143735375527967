import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMessagesThunk, selectChat, setSelectingChatBot } from './chatSlice'

const openChat = (eventSlug: string, userId: number) => createAsyncThunk(
    'OPEN_CHAT_WITH_USER',
    (__, thunkAPI) => {
        thunkAPI.dispatch(selectChat(userId))
        thunkAPI.dispatch(getMessagesThunk({ eventSlug, userId, offset: 0 }))
        thunkAPI.dispatch(setSelectingChatBot(false))
    },
)()

export default openChat
