import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../utils/api'
import { CreateCreatePrivateMessageRequest } from '../../types/__generated_swagger'
import { withCapture } from '../../utils/errorTracker'
// eslint-disable-next-line import/named
import { setMessage } from './chatSlice'
import { Message } from './types'
import { SimpleAppointmentSchedule } from '../../types'
import { sleep } from '../../utils/datetime'

interface SendMessage {
    eventSlug
    text: string;
    currentUserId: number
    receiverId: number
    shouldSendEmail?: boolean
    appointmentSchedule?: SimpleAppointmentSchedule
    callback?: () => void
}

interface ExtraMessageFields {
    should_send_email?: boolean
    event_slug?: string
    appointment_schedule?: number
}

let id = -9999 // to generate unique id's that are only in stored in client

const sendMessage = createAsyncThunk('SEND_MESSAGE', (params: SendMessage, thunkApi) => {
    const {
        text, receiverId, eventSlug, shouldSendEmail, appointmentSchedule, callback,
    } = params

    // Set it to state
    const message: Message = {
        id: --id,
        text,
        sent_by_current_user: true,
        read: true,
        sent_at: new Date().toISOString(),
        sent_email: shouldSendEmail,
        appointment_schedule: appointmentSchedule,
    }
    thunkApi.dispatch(setMessage({ message, userId: receiverId }))

    // Post to server
    const data: CreateCreatePrivateMessageRequest & ExtraMessageFields = {
        text,
        receiver: receiverId,
        should_send_email: shouldSendEmail,
        event_slug: eventSlug,
        appointment_schedule: appointmentSchedule?.id,
    }
    withCapture(async () => {
        const response = await api.post(`/events/${eventSlug}/chat/messages/send/`, data)
        sleep(500)
        callback?.()
        return response
    })
})

export default sendMessage
