import React from 'react'
import Get from '../../../components/Get'
import useSlug from '../../../hooks/useSlug'
import ProgramContainerModal from './ProgramContainerModal'

const FetchProgramContainerModal = ({id}: {id: number}) => {
    const eventSlug = useSlug('eventSlug')
    return (
        <Get url={`/events/${eventSlug}/virtualprogram/containers/${id}`}>
            {(resp: any) => (
                <ProgramContainerModal container={resp} />
            )}
        </Get>
    )
}

export default FetchProgramContainerModal
