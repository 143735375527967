import { SettingItem } from '../../components/CompatibilityTestProgress/CompatibilityTestProgress'

interface CompatibleSettingResult {
    type?: 'audio' | 'video' | 'sharedScreen'
    status: 'pending' | 'processing' | 'success' | 'failed'
    message?: string
}

const soundVideoErrorCallback = (error): CompatibleSettingResult => {
    if (error.name === 'NotAllowedError') {
        return {
            status: 'failed',
            message: 'Permission denied'
        }
    }
    if (error.name === 'NotFoundError') {
        return {
            status: 'failed',
            message: 'Not found device'
        }
    }
    return {
        status: 'failed',
        message: error.message
    }
}

export const checkSoundSetting = (): Promise<CompatibleSettingResult> => {
    if (!navigator.mediaDevices) {
        return Promise.resolve({
            status: 'failed',
            message: 'Browser is not supported'
        })
    }

    const successCallback = (stream): CompatibleSettingResult => {
        if (stream.getAudioTracks().length > 0) {
            return { status: 'success' }
        }
        return {
            status: 'failed',
            message: 'Not found device'
        }
    }

    return navigator.mediaDevices?.getUserMedia?.({audio: true})
        .then(successCallback, soundVideoErrorCallback)
}

export const checkVideoSetting = (): Promise<CompatibleSettingResult> => {
    if (!navigator.mediaDevices) {
        return Promise.resolve({
            status: 'failed',
            message: 'Browser is not supported'
        })
    }

    const successCallback = (stream): CompatibleSettingResult => {
        if (stream.getVideoTracks().length > 0) {
            return { status: 'success' }
        }
        return {
            status: 'failed',
            message: 'Not found device'
        }
    }

    return navigator.mediaDevices?.getUserMedia?.({video: true})
        .then(successCallback, soundVideoErrorCallback)
}

export const checkShareScreenSetting = (): Promise<CompatibleSettingResult> => {
    if (navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices) {
        return Promise.resolve({
            status: 'success'
        })
    }
    return Promise.resolve({
        status: 'failed',
        message: 'Permission denied'
    })
}

const checkCompatibleSetting = (item: SettingItem): Promise<CompatibleSettingResult> => {
    if (item.type === 'audio') {
        return checkSoundSetting()
    }
    if (item.type === 'video') {
        return checkVideoSetting()
    }
    if (item.type === 'sharedScreen') {
        return checkShareScreenSetting()
    }
}

export default checkCompatibleSetting
