import {
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { RequestStatus } from '../../types'
import { RetrieveCinemaSpaceResponse, ListMoviesResponse } from '../../types/__generated_swagger'
import api from '../../utils/api'
import { UpdatedPublishedSpace, WS as SpaceWS } from '../spaces/spaceSlice'

export const fetchCinema = createAsyncThunk(
    'FETCH_CINEMA',
    ({ eventSlug, cinemaSlug }: {eventSlug: string, cinemaSlug: string}) => (
        api.get<RetrieveCinemaSpaceResponse>(`/events/${eventSlug}/cinema/${cinemaSlug}/`)
    )
)

type MovieType = ListMoviesResponse['results'][0]

interface GetVideosThunkProps {
    eventSlug: string
    cinemaSlug: string
    limit: number
    search?: string
    tagsFilter?: Record<string, any[]>
}

export const fetchVideos = createAsyncThunk(
    'FETCH_VIDEO',
    ({
        eventSlug, cinemaSlug, limit, search = '', tagsFilter = {}
    }: GetVideosThunkProps) => {
        const tagByCategory = Object.values(tagsFilter)
            .filter((category) => category.length)
            .map((category) => category.map((e) => e.value).join(','))
        const url = `events/${eventSlug}/cinema/${cinemaSlug}/movies/?limit=${limit}&search=${search}&tags=${tagByCategory.join('__')}`
        return api.get<ListMoviesResponse>(url)
    },
)

const cinemaSlice = createSlice({
    name: 'cinemaSlice',
    initialState: {
        cinema: {
            data: {} as Record<string, RetrieveCinemaSpaceResponse>,
            requestStatus: 'idle' as RequestStatus
        },
        videos: {
            data: [] as MovieType[],
            count: 0 as number,
            limit: 10,
            requestStatus: 'idle' as RequestStatus
        },
        currentVideo: null as MovieType
    },
    reducers: {
        loadMore: (state) => {
            state.videos.limit += 10
        },
        selectVideo: (state, { payload }: PayloadAction<MovieType>) => {
            state.currentVideo = payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchCinema.fulfilled, (state, { payload }: PayloadAction<RetrieveCinemaSpaceResponse>) => {
                state.cinema.data[payload?.meta?.slug] = payload
                state.cinema.requestStatus = 'succeeded'
            }
        )
        builder.addCase(fetchCinema.pending, (state) => {
            state.cinema.requestStatus = 'loading'
        })
        builder.addCase(fetchCinema.rejected, (state) => {
            state.cinema.data = null
            state.cinema.requestStatus = 'failed'
        })
        builder.addCase(fetchVideos.fulfilled, (state, { payload }) => {
            state.videos.data = payload.results
            state.videos.count = payload.count
            state.videos.requestStatus = 'succeeded'

            // This could be false after navigating from 1 cinema to another.
            const currentVideoIsInCinema = payload.results.find(
                ({ cinema_slug }) => cinema_slug === state.currentVideo?.cinema_slug
            )

            // Set initial video
            if (payload.count > 0 && (!state.currentVideo || !currentVideoIsInCinema)) {
                state.currentVideo = payload.results[0]
            }
        })
        builder.addCase(fetchVideos.pending, (state) => {
            state.videos.requestStatus = 'loading'
        })
        builder.addCase(
            SpaceWS.publishedSpaceUpdated,
            (state, { payload }: PayloadAction<UpdatedPublishedSpace>) => {
                if (state.cinema.data[payload.space_slug]) {
                    state.cinema.data[payload.space_slug].meta.published = payload.published
                }
            }
        )
    }
})

export const cinemaSelector = (slug: string) => (state: RootState) => (
    {
        data: state.cinema.cinema.data[slug],
        requestStatus: state.cinema.cinema.requestStatus
    }
)

export const videosSelector = (state: RootState) => state.cinema.videos.data || []

export const fetchVideosStatusSelector = (state: RootState) => state.cinema.videos.requestStatus

export const limitVideosSelector = (state: RootState) => state.cinema.videos.limit

export const currentVideoSelector = (state: RootState) => state.cinema.currentVideo

export const canLoadMoreSelector = createSelector(
    [
        (state: RootState) => state.cinema.videos.limit,
        (state: RootState) => state.cinema.videos.count,
    ],
    (limit, count) => limit < count,
)

export const loadMore = cinemaSlice.actions.loadMore
export const selectVideo = cinemaSlice.actions.selectVideo

export default cinemaSlice.reducer
