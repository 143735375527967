import groupBy from 'lodash/groupBy'
import { _ } from '../../utils/localization'
import { LocationType, LocationTypeKeys } from './calendarSlice'
import { SizeDirection } from './components/CalendarSizes'

const widthMapper = {
    S: 160,
    M: 230,
    L: 300
}

const heightMapper = {
    S: 80,
    M: 120,
    L: 160,
}

export const calculateBySize = (direction: SizeDirection, size) => {
    if (direction === 'horizontal') {
        return widthMapper[size]
    }
    return heightMapper[size]
}

export const getLocationTypeTranslation = (location: LocationTypeKeys) => ({
    [LocationType.VIRTUAL]: _('program_calendar.location_type.virtual'),
    [LocationType.IN_PERSON]: _('program_calendar.location_type.in_person'),
    [LocationType.HYBRID]: _('program_calendar.location_type.hybrid'),
}[location])

export const getLocationTypeOptions = () => ([
    { label: _('program_calendar.location_type.virtual'), value: LocationType.VIRTUAL },
    { label: _('program_calendar.location_type.in_person'), value: LocationType.IN_PERSON },
    { label: _('program_calendar.location_type.hybrid'), value: LocationType.HYBRID },
])

const convertData = (data = []) => data.map((e) => ({ label: e.text, value: e.id, color: e.color }))

export const convertTagsGroupByCategory = (tags) => {
    const tagCategories = groupBy(tags, (e) => e.category?.text)
    const sortable = Object.entries(tagCategories)
        .sort(([,a], [,b]) => a[0]?.category?.order - b[0]?.category?.order)
        .reduce((r, [k, v]) => {
            r[k] = v
            return r
        }, {})

    Object.keys(sortable).forEach((key) => {
        if (key === 'undefined') {
            tagCategories['uncategorized tags'] = convertData(tagCategories[key])
            delete tagCategories[key]
        } else {
            tagCategories[key] = convertData(tagCategories[key])
        }
    })

    return tagCategories
}
