import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { uniq, uniqBy } from 'lodash'
import { websocketAction } from '../../utils/redux'
import { RootState } from '../../store'
import { VirtualAttendee } from '../../types/swagger'
import attendeeList from '../events/attendeeList'

interface UserLeft {
    user_id: number
}

export const WS = {
    joinedBoothSpace: websocketAction<VirtualAttendee>('joined_booth_space'),
    leftBoothSpace: websocketAction<UserLeft>('left_booth_space'),
}

const boothVisitorsSlice = createSlice({
    name: 'booth',
    initialState: {
        joinedUsers: {} as Record<string, VirtualAttendee>,
        leftUserIds: [] as number[],
        showSidebar: false,
    },
    reducers: {
        toggleSidebar: (state, { payload }: PayloadAction<boolean>) => {
            state.showSidebar = payload ?? !state.showSidebar
        },
        clearData: (state) => {
            state.joinedUsers = {}
            state.leftUserIds = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(WS.joinedBoothSpace, (state, { payload }) => {
            state.joinedUsers[payload.id] = payload
            state.leftUserIds = state.leftUserIds.filter((e) => e !== payload.id)
        })
        builder.addCase(WS.leftBoothSpace, (state, { payload }) => {
            state.leftUserIds.push(payload.user_id)
        })
        builder.addCase(attendeeList.WS.attendee_updated, (state, { payload }: { payload }) => {
            if (state.joinedUsers[payload.id]) {
                state.joinedUsers[payload.id] = payload
            }
        })
        builder.addCase(attendeeList.WS.privacy_status_updated, (state, { payload }) => {
            if (state.joinedUsers && state.joinedUsers[payload.user]) {
                state.joinedUsers[payload.user].is_public = payload.is_public
            }
        })
    },
})

export const showSidebarSelector = (state: RootState) => state.exhibition.boothVisitors.showSidebar
export const joinedBoothVisitorsSelector = (state: RootState) => (
    uniqBy(Object.values(state.exhibition.boothVisitors.joinedUsers), 'id')
)
export const leftBoothVisitorsSelector = (state: RootState) => (
    uniq(state.exhibition.boothVisitors.leftUserIds)
)

export const { toggleSidebar, clearData } = boothVisitorsSlice.actions

export default boothVisitorsSlice.reducer
