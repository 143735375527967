import {
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RetrieveBoothSpaceDetailedResponse } from '../../types/swagger'
import api from '../../utils/api'
import { RequestStatus } from '../../types'
import { RootState } from '../../store'

export const fetchBoothDetail = createAsyncThunk(
    'FETCHBOOTHDETAIL',
    async ({ eventSlug, boothSlug }: { eventSlug: string, boothSlug: string }, thunkAPI) => {
        thunkAPI.dispatch(boothDetailSlice.actions.startLoading(boothSlug))
        const url = `events/${eventSlug}/booths/${boothSlug}/`
        return {
            boothSlug,
            resp: await api.get<RetrieveBoothSpaceDetailedResponse>(url),
        }
    },
)

interface BoothRequest {
    data?: RetrieveBoothSpaceDetailedResponse
    status: RequestStatus
}

const boothDetailSlice = createSlice({
    name: 'boothDetailSlice',
    initialState: {
        requests: {} as Record<string, BoothRequest>,
    },
    reducers: {
        startLoading: (state, { payload }: PayloadAction<string>) => {
            state.requests[payload] = {
                status: 'loading',
                data: null,
            }
        },
        emptyData: (state, { payload }: PayloadAction<string>) => {
            delete state.requests[payload]
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBoothDetail.fulfilled, (state, { payload }) => {
            state.requests[payload.boothSlug] = {
                status: 'succeeded',
                data: payload.resp,
            }
        })
    },
})

export const boothDetailSelector = (boothSlug: string) => createSelector(
    [(state: RootState) => state.exhibition.detail.requests[boothSlug]],
    (booth) => booth || { data: undefined, status: 'idle' },
)

export default boothDetailSlice.reducer
