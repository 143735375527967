import React from 'react'
import styled from 'styled-components'
import SideBar from './SideBar'
import colors from '../../constants/colors'
import Get from '../../components/Get'
import { RetrieveJobsSpaceResponse } from '../../types/__generated_swagger'
import useSlug from '../../hooks/useSlug'

const getBackground = ({ image }: { image?: string }) => (image
    ? `url(${image})`
    : colors.gray4)

const S = {
    Page: styled.div<{ image?: string }>`
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        background: ${getBackground};
        background-size: cover;
    `,
}

const Jobs = () => {
    const eventSlug = useSlug('eventSlug')
    const jobsSlug = useSlug('jobsSlug')
    const url = `events/${eventSlug}/jobs/${jobsSlug}/`
    return (
        <Get url={url}>
            {(resp: RetrieveJobsSpaceResponse) => (
                <S.Page image={resp?.meta?.image}>
                    <SideBar />
                </S.Page>
            )}
        </Get>
    )
}
export default Jobs
