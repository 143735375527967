import { format } from 'date-fns'
import React, { forwardRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'
import colors from '../../constants/colors'
import Icon from '../Icon'
import './style.scss'

export const S = {
    CustomInput: styled.div`
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 6px 10px;
        border-radius: 4px;
        background-color: ${colors.primaryOpacity(0.03)};
        color: ${colors.primary};
        cursor: pointer;
        user-select: none;
    `
}

interface Props {
    value: Date
    onChange: (selectedDate) => void
    className?: string
    minDate?: Date
    maxDate?: Date
    highlightDates?: Date[]
}

const DatePicker = ({
    value, onChange, className, minDate, maxDate, highlightDates
}: Props) => {
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    const CustomInput = forwardRef(({ value: _value, onClick }, ref) => (
        // @ts-ignore
        // eslint-disable-next-line react/button-has-type
        <S.CustomInput onClick={onClick} ref={ref}>
            <Icon icon="calendar" size={18} className="mr-1" color={colors.primary} />
            {format(new Date(_value), 'eee, MMM d')}
        </S.CustomInput>
    ))

    return (
        <ReactDatePicker
            onChange={onChange}
            selected={value}
            className={className}
            customInput={<CustomInput />}
            popperPlacement="bottom-end"
            minDate={minDate}
            maxDate={maxDate}
            highlightDates={highlightDates || []}
        />
    )
}

export default DatePicker
