import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Popover } from '@mui/material'
import colors from '../constants/colors'
import Icon from './Icon'

const S = {
    ButtonWrapper: styled.div<{hasFilter?: boolean}>`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        background-color: ${colors.primary};
        border-radius: 4px;
        cursor: pointer;
        ${({hasFilter}) => hasFilter && css`
            &::after {
                content: '';
                position: absolute;
                top: -2px;
                right: -2px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: ${colors.danger};
                border: 2px solid white;
            }
        `}
    `
}

const DropdownFilter = ({
    children, hasFilter
}: {children?: ReactNode, hasFilter?: boolean}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
        <div>
            <S.ButtonWrapper
                onClick={(e) => {
                    e.preventDefault()
                    handleClick(e)
                }}
                hasFilter={hasFilter}
                aria-describedby={id}
            >
                <Icon icon="filter" color="white" />
            </S.ButtonWrapper>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {children}
            </Popover>
        </div>

    )
}

export default DropdownFilter
