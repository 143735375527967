import React from 'react'
import debounce from 'lodash/debounce'
import InfiniteScroll from 'react-infinite-scroll-component'
import { TwitterTweetEmbed } from 'react-twitter-embed'
import styled from 'styled-components'
import FullPageLoading from '../../../components/FullPageLoading'
import { Tweet } from '../../../types/__generated_swagger'

const S = {
    Container: styled.div<{isShow: boolean}>`
        position: relative;
        display: ${({isShow}) => (isShow ? 'flex' : 'none')};
        flex-grow: 1;
        flex-basis: 400px;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        overflow: hidden;
    `,
    Wrapper: styled.div`
        padding: 10px;
        height: 100%;
        overflow-y: auto;
    `,
    TwitterButtonWrapper: styled.div`
        position: fixed;
        z-index: 1;
        top: 71px;
        right: 10px;
    `
}

interface Props {
    twitterSearch: string
    isSelected: boolean
    data?: Tweet[]
    isReachingEnd?: boolean
    size?: number
    setSize?: (size: number | ((_size: number) => number)) => Promise<Tweet[][]>
}

const Twitter = ({
    isSelected, data, isReachingEnd, size, setSize
}: Props) => {
    const loadMoreDebounce = debounce(() => setSize(size + 1), 1000)

    return (
        <S.Container isShow={isSelected}>
            <S.Wrapper id="infinite-scroll-tweets">
                <InfiniteScroll
                    dataLength={(data || []).length}
                    next={loadMoreDebounce}
                    hasMore={!isReachingEnd}
                    loader={<span />}
                    scrollableTarget="infinite-scroll-tweets"
                >
                    {(data || []).map((e) => (
                        <TwitterTweetEmbed
                            tweetId={`${e.external_id}`}
                            placeholder={<FullPageLoading isAbsolute shouldBeWrapped />}
                            key={e.external_id}
                        />
                    ))}
                </InfiniteScroll>
            </S.Wrapper>
        </S.Container>
    )
}

export default Twitter
