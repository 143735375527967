import { PayloadAction } from '@reduxjs/toolkit'
import { withCapture } from '../errorTracker'
import env from '../../constants/env'
import networkMonitor from '../networkMonitor'
import {
    RECEIVED_FROM_USER_TYPE,
} from '../../features/websocket/sendToUser'

export const PREFIX = 'REDUX_WEBSOCKET'
export const FROM_USER_PREFIX = 'RECEIVED_FROM_USER'
export const createType = (text: string): string => `${PREFIX}::${text.toUpperCase()}`
export const createSendToUserType = (text: string, payload: any): string => {
    if (!payload?.event) {
        throw Error(`createSendToUserType expects payload with event, received ${payload}`)
    }
    return `${PREFIX}::${FROM_USER_PREFIX}::${payload.event.toUpperCase()}`
}

/**
 * Take payload of https://github.com/giantmachines/redux-websocket structure:
 *      {
 *        type: 'REDUX_WEBSOCKET::MESSAGE',
 *        meta: {
 *            timestamp: Date,
 *        },
 *        payload: {
 *            message: string,  // json formatted string
 *            origin: string,
 *        },
 *      }
 *
 * Take out the message, convert it to json and pass it to reducers with structure:
 *      {
 *          user: 1
 *          type: "REDUX_WEBSOCKET::USER_JOINED_CHAT"
 *      }
 */
const parsePayload = (action: PayloadAction<{message: string}, string>) => {
    const json = JSON.parse(action.payload.message)

    json.type = json.type === RECEIVED_FROM_USER_TYPE
        ? createSendToUserType(json.type, json.payload)
        : createType(json.type)

    if (env.debug) {
        networkMonitor.incomingWs.add(json.type)
    }

    return json
}

const INCOMING_MESSAGE = createType('MESSAGE')

export default () => (next) => (action: PayloadAction<{message: string}, string>) => {
    if (action.type === INCOMING_MESSAGE) {
        return withCapture(() => next(parsePayload(action)))
    }
    return next(action)
}
