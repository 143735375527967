import React, { ReactNode } from 'react'
import styled from 'styled-components'
import StyledIcon from './Icon'

const S = {
    LoadMore: styled.div<{variant: string}>`
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: ${({ variant }) => (variant === 'light' ? 'rgba(255, 255, 255, 0.7)' : '#78909C')};
        cursor: pointer;
        &:hover {
            color: ${({ variant }) => (variant === 'light' ? 'white' : '#1e688e')};
            & path{
                fill: ${({ variant }) => (variant === 'light' ? 'white' : '#1e688e')}!important;
            }
        }
    `
}

interface Props {
    onClick?: () => void
    children?: ReactNode
    style?: React.CSSProperties
    variant?: 'light'
}

const LoadMore = ({
    children,
    onClick,
    style,
    variant
}: Props) => (
    <S.LoadMore onClick={onClick} style={style} variant={variant}>
        {children}
        <StyledIcon
            icon="chevronDown"
            color={variant === 'light' ? 'rgba(255, 255, 255, 0.7)' : '#78909C'}
            style={{ marginLeft: 5 }}
        />
    </S.LoadMore>
)

export default LoadMore
