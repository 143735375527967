import constants from '../constants/constants'
import env from '../constants/env'

const getMugshotSrc = (user: { mugshot?: string }) => {
    if (!user || !user.mugshot) { return constants.urlThumbnailDefault }
    return user.mugshot.startsWith('http')
        ? user.mugshot
        : `${env.backendUrl}${user.mugshot}`
}

export default getMugshotSrc
