import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { fetchEvent } from './eventSlice'
import { RetrieveAccessEventResponse } from '../../types/swagger'

const showMeSlice = createSlice({
    name: 'showMeSlice',
    initialState: false,
    reducers: {
        setShowMe: (state, { payload }: PayloadAction<boolean>) => payload,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEvent.fulfilled, (
            state,
            { payload }: PayloadAction<RetrieveAccessEventResponse>
        ) => payload.current_user_is_public)
    }
})

export const setShowMe = showMeSlice.actions.setShowMe
export const selectShowMe = (state: RootState) => state.showMe
export default showMeSlice.reducer
