import styled from 'styled-components'

const UserThumbnail = styled.img`
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
    `

export default UserThumbnail
