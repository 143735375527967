import { createAsyncThunk } from '@reduxjs/toolkit'
import React from 'react'
import { RootState } from '../../store'
import { Videocall, VirtualAttendee } from '../../types/swagger'
import { setPopover } from '../networking/networkingSlice'
import { _ } from '../../utils/localization'
import { addModal } from '../modals/modalSlice'
import InviterModal from './components/InviterModal'
import { VideocallRoomStatus } from '../videocall/types'
import JoinConfirmation from './JoinConfirmation'
import RequestAccess from './RequestAccess'
import api from '../../utils/api'
import { selectEventSlug } from '../events/eventSlice'
import { CallConfig, JoinCallConfig } from './types'
import CustomPopover from '../../components/CustomPopover'
import env from '../../constants/env'

const selectVideocallForUser = (state: RootState, userId: number) => {
    const list = Object.values(state.videocalls)
    return list.find(({ users, ended }) => users.includes(userId) && !ended)
}

export const showJoinConfirmation = (attendee: VirtualAttendee, config: CallConfig) => (
    createAsyncThunk(
        'SHOW_JOIN_CONFIRMATION',
        async (__, thunkAPI) => {
            const state = thunkAPI.getState() as RootState
            let videocall = selectVideocallForUser(state, attendee.id)
            const eventSlug = selectEventSlug(state)

            if (!videocall) {
                const videocallId = attendee.videocall_status.id
                const url = `events/${eventSlug}/videocalls/${videocallId}/`
                videocall = await api.get<Videocall>(url)
            }

            const Component = ({ close }: { close: () => void }) => (
                <JoinConfirmation
                    close={close}
                    videocall={videocall}
                    config={config}
                />
            )

            if (config.requestInPopover) {
                thunkAPI.dispatch(setPopover({
                    videocall,
                    Component,
                }))
            } else {
                thunkAPI.dispatch(addModal({
                    Component, size: 'sm', padding: 0, backdrop: 'static'
                }))
            }
        }
    ))()

export const requestPermissionToJoin = (attendee: VirtualAttendee, config: CallConfig) => (
    createAsyncThunk(
        'REQUEST_PERMISSION_TO_JOIN',
        async (__, thunkAPI) => {
            const state = thunkAPI.getState() as RootState
            let videocall = selectVideocallForUser(state, attendee.id)
            const eventSlug = selectEventSlug(state)

            if (!videocall) {
                const videocallId = attendee.videocall_status.id
                const url = `events/${eventSlug}/videocalls/${videocallId}/`
                videocall = await api.get<Videocall>(url)
            }

            const Component = ({ close }: { close: () => void }) => (
                <RequestAccess
                    close={close}
                    videocall={videocall}
                    config={config}
                />
            )

            const requestAction = config.requestInPopover
                ? setPopover({
                    videocall,
                    Component,
                })
                : addModal({
                    Component,
                    size: 'sm',
                    padding: 0,
                    backdrop: 'static',
                })

            thunkAPI.dispatch(requestAction)
        }
    ))()

export const showUnableToJoin = () => addModal({
    Component: () => <div>{_('networking.popovers.unable_to_join')}</div>,
    header: _('networking.popovers.private_meeting'),
    size: 'sm',
})

export const showVideocallIsFull = (attendee: VirtualAttendee, config: CallConfig) => (
    createAsyncThunk(
        'VIDEOCALL_IS_FULL',
        async (__, thunkAPI) => {
            const state = thunkAPI.getState() as RootState
            let videocall = selectVideocallForUser(state, attendee.id)
            const eventSlug = selectEventSlug(state)

            if (!videocall) {
                const videocallId = attendee.videocall_status.id
                const url = `events/${eventSlug}/videocalls/${videocallId}/`
                videocall = await api.get<Videocall>(url)
            }

            const Component = ({ close }: { close: () => void }) => (
                <CustomPopover
                    title={_('videocall.popovers.call_is_full.header')}
                    text={_('videocall.popovers.call_is_full.body')}
                    cancelButton={_('button.cancel')}
                    onCancel={close}
                    cancelIcon="close"
                />
            )

            const requestAction = config.requestInPopover
                ? setPopover({
                    videocall,
                    Component,
                })
                : addModal({
                    Component,
                    size: 'sm',
                    padding: 0,
                    backdrop: 'static',
                })

            thunkAPI.dispatch(requestAction)
        }
    )
)()

export const inviteToNewCall = (userId, config: CallConfig) => addModal({
    Component: ({ close }: { close: () => void }) => (
        <InviterModal
            config={config}
            inviteeId={userId}
            close={close}
        />
    ),
    header: _('send_videocall_invite_modal.header'),
    size: 'sm',
    padding: 0,
    backdrop: 'static',
})

/**
 * @param status of the videocall.
 * @param attendee the user wants to go in a call with
 * @param config requestInPopover = should we open it in the networking area map if possible?
 */
export const joinVideocall = (
    status: VideocallRoomStatus,
    attendee: VirtualAttendee,
    config: JoinCallConfig,
) => (
    createAsyncThunk(
        'JOIN_VIDEOCALL',
        (__, thunkAPI) => {
            // When requestInPopover = true, we need to make sure
            // the user is on the map of the current networking area
            // This covers two cases where we should not requestInPopover:
            // 1. Not currently on networking area
            // 2. Videocall we are asking permission for is in other networking area
            const state = thunkAPI.getState() as RootState
            const calls = Object.values(state.videocalls) as Videocall[]
            const usersOnMap = calls.filter((v) => v).flatMap(({ users }) => users)
            const userIsOnMap = usersOnMap.includes(attendee.id)
            const newConfig = { requestInPopover: config.requestInPopover && userIsOnMap, ...config }
            if (config.amountOfUsers >= env.videocallCapacity) {
                return thunkAPI.dispatch(showVideocallIsFull(attendee, newConfig))
            }

            // eslint-disable-next-line default-case
            switch (status) {
            case 'closed': thunkAPI.dispatch(showUnableToJoin()); break
            case 'request': thunkAPI.dispatch(requestPermissionToJoin(attendee, newConfig)); break
            case 'open': thunkAPI.dispatch(showJoinConfirmation(attendee, newConfig)); break
            }
        }
    ))()
