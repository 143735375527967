import styled from 'styled-components'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import config from '../config'
import { decrementDate, incrementDate, selectedDateSelector } from '../redux/dateSlice'
import { toLocalDateFormat } from '../../../utils/datetime'
import Icon from '../../../components/Icon'

const S = {
    DateControl: styled.div`
        background-color: ${config.timelineNavColor};
        height: ${config.timelineNavHeight}px;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    Date: styled.span`
        color: white;
        width: 120px;
        text-align: center;
    `,
    Arrow: styled.div`
        background-color: white;
        border-radius: 99px;
        width: 21px;
        height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    `,
}

const DateControl = () => {
    const dispatch = useDispatch()
    const date = useSelector(selectedDateSelector)
    return (
        <S.DateControl>
            <S.Arrow onClick={() => dispatch(decrementDate())}>
                <Icon icon="left" size={22} style={{ marginBottom: 3 }} />
            </S.Arrow>

            <S.Date>{toLocalDateFormat(date)}</S.Date>

            <S.Arrow onClick={() => dispatch(incrementDate())}>
                <Icon icon="right" size={22} style={{ marginBottom: 3 }} />
            </S.Arrow>
        </S.DateControl>
    )
}

export default DateControl
