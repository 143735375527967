import {
    createAsyncThunk, createSelector, createSlice, PayloadAction,
} from '@reduxjs/toolkit'
import api from '../../utils/api'
import { RetrievePresentationSpaceResponse } from '../../types/swagger'
import { RequestStatus } from '../../types'
import { RootState } from '../../store'
import { websocketAction } from '../../utils/redux'
import { UpdatedPublishedSpace, WS as SpaceWS } from '../spaces/spaceSlice'

interface FetchPresentationProps {
    eventSlug: string
    presentationSlug: string
}

interface PresentationRequest {
    data?: RetrievePresentationSpaceResponse
    status: RequestStatus
}

const WS = {
    presentationSpaceUpdated: websocketAction<RetrievePresentationSpaceResponse>('updated_presentation_space')
}

export const fetchPresentationArea = createAsyncThunk(
    'GET_PRESENTATION_PAGE',
    async ({ eventSlug, presentationSlug }: FetchPresentationProps) => {
        const url = `/events/${eventSlug}/presentations/${presentationSlug}/`
        return {
            presentationSlug,
            resp: await api.get<RetrievePresentationSpaceResponse>(url),
        }
    },
)

const presentationSlice = createSlice({
    name: 'presentation',
    initialState: {
        // todo rename this. We don't save the requests, but presentationSpaces
        requests: {} as Record<string, PresentationRequest>,
        isShowSidebar: false as boolean,
        isFullScreen: false as boolean,
        isShowWidget: false as boolean,
        isShowPublicChat: false as boolean,
    },
    reducers: {
        startLoading: (state, { payload }: PayloadAction<string>) => {
            state.requests[payload] = state.requests[payload] || {
                status: 'loading',
                data: null,
            }
        },
        emptyData: (state, { payload }: PayloadAction<string>) => {
            delete state.requests[payload]
        },
        toggleSidebar: (state) => {
            state.isShowSidebar = !state.isShowSidebar
            if (state.isShowSidebar) {
                state.isShowWidget = false
                state.isShowPublicChat = false
            }
        },
        setFullScreenMode: (state, { payload }: PayloadAction<boolean>) => {
            state.isFullScreen = payload
        },
        toggleWidget: (state) => {
            state.isShowWidget = !state.isShowWidget
            if (state.isShowWidget) {
                state.isShowSidebar = false
                state.isShowPublicChat = false
            }
        },
        togglePublicChat: (state) => {
            state.isShowPublicChat = !state.isShowPublicChat
            if (state.isShowPublicChat) {
                state.isShowWidget = false
                state.isShowSidebar = false
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPresentationArea.fulfilled, (state, { payload }) => {
            state.requests[payload.presentationSlug] = {
                status: 'succeeded',
                data: payload.resp,
            }
        })
        builder.addCase(WS.presentationSpaceUpdated, (state, { payload }) => {
            state.requests[payload.meta.slug].data = payload
        })
        builder.addCase(
            SpaceWS.publishedSpaceUpdated,
            (state, { payload }: PayloadAction<UpdatedPublishedSpace>) => {
                if (state.requests?.[payload.space_slug]?.data) {
                    state.requests[payload.space_slug].data.meta.published = payload.published
                }
            }
        )
    },
})

export const presentationSelector = (presentationSlug: string) => createSelector(
    [(state: RootState) => state.presentationRoom.presentation.requests[presentationSlug]],
    (presentation) => presentation || { data: undefined, status: 'idle' },
)

export const fullScreenSelector = () => createSelector(
    [(state: RootState) => state.presentationRoom.presentation.isFullScreen],
    (isFullScreen) => isFullScreen
)

export const moderatorSelector = (presentationSlug: string) => createSelector(
    [(state: RootState) => state.presentationRoom.presentation.requests[presentationSlug]],
    ({ data }) => data && data.current_presentation && data.current_presentation.moderator,
)

export const showWidgetSelector = (state: RootState) => state.presentationRoom.presentation.isShowWidget
export const showSidebarSelector = (state: RootState) => state.presentationRoom.presentation.isShowSidebar
export const showPublicChatSelector = (state: RootState) => (
    state.presentationRoom.presentation.isShowPublicChat
)

export const toggleSidebar = presentationSlice.actions.toggleSidebar
export const setFullScreenMode = presentationSlice.actions.setFullScreenMode
export const toggleWidget = presentationSlice.actions.toggleWidget
export const togglePublicChat = presentationSlice.actions.togglePublicChat

export default presentationSlice.reducer
