import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import useSWRInfinite from 'swr/infinite'
import Icon from '../../components/Icon'
import { _ } from '../../utils/localization'
import { S as SAttendee } from '../attendees/AttendeesSidebar'
import api from '../../utils/api'
import useSlug from '../../hooks/useSlug'
import PerformerRow from './view/PerformerRow'
import { getLocationTypeOptions } from '../calendar/utils'
import FullPageLoading from '../../components/FullPageLoading'
import { isExpandingSelector, setIsExpanding, toggleSidebar } from './performerSlice'
import { eventPreviewSelector } from '../auth/eventPreviewSlice'
import DropdownFilter from '../../components/DropdownFilter'
import MultiSelectFilter from '../../components/MultiSelectFilter'

const LIMIT = 20

const getKey = (pageIndex, previousPageData, eventSlug, search, locationTypes) => {
    if (previousPageData && previousPageData.length < LIMIT) { // reached the end
        return null
    }
    const searchParams = new URLSearchParams({
        search,
        offset: `${pageIndex * LIMIT}`,
        limit: `${LIMIT}`,
        location_types: locationTypes.map((e) => e.value).join(','),
    }).toString()
    return `/events/${eventSlug}/performers/?${searchParams}`
}

const fetcher = (url) => api.get(url).then((res) => res.results)

const PerformerSidebar = () => {
    const dispatch = useDispatch()
    const eventPreview = useSelector(eventPreviewSelector)
    const isExpanding = useSelector(isExpandingSelector)
    const [search, setSearch] = useState('')
    const [locationTypes, setLocationTypes] = useState([])
    const eventSlug = useSlug('eventSlug')
    const {
        data, size, setSize
    } = useSWRInfinite((...args) => getKey(...args, eventSlug, search, locationTypes), fetcher)
    const performers = data ? [].concat(...data) : []
    const isHybridEvent = eventPreview?.is_virtual && eventPreview?.is_in_person

    return (
        <SAttendee.Wrapper isExpanding={isExpanding}>
            <SAttendee.Header>
                <span>{_('performer_sidebar.header')}</span>
                <SAttendee.Actions>
                    <Icon
                        className="mr-2 expand-icon"
                        icon={isExpanding ? 'collapseHorizontal' : 'expandHorizontal'}
                        onClick={() => dispatch(setIsExpanding(!isExpanding))}
                    />
                    <Icon icon="close" onClick={() => dispatch(toggleSidebar())} />
                </SAttendee.Actions>
            </SAttendee.Header>
            <SAttendee.Filter>
                <SAttendee.SearchInput
                    value={search}
                    onSearch={(value) => setSearch(value)}
                    placeholder={_('attendee_sidebar.search_placeholder')}
                    isFullWidth={!isHybridEvent}
                    className="mr-2"
                />
                {isHybridEvent && (
                    <DropdownFilter>
                        <SAttendee.FilterWrapper>
                            <div className="title">{_('header.filters')}</div>
                            <MultiSelectFilter
                                options={getLocationTypeOptions()}
                                onSelect={(value) => setLocationTypes(value)}
                                selected={locationTypes}
                                allSelectedText={_('tag_filter.select_all')}
                            />
                        </SAttendee.FilterWrapper>
                    </DropdownFilter>
                )}
            </SAttendee.Filter>
            <SAttendee.AttendeeList id="infinite-scroll-performer">
                <InfiniteScroll
                    dataLength={performers.length}
                    next={() => setSize(size + 1)}
                    hasMore
                    loader={<span />}
                    scrollableTarget="infinite-scroll-performer"
                >
                    {performers.map((item) => (
                        <PerformerRow item={item} key={item.id} />
                    ))}
                </InfiniteScroll>
                {data === undefined && <FullPageLoading isAbsolute />}
            </SAttendee.AttendeeList>
        </SAttendee.Wrapper>
    )
}

export default PerformerSidebar
