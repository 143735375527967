import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import Button from 'react-bootstrap/Button'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { currentUserSelector } from '../auth/authSlice'
import Get from '../../components/Get'
import useSlug from '../../hooks/useSlug'
import { editProfile, setDoNotDisturb } from '../profile/editProfileSlice'
import { _ } from '../../utils/localization'
import Icon from '../../components/Icon'

interface GetChatRouletteSpaceResponse {
    id: number
    embedded_url: string
}

const addSearch = (url: string, search: string) => {
    if (url.includes('?')) {
        return url.endsWith('&') ? `${url}${search}` : `${url}&${search}`
    }
    return `${url}?${search}`
}

const getUUID = (userId: number) => {
    const storeKey = `ELEVENTLY::ID_TO_UUID::${userId}`
    const uuid = window.localStorage.getItem(storeKey)
    if (uuid) {
        return uuid
    }
    const newUuid = uuidv4()
    window.localStorage.setItem(storeKey, newUuid)
    return newUuid
}

const S = {
    Page: styled.div`
      height: 100%;
      padding-bottom: 50px;
      background: #181818; // same as chatroullete UI background
    `,
    IFrameWrapper: styled.div`
      height: 100%;
    `,
    GoBack: styled(Button)`
      position: absolute;
      top: 80px;
      right: 30px;
    `,
}

const GoBack = () => {
    const history = useHistory()
    return (
        <S.GoBack onClick={() => history.goBack()} variant="light">
            <span>
                {_('chatroulette.exit')}
                <Icon icon="exit" style={{ marginLeft: 6 }} />
            </span>
        </S.GoBack>
    )
}

const updateDoNotDisturb = createAsyncThunk(
    'UPDATE_DO_NOT_DISTURB', (args: { userId: number, value: boolean }, thunkAPI) => {
        const { userId, value } = args
        thunkAPI.dispatch(setDoNotDisturb(value))
        thunkAPI.dispatch(editProfile({ id: userId, do_not_disturb: value }))
    }
)

// info at https://about.chatroulette.com/embedded/
const ChatRouletteView = () => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const chatRouletteSlug = useSlug('chatRouletteSlug')
    const user = useSelector(currentUserSelector())
    const userId = user.id
    const userSearchParam = `userId=${getUUID(userId)}`

    // Go in DND while in chatroulette, and go out of DND when leaving
    useEffect(() => {
        dispatch(updateDoNotDisturb({ userId, value: true }))
        return () => {
            dispatch(updateDoNotDisturb({ userId, value: false }))
        }
    }, [dispatch, userId])

    return (
        <S.Page>
            <S.IFrameWrapper>
                <Get url={`events/${eventSlug}/chatroulette/${chatRouletteSlug}/`}>
                    {({ embedded_url }: GetChatRouletteSpaceResponse) => (
                        <iframe
                            title="chatroulette"
                            name="chatroulette"
                            src={addSearch(embedded_url, userSearchParam)}
                            allow="camera;microphone"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                        />
                    )}
                </Get>
            </S.IFrameWrapper>

            <GoBack />
        </S.Page>
    )
}

export default ChatRouletteView
