import { VirtualAttendee } from '../../types/swagger'
import { RetrieveBoothSpaceDetailedResponse } from '../../types/__generated_swagger'

export type Booth = RetrieveBoothSpaceDetailedResponse

export type BoothVideosType = RetrieveBoothSpaceDetailedResponse['videos']

export type BoothBusinessCard = {
    id: number,
    created: string,
    updated: string,
    done: boolean,
    user: number,
    assigned_to: number,
    booth: number,
    user_email?: string,
    message?: string
    is_auto?: boolean
    ignore_incognito_mode?: boolean
    first_name?: string
    last_name?: string
    mugshot?: string
}

export type BoothBusinessCardResponse = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: BoothBusinessCard[];
}

export type BoothBusinessCardProps = {
    businessCard?: BoothBusinessCard
    businessCards?: BoothBusinessCard[]
    staffs?: VirtualAttendee[]
    userIds?: number[]
    eventKey?: string
}

export enum BusinessCardManagementTabs {
    ALL = 'all',
    ASSIGNED_TO_ME = 'assigned_to_me',
    NOT_ASSIGNED = 'not_assigned',
}

export enum SwipeDirection {
    LEFT = 'left',
    RIGHT = 'right'
}
