import {
    createAsyncThunk, createSelector, createSlice, PayloadAction
} from '@reduxjs/toolkit'
import uniq from 'lodash/uniq'
import api from '../../utils/api'
import {
    ListVirtualAttendeesResponse,
    VirtualAttendee,
} from '../../types/swagger'
import { RootState } from '../../store'
import attendeeList from '../events/attendeeList'
import { RequestStatus } from '../../types'

interface GetAttendeesThunkProps {
    eventSlug: string
    boothSlug?: string
    search?: string
    offset?: number
    tagsFilter?: Record<string, any[]>
}

interface GetAttendeesForUsersThunkProps {
    eventSlug: string
    userIds: number[]
}

export interface UpdatePrivacyPayload {
    user: number;
    is_public: boolean;
}

const LIMIT = 25

export const getAttendeeList = createAsyncThunk(
    'GET_ATTENDEES_LIST',
    ({ eventSlug, boothSlug = '', offset }: GetAttendeesThunkProps) => {
        const params = `?limit=${LIMIT}&offset=${offset}&booth_slug=${boothSlug}`
        const url = `events/${eventSlug}/virtual_attendees/${params}`
        return api.get<ListVirtualAttendeesResponse>(url)
    },
)

export const getAttendeeListWithSearch = createAsyncThunk(
    'GET_ATTENDEES_LIST_SEARCH',
    ({
        eventSlug, boothSlug = '', offset, search, tagsFilter = {}
    }: GetAttendeesThunkProps) => {
        const tagByCategory = Object.values(tagsFilter)
            .filter((category) => category.length)
            .map((category) => category.map((e) => e.value).join(','))
        const params = `?limit=${LIMIT}&booth_slug=${boothSlug}&search=${search}&offset=${offset}&tags=${tagByCategory.join('__')}`
        const url = `events/${eventSlug}/virtual_attendees/${params}`
        return api.get<ListVirtualAttendeesResponse>(url)
    },
)

export const getAttendeesForUsers = createAsyncThunk(
    'GET_ATTENDEES_FOR_USERS',
    ({ eventSlug, userIds }: GetAttendeesForUsersThunkProps) => {
        const params = `?user_ids=${userIds.filter((id) => !!id).join(',')}`
        const url = `events/${eventSlug}/virtual_attendees/${params}`
        return api.get<ListVirtualAttendeesResponse>(url)
    },
)

const attendeesSlice = createSlice({
    name: 'attendees',
    initialState: {
        order: [] as number[], // order of ids in server resp
        results: {} as Record<number, VirtualAttendee>,
        count: 0,
        offset: 0,

        orderSearch: [] as number[],
        resultsSearch: {} as Record<number, VirtualAttendee>,
        countSearch: 0,
        offsetSearch: 0,

        search: '',
        requestStatus: 'idle' as RequestStatus,
        fetchAttendeeForUsersStatus: 'idle' as RequestStatus,
        tagsFilter: {} as Record<string, number[]>,

        // Global Attendee Sidebar
        showSideBar: false,
        expandedSidebar: false,
    },
    reducers: {
        loadMore: (state, { payload }: PayloadAction<boolean>) => {
            if (payload) {
                state.offsetSearch += LIMIT
            } else {
                state.offset += LIMIT
            }
        },
        clearOffsetSearch: (state) => {
            state.offsetSearch = 0
        },
        clearOffset: (state) => {
            state.offset = 0
            state.offsetSearch = 0
        },
        removeAttendeeData: (state, { payload: attendeeIds }: PayloadAction<number[]>) => {
            attendeeIds.forEach((id) => {
                if (state.results[id]) {
                    delete state.results[id]
                }
                if (state.resultsSearch[id]) {
                    delete state.resultsSearch[id]
                }
            })
        },
        toggleSideBar: (state) => {
            state.showSideBar = !state.showSideBar
        },
        toggleExpandedSidebar: (state) => {
            state.expandedSidebar = !state.expandedSidebar
        },
        searchAttendees: (state, { payload }: PayloadAction<string>) => {
            state.search = payload
        },
        updateTagsFilter: (state, { payload }: PayloadAction<Record<string, number[]>>) => {
            state.tagsFilter = {
                ...state.tagsFilter,
                ...payload
            }
        },
        closeSidebar: (state) => {
            state.showSideBar = false
            state.expandedSidebar = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAttendeeList.fulfilled, (state, { payload }) => {
            state.requestStatus = 'succeeded'
            state.order = uniq([...state.order, ...payload.results.map(({ id }) => id)])
            state.count = payload.count
            payload.results.forEach((profile) => {
                state.results[profile.id] = profile as VirtualAttendee
            })
        })
        builder.addCase(getAttendeeList.pending, (state) => {
            state.requestStatus = 'loading'
        })
        builder.addCase(getAttendeeListWithSearch.fulfilled, (state, { payload }) => {
            state.requestStatus = 'succeeded'
            if (state.offsetSearch === 0) {
                state.resultsSearch = {}
                state.orderSearch = []
            }
            state.orderSearch = uniq([...state.orderSearch, ...payload.results.map(({ id }) => id)])
            state.countSearch = payload.count
            payload.results.forEach((profile) => {
                state.resultsSearch[profile.id] = profile as VirtualAttendee
            })
        })
        builder.addCase(getAttendeeListWithSearch.pending, (state) => {
            state.requestStatus = 'loading'
        })
        builder.addCase(getAttendeesForUsers.fulfilled, (state, { payload }) => {
            payload.results.forEach((profile) => {
                state.results[profile.id] = profile as VirtualAttendee
            })
            state.fetchAttendeeForUsersStatus = 'succeeded'
        })
        builder.addCase(getAttendeesForUsers.pending, (state) => {
            state.fetchAttendeeForUsersStatus = 'loading'
        })
        builder.addCase(attendeeList.WS.attendee_updated, (state, { payload }: { payload }) => {
            if (state.results && state.results[payload.id]) {
                state.results[payload.id] = payload
            }
        })
        builder.addCase(attendeeList.WS.privacy_status_updated, (state, { payload }) => {
            if (state.results && state.results[payload.user]) {
                state.results[payload.user].is_public = payload.is_public
            }
        })
    },
})

export const createAnonymousUserIfNotPublic = (user: VirtualAttendee) => {
    if (user && user.is_public) {
        return user
    }

    return {
        ...user,
        first_name: 'Anonymous',
        last_name: '',
        jobtitle: '',
        company: '',
        mugshot: 'https://secure.gravatar.com/avatar/8fbf5ffda6230029170d4bf05c9f332a?s=140&d=mm',
    }
}

export const publicAttendeesSelector = (hasSearch?: boolean) => createSelector(
    [
        (state: RootState) => state.auth.user,
        (state: RootState) => state.attendees.order,
        (state: RootState) => state.attendees.results,
        (state: RootState) => state.attendees.orderSearch,
        (state: RootState) => state.attendees.resultsSearch
    ],
    (user, order, results, orderSearch, resultsSearch) => (hasSearch ? orderSearch : order)
        .map((id) => (hasSearch ? resultsSearch[id] : results[id]))
        .filter((v) => v)
        .filter((e) => e.id !== user.id && e.is_public)
        .sort((e) => (e.is_online ? -1 : 1)),
)

export const attendeesSelector = (hasSearch?: boolean) => createSelector(
    [
        (state: RootState) => state.auth.user,
        (state: RootState) => state.attendees.order,
        (state: RootState) => state.attendees.results,
        (state: RootState) => state.attendees.orderSearch,
        (state: RootState) => state.attendees.resultsSearch
    ],
    (user, order, results, orderSearch, resultsSearch) => (hasSearch ? orderSearch : order)
        .map((id) => (hasSearch ? resultsSearch[id] : results[id]))
        .filter((v) => v)
        .filter((e) => e.id !== user.id),
)

export const canLoadMoreSelector = (hasSearch?: boolean) => createSelector(
    [
        (state: RootState) => state.attendees.offset,
        (state: RootState) => state.attendees.count,
        (state: RootState) => state.attendees.offsetSearch,
        (state: RootState) => state.attendees.countSearch,
    ],
    (offset, count, offsetSearch, countSearch) => (
        hasSearch ? (offsetSearch + LIMIT < countSearch) : (offset + LIMIT < count)
    )
)

export const selectAttendeeStatus = (state: RootState) => state.attendees.requestStatus
export const selectAttendeeOffset = (hasSearch: boolean) => (state: RootState) => (
    hasSearch ? state.attendees.offsetSearch : state.attendees.offset
)
export const selectAttendeeMap = (state: RootState) => state.attendees.results
export const selectAttendeeForUser = (userId: number) => (state: RootState) => (
    state.attendees.results[userId]
)
export const attendeeForUsersStatusSelector = (state: RootState) => (
    state.attendees.fetchAttendeeForUsersStatus
)
export const showSideBarSelector = (state: RootState) => state.attendees.showSideBar
export const expandedSideBarSelector = (state: RootState) => state.attendees.expandedSidebar
export const searchAttendeesSelector = (state: RootState) => state.attendees.search
export const tagsFilterSelector = (state: RootState) => state.attendees.tagsFilter

export const loadMore = attendeesSlice.actions.loadMore
export const removeAttendeeData = attendeesSlice.actions.removeAttendeeData
export const clearOffsetSearch = attendeesSlice.actions.clearOffsetSearch
export const clearOffset = attendeesSlice.actions.clearOffset
export const toggleSideBar = attendeesSlice.actions.toggleSideBar
export const toggleExpandedSidebar = attendeesSlice.actions.toggleExpandedSidebar
export const searchAttendees = attendeesSlice.actions.searchAttendees
export const updateTagsFilter = attendeesSlice.actions.updateTagsFilter
export const closeSidebar = attendeesSlice.actions.closeSidebar

export default attendeesSlice.reducer
