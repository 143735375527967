import React, { CSSProperties } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import colors from '../../../constants/colors'
import { closeSidebar as closeAttendeeSidebar } from '../../../features/attendees/attendeesSlice'
import { toggleSidebar, calendarSidebarInfoSelector } from '../../../features/calendar/calendarSlice'
import { changeShowTooltip } from '../../../features/events/fullScreenSlice'
import { closeSidebar as closePerfomerSidebar } from '../../../features/performer/performerSlice'
import Icon from '../../Icon'
import { _ } from '../../../utils/localization'

export const S = {
    Wrapper: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        border-left: 1px solid ${colors.navDivider};
        cursor: pointer;
    
        & > span.label {
            margin-left: 14px;
            font-size: 14px;
            color: ${colors.grayTitle};
        }
    `
}

const CalendarToggle = ({
    style, showLabel
}: {style?: CSSProperties, showLabel?: boolean}) => {
    const dispatch = useDispatch()
    const { isShowing } = useSelector(calendarSidebarInfoSelector)
    const handleToggleAttendeesSidebar = () => {
        dispatch(changeShowTooltip(false))
        dispatch(closeAttendeeSidebar())
        dispatch(closePerfomerSidebar())

        dispatch(toggleSidebar())
    }

    return (
        <S.Wrapper style={style} onClick={handleToggleAttendeesSidebar}>
            <Icon
                icon="timetable"
                isActive={isShowing}
                size={20}
            />
            {showLabel && <span className="label">{_('nav.calendar_icon.helper_text')}</span>}
        </S.Wrapper>
    )
}

export default CalendarToggle
