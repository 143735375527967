import React, { Suspense } from 'react'
import useResponsive from '../../../hooks/useResponsive'

const PresentationRoomMobile = React.lazy(() => import('./PresentationRoomMobile'))
const PresentationRoom = React.lazy(() => import('./PresentationRoom'))

const PresentationRoomView = () => {
    const {isMobile} = useResponsive()

    return isMobile ? (
        <Suspense fallback={null}>
            <PresentationRoomMobile />
        </Suspense>
    ) : (
        <Suspense fallback={null}>
            <PresentationRoom />
        </Suspense>
    )
}

export default PresentationRoomView
