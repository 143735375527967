import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useHomeLink from '../../hooks/useHomeLink'

const EventMapRedirect = () => {
    const history = useHistory()
    const homeLink = useHomeLink()

    useEffect(() => {
        history.push(homeLink)
    }, [history, homeLink])
    return null
}

export default EventMapRedirect
