import Videocall from './views/Videocall'
import { createEventRoute } from '../../utils/routes'

export default {
    videoCall: createEventRoute({
        title: 'Video Call',
        path: '/videocall/:roomId',
        component: Videocall,
    }),
}
