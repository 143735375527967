import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { _ } from '../../../utils/localization'
import { RetrieveVirtualEventResponse } from '../../../types/__generated_swagger'
import TicketCodeForm from './TicketCodeForm'
import IntroVideo from './IntroVideo'
import { useOpenHelpModal } from '../../helpcontent/HelpContent'
import Icon from '../../../components/Icon/Icon'
import BrowserSupportToast from '../../browsersupport/BrowserSupportToast'
import BackgroundImagePlaceholder from '../../../assets/Background_placeholder.jpg'
import routes from '../../../routes'

export const S = {
    Page: styled.div`
      height: var(--window-height);
      margin-top: -58px;  // escape top nav
      display: flex;
      flex-direction: row;
    `,
    Image: styled.div<{ $image: string }>`
      background: ${({ $image }) => `url(${$image})`};
      background-size: cover;
      background-position: center center;
      border: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      flex-grow: 4;
      justify-content: space-between;
    `,
    Content: styled.div`
      width: 400px;
      flex-grow: 1;
      height: 100%;
      padding: 40px;
      display: flex;
      flex-direction: column;
    `,
    Logo: styled.img`
        max-width: 400px;
        max-height: 100px;
        object-fit: contain;
        object-position: left top;
        flex-shrink: 0;
    `,
    TextOverImage: styled.span`
      color: white;
      font-weight: bold;
      margin-left: 6px;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
    `,
    TextOverImageWrapper: styled.div`
      display: flex;
      justify-content: center;
      padding: 12px;
      cursor: pointer;
    `,
    AlignBottom: styled.div`
      display: flex;
      justify-content: space-between;
      width: 100%;
    `,
    TextOverImageLink: styled(Link)`
      opacity: 0 !important; // temporarly disabled
      text-decoration:none !important;
      display: flex;
      justify-content: center;
      padding: 12px;
      cursor: pointer;
      margin-left: auto;
    `,
    IntoText: styled.div`
        margin: 2rem -40px 2rem 0;
        padding-right: 40px;
        overflow: auto;
    `
}

export const NeedHelp = () => {
    const onClick = useOpenHelpModal()
    return (
        <S.TextOverImageWrapper className="help" onClick={onClick}>
            <Icon
                size={21}
                icon="question"
                onClick={onClick}
                color="white"
            />

            <S.TextOverImage>
                {_('auth.enter_event.need_help')}
            </S.TextOverImage>
        </S.TextOverImageWrapper>
    )
}

const MyBrowserLink = () => (
    <S.TextOverImageLink to="/my-browser/">
        <Icon
            size={21}
            icon="check"
            color="white"
        />

        <S.TextOverImage>
            {_('auth.enter_event.check_my_browser')}
        </S.TextOverImage>
    </S.TextOverImageLink>
)

const TicketCodePage = ({ eventData }: { eventData: RetrieveVirtualEventResponse }) => {
    // @ts-ignore
    const isBackStage = useRouteMatch(routes.enterEventBackStage.path)

    return (
        <S.Page>
            <S.Image $image={eventData?.background_image || BackgroundImagePlaceholder}>
                <MyBrowserLink />
                <BrowserSupportToast />

                <S.AlignBottom>
                    {eventData?.help_content ? <NeedHelp /> : <div />}

                    <S.TextOverImageWrapper>
                        <IntroVideo eventData={eventData}>
                            <Icon
                                icon="refresh"
                                color="black"
                                style={{ background: 'white', borderRadius: 24, lineHeight: 1 }}
                                size={21}
                            />
                            <S.TextOverImage>
                                {_('auth.enter_event.replay_video')}
                            </S.TextOverImage>
                        </IntroVideo>
                    </S.TextOverImageWrapper>
                </S.AlignBottom>

            </S.Image>

            <S.Content>
                <S.Logo src={eventData?.logo} alt="" />
                {
                    eventData.published || isBackStage
                        ? <TicketCodeForm eventData={eventData} />
                        : <S.IntoText className="editor-content" dangerouslySetInnerHTML={{ __html: eventData.intro_text }} />
                }
            </S.Content>
        </S.Page>
    )
}

export default TicketCodePage
