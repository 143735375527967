import { createEventRoute } from '../../utils/routes'
import ManageAppointment from './ManageAppointment'

export default {
    appointments: createEventRoute({
        title: 'Appointments',
        path: '/appointments',
        exact: true,
        component: ManageAppointment,
    }),
}
