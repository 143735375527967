import { createAsyncThunk } from '@reduxjs/toolkit'
import { emptyData as EmptyEventData } from './eventSlice'
import { emptyData as EmptyNavData } from './navigationSlice'

export default createAsyncThunk(
    'EXIT_EVENT',
    async (_, thunkAPI) => {
        // @ts-ignore
        thunkAPI.dispatch(EmptyEventData())
        // @ts-ignore
        thunkAPI.dispatch(EmptyNavData())
    },
)
