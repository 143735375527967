import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { websocketAction } from '../../utils/redux'

export interface UpdatedPublishedSpace {
    id: number
    space_slug: string
    published: boolean
}

export const WS = {
    publishedSpaceUpdated: websocketAction<UpdatedPublishedSpace>('updated_published_space')
}

const spaceSlice = createSlice({
    name: 'spaces',
    initialState: {
        isInSpace: false,
        standingSpace: undefined as number,
        updatedSpace: {} as Record<string, UpdatedPublishedSpace>
    },
    reducers: {
        setStandingSpace(state, { payload }) {
            state.standingSpace = payload
        },
        clearStandingSpace(state) {
            state.standingSpace = undefined
            state.isInSpace = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(WS.publishedSpaceUpdated, (state, { payload }) => {
            state.updatedSpace[payload.id] = payload
            state.isInSpace = state.standingSpace === payload.id
        })
    },
})

export const updatedSpaceSelector = (id) => (state: RootState) => state.spaces.updatedSpace?.[id]
export const isInSpaceSelector = (state: RootState) => state.spaces.isInSpace
export const allUpdatedSpacesSelector = (state: RootState) => state.spaces.updatedSpace

export const { setStandingSpace, clearStandingSpace } = spaceSlice.actions

export default spaceSlice.reducer
