import React from 'react'
import ReactIcon from '@mdi/react'
import styled from 'styled-components'
import colors from '../../constants/colors'

// This imports a huge file, we can just take out the icons we need
// at some point in the future. Or see if we can do so with webpack
// Find icons at: https://materialdesignicons.com/
import { IconOption, iconPaths } from './icons'

export interface IconProps {
    icon: IconOption
    // Optional:
    isActive?: boolean
    size?: number | string
    color?: string
    activeColor?: string
    onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
    style?: React.CSSProperties
    className?: string
}

export interface StyledIconProps {
    isClickable?: boolean
}

const S = {
    IconWrapper: styled.span<StyledIconProps>`
        display: inline-block;
        cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'unset')};
        :hover {
            filter: brightness(${({ isClickable }) => (isClickable ? 0.6 : 1)});
        }
    `,
}

const Icon = ({
    icon,
    size = 24,
    isActive = false,
    color = colors.icon,
    activeColor = colors.activeIconColor,
    onClick,
    style,
    className,
}: IconProps) => {
    const currentColor = isActive
        ? activeColor
        : color
    return (
        <S.IconWrapper
            onClick={onClick}
            isClickable={!!onClick}
            style={{ ...style }}
        >
            <ReactIcon
                path={iconPaths[icon]}
                size={typeof size === 'number' ? `${size}px` : size}
                color={currentColor}
                className={className}
            />
        </S.IconWrapper>
    )
}

export default Icon
