import Logger from 'js-logger'
import constants from '../constants/constants'
import env from '../constants/env'
import { raiseError } from './errorTracker'

const PREFIX = 'NetworkMonitor: '
const AGE = 5000
const element = document.createElement('div')

const createEntryStore = (store: (representation: string) => void) => {
    let entries = []
    return {
        add: (representation: string) => {
            entries.push(Date.now())
            store(representation)
        },
        clear: () => {
            const limit = Date.now() - AGE
            entries = entries.filter((entry) => entry > limit)
        },
        count: () => entries.length,
        text: () => `${entries.length} / ${AGE / 1000}s`,
        checkCount: (name: string, errorLimit: number, warningLimit: number) => {
            const count = entries.length
            if (count > errorLimit) {
                raiseError(Error(`${PREFIX} ${count} ${name} were sent over the last ${AGE}ms`))
            }
            if (count > warningLimit) {
                Logger.warn(`${PREFIX} ${count} ${name} were sent over the last ${AGE}ms`)
            }
        },
    }
}

const monitor = () => {
    element.textContent = `http: ${http.text()}
incoming ws: ${incomingWs.text()}
outgoing ws: ${outgoingWs.text()}`
    http.checkCount('http', 20, 10)
    incomingWs.checkCount('incomingWs', 100, 50)
    outgoingWs.checkCount('outgoingWs', 15, 30)
}

const clearOldEntries = () => {
    http.clear()
    incomingWs.clear()
    outgoingWs.clear()
}

// eslint-disable-next-line
let store = (s: string) => null

if (env.isDevelopment) {
    element.style.cssText = `
        position:fixed;
        color: black;
        background-color: white;
        height:20px;
        opacity:0.8;
        z-index: ${constants.Z_INDEX.NETWORK_MONITOR};
        white-space: pre;
        height: 80px;
        top:0;
        left: 0;
        padding: 4px;
        display: none;
    `
    document.body.appendChild(element)
    setInterval(monitor, 1000)
    setInterval(clearOldEntries, 1000)
    const networkLog = []
    store = (s: string) => networkLog.push([new Date().toLocaleTimeString(), s])

    // @ts-ignore
    window.getCount = () => {
        const counts = {}
        networkLog.forEach((entry) => {
            const key = entry[1].split('ds=')[0]
            counts[key] = (counts[key] || 0) + 1
        })
        return counts
    }

    // @ts-ignore
    window.networkLog = networkLog
}

const http = createEntryStore((s: string) => store(`http: ${s}`))
const incomingWs = createEntryStore((s: string) => store(`incomingWs: ${s}`))
const outgoingWs = createEntryStore((s: string) => store(`outgoingWs: ${s}`))

export default {
    http,
    incomingWs,
    outgoingWs,
}
