import React from 'react'
import styled from 'styled-components'
import CustomPopover from '../../components/CustomPopover'
import { _ } from '../../utils/localization'
import useRedirectToRoom from './useRedirectToRoom'
import { Videocall } from '../../types/swagger'
import AttendeeList from '../networking/components/AttendeeList'
import { CallConfig } from './types'

export const S = {
    AttendeeWrapper: styled.div`
        max-height: 200px;
        overflow-y: auto;
    `,
}

interface JoinConfirmationProps {
    close: () => void
    videocall: Videocall
    config: CallConfig
}

const JoinConfirmation = ({ close, videocall, config }: JoinConfirmationProps) => {
    const redirect = useRedirectToRoom(videocall.twilio_name, config)
    const redirectAndClose = () => {
        redirect()
        close()
    }
    return (
        <CustomPopover
            // eslint-disable-next-line react/prop-types
            title={`${_('networking.popovers.join')} '${videocall.display_name}'`}
            cancelButton={_('networking.popovers.not_now_button')}
            confirmButton={_('networking.popovers.join_meeting_button')}
            onCancel={close}
            onConfirm={redirectAndClose}
            cancelIcon="close"
            confirmIcon="check"
        >
            <AttendeeList attendees={videocall.users} />
        </CustomPopover>
    )
}

export default JoinConfirmation
