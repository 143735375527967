import { PayloadAction } from '@reduxjs/toolkit'
import { useEffect, useRef } from 'react'
import { Action } from 'redux'

type Fn = (action: Action) => any
type Id = number

export const reduxBus = (() => {
    const functions: Record<Id, Fn> = {}
    return {
        send: (action) => {
            Object.values(functions).forEach((fn) => fn(action))
        },
        register: (id: Id, fn: Fn) => {
            functions[id] = fn
        },
        unregister: (id: Id) => {
            delete functions[id]
        },
    }
})()

const createId = () => Math.round(Math.random() * 9999)

const withTypeFilter = (fn: Fn, typeFilter?: string): Fn => (action: Action) => {
    if (!typeFilter || action.type === typeFilter) {
        fn(action)
    }
}

export const useReduxBus = (fn: Fn, typeFilter?: string) => {
    const idRef = useRef(createId())
    const id = idRef.current
    useEffect(() => {
        reduxBus.register(id, withTypeFilter(fn, typeFilter))
        return () => reduxBus.unregister(id)
    })
}

export default () => (next) => (action: PayloadAction<{message: string}>) => {
    reduxBus.send(action)
    return next(action)
}
