import React, {
    useLayoutEffect, useRef, useState, useCallback
} from 'react'
import ListGroup from 'react-bootstrap/cjs/ListGroup'
import styled from 'styled-components'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { useHistory, useLocation } from 'react-router'
import Icon from '../../../../components/Icon'
import { IconOption } from '../../../../components/Icon/icons'
import useClickOutSide from '../../../../hooks/useClickOutSide'

import { _ } from '../../../../utils/localization'
import { currentUserSelector } from '../../../auth/authSlice'
import env from '../../../../constants/env'
import useSlug from '../../../../hooks/useSlug'
import useHelpContent from '../../../../hooks/useHelpContent'
import LeaveBusinessCardModal from './LeaveBusinessCardModal'
import { addModal } from '../../../modals/modalSlice'
import CardContainer from '../../../../components/CardContainer'
import BoothVideos from './BoothVideos'
import { Booth } from '../../types'
import BoothDocuments from './BoothDocuments'
import Bookmark from '../../../goodiebag/Bookmark'
import { GoodiebagType } from '../../../goodiebag/types'
import colors from '../../../../constants/colors'
import BusinessCardManagement from './BusinessCardManagement'
import { VirtualAttendee } from '../../../../types/swagger'
import BoothJobs from './BoothJobs'
import routes from '../../../jobs/routes'
import { eventPreviewSelector, showJobsInsideBoothsSelector } from '../../../auth/eventPreviewSlice'
import SponsoredSessions from './SponsoredSessions'
import { useJoinBooth, useSubscribeToBoothVistors } from '../../subscriptions'
import BoothVisitorsSidebar from './BoothVisitorsSidebar'
import { showSidebarSelector, toggleSidebar } from '../../boothVististorsSlice'
import { showSideBarSelector as showAttendeeSidebarSelector } from '../../../attendees/attendeesSlice'
import Divider from '../../../../components/Divider'
import { leaveBusinessCard } from '../../leaveBusinessCardSlice'
import useDebounce from '../../../../hooks/useDebounce'

interface MenuItemProps {
    icon: IconOption;
    text: string;
    eventKey?: string;
    backgroundColor?: string;
    color?: string;
    children?: any;
    onClick?: () => void
}

const S = {
    Wrapper: styled.div`
      display: flex;
      width: 100%;
      height: 100%;
      margin-top: 20px;
      flex: 1;
      position: relative;
      overflow: hidden;
    `,
    Sidebar: styled.div`
      width: 220px;
      background-color: white;
    `,
    AnchorMenuItem: styled.a`
      padding: 8px 8px 8px 20px;
      border: none;
      color: ${colors.darkTitle};
      display: flex;
      align-items: center;
      &:hover {
          text-decoration: none;
          background-color: ${colors.gray1};
      }
    `,
    MenuItem: styled(ListGroup.Item).attrs({ action: true })<{isActive: boolean}>`
      border: none;
      border-radius: 0!important;
      padding: 8px 0 8px 20px;
      background: ${({ $backgroundColor, isActive }) => (
        $backgroundColor || (isActive ? colors.gray1 : 'transparent')
    )};
      color: ${({ color }) => color || colors.darkTitle};
      display: flex;
      align-items: center;
    `,
    DropdownIcon: styled.div`
      margin-left: auto;
    `,
    VideoCallName: styled.span`
      margin-left: 12px;
    `,
    NavLink: styled(Nav.Link)`
      padding: 0;
      color: inherit!important;
      font-size: 16px;
      font-weight: 300;
      &:hover {
          text-decoration: none;
      }
    `,
    MenuText: styled.span<{color?: string}>`
      max-width: 120px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: 300;
      color: ${({ color }) => color || colors.darkTitle} !important;
    `,
    SubMenu: styled.div`
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      transform: translateX(100%);
      max-height: 180px;
      overflow: auto;
      box-shadow: 5px 0 10px rgba(0, 0, 0, 0.25);
    `,
    SubMenuGroup: styled(ListGroup)`
      width: 300px;
      background-color: white;
    `,
    SubMenuItem: styled(ListGroup.Item)`
      display: flex;
      align-items: center;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    BusinessCardButton: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        width: 100%;
        margin-top: 8px;
        margin-bottom: -10px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        font-size: 16px;
        color: ${colors.primary};
        text-transform: uppercase;
        background-color: ${colors.primaryOpacity(0.1)};
        cursor: pointer;
    `,
    SidebarCard: styled(CardContainer)`
        margin-right: 20px;
        padding: 10px 0;
        align-self: baseline;
    `,
    TabContentCard: styled(CardContainer)`
        flex-grow: 1;
        flex-basis: 100px;
        overflow: hidden;
    `,
    BoothName: styled.h3`
        font-size: 32px;
        color: ${colors.grayTitle};
        font-weight: 500;
        display: flex;
        align-items: flex-end;
    `,
    BoothDescription: styled.p`
        color: ${colors.grayDescription};
    `
}

const MenuIcon = ({ icon, color, size = 18 }: { icon: IconOption, color?: string, size?: number }) => (
    <Icon icon={icon} style={{ margin: '0 5px 3px 0' }} color={color || colors.icon} size={size} />
)

const MenuItem = ({
    icon, text, backgroundColor, color, eventKey, children, onClick
}: MenuItemProps) => {
    const location = useLocation()
    const isActive = location.hash === eventKey
    const [isShowSubMenu, setIsShowSubMenu] = useState(false)
    const linkRef = useRef<HTMLAnchorElement>()
    const subMenuRef = useRef<HTMLDivElement>()
    useClickOutSide(subMenuRef, () => isShowSubMenu && setIsShowSubMenu(false))

    const handleClick = () => {
        if (eventKey) {
            linkRef.current.click()
            return
        }
        setIsShowSubMenu(!isShowSubMenu)
    }

    return (
        <S.MenuItem
            action
            $backgroundColor={backgroundColor}
            color={color}
            onClick={onClick || handleClick}
            isActive={isActive}
        >
            <MenuIcon icon={icon} color={color} />
            {eventKey
                ? (
                    <S.NavLink eventKey={eventKey} ref={linkRef} href={eventKey}>{text}</S.NavLink>
                )
                : <S.MenuText color={color}>{text}</S.MenuText>}
            {!eventKey
            && children && (
                <S.DropdownIcon>
                    <MenuIcon icon="right" color={color} />
                </S.DropdownIcon>
            )}
            {isShowSubMenu && children
            && (
                <S.SubMenu ref={subMenuRef}>
                    <S.SubMenuGroup>
                        {children}
                    </S.SubMenuGroup>
                </S.SubMenu>
            )}
        </S.MenuItem>
    )
}

const AnchorMenuItem = ({ icon, text, link }: { icon: IconOption, text: string, link: string }) => (
    <S.AnchorMenuItem as="a" target="__blank" href={link}>
        <MenuIcon icon={icon} />
        <S.MenuText>{text}</S.MenuText>
    </S.AnchorMenuItem>
)

export const createManageLink = (eventSlug: string, boothSlug: string) => (
    `${env.virtualPortalUrl}/${eventSlug}/booth/${boothSlug}`
)

export const getLeftBusinessCardKey = (eventSlug, boothSlug) => `${eventSlug}__${boothSlug}__left_businesscard`

const Sidebar = ({
    boothDetail, staffs, showJobsInsideBooths
}: { boothDetail: Booth, staffs: VirtualAttendee[], showJobsInsideBooths?: boolean }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const currentUser = useSelector(currentUserSelector())
    const eventSlug = useSlug('eventSlug')
    const boothSlug = useSlug('boothSlug')
    const isStaff = boothDetail.staff_ids.includes(currentUser.id)
    const showAttendeeSidebar = useSelector(showAttendeeSidebarSelector)
    const showSidebar = useSelector(showSidebarSelector)
    const eventPreview = useSelector(eventPreviewSelector)

    useLayoutEffect(() => {
        if (boothDetail.enable_visitor_list && !showAttendeeSidebar && isStaff) {
            dispatch(toggleSidebar(true))
        }
        return () => {
            dispatch(toggleSidebar(false))
        }
    }, [boothDetail.enable_visitor_list, dispatch, showAttendeeSidebar, boothSlug, isStaff])

    const showLeaveBusinessCardModal = () => {
        dispatch(addModal({
            Component: ({ close }: {close: () => void}) => (
                <LeaveBusinessCardModal
                    staffs={staffs}
                    close={close}
                    businesscard={boothDetail.left_business_card}
                />
            ),
            size: 'xl',
            padding: 0
        }))
    }

    const redirectToJobSpace = () => {
        const url = routes.jobs.getPath({eventSlug, jobsSlug: boothDetail.jobs_slug})
        history.push(`${url}?booth_slug=${boothDetail.slug}`)
    }

    const leaveBusinesscardAutomatically = useCallback(() => {
        if (
            boothDetail.left_business_card
            || localStorage.getItem(getLeftBusinessCardKey(eventSlug, boothSlug)) === 'true'
            || isStaff
        ) {
            return
        }
        localStorage.setItem(getLeftBusinessCardKey(eventSlug, boothSlug), 'true')

        dispatch(leaveBusinessCard({
            eventSlug,
            spaceSlug: boothSlug,
            requestBody: {is_auto: true},
            preventNotification: true,
        }))
    }, [boothDetail.left_business_card, boothSlug, dispatch, eventSlug, isStaff])

    const leaveBusinesscardAutomaticallyDebounce = useDebounce(leaveBusinesscardAutomatically, 1500)

    return (
        <S.SidebarCard>
            <S.Sidebar>
                <ListGroup onClick={leaveBusinesscardAutomaticallyDebounce}>
                    <MenuItem icon="info" text={_('booth.tabs.information')} eventKey="#information" />
                    {!isEmpty(boothDetail.videos) && (
                        <MenuItem icon="youtube" text={_('booth.tabs.videos')} eventKey="#video" />
                    )}
                    {!isEmpty(boothDetail.documents) && (
                        <MenuItem icon="file" text={_('booth.tabs.documents')} eventKey="#document" />
                    )}
                    {boothDetail.has_jobs && (showJobsInsideBooths ? (
                        <MenuItem icon="jobs" text={_('booth.tabs.jobs')} eventKey="#jobs" />
                    ) : (boothDetail.jobs_slug && (
                        <MenuItem
                            onClick={redirectToJobSpace}
                            icon="jobs"
                            text={_('booth.tabs.jobs')}
                        />
                    )))}

                    {boothDetail?.website && (
                        <AnchorMenuItem
                            link={boothDetail.website}
                            icon="earth"
                            text={_('booth.tabs.website')}
                        />
                    )}

                    {!isEmpty(boothDetail?.sponsored_sessions) && (
                        <MenuItem
                            icon="hybrid"
                            text={_('booth.tabs.sponsored_sessions')}
                            eventKey="#sponsored_sessions"
                        />
                    )}

                    {isStaff && <Divider marginBottom={10} marginTop={10} />}
                    {isStaff && boothDetail.enable_visitor_list && eventPreview.show_booth_sidebar && (
                        <MenuItem
                            onClick={() => dispatch(toggleSidebar())}
                            icon="homeSwitch"
                            text={(showSidebar
                                ? _('booth.show_navigation_sidebar')
                                : _('booth.hide_navigation_sidebar')
                            )}
                        />
                    )}
                    {isStaff && (
                        <AnchorMenuItem
                            link={createManageLink(eventSlug, boothDetail.slug)}
                            icon="settings"
                            text={_('booth.tabs.manage')}
                        />
                    )}
                    {isStaff
                        ? (
                            <MenuItem
                                icon="cardAccountDetailsOutline"
                                text={_('booth.tabs.business_card_management')}
                                eventKey="#business-card-management"
                                backgroundColor="rgba(0, 133, 255, 0.05)"
                                color={colors.primary}
                            />
                        )
                        : (
                            <S.BusinessCardButton onClick={showLeaveBusinessCardModal}>
                                {_('booth.tabs.leave_businesscard')}
                            </S.BusinessCardButton>
                        )}
                </ListGroup>
            </S.Sidebar>
        </S.SidebarCard>
    )
}

const Websocket = ({id, showSidebar}: {id: number, showSidebar: boolean}) => {
    useJoinBooth(`${id}`)
    useSubscribeToBoothVistors(showSidebar && `${id}`)

    return <></>
}

const BoothDetail = ({ boothDetail, staffs }: { boothDetail: Booth, staffs: VirtualAttendee[] }) => {
    const defaultActiveKey = '#information'
    const boothSlug = useSlug('boothSlug')
    const currentUser = useSelector(currentUserSelector())
    const isStaff = boothDetail.staff_ids.includes(currentUser.id)
    const showJobsInsideBooths = useSelector(showJobsInsideBoothsSelector)
    const showSidebar = useSelector(showSidebarSelector)

    useHelpContent({
        data: boothDetail.help_content, type: boothDetail.help_content_type
    }, boothSlug !== boothDetail.slug)

    return (
        <S.Wrapper>
            {boothSlug === boothDetail.slug && boothDetail.enable_visitor_list && (
                <Websocket id={boothDetail.id} showSidebar={showSidebar} />
            )}
            {showSidebar && boothDetail.enable_visitor_list && <BoothVisitorsSidebar />}
            <Tab.Container id="side-bar-booth" defaultActiveKey={defaultActiveKey}>
                <Sidebar
                    boothDetail={boothDetail}
                    staffs={staffs}
                    showJobsInsideBooths={showJobsInsideBooths}
                />
                {boothSlug === boothDetail.slug && (
                    <S.TabContentCard>
                        <Tab.Content>
                            <Tab.Pane eventKey="#information" style={{ padding: 20 }}>
                                <S.BoothName>
                                    {boothDetail.name}
                                    <Bookmark
                                        item={{ id: boothDetail.id, type: GoodiebagType.Booth }}
                                        size={22}
                                        style={{ margin: '0 0 4px 8px' }}
                                    />
                                </S.BoothName>
                                <S.BoothDescription>{boothDetail.subtitle}</S.BoothDescription>
                                <div className="editor-content" dangerouslySetInnerHTML={{ __html: boothDetail.description }} />
                            </Tab.Pane>
                            {!isEmpty(boothDetail.videos) && (
                                <Tab.Pane eventKey="#video" unmountOnExit>
                                    <BoothVideos boothDetail={boothDetail} />
                                </Tab.Pane>
                            )}
                            {!isEmpty(boothDetail.documents) && (
                                <Tab.Pane eventKey="#document">
                                    <BoothDocuments boothDetail={boothDetail} />
                                </Tab.Pane>
                            )}
                            {showJobsInsideBooths && boothDetail.has_jobs && (
                                <Tab.Pane eventKey="#jobs">
                                    <BoothJobs slug={boothDetail.slug} />
                                </Tab.Pane>
                            )}
                            {!isEmpty(boothDetail?.sponsored_sessions) && (
                                <Tab.Pane eventKey="#sponsored_sessions">
                                    <SponsoredSessions boothDetail={boothDetail} />
                                </Tab.Pane>
                            )}

                            {isStaff && (
                                <Tab.Pane eventKey="#business-card-management">
                                    <BusinessCardManagement staffs={staffs} />
                                </Tab.Pane>
                            )}
                        </Tab.Content>
                    </S.TabContentCard>
                )}
            </Tab.Container>
        </S.Wrapper>
    )
}

export default BoothDetail
