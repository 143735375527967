import * as Sentry from '@sentry/react'
import Logger from 'js-logger'
import { Integrations } from '@sentry/tracing'
import { RewriteFrames } from '@sentry/integrations'
import env from '../constants/env'
import history from '../history'

const ENABLE_SENTRY = !env.isDevelopment && env.sentryDns

export const setupErrorTracking = () => {
    if (ENABLE_SENTRY) {
        Sentry.init({
            normalizeDepth: 5, // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#normalization-depth
            dsn: env.sentryDns,
            release: `elevently@${env.buildVersion}`,
            environment: env.environment,
            integrations: [
                new Integrations.BrowserTracing({
                    // Can also use reactRouterV4Instrumentation
                    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                }),
                new RewriteFrames(),
            ],
        })
    }
}

export const raiseError = (e: Error | string) => {
    const error = typeof e === 'string' ? new Error(e) : e
    if (env.isDevelopment) {
        throw error
    } else {
        Sentry.captureException(error)
    }
}

export const captureError = (e: Error | string) => {
    const error = typeof e === 'string' ? new Error(e) : e
    if (env.isDevelopment) {
        Logger.error(error)
    } else {
        Sentry.captureException(error)
    }
}

export const ignoreError = (fn: () => any) => {
    try {
        return fn()
    // eslint-disable-next-line no-empty
    } catch (e) {}
}

export const withCapture = (fn: () => any) => {
    try {
        return fn()
    } catch (e) {
        captureError(e)
    }
}
