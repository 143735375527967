import useFetchInfiniteList from '../../../hooks/useFetchInfiniteList'
import useSlug from '../../../hooks/useSlug'
import { AppointmentSchedule } from '../../../types/swagger'

const LIMIT = 10

const getKey = (pageIndex, previousPageData, eventSlug, filter) => {
    if (previousPageData && previousPageData.length < LIMIT) { // reached the end
        return null
    }
    const searchParams = new URLSearchParams({
        filter,
        offset: `${pageIndex * LIMIT}`,
        limit: `${LIMIT}`,
    }).toString()
    return `/events/${eventSlug}/appointment_schedules/?${searchParams}`
}

const useFetchAppointmentList = (filter: 'upcoming' | 'past') => {
    const eventSlug = useSlug('eventSlug')
    const result = useFetchInfiniteList<AppointmentSchedule>(
        (pageIndex, previousPageData) => getKey(pageIndex, previousPageData, eventSlug, filter),
        LIMIT,
        { dedupingInterval: 15000, revalidateOnFocus: false }
    )

    return result
}

export default useFetchAppointmentList
