import React from 'react'
import { useDispatch } from 'react-redux'
import { addModal } from '../../modals/modalSlice'
import Profile from './Profile'
import { Performer as PerformerType } from '../../presentation/types'
import Performer from '../../performer/view/Performer'

const PerformerProfile = ({ performer }: { performer: PerformerType }) => {
    const dispatch = useDispatch()
    const showPerformerDetail = () => {
        const Component = () => <Performer performer={performer} />
        dispatch(addModal({
            Component, size: 'xl', showCloseIcon: true
        }))
    }
    return <Profile user={performer} onClick={() => showPerformerDetail()} />
}

export default PerformerProfile
