import { useMatomo } from '@datapunt/matomo-tracker-react'
import React, { useEffect } from 'react'
import { processUrl } from './embedded_urls'

interface EmbeddedVideoProps {
    width?: number | string;
    height?: number | string;
    url?: string;
    disableControl?: boolean
    startAt?: number
}

const EmbeddedVideo = ({
    url,
    width = '100%',
    height = '100%',
    disableControl,
    startAt
}: EmbeddedVideoProps) => {
    const { pushInstruction } = useMatomo()
    useEffect(() => {
        pushInstruction('MediaAnalytics::scanForMedia')
    }, [pushInstruction, url])

    return (
        <iframe
            src={processUrl(url || '', disableControl, startAt)}
            title="stream"
            width={width}
            height={height}
            frameBorder="0"
            allow="autoplay; fullscreen; camera; microphone"
            allowFullScreen
        />
    )
}

export default EmbeddedVideo
