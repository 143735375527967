import React from 'react'
import Icon from '../Icon/Icon'
import colors from '../../constants/colors'

interface Props {
    show: boolean
    onChange?: (s?: any) => void
    disabled?: boolean
}

const VisualIcon = ({show, onChange, disabled}: Props) => (
    <Icon icon={show ? 'eye' : 'eyeOff'} onClick={onChange} color={disabled ? colors.iconOpacity(0.5) : colors.icon} />
)

export default VisualIcon
