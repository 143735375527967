import React from 'react'
import Container from 'react-bootstrap/cjs/Container'
import { useDispatch, useSelector } from 'react-redux'
import { eventSelector } from '../../events/eventSlice'
import { clearNotification } from '../../soundnotifications/soundNotificationSlice'
import { clearEventPreviewState } from '../eventPreviewSlice'
import logout from '../logout'

const Logout = () => {
    const event = useSelector(eventSelector())
    const eventSlug = event?.slug
    const dispatch = useDispatch()

    // Redirect to login
    React.useEffect(() => {
        dispatch(clearNotification())
        if (eventSlug) {
            dispatch(clearEventPreviewState())
            dispatch(logout({ eventSlug }))
        }
    })

    return (
        <Container>
            <h1>Logging out</h1>
        </Container>
    )
}

export default Logout
