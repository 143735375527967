import { differenceInMinutes, isAfter } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Divider from '../../../../components/Divider'
import GeneralInfoWithThumbnail from '../../../../components/GeneralInfoWithThumbnail'
import Icon from '../../../../components/Icon'
import colors from '../../../../constants/colors'
import { SimpleAppointmentSchedule } from '../../../../types'
import { formatStartAndEndDate } from '../../../../utils/datetime'
import getMugshotSrc from '../../../../utils/getMugshotSrc'
import { _ } from '../../../../utils/localization'
import { formatJobInfo, formatName } from '../../../../utils/profile'
import { currentUserSelector } from '../../../auth/authSlice'
import JoinAppointmentButton from './JoinAppointmentButton'

export const S = {
    HeaderWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 100%;
        padding: 8px 12px;
        background-color: ${colors.primary};
    `,
    Header: styled.h3`
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        color: white;
    `,
    Date: styled.div`
        font-size: 12px;
        color: white;
        opacity: 0.75;
    `,
    Content: styled.div`
        padding: 8px 12px;
        background-color: ${colors.primaryOpacity(0.05)};
    `,
    Subject: styled.div`
        font-weight: 300;
        font-size: 12px;
    `,
    Info: styled.div`
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 12px;
        color: ${colors.darkTitle};
    `,
    Actions: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    `,
    TextButton: styled.div<{ color: string }>`
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: ${({ color }) => color};
        cursor: pointer;

        &:hover {
            filter: brightness(70%);
        }
    `
}

interface Props {
    appointmentSchedule: SimpleAppointmentSchedule
}

const AppointmentScheduleNotificationFromChatBot = ({
    appointmentSchedule
}: Props) => {
    const currentUser = useSelector(currentUserSelector())
    const meetingUserInfo = appointmentSchedule.sender_info?.id === currentUser?.id
        ? appointmentSchedule.receiver_info : appointmentSchedule.sender_info
    const minutesLeft = differenceInMinutes(new Date(appointmentSchedule.start_date), new Date())
    const isEnded = isAfter(new Date(), new Date(appointmentSchedule.end_date))

    return (
        <>
            <S.HeaderWrapper>
                <S.Header>
                    {_('chat.upcoming_meeting')}
                </S.Header>
                <S.Date>
                    {formatStartAndEndDate(appointmentSchedule.start_date, appointmentSchedule.end_date)}
                </S.Date>
            </S.HeaderWrapper>
            <S.Content>
                <GeneralInfoWithThumbnail
                    name={formatName(meetingUserInfo)}
                    description={formatJobInfo(meetingUserInfo)}
                    image={getMugshotSrc(meetingUserInfo)}
                    style={{ marginLeft: -4, marginBottom: 16 }}
                />
                <S.Subject className="text-cut-line-2">
                    {appointmentSchedule.subject}
                </S.Subject>
                {appointmentSchedule.location && (
                    <>
                        <Divider marginTop={10} marginBottom={8} />
                        <S.Info>
                            <Icon icon="mapMarker" size={16} className="mr-1" />
                            {appointmentSchedule.location}
                        </S.Info>
                    </>
                )}
                <>
                    <Divider marginTop={10} marginBottom={8} />
                    <S.Info>
                        <S.Actions>
                            <div className="flex-align-center">
                                <Icon icon="clockTimeFour" size={16} className="mr-1 mb-2px" />
                                {isEnded ? _('chat.appointment_notification.ended') : _(
                                    'chat.appointment_notification.start_in',
                                    { minutesLeft: Math.max(minutesLeft, 0) }
                                )}
                            </div>
                            {!isEnded && appointmentSchedule.twilio_name && (
                                <JoinAppointmentButton appointment={appointmentSchedule} />
                            )}
                        </S.Actions>
                    </S.Info>
                </>
            </S.Content>
        </>
    )
}

export default AppointmentScheduleNotificationFromChatBot
