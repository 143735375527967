import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {RootState} from '../store'

export default function useDispatchAndSelect<TSelected= unknown>(fetchData: () => any,
    state: (state: RootState) => TSelected, key?: string): TSelected {
    const dispatch = useDispatch()
    const selector = useSelector(state)
    useEffect(() => {
        // Don't fetch data if already had data from prefetching
        if ((!key && !selector) || (key && !selector[key])) {
            dispatch(fetchData())
        }
    }, [dispatch, fetchData, selector, key])
    return selector
}
