import React, { ReactNode } from 'react'
import styled from 'styled-components'

const S = {
    CardContainer: styled.div`
        width: 100%;
        padding: 40px;
        background-color: white;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        & div:last-child {
            margin-bottom: 0;
        }
    `
}

const CardContainer = ({ children }: {children: ReactNode}) => (
    <S.CardContainer>
        {children}
    </S.CardContainer>
)

export default CardContainer
