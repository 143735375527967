import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { RequestStatus } from '../../types'
import { PartialUpdateUserRequest } from '../../types/swagger'
import api from '../../utils/api'
import { getCurrentUser, updateCurrentUser } from '../auth/authSlice'
import { showErrorNotification } from '../notifications/notificationsSlice'
import { User } from '../auth/types'

interface EditProfile extends PartialUpdateUserRequest {
    id: number
    eventSlug?: string
}

export const saveProfileBadge = createAsyncThunk(
    'SAVE_PROFILE',
    async (props: EditProfile, thunkApi) => {
        thunkApi.dispatch(preventHideProfile(false))
        thunkApi.dispatch(editProfile(props))
    },
)

export const editProfile = createAsyncThunk(
    'EDIT_PROFILE',
    async ({ id, eventSlug = '', ...args }: EditProfile, thunkApi) => {
        const url = `/profiles/${id}/?event_slug=${eventSlug}`
        const response = await api.safePatch<PartialUpdateUserRequest>(url, args)
        if (response.error) {
            thunkApi.dispatch(showErrorNotification(response.error))
            return thunkApi.rejectWithValue(response)
        }
        thunkApi.dispatch(updateCurrentUser(response.data))
        return response
    },
)

export const uploadProfileImage = createAsyncThunk(
    'UPLOAD_PROFILE_IMAGE',
    async (args: {id: number, body: FormData}, thunkApi) => {
        const url = `/profiles/${args.id}/mugshot/`
        const response = await api.safePatch(url, args.body)
        if (response.error) {
            thunkApi.dispatch(showErrorNotification(response.error))
            thunkApi.rejectWithValue(response)
        }
        thunkApi.dispatch(updateCurrentUser(response?.data))
        return response
    },
)

export const uploadProfileDocument = createAsyncThunk(
    'UPLOAD_PROFILE_IMAGE',
    async (args: {id: number, body: FormData, eventSlug: string}, thunkApi) => {
        const url = `/profiles/${args.id}/document/?event_slug=${args.eventSlug}`
        const response = await api.safePatch(url, args.body)
        if (response.error) {
            thunkApi.dispatch(showErrorNotification(response.error))
            thunkApi.rejectWithValue(response)
        }
        thunkApi.dispatch(updateCurrentUser(response?.data))
        return response
    },
)

const editProfileSlice = createSlice({
    name: 'profile',
    initialState: {
        doNotDisturb: false as boolean,
        preventHideProfile: false as boolean,
        editProfileStatus: 'idle' as RequestStatus,
        profileImage: {
            data: null as {mugshot: string},
            status: 'idle' as RequestStatus,
        },
    },
    reducers: {
        setDoNotDisturb: (state, { payload }: PayloadAction<boolean>) => {
            state.doNotDisturb = payload
        },
        activateProfile: (state) => {
            state.preventHideProfile = true
        },
        preventHideProfile: (state, { payload }: PayloadAction<boolean>) => {
            state.preventHideProfile = payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentUser.fulfilled, (state, action: PayloadAction<{ user: User }>) => {
            state.doNotDisturb = action.payload.user.do_not_disturb

            // There are some cases where users are imported with excel files or API,
            // and they don't have first name + last name yet.
            // Will show badgeProfile and prevent hiding the badge until user filled first and last name
            if (!(action.payload.user?.first_name || '').trim() || !(action.payload.user?.last_name || '').trim()) {
                state.preventHideProfile = true
            }
        })
        builder.addCase(uploadProfileImage.pending, (state) => {
            state.profileImage.status = 'loading'
        })
        builder.addCase(uploadProfileImage.fulfilled, (state, { payload }) => {
            state.profileImage.status = 'succeeded'
            state.profileImage.data = payload.data
        })
        builder.addCase(editProfile.pending, (state) => {
            state.editProfileStatus = 'loading'
        })
        builder.addCase(editProfile.fulfilled, (state) => {
            state.editProfileStatus = 'succeeded'
        })
        builder.addCase(editProfile.rejected, (state) => {
            state.editProfileStatus = 'failed'
        })
    },
})

export const preventHideProfile = editProfileSlice.actions.preventHideProfile
export const activateProfile = editProfileSlice.actions.activateProfile
export const setDoNotDisturb = editProfileSlice.actions.setDoNotDisturb

export const editProfileStatusSelector = (state: RootState) => state.editProfile.editProfileStatus
export const doNotDisturbSelector = (state: RootState) => state.editProfile.doNotDisturb
export const preventHideProfileSelector = (state: RootState) => state.editProfile.preventHideProfile

export default editProfileSlice.reducer
