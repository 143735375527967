import { createAction } from '@reduxjs/toolkit'
import sendToWs from './sendToWs'
import { WS_MESSAGE_PREFIX } from '../../utils/redux'
import { raiseError } from '../../utils/errorTracker'

interface Props {
    receiverId: number
    event: string
    data?: Record<any, any>
}

/**
 * Function to send an event to a user.
 * Useful for events where the server doesn't have to
 * do anything with. eg:
 *
 * dispatch(sendToUser({ userId: 1, event: 'request_access', data: { room: 1 } }))
 */

export const SEND_TO_USER_TYPE = 'send_to_user'
export const RECEIVED_FROM_USER_TYPE = 'received_from_user'

export interface ReceivedFromUserPayload<T> {
    data?: T
    sender: number
    type: string
}

const createReceivedFromUserType = (type: string): string => (
    `${WS_MESSAGE_PREFIX}::${RECEIVED_FROM_USER_TYPE}::${type}`.toUpperCase()
)

export const receivedFromUserAction = <T>(type: string) => (
    createAction<ReceivedFromUserPayload<T>>(createReceivedFromUserType(type))
)

const sendToUser = ({ receiverId, event, data }: Props) => {
    if (!receiverId) {
        raiseError(Error(`sendToUser requires userId, receiver ${receiverId}`))
    }

    if (!event) {
        raiseError(Error(`sendToUser requires event, receiver ${event}`))
    }

    return sendToWs({
        user_id: receiverId,
        // Type is used so the server knows what to do with it
        type: SEND_TO_USER_TYPE,
        // event is used so the receiving browser knows what to do with it
        event,
        // data is optional, and can contain any dict
        data,
    })
}

export default sendToUser
