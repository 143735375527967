import Url from 'url-parse'

type ProviderOption = 'vimeo' | 'youtube' | 'appendee'

type ProcessUrlFn = (url: string, disableControl?: boolean, startAt?: number) => string

interface ProviderConfig {
    provider: ProviderOption
    url: string
}

const getSearchParams = (url) => {
    const _url = new URL(url)
    const params = Object.fromEntries(_url.searchParams.entries())
    return params
}

const setSearchParam = (url: string, param: Record<string, any>) => {
    const urlConstructor = new Url(url, true)
    Object.keys(param).forEach((key) => {
        urlConstructor.query[key] = param[key]
    })
    return urlConstructor.toString()
}

const providerToUrlProcessor: Record<ProviderOption, ProcessUrlFn> = {
    vimeo: (url, disableControl, startAt) => {
        if (startAt > 0) { // startAt in second unit
            // Format: https://player.vimeo.com/video/81400335#t=1m2s
            const start = `${Math.floor(startAt / 60)}m${startAt % 60}s`
            url = `${url}#t=${start}`
        }
        return setSearchParam(
            url, {
                autoplay: 1,
                ...getSearchParams(url),
                controls: disableControl ? 0 : 1
            }
        )
    },
    youtube: (url, disableControl, startAt) => {
        // Add enablejsapi=1 for Matomo Media Analytics tracker
        const commonSetting = {
            enablejsapi: 1,
            autoplay: 1,
            ...getSearchParams(url),
            controls: disableControl ? 0 : 1
        }
        // Don't override the 'start' parameter if it's set via the url instead of via startAt
        const params = startAt
            ? { ...commonSetting, start: startAt}
            : commonSetting
        return setSearchParam(url, params)
    },
    appendee: (url) => url,
}

const PROVIDER_CONFIG: ProviderConfig[] = [
    // Remove protocol because user somtimes doesn't input the protocol
    // For eg: just enter www.youtube.com/ instead of https://www.youtube.com/
    {
        provider: 'vimeo',
        url: 'player.vimeo.com/',
    },
    {
        provider: 'youtube',
        url: 'www.youtube.com/',
    },
    {
        provider: 'youtube',
        url: 'youtu.be/',
    },
    {
        provider: 'appendee',
        url: 'cdn.elevent.ly/',
    },
    {
        provider: 'appendee',
        url: 'cdn.appendee.com/',
    },
]

export const getProviderConfig = (streamUrl: string): ProviderConfig | undefined => (
    streamUrl
    && PROVIDER_CONFIG.find(
        ({ url }) => streamUrl.includes(url)
    )
)

export const getIsValidStreamUrl = (streamUrl: string) => !!getProviderConfig(streamUrl)

export const processProtocol = (streamUrl: string) => (
    // eslint-disable-next-line no-nested-ternary
    !streamUrl.startsWith('http')
        ? (streamUrl.startsWith('www.') ? `https://${streamUrl}` : `https://www.${streamUrl}`)
        : streamUrl
)

export const processUrl = (streamUrl: string, disableControl?: boolean, startAt?: number) => {
    // eslint-disable-next-line no-nested-ternary
    const _streamUrl = processProtocol(streamUrl)
    const config = getProviderConfig(_streamUrl)
    if (!config) { return _streamUrl }
    return providerToUrlProcessor[config.provider](_streamUrl, disableControl, startAt || 0)
}

export const urlOptions = PROVIDER_CONFIG.map(({ url }) => url)
