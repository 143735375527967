import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/cjs/ListGroup'
import { useDispatch, useSelector } from 'react-redux'

import { InputGroup } from 'react-bootstrap'
import Icon from '../../../components/Icon'
import LoadMore from '../../../components/LoadMore'

import { _ } from '../../../utils/localization'
import colors from '../../../constants/colors'
import useSlug from '../../../hooks/useSlug'
import styles from '../../../constants/styles'
import { Poster } from '../types'
import Bookmark from '../../goodiebag/Bookmark'
import { GoodiebagType } from '../../goodiebag/types'
import {
    fetchPosters,
    posterIdSelector,
    postersSelector,
    selectPoster,
    limitPostersSelector,
    setLimitPosters,
    canLoadMorePostersSelector,
} from '../posterSlice'
import useDebounce from '../../../hooks/useDebounce'

const S = {
    SideBar: styled.div`
        background: white;
        display: flex;
        flex-direction: column;
        width: ${styles.sidebarWidth}px;
        height: calc(100% - ${styles.topNavHeight}px - 10px); // 10px of timetable's border
        position: fixed;
        top: ${styles.topNavHeight}px;
        right: 0;
        @media (max-width: 1366px) {
            width: ${styles.smallerSidebarWidth}px;
        }
    `,
    SideBarTop: styled.div`
        background: ${colors.gray8};
        width: 100%;
        padding: 20px;
    `,
    Title: styled.p`
        font-size: 20px;
        font-weight: 500;
        color: white;
    `,
    SearchGroup: styled(Form.Group)`
        position: relative;
        margin-bottom: 20px;
    `,
    SearchInput: styled(Form.Control)`
        padding-right: 35px;
        &::placeholder {
            font-style: italic;
        }
    `,
    SearchIcon: styled.div`
        position: absolute;
        top: 4px;
        right: 5px;
    `,
    ListGroup: styled(ListGroup)`
        flex: 1 1 auto;
        overflow-y: auto;
    `,
    PosterItem: styled.div<{active: boolean}>`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px;
        background-color: ${({ active }) => (active ? `${colors.gray3}` : 'white')};
        &:hover {
            background-color: ${colors.gray3};
        }
    `,
    InfoWrapper: styled.div`
        flex: 1 1 100px;
        margin-right: 10px;
        overflow: hidden;
    `,
    PosterTitle: styled.div`
        max-height: 32px;
        margin-bottom: 4px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: ${colors.gray9};
    `,
    AuthorName: styled.div`
        font-size: 12px;
        line-height: 16px;
        color: ${colors.gray6};
    `,
}

const ListItem = ({ poster, isActive }: { poster: Poster, isActive: boolean }) => {
    const dispatch = useDispatch()
    const bookmarkItem = { id: poster.id, type: GoodiebagType.Poster }

    return (
        <S.PosterItem active={isActive}>
            <S.InfoWrapper onClick={() => dispatch(selectPoster(poster.id))}>
                <S.PosterTitle className="text-cut-line-2">{poster.title}</S.PosterTitle>
                <S.AuthorName className="text-cut notranslate">{poster.author_names}</S.AuthorName>
            </S.InfoWrapper>
            <div className="d-flex">
                <a
                    href={poster.pdf}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Icon icon="download" size={22} onClick={() => null} />
                </a>
                <Bookmark item={bookmarkItem} size={22} style={{ marginLeft: 8 }} />
            </div>
        </S.PosterItem>
    )
}

const SideBar = () => {
    const initSearch = new URLSearchParams(window.location.search).get('search')
    const eventSlug = useSlug('eventSlug')
    const posterSlug = useSlug('posterSlug')
    const dispatch = useDispatch()
    const selectedPosterId = useSelector(posterIdSelector)
    const [search, setSearch] = useState(initSearch || '')
    const limit = useSelector(limitPostersSelector)
    const loadMore = () => dispatch(setLimitPosters(limit + 10))
    const canLoadMore = useSelector(canLoadMorePostersSelector)
    const posters = useSelector(postersSelector)
    const isLastPosterActive = selectedPosterId && posters[posters.length - 1]?.id === selectedPosterId

    const debounceSearch = useDebounce((value) => {
        setSearch(value)
        dispatch(selectPoster(null))
    }, 400)
    const handleSearch = (event) => {
        const { value } = event.target
        debounceSearch(value)
    }

    useEffect(() => {
        dispatch(fetchPosters({
            eventSlug, posterSlug, search, limit
        }))
    }, [eventSlug, posterSlug, search, limit, dispatch])

    useEffect(() => {
        // Load more posters when the last poster at frontend is selected
        if (isLastPosterActive) {
            canLoadMore && loadMore()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLastPosterActive, canLoadMore]) // Don't want to check the change of loadMore function

    return (
        <S.SideBar>
            <S.SideBarTop>
                <S.Title>
                    {_('posters.sidebar.title')}
                </S.Title>

                <InputGroup>
                    <Form.Control
                        placeholder={_('posters.sidebar.search_placeholder')}
                        onChange={handleSearch}
                    />
                    <S.SearchIcon>
                        <Icon icon="search" size={22} />
                    </S.SearchIcon>
                </InputGroup>
            </S.SideBarTop>

            <S.ListGroup>
                {posters.map((poster) => (
                    <ListItem
                        poster={poster}
                        isActive={poster.id === selectedPosterId}
                        key={poster.id}
                    />
                ))}
                {canLoadMore && (
                    <LoadMore onClick={loadMore}>
                        {_('posters.sidebar.load_more')}
                    </LoadMore>
                )}
            </S.ListGroup>
        </S.SideBar>
    )
}

export default SideBar
