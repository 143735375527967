import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { MeetingItem } from '../types'
import { MeetingRecording } from '../../../types/__generated_swagger'
import { _ } from '../../../utils/localization'

import { deleteRecording } from '../meetingRoomSlice'
import useSlug from '../../../hooks/useSlug'

interface DeleteConfirmationProps {
    meeting: MeetingItem
    recording: MeetingRecording
    title: string
    close: () => void
}

const S = {
    Wrapper: styled.div``,
    Header: styled(Modal.Header)`
      display: flex;
      padding: 0;
    `,
    Body: styled.div`
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 0.5rem;
    `,
    Buttons: styled.div`
      display: flex;
      gap: 5px;
    `
}

const DeleteConfirmationModal = ({
    meeting, recording, title, close
}: DeleteConfirmationProps) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const meetingSpaceSlug = useSlug('meetingSlug')

    const del = () => {
        dispatch(deleteRecording({
            eventSlug, meetingSpaceSlug, meetingSlug: meeting.slug, recordingId: recording.id
        }))
        close()
    }

    return (
        <S.Wrapper>
            <S.Header>
                <h3>{_('meetingspace.meeting.delete_recording.header')}</h3>
            </S.Header>
            <S.Body>
                {' '}
                {_('meetingspace.meeting.delete_recording.body')}
                {' '}
                {title}
                ?
                <S.Buttons>
                    <Button variant="light" onClick={close}>{_('meetingspace.meeting.delete_recording.button_cancel')}</Button>
                    <Button onClick={del}>{_('meetingspace.meeting.delete_recording.button_confirm')}</Button>
                </S.Buttons>
            </S.Body>

        </S.Wrapper>
    )
}

export default DeleteConfirmationModal
