import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { MeetingItem } from '../types'
import { MeetingRecording } from '../../../types/__generated_swagger'
import { _ } from '../../../utils/localization'
import env from '../../../constants/env'
import Icon from '../../../components/Icon'
import colors from '../../../constants/colors'
import { showRecordingApprovalModal } from './utils'

interface PreviewModalProps {
    meeting: MeetingItem
    recording: MeetingRecording
    close: () => void
}

const S = {
    Wrapper: styled.div``,
    Header: styled(Modal.Header)`
      display: flex;
      justify-content: space-between;
      color: white;
      background-color: ${colors.darkTitle};
      align-items: baseline;
      border-bottom: 0;
    `,
    HeaderRight: styled.div`
      display:flex;
      gap: 10px;
      align-items: baseline;
    `,
    BackButton: styled(Button)`
      border: none !important;
      color: white;
      background: ${colors.darkTitle}; !important;
      :hover {
        background: ${colors.darkTitle} !important;
      }
      
    `,
    ApproveButton: styled(Button)`
      border-radius: 25px;
      color: white;
    `,
    Title: styled.h3``,
    IframeWrapper: styled.div`
      
    `,
    Video: styled.video`
      width: 100%;
    `
}

const PreviewModal = ({recording, meeting, close}: PreviewModalProps) => {
    const dispatch = useDispatch()
    const openAcceptRecordingModal = () => {
        dispatch(showRecordingApprovalModal(meeting, recording))
    }
    const sambaString = `${env.sambaUrl}/storage/recordings/mp4/${recording.download_slug}.mp4`

    return (
        <S.Wrapper>
            <S.Header>
                <S.BackButton onClick={close}>
                    <Icon icon="left" color="white" />
                    {_('meetingspace.recordings_modal.back_to_recordings')}
                </S.BackButton>
                <S.HeaderRight>
                    <S.ApproveButton variant="outline-light" size="sm" onClick={openAcceptRecordingModal}>
                        <Icon icon="checkCircle" color="#fff" />
                        {' '}
                        {_('meetingspace.recordings_modal.approve')}
                    </S.ApproveButton>
                    <Icon icon="close" color="#fff" onClick={close} />
                </S.HeaderRight>
            </S.Header>
            <S.IframeWrapper>
                <S.Video src={sambaString} controls />

            </S.IframeWrapper>
        </S.Wrapper>
    )
}

export default PreviewModal
