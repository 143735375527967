import useRoute from './useRoute'
import { ParamSlug, Slug } from '../types'

const useSlug = (slug: Slug) => {
    const route = useRoute()
    const params = route && route.params as ParamSlug
    return params
        ? params[slug]
        : undefined
}

export default useSlug
