import intl from 'react-intl-universal'

export const browserLocale = intl.determineLocale({})

/**
 * Get translation from localization file for an ID.
 * Optionally add variables to replace in the string,
 * e.g. for "Hello, {username}" pass variables {username: "joey"}
 */
export const _ = (id: string, variables?: Record<string, unknown>, title = true): string => {
    const value = intl.get(id, variables)
    const result = typeof value === 'object' ? id : (value || id || '')
    return title
        ? result.charAt(0).toUpperCase() + result.slice(1)
        : result
}
