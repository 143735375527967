import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { _ } from '../../utils/localization'
import openChatModal from '../chat/openChatModal'
import colors from '../../constants/colors'
import Icon from '../../components/Icon'
import getMugshotSrc from '../../utils/getMugshotSrc'
import Bookmark from '../goodiebag/Bookmark'
import { GoodiebagType } from '../goodiebag/types'
import { VirtualAttendee } from '../../types/swagger'
import { formatJobInfo, formatName } from '../../utils/profile'
import useSlug from '../../hooks/useSlug'
import { currentUserSelector } from '../auth/authSlice'
import { TagWithColor } from '../networking/components/AttendeeInfoWithTags'
import LocationIndicator from '../../components/LocationIndicator'
import { eventPreviewSelector } from '../auth/eventPreviewSlice'
import { addModal } from '../modals/modalSlice'
import AppointmentScheduleModal from '../appointment_schedules/AppointmentScheduleModal'
import Background from '../../assets/profile_background.jpg'
import Divider from '../../components/Divider'
import ReadMoreOrLess from '../../components/ReadMoreOrLess'
import { getSocialIconColor } from './EditProfileForm'
import CustomButton from '../../components/CustomButton'

export const S = {
    Header: styled.div`
        position: relative;
        height: 110px;
        width: 100%;
        margin-bottom: 38px;
        background: url(${Background});
        background-size: cover;
        background-position: center;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    `,
    Avatar: styled.img`
        position: absolute;
        bottom: 0;
        left: 35px;
        transform: translateY(50%);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 5px solid white;
        background-color: white;
        @media (max-width: 932px) {
            left: 20px;
        }
    `,
    CloseIcon: styled.div`
        position: absolute;
        top: 24px;
        right: 24px;
    `,
    BusinessCardBody: styled.p`
        padding: 8px 0;
        margin-top: 8px;
        white-space: pre-line;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: ${colors.grayDescription};
    `,
    BusinessCardFooter: styled.div`
        display: flex;
        gap: 20px;
    `,
    Connect: styled.div`
        padding: 16px 0;
        display: flex;
        justify-content: space-between;
        width: 60px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: ${colors.primary};
        color: white;
        cursor: pointer;
        transform: translateY(-0.5px);
    `,
    // eslint-disable-next-line react/display-name
    Icon: (props: any) => <Icon size={36} {...props} />,
    Tags: styled.div`
        height: 48px;
        padding: 16px 0;
        line-height: 16px;
        font-size: 14px;
        color: ${colors.mineShaft};
        border-bottom: 1px solid ${colors.iconOpacity(0.2)};
        text-transform: uppercase;
    `,
    Info: styled.div`
        position: absolute;
        top: 72px;
        left: 150px;
        width: calc(100% - 230px);
        overflow: hidden;
        @media (max-width: 932px) {
            left: 130px;
            width: calc(100% - 145px);
        }
    `,
    NameWrapper: styled.div`
        display: flex;
        align-items: center;
        overflow: hidden;
    `,
    Name: styled.h3`
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 24px;
        color: ${colors.darkTitle};
        @media (max-width: 932px) {
            font-size: 16px;
        }
    `,
    Description: styled.p`
        margin-top: 10px;
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 14px;
        color: ${colors.grayDescription};
    `,
    Bookmark: styled(Bookmark)`
        flex-shrink: 0;
        margin-bottom: 6px;
        margin-left: 8px;
    `,
    Document: styled.a`
        display: flex;
        align-items: center;
        color: ${colors.midBlue};
        font-size: 14px;
    `,
    Social: styled.a<{disabled: boolean}>`
        display: grid;
        place-items: center;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background: white;
        box-shadow: 0px 0px 2px rgba(41, 41, 41, 0.1), inset 0px 0px 2px rgba(41, 41, 41, 0.1);
        cursor: pointer;
        pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
    `,
    Actions: styled.div`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 32px;
    `
}

const BusinessCard = ({ close, user }: { close: ()=> void, user: VirtualAttendee }) => {
    const eventSlug = useSlug('eventSlug')
    const eventPreview = useSelector(eventPreviewSelector)
    const dispatch = useDispatch()
    const handleOpenChat = () => dispatch(openChatModal(eventSlug, user.id))
    const currentUser = useSelector(currentUserSelector())
    const isOwnCard = user.id === currentUser.id
    const isPublic = user.is_public || isOwnCard
    const [count, setCount] = useState(0)
    const aboutRef = useRef(null)
    const [aboutClassName, setAboutClassName] = useState('notranslate')

    useEffect(() => {
        // To fix the weird issue that the content in this modal can't be translated by google.
        setTimeout(() => {
            setCount(1)
        }, 500)
    }, [])

    const showAppointmentSchedule = () => dispatch(addModal({
        Component: ({close: _close}: {close: () => void}) => (
            <AppointmentScheduleModal close={_close} receiver={user} />
        )
    }))

    const toggleTranslation = () => {
        setAboutClassName(aboutClassName ? '' : 'notranslate')
        setCount((icount) => icount + 1)
    }

    return (
        <div key={count}>
            <S.Header>
                <S.Avatar src={getMugshotSrc(user)} width={80} />
                <S.Info>
                    <S.NameWrapper>
                        <S.Name className="text-cut notranslate">
                            {formatName(user)}
                        </S.Name>
                        <S.Bookmark item={{ id: user.id, type: GoodiebagType.Attendee }} />
                    </S.NameWrapper>
                    <S.Description className="text-cut">{formatJobInfo(user)}</S.Description>
                    <LocationIndicator location={user.location_type} />
                </S.Info>
                <S.CloseIcon>
                    <Icon icon="close" size={28} onClick={close} />
                </S.CloseIcon>
            </S.Header>

            <div style={{padding: 20}}>
                {!!user?.tags?.length && (
                    <>
                        <Divider marginBottom={10} marginTop={60} />

                        <div className="d-flex" style={{flexWrap: 'wrap'}}>
                            {user?.tags?.map((tag) => (
                                <TagWithColor tag={tag} expanded />
                            ))}
                        </div>
                    </>
                )}

                <Divider marginBottom={16} marginTop={16} />

                <S.BusinessCardBody className={aboutClassName} ref={aboutRef}>
                    {isPublic && user.about
                        ? <ReadMoreOrLess content={user.about} isHtmlContent />
                        : _('profile.card.no_about_for_user')}
                </S.BusinessCardBody>
                {eventPreview?.enable_page_translation && (
                    <CustomButton onClick={toggleTranslation} variant="secondary">
                        {aboutClassName === 'notranslate' ? 'Translate' : 'Show original'}
                    </CustomButton>
                )}

                <Divider marginBottom={16} marginTop={24} />

                {user.document && (
                    <>
                        <S.Document href={user.document} target="__blank" download>
                            <Icon icon="download" color={colors.midBlue} />
                            {user.document_name || user.document.split('/').slice(-1).pop()}
                        </S.Document>
                        <Divider marginBottom={24} marginTop={16} />
                    </>
                )}

                <S.BusinessCardFooter>
                    {isPublic && (
                        <>
                            <S.Social href={user.linkedin} target="__blank" disabled={!user.linkedin}>
                                <S.Icon
                                    icon="linkedIn"
                                    color={user.linkedin ? getSocialIconColor('linkedin') : colors.icon}
                                    size={24}
                                />
                            </S.Social>
                            <S.Social href={user.facebook} target="__blank" disabled={!user.facebook}>
                                <S.Icon
                                    icon="facebook"
                                    color={user.facebook ? getSocialIconColor('facebook') : colors.icon}
                                    size={24}
                                />
                            </S.Social>

                            <S.Social href={user.twitter} target="__blank" disabled={!user.twitter}>
                                <S.Icon
                                    icon="twitter"
                                    color={user.twitter ? getSocialIconColor('twitter') : colors.icon}
                                    size={24}
                                />
                            </S.Social>
                            <S.Social href={user.instagram} target="__blank" disabled={!user.instagram}>
                                <S.Icon
                                    icon="instagram"
                                    color={user.instagram ? getSocialIconColor('instagram') : colors.icon}
                                    size={24}
                                />
                            </S.Social>
                        </>
                    )}
                </S.BusinessCardFooter>

                <S.Actions>
                    <CustomButton
                        variant="secondary"
                        onClick={isOwnCard ? null : handleOpenChat}
                    >
                        <Icon icon="message" size={18} className="mr-2" />
                        {_('networking.attendee_list.open_chat')}
                    </CustomButton>
                    {eventPreview?.enable_meeting_scheduling && (
                        <CustomButton variant="secondary" onClick={showAppointmentSchedule}>
                            <Icon icon="appointmentSchedule" size={18} className="mr-2" />
                            {_('networking.attendee_list.schedule_appointment')}
                        </CustomButton>
                    )}
                </S.Actions>
            </div>
        </div>
    )
}

export default BusinessCard
