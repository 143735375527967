/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Notification from 'react-web-notification'
// @ts-ignore
import MessageSound from '../../assets/sounds/incoming-message.mp3'
// @ts-ignore
import VideocallSound from '../../assets/sounds/incoming-videocall.mpeg'
import { doNotDisturbSelector } from '../profile/editProfileSlice'
import { clearNotification, notificationSelector } from './soundNotificationSlice'

const SoundNotification = () => {
    const [ignore, setIgnore] = useState(true)
    const dispatch = useDispatch()
    const notification = useSelector(notificationSelector)
    const doNotDisturb = useSelector(doNotDisturbSelector)

    // @ts-ignore
    const options = {
        icon: 'https://appendee.com/wp-content/uploads/2019/01/Appendee-icon.png',
        dir: 'ltr',
        body: notification.body,
        requireInteraction: true,
    }

    const handlePermissionGranted = () => {
        console.log('Permission Granted')
        setIgnore(false)
    }
    const handlePermissionDenied = () => {
        console.log('Permission Denied')
        setIgnore(true)
    }
    const handleNotSupported = () => {
        console.log('Web Notification not Supported')
        setIgnore(true)
    }

    const handleNotificationOnClick = () => {
        window.focus()
    }

    const handleNotificationOnError = (e, tag) => {
        console.log(e, `Notification error tag: ${tag}`)
    }

    const handleNotificationOnClose = () => {
        dispatch(clearNotification())
    }

    const playSound = () => {
        if (notification.type === 'videocall') {
            // @ts-ignore
            document.getElementById('videocall-sound')?.play?.()
        } else {
            // @ts-ignore
            document.getElementById('message-sound')?.play?.()
        }
    }

    const handleNotificationOnShow = () => {
        playSound()
    }

    return (
        <>
            <Notification
                ignore={(ignore && notification.title !== '') || doNotDisturb}
                notSupported={handleNotSupported}
                onPermissionGranted={handlePermissionGranted}
                onPermissionDenied={handlePermissionDenied}
                onShow={handleNotificationOnShow}
                onClick={handleNotificationOnClick}
                onClose={handleNotificationOnClose}
                onError={handleNotificationOnError}
                timeout={notification.type === 'videocall' ? 15000 : 5000}
                title={notification.title}
                options={options}
                key={`notification-${notification.id}`}
            />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio id="message-sound" preload="auto" key={`message-sound-${notification.id}`}>
                <source
                    src={MessageSound}
                    type="audio/mpeg"
                />
                <embed
                    hidden
                    // @ts-ignore
                    autostart="false"
                    loop={false}
                    src={MessageSound}
                />
            </audio>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio id="videocall-sound" preload="auto" key={`videocall-sound-${notification.id}`}>
                <source
                    src={VideocallSound}
                    type="audio/mpeg"
                />
                <embed
                    hidden
                    // @ts-ignore
                    autostart="false"
                    loop={false}
                    src={VideocallSound}
                />
            </audio>
        </>
    )
}

export default SoundNotification
