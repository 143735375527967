import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Dropdown from 'react-bootstrap/cjs/Dropdown'
import openChatModal from '../../chat/openChatModal'
import Icon from '../../../components/Icon'
import openBusinessCard from '../../profile/openBusinessCard'
import { GoodiebagType } from '../../goodiebag/types'
import { _ } from '../../../utils/localization'
import useToggleBookmark from '../../goodiebag/useToggleBookmark'
import { User } from '../../auth/types'
import AttendeeCallStatus from '../../entervideocall/AttendeeCallStatus'
import { VirtualAttendee } from '../../../types/swagger'
import useSlug from '../../../hooks/useSlug'
import { currentUserSelector } from '../../auth/authSlice'
import AttendeeInfoWithTags from './AttendeeInfoWithTags'
import constants from '../../../constants/constants'
import LocationIndicator from '../../../components/LocationIndicator'
import DropdownButton from '../../../components/DropdownButton'
import { addModal } from '../../modals/modalSlice'
import AppointmentScheduleModal from '../../appointment_schedules/AppointmentScheduleModal'
import { eventPreviewSelector } from '../../auth/eventPreviewSlice'

const S = {
    AttendeeItem: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 50px;
        padding: 0 8px;
        margin-bottom: 8px;
        &:hover {
            background-color: rgba(0, 123, 255, 0.03);
        }
    `,
    DropDownToggle: styled(Dropdown.Toggle)<{isOnline: boolean}>`
        margin-left: 4px;
        padding: 0 2px 2px 2px;
        background-color: transparent;
        border: none;
        border-radius: 0;
        outline: none;
        box-shadow: none!important;
        opacity: ${({ isOnline }) => (isOnline ? 1 : 0.5)};
        &::after {
            display: none;
        }
        &:hover, &:focus, &:active {
            background-color: transparent;
            box-shadow: none!important;
        }
        &[aria-expanded="true"] {
            background-color: green!important;
            span > svg > path {
                fill: white!important;
            }
        }
    `,
    Actions: styled.div`
        position: relative;
        display: flex;
        flex-shrink: 0;
        margin-left: 5px;
    `,
    AttendeeInfo: styled.div`
        width: 100%;
        display: flex;
        flex: 1;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    `,
    LocationIndicator: styled(LocationIndicator)`
        position: absolute;
        top: 5px;
        right: 62px;
    `
}

const MoreOptionDropDown = ({ attendee }: {attendee: VirtualAttendee}) => {
    const eventSlug = useSlug('eventSlug')
    const eventPreview = useSelector(eventPreviewSelector)
    const dispatch = useDispatch()
    const { toggleBookmark, bookmarkIcon, isBookmarked } = useToggleBookmark({
        id: attendee.id,
        type: GoodiebagType.Attendee,
    })
    const showBusinessCard = () => dispatch(openBusinessCard(attendee as User))
    const showAppointmentSchedule = () => dispatch(addModal({
        Component: ({close}: {close: () => void}) => (
            <AppointmentScheduleModal close={close} receiver={attendee} />
        )
    }))

    return (
        <DropdownButton renderToggleButton={() => <Icon icon="dotsVertical" size={20} className="ml-1" />}>
            <div className="flex-align-center" onClick={() => dispatch(openChatModal(eventSlug, attendee.id))}>
                <Icon icon="forum" size={18} className="mr-2 mb-2px" />
                {_('networking.attendee_list.open_chat')}
            </div>
            {eventPreview?.enable_meeting_scheduling && (
                <div className="flex-align-center" onClick={showAppointmentSchedule}>
                    <Icon icon="appointmentSchedule" size={18} className="mr-2 mb-2px" />
                    {_('networking.attendee_list.schedule_appointment')}
                </div>
            )}
            <div className="flex-align-center" onClick={showBusinessCard}>
                <Icon icon="cardAccountDetailsOutline" size={18} className="mr-2 mb-2px" />
                {_('networking.attendee_list.open_business_card')}
            </div>
            <div className="flex-align-center" onClick={toggleBookmark}>
                <Icon icon={bookmarkIcon} isActive={isBookmarked} size={18} className="mr-2 mb-2px" />
                {_('networking.attendee_list.bookmark')}
            </div>
        </DropdownButton>
    )
}

const AttendeeListItem = (
    { attendee, hideActions } : { attendee: VirtualAttendee, hideActions?: boolean }
) => {
    const currentUser = useSelector(currentUserSelector())
    const isLiteVersion = constants.isLite

    return (
        <S.AttendeeItem>
            <S.AttendeeInfo>
                <AttendeeInfoWithTags attendee={attendee} />

                {!hideActions && (
                    <S.Actions>
                        {attendee.location_type && (
                            <S.LocationIndicator location={attendee.location_type} />
                        )}
                        {!isLiteVersion && (
                            <AttendeeCallStatus
                                attendee={attendee}
                                config={{ requestInPopover: true, adminId: currentUser.id }}
                            />
                        )}
                        <MoreOptionDropDown attendee={attendee} />
                    </S.Actions>
                )}
            </S.AttendeeInfo>
        </S.AttendeeItem>

    )
}

export default AttendeeListItem
