import React, { useState } from 'react'
import PageContainer from '../../components/PageContainer'
import Tabs from '../../components/Tabs'
import { _ } from '../../utils/localization'
import AppointmentList from './tabs/AppointmentList'
import AvailabilityManagement from './tabs/AvailabilityManagement'

const ManageAppointment = () => {
    const [selectedTab, setSelectedTab] = useState('list')

    return (
        <PageContainer title={_('my_appointments.manage_page')}>
            <Tabs
                onSelect={setSelectedTab}
                activeKey={selectedTab}
                tabs={[
                    {
                        id: 'list',
                        name: _('my_appointments.tabs.meeting_appointments'),
                        Component: () => <AppointmentList />,
                    },
                    {
                        id: 'manage-availability',
                        name: _('my_appointments.tabs.manage_availability'),
                        Component: () => <AvailabilityManagement />,
                    },
                ]}
            />
        </PageContainer>
    )
}

export default ManageAppointment
