import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import colors from '../../constants/colors'
import Chat from './components/Chat'
import SideBar from './components/SideBar'
import {
    selectCurrentMessages,
    selectChatId,
    setIsOpened,
    setUnreadToZero,
    selectingBroadcastSelector,
} from './chatSlice'
import readChat from './readChat'
import useDebounce from '../../hooks/useDebounce'
import useSlug from '../../hooks/useSlug'
import BroadcastMessage from './components/broadcast_message/BroadcastMessageBoard'
import useResponsive from '../../hooks/useResponsive'

interface StyledNotificationProps {
    isLast: boolean
}

const getBorderColor = ({ isLast }: StyledNotificationProps) => (isLast ? 'transparant' : 'gray')

const S = {
    ChatWindow: styled.div`
        max-width: 95vw;
        max-height: 80vh;
        min-height: 80vh;
        display: flex;
    `,
    Notification: styled.div<StyledNotificationProps>`
        padding: 8px 16px;
        border-bottom: ${getBorderColor} solid 1px;
        display: flex;
        align-items: center;
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `,
    Mugshot: styled.img`
        width: 30px;
        height: 30px;
        margin-right: 8px;
        border-radius: 30px;
        object-fit: cover;
    `,
    OverviewCol: styled.div`
        max-height: 100%;
        overflow-y: auto;
        overflow-x: auto;
        // This works on safari and chrome only:
        overflow-x: overlay;
     `,
    ChatCol: styled.div`
        padding: 0;
        border-left: ${colors.dividerColor} solid 1px;
        max-height: 100%;
        overflow: hidden;
     `,
    Row: styled.div`
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 3fr;
    `
}

const ChatOverview = () => {
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const messages = useSelector(selectCurrentMessages)
    const chatCol = React.useRef<HTMLDivElement>()
    const userId = useSelector(selectChatId)
    const selectingBroadcast = useSelector(selectingBroadcastSelector)
    const {isMobile} = useResponsive()

    const currentChatUnreads = messages.results.filter(
        (message) => !message?.sent_by_current_user && !message?.read
    ).length

    const debouncedRead = useDebounce((_dispatch, _userId) => {
        _dispatch(readChat(eventSlug, _userId))
    }, 2000)

    // Send current chat to read in server
    useEffect(() => {
        if (userId && currentChatUnreads > 0) {
            dispatch(setUnreadToZero(userId))
            debouncedRead(dispatch, userId)
        }
        // Listen to currentChatUnreads so
        // we send it on every newly received message
    }, [currentChatUnreads, debouncedRead, dispatch, userId])

    // Sync mount/unmount with state.isOpened
    useEffect(() => {
        dispatch(setIsOpened(true))
        return () => {
            dispatch(setIsOpened(false))
        }
    }, [dispatch])

    // eslint-disable-next-line no-nested-ternary
    return isMobile ? (
        (userId || selectingBroadcast) ? (
            <S.ChatCol ref={chatCol}>
                {selectingBroadcast ? <BroadcastMessage /> : <Chat />}
            </S.ChatCol>
        ) : (
            <S.OverviewCol>
                <SideBar />
            </S.OverviewCol>
        )
    ) : (
        <S.ChatWindow>
            <S.Row>
                <S.OverviewCol>
                    <SideBar />
                </S.OverviewCol>
                <S.ChatCol ref={chatCol}>
                    {selectingBroadcast ? <BroadcastMessage /> : <Chat />}
                </S.ChatCol>
            </S.Row>
        </S.ChatWindow>
    )
}

export default ChatOverview
