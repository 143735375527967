import { differenceInSeconds } from 'date-fns'
import { DateTime, toDate } from '../utils/datetime'
import useInterval from './useInterval'

const useCountDown = (datetime: DateTime, interval?: number) => {
    const time = useInterval(interval)
    let totalSeconds = differenceInSeconds(toDate(datetime), toDate(time))
    if (totalSeconds < 1) { totalSeconds = 0 }
    const secondsLeft = totalSeconds % 60
    const minutesLeft = Math.floor(totalSeconds / 60) % 60
    const hoursLeft = Math.floor(totalSeconds / 60 / 60) % 24
    const daysLeft = Math.floor(totalSeconds / 60 / 60 / 24)
    return {
        secondsLeft,
        minutesLeft,
        hoursLeft,
        daysLeft,
        totalSeconds
    }
}

export default useCountDown
