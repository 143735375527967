import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import omit from 'lodash/omit'

import reduxWebsocket from '@giantmachines/redux-websocket'
import { createEpicMiddleware } from 'redux-observable'
import { createReduxEnhancer } from '@sentry/react'
import history from './history'

// Middleware
import logEvents from './utils/reduxmiddleware/logEvents'
import parseWebsocketPayload from './utils/reduxmiddleware/parseWebsocketPayload'
import reduxEventBus from './utils/reduxmiddleware/reduxEventBus'

// Reducers
import modalsReducer from './features/modals/modalSlice'
import chatReducer from './features/chat/chatSlice'
import authReducer from './features/auth/authSlice'
import timetableReducers from './features/timetable/redux/combinedReducer'
import presentationRoomReducer from './features/presentation/presentationRoomReducer'
import presentationAttendeesSlice from './features/presentation/presentationAttendeesSlice'
import websocketSlice from './features/websocket/websocketSlice'
import videocallsSlice from './features/networking/networkingCallsSlice'
import videocallSlice from './features/videocall/videocallSlice'
import networkingReducer from './features/networking/networkingSlice'
import attendeesSlice from './features/attendees/attendeesSlice'
import notificationsSlice from './features/notifications/notificationsSlice'
import eventReducer from './features/events/reducer'
import eventPreviewSlice from './features/auth/eventPreviewSlice'
import exhibitionReducer from './features/exhibition/reducer'
import mapReducer from './features/imagemap/redux/reducer'
import profileReducer from './features/profile/editProfileSlice'
import goodiebagReducer from './features/goodiebag/reducer'
import cinemaReducer from './features/cinema/cinemaSlice'
import meetingSpaceReducer from './features/meetings/meetingListSlice'
import publicChatReducer from './features/publicchat/reducer'
import helpContextReducer from './features/helpcontent/HelpContentSlice'
import posterReducer from './features/posters/posterSlice'
import showMeSlice from './features/events/showMeSlice'
import groupSessionsReducer from './features/groupsessions/groupSessionsSlice'
import spaceSliceReducer from './features/spaces/spaceSlice'
import soundNotificationSliceReducer from './features/soundnotifications/soundNotificationSlice'
import calendarSliceReducer from './features/calendar/calendarSlice'
import entireEventSearchReducer from './features/event_search/searchEntireEventSlice'
import performerReducer from './features/performer/performerSlice'
import appointmentScheduleReducer from './features/appointment_schedules/appointmentSchedulesSlice'
import pageLoadingReducer from './features/events/pageLoadingSlice'

// Pipes
import videocallPipes from './features/videocall/pipes'
import videocallInvitePipes from './features/entervideocall/pipes'

const rootReducer = combineReducers({
    router: connectRouter(history),
    modals: modalsReducer,
    auth: authReducer,
    publicChat: publicChatReducer,
    timetable: timetableReducers,
    chat: chatReducer,
    websocket: websocketSlice,
    presentationRoom: presentationRoomReducer,
    presentationAttendees: presentationAttendeesSlice,
    videocall: videocallSlice,
    networking: networkingReducer,
    videocalls: videocallsSlice,
    attendees: attendeesSlice,
    showMe: showMeSlice,
    event: eventReducer,
    notification: notificationsSlice,
    eventPreview: eventPreviewSlice,
    exhibition: exhibitionReducer,
    map: mapReducer,
    editProfile: profileReducer,
    goodiebag: goodiebagReducer,
    cinema: cinemaReducer,
    meeting: meetingSpaceReducer,
    helpContent: helpContextReducer,
    poster: posterReducer,
    groupSessions: groupSessionsReducer,
    spaces: spaceSliceReducer,
    soundNotifications: soundNotificationSliceReducer,
    calendar: calendarSliceReducer,
    eventSearch: entireEventSearchReducer,
    performer: performerReducer,
    appointmentSchedule: appointmentScheduleReducer,
    pageLoading: pageLoadingReducer,
})

export type RootState = ReturnType<typeof rootReducer>;

const reduxObservableMiddleware = createEpicMiddleware()

/**
 * Websockets messages are received by the store using "reduxWebsocket"
 * Then they get converted with "parseWebsocketPayload"
 *
 * Example:
 * Incoming websocket message:
     {
        type: "user_joined_chat",
        payload: {
            user: 1
        }
    }

 * reduxWebsocket sends this to the reducer like:
    {
      type: 'REDUX_WEBSOCKET::MESSAGE',
      meta: {
          timestamp: Date,
      },
      payload: {
          message: string,  // json formatted string
          origin: string,
      },
    }

 * reduxObservableMiddleware will parse the payload and convert the action to:
    {
        user: 1
        type: "REDUX_WEBSOCKET::USER_JOINED_CHAT"
    }

 * We can express this in a typed action like:
   interface UserJoinedChat {
        user: number
   }
   const action = websocketAction<UserJoinedChat>("user_joined_chat");

 * Which then can added to reducer, or can be converted into different
 * action using "reduxObservableMiddleware"
 */
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(
        routerMiddleware(history),
        reduxWebsocket({ reconnectOnClose: false }), // reconnect is done by WebsocketProvider
        parseWebsocketPayload,
        reduxEventBus,
        reduxObservableMiddleware,
        logEvents,
    ),
    enhancers: [createReduxEnhancer({
        stateTransformer: (state: RootState) => {
            // Transform the state to remove sensitive information
            const transformedState = {
                ...state,
                auth: {
                    isLoadingAuth: state.auth?.isLoadingAuth,
                    isLoggedIn: state.auth?.isLoggedIn,
                    user_id: state.auth?.user?.id,
                },
                attendees: {
                    ...omit(state.attendees, 'results', 'resultsSearch'),
                }
            }

            return transformedState
        },
    })],
})

// Pipes
// **Must** add the epic to the observable after calling `applyMiddleware()`.
// Otherwise you'll get a warning: "epicMiddleware.run(rootEpic) called before
// the middleware has been setup by redux. Provide the epicMiddleware instance
// to createStore() first"
videocallPipes.map(reduxObservableMiddleware.run)
videocallInvitePipes.map(reduxObservableMiddleware.run)

export default store
