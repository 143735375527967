import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format, startOfDay } from 'date-fns'
import intersection from 'lodash/intersection'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import styled from 'styled-components'
import CollapsiblePanel from '../../../components/CollapsiblePanel'
import useSlug from '../../../hooks/useSlug'
import { _ } from '../../../utils/localization'
import ProgramItemRow from '../../calendar/components/ProgramItemRow'
import FetchItemDetail from '../../imagemap/components/FetchItemDetail'
import { addModal } from '../../modals/modalSlice'
import { ProgramItem } from '../../timetable/types'
import { SearchItemType } from '../types'
import { locationFilterSelector, tagsFilterSelector } from '../../calendar/calendarSlice'
import colors from '../../../constants/colors'

const S = {
    DateLabel: styled.div`
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: ${colors.icon};
        margin-top: 24px;
        margin-bottom: 4px;
    `
}

interface Props {
    data: {
        type: SearchItemType, items: ProgramItem[]
    }
}

const filterLocationType = (filter = []) => (item: ProgramItem) => {
    if (isEmpty(filter)) {
        return true
    }

    return filter.map((e) => e.value).includes(item.location_type)
}

const filterTags = (tagsFilter = {}) => (item) => {
    const itemTags = item.tags?.map((tag) => tag.id)
    return Object.values(tagsFilter).filter((e) => !isEmpty(e)).every(
        (tags: any) => intersection(map(tags, (e) => e?.value), itemTags).length
    )
}

const groupByDate = (programs: ProgramItem[]): ProgramItem => {
    const updatedDatePrograms = programs.map((e) => ({
        ...e,
        date: format(startOfDay(new Date(e.start_date)), 'dd LLL yyyy')
    }))
    return groupBy(updatedDatePrograms, 'date')
}

const Programs = ({ data }: Props) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const locationFilter = useSelector(locationFilterSelector)
    const tagsFilter = useSelector(tagsFilterSelector)

    const filteredData = data.items
        .filter(filterLocationType(locationFilter))
        .filter(filterTags(tagsFilter))

    const itemInfoClick = (id) => {
        // eslint-disable-next-line react/prop-types
        const Component = () => <FetchItemDetail itemId={id} eventSlug={eventSlug} />
        dispatch(addModal({ Component }))
    }

    return (
        <CollapsiblePanel
            title={`${_('event_search.programs')} (${filteredData.length})`}
            isDefaultCollapsed
        >
            <div style={{ marginTop: -16 }}>
                <>
                    {filteredData.length ? map(groupByDate(filteredData), (value: any, key) => (
                        <div key={key}>
                            <S.DateLabel>
                                {key}
                            </S.DateLabel>
                            {map(value, (item) => (
                                <ProgramItemRow item={item} onClick={() => itemInfoClick(item.id)} />
                            ))}
                        </div>
                    )) : (
                        <div>{_('general.no_data_available')}</div>
                    )}
                </>
            </div>
        </CollapsiblePanel>
    )
}

export default Programs
