import useSWR from 'swr'
import useSlug from '../../hooks/useSlug'
import api from '../../utils/api'

const fetcher = (url) => api.get(url).then((response) => response.results)

const useAttendeeTags = () => {
    const eventSlug = useSlug('eventSlug')
    const { data } = useSWR(`/events/${eventSlug}/attendee_tags/`, fetcher, { revalidateOnFocus: false })
    return data
}

export default useAttendeeTags
