/**
 * cords are in one array like:
 * index 1 = y1
 * index 2 = x1
 * index 3 = y2
 * index 4 = x2
 *
 * And we want to create:
 * index 1 = {y: y1, x: x1} ...
 */

type Coord = { x: number, y: number }

export const nestCoords = (coords: number[]): Coord[] => coords.reduce(
    (arr: Coord[], val, idx) => (!(idx % 2)
        ? [...arr, { x: val, y: coords[idx + 1] }]
        : arr),
    [],
)

export default nestCoords
