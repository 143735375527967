const prepareCompare = (value) => (typeof value === 'object'
    ? JSON.stringify(value)
    : value)

export const makeUnique = (array: any[]) => {
    const compareArray = array.map(prepareCompare)
    return array.filter((_, i) => i === compareArray.indexOf(compareArray[i]))
}

export const add = <T>(array: T[], v: T): T[] => {
    const included = array.map(prepareCompare).includes(prepareCompare(v))
    return included
        ? array
        : [...array, v]
}

export const remove = <T>(array: T[], v: T): T[] => {
    const compareValue = prepareCompare(v)
    const compareArray = array.map(prepareCompare)
    return array.filter((_, i) => compareArray[i] !== compareValue)
}
