import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import screenfull from 'screenfull'
import styled from 'styled-components'
import Logger from 'js-logger'
import { Divider, Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
import colors from '../../../constants/colors'
import { RootState } from '../../../store'
import { _ } from '../../../utils/localization'
import StyledIcon from '../../Icon'
import { changeShowTooltip } from '../../../features/events/fullScreenSlice'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import env from '../../../constants/env'

const S = {
    Wrapper: styled.div`
        height: 100%;
        display: flex;
        align-items: center;
    `,
    FullScreenToggle: styled.div<{isActive: boolean}>`
        margin-bottom: -3px;
        padding: 5px;
        padding-top: 1px;
        border-radius: 4px;
        cursor: pointer;
        background-color: ${({ isActive }) => (isActive ? colors.gray3 : 'transparent')};
        &:hover {
            background-color: ${colors.gray3}
        }
    `,
    Actions: styled.div`
        display: flex;
        justify-content: flex-end;
    `,
    TooltipWrapper: styled.div`
        padding: 8px 16px;
        background: white;
    `
}

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        border: '1px solid #dadde9',
        padding: '8px 16px',
    },
    '& .MuiTooltip-arrow': {
        color: 'white',
    }
}))

const FullScreenToggle = () => {
    const isShowTooltip = useSelector((state: RootState) => state.event.fullScreen.isShowTooltip)
    const dispatch = useDispatch()
    const [isFullScreen, setIsFullScreen] = useState(false)
    const windowSize = useWindowDimensions()

    const setFullScreen = () => {
        if (screenfull.isEnabled) {
            screenfull.request().catch((error) => Logger.warn('Unable to request full screen', error))
        }
        dispatch(changeShowTooltip(false))
    }

    useEffect(() => {
        setIsFullScreen(screenfull.isEnabled && screenfull.isFullscreen)
    }, [windowSize])

    if (!env.enableFullscreenToggle) {
        return null
    }

    const popover = (
        <div>
            <h6>{_('fullscreen.title')}</h6>
            <Divider sx={{mb: 1}} />
            <p style={{fontSize: '12px'}}>{_('fullscreen.description')}</p>
            <S.Actions>
                <Button variant="success" size="sm" onClick={setFullScreen}>
                    {_('fullscreen.button.accept')}
                </Button>
                <Button
                    variant="light"
                    size="sm"
                    style={{ marginLeft: 10, border: '1px solid #E8E9E9' }}
                    onClick={() => dispatch(changeShowTooltip(false))}
                >
                    {_('fullscreen.button.decline')}
                </Button>
            </S.Actions>
        </div>
    )
    return (
        <HtmlTooltip open={isShowTooltip} placement="bottom-end" title={popover} arrow>
            {!isFullScreen ? (
                <S.Wrapper onClick={setFullScreen}>
                    <S.FullScreenToggle isActive={isShowTooltip}>
                        <StyledIcon icon="arrowExpand" size={20} />
                    </S.FullScreenToggle>
                </S.Wrapper>
            ) : <div />}
        </HtmlTooltip>
    )
}

export default FullScreenToggle
