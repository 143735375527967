import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchEvent } from './eventSlice'
import { fetchNavigation } from './navigationSlice'
import { getChats } from '../chat/chatSlice'
import { fetchGoodiebags } from '../goodiebag/itemsSlice'
import { getEventFiles } from '../goodiebag/eventFilesSlice'

export default createAsyncThunk(
    'FETCH_EVENT',
    async (args: { eventSlug: string }, thunkAPI) => {
        thunkAPI.dispatch(fetchEvent(args))
        thunkAPI.dispatch(fetchNavigation(args))
        thunkAPI.dispatch(fetchGoodiebags(args))
        thunkAPI.dispatch(getEventFiles(args))
        thunkAPI.dispatch(getChats({ eventSlug: args.eventSlug, limit: 10 }))
    },
)
