import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import colors from '../constants/colors'
import useDebounceValue from '../hooks/useDebounceValue'
import Icon from './Icon'
import { _ } from '../utils/localization'

const S = {
    InputGroup: styled.div`
        position: relative;
        align-self: baseline;
        width: 100%;
        z-index: 2;
        & > input::placeholder {
            font-size: 14px;
            font-weight: 300;
            color: ${colors.iconOpacity(0.5)}
        }
    `,
    SearchIcon: styled.div`
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-53%);
    `,
}

interface Props {
    value?: string
    onSearch: (search: string) => void
    debounce?: number
    className?: string
    placeholder?: string
}

const SearchInput = ({
    value = '', onSearch, debounce = 400, className, placeholder
}: Props) => {
    const [searchValue, setSearchValue] = useState(value)
    const searchParam = useDebounceValue(searchValue, debounce)

    useEffect(() => {
        onSearch(searchParam)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam])

    return (
        <S.InputGroup className={className}>
            <Form.Control
                placeholder={placeholder || _('placeholder.search')}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
            />
            <S.SearchIcon>
                <Icon icon="search" size={22} />
            </S.SearchIcon>
        </S.InputGroup>
    )
}

export default SearchInput
