import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { websocketAction } from '../../utils/redux'
import { User } from '../auth/types'
import api from '../../utils/api'
import { ListVirtualAttendeesResponse } from '../../types/__generated_swagger'
import { RootState } from '../../store'
import { createAnonymousUserIfNotPublic, UpdatePrivacyPayload } from '../attendees/attendeesSlice'
import attendeeList from '../events/attendeeList'
import { VirtualAttendee } from '../../types/swagger'
import searchUtil from '../../utils/search'
import { makeUnique } from '../../utils/arrays'

interface GetAttendeesThunkProps {
    eventSlug: string
    presentationSlug: string
    search: string
    limit: number
}

// TODO implement proper pagination with offset
export const getAttendees = createAsyncThunk(
    'GET_ATTENDEES',
    ({
        eventSlug, presentationSlug, limit, search
    }: GetAttendeesThunkProps) => {
        const params = `?search=${search}&limit=${limit}&presentation_slug=${presentationSlug}`
        const url = `events/${eventSlug}/virtual_attendees/${params}`
        return api.get<ListVirtualAttendeesResponse>(url)
    },
)

interface UserLeft {
    user_id: number
}

export const WS = {
    joinedPresentationArea: websocketAction<VirtualAttendee>('joined_presentation_space'),
    leftPresentationArea: websocketAction<UserLeft>('left_presentation_space'),
}

const presentationAttendeesSlice = createSlice({
    name: 'presentation',
    initialState: {
        results: {} as Record<number, VirtualAttendee>,
        currentUsers: [] as number[],
        count: 0,
        search: '',
        limit: 15,
    },
    reducers: {
        loadMore: (state) => {
            state.limit += 10
        },
        setSearch: (state, { payload }) => {
            state.search = payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAttendees.fulfilled, (state, { payload }) => {
            state.results = {}
            state.count = payload.count
            payload.results.forEach((profile) => {
                state.results[profile.id] = profile as User
            })
            state.currentUsers = payload.results.map((e) => e.id)
        })
        builder.addCase(WS.joinedPresentationArea, (state, { payload }) => {
            if (!state.currentUsers.includes(payload.id)) {
                state.results[payload.id] = payload
                if (state.currentUsers.length <= 15) {
                    state.currentUsers = makeUnique([...state.currentUsers, payload.id])
                } else {
                    // activate loadmore
                    state.count = state.currentUsers.length + 1
                }
            }
        })
        builder.addCase(WS.leftPresentationArea, (state, { payload }) => {
            state.currentUsers = state.currentUsers.filter((e) => e !== payload.user_id)
        })
        builder.addCase(attendeeList.WS.attendee_updated, (state, { payload }) => {
            state.results[payload.id] = payload
        })
        builder.addCase(websocketAction<UpdatePrivacyPayload>('privacy_status_updated'), (state, { payload }) => {
            if (state.results && state.results[payload.user]) {
                state.results[payload.user].is_public = payload.is_public
            }
        })
    },
})

export const presentationAttendeesSelector = () => createSelector(
    [
        (state: RootState) => state.auth.user,
        (state: RootState) => state.presentationAttendees.results,
        (state: RootState) => state.presentationAttendees.currentUsers,
        (state: RootState) => state.presentationAttendees.search
    ],
    (user, results, currentUsers, search) => {
        const toSearchValue = (attendee) => `
            ${attendee.first_name}
            ${attendee.last_name}
            ${attendee.jobtitle}
            ${attendee.company}
        `
        // When receiving a WS.joinedPresentationArea,
        // we will add that user into the results state.
        // So the results won't correct if the user joins after searching
        return (
            Object.values(results)
                .map(createAnonymousUserIfNotPublic)
                .filter((e) => currentUsers.includes(e.id) && e.id !== user.id)
                .filter((e) => searchUtil(toSearchValue(e), search))
        )
    }
)
export const selectSearch = (state: RootState) => state.presentationAttendees.search
export const selectLimit = (state: RootState) => state.presentationAttendees.limit
export const canLoadMoreSelector = () => createSelector(
    [
        (state: RootState) => state.presentationAttendees.limit,
        (state: RootState) => state.presentationAttendees.count,
    ],
    (limit, count) => limit < count,
)
export const { loadMore, setSearch } = presentationAttendeesSlice.actions

export default presentationAttendeesSlice.reducer
