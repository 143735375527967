import React from 'react'
import styled from 'styled-components'
import { Popover } from 'react-bootstrap'
import CustomButton from './CustomButton'
import { IconOption } from './Icon/icons'

interface PopoverProps {
    title?: string
    text?: string
    cancelButton?: string
    confirmButton?: string
    children?: React.ReactNode
    onConfirm?: () => void
    onCancel?: () => void
    cancelIcon?: IconOption
    confirmIcon?: IconOption
}

const S = {
    Popover: styled.div`
        position: static;
        max-width: 360px;
    `,
    PopoverTitle: styled(Popover.Title)`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    PopoverContent: styled(Popover.Content)`
        padding: 0;
        background: white;
    `,
    Text: styled.p`
        font-size: 13px;
    `,
    ActionWrapper: styled.div`
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    `,
}

const CustomPopover = ({
    title, text, cancelButton, confirmButton, children, onConfirm, onCancel, cancelIcon, confirmIcon
}: PopoverProps) => (
    <S.Popover>
        <S.PopoverTitle>{title}</S.PopoverTitle>
        <Popover.Content>
            {text && <S.Text>{text}</S.Text>}
            {children}
            <S.ActionWrapper>
                {confirmButton && <CustomButton variant="success" onClick={onConfirm} icon={confirmIcon}>{confirmButton}</CustomButton>}
                {cancelButton && <CustomButton variant="danger" onClick={onCancel} icon={cancelIcon}>{cancelButton}</CustomButton>}
            </S.ActionWrapper>
        </Popover.Content>
    </S.Popover>
)

export default CustomPopover
