import React from 'react'
import styled from 'styled-components'
import Text from 'react-texty'
import colors from '../constants/colors'
import { LocationTypeKeys } from '../features/calendar/calendarSlice'
import { getLocationTypeTranslation } from '../features/calendar/utils'
import Icon from './Icon'
import { IconOption } from './Icon/icons'

const S = {
    Wrapper: styled.div`
        display: flex;
        width: 70px;
        align-items: center;
        flex-shrink: 0;
        font-size: 10px;
        color: ${colors.icon};
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
    `,
}

const iconMapper: Record<LocationTypeKeys, IconOption> = {
    VIRTUAL: 'virtual',
    IN_PERSON: 'handShake',
    HYBRID: 'hybrid',
}

const LocationIndicator = ({ location, className }: {location: LocationTypeKeys, className?: string}) => (
    location ? (
        <S.Wrapper className={className}>
            <Icon icon={iconMapper[location]} size={18} className="mr-1" />
            <Text className="text-cut">{getLocationTypeTranslation(location)}</Text>
        </S.Wrapper>
    ) : <></>
)

export default LocationIndicator
