import React, { ReactNode } from 'react'
import styled from 'styled-components'
import colors from '../constants/colors'

const S = {
    Wrapper: styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        min-height: 100%;
        padding: 16px 40px;
        background-color: ${colors.gray1};
    `,
    Container: styled.div`
        max-width: 1400px;
        width: 100%;
    `,
    PageTitle: styled.h1`
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 700;
        color: ${colors.grayTitle};
    `
}

interface Props {
    title?: ReactNode
    children?: ReactNode
}

const PageContainer = ({ title, children }: Props) => (
    <S.Wrapper>
        <S.Container>
            {title && <S.PageTitle>{title}</S.PageTitle>}
            {children}
        </S.Container>
    </S.Wrapper>
)

export default PageContainer
