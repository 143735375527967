import {
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import api from '../../utils/api'
import { captureError } from '../../utils/errorTracker'
import { RootState } from '../../store'
import { websocketAction } from '../../utils/redux'
import LeaveConfirmation from './components/LeaveConfirmation'
import { addModal } from '../modals/modalSlice'
import { Position, VideocallRoomStatus } from './types'

import {
    CreateJoinVideocallRequest,
    CreateJoinVideocallResponse,
    PartialUpdateVideocallRequest,
    PartialUpdateVideocallResponse,
    UpdateVideocallResponse,
} from '../../types/swagger'

import sendToWs from '../websocket/sendToWs'
import { receivedFromUserAction } from '../websocket/sendToUser'

interface VideocallUrlArgs {
    roomId: number | string
    eventSlug: string
}

interface VideocallTokenThunkProps {
    eventSlug: string
    room: string
}

interface CreateVideocalThunkProps {
    eventSlug: string
    admin?: number
}

interface VideocallChangeNameThunkProps {
    input: PartialUpdateVideocallRequest
    urlArgs: VideocallUrlArgs
}

interface UpdateRoomName extends VideocallUrlArgs {
    name: string
}

interface UpdateSpeaker extends VideocallUrlArgs {
    speaker: number
}

interface UpdateRoomStatusProps extends VideocallUrlArgs {
    status: VideocallRoomStatus
}

export interface RequestVideoAccess {
    videocall_id: number
    user_requesting_access: number
}

export interface CancelledVideocallRequest {
    videocall_id: number
    user_cancelling_access_request: number
}

export interface RequestReplied {
    videocall_id: number
    accepted: boolean
}

export interface MuteAllUsersProps {
    eventSlug: string
    roomId: number
}

export const WS = {
    updatedCall: websocketAction<UpdateVideocallResponse>('updated_videocall'),
    accessRequested: websocketAction<RequestVideoAccess>('requested_videocall_access'),
    gotKicked: receivedFromUserAction('kicked_from_videocall'),
    requestReplied: receivedFromUserAction<RequestReplied>('replied_videocall_access'),
    accessRequestCanceled: websocketAction<CancelledVideocallRequest>('canceled_videocall_accesss'),
    muteVideocall: receivedFromUserAction('host_muted_videocall')
}

export const postJoinRoom = createAsyncThunk(
    'GET_VIDEOCALL_TOKEN',
    async ({ eventSlug, room }: VideocallTokenThunkProps) => {
        const input: CreateJoinVideocallRequest = { room }
        return api.post<CreateJoinVideocallResponse>(`/events/${eventSlug}/videocalls/join/`, input)
    },
)

export const createVideocall = createAsyncThunk(
    'CREATE_VIDEO_CALL',
    (params: CreateVideocalThunkProps): Promise<CreateJoinVideocallResponse> => {
        const { eventSlug, admin } = params
        const data = admin ? { admin } : {}
        const url = `/events/${eventSlug}/videocalls/create/`
        return api.post<CreateJoinVideocallResponse>(url, data)
    },
)

export const joinRoom = (
    eventSlug: string,
    room: string,
) => postJoinRoom({
    eventSlug,
    room,
})

export const postRoomChange = createAsyncThunk(
    'UPDATE_VIDEOCALL',
    async ({ input, urlArgs }: VideocallChangeNameThunkProps) => {
        const url = `/events/${urlArgs.eventSlug}/videocalls/${urlArgs.roomId}/`
        return api.put<PartialUpdateVideocallResponse>(url, input)
    },
)

export const openLeaveCallModal = createAsyncThunk(
    'OPEN_LEAVE_CALL_MODAL',
    (params, thunkApi) => {
        thunkApi.dispatch(addModal({
            Component: LeaveConfirmation,
            size: 'sm',
        }))
    },
)

export type Layout = 'equal' | 'speaker' | 'host'

export const videocallSlice = createSlice({
    name: 'videocallJWT',
    initialState: {
        current: null as UpdateVideocallResponse,
        jwt: null as string,
        sharingScreenId: null as string,
        layout: 'equal' as Layout,
        isAudioEnabled: true,
        isVideoEnabled: true,
        isScreenSharingEnabled: false,
    },
    reducers: {
        setSharingScreenId(state, { payload }: PayloadAction<string>) {
            state.sharingScreenId = payload
        },
        setLayout(state, { payload }: PayloadAction<Layout>) {
            state.layout = payload
        },
        leaveCall(state) {
            state.jwt = null
            state.isScreenSharingEnabled = false
        },
        setSpeaker(state, action: PayloadAction<number>) {
            state.current.speaker = action.payload
        },
        setRoomName(state, action: PayloadAction<string>) {
            state.current.display_name = action.payload
        },
        setRoomStatus(state, action: PayloadAction<VideocallRoomStatus>) {
            state.current.status = action.payload
        },
        setPosition(state, action: PayloadAction<Position>) {
            state.current.x_position = action.payload.x
            state.current.y_position = action.payload.y
        },
        toggleAudio: (state) => {
            state.isAudioEnabled = !state.isAudioEnabled
        },
        disableAudio: (state) => {
            state.isAudioEnabled = false
        },
        toggleVideo: (state) => {
            state.isVideoEnabled = !state.isVideoEnabled
        },
        setIsScreenSharing: (state, action: PayloadAction<boolean>) => {
            state.isScreenSharingEnabled = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(postJoinRoom.fulfilled, (state, { payload }) => {
            state.jwt = payload.jwt
            state.current = payload.videocall
        })
        builder.addCase(postJoinRoom.rejected, (state) => {
            captureError('Couldnt fetch videocall token')
            state.jwt = undefined
        })
        builder.addCase(WS.updatedCall, (state, { payload }) => {
            state.current = payload
        })
        builder.addCase(createVideocall.fulfilled, (state, { payload }) => {
            // TODO this eslint ignore
            // @ts-ignore
            state.current = payload
        })
    },
})

export const jwtSelector = () => createSelector(
    [(state: RootState) => state.videocall],
    (videocall) => videocall.jwt,
)

export const updateRoomName = createAsyncThunk('UPDATE_ROOM_NAME', (
    { name, eventSlug, roomId }: UpdateRoomName, thunkAPI,
) => {
    thunkAPI.dispatch(postRoomChange({
        input: { display_name: name },
        urlArgs: { eventSlug, roomId },
    }))
    thunkAPI.dispatch(videocallSlice.actions.setRoomName(name))
})

export const updateSpeaker = createAsyncThunk('UPDATE_SPEAKER', (
    { speaker, eventSlug, roomId }: UpdateSpeaker, thunkAPI,
) => {
    thunkAPI.dispatch(postRoomChange({
        input: { speaker },
        urlArgs: { eventSlug, roomId },
    }))
    thunkAPI.dispatch(videocallSlice.actions.setSpeaker(speaker))
})

export const updateRoomStatus = createAsyncThunk('UPDATE_ROOM_STATUS', (
    { status, eventSlug, roomId }: UpdateRoomStatusProps, thunkAPI,
) => {
    thunkAPI.dispatch(postRoomChange({
        input: { status },
        urlArgs: { eventSlug, roomId },
    }))
    thunkAPI.dispatch(videocallSlice.actions.setRoomStatus(status))
})

export const leaveCall = createAsyncThunk(
    'LEAVE_CALL',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(sendToWs({
            type: 'exit_videocalls',
        }))
        thunkAPI.dispatch(videocallSlice.actions.leaveCall())
    },
)

export const selectSharingScreenId = (state: RootState) => state.videocall.sharingScreenId
export const selectIsScreenSharingEnabled = (state: RootState) => state.videocall.isScreenSharingEnabled
export const selectSpeaker = (state: RootState) => state.videocall.current?.speaker
export const selectHost = (state: RootState) => state.videocall.current?.speaker
export const selectVideocall = (state: RootState) => state.videocall.current
export const selectLayout = (state: RootState) => state.videocall.layout
export const selectIsSpeaker = createSelector(
    [
        (state: RootState) => state.videocall?.current?.speaker,
        (state: RootState) => state.auth.user?.id,
    ],
    (hostId, userId) => hostId === userId,
)

export const toggleAudio = videocallSlice.actions.toggleAudio
export const disableAudio = videocallSlice.actions.disableAudio
export const toggleVideo = videocallSlice.actions.toggleVideo
export const setLayout = videocallSlice.actions.setLayout
export const setSharingScreenId = videocallSlice.actions.setSharingScreenId
export const setIsScreenSharing = videocallSlice.actions.setIsScreenSharing
export default videocallSlice.reducer
