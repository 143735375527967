import useSWR, { mutate } from 'swr'
import useSlug from '../../../hooks/useSlug'
import { AppointmentSchedule } from '../../../types/swagger'
import { listFetcher } from '../../../utils/api'

interface Result {
    data?: AppointmentSchedule[]
    mutate?: (data) => void
    removedMutate?: (id: number) => void
    isLoading?: boolean
}

const initValues = {
    data: [],
    mutate: () => null,
    removedMutate: () => null,
}

const useFetchBlockedSchedules = (): Result => {
    const eventSlug = useSlug('eventSlug')
    const { data, error } = useSWR<AppointmentSchedule[]>(
        `/events/${eventSlug}/appointment_schedules/blocked/`,
        listFetcher,
        { dedupingInterval: 15000, revalidateOnFocus: false }
    )

    if (!data && !error) {
        return {
            ...initValues,
            isLoading: true
        }
    }

    if (error) {
        return {
            ...initValues,
            isLoading: false
        }
    }
    const results = (data || []).map((appointment) => ({
        ...appointment,
        start_date: new Date(appointment.start_date),
        end_date: new Date(appointment.end_date),
    }))

    return {
        isLoading: false,
        data: results.map((item) => ({
            ...item, start_date: new Date(item.start_date), end_date: new Date(item.end_date)
        })),
        mutate: (_data) => mutate(
            `/events/${eventSlug}/appointment_schedules/blocked/`,
            [...results.filter((e) => e.id !== _data.id), _data],
            false
        ),
        removedMutate: (id) => mutate(
            `/events/${eventSlug}/appointment_schedules/blocked/`,
            results.filter((e) => e.id !== id),
            false
        ),
    }
}

export default useFetchBlockedSchedules
