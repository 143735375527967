import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'

interface AbsolutePopoverProps {
    x: string | number
    y: string | number
    children: any
}

const S = {
    Position: styled.div<AbsolutePopoverProps>`
        left: ${({ x }) => (typeof x === 'number' ? `${x}px` : x)};
        top: ${({ y }) => (typeof y === 'number' ? `${y}px` : y)};
        position: absolute;
        overflow: visible;
        height: 1px;
        width: 1px;
    `,
    Inner: styled.div`
        position: relative;
        z-index: 10;
    `,
    Content: styled.div`
        border-radius: 0.3rem;
        background-color: white;
        bottom: 100%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -8px);
    `,
    Arrow: styled.div`
        border: 8px solid transparent;
        border-top-color: white;
        bottom: 100%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 8px);
        height: 0;
        width: 0;
    `
}

const AbsolutePopover = (props: AbsolutePopoverProps) => {
    const ref = useRef(null)
    useLayoutEffect(() => {

    }, [])
    return (
        <S.Position {...props} ref={ref}>
            <S.Inner>
                <S.Content>
                    {props.children}
                </S.Content>
                <S.Arrow />
            </S.Inner>
        </S.Position>
    )
}
export default AbsolutePopover
