import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { getIsNow, getIsSoon } from '../../timetable/utils'
import { addModal } from '../../modals/modalSlice'
import Icon from '../../../components/Icon'
import { _ } from '../../../utils/localization'
import { toLocalDateFormat, toLocalTimeFormat } from '../../../utils/datetime'
import { GroupSessionType } from '../groupSessionsSlice'
import { S } from '../../timetable/components/ProgramItem'
import GroupSessionDetails from './GroupSessionDetails'
import colors from '../../../constants/colors'
import { eventTimeFormatSelector } from '../../auth/eventPreviewSlice'

export interface GroupSessionProps {
    item: GroupSessionType
    onClick?: (entered?: boolean) => void
}

const GroupSession = ({ item, onClick = () => undefined }: GroupSessionProps) => {
    const dispatch = useDispatch()
    const isNow = getIsNow(item.starts_at, item.ends_at)
    const isSoon = getIsSoon(item.starts_at)
    const timeFormat = useSelector(eventTimeFormatSelector)

    const itemInfoClick = () => {
        const Component = () => <GroupSessionDetails item={item} />
        dispatch(addModal({ Component }))
        onClick()
    }

    return (
        <S.ProgramItem $itemColor={colors.red}>
            <S.ProgramItemContents onClick={itemInfoClick}>
                <S.Name>{item.videocall.display_name}</S.Name>
                { (isNow || isSoon)
                    ? (
                        <S.NowLive>
                            <Icon icon="monitorEye" style={{ marginRight: 12 }} />
                            {' '}
                            { isNow ? _('item.is_live') : _('item.is_soon') }
                        </S.NowLive>
                    )
                    : (
                        <S.TimeText>
                            <span className="mr-3">
                                {toLocalTimeFormat(item.starts_at, timeFormat)}
                                {' - '}
                                {toLocalTimeFormat(item.ends_at, timeFormat)}
                            </span>
                            <span>{toLocalDateFormat(item.starts_at)}</span>
                        </S.TimeText>
                    )}
            </S.ProgramItemContents>
            <Icon
                icon="info"
                onClick={itemInfoClick}
            />
        </S.ProgramItem>
    )
}

export default GroupSession
