import { createRoute } from '../../utils/routes'
import BrowserSupportView from './BrowserSupportView'

export default {
    browserSupport: createRoute({
        title: 'Browser Status',
        path: '/my-browser',
        isPublic: true,
        component: BrowserSupportView,
    }),
}
