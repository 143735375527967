import React, { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { PayloadAction } from '@reduxjs/toolkit'
import env from '../../../constants/env'
import SideBar from '../components/SideBar'
import Map from '../components/Map'
import NetworkingLayout from '../components/NetworkingLayout'
import Videocall from '../../videocall/views/Videocall'
import { fetchNetworkingArea, fetchNetworkingStatusSelector, selectPageData } from '../networkingSlice'
import { joinNetworkingArea } from '../wsActions'
import useSlug from '../../../hooks/useSlug'
import { currentUserSelector } from '../../auth/authSlice'
import styles from '../../../constants/styles'
import useHelpContent from '../../../hooks/useHelpContent'
import { useReduxBus } from '../../../utils/reduxmiddleware/reduxEventBus'
import openChatRouletteLinkModal, {
    ChatRouletteStarted,
    WS
} from '../../chatroulette/openChatRouletteLinkModal'
import { clearOffset } from '../../attendees/attendeesSlice'
import useCloseSpace from '../../../hooks/useCloseSpace'
import useWatchChangeInSpace from '../../../hooks/useWatchChangeInSpace'
import ClosedSpace from '../../../components/ClosedSpace'
import FullPageLoading from '../../../components/FullPageLoading'
import useDispatchAndSelect from '../../../hooks/useDispatchAndSelect'

const S = {
    VideoCallWrapper: styled.div`
      margin-right: ${styles.sidebarWidth}px;
      width: 100%;
      @media (max-width: 1366px) {
        margin-right: ${styles.sidebarWidth}px;
      }
    `,
}

const NetworkingArea = () => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const networkingSlug = useSlug('networkingSlug')
    const currentUser = useSelector(currentUserSelector())
    const pageData = useDispatchAndSelect(() => fetchNetworkingArea({ eventSlug, networkingSlug }),
        selectPageData(networkingSlug))
    const fetchNetworkingStatus = useSelector(fetchNetworkingStatusSelector)

    const closeSpace = useCloseSpace(pageData?.meta?.published)
    useWatchChangeInSpace(pageData?.meta?.id)

    useHelpContent({data: pageData?.meta.help_content, type: pageData?.meta.help_content_type})

    // When in networking area and a chatroulette is about to start
    // show a modal to go to chatroulette space.
    useReduxBus((action: PayloadAction<ChatRouletteStarted>) => {
        dispatch(openChatRouletteLinkModal(action))
    }, WS.chatRouletteStarted.type)

    useEffect(() => {
        dispatch(joinNetworkingArea({ eventSlug, networkingSlug }))
    }, [dispatch, networkingSlug, eventSlug])

    // Clear offset on unmount
    useEffect(() => () => {
        dispatch(clearOffset())
    })

    if (fetchNetworkingStatus === 'loading' || fetchNetworkingStatus === 'idle') {
        return <FullPageLoading />
    }

    if (closeSpace) {
        return <ClosedSpace />
    }

    return (
        // @ts-ignore
        <DndProvider debugMode={env.debug} backend={HTML5Backend}>
            <NetworkingLayout>
                <Switch>
                    <Route path="/:eventSlug/networking/:networkingSlug/videocall/:roomId">
                        <S.VideoCallWrapper>
                            <Videocall />
                        </S.VideoCallWrapper>
                    </Route>
                    <Route>
                        <Map />
                    </Route>
                </Switch>

                {currentUser && <SideBar currentUser={currentUser} chatId={pageData?.meta?.chat_id} />}
            </NetworkingLayout>
        </DndProvider>
    )
}

export default NetworkingArea
