import PresentationRoomView from './views/PresentationRoomView'
import { createEventRoute } from '../../utils/routes'

export default {
    presentationRoom: createEventRoute({
        title: 'Presentation Room',
        path: '/presentation/:presentationSlug',
        component: PresentationRoomView,
    }),
}
