import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { GoodiebagCategory, GoodiebagType } from '../types'
import FileTable from './FileTable'
import Speakers from './Speakers'
import Booths from './Booths'
import getTitle from '../getTitle'
import Get from '../../../components/Get'
import { ListGoodiebagItemsResponse } from '../../../types/__generated_swagger'
import { categorySelector } from '../categorySlice'
import useSlug from '../../../hooks/useSlug'
import ProgramItems from './ProgramItems'
import Attendees from './Attendees'
import Posters from './Posters'
import { _ } from '../../../utils/localization'
import colors from '../../../constants/colors'
import { EventFileItem, selectEventFiles } from '../eventFilesSlice'
import MeetingExperts from './MeetingExperts'
import GroupSessions from './GroupSessions'
import { selectGroupSessions } from '../../groupsessions/groupSessionsSlice'

const S = {
    Title: styled.h1`
        font-weight: 500;
        font-size: 20px;
        text-transform: uppercase;
        margin-top: 40px;
        margin-bottom: 20px;
    `,
    NoContent: styled.p`
        color: ${colors.grayDescription};
        font-weight: 300;
    `
}

export type ContentProps = { items: ListGoodiebagItemsResponse['results'] }

export type EventContentProps = { items: EventFileItem[] }

const typeToComponent: Record<GoodiebagType, FC<ContentProps>> = {
    [GoodiebagType.Speaker]: Speakers,
    [GoodiebagType.ProgramItem]: ProgramItems,
    [GoodiebagType.Attendee]: Attendees,
    [GoodiebagType.Booth]: Booths,
    [GoodiebagType.Poster]: Posters,
    [GoodiebagType.MeetingExpert]: MeetingExperts,
}

const GoodiebagFiles = () => {
    const eventFiles = useSelector(selectEventFiles)
    return eventFiles && (
        <div>
            <S.Title>{_(getTitle(GoodiebagCategory.File))}</S.Title>
            {
                eventFiles.count > 0
                    ? <FileTable items={eventFiles.results || []} />
                    : <S.NoContent>{_('goodiebag.category_item_empty')}</S.NoContent>
            }
        </div>
    )
}

const GoodiebagGroupSessions = () => {
    const groupSessions = useSelector(selectGroupSessions)
    return groupSessions && (
        <div>
            <S.Title>{_(getTitle(GoodiebagCategory.GroupSession))}</S.Title>
            {
                groupSessions.length > 0
                    ? <GroupSessions items={groupSessions} />
                    : <S.NoContent>{_('goodiebag.category_item_empty')}</S.NoContent>
            }
        </div>
    )
}

const GoodiebagContent = () => {
    const eventSlug = useSlug('eventSlug')
    const category = useSelector(categorySelector)
    if (category === GoodiebagCategory.File) {
        return <GoodiebagFiles />
    }

    if (category === GoodiebagCategory.GroupSession) {
        return <GoodiebagGroupSessions />
    }

    const Content = typeToComponent[category]
    return (
        <div>
            <S.Title>{_(getTitle(category))}</S.Title>
            <Get url={`events/${eventSlug}/goodiebag/?type=${category}`}>
                {({ results }: ListGoodiebagItemsResponse) => {
                    if (results.length) {
                        return <Content items={results} />
                    }
                    return <S.NoContent>{_('goodiebag.category_item_empty')}</S.NoContent>
                }}
            </Get>
        </div>
    )
}

export default GoodiebagContent
