/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit'
import styled from 'styled-components'
import { _ } from '../../utils/localization'
import { addModal, closeAllModal } from '../modals/modalSlice'
import ChatOverview from './ChatOverview'
import { clearCurrentChatId, setSelectingBroadcastMessage, setSelectingChatBot } from './chatSlice'
import openChat from './openChat'
import colors from '../../constants/colors'

const S = {
    Header: styled.div`
        flex: 1;
        display: flex;
        align-items: center;
        & > div{
            color: ${colors.midBlue};
        }
        & > h3 {
            flex: 1;
            margin: 0;
            margin-right: 10px;
            text-align: center;
            color: #7f7f7f;
            font-weight: bold;
            font-size: 18px;
        }
        @media (min-width: 933px) {
            & > div {
                display: none;
            }
        }
    `
}

const openChatModal = (eventSlug, userId) => createAsyncThunk(
    'OPEN_CHAT_MODAL',
    (__, thunkAPI) => {
        thunkAPI.dispatch(closeAllModal())
        const reset = () => {
            thunkAPI.dispatch(clearCurrentChatId())
            thunkAPI.dispatch(setSelectingBroadcastMessage(false))
            thunkAPI.dispatch(setSelectingChatBot(false))
        }
        const backToList = () => {
            thunkAPI.dispatch(closeAllModal())
            reset()
            thunkAPI.dispatch(openChatModal(eventSlug))
        }
        if (userId) {
            thunkAPI.dispatch(openChat(eventSlug, userId))
        }
        thunkAPI.dispatch(addModal({
            Component: ChatOverview,
            header: () => (
                <S.Header>
                    {userId && (
                        <div onClick={backToList} style={{cursor: 'pointer', fontSize: 14}}>
                            {_('messages.back_to_list')}
                        </div>
                    )}
                    <h3>{_('messages.modal_header')}</h3>
                </S.Header>
            ),
            size: 'xl',
            callback: reset,
            padding: 0,
            useNewModal: true,
        }))
    },
)()

export default openChatModal
