import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
import GeneralInfoWithThumbnail from '../../../../components/GeneralInfoWithThumbnail'
import colors from '../../../../constants/colors'
import { _ } from '../../../../utils/localization'
import { VirtualAttendee } from '../../../../types/swagger'
import Icon from '../../../../components/Icon'
import openChatModal from '../../../chat/openChatModal'
import AttendeeCallStatus from '../../../entervideocall/AttendeeCallStatus'
import useSlug from '../../../../hooks/useSlug'
import { formatJobInfo } from '../../../../utils/profile'

const S = {
    Wrapper: styled.div`
        padding: 20px;
    `,
    Divider: styled.div`
        width: 100%;
        height: 1px;
        margin: 20px 0;
        background-color: ${colors.gray2};
    `,
    Contact: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 932px) {
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
        }
    `,
    Title: styled.p`
        margin-bottom: 20;
        font-weight: 500;
    `,
    Description: styled.p`
        font-size: 14px;
        font-weight: 300;
        color: ${colors.gray7};
    `,
    Email: styled.span`
        margin-left: 10px;
        font-weight: 300;
        color: ${colors.grayDescription};
    `,
    Communication: styled.span`
        font-weight: 500;
        color: ${colors.grayDescription};
    `

}

interface Props {
    user: VirtualAttendee
    message: string
    close: () => void
}

const BoothBusinessCardModal = ({ user, message }: Props) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')

    return (
        <S.Wrapper>
            <Modal.Header closeButton style={{ padding: 0, border: 'none' }}>
                <GeneralInfoWithThumbnail
                    name={`${user.first_name} ${user.last_name}`}
                    description={formatJobInfo(user)}
                    image={user.mugshot}
                    size="sm"
                />
            </Modal.Header>
            <S.Divider />
            <S.Contact>
                {
                    user.email ? (
                        <a href={`mailto:${user.email}`}>
                            <Icon icon="email" />
                            <S.Email>{user.email}</S.Email>
                        </a>
                    ) : <div />
                }

                <div className="d-flex">
                    <S.Communication>
                        {_('booth.leave_businesscard_management.communication')}
                        :
                    </S.Communication>
                    <Icon
                        icon="forum"
                        color={colors.primary}
                        onClick={() => dispatch(openChatModal(eventSlug, user.id))}
                        style={{ margin: '0 16px' }}
                    />
                    <AttendeeCallStatus attendee={user} />
                </div>
            </S.Contact>
            <S.Divider />
            <S.Title>
                {_('booth.leave_businesscard_management.note')}
            </S.Title>
            <S.Description>
                {message}
            </S.Description>
        </S.Wrapper>
    )
}

export default BoothBusinessCardModal
