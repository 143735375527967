import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import colors from '../../../constants/colors'
import { _ } from '../../../utils/localization'
import { MeetingItem } from '../types'
import useSlug from '../../../hooks/useSlug'
import useRedirectToMeetingRoom from '../useRedirectToMeetingRoom'

const S = {
    Wrapper: styled.div`
        padding: 10px 20px;
    `,
    Title: styled.h3`
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
        & + button {
            margin-top: -6px!important;
            font-size: 32px;
            font-weight: 400;
            &:focus {
                outline: none;
            }
        }
    `,
    Description: styled.p`
        font-size: 14px;
        line-height: 18px;
        font-weight: 300;
        color: ${colors.gray7};
    `,
    Actions: styled.div`
        display: flex;
        justify-content: flex-end;
    `
}

const JoinConfirmModal = ({ meeting, close }: {meeting: MeetingItem, close: () => void}) => {
    const meetingSlug = useSlug('meetingSlug')
    const redirect = useRedirectToMeetingRoom(meeting, meetingSlug)

    const redirectAndClose = () => {
        redirect()
        close()
    }

    return (
        <S.Wrapper>
            <Modal.Header closeButton style={{ padding: 0, border: 'none' }}>
                <S.Title>{_('meetingspace.join_modal.title')}</S.Title>
            </Modal.Header>
            <S.Description>
                {`${_('meetingspace.join_modal.body')} ${meeting.title} ?`}
            </S.Description>
            <S.Actions>
                <Button
                    size="sm"
                    variant="light"
                    style={{ border: colors.outlineButton, padding: '5px 20px' }}
                    onClick={close}
                >
                    {_('button.cancel')}
                </Button>
                <Button
                    size="sm"
                    variant="success"
                    style={{ marginLeft: 10, padding: '5px 20px' }}
                    onClick={redirectAndClose}
                >
                    {_('meetingspace.join_modal.join_button')}
                </Button>
            </S.Actions>
        </S.Wrapper>
    )
}

export default JoinConfirmModal
