import React from 'react'
import { useDispatch } from 'react-redux'
import ConfirmModal from '../../../../components/ConfirmModal'
import colors from '../../../../constants/colors'
import useReloadMessages from '../../../../hooks/useReloadMessages'
import api from '../../../../utils/api'
import { captureError } from '../../../../utils/errorTracker'
import { _ } from '../../../../utils/localization'
import { setIsPageLoading } from '../../../events/pageLoadingSlice'
import { addModal } from '../../../modals/modalSlice'
import { showErrorNotification } from '../../../notifications/notificationsSlice'
import sendMessage from '../../sendMessage'
import { S } from './AppointmentScheduleNotification'
import { ScheduleActionsProps } from './DenyScheduleButton'

const AcceptScheduleButton = ({
    eventSlug, currentUser, receiver, appointmentSchedule, callback
}: ScheduleActionsProps) => {
    const dispatch = useDispatch()
    const reloadMessages = useReloadMessages(receiver?.id)

    const acceptSchedule = async (close) => {
        try {
            dispatch(setIsPageLoading(true))
            await api.post(`/events/${eventSlug}/appointment_schedules/${appointmentSchedule.id}/accept/`)
            dispatch(sendMessage({
                eventSlug,
                text: 'ACCEPTED',
                receiverId: receiver.id,
                currentUserId: currentUser.id,
                shouldSendEmail: !receiver?.is_online,
                appointmentSchedule,
                callback: reloadMessages,
            }))
            close()
            callback?.()
        } catch (error) {
            captureError(error)
            dispatch(showErrorNotification(_('appointment_schedule.accept_schedule_failed')))
        } finally {
            dispatch(setIsPageLoading(false))
        }
    }

    const showAcceptConfirm = () => {
        dispatch(addModal({
            Component: ({ close }: {close: () => null}) => (
                <ConfirmModal
                    header={_('appointment_schedule.accept_schedule.modal_header')}
                    body={_('appointment_schedule.accept_schedule.modal_body')}
                    onConfirm={() => acceptSchedule(close)}
                    close={close}
                />
            ),
            padding: 30
        }))
    }

    return (
        <S.TextButton
            color={colors.primary}
            onClick={showAcceptConfirm}
        >
            {_('button.accept')}
        </S.TextButton>
    )
}

export default AcceptScheduleButton
