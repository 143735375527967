import { Area } from './types'

type Point = { x: number, y: number };

const toPoints = (coords: number[]): Point[] => {
    const points = []
    for (let i = 0; i < coords.length; i += 2) {
        points.push({ x: coords[i], y: coords[i + 1] })
    }
    return points
}

export const Canvas = (canvas: HTMLCanvasElement, width: number, height: number) => {
    const OPACITY = 0.75
    const ctx = canvas.getContext('2d')

    const fill = () => {
        ctx.fillStyle = 'rgba(0, 0, 0, 0.57)'
        ctx.fill()
    }

    let requestId
    const fillWithAnimation = () => {
        const LAYERS = 20
        ctx.fillStyle = `rgba(0, 0, 0, ${OPACITY / LAYERS})`
        let i = 0

        const drawLayer = () => {
            requestId = undefined
            if (++i > LAYERS) { return }
            ctx.fill()
            startAnimation()
        }
        const startAnimation = () => {
            if (!requestId) {
                requestId = window.requestAnimationFrame(drawLayer)
            }
        }
        startAnimation()
    }

    const stopAnimation = () => {
        if (requestId) {
            window.cancelAnimationFrame(requestId)
            requestId = undefined
        }
    }

    const drawBackground = () => {
        ctx.beginPath()
        ctx.moveTo(0, 0)
        ctx.lineTo(width, 0)
        ctx.lineTo(width, height)
        ctx.lineTo(0, height)
        ctx.lineTo(0, 0)
        ctx.closePath()
    }

    const drawrect = (coords: number[]) => {
        const [left, top, right, bot] = coords
        const _left = Math.min(left, right)
        const _right = Math.max(left, right)
        const _bot = Math.min(top, bot)
        const _top = Math.max(top, bot)

        ctx.moveTo(_left, _bot)
        ctx.lineTo(_left, _top)
        ctx.lineTo(_right, _top)
        ctx.lineTo(_right, _bot)
        ctx.lineTo(_left, _bot)
    }

    const drawcircle = (coords: number[]) => {
        ctx.arc(coords[0], coords[1], coords[2], 0, 2 * Math.PI, true)
    }

    const getIsClockwise = (points: Point[]): boolean => {
        /**
         * Mathematics from: https://stackoverflow.com/a/1165943
         */
        const getEdge = (point: Point, nextPoint: Point) => (nextPoint.x - point.x) * (nextPoint.y + point.y)
        const totalEdge = points.reduce(
            (sum, point, i) => sum + getEdge(point, points[i + 1] || points[0]),
            0,
        )
        return totalEdge > 0
    }

    /**
     * Make coords of poly area follow clockwise to fix issue the area doesn't light up.
     */
    const makeClockwise = (points: Point[]): Point[] => (getIsClockwise(points)
        ? points
        : points.reverse())

    const drawpoly = (coords) => {
        const points: Point[] = makeClockwise(toPoints(coords))
        const firstPoint = points.shift()
        // Move without drawing line to the starting point
        ctx.moveTo(firstPoint.x, firstPoint.y)

        // Draw all lines
        points.forEach((point) => ctx.lineTo(point.x, point.y))
    }

    const drawArea = (area: Area) => ({
        circle: drawcircle,
        poly: drawpoly,
        rect: drawrect,
    }[area.shape])(area.coords)

    const erase = () => {
        stopAnimation()
        ctx.clearRect(0, 0, width, height)
    }

    const destroy = () => {
        erase()
    }

    const draw = (area: Area, animate = true) => {
        erase()
        drawBackground()
        drawArea(area)
        ctx.closePath()
        if (animate) {
            fillWithAnimation()
        } else {
            fill()
        }
    }

    return {
        erase,
        destroy,
        draw,
    }
}
