import {
    createAsyncThunk, createSelector,
    createSlice,
} from '@reduxjs/toolkit'

import api from '../../utils/api'
import { User } from '../auth/types'
import { Videocall } from '../../types/swagger'
import { RootState } from '../../store'
import { isSameDay, toDate } from '../../utils/datetime'

export type ListGroupSessionsResponse = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: GroupSessionType[];
};

export type GroupSessionType = {
    id: number
    starts_at: string
    ends_at: string
    description: string
    enable_host_chat: boolean
    participants: User[]
    videocall: Videocall
}

export const getGroupSessions = createAsyncThunk(
    'FETCH_GROUP_SESSIONS',
    async (eventSlug: string) => {
        const resp = await api.get<ListGroupSessionsResponse>(`events/${eventSlug}/groupsessions/`)
        return resp.results
    },
)

export const groupSessionsSlice = createSlice({
    name: 'groupSessionsSlice',
    initialState: {
        data: null as GroupSessionType[]
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getGroupSessions.fulfilled, (state, { payload }) => {
            state.data = payload
        })
    },
})

export const getGroupSessionsForTimeTable = createSelector(
    [
        (state: RootState) => state.groupSessions.data,
        (state: RootState) => state.timetable.date || new Date(),
    ],
    (groupSessions, date): GroupSessionType[] => {
        if (!groupSessions) {
            return []
        }

        return groupSessions.filter((session: GroupSessionType) => (
            isSameDay(toDate(session.starts_at), date)
            || isSameDay(toDate(session.ends_at), date)
        ))
    },
)
export default groupSessionsSlice.reducer

export const selectGroupSessions = (state: RootState) => state.groupSessions.data
