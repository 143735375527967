import { combineReducers } from 'redux'
import eventSlice from './eventSlice'
import fullScreenReducer from './fullScreenSlice'
import navigationSlice from './navigationSlice'
import eventOverviewSlice from './eventOverviewSlice'

export default combineReducers({
    event: eventOverviewSlice,
    current: eventSlice,
    navigation: navigationSlice,
    fullScreen: fullScreenReducer
})
