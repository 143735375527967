import React, { useEffect, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Switch, useHistory } from 'react-router-dom'
import constants from '../features/imagemap/constants'
import useSlug from '../hooks/useSlug'

interface TranslationSwitchProps {
    location: any
    pathname: string
    children: any
}

class TransitionSwitchInner extends React.Component<TranslationSwitchProps> {
    // It is important to not update the component if the location is not
    // changed, because it will trigger an animation when we don't want it.
    shouldComponentUpdate(nextProps: TranslationSwitchProps): boolean {
        return this.props.pathname !== nextProps.pathname
    }

    render() {
        const timeout = this.props.location
            ? { exit: constants.transitionSpeed, enter: 0 }
            : { enter: 0, exit: 0 }

        return (
            <TransitionGroup>
                <CSSTransition
                    key={this.props.pathname}
                    classNames="page-animation"
                    timeout={timeout}
                    unmountOnExit
                >
                    <Switch location={this.props.location}>
                        {this.props.children}
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        )
    }
}

const TransitionSwitch = ({ children }: { children: any }) => {
    const history = useHistory()
    const path = history.location.pathname
    const [pathname, setPathname] = useState('')
    const boothSlug = useSlug('boothSlug')

    // Here we set the pathname, so TransitionSwitchInner will only
    // update when we want it to.
    useEffect(() => {
        setPathname(
            // TODO
            //  this wrongly assumes that the bootSlug can only be in the
            //  url once. This is incorrect, because for example the booth
            //  slug can be the same as the event slug
            // Ignore boothSlug
            path.replace(boothSlug, ''),
        )
    }, [path, boothSlug])

    return !pathname ? null : (
        <TransitionSwitchInner pathname={pathname} location={boothSlug ? null : history.location}>
            {children}
        </TransitionSwitchInner>
    )
}

export default TransitionSwitch
