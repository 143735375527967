import { Field, useFormikContext } from 'formik'
import React from 'react'
import styled from 'styled-components'
import Divider from '../../../components/Divider'
import RadioButton from '../../../components/RadioButton'
import TextInput from '../../../components/TextField'
import colors from '../../../constants/colors'
import { _ } from '../../../utils/localization'

const S = {
    Description: styled.div`
        font-size: 14px;
        color: ${colors.darkGray};
    `
}

const SubjectAndLocationForm = () => {
    const { values } = useFormikContext<any>()

    return (
        <>
            <Divider />
            <S.Description>
                {_('appointment_schedule.location_type_description')}
            </S.Description>
            <Field name="locationType">
                {({ field }) => (
                    <RadioButton
                        {...field}
                        options={[
                            { label: _('program_calendar.location_type.virtual'), value: 'VIRTUAL' },
                            { label: _('program_calendar.location_type.in_person'), value: 'IN_PERSON' },
                        ]}
                        isVerticalAlign
                    />
                )}
            </Field>
            {values.locationType === 'IN_PERSON' && (
                <TextInput
                    name="location"
                    label={_('appointment_schedule.form.location')}
                    isRequired
                />
            )}
            <Divider />
            <TextInput
                name="subject"
                label={_('appointment_schedule.form.subject')}
                isTextArea
            />
        </>
    )
}

export default SubjectAndLocationForm
