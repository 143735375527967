import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    toLocalDateFormat,
    toLocalTimeFormat,
} from '../../../utils/datetime'
import Icon from '../../../components/Icon/Icon'
import { _ } from '../../../utils/localization'
import colors from '../../../constants/colors'
import { ProgramItem as ProgramItemType } from '../types'
import { getIsNow, getIsSoon } from '../utils'
import FetchItemDetail from '../../imagemap/components/FetchItemDetail'
import { addModal } from '../../modals/modalSlice'
import useSlug from '../../../hooks/useSlug'
import getPathForSpace from '../../../utils/spaces/getPathForSpace'
import { ParamSlug } from '../../../types'
import { eventTimeFormatSelector } from '../../auth/eventPreviewSlice'
import { isInRangePosition } from '../../calendar/CalendarItem'
import SponsorIndicator from '../../../assets/sponsor.svg'
import FetchProgramContainerModal from '../../calendar/components/FetchProgramContainerModal'

export const S = {
    ProgramItem: styled.div<{$itemColor: string}>`
        display: flex;
        width: 100%;
        height: 63px;
        overflow: hidden;
        background-color: ${colors.gray2};
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        font-size: 12px;
        border: 0;
        cursor: pointer;
        border-top: 5px solid ${({ $itemColor }) => $itemColor}!important;
        border-radius: 0!important;
    `,
    ProgramItemContents: styled.div`
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 20px;
    `,
    NowLive: styled.div`
        display: flex;
        align-items: center;
        color: red;
        font-weight: 600;
    `,
    Name: styled.p`
        width: 100%;
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 13px;
        color: ${colors.gray7};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    TimeText: styled.span`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    SponsorIndicator: styled.img`
        flex-shrink: 0;
        margin-bottom: 2px;
    `
}

export interface ProgramItemProps {
    item: ProgramItemType
    onClick?: (entered?: boolean) => void
    alwaysShowInfo?: boolean
}

const ProgramItem = ({ alwaysShowInfo, item, onClick = () => undefined }: ProgramItemProps) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<ParamSlug>()
    const eventSlug = useSlug('eventSlug')
    const isNow = getIsNow(item.start_date, item.end_date)
    const isSoon = getIsSoon(item.end_date)
    const path = getPathForSpace(item.space, params)
    const link = item.meeting_id
        ? `${path}?meetingId=${item.meeting_id}`
        : path
    const timeFormat = useSelector(eventTimeFormatSelector)
    const [mousePosition, setMousePosition] = useState({x: 0, y: 0})

    const itemInfoClick = (id) => {
        // eslint-disable-next-line react/prop-types
        const Component = () => <FetchItemDetail itemId={id} eventSlug={eventSlug} />
        dispatch(addModal({ Component }))
        onClick()
    }

    const showContainerModal = () => {
        dispatch(addModal({
            // eslint-disable-next-line react/prop-types
            Component: () => <FetchProgramContainerModal id={item.id} />,
            header: _('program.session_block'),
            padding: 0,
        }))
    }

    const itemClick = (evt) => {
        if (!isInRangePosition(evt.clientX, mousePosition.x)
            || !isInRangePosition(evt.clientY, mousePosition.y)) {
            return
        }

        if (item.isContainer) {
            showContainerModal()
            return
        }

        const shouldEnter = (
            getIsNow(item.start_date, item.end_date)
            || getIsSoon(item.start_date))
            && !alwaysShowInfo
        shouldEnter
            ? goToSpace()
            : itemInfoClick(item.id)
    }

    const goToSpace = () => {
        onClick(true)
        history.push(link)
    }

    return (
        <S.ProgramItem $itemColor={item.color}>
            <S.ProgramItemContents
                onMouseDown={(evt) => setMousePosition({x: evt.clientX, y: evt.clientY})}
                onClick={itemClick}
            >
                <div className="flex-align-center">
                    {item.sponsor && (
                        <S.SponsorIndicator
                            src={SponsorIndicator}
                            alt="program-sponsor"
                            className="mr-1"
                        />
                    )}
                    <S.Name>{item.name}</S.Name>
                </div>
                { (isNow || isSoon)
                    ? (
                        <S.NowLive>
                            <Icon icon="monitorEye" style={{ marginRight: 12 }} />
                            {' '}
                            { isNow ? _('item.is_live') : _('item.is_soon') }
                        </S.NowLive>
                    )
                    : (
                        <S.TimeText>
                            <span className="mr-3">
                                {toLocalTimeFormat(item.start_date, timeFormat)}
                                {' - '}
                                {toLocalTimeFormat(item.end_date, timeFormat)}
                            </span>
                            <span>{toLocalDateFormat(item.start_date)}</span>
                        </S.TimeText>
                    )}
            </S.ProgramItemContents>
            <Icon
                icon="info"
                onClick={item.isContainer ? showContainerModal : () => itemInfoClick(item.id)}
            />
        </S.ProgramItem>
    )
}

export default ProgramItem
