import { useDispatch } from 'react-redux'
import { useEffect, useRef } from 'react'
import difference from 'lodash/difference'
import sendToWs from '../websocket/sendToWs'
import throttle from '../../utils/throttle'
import { makeUnique } from '../../utils/arrays'
import { removeAttendeeData } from './attendeesSlice'

const subscribeAction = (attendeesIds: number[]) => sendToWs({
    type: 'subscribe_to_attendees',
    user_ids: makeUnique(attendeesIds),
})
const unsubscribeAction = (attendeesIds: number[]) => sendToWs({
    type: 'unsubscribe_to_attendees',
    user_ids: makeUnique(attendeesIds),
})

const createId = () => `${Math.round(Math.random() * 9999)}`

const throttled = (() => {
    // states:
    const idToAttendees: Record<string, number[]> = {}
    let toUnsubscribe: number[] = []
    let lastToSubscribe: number[] = []

    // Throttled function sends the attendee ids to websocket
    const runActions = throttle((dispatch) => {
        const toSubscribe: number[] = Object.values(idToAttendees).flatMap((ids) => ids)
        const newSubscribes = makeUnique(difference(toSubscribe, lastToSubscribe))
        lastToSubscribe = toSubscribe
        const filteredToUnsubscribe = makeUnique(toUnsubscribe.filter(
            (id) => !toSubscribe.includes(id)
        ))

        if (filteredToUnsubscribe.length) {
            dispatch(unsubscribeAction(filteredToUnsubscribe))
            dispatch(removeAttendeeData(makeUnique(filteredToUnsubscribe)))
        }

        if (newSubscribes.length) {
            dispatch(subscribeAction(newSubscribes))
        }
        toUnsubscribe = []
    }, 1000)

    // api
    return {
        subscribe: (dispatch, attendeesIds: number[], id: string) => {
            idToAttendees[id] = attendeesIds.filter((v) => v)
            runActions(dispatch)
        },
        unsubscribe: (dispatch, attendeesIds: number[], id: string) => {
            if (!attendeesIds[id]) {
                return
            }
            toUnsubscribe.push(...idToAttendees[id])
            delete idToAttendees[id]
            runActions(dispatch)
        },
    }
})()

/**
 * Use this hook to register the current user websocket connection
 * for updates about the status of certain attendees, while the component is mounted
 */
export default (attendeesIds: number[] = []) => {
    const dispatch = useDispatch()
    const idRef = useRef(createId())
    const id = idRef.current

    useEffect(() => {
        throttled.subscribe(dispatch, attendeesIds, id)
        return () => throttled.unsubscribe(dispatch, attendeesIds, id)
    }, [attendeesIds, dispatch, id])
}
