import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { withCapture } from '../../utils/errorTracker'
import api from '../../utils/api'

const readChat = (eventSlug: string, userId: number) => createAsyncThunk(
    'READ_CHAT_WITH_USER',
    (__, thunkAPI) => {
        const state = thunkAPI.getState() as RootState
        const chatExists = !!state.chat.idToChat[userId]
        if (chatExists) {
            withCapture(() => api.put(`/events/${eventSlug}/chat/${userId}/messages/read/`))
        }
    },
)()

export default readChat
