import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import Checkbox from '../../components/Checkbox'
import SearchInput from '../../components/SearchInput'
import colors from '../../constants/colors'
import { _ } from '../../utils/localization'
import EventSearch from '../event_search/EventSearch'
import { searchSelector, updateSearch } from '../event_search/searchEntireEventSlice'
import { ProgramSpace } from '../timetable/types'
import Calendar from './Calendar'
import { changeHorizontalSize, changeVerticalSize, sizeSelector } from './calendarSlice'
import CalendarSizes from './components/CalendarSizes'
import DropdownFilter from '../../components/DropdownFilter'
import ProgramFilters from './components/ProgramFilters'

const S = {
    CalenderWrapper: styled.div<{ isExpanding: boolean }>`
        display: grid;
        height: calc(100% - 108px);
        overflow: hidden;
        grid-template-rows: 62px 1fr;
        ${({ isExpanding }) => isExpanding && css`
            width: 100vw;
            grid-template-columns: 320px 1fr;
            grid-template-rows: 1fr;
        `}
    `,
    SearchAndFilter: styled.div<{ isExpanding: boolean }>`
        position: relative;
        display: flex;
        flex-direction: ${({ isExpanding }) => (isExpanding ? 'column' : 'row')};
        overflow-y: ${({ isExpanding }) => (isExpanding ? 'auto' : 'visible')};
        overflow-x: ${({ isExpanding }) => (isExpanding ? 'hidden' : 'visible')};
        padding: 12px 16px;
        background-color: ${({ isExpanding }) => (isExpanding ? 'white' : colors.iconOpacity(0.05))};
        border-right: 1px solid ${colors.inputBorder};
    `,
    FilterWrapper: styled.div`
        height: calc(var(--window-height) - 170px);
        padding: 16px;
        overflow: auto;
    `,
    Title: styled.div`
        font-size: 20px;
        font-weight: 700;
        color: ${colors.grayDescription};
    `,
    HighlightMySchedule: styled(Checkbox)`
        span.checkbox-label {
            white-space: nowrap;
        }
    `,
}

interface Props {
    isExpanding: boolean
    programSpaces: ProgramSpace[]
}

const Filters = ({
    showFavorites,
    isExpanding,
    setShowFavorites,
}: {
    showFavorites: boolean
    isExpanding: boolean
    setShowFavorites: (v) => void
}) => {
    const dispatch = useDispatch()
    const size = useSelector(sizeSelector)

    return (
        <>
            <S.HighlightMySchedule
                checked={showFavorites}
                onChange={() => setShowFavorites((v) => !v)}
                label={_('program_calendar.highlight_my_schedule')}
                className="mt-4"
            />
            <ProgramFilters />
            {isExpanding && (
                <div className="d-flex mt-4">
                    <CalendarSizes
                        selectedSize={size.horizontal}
                        onSelect={(value) => dispatch(changeHorizontalSize(value))}
                        direction="horizontal"
                        sizes={['S', 'M', 'L']}
                        className="mr-2"
                    />
                    <CalendarSizes
                        selectedSize={size.vertical}
                        onSelect={(value) => dispatch(changeVerticalSize(value))}
                        direction="vertical"
                        sizes={['S', 'M', 'L']}
                    />
                </div>
            )}
        </>
    )
}

const CalendarOverview = ({ isExpanding, programSpaces }: Props) => {
    const dispatch = useDispatch()
    const search = useSelector(searchSelector)
    const [showFavorites, setShowFavorites] = useState(false)

    return (
        <S.CalenderWrapper isExpanding={isExpanding}>
            <S.SearchAndFilter isExpanding={isExpanding}>
                <SearchInput
                    value={search}
                    onSearch={(value) => dispatch(updateSearch(value))}
                    className="mr-2"
                    debounce={750}
                />
                {isExpanding ? (
                    <Filters
                        setShowFavorites={setShowFavorites}
                        showFavorites={showFavorites}
                        isExpanding={isExpanding}
                    />
                ) : (
                    <DropdownFilter>
                        <S.FilterWrapper>
                            <S.Title>Filters</S.Title>
                            <Filters
                                setShowFavorites={setShowFavorites}
                                showFavorites={showFavorites}
                                isExpanding={isExpanding}
                            />
                        </S.FilterWrapper>
                    </DropdownFilter>
                )}
            </S.SearchAndFilter>
            {search ? (
                <EventSearch isExpanding={isExpanding} />
            ) : (
                <Calendar programSpaces={programSpaces} showFavorites={showFavorites} />
            )}
        </S.CalenderWrapper>
    )
}

export default CalendarOverview
