import { ActionCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { Space, SpaceType } from '../../types/swagger'
import preloadImage from '../preloadImage'
import { fetchMap } from '../../features/imagemap/redux/getMapSlice'
import { fetchPresentationArea } from '../../features/presentation/presentationSlice'
import { fetchBoothDetail } from '../../features/exhibition/boothDetailSlice'
import { fetchNetworkingArea } from '../../features/networking/networkingSlice'
import { fetchCinema } from '../../features/cinema/cinemaSlice'
import { fetchMeetingSpace } from '../../features/meetings/meetingSpaceSlice'
import { fetchPosterSpace } from '../../features/posters/posterSlice'

export const spaceTypeToFetch: Record<SpaceType, ActionCreator<any>> = {
    map: (space: Space) => fetchMap({ mapSlug: space.slug, eventSlug: space.event_slug }),
    presentation: (space: Space) => fetchPresentationArea({
        eventSlug: space.event_slug, presentationSlug: space.slug,
    }),
    booth: (space: Space) => fetchBoothDetail({ eventSlug: space.event_slug, boothSlug: space.slug }),
    networking: (space: Space) => fetchNetworkingArea({
        eventSlug: space.event_slug, networkingSlug: space.slug,
    }),
    cinema: (space: Space) => fetchCinema({
        eventSlug: space.event_slug, cinemaSlug: space.slug,
    }),
    jobs: () => ({ type: '...todo...' }),
    posters: (space: Space) => fetchPosterSpace({
        eventSlug: space.event_slug, posterSlug: space.slug,
    }),
    chat_roulette: () => ({ type: '...todo...' }),
    meeting: (space: Space) => fetchMeetingSpace({
        eventSlug: space.event_slug, meetingSlug: space.slug,
    }),
    embedded: () => ({ type: '...todo...' }),
}

export default createAsyncThunk(
    'PREFETCH_SPACE',
    async ({ space }: { space: Space }, thunkAPI) => {
        if (space.image) {
            preloadImage(space.image)
        }
        const prefetch = spaceTypeToFetch[space.type]
        thunkAPI.dispatch(prefetch(space))
    },
)
