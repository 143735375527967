import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import GeneralInfoWithThumbnail, { GeneralInfoProps } from '../../../components/GeneralInfoWithThumbnail'

import getPathForSpace from '../../../utils/spaces/getPathForSpace'
import useParams from '../../../hooks/useParams'
import Bookmark from '../../goodiebag/Bookmark'
import { GoodiebagType } from '../../goodiebag/types'
import LogoPlaceholder from '../../../assets/logo-placeholder.webp'

const S = {
    Link: styled(Link)`
        &:hover {
            text-decoration: none;
        }
    `,
}

const ExhibitorItem = ({
    id, name, description, image, slug,
}: GeneralInfoProps & { id: number, slug: string }) => {
    const params = useParams()
    const itemRef = useRef<HTMLAnchorElement>()
    const isActive = params.boothSlug === slug
    if (isActive && itemRef.current) {
        // If item is not in current view, scroll to the item in Sidebar.
        if (itemRef.current.getBoundingClientRect().top > window.innerHeight
            || itemRef.current.getBoundingClientRect().top < 0
        ) {
            itemRef.current.scrollIntoView()
        }
    }

    return params.mapSlug ? (
        <S.Link to={getPathForSpace({ slug, type: 'booth' }, params)} ref={itemRef}>
            <GeneralInfoWithThumbnail
                name={name}
                description={description}
                image={image || LogoPlaceholder}
                active={isActive}
            >
                <Bookmark item={{ id, type: GoodiebagType.Booth }} style={{ margin: '0 10px 0 5px', pointerEvents: 'none' }} size={24} />
            </GeneralInfoWithThumbnail>
        </S.Link>
    ) : (
        <GeneralInfoWithThumbnail
            name={name}
            description={description}
            image={image || LogoPlaceholder}
            active={isActive}
        >
            <Bookmark item={{ id, type: GoodiebagType.Booth }} style={{ margin: '0 10px 0 5px', pointerEvents: 'none' }} size={24} />
        </GeneralInfoWithThumbnail>
    )
}

export default ExhibitorItem
