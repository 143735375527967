import React from 'react'
import { useDispatch } from 'react-redux'

import { PayloadAction } from '@reduxjs/toolkit'
import { CancelledVideocallRequest, RequestVideoAccess, WS } from '../videocallSlice'
import CustomPopover from '../../../components/CustomPopover'
import { _ } from '../../../utils/localization'
import Get from '../../../components/Get'
import sendToUser from '../../websocket/sendToUser'
import { useReduxBus } from '../../../utils/reduxmiddleware/reduxEventBus'
import AttendeeInfoWithTags from '../../networking/components/AttendeeInfoWithTags'
import { VirtualAttendee } from '../../../types/swagger'
import useSlug from '../../../hooks/useSlug'

interface Props {
    accessRequest: RequestVideoAccess
    close: () => void
}

const ReceiveAccessRequest = ({ accessRequest, close }: Props) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const reply = (accepted: boolean) => {
        dispatch(sendToUser({
            receiverId: accessRequest.user_requesting_access,
            event: 'replied_videocall_access',
            data: {
                accepted,
                videocallId: accessRequest.videocall_id,
            },
        }))
        close()
    }

    useReduxBus((action: PayloadAction<CancelledVideocallRequest>) => {
        if (action.payload.user_cancelling_access_request === accessRequest.user_requesting_access) {
            close()
        }
    }, WS.accessRequestCanceled.type)

    // TODO use attendee list instead of user endpoint here
    return (
        <Get url={`/events/${eventSlug}/virtual_attendees/${accessRequest.user_requesting_access}/`}>
            {(requestUser: VirtualAttendee) => (
                <CustomPopover
                    title={_('videocall.receive_access_request.title')}
                    cancelButton={_('button.decline')}
                    confirmButton={_('button.accept')}
                    onCancel={() => reply(false)}
                    onConfirm={() => reply(true)}
                    cancelIcon="close"
                    confirmIcon="check"
                >
                    <AttendeeInfoWithTags attendee={{...requestUser, is_online: true}} />
                </CustomPopover>
            )}
        </Get>
    )
}

export default ReceiveAccessRequest
