import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AddModal, Modal } from './types'

let _nextId = 0
let _nextZIndex = 99

const modalSlice = createSlice({
    name: 'modal',
    initialState: {} as Record<number, Modal>,
    reducers: {
        addModal: (state, action: PayloadAction<AddModal>) => {
            const id = ++_nextId
            state[id] = { ...action.payload, id, zIndex: ++_nextZIndex }
        },
        closeModal: (state, action) => {
            delete state[action.payload]
        },
        closeAllModal: () => ({}),
    },
})

export const addModal = modalSlice.actions.addModal
export const closeModal = modalSlice.actions.closeModal
export const closeAllModal = modalSlice.actions.closeAllModal
export default modalSlice.reducer
