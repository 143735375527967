import React from 'react'

// This hook allows you to detect clicks outside of a specified element
// In the example below we use it to close a modal when any element outside of the modal is clicked.
// See in BoothDetail to get how to use, this hook helps to hide submenu when click outside of it.

const useClickOutSide = (ref, callback) => {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback()
        }
    }

    React.useEffect(() => {
        // In React 17, React will no longer attach event handlers at the document level under the hood.
        // Instead, it will attach them to the root DOM container into which your React tree is rendered.
        document.getElementById('root').addEventListener('click', handleClick)
        return () => {
            document.getElementById('root').removeEventListener('click', handleClick)
        }
    })
}
export default useClickOutSide
