import React, { CSSProperties } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import colors from '../../../constants/colors'
import { closeSidebar as closeAttendeeSidebar } from '../../../features/attendees/attendeesSlice'
import { closeCalendar } from '../../../features/calendar/calendarSlice'
import { changeShowTooltip } from '../../../features/events/fullScreenSlice'
import { isShowSidebarSelector, toggleSidebar } from '../../../features/performer/performerSlice'
import Icon from '../../Icon'
import { _ } from '../../../utils/localization'

const S = {
    Wrapper: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        border-left: 1px solid ${colors.navDivider};
        cursor: pointer;
        & > span.label {
            margin-left: 10px;
            font-size: 14px;
            color: ${colors.grayTitle};
        }
        
    `
}

const PerformerSidebarToggle = ({
    style, showLabel
}: {style?: CSSProperties, showLabel?: boolean}) => {
    const dispatch = useDispatch()
    const showSideBar = useSelector(isShowSidebarSelector)
    const handleTogglePerformerSidebar = () => {
        dispatch(changeShowTooltip(false))
        dispatch(closeAttendeeSidebar())
        dispatch(closeCalendar())

        dispatch(toggleSidebar())
    }

    return (
        <S.Wrapper style={style} onClick={handleTogglePerformerSidebar}>
            <Icon
                icon="performer"
                isActive={showSideBar}
                size={30}
                style={{ margin: '0 -6px -11px 0' }}
            />
            {showLabel && <span className="label">{_('nav.performer_icon.helper_text')}</span>}
        </S.Wrapper>
    )
}

export default PerformerSidebarToggle
