import React, { useState } from 'react'
import BSModal from 'react-bootstrap/Modal'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { Dialog, DialogContent, DialogTitle, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { closeModal as dispatchCloseModal } from './modalSlice'
import { Modal as ModalType } from './types'
import { RootState } from '../../store'
import colors from '../../constants/colors'
import StyledIcon from '../../components/Icon'
import useResponsive from '../../hooks/useResponsive'

const Transition = React.forwardRef((
    props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
    ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />)

interface ModalProps extends ModalType {
    closeModal: (id: number) => void;
}

const S = {
    BSModal: styled(BSModal)<{$backgroundColor?: string, $expand?: boolean}>`
        background-color: ${({ $backgroundColor }) => $backgroundColor};
        ${({$expand}) => $expand && css`
            height: 100% !important;
        `}
    `,
    Header: styled(BSModal.Header)`
        position: relative;
        padding: 16px;
    `,
    NewHeader: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${colors.divider};
        padding: 10px;
        font-size: 16px;
        font-weight: 400;
    `,
    HeaderInner: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        & + button {
            margin-top: -6px!important;
            font-size: 37px;
            font-weight: 400;
            &:focus {
                outline: none;
            }
        }
    `,
    Title: styled(BSModal.Title)`
        margin-top: auto;
        margin-bottom: auto;
        font-size: 20px;
        line-height: unset;
        color: ${colors.gray6};
        font-weight: bold;
    `,
    CloseModalIcon: styled.div`
        position: absolute;
        z-index: 1;
        top: 32px;
        right: 32px;
        cursor: pointer;
        @media (max-width: 932px) {
            top: 16px;
            right: 16px;
        }
    `,
    Close: styled.div`
        font-size: 14px;
        color: ${colors.midBlue};
        cursor: pointer;
    `,
    ExpandIcon: styled.div`
        /* position: absolute;
        top: 0;
        left: 50%; */
    `
}

const renderHeaderComponent = (Component) => <Component />

const NewModal = ({
    id,
    header,
    closeModal,
    Component,
    size = 'lg',
    callback = () => undefined,
    padding = 16,
    backdrop = true,
    asDrawer
}: ModalProps) => {
    const {isMobile} = useResponsive()
    const [expand, setExpand] = useState(false)
    const [show, setShow] = React.useState(true)
    const close = (reason?: string) => {
        if (!backdrop && ['backdropClick', 'escapeKeyDown'].includes(reason)) {
            return
        }
        setShow(false)
        setTimeout(() => {
            closeModal(id)
            callback()
        }, 200)
    }

    return (
        <Dialog
            open={show}
            onClose={(e, reason) => close(reason)}
            fullScreen={isMobile}
            maxWidth={size}
            fullWidth
            TransitionComponent={Transition}
            sx={{zIndex: 1050}}
        >
            <DialogTitle id="alert-dialog-title" sx={{padding}}>
                {
                    header
                    && (
                        <S.NewHeader>
                            {asDrawer && (
                                <S.ExpandIcon onClick={() => setExpand(!expand)}>
                                    <StyledIcon icon={expand ? 'collapseVertical' : 'expandVertical'} />
                                </S.ExpandIcon>
                            )}
                            <S.HeaderInner className="header">
                                {
                                    typeof header === 'string'
                                        ? <S.Title>{header}</S.Title>
                                        : renderHeaderComponent(header)
                                }
                            </S.HeaderInner>
                            {isMobile ? (
                                <S.Close onClick={() => close()}>Close</S.Close>
                            ) : (
                                <StyledIcon icon="close" size={26} onClick={() => close()} />
                            )}
                        </S.NewHeader>
                    )
                }
            </DialogTitle>
            <DialogContent sx={{padding}}>
                <Component close={close} />
            </DialogContent>
        </Dialog>
    )
}

const Modal = ({
    id,
    header,
    closeModal,
    Component,
    size = 'lg',
    callback = () => undefined,
    padding = 16,
    showCloseIcon,
    backdrop = true,
    backgroundColor = 'rgba(0, 0, 0, 0.1)',
    asDrawer
}: ModalProps) => {
    const {isMobile} = useResponsive()
    const [expand, setExpand] = useState(false)
    const [show, setShow] = React.useState(true)
    const close = (withAnimation = true) => {
        setShow(false)
        setTimeout(() => {
            closeModal(id)
            callback()
        }, withAnimation ? 250 : 0)
    }
    return !Component ? null : (
        <>
            <S.BSModal
                show={show}
                onHide={close}
                centered
                size={size}
                backdrop={!asDrawer && backdrop}
                $backgroundColor={backgroundColor}
                $expand={expand}
                className={`${isMobile ? 'fullscreen-modal' : ''} ${asDrawer ? 'as-drawer' : ''}`}
            >
                {
                    header
                    && (
                        <S.Header closeButton={!isMobile}>
                            {asDrawer && (
                                <S.ExpandIcon onClick={() => setExpand(!expand)}>
                                    <StyledIcon icon={expand ? 'collapseVertical' : 'expandVertical'} />
                                </S.ExpandIcon>
                            )}
                            <S.HeaderInner className="header">
                                {
                                    typeof header === 'string'
                                        ? <S.Title>{header}</S.Title>
                                        : renderHeaderComponent(header)
                                }
                            </S.HeaderInner>
                            {isMobile && <S.Close onClick={() => close(true)}>Close</S.Close>}
                        </S.Header>
                    )
                }
                {showCloseIcon && show && (
                    <S.CloseModalIcon>
                        <StyledIcon
                            icon="close"
                            size={32}
                            onClick={() => close(true)}
                        />
                    </S.CloseModalIcon>
                )}
                <BSModal.Body style={{ padding }}>
                    <Component close={close} />
                </BSModal.Body>
            </S.BSModal>
        </>
    )
}

const ModalContainer = () => {
    const modals = useSelector((state: RootState) => state.modals)
    const dispatch = useDispatch()
    const keys = Object.keys(modals)
    return keys.length === 0 ? null : (
        <div>
            { keys.map((modalId) => {
                if (modals[modalId]?.useNewModal) {
                    return (
                        <NewModal
                            {...modals[modalId]}
                            closeModal={(id: number) => dispatch(dispatchCloseModal(id))}
                            key={modalId}
                        />
                    )
                }
                return (
                    <Modal
                        {...modals[modalId]}
                        closeModal={(id: number) => dispatch(dispatchCloseModal(id))}
                        key={modalId}
                    />
                )
            })}
        </div>
    )
}

export { S }
export default ModalContainer
