import React, { useEffect, useState } from 'react'
import {
    add, format, isBefore, isSameDay, sub
} from 'date-fns'
import chunk from 'lodash/chunk'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import DatePicker from '../../../components/DatePicker/DatePicker'
import Icon from '../../../components/Icon'
import IconButton from '../../../components/IconButton'
import colors from '../../../constants/colors'
import { _ } from '../../../utils/localization'
import { eventPreviewSelector } from '../../auth/eventPreviewSlice'
import { getAvailableDates } from '../utils'
import { dateOfAppointmentScheduleSelector, updateScheduleDate } from '../appointmentSchedulesSlice'
import Divider from '../../../components/Divider'
import { SimpleAppointmentSchedule } from '../../../types'

const S = {
    Wrapper: styled.div`
    `,
    HeaderWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    Header: styled.h1`
        font-size: 16px;
        font-weight: 500;
        color: ${colors.darkTitle};
    `,
    DateFilterWrapper: styled.div`
        display: flex;
        align-items: center;
    `,
    DatePicker: styled(DatePicker)`
        position: relative;
        z-index: 10;
    `,
    Dates: styled.div`
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
    `,
    Date: styled.div<{scheduled?: boolean, isActive?: boolean}>`
        width: 50px;
        height: 56px;
        border: 1px solid ${colors.iconOpacity(0.25)};
        border-radius: 5px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
        color: ${colors.icon};
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        user-select: none;
        cursor: pointer;
        
        &:hover {
            background-color: ${colors.primaryOpacity(0.05)};
        }
        
        ${({ scheduled }) => scheduled && css`
            border: 1px dashed ${colors.primary};
            background-color: ${colors.gray1};
        `}

        ${({ isActive }) => isActive && css`
            border: 1px solid ${colors.primary};
            background-color: ${colors.primary} !important;
            color: white;
        `}
    `,
    DateOfMonth: styled.div`
        font-size: 20px;
    `,
    DateOfWeek: styled.div`
        font-size: 12px;
    `
}

interface DateCardProps {
    date: Date
    scheduled?: boolean
    isActive?: boolean
    onClick?: () => void
}

const DateCard = ({
    date, scheduled, isActive, onClick
}: DateCardProps) => {
    const [dateOfMonth, dateOfWeek] = format(new Date(date), 'dd EEE').split(' ')
    return (
        <S.Date scheduled={scheduled} isActive={isActive} onClick={onClick}>
            <S.DateOfMonth>{dateOfMonth}</S.DateOfMonth>
            <S.DateOfWeek>{dateOfWeek}</S.DateOfWeek>
        </S.Date>
    )
}

const AvailableDates = ({ previousSchedule }: {previousSchedule?: SimpleAppointmentSchedule}) => {
    const dispatch = useDispatch()
    const dateOfAppointmentSchedule = useSelector(dateOfAppointmentScheduleSelector)
    const eventPreview = useSelector(eventPreviewSelector)
    const [blockIndex, setBlockIndex] = useState(0)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const availableDates = eventPreview ? getAvailableDates(
        new Date(eventPreview.start_date),
        new Date(eventPreview.end_date)
    ) : []
    const chunkedAvailableDates: Date[][] = chunk(availableDates, 10)

    useEffect(() => {
        dispatch(updateScheduleDate(selectedDate))
        chunkedAvailableDates.forEach((group, i) => {
            if (group.find((e) => isSameDay(e, selectedDate))) {
                setBlockIndex(i)
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    useEffect(() => {
        if (eventPreview?.start_date && isBefore(new Date(), new Date(eventPreview.start_date))) {
            setSelectedDate(new Date(eventPreview.start_date))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventPreview?.start_date])

    const goToPreviousTenDays = () => {
        setBlockIndex(blockIndex - 1)
        setSelectedDate(sub(selectedDate, {days: 10}))
    }

    const goToNextTenDays = () => {
        setBlockIndex(blockIndex + 1)
        setSelectedDate(add(selectedDate, {days: 10}))
    }

    return (
        <S.Wrapper>
            <Divider className="mt-4" />

            <S.HeaderWrapper>
                <S.Header>{_('appointment_schedule.find_convenient_date')}</S.Header>
                <S.DateFilterWrapper>
                    <S.DatePicker
                        value={selectedDate}
                        onChange={setSelectedDate}
                        minDate={new Date()}
                        maxDate={new Date(eventPreview?.end_date)}
                    />
                    <IconButton
                        disabled={blockIndex === 0}
                        onClick={goToPreviousTenDays}
                    >
                        <Icon icon="chevronDown" style={{ transform: 'rotate(90deg)' }} />
                    </IconButton>
                    <IconButton
                        disabled={blockIndex === chunkedAvailableDates.length - 1}
                        onClick={goToNextTenDays}
                    >
                        <Icon icon="chevronDown" style={{ transform: 'rotate(-90deg)' }} />
                    </IconButton>
                </S.DateFilterWrapper>
            </S.HeaderWrapper>
            <S.Dates>
                {chunkedAvailableDates[blockIndex]?.map((date, i) => (
                    <DateCard
                        date={date}
                        key={i}
                        scheduled={previousSchedule && isSameDay(date, new Date(previousSchedule.start_date))}
                        isActive={isSameDay(date, dateOfAppointmentSchedule)}
                        onClick={() => dispatch(updateScheduleDate(date))}
                    />
                ))}
            </S.Dates>
        </S.Wrapper>
    )
}

export default AvailableDates
