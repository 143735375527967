import AttendeesGridList from './AttendeesGridList'
import { createEventRoute } from '../../utils/routes'

export default {
    attendees: createEventRoute({
        title: 'Attendees',
        path: '/attendees',
        exact: true,
        component: AttendeesGridList,
    }),
}
