import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import ConfirmModal from '../../components/ConfirmModal'
import colors from '../../constants/colors'
import useFirstRender from '../../hooks/useFirstRender'
import { _ } from '../../utils/localization'
import { addModal } from '../modals/modalSlice'
import { showNotification } from '../notifications'
import { closeNotification } from '../notifications/notificationsSlice'
import CompatibilityTestModal from './CompatibilityTestModal'

const S = {
    Actions: styled.div`
        display: flex;
        align-items: center;
        margin-left: 16px;
        font-size: 16px;
        font-weight: 500;
        color: ${colors.icon};
        text-transform: uppercase;
        cursor: pointer;
    `,
    Action: styled.span`
        cursor: pointer;
        white-space: nowrap;
        color: ${colors.icon};
    `,
    Divider: styled.div`
        width: 1px;
        height: 20px;
        margin: 0 8px;
        background-color: ${colors.iconOpacity(0.2)};
    `,
    ReadMore: styled.span`
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        color: ${colors.icon};
        &:hover {
            color: ${colors.dark}
        }
    `
}

const BrowserSupportToast = () => {
    const dispatch = useDispatch()
    const showTestModal = () => {
        dispatch(addModal({
            Component: CompatibilityTestModal,
            padding: 20
        }))
    }

    const showReadMoreModal = useCallback((e) => {
        e.preventDefault()
        dispatch(dispatch(closeNotification(999)))
        dispatch(addModal({
            Component: ({ close }: {close: () => null}) => (
                <ConfirmModal
                    header={_('accreditation_warning.modal_header')}
                    body={_('accreditation_warning.modal_body')}
                    cancelText={_('accreditation_warning.understand_button')}
                    close={close}
                />
            ),
            padding: 30
        }))
    }, [dispatch])

    useEffect(() => {
        setTimeout(() => {
            // Wait for 3s to make sure Matomo initialized successfully
            // @ts-ignore
            if (!window?.Matomo?.initialized) {
                dispatch(showNotification({
                    type: 'warning',
                    body: _('user_setting.accreditation_warning'),
                    displayDuration: 300000,
                    id: 999,
                    render: () => (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <S.ReadMore
                            className="ml-4"
                            onClick={showReadMoreModal}
                        >
                            {_('user_setting.accreditation_warning_read_more')}
                        </S.ReadMore>
                    )
                }))
            }
        }, 3000)
    }, [dispatch, showReadMoreModal])

    const notShowAgain = localStorage.getItem('browserSupportTest.dontShowModalAgain')
    const isFirstRender = useFirstRender()
    if (!notShowAgain && isFirstRender) {
        dispatch(showNotification({
            type: 'info',
            displayDuration: 300000,
            id: 9999,
            body: _('user_setting.checking_description'),
            render: (setShow) => (
                <S.Actions>
                    <S.Action
                        onClick={() => {
                            setShow(false)
                            localStorage.setItem('browserSupportTest.dontShowModalAgain', 'true')
                        }}
                    >
                        {_('user_setting.do_not_show_again')}
                    </S.Action>
                    <S.Divider />
                    <S.Action
                        onClick={() => {
                            setShow(false)
                            showTestModal()
                        }}
                        style={{color: colors.primary}}
                    >
                        {_('user_setting.start_testing')}
                    </S.Action>
                </S.Actions>
            )
        }))
    }
    return <></>
}

export default BrowserSupportToast
