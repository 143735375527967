import React from 'react'
import styled from 'styled-components'
import colors from '../constants/colors'

const S = {
    Label: styled.label<{ checked?: boolean }>`
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: ${colors.grayTitle};
        &::before {
            content: '';
            display: inline-block;
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin-right: 6px;
            border-radius: 50%;
            border: 2px solid ${({ checked }) => (checked ? colors.primary : colors.icon)};
        }
        &::after {
            position: absolute;
            top: 6px;
            left: 4px;
            display: inline-block;
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: ${colors.primary};
            opacity: ${({ checked }) => (checked ? 1 : 0)};
            transition: 0.2s;
        }
    `,
    RadioButton: styled.input`
        display: none;
    `,
}

interface Props {
    options: {value: any, label: string}[]
    isVerticalAlign?: boolean
    name: string
    value: any
    onChange: (value: any) => void
}

const RadioButton = ({
    options, isVerticalAlign, value, onChange, name
}: Props) => (
    <div className={isVerticalAlign ? '' : 'd-flex'}>
        {options.map((option, index) => (
            <div className="mr-4" key={index}>
                <S.Label
                    htmlFor={option.value}
                    checked={value === option.value}
                >
                    <S.RadioButton
                        name={name}
                        type="radio"
                        value={option.value}
                        checked={value === option.value}
                        onChange={onChange}
                        id={option.value}
                    />
                    {option.label}
                </S.Label>
            </div>
        ))}
    </div>
)

export default RadioButton
