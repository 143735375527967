import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { MapItemType, MapType } from './Map'
import getPathForSpace from '../../../utils/spaces/getPathForSpace'
import useParams from '../../../hooks/useParams'
import { _ } from '../../../utils/localization'
import SwipeHand from '../../../assets/swipe-hand.svg'
import colors from '../../../constants/colors'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import MobileFooter from '../components/MobileFooter'
import useSlug from '../../../hooks/useSlug'
import handleWidgetClick from '../handleWidgetClick'

type PointStyleProps = {
    x: number
    y: number
    mapHeight: number
    mapWidth: number
    imageHeight: number
    imageWidth: number
}

const S = {
    Wrapper: styled.div`
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
    `,
    Map: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        bottom: -20px;
        right: -20px;
        overflow: scroll;
    `,
    Point: styled.div<PointStyleProps>`
        position: absolute;
        top: ${({ y, mapHeight, imageHeight }) => (y * mapHeight) / imageHeight}px;
        left: ${({ x, mapWidth, imageWidth }) => (x * mapWidth) / imageWidth}px;
        width: 20px;
        height: 20px;
        transform: translate(-50%, -50%);
        background: white;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
        z-index: 1;
        border-radius: 50%;
        border: 1px solid #989898;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 26px;
            height: 26px;
            border-radius: 50%;
            border: 1px solid rgba(255, 255, 255, 0.5);
        }
        &::before {
            width: 32px;
            height: 32px;
            border: 1px solid rgba(255, 255, 255, 0.25);
        }
    `,
    HintWrapper: styled.div`
        position: fixed;
        top: 58px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        color: white;
        background: rgba(0, 0, 0, 0.53);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    `,
    HintIcon: styled.img``,
    HintTitle: styled.h1`
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        opacity: 0.9;
        text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
    `,
    HintDescription: styled.p`
        color: rgba(255, 255, 255, 0.75);
        font-size: 14px;
        line-height: 20px;
        opacity: 0.75;
    `,
    HintFooter: styled.h3`
        margin-top: 32px;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
        text-transform: uppercase;
    `,
    AreaTitle: styled.div`
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 52px);
        max-width: 200px;
        min-width: 60px;
        padding: 6px 8px;
        background: ${colors.darkTitle};
        border: 1px solid #ffffff;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        color: white;
        opacity: 0.9;
        font-weight: 500;
        font-size: 12px;
    `,
    ActionsFooter: styled.div`
        position: fixed;
        bottom: 0;
        left: 0;
        padding-bottom: 10px;
        width: 100vw;
        height: 42px;
        background: #343A40;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 14px;
    `,
    Action: styled.div`
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    Divider: styled.div`
        height: 20px;
        width: 0;
        border: 1px solid #F9F9F9;
        opacity: 0.5;
    `
}

const MobileMap = ({ map }: { map: MapType }) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const { width: screenWidth, height: screenHeight } = useWindowDimensions()
    const isLandscape = screenWidth > screenHeight
    const ref = useRef<HTMLDivElement>()
    const [mapDimension, setMapDimension] = useState({ width: 0, height: 0 })
    const isShownHint = localStorage.getItem(`${eventSlug}__mobile_hint`)
    const [showHint, setShowHint] = useState(false)
    const [showAreaTitle, setShowAreaTitle] = useState(false)

    const ratio = isLandscape
        ? map.image_height / map.image_width
        : map.image_width / map.image_height
    const history = useHistory()
    const params = useParams()

    useEffect(() => {
        if (ref.current) {
            isLandscape ? setMapDimension({
                width: ref.current.clientWidth,
                height: ref.current.clientWidth * ratio,
            }) : setMapDimension({
                width: ref.current.clientHeight * ratio,
                height: ref.current.clientHeight,
            })

            if (isLandscape) {
                const offsetHeight = ref.current.clientWidth * ratio - (screenHeight - 58)
                if (offsetHeight > 0) {
                    ref.current.scrollTop = offsetHeight / 2
                }
            }
        }
    }, [ratio, isLandscape, map.image_height, screenHeight])

    const hideHint = (e) => {
        e.stopPropagation()
        localStorage.setItem(`${eventSlug}__mobile_hint`, 'true')
        setShowAreaTitle(false)
        setShowHint(true)
    }

    const goToSpace = (item) => {
        history.push(
            getPathForSpace(
                {
                    type: item.space_type,
                    slug: item.space_slug,
                },
                params
            )
        )
    }

    const handleClick = (e, item: MapItemType) => {
        e.stopPropagation()
        if (item.space) {
            return goToSpace(item)
        }
        return dispatch(handleWidgetClick(item))
    }

    return (
        <S.Wrapper>
            <S.Map ref={ref} onClick={() => setShowAreaTitle(!showAreaTitle)}>
                {screenHeight > screenWidth ? (
                    <img
                        src={map.meta.image}
                        alt="map"
                        width={`${ratio * ref.current?.clientHeight}px`}
                        height="100%"
                    />
                ) : (
                    <img
                        src={map.meta.image}
                        alt="map"
                        height={`${ratio * ref.current?.clientWidth}px`}
                        width="100%"
                    />
                )}
                {(!isShownHint && !showHint) ? (
                    <S.HintWrapper onClick={hideHint}>
                        <S.HintIcon src={SwipeHand} />
                        <S.HintTitle>
                            {_('map_mobile.swipe_to_navigate')}
                        </S.HintTitle>
                        <S.HintDescription>
                            {_('map_mobile.swipe_to_navigate_description')}
                        </S.HintDescription>
                        <S.HintFooter>
                            {_('map_mobile.tap_to_continue')}
                        </S.HintFooter>
                    </S.HintWrapper>
                ) : (
                    map.items
                        .filter((item) => item.for_mobile)
                        .map((item) => (
                            <S.Point
                                key={item.id}
                                x={item.coords[0]}
                                y={item.coords[1]}
                                mapHeight={mapDimension.height}
                                mapWidth={mapDimension.width}
                                imageHeight={map.image_height}
                                imageWidth={map.image_width}
                                onClick={(e) => handleClick(e, item)}
                            >
                                {showAreaTitle && (
                                    <S.AreaTitle className="text-cut">
                                        {item.space_name || item.name}
                                    </S.AreaTitle>
                                )}
                            </S.Point>
                        ))
                )}
            </S.Map>
            <MobileFooter map={map} />
        </S.Wrapper>
    )
}

export default MobileMap
