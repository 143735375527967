import React, { useState } from 'react'
import {
    Alert, Button, Form, Modal
} from 'react-bootstrap'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { MeetingRecording } from '../../../types/__generated_swagger'
import { _ } from '../../../utils/localization'
import { toLocalDateFormat, toLocalTimeFormat } from '../../../utils/datetime'
import { eventTimeFormatSelector } from '../../auth/eventPreviewSlice'
import colors from '../../../constants/colors'
import { acceptRecording } from '../meetingRoomSlice'
import { MeetingItem } from '../types'
import useSlug from '../../../hooks/useSlug'
import Icon from '../../../components/Icon'
import { closeAllModal } from '../../modals/modalSlice'
import { showNotification } from '../../notifications'

const S = {
    Wrapper: styled.div`
    `,
    Header: styled(Modal.Header)`
      display: flex;
      align-items: center;
      border-bottom: 0;
    `,
    Title: styled.h3`
      font-size: 30px;
      font-weight: 500;
      line-height: 35px;
      letter-spacing: 0;
      text-align: left;
    `,
    Alert: styled(Alert)`
      display: flex;
      justify-content: flex-start;
      gap: 10px;
    `,
    AlertIcon: styled(Icon)`
      flex-grow: 0;
    `,
    FormLabel: styled(Form.Label)`
      color: ${colors.grayTitle};
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0;
      text-align: left;
    `,
    Actions: styled.div`
        display: flex;
        justify-content: flex-end;
    `
}

interface RecordingApprovalModalProps {
    recording: MeetingRecording
    meeting: MeetingItem
    close: () => void
}

const RecordingApprovalModal = ({recording, meeting, close}: RecordingApprovalModalProps) => {
    const timeFormat = useSelector(eventTimeFormatSelector)
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const meetingSpaceSlug = useSlug('meetingSlug')
    const [hostName, setHostName] = useState('')

    const sendAcceptRecording = () => {
        dispatch(acceptRecording({
            eventSlug, meetingSpaceSlug, meetingSlug: meeting.slug, recordingId: recording.id, hostName
        }))
        dispatch(closeAllModal())
        dispatch(
            showNotification({
                body: _('meetingspace.recordings_modal.upload_started'),
                type: 'success',
            })
        )
    }

    return (
        <S.Wrapper>
            <S.Header closeButton>
                <S.Title>{_('meetingspace.approve_recordings_modal.title')}</S.Title>
            </S.Header>

            <S.Alert variant="info">
                <S.AlertIcon icon="info" color={colors.blue} />
                <p>
                    {_('meetingspace.approve_recordings_modal.info_text')}
                </p>
            </S.Alert>
            <Form>
                <Form.Group className="mb-3" controlId="name">
                    <Form.Label>{_('meetingspace.recordings_modal.name')}</Form.Label>
                    <Form.Control
                        type="text"
                        value={hostName}
                        onChange={(e) => setHostName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="session">
                    <Form.Label>{_('meetingspace.recordings_modal.session')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={`${meeting.title} ${toLocalDateFormat(meeting.starts_at)} - ${toLocalTimeFormat(meeting.starts_at, timeFormat)}`}
                        disabled
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="date">
                    <Form.Label>{_('meetingspace.recordings_modal.date')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={`${toLocalDateFormat(recording.created)} - ${toLocalTimeFormat(recording.created, timeFormat)}`}
                        disabled
                    />
                </Form.Group>
                <S.Actions>
                    <Button
                        size="sm"
                        variant="light"
                        style={{ border: colors.outlineButton, padding: '5px 20px' }}
                        onClick={close}
                    >
                        {_('button.cancel')}
                    </Button>
                    <Button
                        variant="primary"
                        disabled={!hostName}
                        onClick={sendAcceptRecording}
                    >
                        {_('meetingspace.recordings_modal.send_button')}
                    </Button>
                </S.Actions>
            </Form>
        </S.Wrapper>
    )
}

export default RecordingApprovalModal
