import { format } from 'date-fns'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'
import CardContainer from '../../../components/CardContainer'
import Get from '../../../components/Get'
import Icon from '../../../components/Icon'
import colors from '../../../constants/colors'
import useSlug from '../../../hooks/useSlug'
import { ListMeetingListItemsResponse } from '../../../types/swagger'
import { toLocalTimeFormat } from '../../../utils/datetime'
import { _ } from '../../../utils/localization'
import { eventTimeFormatSelector } from '../../auth/eventPreviewSlice'
import showMeetingInfoModal from '../components/showMeetingInfoModal'
import PageContainer from '../../../components/PageContainer'

const S = {
    PageDescription: styled.div`
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 30px;
        padding: 12px;
        background-color: ${colors.primaryOpacity(0.05)};
        font-size: 14px;
        color: ${colors.darkTitle};
        border-radius: 5px;
    `,
    CardContainer: styled(CardContainer)`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        align-items: center;
        margin-bottom: 10px;
        padding: 8px 12px;
        font-size: 12px;
        cursor: pointer;
    `,
    Info: styled.div`
    `,
    Title: styled.p`
        margin-bottom: 4px;
        color: ${colors.grayDescription};
    `,
    SpaceName: styled.p`
        margin-bottom: 0;
        color: ${colors.grayDescription};
        opacity: 0.5;
    `,
    Date: styled.div`
        color: ${colors.icon};
    `
}

const AllMeetings = () => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const timeFormat = useSelector(eventTimeFormatSelector)

    return (
        <PageContainer>
            <S.PageDescription>
                <Icon className="mb-2px mr-2" icon="info" size={18} color={colors.primaryOpacity(0.75)} />
                {_('my_presentations.page_description')}
            </S.PageDescription>
            <Get url={`/events/${eventSlug}/upcoming_meetings/`}>
                {(resp: ListMeetingListItemsResponse) => (
                    <>
                        {resp.results.length ? sortBy(resp.results, 'starts_at').map((meeting) => (
                            <S.CardContainer
                                key={meeting.id}
                                onClick={() => dispatch(showMeetingInfoModal(meeting))}
                            >
                                <S.Info>
                                    <S.Title>
                                        {meeting.title}
                                    </S.Title>
                                    <S.SpaceName>
                                        {meeting?.space?.name}
                                    </S.SpaceName>
                                </S.Info>
                                <S.Date>
                                    <span>{format(new Date(meeting.starts_at), 'LLL dd, yyyy')}</span>
                                    <span className="mx-1">-</span>
                                    <span>{toLocalTimeFormat(meeting.starts_at, timeFormat)}</span>
                                    <span className="mx-1">-</span>
                                    <span>{toLocalTimeFormat(meeting.ends_at, timeFormat)}</span>
                                </S.Date>
                            </S.CardContainer>
                        )) : (
                            <div className="title">{_('my_presentations.no_data_available')}</div>
                        )}
                    </>
                )}
            </Get>
        </PageContainer>
    )
}

export default AllMeetings
