import {
    AsyncThunk,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RequestState } from './api'

export interface RequestMapAction<T, ID> {
    identifier: ID
    data: T
}

const createRequestMapSlice = <T, ID extends keyof any>(
    thunk: AsyncThunk<RequestMapAction<T, ID>, any, any>, name: string,
) => createSlice({
        name,
        initialState: {} as Record<any, RequestState<T>>,
        reducers: {
            startLoading: (state, { payload }: PayloadAction<ID>) => {
                if (!(payload in state)) {
                    state[payload] = {
                        status: 'loading',
                        data: null,
                    }
                }
            },
            emptyData: (state, { payload }: PayloadAction<ID>) => {
                delete state[payload]
            },
        },
        extraReducers: (builder) => {
            builder.addCase(thunk.fulfilled, (state, { payload }) => {
                // @ts-ignore
                state[payload.identifier] = {
                    status: 'succeeded',
                    data: payload.data,
                }
            })
        },
    })

export default createRequestMapSlice
