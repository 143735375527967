import {
    combineReducers,
    createAsyncThunk,
    createSlice,
    PayloadAction,
    SerializedError
} from '@reduxjs/toolkit'
import uniq from 'lodash/uniq'
import union from 'lodash/union'
import api from '../../utils/api'
import { RootState } from '../../store'
import { RequestStatus } from '../../types'
import {
    ListMeetingListItemsResponse,
    Meeting,
    UpdateVideocallResponse
} from '../../types/swagger'
import { websocketAction } from '../../utils/redux'
import meetingSpaceSlice from './meetingSpaceSlice'
import { withCapture } from '../../utils/errorTracker'
import meetingRoomSlice from './meetingRoomSlice'
import { DateTime, toDate } from '../../utils/datetime'

interface FetchMeeting {
    eventSlug: string
    meetingSlug: string
    page?: number
}

interface CapacityUpdated {
    capacity_left: number
    meeting_id: number
}

interface HostJoinedMeeting {
    meeting_id: number
}

// todo check if updatedCall is still used and rename if it is
const WS = {
    updatedCall: websocketAction<UpdateVideocallResponse>('updated_meeting_videocall'),
    meetingCapacityUpdated: websocketAction<CapacityUpdated>('meeting_capacity_updated'),
    hostJoinedMeeting: websocketAction<HostJoinedMeeting>('host_joined_meeting')
}

interface Registered {
    meetingId: number
    attendeeId: number
    isRegistered: boolean
}

const MEETINGS_PER_PAGE = 12

const offset = (page) => (page - 1) * MEETINGS_PER_PAGE

export const fetchUpcomingMeetingList = createAsyncThunk(
    'FETCH_MEETING_LIST',
    ({
        eventSlug, meetingSlug, page
    }: FetchMeeting) => (
        api.get<ListMeetingListItemsResponse>(
            `/events/${eventSlug}/meetings/${meetingSlug}/list/?limit=${MEETINGS_PER_PAGE}&offset=${offset(page)}`
        ))
)

export const fetchLiveMeetingList = createAsyncThunk(
    'FETCH_LIVE_MEETING_LIST',
    ({
        eventSlug, meetingSlug, page,
    }: FetchMeeting) => (
        api.get<ListMeetingListItemsResponse>(
            `/events/${eventSlug}/meetings/${meetingSlug}/list/?limit=${MEETINGS_PER_PAGE}&offset=${offset(page)}&live=1`
        )
    )
)

export const fetchMyMeetingList = createAsyncThunk(
    'FETCH_MY_MEETING_LIST',
    ({
        eventSlug, meetingSlug, page,
    }: FetchMeeting) => (
        api.get<ListMeetingListItemsResponse>(
            `/events/${eventSlug}/meetings/${meetingSlug}/list/?limit=${MEETINGS_PER_PAGE}&offset=${offset(page)}&my_meetings=1`
        )
    )
)
interface RemoveOldMeetingsProps {
    datetime: DateTime
}

const createMeetingListSlice = (thunk: ReturnType<typeof createAsyncThunk>, name: string) => createSlice({
    name,
    initialState: {
        order: [] as number[],
        map: {} as Record<string, Meeting>,
        count: 0 as number,
        status: 'idle' as RequestStatus,
        error: null as SerializedError & {detail?: string, code?: string, error?: string},
    },
    reducers: {
        emptyData: (state) => {
            state.order = []
            state.map = {}
            state.count = 0
            state.error = null
            state.status = 'idle'
        },
        setRegisteredStatus: (state, { payload }: {payload: Registered}) => {
            const { meetingId, attendeeId, isRegistered } = payload
            withCapture(() => {
                if (state.map[meetingId]) {
                    if (isRegistered) {
                        state?.map[payload.meetingId]?.participating_users?.push(attendeeId)
                    } else {
                        delete state.map[meetingId].participating_users[
                            state.map[meetingId].participating_users.indexOf(attendeeId)
                        ]
                    }
                }
            })
        },
        removeOldMeetings: (state, {payload}: {payload: RemoveOldMeetingsProps}) => {
            const upcomingAndLiveMeetings = {}
            const meetings = Object.values(state.map)
            let currentCount = state.count
            // todo check if this is the most efficient way to do this
            meetings.forEach((meeting: any) => {
                if (toDate(meeting.ends_at) > payload.datetime) {
                    upcomingAndLiveMeetings[meeting.id] = meeting
                } else {
                    currentCount--
                }
            })
            state.map = upcomingAndLiveMeetings
            state.count = currentCount
        }
    },
    extraReducers: (builder) => {
        builder.addCase(thunk.fulfilled, (state, { payload }: PayloadAction<any>) => {
            const response: ListMeetingListItemsResponse = payload
            state.order = uniq([...state.order, ...payload.results.map(({ id }) => id)])
            state.status = 'succeeded'
            response.results.forEach((meeting) => {
                state.map[meeting.id] = meeting
            })
            state.count = response.count
        })
        builder.addCase(thunk.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(thunk.rejected, (state, action) => {
            state.status = 'failed'
            state.order = []
            state.map = {}
            state.error = action.payload ? action.payload : action.error
        })

        builder.addCase(WS.meetingCapacityUpdated, (state, { payload }) => {
            if (state.map[payload.meeting_id]) {
                state.map[payload.meeting_id].capacity_left = payload.capacity_left
            }
        })

        builder.addCase(WS.updatedCall, (state, { payload }: PayloadAction<UpdateVideocallResponse>) => {
            const exists = payload.meeting in state.map
            if (exists) {
                const meeting = state.map[payload.meeting]
                // Update users and make sure the primary speaker is always added
                meeting.participating_users = union(payload.users, [meeting.primary_speaker_id])
                meeting.capacity_left = meeting.capacity - meeting.participating_users.length
            }
        })
        builder.addCase(WS.hostJoinedMeeting, (state, { payload }) => {
            if (state.map[payload.meeting_id]) {
                state.map[payload.meeting_id].is_host_in_meeting = true
            }
        })
    }
})

export const setRegisterStatus = createAsyncThunk(
    'SET_REGISTER_STATUS',
    (props: Registered, thunkAPI) => {
        thunkAPI.dispatch(setUpcomingRegisteredStatus(props))
        thunkAPI.dispatch(setLiveRegisteredStatus(props))
        thunkAPI.dispatch(setMyMeetingsRegisteredStatus(props))
    }
)

export const selectUpcomingListFetchStatus = (state: RootState) => state.meeting.upcoming.status
export const fetchedUpcomingPages = (state: RootState) => Object.keys(state.meeting.upcoming.order)

export const selectLiveListFetchStatus = (state: RootState) => state.meeting.live.status
export const fetchedLivePages = (state: RootState) => Object.keys(state.meeting.live.order)

export const selectMyMeetingsListFetchStatus = (state: RootState) => state.meeting.myMeetings.status
export const fetchedMyMeetingsPages = (state: RootState) => Object.keys(state.meeting.myMeetings.order)

export const selectUpcomingMeetingList = (state: RootState) => state.meeting.upcoming.order.map(
    (id) => state.meeting.upcoming.map[id]
).filter((v) => v) as Meeting[]

export const selectLiveMeetingList = (state: RootState) => state.meeting.live.order.map(
    (id) => state.meeting.live.map[id]
).filter((v) => v) as Meeting[]

export const selectMyMeetingList = (state: RootState) => state.meeting.myMeetings.order.map(
    (id) => state.meeting.myMeetings.map[id]
).filter((v) => v) as Meeting[]

export const upcomingMeetingsCount = (state: RootState) => state.meeting.upcoming.count
export const liveMeetingsCount = (state: RootState) => state.meeting.live.count
export const myMeetingsCount = (state: RootState) => state.meeting.myMeetings.count

const upcomingMeetingsSlice = createMeetingListSlice(fetchUpcomingMeetingList, 'upcomingMeetingListSlice')
const liveMeetingsSlice = createMeetingListSlice(fetchLiveMeetingList, 'liveMeetingListSlice')
const myMeetingsSlice = createMeetingListSlice(fetchMyMeetingList, 'myMeetingListSlice')

const upcomingMeetingsReducer = upcomingMeetingsSlice.reducer
const liveMeetingsReducer = liveMeetingsSlice.reducer
const myMeetingsReducer = myMeetingsSlice.reducer

export const setUpcomingRegisteredStatus = upcomingMeetingsSlice.actions.setRegisteredStatus
export const setLiveRegisteredStatus = liveMeetingsSlice.actions.setRegisteredStatus
export const setMyMeetingsRegisteredStatus = myMeetingsSlice.actions.setRegisteredStatus

const removeOldUpcomingMeetings = upcomingMeetingsSlice.actions.removeOldMeetings
const removeOldLiveMeetings = liveMeetingsSlice.actions.removeOldMeetings
const removeOldMyMeetings = myMeetingsSlice.actions.removeOldMeetings

export const removeOldMeetings = createAsyncThunk(
    'REMOVE_OLD_MEETINGS',
    (props: RemoveOldMeetingsProps, thunkAPI) => {
        thunkAPI.dispatch(removeOldUpcomingMeetings(props))
        thunkAPI.dispatch(removeOldLiveMeetings(props))
        thunkAPI.dispatch(removeOldMyMeetings(props))
    }
)

export const emptyMeetings = createAsyncThunk(
    'EMPTY_MEETINGS',
    (__, thunkAPI) => {
        thunkAPI.dispatch(upcomingMeetingsSlice.actions.emptyData())
        thunkAPI.dispatch(liveMeetingsSlice.actions.emptyData())
        thunkAPI.dispatch(myMeetingsSlice.actions.emptyData())
    }
)

const meetingListReducer = combineReducers({
    space: meetingSpaceSlice,
    room: meetingRoomSlice,
    upcoming: upcomingMeetingsReducer,
    live: liveMeetingsReducer,
    myMeetings: myMeetingsReducer,
})

export default meetingListReducer
