import React, { useEffect, useState } from 'react'
import { components } from 'react-select'
import styled from 'styled-components'
import { Checkbox, FormControlLabel } from '@mui/material'
import SelectWithAllOption from './SelectWithAllOption'
import colors from '../constants/colors'
import useDebounceValue from '../hooks/useDebounceValue'
import { _ } from '../utils/localization'
import useFirstRender from '../hooks/useFirstRender'

const S = {
    OptionWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        & label {
            margin: 0;
            font-size: 14px;
            color: ${colors.grayDescription};
        }
    `,
    ColorIndicator: styled.div`
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        border-radius: 2px;
    `
}

const Option = (props: any) => {
    const showMinus = (
        props.value === '*' && props.getValue().length > 0 && props.getValue().length < props.options.length - 1
    )
    const getIsChecked = () => {
        if (props.label === props.options[0]?.label) {
            return props.isSelected || props.getValue().length === props.options.length - 1
        }
        return props.isSelected
    }

    return (
        <components.Option {...props}>
            <S.OptionWrapper>
                <FormControlLabel
                    label={props.label}
                    control={<Checkbox checked={getIsChecked()} indeterminate={showMinus} />}
                />
                <S.ColorIndicator style={{ background: props.data?.color || 'transparent' }} />
            </S.OptionWrapper>
        </components.Option>
    )
}

const ValueContainer = ({ children, itemText, ...props }: any) => {
    // @ts-ignore
    const selectInput = React.Children.toArray(children).find((input) => input.type.name === 'Input' || input.type.name === 'DummyInput')
    const currentValues = props.getValue()
    let toBeRendered = currentValues.length
        ? _('checkbox_filter.selected_items_with_amount', { value: currentValues.length, itemText })
        : _('checkbox_filter.no_item_selected')
    if (
        currentValues.some((val) => val.value === props.options[0]?.value)
        || currentValues?.length === props.options?.length - 1
    ) {
        toBeRendered = props.options[0]?.label
    }

    return (
        <components.ValueContainer {...props}>
            {toBeRendered}
            {selectInput}
        </components.ValueContainer>
    )
}

const MultiValue = (props: any) => {
    let labelToBeDisplayed = `${props.data.label}`
    if (props.data.value === props.options[0]?.value) {
        labelToBeDisplayed = props.options[0]?.label
    }
    return (
        <components.MultiValue {...props}>
            <div style={{ maxWidth: 50, overflow: 'hidden' }}>
                <span className="d-block text-cut">{labelToBeDisplayed}</span>
            </div>
        </components.MultiValue>
    )
}

interface Props {
    allOption?: {
        label: string
        value: string
    }
    options: any[]
    onChange: (data?: any) => void
    debounce?: number
    classNamePrefix?: string
    className?: string
    defaultValues?: any[]
    itemText?: string
}

const SelectFilter = ({
    allOption = {
        label: _('placeholder.select_all'),
        value: '*'
    },
    options,
    onChange,
    debounce = 1000,
    classNamePrefix,
    className,
    defaultValues,
    itemText = 'item',
}: Props) => {
    const [optionSelected, setOptionSelected] = useState(null)
    const [filter, setFilter] = useState([])
    const filterParam = useDebounceValue(filter, debounce)
    const firstRender = useFirstRender()

    useEffect(() => {
        if (firstRender) {
            return
        }

        onChange?.(filterParam)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterParam])

    useEffect(() => {
        if (defaultValues) {
            setOptionSelected(defaultValues)
        }
    }, [defaultValues])

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: 44,
            borderColor: colors.inputBorder,
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            height: 12,
            margin: 'auto',
            background: colors.inputBorder
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: 14,
            color: colors.grayTitle,
        }),
        option: (provided) => ({
            ...provided,
            fontSize: 14,
            backgroundColor: 'white !important',
            fontWeight: 300,
            color: colors.darkTitle,
            borderBottom: '1px solid rgb(84, 110, 122, 0.05)',
        }),
        placeholder: (provided) => ({
            ...provided,
            fontWeight: 400,
            fontSize: '14px',
            color: colors.grayTitle,
        }),
        valueContainer: (provided) => ({
            ...provided,
            maxHeight: 42,
            fontSize: 14,
            color: colors.grayTitle,
        }),
        input: () => ({
            height: 0,
            width: 0,
            minHeight: 0,
            overflow: 'hidden',
            margin: 0,
            padding: 0,
        })
    }

    const handleChange = (selected = []) => {
        setOptionSelected(selected)
        setFilter(selected.filter((e) => e.value !== allOption?.value).map((e) => e.value))
    }

    return (
        <SelectWithAllOption
            allOption={allOption}
            isClearable={false}
            options={options}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                Option,
                MultiValue,
                ValueContainer: (props) => <ValueContainer itemText={itemText} {...props} />,
            }}
            onChange={handleChange}
            allowSelectAll
            value={optionSelected}
            styles={customStyles}
            classNamePrefix={classNamePrefix}
            className={className}
        />
    )
}

export default SelectFilter
