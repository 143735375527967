import Bowser from 'bowser'

const browser = Bowser.getParser(navigator.userAgent)

const checkSupportedBrowser = () => browser.satisfies({
    chrome: '>83',
    edge: '>80',
    firefox: '>80',
    safari: '>11'
})

export default checkSupportedBrowser
