import { GoodiebagCategory } from './types'
import { _ } from '../../utils/localization'

// titles should be turned into a translatable string here and everywhere getTitle is called.
// here so i18nExtract know they are used
// when calling getTitle so they are actually translated
const categoryToTitle: Record<GoodiebagCategory, string> = {
    [GoodiebagCategory.File]: _('goodiebag.file', {}, false),
    [GoodiebagCategory.Speaker]: _('goodiebag.speaker', {}, false),
    [GoodiebagCategory.ProgramItem]: _('goodiebag.program_item', {}, false),
    [GoodiebagCategory.Attendee]: _('goodiebag.attendee', {}, false),
    [GoodiebagCategory.Booth]: _('goodiebag.exhibitor', {}, false),
    [GoodiebagCategory.Poster]: _('goodiebag.poster', {}, false),
    [GoodiebagCategory.MeetingExpert]: _('goodiebag.meeting_expert', {}, false),
    [GoodiebagCategory.GroupSession]: _('goodiebag.group_session', {}, false)
}

export const getTitle = (category: GoodiebagCategory): string => categoryToTitle[category]

export default getTitle
