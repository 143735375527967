import React from 'react'
import styled from 'styled-components'
import colors from '../constants/colors'
import Icon from './Icon'
import { IconOption } from './Icon/icons'

const S = {
    Wrapper: styled.div<{backgroundColor: string}>`
        display: flex;
        align-items: center;
        padding: 10px 12px;
        border-radius: 5px;
        background-color: ${({backgroundColor}) => backgroundColor};
    `,
    Icon: styled(Icon)`

    `,
    Text: styled.p`
        margin: 0;
        margin-left: 10px;
        font-size: 12px;
        color: ${colors.grayTitle};
    `
}

type AlertVariant = 'info' | 'warning'

const colorMapper = {
    background: {
        info: '#F9FAFF',
        warning: 'rgb(255, 244, 229)',
    },
    icon: {
        info: colors.primary,
        warning: colors.warning,
    }
}

const iconMapper: Record<AlertVariant, IconOption> = {
    info: 'info',
    warning: 'alert',
}

interface AlertProps {
    variant: 'info' | 'warning'
    text: string
    className?: string
}

const Alert = ({variant, text, className}: AlertProps) => (
    <S.Wrapper backgroundColor={colorMapper.background[variant]} className={className}>
        <S.Icon icon={iconMapper[variant]} color={colorMapper.icon[variant]} />
        <S.Text>
            {text}
        </S.Text>
    </S.Wrapper>
)

export default Alert
