import React, { useCallback, useMemo } from 'react'
import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { sub } from 'date-fns'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import styled from 'styled-components'
import useFetchBlockedSchedules from '../hooks/useFetchBlockedSchedules'
import AppointmentInCalendar from '../components/availability_management/AppointmentInCalendar'
import colors from '../../../constants/colors'
import { _ } from '../../../utils/localization'
import Loader from '../../../components/Loader'
import useSlug from '../../../hooks/useSlug'
import { currentUserSelector } from '../../auth/authSlice'
import { showErrorNotification, showNotification } from '../../notifications/notificationsSlice'
import { captureError } from '../../../utils/errorTracker'
import { setIsPageLoading } from '../../events/pageLoadingSlice'
import api from '../../../utils/api'
import { AppointmentSchedule } from '../../../types/swagger'
import { isInBetween } from '../../../utils/datetime'
import { verticalSizeSelector } from '../appointmentSchedulesSlice'
import { heightMapper } from '../utils'
import CustomToolbar from '../components/availability_management/CustomToolbar'

const DnDCalendar = withDragAndDrop(Calendar)

const S = {
    Wrapper: styled.div<{height?: number}>`
        margin-top: 10px;
        width: 100%;
        height: calc(var(--window-height) - 215px);

        .rbc-events-container {
            margin-right: 0;
        }
        .rbc-event-label {
            display: none;
        }
        .rbc-event {
            padding: 0;
            border: none;
            background-color: transparent;
        }
        .rbc-time-gutter {
            .rbc-timeslot-group {
                border-bottom: 1px solid transparent;
                .rbc-time-slot {
                    .rbc-label {
                        display: block;
                        margin-top: -9px;
                        font-size: 10px;
                        color: ${colors.icon};
                    }
                }
            }
        }
        .rbc-time-header-content {
            background-color: ${colors.gray1} !important;
            .rbc-header {
                border-bottom: none;
            }
            .rbc-allday-cell {
                display: none;
            }
            .rbc-time-header-cell {
                .rbc-header span[role=columnheader] {
                    font-size: 12px;
                    font-weight: 700;
                    color: ${colors.grayDescription};
                }
            }
        }
        .rbc-time-slot {
            height: ${({ height }) => height}px !important;
            min-height: ${({ height }) => height}px !important;
            flex: unset;
        }
        .rbc-toolbar {
            font-size: 12px;
            .rbc-toolbar-label {
                font-size: 14px;
                font-weight: 500;
                color: ${colors.primary};
            }
        }
        .rbc-timeslot-group {
            min-height: 0;
        }
    `,
}

const locales = {
    'en-US': enUS,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const checkOverlappedBlockedTimeSlots = (events: AppointmentSchedule[], start: Date, end: Date) => (
    events.some((item) => (
        isInBetween(
            new Date(item.start_date), sub(new Date(item.end_date), {seconds: 1}), start
        )
        || isInBetween(
            new Date(item.start_date), sub(new Date(item.end_date), {seconds: 1}), sub(end, {seconds: 1})
        )
        || isInBetween(
            start, sub(end, {seconds: 1}), new Date(item.start_date)
        )
    ))
)

// eslint-disable-next-line react/prop-types
export default function AvailabilityManagement({ dayLayoutAlgorithm = 'no-overlap' }) {
    const eventSlug = useSlug('eventSlug')
    const currentUser = useSelector(currentUserSelector())
    const verticalSize = useSelector(verticalSizeSelector)
    const dispatch = useDispatch()
    const {
        data, mutate, isLoading
    } = useFetchBlockedSchedules()

    const handleSelectSlot = useCallback(
        async ({ start, end, action }) => {
            if (checkOverlappedBlockedTimeSlots(data, start, end)) {
                return
            }
            if (action === 'click') {
                return
            }
            dispatch(setIsPageLoading(true))
            try {
                const values = {
                    start_date: start,
                    end_date: end,
                    subject: 'Blocked',
                    receiver: currentUser.id
                }
                const response = await api.post(
                    `/events/${eventSlug}/appointment_schedules/blocked/`,
                    values
                )
                mutate(response)
                dispatch(showNotification({
                    type: 'success',
                    body: _('appointment_schedule.manage_availability.block_time_successfully'),
                    displayDuration: 3000,
                }))
            } catch (error) {
                dispatch(showErrorNotification(_('appointment_schedule.manage_availability_failed')))
                captureError(error)
            } finally {
                dispatch(setIsPageLoading(false))
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentUser.id, dispatch, eventSlug, mutate]
    )

    const handleResizeOrDropTimeSlot = async ({start, end, event}) => {
        if (checkOverlappedBlockedTimeSlots(data.filter((e) => e.id !== event.id), start, end)) {
            return
        }
        dispatch(setIsPageLoading(true))
        try {
            const values = {
                start_date: start,
                end_date: end,
                receiver: currentUser.id
            }
            const response = await api.put(
                `/events/${eventSlug}/appointment_schedules/blocked/${event?.id}/`,
                values
            )
            mutate(response)
            dispatch(showNotification({
                type: 'success',
                body: _('appointment_schedule.manage_availability.block_time_successfully'),
                displayDuration: 3000,
            }))
        } catch (error) {
            dispatch(showErrorNotification(_('appointment_schedule.manage_availability_failed')))
            captureError(error)
        } finally {
            dispatch(setIsPageLoading(false))
        }
    }

    const { defaultDate, scrollToTime, views } = useMemo(
        () => ({
            defaultDate: new Date(),
            scrollToTime: new Date(1970, 1, 1, 6),
            views: {
                day: true,
                week: true,
            },
        }),
        []
    )

    if (isLoading) {
        return (
            <S.Wrapper>
                <Loader />
            </S.Wrapper>
        )
    }

    return (
        <>
            <S.Wrapper height={heightMapper[verticalSize]}>
                <DnDCalendar
                    dayLayoutAlgorithm={dayLayoutAlgorithm}
                    defaultDate={defaultDate}
                    defaultView={Views.WEEK}
                    events={data}
                    localizer={localizer}
                    onSelectSlot={handleSelectSlot}
                    selectable
                    scrollToTime={scrollToTime}
                    step={15}
                    titleAccessor="subject"
                    startAccessor="start_date"
                    endAccessor="end_date"
                    formats={{
                        timeGutterFormat: 'HH:mm',
                        eventTimeRangeStartFormat: 'HH:mm',
                        eventTimeRangeEndFormat: 'HH:mm',
                        selectRangeFormat: ({start, end}) => `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`,
                    }}
                    components={{
                        event: AppointmentInCalendar,
                        toolbar: CustomToolbar,
                    }}
                    draggableAccessor={(event) => event.status === 'BLOCKED'}
                    onEventDrop={handleResizeOrDropTimeSlot}
                    onEventResize={handleResizeOrDropTimeSlot}
                    views={views}
                />
            </S.Wrapper>
        </>
    )
}
