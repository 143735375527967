import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import useParams from './useParams'
import { eventSelector } from '../features/events/eventSlice'
import getPathForSpace from '../utils/spaces/getPathForSpace'

const useHomeLink = () => {
    const params = useParams()
    const event = useSelector(eventSelector())

    if (!(event && event.home_space)) {
        return '/'
    }

    if (isEmpty(params)) {
        params.eventSlug = event.slug
    }

    if (event.home_space?.type === 'booth') {
        return `/${event.slug}/booths/${event.home_space.slug}`
    }

    return getPathForSpace(event.home_space, params)
}

export default useHomeLink
