import React from 'react'
import styled from 'styled-components'
import colors from '../../constants/colors'
import Bookmark from '../goodiebag/Bookmark'
import { GoodiebagType } from '../goodiebag/types'
import { ProgramItem as ProgramItemType } from '../timetable/types'
import SponsorLogo from '../../assets/sponsor.svg'
import { _ } from '../../utils/localization'
import { isInBetween } from '../../utils/datetime'
import Typography from '../../components/Typography'

const S = {
    ProgramItem: styled.div<{$itemColor: string, shouldFade: boolean}>`
        display: flex;
        width: 100%;
        min-height: 100%;
        height: 100%;
        justify-content: space-between;
        padding: 4px;
        font-size: 12px;
        border: 0;
        cursor: pointer;
        border-top: 5px solid ${({ $itemColor }) => $itemColor}!important;
        border-radius: 0!important;
        opacity: ${({ shouldFade }) => (shouldFade ? 0.2 : 1)} ;
    `,
    Sponsor: styled.div`
        font-weight: 500;
        font-size: 12px;
        color: #A3BCC9;
        text-transform: uppercase;
    `,
    SponsorLogo: styled.img`
        margin-right: 4px;
        margin-top: -2px;
    `,
    Title: styled.div`
        flex-grow: 1;
        font-size: 12px;
        color: ${colors.grayDescription};
    `,
}

const ProgramItem = ({ item, shouldFade }: {item: ProgramItemType, shouldFade?: boolean}) => {
    const isGoingOn = isInBetween(item.start_date, item.end_date, new Date())

    return (
        <S.ProgramItem
            $itemColor={item.color}
            shouldFade={shouldFade}
        >
            <div>
                <div className="flex-align-center">
                    {item.sponsor && (
                        <S.Sponsor className="mr-4">
                            <S.SponsorLogo src={SponsorLogo} />
                            {_('program.sponsored')}
                        </S.Sponsor>
                    )}
                    {isGoingOn && (
                        <Typography fontSize={12} fontWeight={700} color={colors.danger}>NOW</Typography>
                    )}
                </div>
                <S.Title>{item.name}</S.Title>
            </div>

            {!item.isContainer && (
                <Bookmark
                    item={{ id: item.id, type: GoodiebagType.ProgramItem }}
                    size={16}
                    style={{ marginTop: -3, marginRight: 3, alignSelf: 'baseline' }}
                />
            )}
        </S.ProgramItem>
    )
}

export default ProgramItem
