import React from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import colors from '../constants/colors'

const S = {
    // TODO cover case where isCenter = false
    Wrapper: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    `,
    Loader: styled(Spinner)<{$color: string, $isCenter: boolean}>`
        display: ${({ $isCenter }) => ($isCenter ? 'block' : 'inline-block')};
        margin: ${({ $isCenter }) => ($isCenter ? 'auto' : '20px auto')};
        border-color: ${({ $color }) => $color};
        border-right-color: transparent;
    `
}

export default ({
    color = colors.gray5, isCenter = true, className
}: {color?: string, isCenter?: boolean, className?: string}) => (
    <S.Wrapper className={className}>
        <S.Loader animation="border" role="status" $color={color} $isCenter={isCenter}>
            <span className="sr-only">Loading...</span>
        </S.Loader>
    </S.Wrapper>
)
