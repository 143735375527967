import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
    doNotDisturbSelector,
    editProfile,
    setDoNotDisturb,
} from '../../../features/profile/editProfileSlice'
import Icon from '../../Icon'
import { _ } from '../../../utils/localization'
import colors from '../../../constants/colors'
import { currentUserSelector } from '../../../features/auth/authSlice'
import useSlug from '../../../hooks/useSlug'
import { currentUserIsMeetingSpeakerSelector, eventSelector } from '../../../features/events/eventSlice'
import useDebounce from '../../../hooks/useDebounce'
import useClickOutSide from '../../../hooks/useClickOutSide'
import { addModal } from '../../../features/modals/modalSlice'
import CompatibilityTestModal from '../../../features/browsersupport/CompatibilityTestModal'
import constants from '../../../constants/constants'
import { clearNotification } from '../../../features/soundnotifications/soundNotificationSlice'
import routes from '../../../routes'
import { eventPreviewSelector } from '../../../features/auth/eventPreviewSlice'
import { getLocalTimezone } from '../../../utils/datetime'
import Typography from '../../Typography'
import ProfileBadge from '../../../features/profile/ProfileBadge'

const S = {
    ProfileWrapper: styled.div`
        position: relative;
        cursor: pointer;
    `,
    ProfileImage: styled.div`
        display: flex;
        align-items: center;
        & > img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }
    `,
    DropdownIcon: styled(Icon)<{isShow: boolean}>`
        margin-left: 6px;
        transform: rotate(${({isShow}) => (isShow ? '-180deg' : 0)});
        transition: 0.3s;
    `,
    ProfileMenu: styled.div<{isShow: boolean}>`
        position: absolute;
        z-index: 1;
        bottom: -8px;
        right: 0;
        height: ${({isShow}) => (isShow ? 'auto' : 0)};
        padding: ${({isShow}) => (isShow ? '16px 0 0 0' : 0)};
        background-color: white;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        overflow: hidden;
        opacity: ${({isShow}) => (isShow ? 1 : 0)};
        transform: translateY(100%);
        transition: 0.3s;
    `,
    MenuItem: styled.div`
        display: flex;
        align-items: center;
        padding: 8px 16px;
        font-size: 14px;
        user-select: none;
        & span.text {
            color: ${colors.icon} !important;
            margin-left: 10px;
            white-space: nowrap;
        }
        &:hover {
            background-color: ${colors.gray2};
        }
    `,
    WrapperWithDivider: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
        padding: 16px 0;
        padding-bottom: 0;
        border-top: 1px solid #F0F0F0;
        border-bottom: 1px solid #F0F0F0;
        & .dnd-info {
            padding-top: 0;
            padding-bottom: 16px;
            &:hover {
                background: none;
            }
        }
    `,
    Link: styled(Link)`
        &:hover{
            text-decoration: none;
        }
    `,
}

const ProfileToggle = () => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(addModal({
            Component: ({close}: {close: () => void}) => (
                <ProfileBadge close={close} />
            ),
            padding: 0,
        }))
    }
    return (
        <S.MenuItem onClick={onClick}>
            <Icon icon="pencil" size={18} />
            <span className="text">{ _('nav.edit_profile') }</span>
        </S.MenuItem>
    )
}

const DoNotDisturb = () => {
    const dispatch = useDispatch()
    const currentUser = useSelector(currentUserSelector())
    const doNotDisturb = useSelector(doNotDisturbSelector)

    // Don't send every update immediately to the server because:
    // - We can get race conditions
    // - Prevent load: this sends websocket messages to users in the event
    // - Exact same websocket send within 3 seconds messages are filtered
    const debounceToggle = useDebounce((newValue) => {
        dispatch(editProfile({ id: currentUser.id, do_not_disturb: newValue }))
    }, 2000)

    const update = (v: boolean) => {
        dispatch(setDoNotDisturb(v))
        dispatch(clearNotification())
        debounceToggle(v)
    }

    if (!currentUser?.id) {
        return null
    }

    return (
        <S.WrapperWithDivider>
            <S.MenuItem className="dnd-info">
                <Icon icon="videoOff" size={18} />
                <span className="text">{ _('nav.do_not_disturb') }</span>
            </S.MenuItem>
            <Form.Group controlId="disturb-toggle" style={{marginRight: 10}}>
                <Form.Check
                    type="switch"
                    label=""
                    checked={doNotDisturb}
                    onChange={() => update(!doNotDisturb)}
                />
            </Form.Group>
        </S.WrapperWithDivider>
    )
}

const RemoveAccountMenuItem = () => (
    <S.WrapperWithDivider style={{ marginTop: 0, borderTop: 'none' }}>
        <S.Link to={routes.removeUserData.getPath()}>
            <S.MenuItem className="dnd-info">
                <Icon icon="shieldCheck" size={18} />
                <span className="text">{ _('nav.remove_account') }</span>
            </S.MenuItem>
        </S.Link>
    </S.WrapperWithDivider>
)

const ProfileDropdown = ({hideDropdownIcon}: {hideDropdownIcon?: boolean}) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const event = useSelector(eventSelector())
    const currentUser = useSelector(currentUserSelector())
    const eventPreview = useSelector(eventPreviewSelector)
    const currentUserIsMeetingSpeaker = useSelector(currentUserIsMeetingSpeakerSelector)
    const [isShowMenu, setIsShowMenu] = useState(false)
    const menuRef = useRef<HTMLDivElement>()
    const isLiteVersion = constants.isLite
    const goodieBagName = event.goodiebag_name === 'Favorites' ? _('goodiebag.name') : event.goodiebag_name

    useClickOutSide(menuRef, () => isShowMenu && setIsShowMenu(false))
    const showTestModal = () => {
        dispatch(addModal({
            Component: CompatibilityTestModal,
            padding: 20
        }))
    }

    const toggle = () => {
        setIsShowMenu(!isShowMenu)
    }

    return (
        <S.ProfileWrapper onClick={toggle} ref={menuRef}>
            <S.ProfileImage>
                <img src={currentUser?.mugshot} alt="user" />
                {!hideDropdownIcon && (
                    <S.DropdownIcon isShow={isShowMenu} icon="chevronDown" color={colors.navText} size={16} />
                )}
            </S.ProfileImage>

            <S.ProfileMenu isShow={isShowMenu}>
                <ProfileToggle />
                {eventPreview?.enable_meeting_scheduling && (
                    <S.Link to={routes.appointments.getPath({eventSlug})}>
                        <S.MenuItem>
                            <Icon icon="appointmentSchedule" size={18} />
                            <span className="text">{_('nav.manage_appointments')}</span>
                        </S.MenuItem>
                    </S.Link>
                )}
                {currentUserIsMeetingSpeaker && (
                    <S.Link to={routes.allMeetings.getPath({eventSlug})}>
                        <S.MenuItem>
                            <Icon icon="videoAccount" size={22} />
                            <span className="text" style={{ marginLeft: 6 }}>{_('nav.my_meetings')}</span>
                        </S.MenuItem>
                    </S.Link>
                )}
                <S.Link to={routes.goodiebag.getPath({eventSlug})}>
                    <S.MenuItem>
                        <Icon icon="bookmark" size={18} />
                        <span className="text">{goodieBagName}</span>
                    </S.MenuItem>
                </S.Link>
                <S.MenuItem onClick={showTestModal}>
                    <Icon icon="applicationCog" size={18} />
                    <span className="text">{_('nav.check_browser_setting')}</span>
                </S.MenuItem>
                {!isLiteVersion && <DoNotDisturb />}
                <RemoveAccountMenuItem />
                <S.Link to="/logout">
                    <S.MenuItem>
                        <Icon icon="logout" size={18} style={{marginBottom: 2}} />
                        <span className="text">{_('nav.sign_out')}</span>
                    </S.MenuItem>
                </S.Link>
                <S.MenuItem style={{ borderTop: '1px solid #F0F0F0', cursor: 'default' }}>
                    <Typography color={colors.icon} fontSize={14}>
                        <span className="text-uppercase">{_('timezone.infomation')}</span>
                        <br />
                        {getLocalTimezone()}
                    </Typography>
                </S.MenuItem>
            </S.ProfileMenu>
        </S.ProfileWrapper>
    )
}

export default ProfileDropdown
