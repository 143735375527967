import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../utils/api'
import createRequestSlice from '../../utils/createRequestSlice'
import { RootState } from '../../store'

export interface EventFileItem {
    id: number
    name: string
    url: string
}

export interface EventFilesResponse {
    results: EventFileItem[]
    count: number
    next?: string | null;
    previous?: string | null;
}

export const getEventFiles = createAsyncThunk(
    'GET_EVENT_GOODIEBAG_ITEMS',
    ({ eventSlug }: { eventSlug: string }) => api.get<EventFilesResponse>(`events/${eventSlug}/goodiebag/eventfiles/`),
)

const getEventFilesSlice = createRequestSlice<EventFilesResponse>(getEventFiles, 'getEventFiles')
export default getEventFilesSlice.reducer
export const selectEventFiles = (state: RootState) => state.goodiebag.eventItems.data
