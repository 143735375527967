import React from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { _ } from '../../../utils/localization'
import Icon from '../../../components/Icon'
import colors from '../../../constants/colors'
import {
    toggleAudio,
    toggleVideo,
    setIsScreenSharing,
} from '../videocallSlice'
import { RootState } from '../../../store'
import { currentUserSelector } from '../../auth/authSlice'

const hoverBackgroundColor = colors.gray6
const backgroundColor = colors.gray8
const fontColor = colors.gray3

const S = {
    BottomBar: styled.div`
      width: 100%;
      padding: 8px 16px;
      background: ${backgroundColor};
      color: ${fontColor};
      height: 200px;
      display: flex;
      flex-direction: row;
      & > * {  // make direct children same width
        flex-basis: 100%;
      }
    `,
    ContentWrapper: styled.div`
      display: flex;
      flex-direction: row;
    `,
    VideoControls: styled.div`
      display: flex;
      justify-content: center;
      height: 60px;
      align-items: start;
    `,
    LeaveButtonWrapper: styled.div`
      display: flex;
      justify-content: end;
      align-items: start;
    `,
    LeaveButton: styled(Button)`
      border: none !important;
      color: ${fontColor};
      background: ${backgroundColor} !important;
      padding: 0;
      margin-left: auto;
      align-items: center;
      transition: background-color .3s;
      :hover {
        background: ${hoverBackgroundColor} !important;
      }
    `,
    MediaToggle: styled(Button)`
      border: none !important;
      padding: 0;
      margin-right: 16px;
      display: flex;
      color: ${fontColor};
      background: ${backgroundColor} !important;
      font-size: 12px;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      transition: background-color .3s;
      :hover {
        background: ${hoverBackgroundColor} !important;
      }
    `,
    ExitText: styled.span`
      margin-left: 8px;
    `,
}

const BottomBar = ({ children }: { children: JSX.Element[] }) => {
    const {
        isAudioEnabled,
        isVideoEnabled,
        isScreenSharingEnabled,
        current,
    } = useSelector((state: RootState) => state.videocall)
    const dispatch = useDispatch()
    const history = useHistory()
    const currentUser = useSelector(currentUserSelector())

    return (
        <S.BottomBar className="bottom-bar-videocall">
            <S.ContentWrapper>
                {children}
            </S.ContentWrapper>

            <S.VideoControls>
                <S.MediaToggle onClick={() => dispatch(toggleVideo())}>
                    {isVideoEnabled ? (
                        <>
                            <Icon icon="video" color={colors.gray3} size={32} />
                            {_('videocall.stop_video_button')}
                        </>
                    ) : (
                        <>
                            <Icon icon="videoOff" color={colors.gray3} size={32} />
                            {_('videocall.start_video_button')}
                        </>
                    )}
                </S.MediaToggle>

                {
                    current?.admin_id === currentUser?.id
                    && (
                        <S.MediaToggle onClick={() => dispatch(setIsScreenSharing(!isScreenSharingEnabled))}>
                            <Icon
                                icon={isScreenSharingEnabled ? 'monitorOff' : 'monitorShare'}
                                color={colors.gray3}
                                size={32}
                            />
                            {isScreenSharingEnabled
                                ? _('videocall.screen_sharing.disable')
                                : _('videocall.screen_sharing.enable')}
                        </S.MediaToggle>
                    )
                }

                <S.MediaToggle onClick={() => dispatch(toggleAudio())}>
                    {isAudioEnabled ? (
                        <>
                            <Icon icon="mic" color={colors.gray3} size={32} />
                            {_('videocall.mute_button')}
                        </>
                    ) : (
                        <>
                            <Icon icon="micOff" color={colors.gray3} size={32} />
                            {_('videocall.unmute_button')}
                        </>
                    )}
                </S.MediaToggle>
            </S.VideoControls>

            <S.LeaveButtonWrapper>
                <S.LeaveButton onClick={() => history.goBack()}>
                    <Icon icon="exit" color={colors.gray3} size={32} />
                    <S.ExitText>{_('videocall.leave_meeting_button')}</S.ExitText>
                </S.LeaveButton>
            </S.LeaveButtonWrapper>
        </S.BottomBar>
    )
}

export default BottomBar
