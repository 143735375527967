import React from 'react'
import styled from 'styled-components'
import { useDrop } from 'react-dnd'
import Popover from 'react-bootstrap/cjs/Popover'
import Button from 'react-bootstrap/cjs/Button'
import { useDispatch } from 'react-redux'
import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import { ItemTypes } from './Map'
import { getStatusColor } from '../../attendees/statuses'
import getMugshotSrc from '../../../utils/getMugshotSrc'
import { joinVideocall } from '../../entervideocall/videocallActions'
import { Videocall } from '../../../types/swagger'
import useSlug from '../../../hooks/useSlug'
import { VideocallRoomStatus } from '../../videocall/types'
import { JoinCallConfig } from '../../entervideocall/types'
import { RootState } from '../../../store'
import { getAttendeesForUsers } from '../../attendees/attendeesSlice'

interface ActiveCallStyleProps {
    left: number
    top: number
}

interface ImageProps {
    boxShadow: string
    borderColor: string
}

export const S = {
    ActiveCall: styled.div<ActiveCallStyleProps>`
      position: absolute;
      display: flex;
      flex-direction: row;
      left: ${({ left }): ActiveCallStyleProps['left'] => left}%;
      top: ${({ top }): ActiveCallStyleProps['top'] => top}%;
      background: rgba(0 0 0 0);
      cursor: pointer; 
    `,
    Image: styled.img<ImageProps>`
        border: 2px solid ${({ borderColor }): ImageProps['borderColor'] => borderColor};
        height: 50px;
        width: 50px;
        border-radius: 120px;
        object-fit: cover;
        box-shadow: 10px 10px 10px ${({ boxShadow }): ImageProps['boxShadow'] => boxShadow};
        margin: auto -10px;
    `,
    Popover: styled(Popover)`
        max-width: 320px;
    `,
    PopoverTitle: styled(Popover.Title)`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    ActionWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    `,
    Button: styled(Button)`
        display: flex;
        align-items: center;
        margin-right: ${({ $hasMargin }) => ($hasMargin ? '10px' : 0)};
    `,
}

type FetchAndJoinVideocallProps = {
    eventSlug: string,
    adminId: number,
    status: VideocallRoomStatus,
    config: JoinCallConfig
}

const fetchAndJoinVideocall = createAsyncThunk(
    'FETCH_AND_JOIN_VIDEOCALL',
    async (props: FetchAndJoinVideocallProps, thunkAPI) => {
        const state = thunkAPI.getState() as RootState
        let admin = state.attendees.results[props.adminId]

        // Fetch admin if we dont have it in attendee data
        if (!admin) {
            const resp = unwrapResult(await thunkAPI.dispatch(getAttendeesForUsers({
                eventSlug: props.eventSlug,
                userIds: [props.adminId],
            })))
            admin = resp.results[0]
        }

        thunkAPI.dispatch(joinVideocall(props.status, admin, props.config))
    },
)

const CallOnMap = ({ call }: { call: Videocall }) => {
    const networkingSlug = useSlug('networkingSlug')
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const statusColor = getStatusColor(call.status)

    const handleJoin = () => {
        dispatch(fetchAndJoinVideocall({
            eventSlug,
            adminId: call.admin_id,
            status: call.status,
            config: {
                requestInPopover: true,
                networkingSlug,
                amountOfUsers: call.users.length
            }
        }))
    }

    const [{ isOver }, drop] = useDrop({
        accept: ItemTypes.CURRENT_USER,
        drop: () => { handleJoin() },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    })

    return call.status !== 'closed' && (
        <S.ActiveCall
            left={call.x_position}
            top={call.y_position}
            ref={drop}
            onClick={handleJoin}
        >
            {
                call.mugshots.map((mugshot) => (
                    <S.Image
                        key={mugshot}
                        src={getMugshotSrc({ mugshot })}
                        alt=""
                        boxShadow={isOver ? statusColor : ''}
                        borderColor={getStatusColor(call.status)}
                    />
                ))
            }
        </S.ActiveCall>
    )
}

export default React.memo(CallOnMap)
