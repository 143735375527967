import { websocketAction } from '../../utils/redux'
import { VirtualAttendee } from '../../types/swagger'
import { UpdatePrivacyPayload } from '../attendees/attendeesSlice'

const WS = {
    attendee_updated: websocketAction<VirtualAttendee>('attendee_updated'),
    privacy_status_updated: websocketAction<UpdatePrivacyPayload>('privacy_status_updated'),
}

export default {
    WS,
}
