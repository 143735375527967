import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { RetrieveVirtualEventResponse } from '../../../types/__generated_swagger'
import BackgroundImagePlaceholder from '../../../assets/Background_placeholder.jpg'
import routes from '../../../routes'
import TicketCodeForm from './TicketCodeForm'
import { NeedHelp } from './TicketCodePage'
import colors from '../../../constants/colors'
import IntroVideo from './IntroVideo'
import Icon from '../../../components/Icon'
import { _ } from '../../../utils/localization'

export const S = {
    Page: styled.div`
        height: var(--window-height);
        margin-top: -58px;  // escape top nav
        display: flex;
        flex-direction: column;
        background: #f9f9f9;
    `,
    Logo: styled.img`
        width: auto;
        max-height: 29px;
        min-height: 29px;
        object-fit: contain;
        object-position: center;
        margin: 24px 0;
    `,
    Image: styled.div<{ $image: string }>`
        background: ${({ $image }) => `url(${$image})`};
        background-size: cover;
        background-position: center center;
        border: 0;
        width: auto;
        height: 192px;
        min-height: 192px;
        margin: 0 16px;
        border-radius: 10px;
    `,
    Content: styled.div`
        width: 100%;
        padding: 0 16px;
        padding-bottom: 24px;
        background: #f9f9f9;
    `,
    IntoText: styled.div`
        padding: 20px;
        overflow: auto;
    `,
    AlignBottom: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        .help > span {
            color: ${colors.icon};
            text-shadow: none;
        }
        .help svg path {
            fill: ${colors.icon} !important;
        }

    `,
    Replay: styled.div`
        display: flex;
        align-items: center;
        color: ${colors.icon};
        font-weight: 700;
    `
}

const TicketCodePageMobile = ({ eventData }: { eventData: RetrieveVirtualEventResponse }) => {
    // @ts-ignore
    const isBackStage = useRouteMatch(routes.enterEventBackStage.path)

    return (
        <S.Page>
            <S.Logo src={eventData?.logo} alt="" />
            <S.Image $image={eventData?.background_image || BackgroundImagePlaceholder} />

            <S.Content>
                {
                    eventData.published || isBackStage
                        ? <TicketCodeForm eventData={eventData} />
                        : <S.IntoText className="editor-content" dangerouslySetInnerHTML={{ __html: eventData.intro_text }} />
                }

                <S.AlignBottom>
                    {eventData?.help_content ? <NeedHelp /> : <div />}

                    <IntroVideo eventData={eventData}>
                        <S.Replay>
                            <Icon
                                icon="refresh"
                                color={colors.icon}
                                style={{ background: 'white', borderRadius: 24, lineHeight: 1 }}
                                size={21}
                            />
                            {_('auth.enter_event.replay_video')}
                        </S.Replay>
                    </IntroVideo>
                </S.AlignBottom>
            </S.Content>
        </S.Page>
    )
}

export default TicketCodePageMobile
