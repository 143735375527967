import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/cjs/ListGroup'
import { useDispatch, useSelector } from 'react-redux'
import colors from '../../../constants/colors'
import Icon from '../../../components/Icon'
// eslint-disable-next-line import/no-named-as-default-member
import ExhibitorItem from './ExhibitorItem'
import {
    boothListRequestStatusSelector,
    fetchBooths,
    limitSelector,
    setBoothLimit,
} from '../boothsSlice'
import { fetchCategories } from '../categorySlice'
import useSlug from '../../../hooks/useSlug'
import { RootState } from '../../../store'
import { ListBoothSpacesResponse } from '../../../types/swagger'
import useDebounce from '../../../hooks/useDebounce'
import styles from '../../../constants/styles'
import LoadMore from '../../../components/LoadMore'
import { _ } from '../../../utils/localization'
import { setIsPageLoading } from '../../events/pageLoadingSlice'
import Loader from '../../../components/Loader'

const S = {
    SideBar: styled.div<{isHidden?: boolean}>`
        display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
        flex-direction: column;
        background: white;
        width: ${styles.sidebarWidth}px;
        height: 100%;
        position: fixed;
        right: 0;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        @media (max-width: 1366px) {
            width: ${styles.smallerSidebarWidth}px;
        }
    `,
    SideBarTop: styled.div`
        background: ${colors.gray8};
        width: 100%;
        padding: 20px;
    `,
    Title: styled.p`
        font-size: 20px;
        font-weight: 500;
        color: white;
    `,
    SearchGroup: styled(Form.Group)`
        position: relative;
        margin-bottom: 20px;
    `,
    SearchInput: styled(Form.Control)`
        padding-right: 35px;
        &::placeholder {
            font-style: italic;
        }
    `,
    SearchIcon: styled.div`
        position: absolute;
        top: 3px;
        right: 5px;
    `,
    CategorySelect: styled(Form.Group)`
        margin-bottom: 0;
    `,
    ListGroup: styled(ListGroup)`
        margin: 10px 0;
        flex: 1 1 auto;
        overflow-y: auto;
        // Without this the last result in the list is not visible
        margin-bottom: 70px;
    `
}

const ExhibitorList = ({ booths = [] }: {booths: ListBoothSpacesResponse['results']}) => {
    const limit = useSelector(limitSelector)
    const dispatch = useDispatch()

    return (
        <S.ListGroup>
            {booths && booths.slice(0, limit).map((booth) => (
                <ExhibitorItem
                    key={booth.id}
                    id={booth.id}
                    name={booth.name}
                    description={booth.subtitle}
                    image={booth.logo}
                    slug={booth.slug}
                />
            ))}
            {booths?.length > limit && (
                <LoadMore onClick={() => dispatch(setBoothLimit(limit + 10))}>
                    {_('booths.sidebar.load_more')}
                </LoadMore>
            )}
        </S.ListGroup>
    )
}

const SideBar = ({isHidden}: {isHidden: boolean}) => {
    const dispatch = useDispatch()
    const eventSlug = useSlug('eventSlug')
    const mapSlug = useSlug('mapSlug')
    const [categoryId, setCategoryId] = useState(undefined)
    const [search, setSearch] = useState('')
    const booths = useSelector((state: RootState) => state.exhibition.list)
    const categories = useSelector((state: RootState) => state.exhibition.categories.data)
    const debounceSearch = useDebounce((value) => setSearch(value), 400)
    const requestStatus = useSelector(boothListRequestStatusSelector)

    // Fetch Booths
    useEffect(() => {
        dispatch(fetchBooths({
            eventSlug, mapSlug, categoryId, search,
        }))
    }, [dispatch, eventSlug, mapSlug, categoryId, search])

    // Fetch Categories
    useEffect(() => {
        dispatch(fetchCategories({ eventSlug, mapSlug }))
    }, [dispatch, eventSlug, mapSlug])

    const handleSearch = (event) => {
        const { value } = event.target
        debounceSearch(value)
    }

    if (requestStatus === 'loading') {
        dispatch(setIsPageLoading(true))
    } else {
        dispatch(setIsPageLoading(false))
    }

    return (
        <S.SideBar isHidden={isHidden}>
            <S.SideBarTop>
                <S.Title>{_('booth.title')}</S.Title>
                <S.SearchGroup controlId="search-input">
                    <S.SearchInput
                        placeholder={_('booth.search_placeholder')}
                        size="sm"
                        onChange={handleSearch}
                    />
                    <S.SearchIcon><Icon icon="search" /></S.SearchIcon>
                </S.SearchGroup>
                <S.CategorySelect controlId="category-select">
                    <Form.Control as="select" size="sm" onChange={(e) => setCategoryId(e.target.value)}>
                        <option value="">
                            {_('booth.all_categories')}{' '}({booths?.totalBooths || (booths?.data || []).length})
                        </option>
                        {(categories || []).map((category) => (
                            <option value={category.id} key={category.id}>
                                {category.name}{' '}({category.num_of_booths})
                            </option>
                        ))}
                    </Form.Control>
                </S.CategorySelect>
            </S.SideBarTop>
            {booths.status === 'loading' ? <Loader isCenter /> : <ExhibitorList booths={booths.data} />}
        </S.SideBar>
    )
}

export default SideBar
