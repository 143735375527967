enum VideoProviderType {
    YOUTUBE = 'youtube',
    VIMEO = 'vimeo',
}

const getProviderFromUrl = (url: string): VideoProviderType => {
    let result
    Object.values(VideoProviderType).forEach((provider) => {
        const pattern = new RegExp(`\\.?${provider}\\.com`, 'i')
        if (pattern.test(url)) {
            result = provider
        }
    })
    return result
}

const getVideoIdFromEmbedUrl = (url: string, provider: VideoProviderType): string => {
    if (provider === VideoProviderType.YOUTUBE) {
        // Reference pattern from https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
        const match = url.match(regExp)
        return (match && match[7].length === 11) ? match[7] : ''
    }
    if (provider === VideoProviderType.VIMEO) {
        const regExp = /(player\.)?vimeo\.com\/video\/([0-9]{1,10})/
        const match = url.match(regExp)
        return (match && match[2].length > 0) ? match[2] : ''
    }
}

const getThumbnailFromUrl = async (url: string) => {
    const provider = getProviderFromUrl(url)
    if (!provider) { return }

    if (provider === VideoProviderType.YOUTUBE) {
        const videoId = getVideoIdFromEmbedUrl(url, VideoProviderType.YOUTUBE)
        return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`
    }

    if (provider === VideoProviderType.VIMEO) {
        return fetch(`https://vimeo.com/api/oembed.json?url=${url}`, {
            method: 'get',
            cache: 'no-cache',
            mode: 'cors',
        })
            .then((data) => data.json())
            .then((data) => data.thumbnail_url)
    }
}

export default getThumbnailFromUrl
