import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import Form from 'react-bootstrap/cjs/Form'
import styled from 'styled-components'
import FormCheck from 'react-bootstrap/cjs/FormCheck'
import useSlug from '../../hooks/useSlug'
import useDebounce from '../../hooks/useDebounce'
import { updatePrivacyStatus } from '../auth/authSlice'
import { _ } from '../../utils/localization'
import { selectShowMe, setShowMe } from './showMeSlice'

const S = {
    ToggleWrapper: styled(Form.Group)`
      display: flex;
      flex-direction: column;
      margin-right: 5px;
    `,
    ToggleLabel: styled.div`
      font-size: 12px;
    `,
    Toggle: styled(FormCheck)`
      margin-left: auto;
      margin-top: 5px;
      transform: scale(1.6);
    `,
}

const ShowMeToggle = () => {
    const eventSlug = useSlug('eventSlug')
    const dispatch = useDispatch()
    const showMe = useSelector(selectShowMe)

    // To avoid clicking many times too quickly.
    const debounceToggle = useDebounce((value) => {
        dispatch(updatePrivacyStatus({ eventSlug, is_public: value }))
    }, 500)

    const toggleShowMe = () => {
        dispatch(setShowMe(!showMe))
        debounceToggle(!showMe)
    }

    return (
        <S.ToggleWrapper controlId="show-me-toggle">
            <S.ToggleLabel>{_('networking.sidebar.show_me')}</S.ToggleLabel>
            <S.Toggle
                type="switch"
                label=""
                checked={showMe}
                onChange={toggleShowMe}
            />
        </S.ToggleWrapper>
    )
}

export default ShowMeToggle
