import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { MeetingItem } from './types'
import api from '../../utils/api'
import { websocketAction } from '../../utils/redux'

interface FetchMeeting {
    eventSlug: string
    meetingSlug: string
    roomSlug: string
}

interface DeleteRecording {
    eventSlug: string
    meetingSpaceSlug: string
    meetingSlug: string
    recordingId: number
}

interface AcceptRecording {
    eventSlug: string
    meetingSpaceSlug: string
    meetingSlug: string
    recordingId: number
    hostName: string
}

interface SetAcceptRecording {
    recordingId: number
    hostName: string
}

interface VimeoUploadComplete {
    recordingId: number
}

const WS = {
    vimeoUploadComplete: websocketAction<VimeoUploadComplete>('vimeo_upload_complete')
}

export const fetchMeetingRoom = createAsyncThunk(
    'FETCH_MEETING_ROOM',
    ({ eventSlug, meetingSlug, roomSlug }: FetchMeeting) => (
        api.get<any>(
            `/events/${eventSlug}/meetings/${meetingSlug}/meetings/${roomSlug}/`
        )
    )
)

export const deleteRecording = createAsyncThunk(
    'DELETE_RECORDING',
    ({
        eventSlug, meetingSpaceSlug, meetingSlug, recordingId
    }: DeleteRecording, thunkAPI) => {
        thunkAPI.dispatch(meetingRoomSlice.actions.deleteRecording(recordingId))
        return (
            api.post(`/events/${eventSlug}/meetings/${meetingSpaceSlug}/meetings/${meetingSlug}/delete/${recordingId}/`)
        )
    }
)

export const acceptRecording = createAsyncThunk(
    'ACCEPT_RECORDING',
    ({
        eventSlug, meetingSpaceSlug, meetingSlug, recordingId, hostName
    }: AcceptRecording, thunkAPI) => {
        thunkAPI.dispatch(meetingRoomSlice.actions.acceptRecording({recordingId, hostName}))
        return (
            api.post(`/events/${eventSlug}/meetings/${meetingSpaceSlug}/meetings/${meetingSlug}/accept/${recordingId}/`, { host_name: hostName })
        )
    }
)

const meetingRoomSlice = createSlice({
    name: 'meetingRoomSlice',
    initialState: {
        meeting: null as MeetingItem,
        userUrl: null as string
    },
    reducers: {
        setMeetingRoom: (state, { payload }: PayloadAction<MeetingItem>) => {
            state.meeting = payload
        },
        deleteRecording: (state, { payload }: PayloadAction<number>) => {
            const index = state.meeting.recordings.map((e) => e.id).indexOf(payload)
            state.meeting.recordings[index].vimeo_recording = null
            state.meeting.recordings[index].is_deleted = true
        },
        acceptRecording: (state, { payload }: PayloadAction<SetAcceptRecording>) => {
            const index = state.meeting.recordings.map((e) => e.id).indexOf(payload.recordingId)
            state.meeting.recordings[index].vimeo_recording = { host_name: payload.hostName, status: 'in_progress' }
        },
    },

    extraReducers: (builder) => {
        builder.addCase(fetchMeetingRoom.fulfilled, (state, { payload }) => {
            state.meeting = payload
        })
        builder.addCase(fetchMeetingRoom.rejected, (state) => {
            state.meeting = null
        })
        builder.addCase(WS.vimeoUploadComplete, (state, { payload }) => {
            const index = state.meeting.recordings.map((e) => e.id).indexOf(payload.recordingId)
            state.meeting.recordings[index].vimeo_recording = { status: 'complete' }
        })
    }
})

export const selectMeetingRoom = (state: RootState) => state.meeting.room?.meeting
export const setMeetingRoom = meetingRoomSlice.actions.setMeetingRoom
export default meetingRoomSlice.reducer
