import React, { useEffect } from 'react'
import Logger from 'js-logger'
import env from '../constants/env'

const BuildInfo = () => {
    useEffect(() => {
        Logger.info(`BUILD VERSION = ${env.buildVersion}`)
    }, [])
    return (
        <p style={{ display: 'none' }}>
            {`BUILD VERSION = ${env.buildVersion}`}
            {`ENV = ${env.environment}`}
        </p>
    )
}

export default BuildInfo
