import { createSlice } from '@reduxjs/toolkit'

const questionSlice = createSlice({
    name: 'questions',
    initialState: {
        isOpened: false,
    },
    reducers: {
        toggleIsQuestionsOpened(state) {
            state.isOpened = !state.isOpened
        },
    },
})

export const { toggleIsQuestionsOpened } = questionSlice.actions
export default questionSlice.reducer
