import React from 'react'
import { Jumbotron, Button } from 'react-bootstrap'

const reload = () => {
    // https://stackoverflow.com/questions/29884654/button-that-refreshes-the-page-on-click
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href
}

const ErrorPage = () => (
    <Jumbotron>
        <h1>Something went wrong</h1>
        <p>
            The issue has been reported to our team.
        </p>
        <p>
            <Button variant="primary" onClick={reload}>Refresh page</Button>
        </p>
    </Jumbotron>
)

export default ErrorPage
