import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { _ } from '../../utils/localization'
import { WS as MessageWS } from '../chat/chatSlice'
import { WS as VideocallWS } from '../entervideocall/inviteActions'

type NotificationType = undefined | 'videocall' | 'message'

const initialState = {
    notification: {
        id: 0,
        type: undefined as NotificationType,
        title: '',
        body: '',
    }
}

let id = 0

const soundNotificationSlice = createSlice({
    name: 'soundNotification',
    initialState,
    reducers: {
        clearNotification: (state) => {
            state.notification = initialState.notification
        }
    },
    extraReducers: (builder) => {
        builder.addCase(MessageWS.receiveMessage, (state, { payload }) => {
            // @ts-ignore
            if (!window.isVisible) {
                id += 1
                state.notification = {
                    id,
                    type: 'message',
                    title: _('notifications.receive_message'),
                    body: payload.text
                }
            }
        })
        builder.addCase(VideocallWS.newVideoCallInvited, (state) => {
            // @ts-ignore
            if (!window.isVisible) {
                state.notification = {
                    id,
                    type: 'videocall',
                    title: _('notifications.receive_videocall'),
                    body: ''
                }
            }
        })
    },
})

export const notificationSelector = (state: RootState) => state.soundNotifications.notification

export const { clearNotification } = soundNotificationSlice.actions

export default soundNotificationSlice.reducer
