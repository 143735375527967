import ChatRoulette from './ChatRouletteView'
import { createEventRoute } from '../../utils/routes'
import { _ } from '../../utils/localization'

export default {
    chatRoulette: createEventRoute({
        get title() { return _('meta.page_title.chatroulette') },
        path: '/chatroulette/:chatRouletteSlug/',
        exact: true,
        component: ChatRoulette,
    }),
}
