import React, { forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'
import colors from '../../../../constants/colors'
import { updateVerticalSize, verticalSizeSelector } from '../../appointmentSchedulesSlice'
import DropdownButton from '../../../../components/DropdownButton'
import CalendarSizes from '../../../calendar/components/CalendarSizes'
import Icon from '../../../../components/Icon'
import IconButton from '../../../../components/IconButton'
import { eventPreviewSelector } from '../../../auth/eventPreviewSlice'

const S = {
    Toolbar: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
    `,
    Date: styled.div`
        color: ${colors.primary};
        margin-right: 16px;
    `,
    ViewLabel: styled.div`
        position: relative;
        margin-right: 32px;
        font-size: 16px;
        color: ${colors.grayDescription};
        text-transform: capitalize;

        &::after {
            position: absolute;
            top: 12px;
            right: -16px;
            content: '';
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-top-color: ${colors.darkOpacity(0.6)};
        }
    `,
    CustomDateInput: styled.div`
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 6px 10px;
        border-radius: 4px;
        background-color: ${colors.primaryOpacity(0.03)};
        color: ${colors.primary};
        cursor: pointer;
        user-select: none;
    `
}

interface DatePickerProps {
    value: Date
    onChange: (selectedDate) => void
    className?: string
    minDate?: Date
    maxDate?: Date
    label?: string
}

const DatePicker = ({
    value, onChange, className, minDate, maxDate, label
}: DatePickerProps) => {
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    const CustomInput = forwardRef(({ onClick }, ref) => (
        // @ts-ignore
        // eslint-disable-next-line react/button-has-type
        <S.CustomDateInput onClick={onClick} ref={ref}>
            <Icon icon="calendar" size={18} className="mr-1" color={colors.primary} />
            {label}
        </S.CustomDateInput>
    ))

    return (
        <ReactDatePicker
            onChange={onChange}
            selected={value}
            className={className}
            customInput={<CustomInput />}
            popperPlacement="bottom-end"
            minDate={minDate}
            maxDate={maxDate}
        />
    )
}

const CustomToolbar = ({
    // eslint-disable-next-line react/prop-types
    view, onView, views, label, onNavigate, date
}) => {
    const dispatch = useDispatch()
    const verticalSize = useSelector(verticalSizeSelector)
    const eventPreview = useSelector(eventPreviewSelector)

    return (
        <S.Toolbar>
            <div className="flex-align-center">
                <DropdownButton
                    renderToggleButton={() => (
                        <S.ViewLabel>{view}</S.ViewLabel>
                    )}
                >
                    {(views || []).map((v) => (
                        <span className="text-capitalize" key={v} onClick={() => onView(v)}>
                            {v}
                        </span>
                    ))}
                </DropdownButton>
                <CalendarSizes
                    selectedSize={verticalSize}
                    onSelect={(value) => dispatch(updateVerticalSize(value))}
                    direction="vertical"
                    sizes={['S', 'M', 'L']}
                    className="ml-2"
                />
            </div>
            <div className="flex-align-center">
                <DatePicker
                    value={date}
                    onChange={(value) => onNavigate('DATE', value)}
                    label={label}
                    minDate={new Date(eventPreview?.start_date)}
                    maxDate={new Date(eventPreview?.end_date)}
                />
                <IconButton onClick={() => onNavigate('PREV')}>
                    <Icon icon="chevronDown" style={{ transform: 'rotate(90deg)' }} />
                </IconButton>
                <IconButton onClick={() => onNavigate('NEXT')}>
                    <Icon icon="chevronDown" style={{ transform: 'rotate(-90deg)' }} />
                </IconButton>
            </div>
        </S.Toolbar>
    )
}

export default CustomToolbar
