import React, { MouseEvent, useState } from 'react'
import inRange from 'lodash/inRange'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getItemSpacingLeft, getItemWidth, TimeTableItem } from '../timetable/calculateSpace'
import { DateTime } from '../../utils/datetime'
import useSlug from '../../hooks/useSlug'
import FetchItemDetail from '../imagemap/components/FetchItemDetail'
import { addModal } from '../modals/modalSlice'
import { _ } from '../../utils/localization'
import { ProgramItem } from '../timetable/types'
import FetchProgramContainerModal from './components/FetchProgramContainerModal'
import { selectedDateSelector } from '../timetable/redux/dateSlice'

const getItemHeight = ({ item, selectedDate }) => (
    Math.min(+getItemWidth(item).split('%')[0], 100 - +getItemSpacingLeft(item, selectedDate).split('%')[0])
)

const S = {
    ItemWrapper: styled.div<{item: TimeTableItem, selectedDate: DateTime}>`
        position: absolute;
        top: ${({ item, selectedDate }) => getItemSpacingLeft(item, selectedDate)};
        width: calc(100% - 1px);
        min-height:${getItemHeight}%;
        height: ${getItemHeight}%;
        margin-left: 0.5px;
        background-color: white;
        overflow: hidden;
        z-index: 6;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        cursor: pointer;
        &:hover {
            z-index: 7;
            height: auto;
            overflow: visible;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: ${({ item }) => item.color};
            opacity: 0.1;
            z-index: -1;
        }
    `,
}

interface Props {
    item: ProgramItem
    children: any
    onClick?: () => void
}

export const isInRangePosition = (value: number, comparedValue: number) => (
    inRange(value, comparedValue - 1, comparedValue + 1)
)

const CalenderItem = ({item, children, onClick }: Props) => {
    const dispatch = useDispatch()
    const selectedDate = useSelector(selectedDateSelector)
    const eventSlug = useSlug('eventSlug')
    const [mousePosition, setMousePosition] = useState({x: 0, y: 0})

    const showProgramModal = () => {
        // eslint-disable-next-line react/prop-types
        const Component = () => <FetchItemDetail itemId={item.id} eventSlug={eventSlug} />
        dispatch(addModal({ Component }))
    }

    const showContainerModal = () => {
        dispatch(addModal({
            // eslint-disable-next-line react/prop-types
            Component: () => <FetchProgramContainerModal id={item.id} />,
            header: _('program.session_block'),
            padding: 0,
        }))
    }

    const handleItemClick = () => {
        if (item.is_container) {
            return showContainerModal()
        }

        return showProgramModal()
    }

    const handleClickItem = (evt: MouseEvent) => {
        if (isInRangePosition(evt.clientX, mousePosition.x)
            && isInRangePosition(evt.clientY, mousePosition.y)) {
            item.isContainer ? showContainerModal() : handleItemClick()
        }
    }

    return (
        <S.ItemWrapper
            item={{
                startsAt: item.start_date,
                endsAt: item.end_date,
                color: item.color,
            }}
            selectedDate={selectedDate}
            onMouseDown={(evt) => setMousePosition({x: evt.clientX, y: evt.clientY})}
            onClick={onClick || handleClickItem}
        >
            { children }
        </S.ItemWrapper>
    )
}

export default CalenderItem
