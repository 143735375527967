import useFetchInfiniteList from '../../../../hooks/useFetchInfiniteList'
import useSlug from '../../../../hooks/useSlug'
import { BroadcastMessage } from '../../../../types/__generated_swagger'

const LIMIT = 5

const getKey = (pageIndex, previousPageData, eventSlug) => {
    if (previousPageData && previousPageData.length < LIMIT) { // reached the end
        return null
    }
    const searchParams = new URLSearchParams({
        offset: `${pageIndex * LIMIT}`,
        limit: `${LIMIT}`,
    }).toString()
    return `/events/${eventSlug}/chat/broadcast_messages/?${searchParams}`
}

const useFetchBroadcastMessages = () => {
    const eventSlug = useSlug('eventSlug')
    const result = useFetchInfiniteList<BroadcastMessage>(
        (pageIndex, previousPageData) => getKey(pageIndex, previousPageData, eventSlug),
        LIMIT,
        { dedupingInterval: 5000, revalidateOnFocus: false }
    )

    return result
}

export default useFetchBroadcastMessages
