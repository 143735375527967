import React from 'react'
import { useDispatch } from 'react-redux'
import { AreaProps } from './Area'
import isHoveredStore from './isHoveredStore'
import mousePosition from '../../../utils/mousePosition'
import WidgetPopup from './WidgetPopup'
import { ClickableWidget } from '../types'
import handleWidgetClick from '../handleWidgetClick'

const ClickableWidgetArea = ({
    area, hoverOn, hoverOff, offsetY
}: AreaProps) => {
    const [showPopup, setShowPopup] = React.useState(false)
    const [popupPosition, setPopupPosition] = React.useState()
    const [isPopupHovered, setIsPopupHovered] = React.useState(false)
    const widgetLink = area.widget_link
    const dispatch = useDispatch()

    const onPopupMouseEnter = () => {
        // Don't animate because the area is already being shown.
        hoverOn(false)
        setIsPopupHovered(true)
    }

    const onPopupMouseLeave = () => {
        hoverOff()
        setIsPopupHovered(false)
    }

    const onAreaMouseEnter = () => {
        // to preview UX when opening popup on hover
        isHoveredStore.set(area.id, true)
        setTimeout(() => {
            if (isHoveredStore.get(area.id)) {
                setShowPopup(true)
            }
        }, 250)
        setTimeout(() => { setPopupPosition(mousePosition.get()) }, 100)
        hoverOn(true)
    }

    const onAreaMouseLeave = () => {
        // to preview UX when opening popup on hover
        isHoveredStore.set(area.id, false)
        setShowPopup(false)
        hoverOff()
    }

    const onClick = (event) => {
        event.preventDefault()
        setShowPopup(!showPopup)
        onAreaMouseLeave()
        onPopupMouseLeave()
        if (widgetLink) {
            dispatch(handleWidgetClick(area))
        } else {
            dispatch(handleWidgetClick(area))
        }
    }

    return (
        <>
            <WidgetPopup
                show={showPopup || isPopupHovered}
                position={popupPosition}
                offsetY={offsetY}
                widget={area.widget as ClickableWidget}
                onMouseEnter={onPopupMouseEnter}
                onMouseLeave={onPopupMouseLeave}
                onClickArea={onClick}
                widgetName={area.name}
            />
            {/* eslint-disable-next-line */}
            <area
                key={area.id}
                shape={area.shape}
                coords={area.coords.join(',')}
                alt={`${area.id}`}
                onMouseEnter={onAreaMouseEnter}
                onMouseLeave={onAreaMouseLeave}
                onClick={onClick}
                href="#" // To show cursor:pointer in Safari
            />
        </>
    )
}

export default ClickableWidgetArea
