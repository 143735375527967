import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { websocketAction } from '../../utils/redux'
import api from '../../utils/api'
import { RootState } from '../../store'
import { createEmptyPagination } from '../../utils/pagination'
import sendToWs from '../websocket/sendToWs'
import logout from '../auth/logout'

export interface PublicMessage {
    id: number
    text: string
    sent_at: string
    sender: number
}

interface ReceivedPublicMessage extends PublicMessage {
    chat_id: number
    generated_id: number
}

type ListMessagesResponse = {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: PublicMessage[];
};

interface SendMessage {
    text: string;
    currentUserId: number
    chatId: number
}

interface Params {
    eventSlug?: string
    chatId: number
    offset?: number
    limit: number
}

export const getMessagesThunk = createAsyncThunk(
    'GET_PUBLIC_MESSAGES',
    ({
        eventSlug, chatId, offset, limit
    }: Params) => (
        api.get<ListMessagesResponse>(
            `/events/${eventSlug}/chat/public/${chatId}/?limit=${limit}&offset=${offset}`
        )
    )
)

let id = -9999 // to generate unique id's that are only in stored in client

export const sendMessagesThunk = createAsyncThunk(
    'SEND_PUBLIC_MESSAGE',
    (params: SendMessage, thunkAPI) => {
        const { text, chatId, currentUserId } = params
        const message: PublicMessage = {
            id: --id,
            text,
            sender: currentUserId,
            sent_at: new Date().toISOString(),
        }
        thunkAPI.dispatch(messagesSlice.actions.setMessage(message))
        thunkAPI.dispatch(sendToWs({
            type: 'send_public_message',
            generated_id: message.id,
            chat_id: chatId,
            text,
        }))
    }
)

const WS = {
    receiveMessage: websocketAction<ReceivedPublicMessage>('received_public_message'),
}

const initialState = {
    chatId: null,
    data: {
        results: [],
        count: 0,
        next: null,
        previous: null,
    },
}

const messagesSlice = createSlice({
    name: 'publicChat',
    initialState,
    reducers: {
        setMessage(state, { payload }: PayloadAction<PublicMessage>) {
            state.data.results.push(payload)
            state.data.count += 1
        },
        openNewChat(state, { payload }: PayloadAction<number>) {
            return {
                chatId: payload,
                data: {
                    results: [],
                    count: 0,
                    next: null,
                    previous: null,
                },
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMessagesThunk.fulfilled, (state, { payload }) => {
            state.data.count = payload.count
            state.data.next = payload.next
            state.data.previous = payload.previous
            state.data.results = uniqBy([
                ...state.data.results,
                ...payload.results,
            ], 'id')
        })
        builder.addCase(WS.receiveMessage, (state, { payload }) => {
            if (state.chatId === payload.chat_id) {
                // If we sent the message, find it in results and set the ID
                const savedMessage = state.data.results.find(
                    (message) => `${message.id}` === `${payload.generated_id}`
                )
                if (savedMessage) {
                    savedMessage.id = payload.id
                    return
                }

                // If some other user sent the message, add it
                state.data.results.push({
                    id: payload.id,
                    text: payload.text,
                    sent_at: payload.sent_at,
                    sender: payload.sender,
                })
            }
        })
        builder.addCase(logout.fulfilled, () => initialState)
    },
})

const subscribe = (chatId: number) => sendToWs({
    type: 'join_chat',
    chat_id: chatId,
})

const unsubscribe = (chatId: number) => sendToWs({
    type: 'leave_chat',
    chat_id: chatId,
})

export const chat = { subscribe, unsubscribe }

export const selectPublicMessages = (state: RootState) => (
    state.publicChat.messages.data || createEmptyPagination()
)

export const selectSenderIds = (state: RootState) => (
    uniq(state.publicChat.messages.data?.results?.map((e) => e.sender))
)

export const openNewChat = messagesSlice.actions.openNewChat

export default messagesSlice.reducer
