import React from 'react'
import { MeetingItem } from '../types'
import { addModal } from '../../modals/modalSlice'
import MeetingInfoModal from './MeetingInfoModal'

const showMeetingInfoModal = (meeting: MeetingItem) => addModal({
    Component: ({ close }: { close: () => void }) => (
        <MeetingInfoModal
            meeting={meeting}
            close={close}
        />
    ),
    size: 'xl',
    padding: 0
})

export default showMeetingInfoModal
