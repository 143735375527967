import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
    SerializedError,
} from '@reduxjs/toolkit'
import { RequestStatus } from '../types'

const createRequestSlice = <T>(thunk: ReturnType<typeof createAsyncThunk>, name: string) => createSlice({
    name,
    initialState: {
        // @ts-ignore
        data: null as T,
        status: 'idle' as RequestStatus,
        error: null as SerializedError & {detail?: string, code?: string, error?: string},
    },
    reducers: {
        // @ts-ignore
        emptyData: (state) => {
            state.data = null
            state.status = 'idle'
        },
    },
    extraReducers: (builder) => {
        builder.addCase(thunk.fulfilled, (state, { payload }: PayloadAction<any>) => {
            state.data = payload
            state.status = 'succeeded'
        })
        builder.addCase(thunk.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(thunk.rejected, (state, action) => {
            state.status = 'failed'
            state.data = null
            state.error = action.payload ? action.payload : action.error
        })
    },
})

export default createRequestSlice
