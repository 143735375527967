import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import usePrevious from '../../../hooks/usePrevious'
import useSlug from '../../../hooks/useSlug'
import { leaveNetworkingArea } from '../wsActions'

const LeaveNetworking = () => {
    const eventSlug = useSlug('eventSlug')
    const networkingSlug = useSlug('networkingSlug')
    const prevNetworkingSlug = usePrevious(networkingSlug) || ''
    const dispatch = useDispatch()

    useEffect(() => {
        if (!networkingSlug && prevNetworkingSlug && eventSlug) {
            // Need delay here because TransitionSwitch delay 1000ms when unmounting a component to show animation when redirecting.
            // Make sure dispatching leaveNetworkingArea after networkingArea unmounted
            setTimeout(
                () => dispatch(leaveNetworkingArea({ networkingSlug: prevNetworkingSlug, eventSlug })),
                1100
            )
        }
    }, [networkingSlug, prevNetworkingSlug, dispatch, eventSlug])

    return null
}

export default LeaveNetworking
