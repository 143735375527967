import React, { useEffect } from 'react'
import useSlug from '../../hooks/useSlug'
import Get from '../../components/Get'
import { processProtocol } from '../embeddedvideo/embedded_urls'

const EmbeddedSpace = () => {
    const eventSlug = useSlug('eventSlug')
    const embeddedSlug = useSlug('embeddedSlug')

    useEffect(() => {
        const timetableFooter = document.getElementById('timetable-footer')
        if (timetableFooter) {
            timetableFooter.style.display = 'none'
            return () => {
                timetableFooter.style.display = 'block'
            }
        }
    }, [])

    return (
        <Get url={`events/${eventSlug}/embedded/${embeddedSlug}/`}>
            {({ url }) => (
                <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                    {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                    <iframe src={processProtocol(url)} width="100%" height="100%" />
                </div>
            )}
        </Get>
    )
}

export default EmbeddedSpace
